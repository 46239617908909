import React, { useEffect, useState} from "react";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import "./BecomeTrader.css";
import ImgWindows from "../../../assets/icons/window.png";
import ImgProduct from "../../../assets/icons/product.png";
import { NavLink, useNavigate } from "react-router-dom";
import { useGetCommerceByUserQuery } from "../../../../utils/api/commerce/commerce.api";
import { useAppSelector } from "../../../../redux/hooks";
import { isSeller } from "../../../../routes/router";
import { EmptyMessage } from "../../../../utils/EmptyMessage";

const BecomeTrader = () => {
	const { data, isSuccess } = useGetCommerceByUserQuery();
	const [isValid, setIsValid] = useState(false);
	const navigate = useNavigate();
	const { is_seller, commerce } = useTrader();
	useEffect(() => {
		window.scrollTo(10, 10);
		if (is_seller && commerce?.condition) {
			setTimeout(() => {
				navigate("/commercant/dashboard", { replace: false });
			}, 50);
		}
	}, [isSuccess]);

	useEffect(() => {
		window.scrollTo(10, 10);
		if (isSuccess) {
			console.log("data", data);
			if (data[0]?.id) {
				setIsValid(data[0]?.valid);
			}
		}
	}, [isSuccess]);
	return (
		<div className="become-trader-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="container-munngano pb-5">
					<div className="mt-5">
						<div className="text-center">
							<h2 className="titre-devenir-commercant">
								Intégrer notre réseau de commerçants
							</h2>
							<p className="sous-titre-devenir-commercant">
								Devenez un commerçant Munngano et profitez de nos
								nombreux avantages
							</p>
						</div>
						<div className="my-5">
							<div className="row my-5">
								<div className="col-md-6 d-flex justify-content-center mb-3">
									<div className="text-center">
										<div className="img-become-trader-container pb-4">
											<img
												src={ImgWindows}
												alt="Window"
												className="img-become-trader"
											/>
										</div>
										<div>
											<p className="titre-bloc-become-trader pb-2">
												Une boutique totalement
												personnalisable
											</p>
											<p className="text-bloc-become-trader">
												Lorem ipsum dolor sit amet,
												consectetur adipiscing elit.
												Integer faucibus lectus nunc
												suspendisse. Sed diam mattis
												tortor sed.
											</p>
										</div>
									</div>
								</div>
								<div className="col-md-6 d-flex justify-content-center mb-3">
									<div className="text-center">
										<div className="img-become-trader-container pb-4">
											<img
												src={ImgProduct}
												alt="Product"
												className="img-become-trader"
											/>
										</div>
										<div>
											<p className="titre-bloc-become-trader pb-2">
												Vendez vos produits sur la
												marketplace
											</p>
											<p className="text-bloc-become-trader">
												Lorem ipsum dolor sit amet,
												consectetur adipiscing elit.
												Integer faucibus lectus nunc
												suspendisse. Sed diam mattis
												tortor sed.
											</p>
										</div>
									</div>
								</div>
							</div>
						</div>

						{isSuccess && data[0]?.id ? (
							isValid ? (
								<div>
									<div>
										{EmptyMessage(
											"Vos informations ont été validées par l'équipe de Munngano. Veuillez clique sur le bouton ci-dessous pour compléter votre compte commerçant."
										)}
									</div>
									<div className="col-md-4 offset-md-4 pt-5">
										<NavLink
											to="/devenir-commercant/inscription"
											state={data[0]}
											className="btn btn-theme trans-0-2 w-100"
										>
											<span>
												Compléter vos informations
											</span>
										</NavLink>
									</div>
								</div>
							) : (
								<div className="pt-5 text-center">
									{EmptyMessage(
										"Vos informations sont en cours de validation par l'équipe de Munngano."
									)}
								</div>
							)
						) : (
							<div className="col-md-4 offset-md-4 pt-5">
								<NavLink
									to="/devenir-commercant/remplir-formulaire"
									state={{ valide: isValid }}
									className="btn btn-theme trans-0-2 w-100"
								>
									<span>Remplir le formulaire</span>
								</NavLink>
							</div>
						)}
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export function useTrader() {
	const is_seller = useAppSelector((s) => isSeller(s.user?.user));
	const commerce = useAppSelector<any>((s) => s.commerce);
	return {
		is_seller,
		commerce,
	};
}
export default BecomeTrader;
