import { BsFillCameraFill } from "react-icons/bs";
import "./AddUserModal.scss";
import DefaultPp from "../../../assets/appImages/default-profil.png";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import UseAddAdminUser from "../../admin/SettingAdmin/requestSettings/UseAddAdminUser";
import { FormError } from "../../../common/CustomInputAdmin";
import GoogleInput from "../../../common/GoogleInput";
import { Authorisations } from "../../../../utils/constantes";

const AddAdminModal = () => {
	const {
		register,
		setValue,
		onSubmit,
		errors,
		isLoading,
		avatar,
		setAvatar,
		address,
		setAddress,
		reset,
	} = UseAddAdminUser();

	const handleChangeImage = (e) => {
		console.log("image", e.target.files[0]);
		setAvatar(e.target.files[0]);
		setValue("avatar", e.target.files[0]);
	};

	const handleReset = () => {
		setAddress("");
		setAvatar(null);
		reset();
	};
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title modal-title-custom" id="AddAdminModal">
						Ajouter un administrateur
					</h5>
					<CloseModalBtn onClick={() => handleReset()} />
				</div>
				<div className="modal-body">
					<form onSubmit={onSubmit}>
						<div className="mb-4">
							<div className="profile-pic">
								<label className="-label" htmlFor="fileAdmin">
									<span className="glyphicon glyphicon-camera">
										<BsFillCameraFill />
									</span>
									<span>Change Image</span>
								</label>
								<input
									id="fileAdmin"
									type="file"
									accept="image/*"
									onChange={(e) => handleChangeImage(e)}
								/>
								<img
									src={
										avatar
											? URL.createObjectURL(avatar)
											: DefaultPp
									}
									id="fileAdmin"
									width="200"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Prénom
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Prénom"
										{...register("firstname")}
									/>
									<FormError
										error={errors?.firstname?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Nom
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Nom"
										{...register("lastname")}
									/>
									<FormError
										error={errors?.lastname?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Email
										<span className="text-danger">*</span>
									</label>
									<input
										type="email"
										className="form-control form-control-input-admin"
										placeholder="Email"
										{...register("email")}
									/>
									<FormError
										error={errors?.lastname?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Téléphone
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Téléphone"
										{...register("phone")}
									/>
									<FormError error={errors?.phone?.message} />
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Adresse
										<span className="text-danger">*</span>
									</label>
									<GoogleInput
										value={address}
										onChange={(value) => {
											setAddress(value?.rue);
											setValue("adresse", value?.rue);
											setValue(
												"latitude",
												value?.latitude
											);
											setValue(
												"longitude",
												value?.longitude
											);
										}}
										className="form-control form-control-input-admin"
									/>
									<FormError error={errors?.phone?.message} />
								</div>
							</div>
							<div className="col-md-12">
								<label htmlFor="text" className="admin-form-label">
									Permissions
								</label>
								<div className="row">
									{Authorisations?.map((item, i) => (
										<div className="col-md-4 test" key={i}>
											<div className="custom-control custom-checkbox mb-3 d-flex justify-content-start align-items-baseline">
												<input
													type="checkbox"
													className="custom-control-input"
													{...register(
														item.value
													)}
													defaultChecked={true}
													id={`customControlValidation${i}`}
												/>
												<label
													className="custom-control-label-input-radio custom-control-label"
													htmlFor={`customControlValidation${i}`}
												>
													{item?.label}
												</label>
											</div>
										</div>
									))}
								</div>
							</div>
						</div>
						<div className="text-center mb-4">
							<button
								className="btn btn-theme"
								type="submit"
								disabled={isLoading}
							>
								{!isLoading ? (
									`Ajouter`
								) : (
									<>
										<span
											className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
											role="status"
										></span>
										<span>Chargement...</span>
									</>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddAdminModal;
