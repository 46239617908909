import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useAppSelector } from '../../../../../redux/hooks'
import {
  useAddCartItemMutation,
  useAddOrderCartMutation,
  useLazyGetPaymentUrlQuery,
} from '../../../../../utils/api/order/order.api'
import {
  CartTypeFormData,
  OrderType,
} from '../../../../../utils/api/order/order.type'
import { ApiBaseUrl } from '../../../../../utils/http'
import { cleannerError } from '../../../../../utils/utils'

function UseAddOrderClient() {
  const navigate = useNavigate()
  const validationSchema = useMemo(
		() =>
			yup.object().shape({
				payment_mode: yup.string().required().label("Le mode de paiement"),
				delivery: yup.string().required().label("Le mode de livraison"),
				shipping_address: yup.string().required().label("L'adresse de livraison"),
				firstname: yup.string().required().label("Le prénom"),
				lastname: yup.string().required().label("Le nom"),
				phone: yup.string().required().label("Le téléphone"),
				email: yup.string().email().required().label("L'email"),
			}),
		[]
  );
  const { register, handleSubmit, formState, reset, clearErrors, setValue, setError } = useForm<
		CartTypeFormData | any
  >({
		resolver: yupResolver(validationSchema),
  });

  const [addCart, { isSuccess, isError, error, data }] = useAddOrderCartMutation();
  const [addCartItem, { isSuccess: success, isError: isErr, error: erreur, data: dataItem }] =
		useAddCartItemMutation();
  const [getUrl, { isSuccess: successUrl, isError: isErrUrl, error: errorUrl, data: dataUrl }] =
		useLazyGetPaymentUrlQuery();
  const { user, token } = useAppSelector((s) => s?.user);
  const { cartItems, total, countItems } = useAppSelector<any>((state) => state.cart);
  const [itemLoading, setitemLoading] = useState<boolean>(false);
  const [shipping, setShipping] = useState<any>();
  const [paymentMode, setPaymentMode] = useState<any>();
  const [order, setOrder] = useState<any>();

  useEffect(() => {
		if (isSuccess) {
			setOrder(data);
			console.log("data", data);
			onAddCartItem(data?.id);
		}

		if (isError) {
			console.log("error", error);
			setitemLoading(false);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
  }, [isSuccess, isError]);

  useEffect(() => {
		if (success) {
			// console.log('item', dataItem)
		}

		if (isErr) {
			console.log("error item", erreur);
		}
  }, [success, isErr]);

  useEffect(() => {
		if (successUrl) {
			console.log("item url", dataUrl);
			if (dataUrl?.url) {
				window.open(`${dataUrl?.url}`, "_parent");
			}
		}

		if (isErrUrl) {
			console.log("error url", errorUrl);
			setitemLoading(false);
			const err = errorUrl as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
  }, [successUrl, isErrUrl]);

  useEffect(() => {
		if (formState?.errors) {
			for (let key of Object.keys(formState?.errors)) {
				if (key) {
					window.scrollTo(10, 10);
				}
			}
			cleannerError(formState?.errors, clearErrors, 6000);
		}
  }, [formState]);

  const onAddCartItem = async (id) => {
    await cartItems.forEach(async (item) => {
      addCartItem({
        quantity: item?.count,
        cart: id,
        item: item?.item?.id,
        price: item?.price,
        mode_livraison: shipping?.name,
      })
    })

    setTimeout(async () => {
      if (paymentMode?.name?.toLowerCase() === 'paypal') {
        // await openConfirmation();
        window.open(
          `${ApiBaseUrl}api/payment/?cart=${id}&page=${'commandes/panier'}&env=${
            window.location.origin
          }`,
          '_parent',
        )
      } else {
        await getUrl({
          cart: id,
          page: 'commandes/panier',
          env: window.location.origin,
        })
      }
    }, 3000)
  }
  const onSubmit = async (formValues: CartTypeFormData) => {
    let data = formValues

    setitemLoading(true)
    data['number_of_items'] = countItems
    data['total'] = total

    data['shipping'] = parseFloat(shipping?.amount)
    data['order_total'] = (
      parseFloat(total) + parseFloat(shipping?.amount)
    ).toFixed(2)
    data['delivery'] = formValues?.delivery
    data['payment_mode'] = formValues?.payment_mode
    if (token) {
      data['owner'] = user?.id
    } else {
      data['owner'] = null
    }

    data['billing_address'] = formValues?.billing_address
    data['shipping_address'] = formValues?.shipping_address
    data['page'] = 'commandes/panier'
    data['env'] = window.location.origin

    await console.log(data)
    await addCart(data)
  }
  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    reset,
    shipping,
    setShipping,
    itemLoading,
    paymentMode,
    setPaymentMode,
  }
}

export default UseAddOrderClient
