import React from "react";

interface Props {
	onClick?: any
}

const CloseModalBtn = ({onClick}: Props) => {
	return (
    <button
      className="no-style-btn"
      data-bs-dismiss="modal"
      type="button"
      onClick={() => onClick && onClick()}
    >
      <svg
        width="33"
        height="33"
        viewBox="0 0 33 33"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="16.5" cy="16.5" r="16.5" fill="#E4E6EB" />
        <path
          d="M21.8203 12.3516L17.1719 17L21.8203 21.6484L20.6484 22.8203L16 18.1719L11.3516 22.8203L10.1797 21.6484L14.8281 17L10.1797 12.3516L11.3516 11.1797L16 15.8281L20.6484 11.1797L21.8203 12.3516Z"
          fill="#666666"
        />
      </svg>
    </button>
  );
};

export default CloseModalBtn;
