import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import {
  Category,
  CategoryFormData,
  Subcategory,
  SubCategoryFormData,
} from "./category.type";

const tags = {
  catVst: "categoriesByVisitor",
  cat: "category",
};

export const CategoryApi = createApi({
	reducerPath: "categoryAPi",
	tagTypes: [tags.catVst, tags.cat, "adminCats"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCategory: builder.query<Category[], void>({
			query: () => `/category/`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.position) - parseInt(b?.position)),
			providesTags: [tags.cat],
		}),
		getCategoriesByVisitor: builder.query<Category[], void>({
			query: () => `/categorybyvisitor/`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.position) - parseInt(b?.position)),
			providesTags: [tags.catVst],
		}),
		getAnnuairesCategoriesByVisitor: builder.query<Category[], void>({
			query: () => `/category_partnerbyvisitor/`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.position) - parseInt(b?.position)),
			providesTags: [tags.catVst],
		}),
		getCategoryAdmin: builder.query<
			PaginationResults<Category>,
			{ page: number; word: string }
		>({
			query: (query) => QueryUrl(`/categorybyfilteradmin/`, query),
			providesTags: ["adminCats"],
		}),
		addOrUpdateCategory: builder.mutation<
			Category,
			{ slug?: string; data: CategoryFormData | FormData }
		>({
			invalidatesTags: ["adminCats"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `category/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `category/`,
					method: "POST",
					body: data,
				};
			},
		}),
		addOrUpdateSubCategory: builder.mutation<
			Subcategory,
			{ slug?: string; data: SubCategoryFormData | FormData }
		>({
			invalidatesTags: ["adminCats"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `subcategory/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `subcategory/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteCategory: builder.mutation<Category, string>({
			query: (slug) => ({
				url: `category/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["adminCats"],
		}),
		deleteSubCategory: builder.mutation<Subcategory, string>({
			query: (slug) => ({
				url: `subcategory/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["adminCats"],
		}),
	}),
});

export const {
	useGetCategoryQuery,
	useLazyGetCategoryQuery,
	useGetCategoriesByVisitorQuery,
	useGetCategoryAdminQuery,
	useAddOrUpdateCategoryMutation,
	useAddOrUpdateSubCategoryMutation,
	useDeleteCategoryMutation,
	useDeleteSubCategoryMutation,
	useGetAnnuairesCategoriesByVisitorQuery,
} = CategoryApi;
