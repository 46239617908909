import React, { useEffect, useState } from "react";
import { CKEditor } from "ckeditor4-react";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import TitlePage from "../MyCustumerArea/TitlePage";
import UseEditAnnonceForm from "./UseAnnonceForm/UseEditAnnonceForm";
import Dropzone, { ImageGrid } from "../../../shared/MyDropzone";
import { useSector } from "./Annonce";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import { ApiBaseUrl } from "../../../../utils/http";
import { useLocationState } from "../../../../utils/utils";
import { IoClose } from "react-icons/io5";
import { useDeleteImageMutation } from "../../../../utils/api/media/image.api";
import Swal from "sweetalert2";
import { useLazyFindAnnonceBySlugQuery } from "../../../../utils/api/annonce/annonce.api";
import { useParams } from "react-router-dom";
import { typeAnnonces } from "./DeposerAnnonce";
import { TagsInput } from "react-tag-input-component";

const EditAnnonce = () => {
	const itemState = useLocationState<any>(undefined);
	const [annonce, setAnnonce] = useState<IAnnonce>(itemState?.annonce);
	const [images, setImages] = useState<File[]>([]);
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const sectors = useSector();
	const {
		register,
		onSubmit,
		errors,
		setValue,
		isLoading,
		isSuccess,
		selected,
		handleChangeTags,
	} = UseEditAnnonceForm(annonce);
	const [deleteImage, { isSuccess: isSuccessDelete }] = useDeleteImageMutation();
	const [findAnnonceBySlug, { data }] = useLazyFindAnnonceBySlugQuery();
	const { slug } = useParams<{ slug: string }>();

	const props = {
		setValue,
		setImages,
	};

	useEffect(() => {
		if (!itemState) {
			findAnnonceBySlug(slug);
		}
	}, [itemState]);
	useEffect(() => {
		if (data) {
			setAnnonce(data);
		}
	}, [data]);
	useEffect(() => {
		if (isSuccessDelete) {
			findAnnonceBySlug(slug);
		}
	}, [isSuccessDelete]);
	const handleCountrySelect = (option: any) => {
		setCountry(option);
		if (option.label) {
			setValue("country", option.name);
		}
	};

	const handleStateSelect = (option: any) => {
		setState(option);
		if (option.label) {
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		setCity(option);
		if (option.label) {
			setValue("ville", option.label);
		}
	};

	useEffect(() => {
		if (annonce?.id) {
			setValue("slug", annonce?.slug);
			setValue("title", annonce?.title);
			setValue("sector", annonce?.sector?.id);
			setValue("description", annonce?.description);
			setValue("type_announcement", annonce?.type_announcement);
		}
	}, [annonce?.id]);
	useEffect(() => {
		if (isSuccess) {
			setImages([]);
		}
	}, [isSuccess]);

	const onDeleteImage = (slug) => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cette image?",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteImage(slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
			}
		});
	};

	return (
		<>
			<TitlePage label="Modifier l'annonce" />
			<section className="content-page-container">
				<div className="container-munngano">
					<div className="form-container px-3 pt-5">
						<form data-testid="editAnnonceFormId" onSubmit={onSubmit}>
							<div className="row mb-4">
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="type_announcement"
										className="espace-client-form-label"
										aria-labelledby="type_announcement"
									>
										Type d'annonce
									</label>
									<span className="text-danger">*</span>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="type_announcement"
										data-testid="typeAnnouncementId"
										{...register("type_announcement")}
									>
										{typeAnnonces.map((item) => (
											<option
												value={item.value}
												key={item.value}
											>
												{item.label}
											</option>
										))}
									</select>
									{errors.type_announcement?.message && (
										<ErrorMessage
											message={
												errors.type_announcement
													.message
											}
										/>
									)}
								</div>
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="titre_annonce"
										className="espace-client-form-label"
										aria-labelledby="titre_annonce"
									>
										Titre annonce
									</label>
									<span className="text-danger">*</span>
									<input
										type="text"
										className="form-control espace-client-form-control"
										placeholder="Titre annonce"
										id="titre_annonce"
										data-testid="TitreAnnonceId"
										{...register("title")}
									/>
									{errors.title?.message && (
										<ErrorMessage
											message={errors.title.message}
										/>
									)}
								</div>
								<div
									className="col-md-12 mb-4"
									data-testid="imagesId"
								>
									<Dropzone {...props} />
									<div className="row">
										<ImageGrid images={images} />
									</div>
									<div className="row">
										{annonce?.images?.length &&
											annonce.images.map((image) => (
												<div
													className="col-md-6 mb-1 col-lg-3"
													key={image?.id}
												>
													<div className="image-item position-relative">
														<img
															src={`${ApiBaseUrl}${image?.picture}`}
															alt={
																image?.slug
															}
															loading="lazy"
															className="img-thumbnail"
														/>
														<div
															className="icon-close-container"
															onClick={() =>
																onDeleteImage(
																	image?.slug
																)
															}
														>
															<IoClose
																style={{
																	color: "#000000",
																	fontSize: "12px",
																}}
															/>
														</div>
													</div>
												</div>
											))}
									</div>
									{errors.images?.message && (
										<ErrorMessage
											message={errors.images.message}
										/>
									)}
								</div>
								<div
									className="col-md-12 mb-4 form-group"
									data-testid="descriptionAnnonceId"
								>
									<label
										htmlFor="desc-produit"
										className="espace-client-form-label"
										aria-labelledby="desc-produit"
									>
										Description de l’annonce
									</label>
									<span className="text-danger">*</span>
									<CKEditor
										initData={annonce?.description}
										onChange={(e) =>
											setValue(
												"description",
												e.editor.getData()
											)
										}
									/>
									{errors.description?.message && (
										<ErrorMessage
											message={
												errors.description.message
											}
										/>
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="secteur-activite"
										className="espace-client-form-label"
										aria-labelledby="secteur-activite"
									>
										Secteurs d’activité et Métiers
									</label>
									<span className="text-danger">*</span>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="secteur-activite"
										data-testid="secteurActiviteId"
										{...register("sector")}
									>
										{sectors?.map((sector) => (
											<option
												key={sector.slug}
												value={sector.id}
											>
												{sector.name}
											</option>
										))}
									</select>
									{errors.sector?.message && (
										<ErrorMessage
											message={errors.sector.message}
										/>
									)}
								</div>
								<div className="col-md-12 mb-4">
									<label
										htmlFor="image-project"
										className="espace-client-form-label"
									>
										Tags
									</label>
									<TagsInput
										value={selected}
										onChange={handleChangeTags}
										name="tags"
										placeHolder="Entrer les tags"
									/>
									<em>
										Appuyer sur Entrer pour ajouter un tag
									</em>
									{errors.tags?.message && (
										<ErrorMessage
											message={errors.tags.message}
										/>
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="experiences"
										className="espace-client-form-label"
										aria-labelledby="experiences"
									>
										Région et Ville
									</label>
									<span className="text-danger">*</span>
									<div className="row">
										<div className="col-md-4">
											<CountrySelector
												onChange={
													handleCountrySelect
												}
												name="country"
												placeholder="Pays"
												value={country}
												containerClass="tx country-select-container p-0"
											/>
											{errors.country?.message && (
												<ErrorMessage
													message={
														errors.country
															.message
													}
												/>
											)}
										</div>
										<div className="col-md-4">
											<StateSelector
												country={country}
												name="state"
												value={state}
												countryPlaceholder="Region"
												onChange={handleStateSelect}
												containerClass="tx country-select-container p-0"
											/>
											{errors.region?.message && (
												<ErrorMessage
													message={
														errors.region
															.message
													}
												/>
											)}
										</div>
										<div className="col-md-4">
											<CitySelector
												state={state}
												name="city"
												value={city}
												statePlaceholder="Ville"
												onChange={handleCitySelect}
												containerClass="tx country-select-container p-0"
											/>
										</div>
										{errors.ville?.message && (
											<ErrorMessage
												message={
													errors.ville.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-md-6 offset-md-6 mt-4">
									<BtnSubmit
										label="Modifier"
										isLoading={isLoading}
									/>
								</div>
							</div>
						</form>
					</div>
				</div>
			</section>
		</>
	);
};

export default EditAnnonce;
