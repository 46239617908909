import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ProjectFormData } from "../../../../../utils/api/project/project.type";
import { cleannerError } from "../../../../../utils/utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddProjectMutation } from "../../../../../utils/api/project/project.api";
import { Color } from "../../../../../utils/theme";
function useAjouterProjet() {
	const [selected, setSelected] = useState<string[]>([]);

	//  Calling the add Project user Mutation
	const [addProject, { isLoading, isSuccess, error, isError }] = useAddProjectMutation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Titre"),
		description: yup.string().required().label("description"),
		prerequisite: yup.string().required().label("Prérequis"),
		budget: yup
			.number()
			.required()
			.label("Budget")
			.typeError("Budget est un champ obligatoire"),
		requirements: yup.string().required().label("Exigences"),
		skills: yup.string().required().label("Compétences").max(500),
		images: yup.mixed().required().label("Images"),
		date_expiration: yup.mixed().required().label("Images"),
		type_contract: yup.string().required().label("Type contrat"),
		ville: yup.string().required().label("Ville"),
		region: yup.string().required().label("Region"),
		sector: yup.number().required().label("Secteur"),
		tags: yup.array().required().min(1).label("Tags"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<ProjectFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Projet ajouté avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => navigate("/app/mon-espace-client/mes-projets"));
		}
		if (isError) {
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isSuccess, isError]);

	const handleChangeTags = (value: string[]) => {
		console.log({ value });
		setSelected(value);
		setValue("tags", value);
	};

	const onSubmit = (data: ProjectFormData) => {
		console.log(data);
		const { tags, ...rest } = data;
		const formData = new FormData();
		if (user?.id) {
			formData.append("user", JSON.stringify(user.id));
		}
		if (tags) {
			formData.append("tags", JSON.stringify(tags));
		}
		for (let key of Object.keys(rest)) {
			const val = rest[key];
			formData.append(key, val);
		}
		addProject(formData);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		selected,
		handleChangeTags,
	};
}

export default useAjouterProjet;
