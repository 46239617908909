import "./LoginAdmin.css";
import Logo from "../../../assets/appImages/logo.png";

const EditPasswordAdmin = () => {
	return (
		<div className="login-component">
			<div className="">
				<div className="row login-page-row">
					<div className="col-md-4 offset-md-4 right-side-col">
						<div className="login-form-container">
							<div className="login-form-introduction d-flex justify-content-center">
								<div className="pb-3">
									<img
										src={Logo}
										alt="logo"
										className="app-logo"
									/>
								</div>
								<h1 className="login-title-label">
									Modification mot de passe
								</h1>
							</div>
							<form id="login-form">
								<div className="form-group my-4">
									<label className="espace-client-form-label">
										Mot de passe
									</label>
									<input
										name="password"
										id="password"
										type="password"
										className="form-control input-with-icon pl-4"
										placeholder="************"
										data-testid="passwordId"
									/>
								</div>
								<div className="form-group mt-4 position-relative">
									<label className="espace-client-form-label">
										Confirmer mot de passe
									</label>
									<input
										name="password"
										id="password"
										type="password"
										className="form-control input-with-icon pl-4"
										placeholder="************"
										data-testid="passwordId"
									/>
								</div>
								<div className="row submit-btn-row">
									<div className="col-md-12 submit-btn-col">
										<button
											type="submit"
											className="btn-connexion"
											data-testid="btnId"
										>
											Modifier
										</button>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditPasswordAdmin;
