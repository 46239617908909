import React, { useState, useEffect } from 'react'
import FrontFooter from '../../../shared/FrontFooter/FrontFooter'
import FrontHeader from '../../../shared/FrontHeader/FrontHeader'
import { NavLink, useLocation } from 'react-router-dom'
import './ShopList.css'
import StarRatingComponent from 'react-star-rating-component'
import ReactSlider from 'react-slider'
import { FilterItem } from '../MarketPlace/MarketPlace'
import MarketPlaceCard from '../MarketPlace/common/MarketPlaceCard'
import Comments from './Comments'
import FormAddComment from './FormAddComment'
import Whatsapp from '../../../assets/icons/whatsapp.png'
import Phone from '../../../assets/icons/phone.png'
import Mail from '../../../assets/icons/mail.png'
import { Map, Marker, InfoWindow } from 'google-maps-react'
import { GoogleApiWrapper } from 'google-maps-react'
import '../PartnerList/PartnerList.css'
import { useCommerceFromLocation } from '../../../../utils/api/commerce/commerce.api'
import { createMarkup, getImage } from "../../../../utils/utils";
import {
	useAddSubscribtionMutation,
	useDeleteSubscribtionMutation,
} from "../../../../utils/api/subscriber/subscriber.api";
import { useAppSelector } from "../../../../redux/hooks";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { EmptyMessage } from "../../../../utils/EmptyMessage";
import { useGetProductsByVisitorQuery } from "../../../../utils/api/product/product.api";
import ProductItemSkeleton from "../../../skeletons/ProductItemSkeleton";
import { useGetCategoriesByVisitorQuery } from "../../../../utils/api/category/category.api";

const ShopDetail = (props) => {
	const [selectedCats, setSelectedCats] = React.useState<number[]>([]);
	const [selectedSellers, setSelectedSellers] = React.useState<number[]>([]);
	const [selectedPrice, setSelectedPrice] = React.useState("");
	const [item, isLoading, slug, findBySlug] = useCommerceFromLocation();
	const [addSubscriber, { isError, isLoading: load, isSuccess, data, error }] =
		useAddSubscribtionMutation();
	const { data: products = [], isLoading: isProductLoad } = useGetProductsByVisitorQuery({
		category: selectedCats,
		price_max: parseInt(selectedPrice) === 0 ? "" : selectedPrice,
		price_min: 0,
		commerce: item.id,
	});
	const [unSubscriber, { isLoading: loading, isSuccess: success, isError: isErr }] =
		useDeleteSubscribtionMutation();
	let location = useLocation();
	const { user, token } = useAppSelector((s) => s?.user);
	const [subscriber, setSubscriber] = useState<any>({});
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);
	const [activeMarker, setActiveMarker] = useState<any>({});
	const [selectedPlace, setSelectedPlace] = useState({});
	const { data: categories = [], isLoading: loadCategory } = useGetCategoriesByVisitorQuery();

	const handleChange = (e) => {
		if (e.target.checked) {
			setSelectedCats((old) => [...old, e.target.value]);
		} else {
			setSelectedCats((old) => old.filter((f) => f !== e.target.value));
		}
	};

	const onMarkerClick = (props, marker) => {
		setActiveMarker(marker);
		setSelectedPlace(props);
		setShowingInfoWindow(true);
	};

	const onInfoWindowClose = () => {
		setActiveMarker(null);
		setShowingInfoWindow(false);
	};

	const onMapClicked = () => {
		if (showingInfoWindow) {
			setActiveMarker(null);
			setShowingInfoWindow(false);
		}
	};

	useEffect(() => {
		if (item?.id) {
			let elem = item?.subscribers?.find((s) => s?.user === user?.id);
			setSubscriber(elem);
		}
	}, [item]);

	useEffect(() => {
		if (products) {
			console.log(products);
		}
	}, [products]);

	const onUnSubscribed = async (slug) => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir vous désabonner de cette boutique?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			preConfirm: () => {
				return unSubscriber(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				findBySlug(item?.slug);
				Swal.fire({
					icon: "success",
					title: `Désabonné avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const isSubscribed = (shop) => {
		let elem = shop?.subscribers?.find((s) => s?.user === user?.id);

		return elem?.id ? true : false;
	};

	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			findBySlug(item?.slug);
		}

		if (isError) {
			console.log("error", error);
		}
	}, [isSuccess, isError]);

	const isAuthor = (id) => {
		return user?.id === id;
	};

	return (
		<div className="shop-detail-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="px-5 container-page-shop-list">
					<div className="col-tab-espace-client">
						<div className="banner-container position-relative">
							<div className="banner-img-shop-container">
								<img
									src={getImage(item?.background_image)}
									alt="banniere"
									className="custom-banner-shop-img"
								/>
								<div className="pt-3 d-flex justify-content-end">
									{token &&
										!isAuthor(item?.author?.id) &&
										(isSubscribed(item) ? (
											<button
												className="btn btn-send-newsletter"
												onClick={(e) => {
													e.preventDefault();
													onUnSubscribed(subscriber?.slug);
												}}
												disabled={loading}
											>
												{!loading ? (
													<span>Abonné(e)</span>
												) : (
													<>
														<span>En cours</span>
														<span
															className="spinner-border spinner-border-sm me-1 d-inline-block"
															role="status"
															style={{
																color: "#FFA73C",
															}}
														></span>
													</>
												)}
											</button>
										) : (
											<button
												className="btn btn-send-newsletter"
												onClick={(e) => {
													e.preventDefault();
													addSubscriber({
														user: user?.id,
														commerce: item?.id,
													});
												}}
												disabled={load}
											>
												{!load ? (
													<span>S'abonner</span>
												) : (
													<>
														<span>En cours</span>
														<span
															className="spinner-border spinner-border-sm me-1 d-inline-block"
															role="status"
															style={{
																color: "#FFA73C",
															}}
														></span>
													</>
												)}
											</button>
										))}

									{!token && (
										<NavLink
											className="btn btn-send-newsletter"
											to="/app/se-connecter"
											state={location}
											onClick={(e) => {
												e.preventDefault();
											}}
										>
											<span>S’abonner</span>
										</NavLink>
									)}
								</div>
							</div>
							<div className="img-logo-shop-container">
								<img src={getImage(item?.logo)} alt="Logo" className="logo-shop-img" />
							</div>
						</div>
						<div className="d-flex align-items-center mt-5 pt-5">
							<p className="name-shop pe-3">{item?.name}</p>
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={item?.note_average}
									starColor="#FFA73C"
									emptyStarColor="rgba(180, 180, 180, 0.8)"
								/>
							</div>
							<div>
								<p className="name-shop ps-2">{item?.note_average}/5</p>
							</div>
						</div>
						<div className="row py-4">
							<div className="col-lg-4 col-md-6">
								<div className="flex-sb">
									{/* <div>
										<p className="chiffre-info-shop">
											24 <span className="text-info-shop">abonnements</span>
										</p>
									</div> */}
									<div>
										<p className="chiffre-info-shop">
											{item?.subscribers?.length}{" "}
											<span className="text-info-shop">abonnés</span>
										</p>
									</div>
								</div>
							</div>
						</div>
						<div className="row b-b-1 pb-3">
							<h3 className="name-shop">Description</h3>
							<div
								dangerouslySetInnerHTML={createMarkup(item?.description)}
								className="shop-description"
							/>
						</div>
						<div>
							<div className="row pt-4">
								{/* <div className="col-md-4">
									<div className="filter-container mb-5">
										<h2 className="filter-title">
											Catégories
										</h2>
										<FilterItem
											items={categories}
											loading={loadCategory}
											name="category"
											onChange={(e) => handleChange(e)}
										/>
									</div>

									<div className="filter-by-price-container pb-3">
										<h2 className="filter-title">Prix</h2>
										<h3 className="filter-price-unity">
											Prix (F CFA)
										</h3>
										<div className="position-relative">
											<ReactSlider
												className="mungano-slider"
												thumbClassName="mungano-thumb"
												trackClassName="mungano-track"
												min={0}
												max={500000}
												defaultValue={
													parseInt(
														selectedPrice
													) || 0
												}
												onChange={(value, index) =>
													setSelectedPrice(
														String(value)
													)
												}
												renderTrack={(
													props,
													state
												) => <div {...props} />} //custom track
											/>
										</div>
										<div className="d-flex gap-1 mt-5 container-price">
											<div className="price-container">
												0
											</div>
											-
											<div className="price-container">
												{selectedPrice || 0}
											</div>
										</div>
									</div>
								</div> */}
								<div className="col-12">
									<div>
										<ul className="nav nav-tabs" id="myTab" role="tablist">
											<li className="nav-item" role="presentation">
												<button
													className="nav-link nav-link-tabs-shop active"
													id="produits-tab"
													data-bs-toggle="tab"
													data-bs-target="#produits"
													type="button"
													role="tab"
													aria-controls="produits"
													aria-selected="true"
												>
													Produits :{" "}
													<span className="statut-tabs-shop-info ps-3">
														{!isProductLoad
															? products?.length
															: null}
													</span>
												</button>
											</li>
											<li className="nav-item" role="presentation">
												<button
													className="nav-link nav-link-tabs-shop"
													id="itineraire-tab"
													data-bs-toggle="tab"
													data-bs-target="#itineraire"
													type="button"
													role="tab"
													aria-controls="itineraire"
													aria-selected="false"
												>
													Itinéraire et conatcts
												</button>
											</li>
											<li className="nav-item" role="presentation">
												<button
													className="nav-link nav-link-tabs-shop"
													id="avis-tab"
													data-bs-toggle="tab"
													data-bs-target="#avis"
													type="button"
													role="tab"
													aria-controls="avis"
													aria-selected="false"
												>
													Avis{" "}
													<span className="statut-tabs-shop-info ps-3">
														{item?.notes?.length}
													</span>
												</button>
											</li>
										</ul>
										<div className="tab-content mt-5" id="myTabContent">
											<div
												className="tab-pane fade show active pt-5"
												id="produits"
												role="tabpanel"
												aria-labelledby="produits-tab"
											>
												<div className="row pt-5">
													{!isProductLoad ? (
														products?.length ? (
															products?.map((product) => (
																<div
																	className="col-lg-4 "
																	key={product.slug}
																>
																	<MarketPlaceCard
																		slug={
																			product?.slug
																		}
																		category={
																			product
																				?.category
																				?.name
																		}
																		image={
																			product?.background_image
																		}
																		name={
																			product?.name
																		}
																		price={
																			product?.price
																		}
																		rate={
																			product?.rate_average
																		}
																		item={
																			product
																		}
																	/>
																</div>
															))
														) : (
															<div>
																{" "}
																{EmptyMessage(
																	"Aucun produit disponible pour le moment."
																)}{" "}
															</div>
														)
													) : (
														Array(6)
															.fill(6)
															.map((item, i) => (
																<div
																	className="col-lg-4"
																	key={i}
																>
																	<div className="marketplace-card">
																		<ProductItemSkeleton />
																	</div>
																</div>
															))
													)}
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="itineraire"
												role="tabpanel"
												aria-labelledby="itineraire-tab"
											>
												<div>
													<div className="col-tab-espace-client p-3 mb-3">
														<div className="pb-3 b-b-1">
															<p className="titre-liste-des-commerce">
																Intinéraire du commerce
															</p>
														</div>
														<div
															style={{
																height: "350px",
																width: "100%",
															}}
															className="container-map mt-3"
														>
															<Map
																google={props.google}
																containerStyle={
																	containerStyle
																}
																zoom={14}
																initialCenter={{
																	lat: parseFloat(
																		item?.latitude
																	),
																	lng: parseFloat(
																		item?.longitude
																	),
																}}
																onClick={onMapClicked}
															>
																<Marker
																	name={item?.name}
																	title={item?.name}
																	clickable
																	onClick={
																		onMarkerClick
																	}
																	position={{
																		lat: parseFloat(
																			item?.latitude
																		),
																		lng: parseFloat(
																			item?.longitude
																		),
																	}}
																/>
																<InfoWindow
																	marker={
																		activeMarker
																	}
																	onClose={
																		onInfoWindowClose
																	}
																	visible={
																		showingInfoWindow
																	}
																	position={{
																		lat: parseFloat(
																			item?.latitude
																		),
																		lng: parseFloat(
																			item?.longitude
																		),
																	}}
																>
																	<div>
																		<h4
																			style={{
																				color: "#FFA73C",
																			}}
																		>
																			{
																				item?.name
																			}
																		</h4>
																	</div>
																</InfoWindow>
															</Map>
														</div>
													</div>
													<div className="col-tab-espace-client p-3 mb-3">
														<div className="pb-3 b-b-1">
															<p className="titre-liste-des-commerce">
																Contacter commerce
															</p>
														</div>
														<div className="my-4">
															<div className="flex-sa">
																<a
																	href={`https://wa.me/${item?.whatsapp}`}
																	target="_blank"
																	className="constainer-icon-socials-media"
																>
																	<img
																		src={
																			Whatsapp
																		}
																		alt="whatsapp"
																		className="icon-socials-media"
																	/>
																	<p className="text-icon-socials-media">
																		Whatsapp
																	</p>
																</a>
																<a
																	href={`tel:${item?.phone}`}
																	target="_blank"
																	className="constainer-icon-socials-media"
																>
																	<img
																		src={Phone}
																		alt="phone"
																		className="icon-socials-media"
																	/>
																	<p className="text-icon-socials-media">
																		Appeler
																	</p>
																</a>
																<a
																	href={`mailto:${item?.email}`}
																	target="_blank"
																	className="constainer-icon-socials-media"
																>
																	<img
																		src={Mail}
																		alt="mail"
																		className="icon-socials-media"
																	/>
																	<p className="text-icon-socials-media">
																		Envoyer un
																		email
																	</p>
																</a>
															</div>
														</div>
														<div className="py-3 b-b-1">
															<p className="titre-liste-des-commerce">
																Suivre sur les réseaux
																sociaux
															</p>
														</div>
														<div className="my-4">
															<div className="social-links flex-c">
																<a
																	href={`${item?.facebook}`}
																	target="_blank"
																>
																	<i className="fab fa-facebook-f"></i>
																</a>
																<a
																	href={`${item?.twitter}`}
																	target="_blank"
																>
																	<i className="fab fa-twitter"></i>
																</a>
																<a
																	href={`${item?.instagram}`}
																	target="_blank"
																>
																	<i className="fab fa-instagram"></i>
																</a>
																<a
																	href={`${item?.linkedin}`}
																	target="_blank"
																>
																	<i className="fab fa-linkedin-in"></i>
																</a>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div
												className="tab-pane fade"
												id="avis"
												role="tabpanel"
												aria-labelledby="avis-tab"
											>
												<Comments commerce={item} />
												{token && !isAuthor(item?.author?.id) ? (
													<FormAddComment commerce={item} />
												) : null}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ',
})(ShopDetail)

const containerStyle = {
  position: 'relative',
  width: '100%',
  height: '100%',
}
