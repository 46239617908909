import React, { useEffect } from 'react'
import TraderCommandesSkeleton from './TraderCommandesSkeleton'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import { Link } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import Pagination from '../../../../common/Pagination'
import moment from 'moment'

export const statusFormatter = (cell: any, row: any) => {
  if (cell === 'created') {
    return <span className="status-user-admin-complet">Nouveau</span>
  }
  if (cell === 'placed') {
    return (
      <span className="status-user-admin-en-attente">
        En cours de préparation
      </span>
    )
  }
  if (cell === 'ready') {
    return <span className="status-user-admin-complet">Prêt à être livrer</span>
  }
  if (cell === 'shipped') {
    return (
      <span className="status-user-admin-en-attente">
        En cours de livraison
      </span>
    )
  }

  if (cell === 'delivered' || cell === 'pelivered') {
    return <span className="status-user-admin-complet">Livrée</span>
  }

  if (cell === 'returned') {
    return <span className="status-user-admin-rejete">Retournée</span>
  }

  if (cell === 'canceled') {
    return <span className="status-user-admin-rejete">Annulée</span>
  }

  if (cell === 'failed') {
    return <span className="status-user-admin-rejete">Echec de livraison</span>
  }
}
function TraderCommandesTable({
  orders,
  isLoading,
  perPage,
  setPerPage,
  pageActive,
  setPageActive,
  count,
}) {
  const actionFormatter: any = (cell, row) => {
    return (
		<div className="table-actions-btn-container-commandes">
			<Link title="Voir commande" state={row} to={`/app/commercant/commandes/${row?.slug}`}>
				<FiEye className="btn-check-commande mr-2" />
			</Link>
			{/* <AiFillCheckSquare className="btn-check-commande" />
        <AiOutlineClose className="btn-close-commande" /> */}
		</div>
    );
  }

  const columns = [
    {
      dataField: 'order_code',
      text: 'N° de la commande',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'order_date',
      text: ' Date',
      style: { textAlign: 'left' },
      formatter: (cell, row) => moment(cell).format('DD/MM/YYYY'),
    },
    {
      dataField: 'user',
      text: 'Nom de l’acheteur',
      formatter: (cell, row) => cell?.firstname + ' ' + cell?.lastname,
    },
    {
      dataField: 'shipping_address',
      text: 'Adresse de l’acheteur',
    },
    {
      dataField: 'delivery',
      text: 'Mode de livraison',
      formatter: (cell, row) => cell?.name || '',
    },
    ,
    {
      dataField: 'order_total',
      text: 'Total de la commande',
    },
    {
      dataField: 'status',
      text: 'Statut',
      formatter: (cell, row) => statusFormatter(cell, row),
      headerStyle: () => {
        return { width: '170px' }
      },
    },
    {
      dataField: 'actions',
      text: 'actions',
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  const handleChangePage = ({ perPage, onPageChange }, newPage) => {
    setPageActive(newPage)
  }

  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    setPageActive(1)
    setPerPage(newSizePerPage)
  }

  const options = {
    custom: true,
    sizePerPage: perPage,
    totalSize: count,
  }

  return (
    <>
      {isLoading && <TraderCommandesSkeleton />}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                data={orders || []}
                columns={columns}
                striped
                noDataIndication="Aucune donnée disponible"
                bordered={false}
                condensed={false}
                responsive
                wrapperClasses="table-responsive seller-table"
                rowStyle={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'rgba(0, 0, 0, 0.7)',
                }}
                headerClasses="header-class"
                {...paginationTableProps}
              />
              <CustomPagination
                nbPages={count}
                page={pageActive}
                onChange={(page, perPage) => {
                  handleSizePerPage(paginationProps, perPage)
                  handleChangePage(paginationProps, page)
                }}
                perPage={perPage}
              />
            </div>
          )}
        </PaginationProvider>
      )}
    </>
  )
}

export default TraderCommandesTable
type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
