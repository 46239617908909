import React, { useEffect } from 'react'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import AdminListTableSkeleton from './AdminListTableSkeleton'
import BootstrapTable from 'react-bootstrap-table-next'

import { MdDeleteForever, MdEmail } from 'react-icons/md'
import { FaRegCalendarAlt } from 'react-icons/fa'
import { RiDeleteBin7Fill, RiEdit2Fill } from 'react-icons/ri'
import {
  useEditAdminMutation,
  useGetAdminQuery,
} from '../../../../../utils/api/admin/admin.api'
import Pagination from '../../../../common/Pagination'
import { getAvatar } from '../../../../../utils/utils'
import EditAdminModal from '../../../modals/adminModal/EditAdminModal'
import Swal from 'sweetalert2'
import { Color } from '../../../../../utils/theme'
import DetailsAdminModal from '../../../modals/adminModal/DetailsAdminModal'
import { FiEye } from 'react-icons/fi'

function AdminListTable({ word }) {
  const [pageActive, setPageActive] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const {
    isLoading,
    data: { results: admins, count } = { results: [], count: 0 },
  } = useGetAdminQuery({
    word: word,
    page: pageActive,
    limit: perPage,
  })

  const [editData, { isSuccess, isError, error, data }] = useEditAdminMutation()

  const onArchive = async (item) => {
    let data = {
      is_active: item?.is_archive,
      is_archive: !item?.is_archive,
    }
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive === true ? 'désarchiver' : 'archiver'
      } cet administrateur?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI',
      cancelButtonText: 'NON',
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return editData({ slug: item?.slug, data: data })
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, 'archiver')
      if (result?.value?.data) {
        Swal.fire({
          icon: 'success',
          title: `Administrateur ${
            result?.value?.data?.is_archive ? 'archivé' : 'désarchivé'
          } avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  const photoFormatter = (cell: any, row: any) => {
    return (
      <div>
        <img
          src={getAvatar(cell)}
          alt="Produit"
          className="img-produit-table-admin"
        />
      </div>
    )
  }

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#DetailsAdminModal${row?.slug}`}
          >
            <FiEye />
          </button>
        </div>
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#EditAdminModal${row?.slug}`}
          >
            <RiEdit2Fill />
          </button>
        </div>
        <div>
          <button
            className="btn btn-action-user-admin"
            title={row?.is_archive ? 'Désarchiver' : 'Archiver'}
            onClick={() => onArchive(row)}
          >
            {row?.is_archive ? <MdDeleteForever /> : <RiDeleteBin7Fill />}
          </button>
        </div>
        <div
          className="modal fade"
          id={`EditAdminModal${row?.slug}`}
          aria-labelledby="EditAdminModalLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <EditAdminModal item={row} />
        </div>
        <div
          className="modal fade"
          id={`DetailsAdminModal${row?.slug}`}
          aria-labelledby="DetailsAdminModalLabel"
          aria-hidden="true"
        >
          <DetailsAdminModal admin={row} />
        </div>
      </div>
    )
  }
  const columns = [
		{
			dataField: "avatar",
			text: " Photo",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) => photoFormatter(cell, row),
			headerStyle: () => {
				return { width: "50px" };
			},
		},
		{
			dataField: "firstname",
			text: " Noms",
			style: { textAlign: "left", color: "#FFA73C" },
			formatter: (cell: any, row: any) => cell + " " + row?.lastname,
			headerStyle: () => {
				return { width: "200px" };
			},
		},
		{
			dataField: "email",
			text: "Email",
			style: { textAlign: "left" },
			headerStyle: () => {
				return { width: "250px" };
			},
		},
		{
			dataField: "phone",
			text: "Téléphone",
			headerStyle: () => {
				return { width: "150px" };
			},
		},

		{
			dataField: "adresse",
			text: "Adresse",
			style: { textAlign: "left" },
		},
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "100px" };
			},
		},
  ];

  const handleChangePage = ({ perPage, onPageChange }, newPage) => {
    setPageActive(newPage)
  }

  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    setPageActive(1)
    setPerPage(newSizePerPage)
  }

  const options = {
    custom: true,
    sizePerPage: perPage,
    totalSize: count,
  }

  return (
    <>
      {isLoading && <AdminListTableSkeleton />}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                data={admins || []}
                columns={columns}
                striped
                noDataIndication="Aucune donnée disponible"
                bordered={false}
                condensed={false}
                responsive
                wrapperClasses="table-responsive admin-table"
                rowStyle={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'rgba(0, 0, 0, 0.7)',
                }}
                headerClasses="header-class"
                {...paginationTableProps}
              />
              <CustomPagination
                nbPages={count}
                page={pageActive}
                onChange={(page, perPage) => {
                  handleSizePerPage(paginationProps, perPage)
                  handleChangePage(paginationProps, page)
                }}
                perPage={perPage}
              />
            </div>
          )}
        </PaginationProvider>
      )}
    </>
  )
}

export default AdminListTable
type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
