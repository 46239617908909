import CloseModalBtn from "../../../shared/CloseModalBtn";
import ReactQuill from "react-quill";
import "../../admin/MessageAdmin/MessageAdmin.css";
import React from "react";
import UseAddConditionForm from "../../admin/SettingAdmin/requestSettings/UseAddConditionForm";
import ErrorMessage from "../../../common/ErrorMessage";

type PropsType = {
	type: "cgu" | "cgv" | "pc" | "ml";
};

const AddCguModal = ({ type }: PropsType) => {
	const { register, setValue, clearErrors, onSubmit, formState, isLoading, isSuccess, reset } =
		UseAddConditionForm();
	const [description, setDescription] = React.useState<any>("");
	const handleChange = (value: any) => {
		setDescription(value);
		setValue("text", value);
		setValue("type", type);
	};

	React.useEffect(() => {
		if (isSuccess) {
			handleReset();
		}
	}, [isSuccess]);

	React.useEffect(() => {
		if (type) {
			setValue("type", type);
		}
	}, [type]);

	React.useEffect(() => {
		if (formState.errors) {
			setTimeout(() => {
				Object.entries(formState.errors).map(([key]: any) => {
					return clearErrors(key);
				});
			}, 3000);
		}
	}, [formState]);

	const handleReset = () => {
		setValue("text", "");
		setDescription("");
	};
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content py-2">
				<div className="munngano-modal-header d-flex justify-content-between align-items-center px-3">
					<h1 className="munngano-modal-title">
						Ajouter{" "}
						{type === "cgu"
							? "conditions générales d’utilisation"
							: type === "pc"
							? "politiques de confidentialités"
							: "Mentions légales"}
					</h1>
					<CloseModalBtn onClick={() => handleReset()} />
				</div>
				<div className="form-container px-3">
					<form onSubmit={onSubmit}>
						<div className="row mb-4">
							<div className="col-12 form-group">
								<label
									htmlFor="desc-mision"
									className="admin-form-label"
								>
									Contenu
								</label>
								<ReactQuill
									className="editor-cgu"
									value={description}
									onChange={handleChange}
								/>
								{formState?.errors.text && (
									<ErrorMessage
										message={
											formState?.errors.text?.message
										}
									/>
								)}
							</div>
						</div>
						<div className="btn-container gap-3 justify-content-end d-flex">
							<button
								className="admin-btn-cancel admin-btn-typo"
								data-bs-dismiss="modal"
								id="btnAdd"
								onClick={(e) => {
									e.preventDefault();
									handleReset();
								}}
							>
								Annuler
							</button>
							<button
								className="admin-btn-add admin-btn-typo"
								type="submit"
								disabled={isLoading}
							>
								{!isLoading ? (
									"Ajouter"
								) : (
									<>
										En cours&nbsp;
										<i className="fas fa-spin fa-spinner"></i>
									</>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AddCguModal;
