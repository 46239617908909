import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useGetAbonnementQuery } from "../../../../../utils/api/abonnement/abonnement.api";
import { useCreateCommerceMutation } from "../../../../../utils/api/commerce/commerce.api";
import { BecomeTraderFormData } from "../../../../../utils/api/Trading/trading.type";
import { createMarkup } from "../../../../../utils/utils";

const SouscriptionTraderModal = ({ trader }: { trader?: BecomeTraderFormData }) => {
	const { data: abonnements } = useGetAbonnementQuery();
	const [checked, setChecked] = useState<number>();
	const [err, setErr] = useState<boolean>(true);
	const handleChecked = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.value) {
			setChecked(parseInt(e.currentTarget.value));
			setErr((err) => !err);
		}
	};
	const [sendData, { isLoading, isSuccess, isError, error, data }] =
		useCreateCommerceMutation();

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess && data) {
			console.log("success", data);
			window.location.href = data?.url;
		}
	}, [isSuccess]);

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		let fd = new FormData();
		if (checked) {
			fd.append("offer", String(checked));
		}
		if (trader) {
			for (let key of Object.keys(trader)) {
				const val = trader[key];
				if (key === "slides") {
					val.forEach((item) => {
						fd.append(key, item);
					});
				} else {
					fd.append(key, val);
				}
			}
		}
		sendData(fd);
	};
	return (
		<div
			className="modal fade"
			id="SouscriptionTraderModal"
			aria-labelledby="SouscriptionTraderModal"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Souscrire à un abonnement</h5>
					</div>
					<div className="px-2 py-4">
						<form onSubmit={(e) => onSubmit(e)}>
							<div className="row px-2 text-center">
								{abonnements?.map((offer) => (
									<div
										className="col-md-6 px-2"
										key={offer?.slug}
									>
										<label
											key={offer?.id}
											className={`offer-bloc  ${
												checked === offer?.id &&
												"check"
											}  shadow py-2 px-3 mb-3`}
										>
											<h3 className="offer-title">
												{offer?.title}
											</h3>
											<h6 className="offer-price">
												{offer.price}€/mois
											</h6>
											<div
												className="offer-desc"
												dangerouslySetInnerHTML={createMarkup(
													offer?.description
												)}
											/>
											<input
												type="radio"
												className="form-check-input"
												value={offer?.id}
												// {...register("offer")}
												onChange={(e) =>
													handleChecked(e)
												}
												hidden={true}
											/>
										</label>
									</div>
								))}
							</div>
							<div className="col-6 offset-6 px-2 pt-5">
								{isLoading ? (
									<button
										disabled={true}
										className="btn btn-primary auth-submit-btn"
									>
										<span
											className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
											role="status"
										></span>
										<span>Chargement...</span>
									</button>
								) : (
									<button
										type="submit"
										className="btn btn-primary auth-submit-btn"
										disabled={err}
									>
										Envoyer
									</button>
								)}
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SouscriptionTraderModal;
