import React from 'react'
import { IoMdHeart } from 'react-icons/io'
import { NavLink, useNavigate } from 'react-router-dom'
import StarRatingComponent from 'react-star-rating-component'
import ImgAdd from '../../../../assets/icons/add.png'
import '../MarketPlace.css'
import '../../../homePage/HomePage.css'
import { Category } from '../../../../../utils/api/category/category.type'
import { getImage } from '../../../../../utils/utils'
import { Product } from '../../../../../utils/api/product/product.type'
import { useDispatch } from 'react-redux'
import Swal from 'sweetalert2'
import { addCartItem } from '../../../../../redux/slice/cart.slice'
import { Color } from '../../../../../utils/theme'
import { useAppSelector } from '../../../../../redux/hooks'

export type MarketPlaceItemType = {
  slug?: string
  image?: any
  category?: string
  name?: string
  price?: string | number
  rate?: string
  item?: Product
}

const MarketPlaceCard: React.FC<MarketPlaceItemType> = ({
  slug,
  image,
  category,
  name,
  price,
  rate,
  item,
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useAppSelector((s) => s?.user)

  const isAuthor = (item) => {
    return user?.id &&
      (user?.id === item?.commerce?.author ||
        user?.id === item?.commerce?.author?.id)
      ? true
      : false
  }

  const onAddItem = (e, item) => {
    e.preventDefault()
    if (
      user?.id &&
      (user?.id === item?.commerce?.author ||
        user?.id === item?.commerce?.author?.id)
    ) {
      navigate(`/marketplace/${item?.slug}`)
    } else {
      dispatch(addCartItem(item))
      Swal.fire({
        icon: 'success',
        title: 'Le produit a bien été ajouté au panier!',
        iconColor: Color.themeColor,
        confirmButtonText: 'Finaliser ma commande',
        cancelButtonText: 'Poursuivre mes achats',

        showConfirmButton: true,
        showCancelButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/commandes/panier')
        } else {
          Swal.close()
        }
      })
    }
  }

  const isFavorite = (item) => {
    if (!item?.favorites?.length) return false
    let elem = item?.favorites?.find((f) => f?.user?.id === user?.id)
    return elem?.id ? true : false
  }
  return (
		<div className="marketplace-card">
			<div className="top-product-market-place-item">
				<NavLink to={`/marketplace/${item?.slug}`} state={item} className="no-link">
					<div className="image-market-container pos-relative">
						<img
							src={getImage(image)}
							alt="Top marketplace produit"
							className="img-top-product-marketplace"
							loading="lazy"
						/>
					</div>
				</NavLink>
				<div className="top-product-market-place-card-container">
					<div className="text-top-product-market-place-container">
						<NavLink
							to={`/marketplace/${item?.slug}`}
							state={item}
							className="no-link"
						>
							<p className="top-product-name">{category}</p>
							<p className="top-product-marque">{name}</p>
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={parseFloat(rate || "0")}
									starColor="#FFA73C"
									emptyStarColor="#f6973f96"
								/>
							</div>
							{isFavorite(item) ? (
								<button
									className="btn-favorite no-style-btn"
									disabled
								>
									<IoMdHeart />
								</button>
							) : null}
						</NavLink>
						<div className="top-product-footer">
							<div className="d-flex">
								<p className="top-product-price-unity mb-4">FCFA</p>
								<p className="top-product-price mt-2">{price}</p>
							</div>
							<div>
								{isAuthor(item) === false ? (
									<button
										onClick={(e) => onAddItem(e, item)}
										className="btn btn-add-product-panier trans-0-2"
									>
										<img
											src={ImgAdd}
											className="img-add"
											alt="Icon add"
											loading="lazy"
										/>
									</button>
								) : null}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
  );
}

export default MarketPlaceCard
