import React, { useEffect } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  useDeleteDeliveryMutation,
  useGetDeliveryQuery,
} from '../../../../utils/api/deliveyMode/delivery.api'
import Swal from 'sweetalert2'
import { Color } from '../../../../utils/theme'
import { RiDeleteBin7Fill, RiEdit2Fill } from 'react-icons/ri'
import DeliveryModeSkeleton from '../../../skeletons/DeliveryModeSkeleton'
import Pagination from '../../../common/Pagination'
import EditDeliveryMode from '../../modals/adminModal/EditDeliveryMode'

function DeliveryMode() {
  const [pageActive, setPageActive] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const {
    isLoading,
    data: { results: deliveryModes, count } = { results: [], count: 0 },
  } = useGetDeliveryQuery({
    word: '',
    page: pageActive,
    limit: perPage,
  })

  const [deleteData] = useDeleteDeliveryMutation()

  useEffect(() => {
    if (deliveryModes) {
      console.log('payment', deliveryModes)
    }
  }, [deliveryModes])

  const onDelete = async (slug) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce mode de livraison?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI',
      cancelButtonText: 'NON',
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug)
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      if (result?.value?.data) {
        Swal.fire({
          icon: 'success',
          title: `Mode de livraison supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#EditDeliveryMode${row?.slug}`}
          >
            <RiEdit2Fill />
          </button>
        </div>
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Supprimer"
            onClick={() => onDelete(row?.slug)}
          >
            <RiDeleteBin7Fill />
          </button>
        </div>
        <div
          className="modal fade"
          id={`EditDeliveryMode${row?.slug}`}
          aria-labelledby="EditDeliveryModeLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <EditDeliveryMode delivery={row} />
        </div>
      </div>
    )
  }
  const columns = [
    {
      dataField: 'name',
      text: ' Nom',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'duration',
      text: 'Durée',
      style: { textAlign: 'left' },
      formatter: (cell, row) => cell + 'h',
    },

    {
      dataField: 'amount',
      text: 'Montant',
      style: { textAlign: 'left' },
      formatter: (cell, row) => cell,
    },

    {
      dataField: 'slug',
      text: 'actions',
      style: { textAlign: 'center' },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: '150px' }
      },
    },
  ]

  const handleChangePage = ({ perPage, onPageChange }, newPage) => {
    setPageActive(newPage)
  }

  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    setPageActive(1)
    setPerPage(newSizePerPage)
  }

  const options = {
    custom: true,
    sizePerPage: perPage,
    totalSize: count,
  }
  return (
    <div className="tab-content-admin" id="paymentMode-component">
      {isLoading && <DeliveryModeSkeleton />}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                data={deliveryModes || []}
                columns={columns}
                striped
                noDataIndication="Aucune donnée disponible"
                bordered={false}
                condensed={false}
                responsive
                wrapperClasses="table-responsive admin-table"
                rowStyle={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'rgba(0, 0, 0, 0.7)',
                }}
                headerClasses="header-class"
                {...paginationTableProps}
              />
              <CustomPagination
                nbPages={count}
                page={pageActive}
                onChange={(page, perPage) => {
                  handleSizePerPage(paginationProps, perPage)
                  handleChangePage(paginationProps, page)
                }}
                perPage={perPage}
              />
            </div>
          )}
        </PaginationProvider>
      )}
    </div>
  )
}

export default DeliveryMode

type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
