import moment from "moment";
import { useEffect, useState } from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { IUser } from "../../../../redux/slice/User.slice";
import {
	ChatApi,
	useChatByUserQuery,
	useUpdateCHatMutation,
} from "../../../../utils/api/chat/chat.api";
import { ChatData } from "../../../../utils/api/chat/chat.type";
import { EmptyMessage } from "../../../../utils/EmptyMessage";
import { getImage, useLocationState } from "../../../../utils/utils";
import NewDiscussionModal from "../../modals/user/NewDiscussionModal";
import DetailsMessageTabs from "./DetailsMessageTabs";
import { isAdmin } from "../../../../routes/router";
import SendMessageToAllUsersModal from "../../modals/user/SendMessageToAllUsersModal";

const MesMessagesTabs = () => {
	const userId = useAppSelector((s) => s.user.user?.id);
	const { user } = useAppSelector((s) => s.user);
	const { data: chatData = [] } = useChatByUserQuery();
	const [updateChat] = useUpdateCHatMutation();
	const [selectedUser, setSelectedUser] = useState<IUser | null>(null);

	const [selectedChat, setSelectedChat] = useState<ChatData | null>(null);
	const dispatch = useAppDispatch();
	const sUser = useLocationState<IUser>(null);
	useEffect(() => {
		if (!selectedUser) {
			if (sUser) {
				setSelectedUser(sUser);
			} else {
				if (chatData.length > 0) {
					setSelectedUser(chatData[0].user);
				}
			}
		}
	}, [sUser, chatData, selectedUser]);
	useEffect(() => {
		if (selectedUser) {
			setSelectedChat(chatData.find((l) => l.user.id === selectedUser.id) || null);
		} else {
			setSelectedChat(null);
		}
	}, [selectedUser, chatData]);

	useEffect(() => {
		dispatch(ChatApi.util.invalidateTags(["chat"]));
	}, []);

	useEffect(() => {
		if (selectedChat) {
			setTimeout(() => {
				const promises = selectedChat?.messages
					.filter((chat) => {
						return !chat.read && chat.sender.id !== userId;
					})
					.map((chat) => updateChat({ slug: chat.slug, read: true }));
				Promise.all(promises).then((res) =>
					console.log("marked as read", res?.length)
				);
			}, 3000);
		}
	}, [selectedChat, userId]);

	return (
		<div className="mes-messages-tabs-component">
			<div className="bloc-title-page-tab pb-4">
				<div>
					<p className="titre-page-tab-content">Mes messages</p>
				</div>
				<div className="d-flex align-items-center gap-3">
					{isAdmin(user) && (
						<button
							className="btn btn-send-newsletter"
							data-bs-toggle="modal"
							data-bs-target="#SendMessageToAllUsersModal"
						>
							<MdOutlineAddCircleOutline />
							<span className="ps-2">
								Envoyer un message aux membres
							</span>
						</button>
					)}
					<button
						className="btn btn-send-newsletter"
						data-bs-toggle="modal"
						data-bs-target="#NewDiscussionModal"
					>
						<MdOutlineAddCircleOutline />
						<span className="ps-2">Nouvelle discussion</span>
					</button>
				</div>
			</div>
			<div className="message-tabs-container">
				<div>
					<div className="row mt-5">
						<div className="col-xl-4 col-lg-4 col-md-6">
							<div className="page-content-container pr-0 pt-0">
								<div className="parent-table-container-message-tabs">
									<ul className="list-group list-group-message list-group-flush">
										{chatData.length === 0 &&
											EmptyMessage(
												"Pas de discussion ouverte"
											)}
										{chatData.map((chat) => (
											<ContactItem
												active={
													selectedChat === chat
												}
												item={chat}
												onCLick={() =>
													setSelectedUser(
														chat.user
													)
												}
												key={chat.user.id}
											/>
										))}
									</ul>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-6">
							<DetailsMessageTabs
								user={selectedUser}
								chat={selectedChat}
							/>
						</div>
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="NewDiscussionModal"
				aria-labelledby="NewDiscussionModalLabel"
				aria-hidden="true"
			>
				<NewDiscussionModal onSelect={setSelectedUser} />
			</div>
			<div
				className="modal fade"
				id="SendMessageToAllUsersModal"
				aria-labelledby="SendMessageToAllUsersModalLabel"
				aria-hidden="true"
			>
				<SendMessageToAllUsersModal />
			</div>
		</div>
	);
};
export default MesMessagesTabs;

function ContactItem({
	item,
	active,
	onCLick,
}: {
	item: ChatData;
	active: boolean;
	onCLick: () => any;
}) {
	const user = item.user;
	const message = item.lastMessage;
	return (
		<li
			onClick={onCLick}
			style={{ cursor: "pointer" }}
			className={`list-group-item list-group-item-meessage linkable ${
				active && "active"
			}`}
		>
			<div>
				<div className="bloc-profil-contact">
					<div className="d-flex align-items-center w-70">
						<img
							src={getImage(user.avatar)}
							className="img-messge-contact"
							alt="image profil contact"
						/>
						<p
							className={`nom-contact ps-3 ${active && "active"}`}
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
							}}
						>
							{user.firstname} {user.lastname}
						</p>
					</div>
					<div>
						<p className="time-message-contact">
							{moment(message.created_at).format("DD/MM/YYYY[,] HH:mm")}
						</p>
					</div>
				</div>
				<div className="bloc-message-contact pt-2">
					<div className="w-85">
						<p
							className="contenu-message-contact ps-5 linkable"
							style={{
								textOverflow: "ellipsis",
								overflow: "hidden",
								whiteSpace: "nowrap",
							}}
						>
							{message.content}
						</p>
					</div>
					{item.count > 0 && (
						<div className="">
							<span>
								<span className="statut-message-tabs-non-lu">
									{item.count}
								</span>
							</span>
						</div>
					)}
				</div>
			</div>
		</li>
	);
}
