import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import { RiSendPlaneFill } from "react-icons/ri";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../redux/hooks";
import { IUser } from "../../../../redux/slice/User.slice";
import { useAddChatMutation } from "../../../../utils/api/chat/chat.api";
import { Chat, ChatData } from "../../../../utils/api/chat/chat.type";
import { getImage } from "../../../../utils/utils";

const DetailsMessageTabs = ({ chat, user }: { chat: ChatData | null; user: IUser | null }) => {
	const ref = useRef<HTMLDivElement>(null);
	useEffect(() => {
		setTimeout(() => {
			if (ref.current) {
				ref.current.scrollTo({
					top: ref.current.scrollHeight,
				});
			}
		}, 500);
	}, [ref.current, chat]);
	if (!user) return null;
	return (
		<div className="dtails-messages-tabs-component">
			<div className="row border-bottom p-3 align-items-center">
				<div className="col-xl-1 col-lg-2 col-sm-1 col-md-3">
					<img
						src={getImage(user.avatar)}
						className="image-profil-entete pe-0"
						alt="Image prfil detail message"
					/>
				</div>
				<div className="col-xl-10 col-lg-8 col-md-9 col-sm-10 ps-0">
					<div className="d-flex align-items-center">
						<p className="profil-detail-message" style={{ fontSize: 15 }}>
							{user.firstname} {user.lastname}
						</p>
						<div className="statut-expediteur ms-3"></div>
					</div>
				</div>
			</div>
			<div className="bloc-details pb-5">
				{chat?.messages?.map((message) => {
					if (message.sender.id === user.id) {
						return <Message item={message} key={message.id} />;
					}
					return <Response item={message} key={message.id} />;
				})}
			</div>
			<ChatInput userId={user.id} />
		</div>
	);
};

export default DetailsMessageTabs;

function Message({ item }: { item: Chat }) {
	return (
		<div>
			<div className="d-flex align-items-center pb-2">
				<img
					src={getImage(item.sender.avatar)}
					className="img-expediteur"
					alt="Image prfil detail message"
				/>

				<p className="profil-detail-message ps-3">
					{item.sender.firstname} {item.sender.lastname}
				</p>
				<span className="time-detail-message ps-3">
					{moment(item.created_at).format("HH:mm")}
				</span>
			</div>
			<div className="bloc-message-recu p-3">
				<p className="text-message-recu">{item.content}</p>
			</div>
		</div>
	);
}

function Response({ item }: { item: Chat }) {
	return (
		<div className="py-3 bloc-reponse">
			<div className="bloc-message-envoyer p-3">
				<p className="text-message-envoyer">{item.content}</p>
			</div>
			<div className="pt-2">
				<div className="d-flex justify-content-end align-items-center">
					<img
						src={getImage(item.sender.avatar)}
						className="img-expediteur"
						alt="Image prfil detail message"
					/>
					<p className="profil-detail-message ps-2">Vous</p>
					<span className="time-detail-message px-2">
						{moment(item.created_at).format("HH:mm")}
					</span>
				</div>
			</div>
		</div>
	);
}

function ChatInput({ userId }) {
	const user = useAppSelector((s) => s.user.user);
	const [addChat, { isLoading, isError, isSuccess }] = useAddChatMutation();
	const [message, setMessage] = useState("");
	useEffect(() => {
		if (isError) {
			Swal.fire({
				icon: "error",
				iconColor: "#F00",
				confirmButtonColor: "#F00",
				title: "Message non envoyé",
			});
		}
	}, [isError]);
	useEffect(() => {
		if (isSuccess) {
			setMessage("");
		}
	}, [isSuccess]);
	const onSubmit = useCallback(() => {
		if (message.trim().length > 1) {
			addChat({
				content: message,
				receiver: userId,
				sender: user.id,
			});
		}
	}, [message, user, userId]);
	return (
		<div className="p-3 border-top">
			<form
				onSubmit={(e) => {
					e.preventDefault();
					onSubmit();
				}}
			>
				<div className="row align-items-center">
					<div className="col-xl-11 col-lg-10 col-md-9">
						<input
							className="input-send-messge form-control"
							placeholder="Écrire un message"
							value={message}
							required
							onChange={(e) => setMessage(e.target.value)}
							name="reponse"
						/>
					</div>
					<div className="col-xl-1 col-lg-2 col-md-3">
						{!isLoading && (
							<button type="submit" className="btn btn-send-message-tabs">
								<RiSendPlaneFill />
							</button>
						)}

						{isLoading && (
							<button disabled type="button" className="btn btn-send-message-tabs">
								<FaSpinner />
							</button>
						)}
					</div>
				</div>
			</form>
		</div>
	);
}
