import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { BudgetEditFormData } from "../../../../utils/api/budget/budget.type";
import { cleannerError } from "../../../../utils/utils";
import { Color } from "../../../../utils/theme";
import { useEditdBudgetMutation } from "../../../../utils/api/budget/budget.api";
import { useAppDispatch } from "../../../../redux/hooks";
function useEditBudgetModal() {
	const [editBudget, { isLoading, isSuccess, error, isError }] = useEditdBudgetMutation();
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Budget"),
	});
	const dispatch = useAppDispatch();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<BudgetEditFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Budget modifié avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				document.getElementById("editBudgetModal")?.classList.remove("show");
				$(".modal-backdrop").remove();
				$(`#editBudgetModal`).hide();
				$(`#editBudgetModal`).click();
			});
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	const onSubmit = (data: BudgetEditFormData) => {
		console.log(data);
		editBudget({ slug: data?.slug, data: data });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading: isLoading,
	};
}

export default useEditBudgetModal;
