import React, { useEffect } from 'react'
import CommandesListAdminTableSkeleton from './CommandesListAdminTableSkeleton'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { Link } from 'react-router-dom'
import { FiEye } from 'react-icons/fi'
import { useGetOrdersQuery } from '../../../../../utils/api/order/order.api'
import Pagination from '../../../../common/Pagination'
import moment from 'moment'
import ChangeStatusOrder from '../../../modals/adminModal/ChangeStatusOrder'

function CommandesListAdminTable() {
  const [pageActive, setPageActive] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const {
    isLoading,
    data: { results: orders, count } = { results: [], count: 0 },
  } = useGetOrdersQuery({
    word: '',
    page: pageActive,
    limit: perPage,
  })

  const selectRow = {
    mode: 'checkbox',
    clickToSelect: true,
  }

  const statusFormatter = (cell: any, row: any) => {
    if (cell === 'created') {
      return (
        <span
          className="status-user-admin-complet linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          Nouveau
        </span>
      )
    }
    if (cell === 'placed') {
      return (
        <span
          className="status-user-admin-en-attente linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          En cours de préparation
        </span>
      )
    }
    if (cell === 'ready') {
      return (
        <span
          className="status-user-admin-complet linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          Prêt à être livrer
        </span>
      )
    }
    if (cell === 'shipped') {
      return (
        <span
          className="status-user-admin-en-attente linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          En cours de livraison
        </span>
      )
    }

    if (cell === 'delivered' || cell === 'pelivered') {
      return <span className="status-user-admin-complet">Livrée</span>
    }

    if (cell === 'returned') {
      return (
        <span
          className="status-user-admin-rejete linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          Retournée
        </span>
      )
    }

    if (cell === 'canceled') {
      return (
        <span
          className="status-user-admin-rejete linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          Annulée
        </span>
      )
    }

    if (cell === 'failed') {
      return (
        <span
          className="status-user-admin-rejete linkable"
          data-bs-toggle="modal"
          data-bs-target={`#ChangeStatusOrderModal${row?.slug}`}
        >
          Echec de livraison
        </span>
      )
    }
  }

  const actionFormatter: any = (cell, row) => {
    return (
		<div className="table-actions-btn-container-commandes gap-2 d-flex">
			<Link
				title="Voir commande"
				state={row}
				to={`/app/admin/commandes/${row?.slug}`}
				className="btn btn-action-user-admin"
			>
				<FiEye />
			</Link>
			{/* <div>
          <button className="btn btn-action-user-admin">
            <RiEdit2Fill />
          </button>
        </div>
        <div>
          <button className="btn btn-action-user-admin">
            <RiDeleteBin7Fill />
          </button>
        </div> */}
			<div
				className="modal fade"
				id={`ChangeStatusOrderModal${row?.slug}`}
				aria-labelledby="ChangeStatusOrderModalLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
			>
				<ChangeStatusOrder order={row} />
			</div>
		</div>
    );
  }
  const columns = [
    {
      dataField: 'order_code',
      text: 'N° de la commande',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'order_date',
      text: ' Date',
      style: { textAlign: 'left' },
      formatter: (cell, row) => moment(cell).format('DD/MM/YYYY'),
    },
    {
      dataField: 'user',
      text: 'Nom de l’acheteur',
      formatter: (cell, row) => cell?.firstname + ' ' + cell?.lastname,
    },
    {
      dataField: 'shipping_address',
      text: 'Adresse de l’acheteur',
    },
    {
      dataField: 'delivery',
      text: 'Mode de livraison',
      formatter: (cell, row) => cell?.name || '',
    },
    ,
    {
      dataField: 'order_total',
      text: 'Total de la commande',
    },
    {
      dataField: 'status',
      text: 'Statut',
      formatter: (cell, row) => statusFormatter(cell, row),
      headerStyle: () => {
        return { width: '170px' }
      },
    },
    {
      dataField: 'actions',
      text: 'actions',
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  const handleChangePage = ({ perPage, onPageChange }, newPage) => {
    setPageActive(newPage)
  }

  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    setPageActive(1)
    setPerPage(newSizePerPage)
  }

  const options = {
    custom: true,
    sizePerPage: perPage,
    totalSize: count,
  }

  return (
    <>
      {isLoading && <CommandesListAdminTableSkeleton />}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                data={orders || []}
                columns={columns}
                striped
                noDataIndication="Aucune donnée disponible"
                bordered={false}
                condensed={false}
                responsive
                wrapperClasses="table-responsive admin-table"
                rowStyle={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'rgba(0, 0, 0, 0.7)',
                }}
                headerClasses="header-class"
                {...paginationTableProps}
              />
              <CustomPagination
                nbPages={count}
                page={pageActive}
                onChange={(page, perPage) => {
                  handleSizePerPage(paginationProps, perPage)
                  handleChangePage(paginationProps, page)
                }}
                perPage={perPage}
              />
            </div>
          )}
        </PaginationProvider>
      )}
    </>
  )
}

export default CommandesListAdminTable

type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
