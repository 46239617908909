import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { MdOutlineAddCircleOutline } from "react-icons/md";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import Pagination from "../../../../common/Pagination";
import { FiEye } from "react-icons/fi";
import {
	useDeleteAboutMutation,
	useGetAboutsQuery,
} from "../../../../../utils/api/about/about.api";
import { AlertInfo } from "../../../../common/Alert";
import TableSkeleton from "../../../../common/TableSkeleton";
import { getAvatar, useModal } from "../../../../../utils/utils";
import { IAbout } from "../../../../../utils/api/about/about.type";
import { useDelete } from "../../../admin/Boosted/UseBoosted";
import AddAboutModal from "../../../modals/adminModal/AddAboutModal";
import DetailSectionAboutModal from "../../../modals/adminModal/DetailSectionAboutModal";

function AboutListTable() {
	const {
		page,
		setPage,
		isLoading,
		data,
		isShowModal,
		closeModal,
		openEditModal,
		openModal,
		item,
		getItem,
	} = useAbouts();

	const photoFormatter = (cell: any, row: any) => {
		return (
			<div>
				<img
					src={getAvatar(cell)}
					alt="Produit"
					className="img-produit-table-admin"
				/>
			</div>
		);
	};

	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						data-bs-toggle="modal"
						data-bs-target={`#DetailSectionAboutModal`}
						onClick={() => getItem(row)}
					>
						<FiEye />
					</button>
				</div>
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						onClick={() => openEditModal(row)}
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteAboutSection item={row} />
				</div>
			</div>
		);
	};
	const messageFormatter = (cell: any, row: IAbout) => {
		return (
			<div
				dangerouslySetInnerHTML={{ __html: row?.content.slice(0, 100) }}
				className="description-section"
			/>
		);
	};
	const columns = [
		{
			dataField: "image",
			text: " Photo",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) => photoFormatter(cell, row),
		},
		{
			dataField: "titre",
			text: " Titre",
			style: { textAlign: "left", color: "#FFA73C" },
		},
		{
			dataField: "type_propos",
			text: "Type",
			style: { textAlign: "left" },
			formatter: (cell: string, row: any) =>
				cell === "histoire" ? "Histoire" : "Idée",
		},
		{
			dataField: "content",
			text: "Message",
			formatter: (cell: any, row: any) => messageFormatter(cell, row),
		},
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			<div className="flex-r mb-4">
				<button className="btn btn-theme p-2" onClick={openModal}>
					<MdOutlineAddCircleOutline />
					<span className="ps-2">Ajouter une section</span>
				</button>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="slug"
						identity_code
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={<AlertInfo message="Aucune donnée trouvée" />}
					/>
					<Pagination page={page} onPageChange={setPage} total={data?.count} />
				</>
			)}
			<AddAboutModal isOpen={isShowModal} closeModal={closeModal} item={item} />
			<DetailSectionAboutModal item={item} />
		</>
	);
}

function useAbouts() {
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);
	const { isLoading, data = { results: [], count: 0 } } = useGetAboutsQuery({
		page,
		limit: perPage,
	});
	const { isShowModal, closeModal, openModal, item, openEditModal, getItem } =
		useModal<IAbout>();
	return {
		page,
		setPage,
		perPage,
		setPerPage,
		isLoading,
		data,
		isShowModal,
		closeModal,
		openEditModal,
		openModal,
		item,
		getItem,
	};
}

export function DeleteAboutSection({ item }: { item: IAbout }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteAboutMutation();
	const onDelete = useDelete<IAbout>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette section?",
		successMessage: "Section supprimé",
	});
	return (
		<button className="btn btn-action-user-admin" title="Supprimer" onClick={onDelete}>
			<RiDeleteBin7Fill />
		</button>
	);
}

export default AboutListTable;
