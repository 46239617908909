import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useResetUserPasswordMutation } from "../../../../utils/api/auth/auth.api";
import {
	ForgetPasswordFormData,
	ResetPasswordFormData,
} from "../../../../utils/api/auth/auth.type";
import { Color } from "../../../../utils/theme";
import { validatePassword } from "./useRegisterForm";
export function UseResetPasswordForm() {
	const [resetPassword, { error, isSuccess, isLoading }] = useResetUserPasswordMutation();

	const state = useLocation().state as ForgetPasswordFormData;

	const navigate = useNavigate();

	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup.string().email().required().label("L'email"),
				code: yup
					.number()
					.required()
					.label("Code")
					.typeError("Le code doit être un nombre"),
				new_password: validatePassword(yup, "Le nouveau mot de passe est requis."),
				new_password_confirm: yup
					.string()
					.oneOf(
						[yup.ref("new_password"), null],
						"veuillez entrer des mots de passe identiques."
					),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		setError,
	} = useForm<ResetPasswordFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (state?.email) {
			register("email");
			setValue("email", state.email);
		} else {
			setTimeout(() => navigate("/app/recuperer-mot-de-passe"), 20);
		}
	}, [state?.email]);

	useEffect(() => {
		if (error) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			setError("code", { message: message });
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Mot de passe modifié avec succès",
			});
			navigate("/app/se-connecter");
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 3000);
		}
	}, [errors]);
	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(resetPassword),
		clearErrors,
		isLoading,
	};
}
