import React, { useState, useEffect } from 'react'
import { AiOutlineHeart } from 'react-icons/ai'
import { RiDeleteBinLine } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { ItemProvider } from '../../../../redux/cart.type'
import { useAppDispatch, useAppSelector } from '../../../../redux/hooks'
import { deleteCartItem, toggleItem } from '../../../../redux/slice/cart.slice'
import { getImage } from '../../../../utils/utils'
import EmptyCartImage from '../../../assets/appImages/empty_cart.svg'
import CartItem from './CartItem'

interface PropsType {
  navigation: any
}

const PanierTabs: React.FC<PropsType> = ({ navigation }) => {
  const { cartItems, total } = useAppSelector<any>((state) => state.cart)
  const [items, setItems] = useState<any[]>([])
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (cartItems?.length) {
      setItems(GroupedItem(cartItems))
    } else {
      setItems([])
    }
  }, [cartItems])

  return (
    <div className="content-card-account">
      <form>
        <div className="commande-item m-b-40">
          <CartItem items={items} />
          {items?.length ? (
            <div className="row">
              <div className="col-lg-8 dis-flex itm-center">
                <div className="row w-full m-b-30">
                  <div className="col-lg-8 col-md-10">
                    <p className="resume-item fw-300 fs-14">
                      Date de livraison
                    </p>
                    <p className="resume-item fw-600 fs-14 ">
                      Livré entre jeudi 3 sept. et vendredi 4 sept.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <p className="resume-item fw-400 fs-14 m-b-20">
                  Prix ​​sous-total :{' '}
                  <span className="value-resume-item fs-14 fw-700">
                    {total} FCFA
                  </span>
                </p>
                <p className="resume-item fw-400 fs-14 m-b-20">
                  Frais de livraison (+) :{' '}
                  <span className="value-resume-item fs-14 fw-700">0 FCFA</span>
                </p>
                <p className="resume-item fw-400 fs-14 m-b-20">
                  Remise (-) :{' '}
                  <span className="value-resume-item fs-14 fw-700">
                    {' '}
                    0 FCFA
                  </span>
                </p>
                <p className="resume-item fw-700 itm-center m-b-20">
                  Total à payer :{' '}
                  <span className="value-resume-item-ttc">{total} FCFA</span>
                </p>
              </div>
            </div>
          ) : null}
        </div>
        {items?.length ? (
          <div className="row justify-content-end m-b-200 mt-5 align-items-center">
            <div className="col-xl-3 col-lg-4 col-md-6 mb-3">
              <NavLink
                to="/marketplace"
                className="btn btn-user-info-form-reverse no-link"
              >
                Poursuivre mes achats
              </NavLink>
            </div>
            <div className="col-lg-4 col-md-6 mb-3">
              <a
                type="button"
                className="btn btn-theme trans-0-2 w-100"
                onClick={(e) => {
                  e.preventDefault()
                  navigation.go('finaliser-commande')
                }}
              >
                Finaliser ma commande
              </a>
            </div>
          </div>
        ) : null}
      </form>
    </div>
  )
}

export default PanierTabs

export const GroupedItem = (arr) => {
  const groups = arr?.reduce((groups, elem) => {
    const shop = elem?.item?.commerce?.id
    if (!groups[shop]) {
      groups[shop] = []
    }
    groups[shop].push(elem)
    return groups
  }, {})

  const allGroups = Object.keys(groups).map((shop) => {
    return {
      commerce: groups[shop][0]?.item?.commerce,
      items: groups[shop],
    }
  })

  return allGroups
}
