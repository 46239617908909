import Divider from "../Divider";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import "./About.css";
import ReactPlayer from "react-player";
import PlayImg from "../../assets/icons/Play.png";
import Imgbg from "../../assets/appImages/video.png";
import PersonBold from "../../assets/icons/person-bold.png";
import CheckBold from "../../assets/icons/check-bold.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const HowItWorks = () => {
	const location = useLocation();
	useEffect(() => {
		if ((location.state as any)?.scroll) {
			window.scrollTo(0, (location.state as any).scroll);
		}
	}, [location]);
	return (
		<>
			<FrontHeader />
			<div className="about-page">
				<h1 className="my-4">Comment Ça marche ?</h1>
				<Divider />
				<div className="py-5  container-munngano">
					<div className="row mb-5 d-md-flex align-items-center">
						<div className="col-md-6 mt-4">
							<h3 className="about-sous-title">
								Comment se référencer
							</h3>
							<div className="about-text">
								<p>
									Lorem ipsum dolor sit amet, consectetur
									adipiscing elit. Phasellus sed sagittis
									tincidunt suscipit. Enim nisi sed sit felis at
									maecenas. Nisl, cras scelerisque at quam
									lectus varius dolor, accumsan. Urna, molestie
									sit turpis vel, commodo, sodales eget enim.
									Neque, non eget eget id justo.
								</p>
								<p>
									Enim, id risus massa nam aenean ut ipsum
									sodales. Risus, elit, sit cursus ultrices
									luctus dolor. Ipsum risus neque eget nullam
									praesent proin nisi. Eu viverra massa ac leo
									semper. Fames quis et, interdum dui felis,
									erat erat. Nibh netus vestibulum nunc, aliquet
									risus purus. Fringilla nisl nisl at
								</p>
							</div>
						</div>
						<div className="col-md-6 d-flex justify-content-center justify-content-md-end">
							<ReactPlayer
								url="munngano.mp4"
								width="80%"
								playing
								playIcon={<img src={PlayImg} />}
								light={Imgbg}
								controls
								className="munngano-video-player"
							/>
						</div>
					</div>
					<div className="row mb-5">
						<div className="pt-3 col-md-4">
							<div className="d-flex pb-3 gap-2 gap-xl-4">
								<div className="img-parent">
									<img
										src={PersonBold}
										alt="icon check"
										className="icon-check-bold"
									/>
								</div>
								<div>
									<p className="col-right-titre-list">
										Vous êtes un particulier?
									</p>
									<p className="col-right-text-list">
										Vous souhaitez (Re)découvrir des
										commerces , de nouveaux produits et
										services. Profiter de promotions
										Echanger sur des thèmes d'actualités
									</p>
								</div>
							</div>
						</div>
						<div className="pt-3 col-md-4">
							<div className="d-flex pb-3 gap-2 gap-xl-4">
								<div className="img-parent">
									<img
										src={PersonBold}
										alt="icon check"
										className="icon-check-bold"
									/>
								</div>
								<div>
									<p className="col-right-titre-list">
										Vous êtes un professionnel?
									</p>
									<p className="col-right-text-list">
										Vous souhaitez intégrer notre réseau,
										gagner en visibilité , faire connaitre
										vos produits et services
									</p>
								</div>
							</div>
						</div>
						<div className="pt-3 col-md-4">
							<div className="d-flex pb-3 gap-2 gap-xl-4">
								<div className="img-parent">
									<img
										src={CheckBold}
										alt="icon check"
										className="icon-check-bold"
									/>
								</div>
								<div>
									<h6 className="col-right-titre-list p-0">
										Munngano centralise l’offre
									</h6>
									<p className="col-right-text-list">
										Notre plateforme centralise l'offre,
										accorde aux professionnels une place de
										choix pour vendre et gagner en
										visibilité , vous offre l'opportunité
										d'intégrer un réseau d'expert. Bienvenue
										dans l'univers Munngano !
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row ">
						<h3 className="about-sous-title">
							Retrouver un professionnel à proximité
						</h3>
						<div className="about-text">
							<p>
								Lorem ipsum dolor sit amet, consectetur adipiscing
								elit. Phasellus sed sagittis tincidunt suscipit.
								Enim nisi sed sit felis at maecenas. Nisl, cras
								scelerisque at quam lectus varius dolor, accumsan.
								Urna, molestie sit turpis vel, commodo, sodales eget
								enim. Neque, non eget eget id justo.
							</p>
							<p>
								Enim, id risus massa nam aenean ut ipsum sodales.
								Risus, elit, sit cursus ultrices luctus dolor. Ipsum
								risus neque eget nullam praesent proin nisi. Eu
								viverra massa ac leo semper. Fames quis et, interdum
								dui felis, erat erat. Nibh netus vestibulum nunc,
								aliquet risus purus. Fringilla nisl nisl at
							</p>
						</div>
					</div>
				</div>
			</div>
			<FrontFooter />
		</>
	);
};

export default HowItWorks;
