import React from "react"
import Icon1 from "../../assets/landingpage/icon1.svg"
import Icon2 from "../../assets/landingpage/icon2.svg"
import Icon3 from "../../assets/landingpage/icon3.svg"
import Icon4 from "../../assets/landingpage/icon4.svg"

const Concept = () => {
	return (
		<div className="concept-component">
			<div className="container-section-landingpage">
				<div className="container-title-landingpage">
					<h2 className="title-section-landingpage position-relative text-center">Notre concept</h2>
				</div>
				<div className="container-text-title-concept-landingpage w-50 m-auto py-4">
					<p className="text-concept-landingpage pt-4">
						Munngano Connectt est une communauté de professionnel fiable qui poursuivent une
						vision stratégique dans le développement des affaires en Afriques .
					</p>
				</div>
				<div className="row row-concept-landingpage mt-4">
					{Datas.map((i, index) => (
						<div className="col-lg-3 col-concept-landingpage d-flex mb-3" key={index}>
							<div className="content-col-concept-landingpage w-100">
								<div className="content-img-icon-concept-landingpage pb-3">
									<img
										src={i.img}
										alt="Concept"
										className="img-icon-concept-landingpage"
									/>
								</div>
								<p className="title-concept-card-landingpage">{i.title}</p>
								<div className="container-text-concept-card pt-4">
									<p className="text-concept-card">{i.text}</p>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Concept;
const Datas = [
    {
        id: 1,
        title: "Identification",
        text: " Nous identifions et référençons les acteurs du réseau, chaque membre est encouragé à créer un profil détaillé mettant avant ses compétences, ses domaines d’expertises, cette richesse d’informations permet une identification précise des compétences disponible au sein du réseau .",
        img: Icon1,
    },
    {
        id: 2,
        title: " Réseau d’affaires",
        text: "nous mettons l’accent sur la construction d’un réseau international solide, favorisant la collaboration, les opportunités et les partenariats entre les entreprises locales et internationales intéressée par le marché africain.",
        img: Icon2,
    },
    {
        id: 3,
        title: "Mise en relation",
        text: "Nous facilitons les mises en relation stratégiques en identifiant les synergies entre les acteurs du réseau et nos partenaires. Ce qui conduit très souvent à des collaborations fructueuses et des opportunités d’investissements .",
        img: Icon3,
    },
    {
        id: 4,
        title: "Accès au marché Africain",
        text: "nous vous fournissons des contacts utiles et informations précieuses de la région visée, ainsi que des opportunités de développement économique.",
        img: Icon4,
    },
]