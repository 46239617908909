import React, { useState } from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { NavLink, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/router";
import { useGetConditionsQuery } from "../../../../utils/api/conditions/condition.api";
import { Conditions } from "../../../../utils/api/conditions/condition.type";
import AddAdminModal from "../../modals/adminModal/AddAdminModal";
import AddDeliveryMode from "../../modals/adminModal/AddDeliveryMode";
import AddPaymentMode from "../../modals/adminModal/AddPaymentMode";
import Abonnements from "./Abonnements";
import AdminList from "./AdminList";
import BannerSlider from "./BannerSlider";
import ConditionGenerale from "./ConditionGenerale";
import DeliveryMode from "./DeliveryMode";
import MentionLegalesAdmin from "./MentionLegalesAdmin";
import Offres from "./Offres";
import PaymentMode from "./PaymentMode";
import PersonalInformation from "./PersonalInformation";
import PolitiqueConfidentialiteAdmin from "./PolitiqueConfidentialiteAdmin";
import "./SettingAdmin.css";
import SocialMedia from "./SocialMedia";

const SettingAdmin = () => {
	const { data: conditions = [], isLoading } = useGetConditionsQuery();
	const [word, setWord] = useState<string>("");
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

	React.useEffect(() => {
		if (!user?.parameter && !is_SuperAdmin) {
			setTimeout(() => {
				navigate(-1);
			}, 50);
		}
	}, [user]);
	let cgu = conditions?.filter((item: Conditions) => item?.type === "cgu")[0] || {};
	let pc = conditions?.filter((item: Conditions) => item?.type === "pc")[0] || {};
	let ml = conditions?.filter((item: Conditions) => item?.type === "ml")[0] || {};

	return (
		<div className="bloc-entete-admin-container position-relative">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container">
					<h1 className="admin-page-title">Paramètres</h1>
				</div>
			</div>
			<div>
				<ul
					className="nav nav-tabs nav-tabs-message-admin ul-boots-content"
					id="myTab"
					role="tablist"
				>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link active navlink-setting-admin "
							id="info-perso-tab"
							data-bs-toggle="tab"
							data-bs-target="#info-perso"
							type="button"
							role="tab"
							aria-controls="info-perso"
							aria-selected="true"
						>
							Informations
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="administrateur-tab"
							data-bs-toggle="tab"
							data-bs-target="#administrateur"
							type="button"
							role="tab"
							aria-controls="administrateur"
							aria-selected="false"
						>
							Administrateurs
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="paymentMode-tab"
							data-bs-toggle="tab"
							data-bs-target="#paymentMode"
							type="button"
							role="tab"
							aria-controls="paymentMode"
							aria-selected="false"
						>
							Moyens de paiement
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="deliveryMode-tab"
							data-bs-toggle="tab"
							data-bs-target="#deliveryMode"
							type="button"
							role="tab"
							aria-controls="deliveryMode"
							aria-selected="false"
						>
							Modes de livraison
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="abonnement-tab"
							data-bs-toggle="tab"
							data-bs-target="#abonnement"
							type="button"
							role="tab"
							aria-controls="abonnement"
							aria-selected="false"
						>
							Abonnements
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="cgu-tab"
							data-bs-toggle="tab"
							data-bs-target="#cgu"
							type="button"
							role="tab"
							aria-controls="cgu"
							aria-selected="false"
						>
							Conditions générales d'utilisation
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="confidentialite-tab"
							data-bs-toggle="tab"
							data-bs-target="#confidentialite"
							type="button"
							role="tab"
							aria-controls="confidentialite"
							aria-selected="false"
						>
							Politique de confidentialité
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="mentions-tab"
							data-bs-toggle="tab"
							data-bs-target="#mentions"
							type="button"
							role="tab"
							aria-controls="mentions"
							aria-selected="false"
						>
							Mentions légales
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="slider-tab"
							data-bs-toggle="tab"
							data-bs-target="#slider"
							type="button"
							role="tab"
							aria-controls="slider"
							aria-selected="false"
						>
							Slider
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="reseaux-tab"
							data-bs-toggle="tab"
							data-bs-target="#reseaux"
							type="button"
							role="tab"
							aria-controls="reseaux"
							aria-selected="false"
						>
							Réseaux Sociaux
						</button>
					</li>
					<li className="nav-item mb-3" role="presentation">
						<button
							className="nav-link navlink-setting-admin"
							id="offre-tab"
							data-bs-toggle="tab"
							data-bs-target="#offre"
							type="button"
							role="tab"
							aria-controls="offre"
							aria-selected="false"
						>
							Offre des boosts
						</button>
					</li>
				</ul>
				<div className="tab-content mt-4" id="myTabContent">
					<div
						className="tab-pane fade show active"
						id="info-perso"
						role="tabpanel"
						aria-labelledby="info-perso-tab"
					>
						<PersonalInformation />
					</div>
					<div
						className="tab-pane fade tab-pane-admin"
						id="administrateur"
						role="tabpanel"
						aria-labelledby="administrateur-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<div className="container-input-search-admin">
									<div className="search-box-admin">
										<input
											type="text"
											className="search-input-admin"
											placeholder="Recherche"
											onChange={(e) =>
												setWord(e.target.value)
											}
										/>

										<button className="search-button-admin">
											<i className="fas fa-search"></i>
										</button>
									</div>
								</div>
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#AddAdminModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter un administrateur
									</span>
								</button>
							</div>
						</div>
						<AdminList word={word} />
					</div>
					<div
						className="tab-pane fade tab-pane-admin"
						id="paymentMode"
						role="tabpanel"
						aria-labelledby="paymentMode-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#AddPaymentModeModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter un moyen de paiement
									</span>
								</button>
							</div>
						</div>
						<PaymentMode />
					</div>
					<div
						className="tab-pane fade tab-pane-admin"
						id="deliveryMode"
						role="tabpanel"
						aria-labelledby="deliveryMode-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#AddDeliveryModeModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter un mode de livraison
									</span>
								</button>
							</div>
						</div>
						<DeliveryMode />
					</div>
					<div
						className="tab-pane fade tab-pane-admin"
						id="abonnement"
						role="tabpanel"
						aria-labelledby="abonnement-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#addAbonnementModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter un abonnement
									</span>
								</button>
							</div>
						</div>
						<Abonnements />
					</div>
					<div
						className="tab-pane fade"
						id="cgu"
						role="tabpanel"
						aria-labelledby="cgu-tab"
					>
						<ConditionGenerale
							contenu={cgu}
							isContent={!cgu?.id ? false : true}
						/>
					</div>
					<div
						className="tab-pane fade"
						id="confidentialite"
						role="tabpanel"
						aria-labelledby="confidentialite-tab"
					>
						<PolitiqueConfidentialiteAdmin
							contenu={pc}
							isContent={!pc?.id ? false : true}
						/>
					</div>
					<div
						className="tab-pane fade"
						id="mentions"
						role="tabpanel"
						aria-labelledby="mentions-tab"
					>
						<MentionLegalesAdmin
							contenu={ml}
							isContent={!ml?.id ? false : true}
						/>
					</div>
					<div
						className="tab-pane fade"
						id="slider"
						role="tabpanel"
						aria-labelledby="slider-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#AddSlideModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter une slide
									</span>
								</button>
							</div>
						</div>
						<BannerSlider />
					</div>
					<div
						className="tab-pane fade"
						id="reseaux"
						role="tabpanel"
						aria-labelledby="social-tab"
					>
						<SocialMedia />
					</div>
					<div
						className="tab-pane fade"
						id="offre"
						role="tabpanel"
						aria-labelledby="offre-tab"
					>
						<div className="bloc-btn-add-tabs">
							<div className="bloc-right-container gap-3">
								<button
									className="btn btn-theme p-2"
									data-bs-toggle="modal"
									data-bs-target="#addBoostOfferModal"
								>
									<MdOutlineAddCircleOutline />
									<span className="ps-2">
										Ajouter une offre
									</span>
								</button>
							</div>
						</div>
						<Offres />
					</div>
				</div>
			</div>
			<div
				className="modal fade"
				id="AddAdminModal"
				aria-labelledby="AddAdminModalLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
			>
				<AddAdminModal />
			</div>
			<div
				className="modal fade"
				id="AddPaymentModeModal"
				aria-labelledby="AddPaymentModeLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
			>
				<AddPaymentMode />
			</div>
			<div
				className="modal fade"
				id="AddDeliveryModeModal"
				aria-labelledby="AddDeliveryModeLabel"
				aria-hidden="true"
				data-bs-backdrop="static"
			>
				<AddDeliveryMode />
			</div>
		</div>
	);
};

export default SettingAdmin;
