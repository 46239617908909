import React, { useEffect, useState } from "react";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import TitlePage from "../MyCustumerArea/TitlePage";
import "./RejoindreReseau.css";
import { BtnSubmit } from "../../../common/Button";
import useRejoindreReseau from "./useRejoindreReseau";
import ErrorMessage from "../../../common/ErrorMessage";
import GoogleInput from "../../../common/GoogleInput";
import { useLazyGetNetworkByUserQuery } from "../../../../utils/api/network/network.api";
import { INetwork } from "../../../../utils/api/network/network.type";
import { useAppSelector } from "../../../../redux/hooks";
import LoaderSuspense from "../../../common/Loader";
import { AlertInfo } from "../../../common/Alert";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import SouscriptionProviderModal from "../../modals/commercant/commandes/SouscriptionProviderModal";
import ReactQuill from "react-quill";
import PhoneInput from "react-phone-input-2";

const RejoindreReseau = () => {
	// const [images, setImages] = useState<File[]>([]);
	const {
		register,
		onSubmit,
		errors,
		setValue,
		pro,
		country,
		state,
		city,
		handleCountrySelect,
		handleCitySelect,
		handleStateSelect,
		isShowSocialInput,
		handleShowSocialInput,
		handleChangeDescription,
		isValidDescription,
		phone,
		setPhone,
	} = useRejoindreReseau();
	const { user, isCandidateToBePro, isLoad, isshowForm, isMember } =
		useCheckIfCurrentUserIsPro();
	// const props = {
	// 	setValue,
	// 	setImages,
	// };
	useEffect(() => {
		if (user) {
			setValue("user", user?.id);
		}
	}, [user]);
	// console.log({ isCandidateToBePro });

	return (
		<div className="project-listing-component">
			<FrontHeader />
			<SouscriptionProviderModal pro={pro} />
			<div className="fixed-after-header">
				<TitlePage label="Intégrer l'écosystème" />

				<section className="content-page-container">
					<div className="container-munngano">
						{(isLoad || !isshowForm) && <LoaderSuspense />}
						{!isLoad &&
							isCandidateToBePro &&
							isCandidateToBePro.paid === true &&
							!isCandidateToBePro?.valid && (
								<div className="py-5 my-4">
									<AlertInfo message="Votre requête est en cours d'étude par l'équipe de Munngano" />
								</div>
							)}
						{isMember && (
							<div className="py-5 my-4">
								<AlertInfo message="Vous faites parti du réseau en tant que prestataire" />
							</div>
						)}
						{!isLoad && !isCandidateToBePro && isshowForm && (
							<div className="form-container px-3">
								<form onSubmit={onSubmit}>
									<div className="row mb-4">
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="raison_sociale"
												className="espace-client-form-label"
												aria-labelledby="raison_sociale"
											>
												Raison Sociale
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Raison Sociale"
												id="raison_sociale"
												{...register(
													"raison_sociale"
												)}
												data-testid="titleProId"
											/>
											{errors.raison_sociale
												?.message && (
												<ErrorMessage
													message={
														errors
															.raison_sociale
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="siret"
												className="espace-client-form-label"
												aria-labelledby="siret"
											>
												Numero d'identification
												nationale
												<span
													style={{
														fontWeight: 400,
														fontSize: 16,
													}}
												>
													(Siret/Ninea)
												</span>
												<span className="text-danger">
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Siret/Ninea"
												id="siret"
												{...register("siret")}
												data-testid="siretId"
											/>
											{errors.siret?.message && (
												<ErrorMessage
													message={
														errors.siret
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="nom"
												className="espace-client-form-label"
												aria-labelledby="nom"
											>
												Nom du responsable
												<span className="text-danger">
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Nom du responsable"
												id="nom"
												{...register("nom")}
												data-testid="nomId"
												defaultValue={
													user?.lastname
												}
											/>
											{errors.nom?.message && (
												<ErrorMessage
													message={
														errors.nom
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="prenom"
												className="espace-client-form-label"
												aria-labelledby="prenom"
											>
												Prénom du responsable
												<span className="text-danger">
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Prénom du responsable"
												id="prenom"
												{...register("prenom")}
												data-testid="prenomId"
												defaultValue={
													user?.firstname
												}
											/>
											{errors.prenom?.message && (
												<ErrorMessage
													message={
														errors.prenom
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="fonction"
												className="espace-client-form-label"
												aria-labelledby="fonction"
											>
												Fonction
												<span className="text-danger">
													*
												</span>
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Fonction"
												id="fonction"
												{...register("fonction")}
												data-testid="fonctionId"
											/>
											{errors.fonction?.message && (
												<ErrorMessage
													message={
														errors.fonction
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="fonction"
												className="espace-client-form-label"
												aria-labelledby="fonction"
											>
												Secteur d'activité
												{/* <span className="text-danger">
												*
											</span> */}
											</label>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Secteur d'activité"
												id="fonction"
												{...register("metier")}
												data-testid="metierId"
											/>
											{errors.fonction?.message && (
												<ErrorMessage
													message={
														errors.fonction
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="city"
												className="espace-client-form-label"
											>
												Adresse
											</label>
											<span className="text-danger">
												*
											</span>
											<GoogleInput
												value={""}
												onChange={(value) => {
													console.log(value);
													setValue(
														"adress",
														value?.rue
													);
													setValue(
														"longitude",
														value?.longitude
													);
													setValue(
														"latitude",
														value?.latitude
													);
												}}
												className="form-control espace-client-form-control"
											/>
											{errors.adress?.message && (
												<ErrorMessage
													message={
														errors.adress
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="code_postal"
												className="espace-client-form-label"
											>
												Code Postal
											</label>
											<span className="text-danger">
												*
											</span>
											<input
												type="text"
												className="form-control espace-client-form-control"
												placeholder="Code Postal"
												id="code_postal"
												{...register("code_postal")}
												data-testid="codePostalId"
											/>
											{errors.code_postal?.message && (
												<ErrorMessage
													message={
														errors
															.code_postal
															.message
													}
												/>
											)}
										</div>
										<div className="row">
											<div className="col-md-4 form-group mb-4">
												<label
													htmlFor="experiences"
													className="espace-client-form-label"
													aria-labelledby="experiences"
												>
													Pays
												</label>
												<CountrySelector
													onChange={
														handleCountrySelect
													}
													name="country"
													placeholder="Pays"
													value={country}
													containerClass="tx country-select-container p-0"
												/>
											</div>
											<div className="col-md-4 form-group mb-4">
												<label
													htmlFor="experiences"
													className="espace-client-form-label"
													aria-labelledby="experiences"
												>
													Région
												</label>
												<StateSelector
													country={country}
													name="state"
													value={state}
													countryPlaceholder="Region"
													onChange={
														handleStateSelect
													}
													containerClass="tx country-select-container p-0"
												/>
												{errors.region?.message && (
													<ErrorMessage
														message={
															errors
																.region
																.message
														}
													/>
												)}
											</div>
											<div className="col-md-4 form-group mb-4">
												<label
													htmlFor="Ville"
													className="espace-client-form-label"
													aria-labelledby="experiences"
												>
													Ville
												</label>
												<CitySelector
													state={state}
													name="city"
													value={city}
													statePlaceholder="Ville"
													onChange={
														handleCitySelect
													}
													containerClass="tx country-select-container p-0"
												/>
												{errors.city?.message && (
													<ErrorMessage
														message={
															errors
																.city
																.message
														}
													/>
												)}
											</div>
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="tel"
												className="espace-client-form-label"
											>
												Téléphone
												<span className="text-danger">
													*
												</span>
											</label>
											<PhoneInput
												country={"sn"}
												value={phone}
												onChange={(phone) => {
													setPhone(phone);
													setValue(
														"telephone",
														phone.slice(3)
													);
												}}
												masks={{
													sn: ".. ...-..-..",
													fr: ".. ..-..-..-..",
												}}
												onlyCountries={["fr", "sn"]}
												inputClass="form-control espace-client-form-control w-100 h-auto"
											/>

											{errors.telephone?.message && (
												<ErrorMessage
													message={
														errors.telephone
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="portable"
												className="espace-client-form-label"
											>
												Portable
											</label>
											<input
												type="tel"
												className="form-control espace-client-form-control"
												placeholder="Portable"
												id="portable"
												{...register("portable")}
												data-testid="portablesId"
											/>
											{errors.portable?.message && (
												<ErrorMessage
													message={
														errors.portable
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group">
											<label
												htmlFor="expertise-pro"
												className="espace-client-form-label"
											>
												Description
												<span
													style={{
														fontSize: "14px",
														opacity: 0.7,
													}}
												>
													(Qui vous êtes, ce que
													vous faites)
												</span>
											</label>
											<ReactQuill
												className="editor-cgu"
												defaultValue={""}
												onChange={
													handleChangeDescription
												}
											/>
											<small
												className={`${
													isValidDescription
														? "text-success"
														: ""
												}`}
												style={{
													fontWeight: "normal",
												}}
											>
												minimum 100 caractères
											</small>
											{errors.expertise_pro
												?.message && (
												<ErrorMessage
													message={
														errors
															.expertise_pro
															.message
													}
												/>
											)}
										</div>
										<div className="col-12 mb-4 form-group form-check">
											<input
												type="checkbox"
												className="form-check-input checkbox-input-custom"
												id="have_social"
												{...register("have_social")}
												value="false"
												data-testid="have_social"
												onChange={
													handleShowSocialInput
												}
											/>
											<label
												htmlFor="have_social"
												className="espace-client-form-label"
											>
												Voulez-vous renseigner un
												réseau social
												<span
													style={{
														fontSize: "14px",
														opacity: 0.7,
													}}
												>
													(Ajouter un reseau
													permettra de traiter
													votre demande dans les
													plus brefs délais)
												</span>
											</label>
										</div>
										{isShowSocialInput && (
											<>
												<div className="col-md-6 form-group mb-4">
													<label
														htmlFor="linkedin"
														className="espace-client-form-label"
													>
														Linkedin du pro
													</label>
													<input
														type="url"
														className="form-control espace-client-form-control"
														id="linkedin"
														placeholder="linkedin.com"
														{...register(
															"linkedin"
														)}
														data-testid="linkedinProId"
													/>
													{errors.linkedin
														?.message && (
														<ErrorMessage
															message={
																errors
																	.linkedin
																	.message
															}
														/>
													)}
												</div>
												<div className="col-md-6 form-group mb-4">
													<label
														htmlFor="facebook"
														className="espace-client-form-label"
													>
														Facebook du pro
													</label>
													<input
														type="url"
														className="form-control espace-client-form-control"
														id="facebook"
														placeholder="facebook.com"
														{...register(
															"facebook"
														)}
														data-testid="facebookProId"
													/>
													{errors.facebook
														?.message && (
														<ErrorMessage
															message={
																errors
																	.facebook
																	.message
															}
														/>
													)}
												</div>
												<div className="col-md-6 form-group mb-4">
													<label
														htmlFor="twitter"
														className="espace-client-form-label"
													>
														Twitter du pro
													</label>
													<input
														type="url"
														className="form-control espace-client-form-control"
														id="twitter"
														placeholder="twitter.com"
														{...register(
															"twitter"
														)}
														data-testid="twitterProId"
													/>
													{errors.twitter
														?.message && (
														<ErrorMessage
															message={
																errors
																	.twitter
																	.message
															}
														/>
													)}
												</div>
												<div className="col-md-6 form-group mb-4">
													<label
														htmlFor="instagram"
														className="espace-client-form-label"
													>
														Instagram du pro
													</label>
													<input
														type="url"
														className="form-control espace-client-form-control"
														id="instagram"
														placeholder="instagram.com"
														{...register(
															"instagram"
														)}
														data-testid="instagramProId"
													/>
													{errors.instagram
														?.message && (
														<ErrorMessage
															message={
																errors
																	.instagram
																	.message
															}
														/>
													)}
												</div>
											</>
										)}

										<div className="col-md-4 offset-md-8 mt-5">
											<BtnSubmit
												label="Rejoindre"
												isLoading={false}
											/>
										</div>
									</div>
								</form>
							</div>
						)}
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export const useCheckIfCurrentUserIsPro = () => {
	const [pros, setPros] = useState<INetwork[]>([]);
	const [isCandidateToBePro, setIsCandidateToBePro] = useState<INetwork>();
	const { user } = useAppSelector((state) => state.user);
	const [getNetWorkByUser, { data, isLoading: isLoad }] = useLazyGetNetworkByUserQuery();
	const [isshowForm, setIsshowForm] = useState<boolean>(false);
	useEffect(() => {
		if (!pros?.length) {
			getNetWorkByUser(user?.slug);
		}
	}, [pros]);
	useEffect(() => {
		if (data) {
			setPros(data);
		}
	}, [data]);
	useEffect(() => {
		if (pros?.length) {
			const isCandidateToBePro = pros?.find((pro) => pro?.user?.id === user?.id);
			setIsCandidateToBePro(isCandidateToBePro);
		}
	}, [pros]);
	useEffect(() => {
		if(!isLoad){
			setTimeout(() => {
				setIsshowForm(true);
			}, 500);
		}
	}, [isLoad]);
	return {
		pros,
		isCandidateToBePro,
		user,
		isLoad,
		isshowForm,
		isMember:
			!isLoad &&
			isCandidateToBePro &&
			isCandidateToBePro.paid === true &&
			isCandidateToBePro?.valid,
	};
};
export default RejoindreReseau;
