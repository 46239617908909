import React from 'react'
import { BtnSubmit } from '../../../../common/Button'
import UseFormChangePassword from '../UseFormMyCustomArea/UseFormChangePassword'
import ErrorMessage from '../../../../common/ErrorMessage'

const ChangePassword = () => {
  const { register, onSubmit, errors, isLoading } = UseFormChangePassword()
  return (
    <>
      <div className="bloc-title-page-tab pb-6 mt-5">
        <div>
          <p className="titre-page-tab-content">Changer votre mot de passe</p>
        </div>
      </div>
      <div className="statistique-page-content mt-5">
        <form onSubmit={onSubmit}>
          <div className="row">
            <div className="col-md-6 mb-3">
              <label
                className="form-label form-label-tabs"
                htmlFor="oldPassword"
              >
                Ancien mot de passe
              </label>
              <input
                type="password"
                className="form-control form-control-input-tabs"
                placeholder="Ancien mot de passe"
                id="oldPassword"
                {...register('old_password')}
              />
              {errors.old_password?.message && (
                <ErrorMessage message={errors.old_password.message} />
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label
                className="form-label form-label-tabs"
                htmlFor="new password"
              >
                Nouveau mot de passe
              </label>
              <input
                type="password"
                className="form-control form-control-input-tabs"
                placeholder="Nouveau mot de passe"
                id="new password"
                {...register('new_password')}
              />
              {errors.new_password?.message && (
                <ErrorMessage message={errors.new_password.message} />
              )}
            </div>
            <div className="col-md-6 mb-3">
              <label
                className="form-label form-label-tabs"
                htmlFor="confirmPassord"
              >
                Confirmer le mot de passe
              </label>
              <input
                type="password"
                className="form-control form-control-input-tabs"
                placeholder="Confirmer le mot de passe"
                id="confirmPassord"
                {...register('confirm_password')}
              />
              {errors.confirm_password?.message && (
                <ErrorMessage message={errors.confirm_password.message} />
              )}
            </div>
            <div className="col-md-6 offset-md-6 col-xl-4 offset-xl-8 pt-5">
              <BtnSubmit
                label="Changer le mot de passe"
                isLoading={isLoading}
              />
            </div>
          </div>
        </form>
      </div>
    </>
  )
}

export default ChangePassword
