import moment from 'moment'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import VolkenoSkeleton from 'volkeno-react-skeleton'
import { useAppSelector } from '../../../../redux/hooks'
import {
  useGetNotifByAdminQuery,
  useReadNotificationMutation,
} from '../../../../utils/api/notification/notification.api'
import { EmptyMessage } from '../../../../utils/EmptyMessage'
import { CustomPagination } from '../../Tables/Admin/CommandesListAdminTable/CommandesListAdminTable'

function NotificationsAdmin() {
  const navigate = useNavigate()
  const [
    readMessage,
    { isSuccess, isError, error },
  ] = useReadNotificationMutation()
  const [page, setPage] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const [firstIndex, setFirstIndex] = React.useState(0)
  const [lastIndex, setLastIndex] = React.useState(0)
  const { user } = useAppSelector((s) => s?.user)
  const { data: notifications = [], isLoading } = useGetNotifByAdminQuery(
    user?.slug,
  )

  React.useEffect(() => {
    if (isSuccess) {
      console.log('success')
    }

    if (isError) {
      console.error('error', error)
    }
  }, [isSuccess, isError])

  React.useEffect(() => {
    if (notifications?.length) {
      const indexOfLastPost = page * perPage
      const indexOfFirstPage = indexOfLastPost - perPage
      setFirstIndex(indexOfFirstPage)
      setLastIndex(indexOfLastPost)
    }
  }, [notifications, page, perPage])

  const handleClick = (page, perPage) => {
    setPage(page)
    setPerPage(perPage)
  }

  const onRedirect = (notif) => {
    if (notif?.read === false) {
      readMessage({ slug: notif?.slug, read: true })
    }
    if (notif?.notif_type === 'soumission_prestataire') {
      return navigate(`/app/admin/professionnels/?numero=${notif?.data?.identity_code}`);
    }
    if (notif?.notif_type === 'soumission_commerce') {
      return navigate(`/app/admin/boutiques?search=${notif?.data?.name}`);
    }

    return navigate('/admin/tableau-de-bord')
  }
  return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container">
					<h1 className="admin-page-title">Liste des notifications</h1>
				</div>
			</div>
			<div>
				{isLoading && <NotificationSkeleton />}
				{!isLoading &&
					(notifications?.length ? (
						notifications?.slice(firstIndex, lastIndex)?.map((notif) => (
							<div
								className="row notification-container pt-2 align-items-center"
								key={notif?.slug}
							>
								<div className="col-xl-2 col-lg-2 col-md-2 pb-3">
									<div>
										<p className="notification-contenu-tabs">
											{moment(
												notif?.created_at
											)?.format("DD/MM/YYYY")}
										</p>
									</div>
								</div>
								<div className="col-md-10 col-md-10 col-md-10 pb-3">
									<div className="bloc-bg-notification-tabs">
										<div className="d-flex align-items-center">
											{notif?.read === true ? null : (
												<div className="statut-notificattion"></div>
											)}

											<p
												className="activite-annonce-notification ps-3 linkable"
												onClick={(e) => {
													e.preventDefault();
													onRedirect(notif);
												}}
											>
												{notif?.content}
											</p>
										</div>
									</div>
								</div>
							</div>
						))
					) : (
						<div>
							{EmptyMessage(
								"Aucune notification disponible pour le moment"
							)}
						</div>
					))}
			</div>
			{notifications?.length > 0 && (
				<CustomPagination
					nbPages={notifications?.length}
					page={page}
					onChange={(page, perPage) => handleClick(page, perPage)}
					perPage={perPage}
				/>
			)}
		</div>
  );
}

export default NotificationsAdmin

const NotificationSkeleton = () => {
  return (
    <>
      {[...Array(6)].map((elem, i) => (
        <div
          className="row notification-container pt-2 align-items-center"
          key={i}
        >
          <div className="col-xl-2 col-lg-2 col-md-2 pb-3">
            <div>
              <VolkenoSkeleton
                variant="rect"
                height={25}
                width="100%"
                style={{ backgroundColor: '#fafafa' }}
              />
            </div>
          </div>
          <div className="col-md-10 col-md-10 col-md-10 pb-3">
            <div className="bloc-bg-notification-tabs">
              <div className="d-flex align-items-center">
                <VolkenoSkeleton variant="rect" height={25} width="100%" />
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
