import React, { useEffect, useState } from "react";
import BudgetsSkeleton from "./BudgetsTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import {
  IconDelete2,
  IconDeleteDanger,
  IconEdit,
} from "../../../../shared/Icons";
import moment from "moment";
import Swal from "sweetalert2";
import { IBudget } from "../../../../../utils/api/budget/budget.type";
import {
  useDeleteBudgetMutation,
  useLazyGetBudgetByUserQuery,
} from "../../../../../utils/api/budget/budget.api";
import EditBudgetModal from "../../../modals/Budget/EditBudgetModal";

function BudgetsTable() {
  const [budgets, setBudgets] = useState<IBudget[]>([]);
  const [budget, setBudget] = useState<IBudget[]>([]);
  const [getBudgetByUser, { data, isLoading }] = useLazyGetBudgetByUserQuery();
  const [deleteBudget, { data: delBudget }] = useDeleteBudgetMutation();
  const onDeleteItem = (slug) => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cet budget",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn mx-2",
      },
      preConfirm: () => {
        return deleteBudget(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  };
  useEffect(() => {
    if (!budgets.length) {
      getBudgetByUser();
    }
  }, [budgets]);
  useEffect(() => {
    if (data) {
      setBudgets(data);
    }
  }, [data]);
  const dateFormatter = (cell, row) => {
    return moment(cell).format("DD/MM/YYYY");
  };

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-projet">
        <button
          data-bs-toggle="modal"
          data-bs-target="#editBudgetModal"
          className="no-style-btn"
          onClick={() => setBudget(row)}
        >
          {<IconEdit />}
        </button>
        <button
          className="no-style-btn"
          title="Supprimer"
          onClick={() => onDeleteItem(row?.slug)}
        >
          {<IconDelete2 />}
        </button>
      </div>
    );
  };

  const columns = [
    {
      dataField: "name",
      text: "Nom",
      style: { textAlign: "left" },
    },
    {
      dataField: "created_at",
      text: "Crée",
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: "slug",
      text: "Action",
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <BudgetsSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={budgets || []}
          columns={columns}
          striped
          noDataIndication="Aucune donnée disponible"
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive seller-table"
          rowStyle={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="header-class"
        />
      )}
      <EditBudgetModal budget={budget} />
    </>
  );
}

export default BudgetsTable;
