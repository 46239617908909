import Pusher from "pusher-js";
import React from "react";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { ChatApi, useChatByUserQuery } from "./api/chat/chat.api";
import { Chat } from "./api/chat/chat.type";

// Enable pusher logging - don't include this in production
Pusher.logToConsole = false;

const pusher = new Pusher("d9e2beb7c5abdb5b8886", {
	cluster: "eu",
});

export const useChatListener = () => {
	const dispatch = useAppDispatch();
	useChatByUserQuery();
	const user = useAppSelector((s) => s.user.user);
	usePusher(
		"chat",
		({ data }: { data: Chat }) => {
			if (data.receiver.id === user?.id) {
				dispatch(ChatApi.util.invalidateTags(["chat"]));
				console.log("hello");
			}
		},
		user?.id
	);
};

const usePusher = (eventName: string, cb: (data: any) => any, userId?: number) => {
	React.useEffect(() => {
		const channel = pusher.subscribe("my-channel");
		const subscription = channel.bind(eventName, (data: any) => {
			cb(data);
		});
		return () => {
			try {
				if (subscription.subscribed) {
					subscription.unsubscribe();
				} else if (subscription.subscriptionPending) {
					subscription.cancelSubscription();
				}
			} catch (ex) {
				console.log("erorr ", ex);
			}
		};
	}, [userId]);
};
