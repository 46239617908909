import React from "react";
import SocialBoostedAnnouncementTable from "../../Tables/Admin/SocialBoostedAnnouncementTable/SocialBoostedAnnouncementTable";
import TopAnnoncesTable from "../../Tables/Admin/TopAnnoncesTable/TopAnnoncesTable";

const BoostedAnnoucements = () => {
	return (
		<div>
			<h2 className="boosted-sous-title">Boost réseaux sociaux</h2>
			<SocialBoostedAnnouncementTable />

			<h2 className="boosted-sous-title mt-4">Top annonces</h2>
			<TopAnnoncesTable />
		</div>
	);
};

export default BoostedAnnoucements;
