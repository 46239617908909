import TestimonialListTable from "../../Tables/Admin/Testimonial/TestimonialListTable";

const Testimonials = () => {
	return (
		<>
			<TestimonialListTable />
		</>
	);
};

export default Testimonials;
