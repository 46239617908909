import moment from "moment";
import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FaRegCalendarAlt } from "react-icons/fa";
import { MdDeleteForever, MdEmail } from "react-icons/md";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { IUser } from "../../../../../redux/slice/User.slice";
import { useUserByFilterQuery } from "../../../../../utils/api/user/user.api";
import { getAvatar } from "../../../../../utils/utils";
import Pagination from "../../../../common/Pagination";
import AddUserModal from "../../../modals/adminModal/AddUserModal";
import { useDeleteUser } from "../../../modals/adminModal/forms/useUserForm";
import UserListTableSkeleton from "./UserListTableSkeleton";

function UserListTable({ search: word }: { search: string }) {
	const {data, perPage, page, setPerPage, setPage, isLoading, total} = useUserList(word)

	const mailFormatter = (cell: any, row: IUser) => {
		return (
			<>
				<span style={{ fontSize: 18 }}>
					<MdEmail style={{ color: "#FFA73C" }} />{" "}
				</span>
				<span className="ps-1">{row.email}</span>
			</>
		);
	};
	const dateInscriptionFormatter = (cell: any, row: IUser) => {
		return (
			<>
				<span style={{ fontSize: 18 }}>
					<FaRegCalendarAlt style={{ color: "#03A89E" }} />{" "}
				</span>
				<span className="ps-1">
					{row.created_at ? moment(row.created_at).format("DD/MM/YYYY") : "-"}
				</span>
			</>
		);
	};

	const nameFormatter = (cell: any, row: IUser) => {
		return (
			<div>
				<img
					src={getAvatar(row.avatar)}
					alt="Produit"
					className="img-produit-table-admin"
				/>
				<span className="ps-2">
					{row.firstname} {row.lastname}
				</span>
			</div>
		);
	};

	const actionFormatter: any = (cell, row: IUser) => {
		const modalId = `UpdateUser${row.id}`;
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<AddUserModal modalId={modalId} user={row} />
				<div>
					<button
						data-bs-toggle="modal"
						data-bs-target={`#${modalId}`}
						className="btn btn-action-user-admin"
						title="Editer"
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteUserButton user={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom_user",
			text: "Noms",
			formatter: (cell: any, row: IUser) => nameFormatter(cell, row),
			style: { textAlign: "left" },
		},
		{
			dataField: "phone",
			text: "Téléphone",
		},
		{
			dataField: "mail",
			text: "Email",
			formatter: (cell: any, row: IUser) => mailFormatter(cell, row),
		},
		{
			dataField: "date_inscription",
			text: "Date d’inscription",
			formatter: (cell: any, row: IUser) => dateInscriptionFormatter(cell, row),
		},
		,
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: IUser) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <UserListTableSkeleton />}
			{!isLoading && (
        <>
        <h3 className="total-items">{total} résultats</h3>
				<BootstrapTable
					keyField="id"
					data={data.results}
					columns={columns}
					bordered={true}
					condensed={false}
					responsive={true}
					noDataIndication={() => "Pas de résultats"}
					wrapperClasses="table-responsive admin-table"
				/>
        </>
			)}
			<CustomPagination
				nbPages={data.count}
				page={page}
				onChange={(page, perPage) => {
					setPerPage(perPage);
					setPage(page);
				}}
				perPage={perPage}
			/>
		</>
	);
}

const useUserList = (word:string) => {
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);
	const { data = { results: [] }, isLoading } = useUserByFilterQuery({
		word,
		page,
		limit: perPage,
		profil: "user",
	});
	useEffect(() => {
		setPage(1);
	}, [word]);
  return {
    isLoading,
    data,
    total: data.count,
    perPage,
    setPerPage,
    page,
    setPage
  }
};

export default UserListTable;

function DeleteUserButton({ user }: { user: IUser }) {
	const onDelete = useDeleteUser(user);

	return (
		<button
			className="btn btn-action-user-admin"
			title={user?.is_archive ? "Désarchiver" : "Archiver"}
			onClick={() => onDelete()}
		>
			{user?.is_archive ? <MdDeleteForever /> : <RiDeleteBin7Fill />}
		</button>
	);
}

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				<select
					className="form-control select-perpage"
					name="limit"
					id="limit"
					value={perPage}
					onChange={(e) => {
						onChange(1, parseInt(e.target.value, 10));
					}}
				>
					<option value="10">10</option>
					<option value="30">30</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
