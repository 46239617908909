import React, { useEffect, useState } from "react";
import { AiTwotoneHeart } from "react-icons/ai";
import StarRatingComponent from "react-star-rating-component";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import Volkeno from "../../../assets/appImages/volkeno.png";
import RecentsProjects from "./RecentsProjects";
import "./ProjectListing.css";
import { createMarkup, formattedDate } from "../../../../utils/utils";
import { IProject } from "../../../../utils/api/project/project.type";
import moment from "moment";
import ApplyForm from "./ApplyForm";
import { useLazyFindProjectBySlugVisitorQuery } from "../../../../utils/api/project/project.api";
import { AlertInfo } from "../../../common/Alert";
import { useAppSelector } from "../../../../redux/hooks";
import {
	useAddFavoriteProjectMutation,
	useDeleteFavoriteProjectMutation,
} from "../../../../utils/api/favoriteProject/favoriteProject.api";
import { useNavigate, useParams } from "react-router-dom";
import { IFavorite } from "../../../../utils/api/favoriteProject/favoriteProject.type";
import { IRateProject } from "../../../../utils/api/rate_project/rateProject.type";
import {
	useAddRateProjectMutation,
	useUpdateRateProjectMutation,
} from "../../../../utils/api/rate_project/rateProject.api";
import { Color } from "../../../../utils/theme";
import { ShareSocialMedia } from "../MarketPlace/MarketPlaceDetail";
import CandidaturesByProject from "./CandidaturesByProject";

const DetailProject = () => {
	const navigate = useNavigate();
	const { token, user } = useAppSelector((s) => s.user);
	const { slug } = useParams();
	const [project, setProject] = useState<IProject>();
	const [isPostule, setIsPostule] = useState<boolean | undefined>(false);
	const [isFavorite, setIsFavorite] = useState<IFavorite | null>(null);
	const [rating, setRating] = useState<number>(0);
	const [isRating, setIsRating] = useState<IRateProject | null>(null);
	const [addFavorite, { isSuccess }] = useAddFavoriteProjectMutation();
	const [deleteFavorite, { isSuccess: isSucdel }] = useDeleteFavoriteProjectMutation();
	const [findProjectBySlug, { data: dataProject, isLoading }] =
		useLazyFindProjectBySlugVisitorQuery();
	const [addRateProject, { isSuccess: isSucRate }] = useAddRateProjectMutation();
	const [updateRateProject, { isSuccess: isSucRateupdate }] = useUpdateRateProjectMutation();
	const isMyProject = user?.id === project?.user?.id;
	useEffect(() => {
		if (slug) {
			findProjectBySlug(slug);
		}
	}, [slug]);
	useEffect(() => {
		if (!project) {
			findProjectBySlug(slug);
		}
	}, [dataProject]);
	useEffect(() => {
		if (dataProject) {
			setProject(dataProject);
		}
	}, [dataProject]);
	useEffect(() => {
		if (project?.rates) {
			const rate =
				project?.rates?.reduce((acc, item) => acc + item?.rating, 0) /
				project?.rates?.length;
			setRating(Math.round(rate));
		}
	}, [project?.rates]);
	useEffect(() => {
		if (project?.rates) {
			const isRating = project?.rates?.find((item) => item?.user === user?.id);
			setIsRating(isRating || null);
		}
	}, [project]);

	useEffect(() => {
		if (isSucdel) {
			findProjectBySlug(slug);
		}
	}, [isSucdel]);
	useEffect(() => {
		if (isSuccess) {
			findProjectBySlug(slug);
		}
	}, [isSuccess]);
	useEffect(() => {
		if (project?.candidatures?.length) {
			const isPostule = project?.candidatures?.some(
				(item) => item?.candidat === user?.id
			);
			setIsPostule(isPostule);
		}
	}, [project?.candidatures]);
	useEffect(() => {
		if (project?.favorites) {
			const isFavorite = project?.favorites?.find((item) => item?.user === user?.id);
			setIsFavorite(isFavorite || null);
		}
	}, [project]);
	useEffect(() => {
		if (isSucRate) {
			findProjectBySlug(slug);
		}
	}, [isSucRate]);
	useEffect(() => {
		if (isSucRateupdate) {
			findProjectBySlug(slug);
		}
	}, [isSucRateupdate]);

	const toggleFavorite = async () => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		if (isMyProject) {
			return null;
		}
		const data = {
			user: user?.id,
			project: project?.id,
		};
		if (!isFavorite) {
			await addFavorite(data);
		} else {
			await deleteFavorite(isFavorite?.slug);
		}
	};
	const handleRateProject = async (index) => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		if (isMyProject) {
			return null;
		}
		if (!isRating) {
			const data = {
				rating: index,
				user: user?.id,
				project: project?.id,
			};
			await addRateProject(data);
		} else {
			await updateRateProject({ slug: isRating?.slug, rating: index });
		}
	};

	return (
		<div>
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="content-page-container">
					<div className="row flex-mobile-col-reverse">
						<div className="col-xl-4 col-lg-4 col-md-4">
							<div className="pt-3">
								<RecentsProjects slug={slug} />
							</div>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-8">
							<div className="col-tab-espace-client py-2">
								<div>
									<div className="flex-sb flex-sb-mobile">
										<div className="d-flex gap-2">
											<h4 className="titre-annonce-projet fw-400">
												{project?.code}
											</h4>
											<h4 className="titre-annonce-projet">
												{project?.title}-
												<span
													className={`${
														project?.type_contract ===
															"cdi" ||
														project?.type_contract ===
															"cdd"
															? "text-uppercase"
															: "text-capitalize"
													}`}
												>
													{
														project?.type_contract
													}
												</span>
											</h4>
										</div>
										<div className="d-flex">
											<div>
												<StarRatingComponent
													name="rate2"
													editing={true}
													starCount={5}
													value={rating}
													starColor={
														Color.themeColor
													}
													emptyStarColor="#CCCCCC"
													onStarClick={(
														index
													) =>
														handleRateProject(
															index
														)
													}
												/>
											</div>
											<button
												className="add-favorite-btn"
												title="Ajouter aux favoris"
												onClick={toggleFavorite}
											>
												<AiTwotoneHeart
													className={`icon-heart-detail-annonce ${
														isFavorite?.slug &&
														"color-theme"
													}`}
												/>
											</button>
										</div>
									</div>
									<div className="row mt-4 b-b-1">
										<div className="col-md-7">
											<div className="flex-sb flex-sb-mobile align-items-center pb-2">
												<div className="d-flex">
													<div>
														<img
															src={
																Volkeno
															}
															alt="Project"
															className="listing-project-detail-img"
														/>
													</div>
												</div>
												<div>
													<p className="texte-detail-annonce-projet">
														À temps plein
													</p>
												</div>
												<div>
													<p className="texte-detail-annonce-projet">
														{moment(
															project?.created_at
														).fromNow()}
													</p>
												</div>
											</div>
										</div>
									</div>
									{!isLoading && (
										<div
											className="mb-5 pt-4 text-description-annonce-detail"
											dangerouslySetInnerHTML={createMarkup(
												project?.description
											)}
										/>
									)}
									<div className="pt-5">
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Grille salariale:
												</p>
											</div>
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-libelle">
													{
														project
															?.budget[0]
															?.name
													}
												</p>
											</div>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Type de contrat:
												</p>
											</div>
											<div className="col-md-4 pb-2">
												<p
													className={`texte-detail-annonce-info-libelle ${
														project?.type_contract ===
															"cdi" ||
														project?.type_contract ===
															"cdd"
															? "text-uppercase"
															: "text-capitalize"
													}`}
												>
													{
														project?.type_contract
													}
												</p>
											</div>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Date d’expiration:
												</p>
											</div>
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-libelle">
													{formattedDate(
														project?.date_expiration
													)}
												</p>
											</div>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Compétences requises:
												</p>
											</div>
											<div
												className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
												dangerouslySetInnerHTML={createMarkup(
													project?.skills
												)}
											/>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Prérequis du projet :
												</p>
											</div>
											<div
												className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
												dangerouslySetInnerHTML={createMarkup(
													project?.prerequisite
												)}
											/>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Exigences du projet:
												</p>
											</div>
											<div
												className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
												dangerouslySetInnerHTML={createMarkup(
													project?.requirements
												)}
											/>
										</div>
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Secteur et Metier:
												</p>
											</div>
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-libelle">
													{
														project?.sector
															?.name
													}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 py-5">
										<ShareSocialMedia
											title={project?.title}
											category="projet"
										/>
									</div>
								</div>
							</div>
							<div className="mt-3">
								<p className="text-center titre-postuler-annonce pt-2 mb-2">
									{token && isMyProject
										? "Candidatures"
										: "Postuler"}
								</p>
							</div>
							{token ? (
								<>
									{!isMyProject ? (
										isPostule ? (
											<AlertInfo message="Vous avez déjà posutulé pour ce projet" />
										) : (
											<ApplyForm
												project={project?.id}
											/>
										)
									) : (
										<>
											<CandidaturesByProject />
										</>
									)}
								</>
							) : (
								<div className="pt-4">
									<AlertInfo message="Pour Pouvoir postuler, vous devez être connecté" />
								</div>
							)}
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default DetailProject;
