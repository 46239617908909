import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { BiCalendar } from "react-icons/bi";

interface props {
	date?: Date;
	setDate: (date: Date) => any;
	id: string;
	placeholder?: string;
}

const CustomDatePicker: React.FC<props> = ({ date, setDate, id, placeholder }) => {
	const customStyle: Object = {
		position: "relative",
	};
	return (
		<div style={customStyle}>
			<DatePicker
				dateFormat={"dd/MM/yyyy"}
				selected={date}
				minDate={new Date()}
				onChange={(date: Date) => setDate(date)}
				id={id}
				className="form-control espace-client-form-control"
				placeholderText={placeholder || "Selectionner une date"}
				showMonthDropdown
				showYearDropdown
			/>
			<BiCalendar
				style={{
					color: "#200E3280",
					fontSize: "24px",
					position: "absolute",
					top: "25%",
					right: "5",
				}}
			/>
		</div>
	);
};

export default CustomDatePicker;
