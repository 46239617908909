import React from "react";
import BootstrapTable from "react-bootstrap-table-next";

import { MdOutlineAddCircleOutline } from "react-icons/md";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import Pagination from "../../../../common/Pagination";
import { FiEye } from "react-icons/fi";
import { AlertInfo } from "../../../../common/Alert";
import TableSkeleton from "../../../../common/TableSkeleton";
import { getAvatar, useModal } from "../../../../../utils/utils";
import AddMemberModal from "../../../modals/adminModal/AddMemberModal";
import DetailMembreEquipeModal from "../../../modals/adminModal/DetailsMembreModal";
import { useDelete } from "../../../admin/Boosted/UseBoosted";
import { useDeleteTeamMutation, useGetTeamsQuery } from "../../../../../utils/api/about/about.api";
import { ITeam } from "../../../../../utils/api/about/about.type";

function TeamListTable() {
	const {
		page,
		setPage,
		isLoading,
		data,
		isShowModal,
		closeModal,
		openEditModal,
		openModal,
		item,
		getItem,
	} = useTeams();

	const photoFormatter = (cell: any, row: any) => {
		return (
			<div>
				<img
					src={getAvatar(cell)}
					alt="Produit"
					className="img-produit-table-admin"
				/>
			</div>
		);
	};

	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						data-bs-toggle="modal"
						data-bs-target={`#DetailMembreModal`}
						onClick={() => getItem(row)}
					>
						<FiEye />
					</button>
				</div>
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						onClick={() => openEditModal(row)}
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteTeamMembre item={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "image",
			text: " Photo",
			style: { textAlign: "left" },
			formatter: (cell: any, row: any) => photoFormatter(cell, row),
		},
		{
			dataField: "nom",
			text: " Noms",
			style: { textAlign: "left", color: "#FFA73C" },
		},
		{
			dataField: "fonction",
			text: "Fonction",
			style: { textAlign: "left" },
		},
		{
			dataField: "facebook",
			text: "Facebook",
		},
		{
			dataField: "twitter",
			text: "Twitter",
			style: { textAlign: "left" },
		},
		{
			dataField: "linkedin",
			text: "Linkedin",
			style: { textAlign: "left" },
		},
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			<div className="flex-r mb-4">
				<button className="btn btn-theme p-2" onClick={openModal}>
					<MdOutlineAddCircleOutline />
					<span className="ps-2">Ajouter un membre</span>
				</button>
			</div>
			{isLoading && <TableSkeleton headers={columns} />}
			{!isLoading && (
				<>
					<BootstrapTable
						keyField="slug"
						identity_code
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={<AlertInfo message="Aucun membre trouvé" />}
					/>
					<Pagination page={page} onPageChange={setPage} total={data?.count} />
				</>
			)}
			<AddMemberModal isOpen={isShowModal} closeModal={closeModal} item={item} />
			<DetailMembreEquipeModal item={item} />
		</>
	);
}

function useTeams() {
	const [page, setPage] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);
	const { isLoading, data = { results: [], count: 0 } } = useGetTeamsQuery({
		page,
		limit: perPage,
	});
	const { isShowModal, closeModal, openModal, item, openEditModal, getItem } =
		useModal<ITeam>();
	return {
		page,
		setPage,
		perPage,
		setPerPage,
		isLoading,
		data,
		isShowModal,
		closeModal,
		openEditModal,
		openModal,
		item,
		getItem,
	};
}

export function DeleteTeamMembre({ item }: { item: ITeam }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteTeamMutation();
	const onDelete = useDelete<ITeam>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cet membre?",
		successMessage: "Membre supprimé",
	});
	return (
		<button className="btn btn-action-user-admin" title="Supprimer" onClick={onDelete}>
			<RiDeleteBin7Fill />
		</button>
	);
}

export default TeamListTable;
