import React from "react";
import { useGetCommerceByAdminQuery, useGetCommercesByVisitorQuery } from "../../../../../utils/api/commerce/commerce.api";
import { Color } from "../../../../../utils/theme";
import { BtnBack, BtnNext } from "../../../../common/Button";
import { CustomSelectInput } from "../../../../common/CustomSelectInput";

const AddBoutique = ({
	navigation,
	register,
	setValue,
	errors,
	setCommerce
}) => {

    const [options, setOptions] = React.useState<any>([]);
	// const { data: commerces, isSuccess } = useGetCommercesByVisitorQuery();
	const { data: commerces, isSuccess } = useGetCommerceByAdminQuery();
	console.log('commerces', commerces)
	React.useEffect(() => {
		if (isSuccess) {
			setOptions(
				commerces?.map((item) => {
					return {
						label: item?.name,
						value: item?.id,
						// subcategories: item?.subcategory,
					};
				})
			);
		}
	}, [commerces, isSuccess]);

    const handleChange = (e) => {
		console.log(
			"value",
			e.target.value,
			options?.find((item) => parseInt(item?.value) === parseInt(e.target.value))
		);
		setValue('commerce',e.target.value)
		setCommerce(options?.find((item) => parseInt(item?.value) === parseInt(e.target.value)));
	}

  return (
    <div>
		<h4 className="stepper-title">Ajouter la boutique </h4>
		<div>
			<div className="row">
				<CustomSelectInput
					label="Choisir une boutique"
					options={options?.sort(function (a, b) {
						return a?.label?.localeCompare(b?.label);
					})}
					required
					onChange={(e) => handleChange(e)}
					// {...register("category")}
					error={errors?.commerce?.message}
					style={{ backgroundColor: Color.bgGray }}
				/>
			</div>
			<div className="flex-r gap-3 mt-4">
				<BtnBack label="Retour" type="button" navigation={navigation} />
				<BtnNext label="Suivant" type="submit" />
			</div>
		</div>
	</div>
  )
}

export default AddBoutique