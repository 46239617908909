import { BsFillChatDotsFill, BsGridFill, BsTagsFill } from "react-icons/bs";
import { FaUserPlus } from "react-icons/fa";
import { GrServices } from "react-icons/gr";
import { IoBag, IoLogOut, IoNotifications, IoSettingsSharp } from "react-icons/io5";
import { MdPanTool } from "react-icons/md";
import { RiShoppingBagFill } from "react-icons/ri";
import { SiGooglemessages } from "react-icons/si";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { onlogout } from "../../../../redux/slice/User.slice";
import { isSuperAdmin } from "../../../../routes/router";
import { useGetNotifByAdminQuery } from "../../../../utils/api/notification/notification.api";
import { IconBoost, IconProject, IconSpeaker } from "../../../shared/Icons";
import { usePartners } from "../PartenaireAdmin/PartenaireAdmin";

function AdminMenu() {
	const { user } = useAppSelector((s) => s?.user);
	const { data: notifications = [], isLoading } = useGetNotifByAdminQuery(user?.slug);
	const { count } = usePartners();
	const dispatch = useDispatch();
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
	return (
		<div className="content">
			<ul className="sidebar-admin-links mt-4 px-0">
				{Routes.map((link, index) => (
					<li className="active-admin-item" key={index}>
						<NavLink
							to={link.pathname}
							className={({ isActive }) =>
								`sidebar-admin-link ${
									isActive && "active-admin-link"
								}`
							}
							hidden={!user[`${link?.name}`] && !is_SuperAdmin}
						>
							<span className="sidebar-icon-container">
								{link.icon}
							</span>

							{link.label}
							{link.label === "Notifications" ? (
								<span className="badge-notif">
									{" "}
									{notifications?.length}{" "}
								</span>
							) : null}
							{link.name === "partners" ? (
								<span className="badge-notif">{count}</span>
							) : null}
						</NavLink>
					</li>
				))}
			</ul>
			<div className="py-4">
				<button
					className="btn btn-logout-admin"
					onClick={() => {
						dispatch(onlogout());
						window.location.pathname = "/app/se-connecter";
					}}
				>
					<span style={{ fontSize: 24, paddingLeft: 13 }}>
						<IoLogOut />
					</span>
					<span className="ps-3">Déconnexion</span>
				</button>
			</div>
		</div>
	);
}

export const Routes = [
	{
		label: "Dashboard",
		pathname: "/app/admin/tableau-de-bord",
		icon: <BsGridFill />,
		name: "dashboard",
	},
	{
		label: "Utilisateurs",
		pathname: "/app/admin/utilisateurs",
		icon: <FaUserPlus />,
		name: "users",
	},
	// {
	// 	label: "Vendeurs",
	// 	pathname: "/app/admin/vendeurs",
	// 	icon: <FaUserPlus />,
	// 	name: "vendeurs",
	// },
	// {
	// 	label: "Boutiques",
	// 	pathname: "/app/admin/boutiques",
	// 	icon: <RiShoppingBagFill />,
	// 	name: "commerces",
	// },
	// {
	// 	label: "Produits",
	// 	pathname: "/app/admin/produits",
	// 	icon: <RiShoppingBagFill />,
	// 	name: "products",
	// },
	// {
	// 	label: "Commandes",
	// 	pathname: "/app/admin/commandes",
	// 	icon: <IoBag />,
	// 	name: "orders",
	// },

	{
		label: "Chat",
		pathname: "/app/admin/chat",
		icon: <BsFillChatDotsFill />,
		name: "orders",
	},
	{
		label: "Annonces",
		pathname: "/app/admin/annonces",
		icon: <IconSpeaker />,
		name: "orders",
	},
	{
		label: "Appel d'offre",
		pathname: "/app/admin/projets",
		icon: <IconProject />,
		name: "orders",
	},
	{
		label: "Messages",
		pathname: "/app/admin/messages",
		icon: <SiGooglemessages />,
		name: "chat",
	},
	{
		label: "Services",
		pathname: "/app/admin/services",
		icon: <GrServices />,
		name: "services",
	},
	{
		label: "Notifications",
		pathname: "/app/admin/notifications",
		icon: <IoNotifications />,
		name: "notifications",
	},
	{
		label: "Annuaires",
		pathname: "/app/admin/annuaires",
		icon: <FaUserPlus />,
		name: "partners",
	},
	{
		label: "Professionnels",
		pathname: "/app/admin/professionnels",
		icon: <FaUserPlus />,
		name: "networks",
	},
	{
		label: "Catégories",
		pathname: "/app/admin/categories",
		icon: <BsTagsFill />,
		name: "categories",
	},
	{
		label: "Secteurs",
		pathname: "/app/admin/secteurs",
		icon: <BsGridFill />,
		name: "sectors",
	},
	{
		label: "Boostés",
		pathname: "/app/admin/boosts",
		icon: <IconBoost />,
		name: "boosts",
	},
	{
		label: "Paramètres",
		pathname: "/app/admin/parametres",
		icon: <IoSettingsSharp />,
		name: "parameter",
	},
	{
		label: "Aide",
		pathname: "/app/admin/aide",
		icon: <MdPanTool />,
		name: "help",
	},
	{
		label: "A propos",
		pathname: "/app/admin/a-propos",
		icon: <IoSettingsSharp />,
		name: "about",
	},
];

export default AdminMenu;
