import { configureStore } from '@reduxjs/toolkit'
import { AnnonceApi } from '../utils/api/annonce/annonce.api'
import { AuthApi } from '../utils/api/auth/auth.api'
import { BudgetApi } from '../utils/api/budget/budget.api'
import { CandidatureApi } from '../utils/api/candidature/candidature.api'
import { CategoryApi } from '../utils/api/category/category.api'
import { ChatApi } from '../utils/api/chat/chat.api'
import { CommerceApi } from '../utils/api/commerce/commerce.api'
import { FavoriteAnnonceApi } from '../utils/api/favoriteAnnonce/favoriteAnnonce.api'
import { FavoriteProjectApi } from '../utils/api/favoriteProject/favoriteProject.api'
import { ImageApi } from '../utils/api/media/image.api'
import { NetworkApi } from '../utils/api/network/network.api'
import { ProductApi } from '../utils/api/product/product.api'
import { ProjectApi } from '../utils/api/project/project.api'
import { SectorApi } from '../utils/api/sector/sector.api'
import { UserApi } from '../utils/api/user/user.api'
import { currentEnv, Env } from '../utils/http'
import { BudgetSlice } from './slice/budget.slice'
import { SectorSlice } from './slice/sector.slice'
import { commerceSlice } from './slice/shop.slice'
import { UserSlice } from './slice/User.slice'
import { PartenaireApi } from '../utils/api/partenaire/partenaire.api'
import { rateAnnonceApi } from '../utils/api/rate_annonce/rateAnnonce.api'
import { SubscribtionApi } from '../utils/api/subscriber/subscriber.api'
import { RateProjectApi } from '../utils/api/rate_project/rateProject.api'
import { notificationApi } from '../utils/api/notification/notification.api'
import { CartSlice } from './slice/cart.slice'
import { SellerApi } from '../utils/api/seller/seller.api'
import { AdminApi } from '../utils/api/admin/admin.api'
import { ConditionApi } from '../utils/api/conditions/condition.api'
import { PaymentModeApi } from '../utils/api/paymentMode/paymentMode.api'
import { DeliveryApi } from '../utils/api/deliveyMode/delivery.api'
import { OrderApi } from '../utils/api/order/order.api'
import { activiteApi } from '../utils/api/activite/activite.api'
import { ratePartnerApi } from "../utils/api/rate_partner/ratePartner.api";
import { NewsletterApi } from "../utils/api/newsletter/newsletter.api";
import { FaqApi } from "../utils/api/faq/faq.api";
import { ContactApi } from "../utils/api/contact/contact.api";
import { AbonnementApi } from "../utils/api/abonnement/abonnement.api";
import { SlideApi } from "../utils/api/slide/slide.api";
import { ServiceApi } from "../utils/api/service/service.api";
import { InterestAnnonceApi } from "../utils/api/interet_annonce/interetAnnonce.api";
import { AboutApi } from "../utils/api/about/about.api";

const store = configureStore({
  reducer: {
    [AuthApi.reducerPath]: AuthApi.reducer,
    [UserSlice.name]: UserSlice.reducer,
    [CartSlice.name]: CartSlice.reducer,
    [commerceSlice.name]: commerceSlice.reducer,
    [UserApi.reducerPath]: UserApi.reducer,
    [AdminApi.reducerPath]: AdminApi.reducer,
    [AnnonceApi.reducerPath]: AnnonceApi.reducer,
    [SectorApi.reducerPath]: SectorApi.reducer,
    [ProjectApi.reducerPath]: ProjectApi.reducer,
    [CategoryApi.reducerPath]: CategoryApi.reducer,
    [CommerceApi.reducerPath]: CommerceApi.reducer,
    [ImageApi.reducerPath]: ImageApi.reducer,
    [NetworkApi.reducerPath]: NetworkApi.reducer,
    [ProductApi.reducerPath]: ProductApi.reducer,
    [CandidatureApi.reducerPath]: CandidatureApi.reducer,
    [BudgetApi.reducerPath]: BudgetApi.reducer,
    [BudgetSlice.name]: BudgetSlice.reducer,
    [SectorSlice.name]: SectorSlice.reducer,
    [FavoriteProjectApi.reducerPath]: FavoriteProjectApi.reducer,
    [FavoriteAnnonceApi.reducerPath]: FavoriteAnnonceApi.reducer,
    [PartenaireApi.reducerPath]: PartenaireApi.reducer,
    [ChatApi.reducerPath]: ChatApi.reducer,
    [ImageApi.reducerPath]: ImageApi.reducer,
    [rateAnnonceApi.reducerPath]: rateAnnonceApi.reducer,
    [SubscribtionApi.reducerPath]: SubscribtionApi.reducer,
    [RateProjectApi.reducerPath]: RateProjectApi.reducer,
    [notificationApi.reducerPath]: notificationApi.reducer,
    [ConditionApi.reducerPath]: ConditionApi.reducer,
    [SellerApi.reducerPath]: SellerApi.reducer,
    [PaymentModeApi.reducerPath]: PaymentModeApi.reducer,
    [DeliveryApi.reducerPath]: DeliveryApi.reducer,
    [OrderApi.reducerPath]: OrderApi.reducer,
    [activiteApi.reducerPath]: activiteApi.reducer,
    [ratePartnerApi.reducerPath]: ratePartnerApi.reducer,
    [NewsletterApi.reducerPath]: NewsletterApi.reducer,
    [FaqApi.reducerPath]: FaqApi.reducer,
    [ContactApi.reducerPath]: ContactApi.reducer,
    [AbonnementApi.reducerPath]: AbonnementApi.reducer,
    [SlideApi.reducerPath]: SlideApi.reducer,
    [ServiceApi.reducerPath]: ServiceApi.reducer,
    [InterestAnnonceApi.reducerPath]: InterestAnnonceApi.reducer,
    [AboutApi.reducerPath]: AboutApi.reducer,
  },
  devTools: Env === currentEnv,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware(),
    AuthApi.middleware,
    UserApi.middleware,
    AdminApi.middleware,
    AnnonceApi.middleware,
    SectorApi.middleware,
    ProjectApi.middleware,
    CategoryApi.middleware,
    CommerceApi.middleware,
    ImageApi.middleware,
    NetworkApi.middleware,
    ProductApi.middleware,
    CandidatureApi.middleware,
    BudgetApi.middleware,
    FavoriteProjectApi.middleware,
    FavoriteAnnonceApi.middleware,
    PartenaireApi.middleware,
    ChatApi.middleware,
    ImageApi.middleware,
    rateAnnonceApi.middleware,
    SubscribtionApi.middleware,
    RateProjectApi.middleware,
    notificationApi.middleware,
    ConditionApi.middleware,
    SellerApi.middleware,
    PaymentModeApi.middleware,
    DeliveryApi.middleware,
    OrderApi.middleware,
    activiteApi.middleware,
    ratePartnerApi.middleware,
    NewsletterApi.middleware,
    FaqApi.middleware,
    ContactApi.middleware,
    AbonnementApi.middleware,
    SlideApi.middleware,
    ServiceApi.middleware,
    InterestAnnonceApi.middleware,
    AboutApi.middleware,
  ],
});

export type AppDispatch = typeof store.dispatch

export type RootState = ReturnType<typeof store.getState>

export default store
