import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

function BudgetsTableSkeleton() {
  return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Budget
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Crée
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					{[...Array(5)].map((elem, i) => (
						<tr key={i}>
							<th scope="row" style={{ verticalAlign: "middle" }}>
								<VolkenoSkeleton
									variant="rect"
									width={`100%`}
									height={20}
								/>
							</th>
							<td>
								<VolkenoSkeleton
									variant="rect"
									width={`100%`}
									height={20}
								/>
							</td>
							<td>
								<VolkenoSkeleton
									variant="rect"
									width={`100%`}
									height={20}
								/>
							</td>
							<td>
								<VolkenoSkeleton
									variant="rect"
									width={`100%`}
									height={20}
								/>
							</td>
						</tr>
					))}
				</tbody>
			</table>
		</div>
  );
}

export default BudgetsTableSkeleton;
