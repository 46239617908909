import React from 'react'
import VolkenoSkeleton from 'volkeno-react-skeleton'

function TraderCommandesSkeleton() {
  return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							N° de la commande
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Date
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Nom de l’acheteur
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Adresse de l’acheteur
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Mode de livraison
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Total de la commande
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Statut
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Poppins",
								fontSize: "14px",
								fontWeight: "400",
								verticalAlign: "middle",
							}}
						>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
  );
}

export default TraderCommandesSkeleton
