import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { EditUserFormData } from "../../../../../utils/api/auth/auth.type";
import { useEditUserMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { useAppDispatch } from "../../../../../redux/hooks";
import { onSetUser } from "../../../../../redux/slice/User.slice";

function UseFormTableauDeBord() {
	const dispatch = useAppDispatch();
	const validationSchema = yup.object().shape({
		lastname: yup.string().required("Le nom est requis"),
		firstname: yup.string().required("Le prenom est requis"),
		// pseudonym: yup.string().required("Le pseudonyme est requis"),
		civilite: yup.string(),
		adresse: yup.string(),
		birthdate: yup.date().typeError("Invalide Date"),
		phone: yup.string().required("Le numéro de téléphone est requis"),
		whatsapp: yup.string().required("Le numéro whatshapp, est requis"),
		email: yup.string().email("email invalide").required("l'email est requis"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<EditUserFormData>({
		resolver: yupResolver(validationSchema),
	});

	//  Calling the Register Mutation
	const [editUser, { isLoading, isSuccess, error, isError, data }] = useEditUserMutation();
	useEffect(() => {
		if (data) {
			dispatch(onSetUser(data));
		}
	}, [data]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Modifiée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {});
		}
	});
	useEffect(() => {
		const err = error as any;
		if (isError) {
			if (err) {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de la modification",
					showConfirmButton: false,
					timer: 3000,
				});
			}
		}
	}, [isLoading]);
	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 4000);
		}
	}, [errors]);

	const onSubmit = (data: EditUserFormData) => {
		console.log(data);
		const userSlug = data?.slug;
		if (data.birthdate) {
			const formatBirthDate = moment(data.birthdate).format("YYYY-MM-DD");
			const userData = { ...data, birthdate: formatBirthDate };
			editUser({ userSlug, userData });
		} else {
			editUser({ userSlug, data });
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default UseFormTableauDeBord;
