import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { CommerceFormData } from "../../../../utils/api/commerce/commerce.type";
import { IUser, onSetUser } from "../../../../redux/slice/User.slice";
import { cleannerError } from "../../../../utils/utils";
import { useEditCommerceMutation } from "../../../../utils/api/commerce/commerce.api";
import { useEditInfosUserMutation } from "../../../../utils/api/user/user.api";
import { Color } from "../../../../utils/theme";
import { useAppDispatch } from "../../../../redux/hooks";
import { addCommerce } from "../../../../redux/slice/shop.slice";

function UseEditInfosCommerce() {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Le Nom du commerce"),
		email: yup.string().email("Email Invalide").required().label("L'email"),
		phone: yup.string().required().label("Le Téléphone"),
		whatsapp: yup.string().nullable().label("Le Whatshapp"),
		city: yup.string().required().label("La ville"),
		region: yup.string().required().label("La région"),
		description: yup.string().label("La Description"),
		location: yup.string().required().label("L'Adresse"),

		category: yup.string().required().label("La catégorie"),

		profil: yup.string().required().label("Le type de profil"),
		country: yup.string().required().label("Le pays").nullable(),
		account_name: yup.string().nullable().label("Le nom du compte"),
		isMoral: yup.boolean(),
		ninea: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup.string().required().label("Le ninea").nullable(),
			})
			.nullable(),
		legal_form: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup.string().required().label("Le nom légale").nullable(),
			})
			.nullable(),
		postal_card: yup.string().nullable().label("Le code postal"),
		company_register_number: yup
			.string()
			.nullable()
			.label("Le numéro d’Enrégistrement de l'entreprise"),
		bank_name: yup.string().nullable().label("Le nom de la banque"),
		account_number: yup.string().nullable().label("Le numéro de compte"),
		iban: yup.string().nullable().label("L'IBAN"),
		account_owner: yup.string().nullable().label("Le propriétaire du compte"),
		swift: yup.string().nullable().label("Le SWIFT"),
		lastname: yup.string().required("Le nom est requis"),
		firstname: yup.string().required("Le prenom est requis"),
		phoneUser: yup.string().required("Le numéro de téléphone est requis"),
		emailUser: yup.string().email("email invalide").required("l'email est requis"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<CommerceFormData | IUser | any>({
		resolver: yupResolver(validationSchema),
	});
	const dispatch = useAppDispatch();
	const [sendData, { isLoading, isSuccess, isError, error, data }] = useEditCommerceMutation();
	const [
		editData,
		{ isLoading: loading, isSuccess: success, isError: isFail, error: fail, data: dataEdit },
	] = useEditInfosUserMutation();

	useEffect(() => {
		if (errors) {
			for (let key of Object.keys(errors)) {
				if (key) {
					window.scrollTo(10, 10);
				}
			}
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isError || isFail) {
			console.log("err", error);
			const erreur = fail as any;
			const err = error as any;
			const {
				message = `Une erreur de statut ${err?.status || erreur?.status} s'est produite`,
			} = err.data || erreur?.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError, isFail]);

	useEffect(() => {
		if (isSuccess && success) {
			dispatch(onSetUser(dataEdit));
			dispatch(addCommerce(data));
			Swal.fire({
				icon: "success",
				title: "Informations modifiées avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isSuccess, success]);
	const onSubmit = async (formValues) => {
		console.log(formValues);
		let userSlug = formValues?.slugUser;
		let userData = {
			lastname: formValues?.lastname,
			firstname: formValues?.firstname,
			email: formValues?.emailUser,
			phone: formValues?.phoneUser,
			slug: formValues?.slugUser,
		};
		let data = formValues;
		const fd = new FormData();

		for (let key of Object.keys(data)) {
			const val = data[key];
			if (key === "images") {
				val.forEach((item) => {
					fd.append(key, item);
				});
			} else {
				fd.append(key, val);
			}
		}
		await editData(userData);
		await sendData(formValues);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		setError,
		loading,
	};
}

export default UseEditInfosCommerce;
