import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { IUser, onSetUser } from '../../../../../redux/slice/User.slice'
import { useAddAdminMutation } from '../../../../../utils/api/admin/admin.api'
import { cleannerError } from '../../../../../utils/utils'
import { Color } from '../../../../../utils/theme'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { Authorisations } from '../../../../../utils/constantes'

function UseAddAdminUser() {
  const validationSchema = yup.object().shape({
    adresse: yup.string().required().label("L'Adresse"),
    lastname: yup.string().required('Le nom est requis'),
    firstname: yup.string().required('Le prenom est requis'),
    phone: yup.string().required('Le numéro de téléphone est requis'),
    email: yup.string().email('email invalide').required("l'email est requis"),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<IUser | any>({
    resolver: yupResolver(validationSchema),
  })
  const dispatch = useAppDispatch()
  const [avatar, setAvatar] = useState<any>(null)
  const [address, setAddress] = useState<any>('')
  const [
    addData,
    { isLoading, isSuccess, isError, error, data, reset },
  ] = useAddAdminMutation()
  const { user } = useAppSelector((s) => s?.user)

  useEffect(() => {
    if (errors) {
      for (let key of Object.keys(errors)) {
        if (key) {
          window.scrollTo(10, 10)
        }
      }
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  const onHide = (modalId) => {
    document.getElementById(modalId)?.classList.remove('show')
    $('.modal-backdrop').remove()
    $(`#${modalId}`).hide()
    $(`body`).css({ overflow: 'scroll' })
  }

  useEffect(() => {
    if (isSuccess) {
      console.log('data', data)

      onHide('AddAdminModal')
      setAddress('')
      setAvatar(null)
      reset()
      Swal.fire({
        icon: 'success',
        title: 'Admin ajouté avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      }).then(async () => {
        await reset()
        await Authorisations.forEach((item) => {
          setValue(`${item?.value}`, true)
        })
      })
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log(formValues)
    const fd = new FormData()

    for (let key of Object.keys(formValues)) {
      const val = formValues[key]
      fd.append(key, val)
    }
    await addData(fd)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading,
    setError,
    avatar,
    setAvatar,
    address,
    setAddress,
    reset,
  }
}

export default UseAddAdminUser
