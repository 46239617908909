import React, { useState } from 'react'
import { Product } from '../../../../utils/api/product/product.type'
import AvisMarket from './Tabs/AvisMarket'
import MarketPlaceDescription from './Tabs/MarketPlaceDescription'
import MoreProduct from './Tabs/MoreProduct'
import SellerInfo from './Tabs/SellerInfo'

const MarketTabsContainer = ({ item }: { item: Product }) => {
  const [active, setActive] = useState<number>(0)
  const tabs = [
    'Description',
    `Avis(${item?.rates?.length})`,
    'Infos vendeur',
    'Produits similaires',
  ]

  return (
		<div className="row">
			<h2 className="market-det-title-tabs">Informations du produit</h2>
			<ul className="market-det-tabs-container">
				{tabs.map((tab, index) => (
					<li
						role="button"
						key={index}
						className={`market-tab ${active === index && "tab-active"} ${
							active === 0
								? "tab-desc"
								: active === 1
								? "tab-avis"
								: active === 2
								? "tab-seller"
								: "tab-more-product"
						}`}
						onClick={() => setActive(index)}
					>
						<span>{tab}</span>
					</li>
				))}
			</ul>
			<div className="tabs-container mt-4">
				{active === 0 && <MarketPlaceDescription text={item?.description} />}
				{active === 1 && <AvisMarket product={item} />}
				{active === 2 && <SellerInfo product={item} />}
        {active === 3 && <MoreProduct item={item} />}
			</div>
		</div>
  );
}

export default MarketTabsContainer
