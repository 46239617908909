import Divider from "../Divider";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import "./About.css";
import services from "../../assets/appImages/services.png";
import serviceIcon from "../../assets/appImages/service.png";
import { IconCircleCheck } from "../Icons";
import { Link } from "react-router-dom";
import pana from "../../assets/appImages/pana.png";
import ServiceForm from "./ServiceForm";

const servicesData = [
	{
		key: "gestion-de-projet",
		title: "Gestion de projet",
		advantages: [
			"Création entreprise",
			"Business plan",
			"Etude de marché",
			"Etude de faisabilité",
		],
	},
	{
		key: "accompagnement-et-conseil",
		title: "Accompagnement et conseil",
		advantages: ["Coaching et formation", "Finance et audit", "Etude de faisabilité"],
	},
	{
		key: "business-development",
		title: "Business developpement",
		advantages: ["Mise en relation"],
	},
];

const Services = () => {
	return (
		<>
			<FrontHeader />
			<div className="about-page">
				<h1 className="my-4">Services</h1>
				<Divider />
				<div className="py-5  container-munngano">
					<div className="row d-flex  align-items-center mb-5">
						<div className="col-md-6 mb-4">
							<img src={services} alt="services" className="w-100" />
						</div>
						<div className="col-md-6">
							{servicesData.map((service) => (
								<div
									className="service-card px-2 py-1 mb-3 d-flex align-items-start gap-3 w-100"
									key={service.key}
								>
									<div>
										<img src={serviceIcon} alt={service.title} />
									</div>
									<div className="pt-2">
										<h3>{service.title}</h3>
										<ul className="services-advantages px-0 mt-2">
											{service.advantages.map((item, index) => (
												<li key={index} className="mb-1">
													<IconCircleCheck />
													<span className="ms-2">{item}</span>
												</li>
											))}
										</ul>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="service-hero px-2 py-3 row m-b-100">
						<div className="col-md-8 d-flex flex-column justify-content-between">
							<h3>
								Trouvez le partenaire (client, fournisseurs, prestataires de services
								etc ...) en France ou au Sénégal
							</h3>
							<div className="d-flex flex-column flex-md-row align-items-md-end gap-3 mt-3">
								<h6>Vous cherchez un partenaire fiable dans un autre pays africain?</h6>
								<Link to="/app/contactez-nous" className="btn-service p-3">
									Contacter-nous
								</Link>
							</div>
						</div>
						<div className="col-md-4 no-view-l-mobile ">
							<div className="service-hero-img-container position-relative">
								<img src={pana} alt="service-hero" className="w-100 service-hero-img" />
							</div>
						</div>
					</div>
					<div className="specialites">
						<h3>Spécialités de nos experts</h3>
						<div className="row ps-3">
							<ul className="col-md-6">
								<li>Agrobusiness</li>
								<li>
									Conseil juridique et fiscal (
									<span>fiscalité française et sénéglaise</span>)
								</li>
								<li>Construction</li>
								<li>Logistique et transport</li>
							</ul>
							<ul className="col-md-6">
								<li>Expertise comptable</li>
								<li>Finance</li>
								<li>Digital -ntic</li>
								<li>
									Biophysicien (<span>Formulation cosmétique normes française</span>
									)
								</li>
							</ul>
						</div>
					</div>
					<ServiceForm />
				</div>
			</div>
			<FrontFooter />
		</>
	);
};

export default Services;
