import React from 'react'
import ReactQuill from 'react-quill'
import { FormError } from '../../../common/CustomInputAdmin'
import CloseModalBtn from '../../../shared/CloseModalBtn'
import UseAddDeliveryMode from '../../admin/SettingAdmin/requestSettings/UseAddDeliveryMode'

function AddDeliveryMode() {
  const {
    register,
    setValue,
    onSubmit,
    errors,
    isLoading,
    reset,
    description,
    setDescription,
  } = UseAddDeliveryMode()
  const handleChange = (value: any) => {
    setDescription(value)
    setValue('description', value)
  }

  const handleReset = () => {
    reset()
    setDescription('')
  }
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title modal-title-custom"
            id="AddPaymentModeModal"
          >
            Ajouter un mode de livraison
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    placeholder="Nom"
                    {...register('name')}
                  />
                  <FormError error={errors?.name?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Durée (en heures)
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-input-admin"
                    placeholder="Durée (Heures)"
                    step="any"
                    {...register('duration')}
                  />
                  <FormError error={errors?.duration?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Montant
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="number"
                    className="form-control form-control-input-admin"
                    placeholder="Montant de la livraison"
                    step="any"
                    {...register('amount')}
                  />
                  <FormError error={errors?.amount?.message} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Description
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    value={description}
                    onChange={handleChange}
                  />
                  <FormError error={errors?.description?.message} />
                </div>
              </div>
            </div>
            <div className="text-center mb-4">
              <button
                className="btn btn-theme"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Ajouter`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Chargement...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddDeliveryMode
