import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { RiDeleteBin7Fill, RiEdit2Fill } from 'react-icons/ri'
import {
  Category,
  Subcategory,
} from '../../../../../utils/api/category/category.type'
import { getImage } from '../../../../../utils/utils'
import AddSubCategoryModal from '../../../modals/adminModal/AddSubCategoryModal'
import { useDeleteSubCategory } from '../../../modals/adminModal/forms/useSubCategoryForm'

function SubCategoryTable({ category }: { category: Category }) {
  const nameFormatter = (cell: any, row: Subcategory) => {
    return (
      <div>
        <img
          src={getImage(row.picture)}
          alt="Produit"
          className="img-produit-table-admin"
        />
        <span className="ps-2">{row.name}</span>
      </div>
    )
  }

  const actionFormatter: any = (cell, row: Subcategory) => {
    const modalId = `UpdateSubCategory${row.id}`
    return (
		<div className="table-actions-btn-container-commandes gap-2 d-flex">
			<AddSubCategoryModal category={category} modalId={modalId} item={row} />
			<div>
				<button
					data-bs-toggle="modal"
					data-bs-target={`#${modalId}`}
					className="btn btn-action-user-admin"
					title="Editer"
				>
					<RiEdit2Fill />
				</button>
			</div>
			<div>
				<DeleteSubCategoryButton item={row} />
			</div>
		</div>
    );
  }
  const columns = [
    {
      dataField: 'name',
      text: 'Nom',
      formatter: (cell: any, row) => nameFormatter(cell, row),
      style: { textAlign: 'left' },
    },
    {
      dataField: 'position',
      text: 'Position',
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell: any, row) => actionFormatter(cell, row),
    },
  ]

  return (
    <>
      <BootstrapTable
        keyField="id"
        data={category.subcategory}
        columns={columns}
        bordered={true}
        condensed={false}
        responsive={true}
        wrapperClasses="table-responsive admin-table"
      />
      {category.subcategory.length === 0 && (
        <small>Pas encore de sous-catégorie</small>
      )}
    </>
  )
}

export default SubCategoryTable

function DeleteSubCategoryButton({ item }: { item: Subcategory }) {
  const onDelete = useDeleteSubCategory(item)
  return (
		<button onClick={onDelete} className="btn btn-action-user-admin" title="Supprimer">
			<RiDeleteBin7Fill />
		</button>
  );
}
