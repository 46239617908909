import { MdOutlineAddCircleOutline } from "react-icons/md";
import AjoutBudgetModal from "../../../modals/Budget/AjoutBudgetModal";
import BudgetsTable from "../../../Tables/user/Budgets/BudgetsTable";

const Budgets = () => {
	return (
		<>
			<div className="bloc-title-page-tab mb-4">
				<div>
					<p className="titre-page-tab-content">Mes Budgets</p>
				</div>
				<button
					className="btn btn-send-newsletter"
					data-bs-toggle="modal"
					data-bs-target="#ajoutBudgetModal"
				>
					<MdOutlineAddCircleOutline />
					<span className="ps-2">Ajouter un budget</span>
				</button>
			</div>
			<BudgetsTable />
			<AjoutBudgetModal />
		</>
	);
};

export default Budgets;
