import React from "react";
import { useGetCategoryQuery } from "../../../../../utils/api/category/category.api";
import { Color } from "../../../../../utils/theme";
import { BtnNext } from "../../../../common/Button";
import { CustomInput } from "../../../../common/CustomInput";
import { CustomSelectInput } from "../../../../common/CustomSelectInput";
import ErrorMessage from "../../../../common/ErrorMessage";

const DetailProduct = ({
	register,
	setValue,
	errors,
	pricipalColor,
	setPricipalColor,
	category,
	setCategory,
}) => {
	const [options, setOptions] = React.useState<any>([]);
	const [subcategories, setSubcategories] = React.useState<any>([]);
	const { data: categories, isSuccess } = useGetCategoryQuery();
	React.useEffect(() => {
		if (isSuccess) {
			setOptions(
				categories?.map((item) => {
					return {
						label: item?.name,
						value: item?.id,
						subcategories: item?.subcategory,
					};
				})
			);
		}
	}, [categories, isSuccess]);

	React.useEffect(() => {
		if (category?.subcategories) {
			setSubcategories(
				category?.subcategories?.map((item) => {
					return {
						label: item?.name,
						value: item?.id,
					};
				})
			);
		}
	}, [category]);

	const handleChange = (e) => {
		console.log(
			"value",
			e.target.value,
			options?.find((item) => parseInt(item?.value) === parseInt(e.target.value))
		);
		setValue("category", e.target.value);
		setCategory(
			options?.find((item) => parseInt(item?.value) === parseInt(e.target.value))
		);
	};
	return (
		<div>
			<h4 className="stepper-title">Détails du produit </h4>
			<div>
				<div className="row">
					<CustomSelectInput
						label="Choisir une catégorie"
						options={options?.sort(function (a, b) {
							return a?.label?.localeCompare(b?.label);
						})}
						required
						onChange={(e) => handleChange(e)}
						error={errors?.category?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					{/* <CustomSelectInput
						label="Choisir une sous catégorie"
						options={subcategories?.sort(function (a, b) {
							return a?.label?.localeCompare(b?.label);
						})}
						required
						// {...register("subcategory")}
						error={errors?.subcategory?.message}
						style={{ backgroundColor: Color.bgGray }}
						onChange={(e) => handleChangeSubCategory(e)}
					/> */}
					<div className="col-md-6 form-group mb-4">
						<label
							htmlFor="nombre_stock"
							className="custom-form-label"
							aria-labelledby="subcategory"
						>
							Choisir une sous catégorie
							<span className="text-danger">*</span>
						</label>
						<select
							id="subcategory"
							className="form-select espace-client-form-select-control"
							{...register("subcategory")}
							style={{ backgroundColor: Color.bgGray }}
						>
							{subcategories
								?.sort(function (a, b) {
									return a?.label?.localeCompare(b?.label);
								})
								.map((option) => (
									<option
										value={option.value}
										key={option.value}
									>
										{option.label}
									</option>
								))}
						</select>
						{errors?.subcategory && (
							<ErrorMessage message={errors?.subcategory?.message} />
						)}
					</div>
					<CustomInput
						label="Nom du produit"
						type="text"
						required
						name="nom_produit"
						{...register("name")}
						error={errors?.name?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Modèle"
						type="text"
						name="modele"
						{...register("modele")}
						error={errors?.modele?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Marque"
						type="text"
						name="modele"
						{...register("marque")}
						error={errors?.marque?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Couleur principale"
						type="color"
						// {...register("color_pricipal")}
						onChange={(e) => {
							setValue("main_color", e.target.value);
							console.log("color", e.target.value);
							setPricipalColor(e.target.value);
						}}
						error={errors?.main_color?.message}
						name="modele"
						style={{
							backgroundColor: pricipalColor || Color.bgGray,
							padding: "1.3rem",
						}}
						className="form-control espace-client-form-control input-color-form-control"
					/>
				</div>
				<div className="flex-r gap-3 mt-4">
					{/* <BtnSkip label="Passer l’étape" type="button" /> */}
					<BtnNext label="Suivant" type="submit" />
				</div>
			</div>
		</div>
	);
};

export default DetailProduct;
