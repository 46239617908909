import React from "react";
import { AiTwotoneHeart } from "react-icons/ai";
import { Link } from "react-router-dom";
import StarRatingComponent from "react-star-rating-component";
import { useGetSimilarProjectsQuery } from "../../../../utils/api/project/project.api";
import { createMarkup } from "../../../../utils/utils";

const RecentProjects = ({ slug }) => {
	const { data } = useGetSimilarProjectsQuery(slug as string);
	return (
		<div className="annonce-detail-item-component">
			{data?.map((elem) => {
				return (
					<div key={elem.id} className="mb-3 card-annonce-detail-item">
						<Link to={`/app/categories/projets/${elem?.slug}`} className="text-decor-none">
							<div className="flex-sb flex-sb-mobile">
								<div className="d-flex">
									<div className="ps-2">
										<p className="card-text titre-annonce-projet">
											{elem.title}
										</p>
										<div className="text-libelle-card-annonc-detail pt-2">
											{elem?.region}
										</div>
									</div>
								</div>
								<div className="text-right">
									<div>
										<StarRatingComponent
											name="rate2"
											editing={false}
											starCount={5}
											value={5}
											starColor="#CCCCCC"
											emptyStarColor="#ffc20b71"
										/>
									</div>
									<div>
										<AiTwotoneHeart className="icon-heart-detail-annonce" />
									</div>
								</div>
							</div>
							<div
								className="mb-2 pt-1 text-libelle-card-annonc-detail"
								dangerouslySetInnerHTML={createMarkup(elem?.description?.slice(0, 200))}
							/>
							{/* <div className="d-flex ps-4">
							<div className="ps-3">
								<p className="text-libelle-card-annonc-detail">Dakar</p>
							</div>
							<div className="ps-2">
								<p className="text-libelle-card-annonc-detail">À plein temps</p>
							</div>
						</div> */}
						</Link>
					</div>
				);
			})}
		</div>
	);
};
export default RecentProjects;
