import { useState } from "react";
import Swal from "sweetalert2";
import { INTECH_API_KEY } from "./http";
import { Color } from "./theme";

export function useCheckTransactionStatus(
	redirectUrl: string,
	externalTransactionId?: number | string
) {
	const [isLoad, setIsLoad] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [seconds, setSeconds] = useState<number>(20);

	const onGetTransactionStatus = () => {
		setIsLoad(true);
		let raw = {
			externalTransactionId: externalTransactionId,
		};
		const requestOptions: RequestInit = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Secretkey: INTECH_API_KEY,
			},
			body: JSON.stringify(raw),
		};

		fetch("https://api.intech.sn/api-services/get-transaction-status", requestOptions)
			.then((response) => response.json())
			.then((result: any) => {
				setIsLoad(false);
				// console.log(result);
				if (result?.data?.status === "SUCCESS") {
					setIsFetching(false);
					window.location.href = redirectUrl;
					// console.log("done");
				} else if (result?.data?.status === "PENDING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en attente!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else if (result?.data?.status === "REFUNDED") {
					setIsFetching(false);
					Swal.fire({
						icon: "info",
						title: `Votre paiement a été remboursé!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				} else if (result?.data?.status === "PROCESSING") {
					setIsFetching(true);
					Swal.fire({
						icon: "info",
						title: `Votre paiement est en cours de traitement!`,
						iconColor: Color.success,
						showConfirmButton: false,
						timer: 3000,
					});
				} else {
					console.log("no done");
					setIsFetching(false);
					Swal.fire({
						icon: "error",
						title: result?.data?.errorType?.message
							? result?.data?.errorType?.message
							: `${result?.msg}`,
						showConfirmButton: false,
						timer: 3000,
					});
					// .then(() => closeModal());
				}
			})
			.catch((error) => {
				setIsFetching(false);
				setIsLoad(false);
				console.log("error", error);
				Swal.fire({
					icon: "error",
					title: error?.data?.message
						? error?.data?.message
						: `Erreur de statut ${error?.status} est survenue`,
					showConfirmButton: false,
					timer: 5000,
				});
				// .then(() => closeModal());
			});
	};

	return {
		onGetTransactionStatus,
		isLoad,
		isFetching,
		seconds,
	};
}
