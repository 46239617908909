import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import {
  AuthState,
  IUser,
  IUserFormData,
  onSetUser,
} from '../../../redux/slice/User.slice'
import { ApiBaseUrl } from '../../http'
import { AppStorage } from '../../storage'
import { QueryUrl } from '../../utils'
import { ChangePasswordData, LoginResult } from '../auth/auth.type'
import { PaginationResults } from '../commerce/commerce.type'
import { IGlobalSearch } from "./user.type";

export const prepareHeaders = (headers: Headers, { getState }) => {
	const token =
		(getState() as { user: LoginResult }).user.token ??
		AppStorage.getItem<AuthState>("user")?.token;
	if (token) {
		headers.set("Authorization", `Bearer ${token}`);
	}
	return headers;
};
type FindUserQuery = {
	word: string;
};
export const UserApi = createApi({
	reducerPath: "userApi",
	tagTypes: ["user", "user_slug", "find_user", "userbyfilter", "globalSearch"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getMe: builder.query<IUser, null>({
			query: () => "me",
			transformResponse: (result: { data: { user: IUser } }) => result.data.user,
			async onQueryStarted(args, { dispatch, queryFulfilled }) {
				try {
					const { data } = await queryFulfilled;
					dispatch(onSetUser(data));
				} catch (error) {}
			},
		}),
		me: builder.query<IUser, void>({
			query: () => `/me/`,
			transformResponse: ({ data }) => data,
			providesTags: ["user"],
		}),
		meAdmin: builder.query<IUser, void>({
			query: () => `/meadmin/`,
			// transformResponse: (resp: any) => resp?.data,
			providesTags: ["user"],
		}),
		userBySlug: builder.query<IUser, string>({
			query: (slug) => `/user/${slug}/`,
			providesTags: ["user_slug"],
		}),
		findUser: builder.query<IUser[], FindUserQuery>({
			query: (query) => QueryUrl("/userbyfilter/", query),
			providesTags: ["find_user"],
			transformResponse: ({ results }) => results,
		}),
		userByFilter: builder.query<
			PaginationResults<IUser>,
			{ word: string; page: number; limit: number; profil: IUser["profil"] }
		>({
			query: (query) => QueryUrl("/userbyfilter/", query),
			providesTags: ["userbyfilter"],
		}),
		editUser: builder.mutation<IUser, IUser | any>({
			invalidatesTags: ["user", "userbyfilter"],
			query: ({ userSlug, userData }) => {
				return {
					url: `user/${userSlug}/`,
					method: "PUT",
					body: userData,
				};
			},
		}),
		editInfosUser: builder.mutation<IUser, IUser | any>({
			invalidatesTags: ["user", "userbyfilter"],
			query: ({ slug, ...data }) => {
				return {
					url: `user/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),

		changePassword: builder.mutation<any, ChangePasswordData>({
			query: (data) => ({
				url: `changepassword/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["user"],
		}),
		deleteUser: builder.mutation<IUser, string>({
			query: (slug) => ({
				url: `user/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["user", "userbyfilter"],
		}),
		addOrUpdateUser: builder.mutation<
			IUser,
			{ slug?: string; data: IUserFormData | FormData }
		>({
			invalidatesTags: ["user", "userbyfilter"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `user/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `user/`,
					method: "POST",
					body: data,
				};
			},
		}),
		globalSearch: builder.query<IGlobalSearch, { query: string; keyword?: string }>({
			query: ({ query, keyword }) => ({
				url: `search/`,
				method: "GET",
				params: { q: query, filter: keyword },
			}),
			providesTags: ["globalSearch"],
		}),
	}),
});

export const {
	useGetMeQuery,
	useLazyGetMeQuery,
	useEditUserMutation,
	useMeQuery,
	useMeAdminQuery,
	useLazyMeAdminQuery,
	useEditInfosUserMutation,
	useLazyUserBySlugQuery,
	useFindUserQuery,
	useChangePasswordMutation,
	useAddOrUpdateUserMutation,
	useUserByFilterQuery,
	useDeleteUserMutation,
	useGlobalSearchQuery,
	useLazyGlobalSearchQuery,
} = UserApi;
