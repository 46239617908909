import React, { useEffect, useState } from "react";
import { FaCheckDouble } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useGetOffersQuery } from "../../../../../utils/api/abonnement/abonnement.api";
import { IOffer, TypeBoost } from "../../../../../utils/api/abonnement/abonnement.type";
import {
	useLazyBoostAnnonceQuery,
	useLazyFindAnnonceBySlugQuery,
} from "../../../../../utils/api/annonce/annonce.api";
import { IAnnonce } from "../../../../../utils/api/annonce/annonce.type";
import { Commerce, TBoostData } from "../../../../../utils/api/commerce/commerce.type";
import { Product } from "../../../../../utils/api/product/product.type";
import { appEnv } from "../../../../../utils/constantes";
import { formatMontant, formattedBoostDate, useLocationState } from "../../../../../utils/utils";
import BoostModal from "../../../modals/commercant/commandes/BoostModal";
import "./Boost.css";

function Boost() {
	const { slug } = useParams();
	const [value, setValues] = useState<TBoostData>();
	const itemState = useLocationState<IAnnonce>(undefined);
	const [annonce, SetAnnonce] = useState<IAnnonce>(itemState);
	const [findAnnonceBySlug, { data: dataAnnonce }] = useLazyFindAnnonceBySlugQuery();
	useEffect(() => {
		if (slug) {
			findAnnonceBySlug(slug);
		}
	}, [slug]);
	useEffect(() => {
		if (dataAnnonce) {
			SetAnnonce(dataAnnonce);
		}
	}, [dataAnnonce]);

	const { AppBoost, networkBoost, networkWithVideoBoost, isLoading } = useOfferBoost();

	return (
		<div className="article-card p-4">
			<div className="flex-sb mb-4">
				<div className="d-flex gap-4">
					<h3 className="article-title">Choisir une option</h3>
				</div>
			</div>
			<div className="container-page-boost">
				<div className="row">
					<div className="col-lg-4 col-md-6 mb-3">
						<div className="col-boost-content">
							<div className="content-boost-item">
								<div className="header-boost-content header-boost-content-color2">
									<p className="title-boost">Top Annonces</p>
								</div>
								<div className="content-boost-text">
									{isLoading && (
										<VolkenoSkeleton
											variant="rect"
											width="100%"
											height={50}
										/>
									)}
									{AppBoost?.map((item, i) => (
										<div
											key={item?.slug}
											className="offer-boost-item"
										>
											<div className="content-price-boost">
												<p className="price-boost">
													{formatMontant(
														item?.prix
													)}
												</p>
											</div>
											<div className="content-description-boost">
												<p className="description-boost">
													Chaque jour pendant{" "}
													<span
														style={{
															fontWeight: 700,
														}}
													>
														{item?.duree}
													</span>{" "}
													jours
												</p>
											</div>
											<div className="container-btn-choise-boost">
												{!annonce?.is_boosted && (
													<button
														className="btn btn-choice-boost"
														data-bs-toggle="modal"
														data-bs-target="#BoostModal"
														onClick={() => {
															setValues(
																{
																	slug: slug,
																	duration: item?.duree,
																	amount: item?.prix,
																	endpoint: "boost-announcement",
																}
															);
														}}
													>
														Choisir
													</button>
												)}
											</div>
											{AppBoost?.length - 1 !== i && (
												<div className="b-b-1 mb-2"></div>
											)}
										</div>
									))}
									<div className="d-flex flex-column align-items-center mt-3">
										{annonce?.is_boosted && (
											<>
												<button className="badge-boost">
													<FaCheckDouble />
													Déjà boostée
												</button>
												<div className="mt-2 text-dark">
													<span className="me-2">
														expire le
													</span>
													<span>
														{formattedBoostDate(
															annonce
																?.boost
																?.boost
																?.date_fin
														)}
													</span>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>

					<SocialBoost
						item={annonce}
						setValue={setValues}
						url="socialboost-announcement"
						data={networkBoost}
						isLoading={isLoading}
					/>
					<SocialBoostPromo
						item={annonce}
						setValue={setValues}
						url="socialboost-announcement"
						data={networkWithVideoBoost}
						isLoading={isLoading}
					/>
				</div>
			</div>
			<div
				className="modal fade"
				id="BoostModal"
				aria-labelledby="BoostModalLabel"
				aria-hidden="true"
			>
				<BoostModal item={value} />
			</div>
		</div>
	);
}

function useBoostAnnonce({ value }: { value?: TBoostData }) {
	const [boostProduct, { isSuccess, isError, data, error }] = useLazyBoostAnnonceQuery();

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = { ...value, env: appEnv };
		console.log(data);
		boostProduct(data);
	};

	// useEffect(() => {
	// 	if (isSuccess && data) {
	// 		window.location.href = data?.url;
	// 	}
	// }, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onSubmit;
}
type BoostProps = {
	item: IAnnonce | Commerce | Product;
	setValue: any;
	url: string;
	data?: IOffer[];
	isLoading: boolean;
};
export function SocialBoost({ item, setValue, url, data, isLoading }: BoostProps) {
	return (
		<div className="col-lg-4 col-md-6 mb-3">
			<div className="col-boost-content">
				<div className="content-boost-item">
					<div className="header-boost-content header-boost-content-color4">
						<p className="title-boost">Promotion sur nos réseaux sociaux</p>
					</div>
					<div className="content-boost-text">
						{isLoading && (
							<VolkenoSkeleton
								variant="rect"
								width="100%"
								height={50}
							/>
						)}
						{data?.map((offer, i) => (
							<div key={offer?.slug} className="offer-boost-item">
								<div className="content-price-boost">
									<p className="price-boost">
										{formatMontant(offer?.prix)}
									</p>
								</div>
								<div className="content-description-boost">
									<p className="description-boost">
										Chaque jour pendant{" "}
										<span style={{ fontWeight: 700 }}>
											{offer?.duree}
										</span>{" "}
										jours
									</p>
								</div>
								<div className="container-btn-choise-boost">
									{!item?.social_boosted &&
										!item?.promosocial_boosted && (
											<button
												className="btn btn-choice-boost"
												data-bs-toggle="modal"
												data-bs-target="#BoostModal"
												onClick={() => {
													setValue({
														slug: item?.slug,
														duration: offer?.duree,
														amount: offer?.prix,
														endpoint: url,
													});
												}}
											>
												Choisir
											</button>
										)}
								</div>
								{data?.length - 1 !== i && (
									<div className="b-b-1 mb-2"></div>
								)}
							</div>
						))}
						<div className="d-flex flex-column align-items-center mt-3">
							{(item?.social_boosted || item?.promosocial_boosted) && (
								<>
									<button className="badge-boost">
										<FaCheckDouble />
										Déjà boostée
									</button>
									{item?.social_boost?.boost?.date_fin ? (
										<div className="mt-2 text-dark">
											<span className="me-2">
												expire le
											</span>
											<span>
												{formattedBoostDate(
													item?.social_boost
														?.boost
														?.date_fin
												)}
											</span>
										</div>
									) : (
										<span className="mt-2">
											Aucune date n'a été trouvée
										</span>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export function SocialBoostPromo({ item, setValue, url, data, isLoading }: BoostProps) {
	return (
		<div className="col-lg-4 col-md-6 mb-3">
			<div className="col-boost-content">
				<div className="content-boost-item">
					<div className="header-boost-content header-boost-content-color5">
						<p className="title-boost">
							Création et montage vidéo + promotion sur les réseaux
							sociaux
						</p>
					</div>
					<div className="content-boost-text">
						{isLoading && (
							<VolkenoSkeleton
								variant="rect"
								width="100%"
								height={50}
							/>
						)}
						{data?.map((offer, i) => (
							<div className="offer-boost-item" key={offer?.slug}>
								<div className="content-price-boost">
									<p className="price-boost">
										{formatMontant(offer?.prix)} HT
									</p>
								</div>
								<div className="content-description-boost">
									<p className="description-boost">
										Reportage/Lifestyle/Video Corporate
									</p>
								</div>
								<div className="container-btn-choise-boost">
									{!item?.social_boosted &&
										!item?.promosocial_boosted && (
											<button
												className="btn btn-choice-boost"
												data-bs-toggle="modal"
												data-bs-target="#BoostModal"
												onClick={() => {
													setValue({
														slug: item?.slug,
														duration: offer?.duree,
														amount: offer?.prix,
														type: "promo",
														endpoint: url,
													});
												}}
											>
												Choisir
											</button>
										)}
								</div>
								{data?.length - 1 !== i && (
									<div className="b-b-1 mb-2"></div>
								)}
							</div>
						))}
						<div className="d-flex flex-column align-items-center mt-3">
							{(item?.social_boosted || item?.promosocial_boosted) && (
								<>
									<button className="badge-boost">
										<FaCheckDouble />
										Déjà boostée
									</button>
									{item?.social_boost?.boost?.date_fin ? (
										<div className="mt-2 text-dark">
											<span className="me-2">
												expire le
											</span>
											<span>
												{formattedBoostDate(
													item?.social_boost
														?.boost
														?.date_fin
												)}
											</span>
										</div>
									) : (
										<span className="mt-2">
											Aucune date n'a été trouvée
										</span>
									)}
								</>
							)}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Boost;

export function useOfferBoost() {
	const { data, isLoading } = useGetOffersQuery();
	let AppBoost = data?.filter((item) => item.type_boost === TypeBoost.simple);
	let networkBoost = data?.filter((item) => item.type_boost === TypeBoost.reseaux);
	let networkWithVideoBoost = data?.filter(
		(item) => item.type_boost === TypeBoost.video_reseau
	);

	return {
		AppBoost,
		networkBoost,
		networkWithVideoBoost,
		isLoading,
	};
}
