import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import { IFaq, TFaqForm } from "./faq.type";

const tags = {
	faq: "faq",
};

export const FaqApi = createApi({
	reducerPath: "faqApi",
	tagTypes: [tags.faq],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getFaq: builder.query<PaginationResults<IFaq>, { title: IFaq["title"] | null }>({
			query: (query) => QueryUrl(`faq/`, query),
			providesTags: [tags.faq],
		}),
		addFaq: builder.mutation<IFaq, TFaqForm>({
			invalidatesTags: [tags.faq],
			query: (data) => {
				return {
					url: `faq/`,
					method: "POST",
					body: data,
				};
			},
		}),
		updateFaq: builder.mutation<IFaq, { slug?: string; data: TFaqForm | FormData }>({
			invalidatesTags: [tags.faq],
			query: ({ slug, data }) => {
				return {
					url: `faq/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteFaq: builder.mutation<IFaq, string>({
			query: (slug) => ({
				url: `faq/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.faq],
		}),
	}),
});

export const { useAddFaqMutation, useGetFaqQuery, useDeleteFaqMutation, useUpdateFaqMutation } =
	FaqApi;
