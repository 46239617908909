import React from "react";
import { CustomInput } from "../../common/CustomInput";
import ReactQuill from "react-quill";
import { BtnSubmit } from "../../common/Button";
import useServiceForm from "./useForm/useServiceForm";
import { FormError } from "../../common/CustomInputAdmin";
import PhoneInput from "react-phone-input-2";
import ReCAPTCHA from "react-google-recaptcha";
import { RECAPTCHA_SITE_KEY } from "../../../utils/http";
import ReactFlagsSelect from "react-flags-select";
import { CountryLabels } from "./Country";

const ServiceForm = () => {
	const {
		register,
		onsubmit,
		errors,
		isLoading,
		country,
		handleCountrySelect,
		phoneWhatshap,
		handleChangePhone,
		onChangeRecaptcha,
		message,
		handleChangeMessage,
		user,
		messageRef,
	} = useServiceForm();

	return (
		<div className="specialites-form-container pt-5">
			<h3>Remplir le formulaire</h3>
			<form onSubmit={onsubmit}>
				<div className="row">
					<div className="mb-3 col-md-6">
						<CustomInput
							label="Prénom*"
							type="text"
							id="prenom"
							placeholder="Prénom"
							col="col-12"
							{...register("prenom")}
							error={errors?.prenom}
							defaultValue={user?.firstname}
						/>
					</div>
					<div className="mb-3 col-md-6">
						<CustomInput
							label="Nom*"
							type="text"
							id="prenom"
							placeholder="Nom"
							col="col-12"
							{...register("nom")}
							error={errors?.nom}
							defaultValue={user?.lastname}
						/>
					</div>

					<div className="mb-3 col-md-6">
						<CustomInput
							label="Fonction/intitulé du poste*"
							type="text"
							id="fonction"
							placeholder="Fonction/intitulé du poste*"
							col="col-12"
							{...register("fonction")}
							error={errors?.fonction}
						/>
					</div>
					<div className="mb-3 col-md-6">
						<CustomInput
							label="Organisation*"
							type="text"
							id="organisation"
							placeholder="Organisation"
							col="col-12"
							{...register("organisation")}
							error={errors?.organisation}
						/>
					</div>

					<div className="mb-3 col-md-6">
						<CustomInput
							label="Email"
							type="email"
							id="email"
							placeholder="Email"
							col="col-12"
							{...register("email")}
							error={errors?.email}
							defaultValue={user?.email}
						/>
					</div>

					<div className="mb-3 col-md-6">
						<label htmlFor="pays" className="custom-form-label">
							Pays
						</label>
						{/* <CountrySelector
							onChange={handleCountrySelect}
							name="country"
							placeholder="Pays"
							value={country}
							containerClass="tx country-select-container"
						/> */}
						<ReactFlagsSelect
							selected={country}
							onSelect={handleCountrySelect}
							customLabels={CountryLabels}
							placeholder="Pays"
							searchable
						/>
						<FormError error={errors.pays} />
					</div>

					<div className="mb-3 col-md-6 react-custom-tel-input-container">
						<label htmlFor="pays" className="custom-form-label">
							Numéro de téléphone(whatshapp)
						</label>
						<PhoneInput
							country={"fr"}
							value={phoneWhatshap}
							onChange={(phone) => {
								handleChangePhone(phone);
							}}
							containerClass="form-control auth-form-control mb-3 p-0"
							inputClass="react-custom-tel-input"
							placeholder="Numéro de téléphone(whatshapp)"
						/>
						<FormError error={errors?.phone} />
					</div>

					<div className="mb-3 col-md-6">
						<CustomInput
							label="Quels services recherchez-vous?"
							type="text"
							id="pays"
							placeholder="Quels services recherchez-vous?"
							col="col-12"
							{...register("service")}
							error={errors?.service}
						/>
					</div>

					<div className="mb-3 col-md-6">
						<CustomInput
							label="Objet*"
							type="text"
							id="objet"
							placeholder="Objet"
							col="col-12"
							{...register("object")}
							error={errors?.object}
						/>
					</div>
					<div className="col-12 mb-4">
						<ReactQuill
							className="editor-cgu"
							defaultValue={message}
							onChange={handleChangeMessage}
							ref={messageRef}
						/>
						<FormError error={errors?.message} />
					</div>
					<div className="col-md-6">
						<ReCAPTCHA
							sitekey={RECAPTCHA_SITE_KEY}
							onChange={onChangeRecaptcha}
						/>
						<FormError error={errors.captcha_value} />
					</div>
					<div className="col-md-6  offset-md-6 my-5">
						<BtnSubmit label="Envoyer" isLoading={isLoading} />
					</div>
				</div>
			</form>
		</div>
	);
};

export default ServiceForm;
