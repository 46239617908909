import { ISlide } from "../../../../utils/api/slide/slide.type";
import { ApiBaseUrl } from "../../../../utils/http";
import { BtnSubmit } from "../../../common/Button";
import { FormError } from "../../../common/CustomInputAdmin";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useEditSlide from "../../admin/SettingAdmin/requestSettings/useEditSlide";

const EditSlideModal = ({ item }: { item?: ISlide }) => {
	const { register, onSubmit, errors, image, handleChangeImage, isLoading } =
		useEditSlide(item);
	return (
		<div
			className="modal fade"
			id="EditSlideModal"
			aria-labelledby="AddDeliveryModeLabel"
			aria-hidden="true"
			data-bs-backdrop="static"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id="EditSlideModal"
						>
							Editer une slide
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="col-12 mb-3">
									<label
										className="form-label label-form-admin"
										htmlFor="nom"
									>
										Noms du prestataire
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Noms complet du prestataire"
										id="nom"
										{...register("nom")}
									/>
									{errors?.nom && (
										<FormError error={errors?.nom} />
									)}
								</div>
								<div className="col-12 mb-3">
									<label
										className="form-label label-form-admin"
										htmlFor="fonction"
									>
										Entreprise
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Entreprise"
										id="fonction"
										{...register("fonction")}
									/>
									{errors?.fonction && (
										<FormError error={errors?.fonction} />
									)}
								</div>
								<div className="col-12 mb-3">
									<label
										className="form-label label-form-admin"
										htmlFor="image_couverture"
									>
										Image
									</label>
									<input
										type="file"
										className="form-control form-control-input-admin"
										id="image_couverture"
										multiple={false}
										onChange={handleChangeImage}
									/>
									{errors?.image_couverture && (
										<FormError
											error={errors?.image_couverture}
										/>
									)}
								</div>
								<div className="col-md-6">
									<img
										src={
											image
												? image
												: ApiBaseUrl +
												  item?.image_couverture
										}
										width="100%"
										height="300"
									/>
								</div>
								<div className="col-md-4 offset-md-8 submit-btn-col">
									<div className="flex-r py-3">
										<BtnSubmit
											isLoading={isLoading}
											label="Enregistrer"
										/>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditSlideModal;
