export const Color = {
	themeColor: "#FFA73C",
	secondary: "#fa983a",
	bgGray: "#F1F1F1",
	success: "#FFA73C",
	theme: "#FFA73C",
};
export const PaginationStyle = (active, page) => {
	let activeStyle: any = {};

	if (active) {
		activeStyle.backgroundColor = "#BDBDBD";
		activeStyle.color = "#FFFFFF";
		activeStyle.padding = "10px 15px";
		activeStyle.borderRadius = "10px";
		activeStyle.margin = "0px 5px";
		activeStyle.textDecoration = "none";
	} else {
		activeStyle.backgroundColor = "#FFFFFF";
		activeStyle.color = "#BDBDBD";
		activeStyle.border = "none";
		activeStyle.padding = "10px 15px";
		activeStyle.borderRadius = "10px";
		activeStyle.margin = "0px 5px";
		activeStyle.textDecoration = "none";
	}
	if (typeof page === "string") {
		activeStyle.backgroundColor = "white";
		activeStyle.color = "black";
	}
	return activeStyle;
};
