import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
// import Skeleton from "react-loading-skeleton";

function ProductListAdminTableSkeleton() {
  return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							N°
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Prenoms et Noms
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Nom Société
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Metiers
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Adresse
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Statut
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Action
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
  );
}

export default ProductListAdminTableSkeleton;
