import "./AddUserModal.scss";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { FormError } from "../../../common/CustomInputAdmin";
import { Modal, ModalProps } from "reactstrap";
import { IModalProps } from "../../../../utils/type";
import { BtnSubmit } from "../../../common/Button";
import { getImage } from "../../../../utils/utils";
import useCrudAbout from "../../admin/About/useForm/useCrudAbout";
import { IAbout } from "../../../../utils/api/about/about.type";
import ReactQuill from "react-quill";

// @ts-ignore
const AddAboutModal: React.FC<ModalProps & IModalProps<IAbout>> = ({
	isOpen,
	closeModal,
	item,
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		avatar,
		handleChangeImage,
		reset,
		description,
		handleChangeDescription,
		descriptionRef,
	} = useCrudAbout(closeModal, item);

	return (
		<Modal isOpen={isOpen} size="lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title modal-title-custom" id="AddAboutModal">
						{item ? "Modifier" : "Ajouter"} une section
					</h5>
					<CloseModalBtn onClick={reset} />
				</div>
				<div className="p-3">
					<form onSubmit={onSubmit}>
						<div className="row pt-2">
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Titre
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Titre"
										{...register("titre")}
									/>
									<FormError error={errors?.titre?.message} />
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Type
										<span className="text-danger">*</span>
									</label>
									<select
										{...register("type_propos")}
										id="type_propos"
										className="form-select espace-admin-form-select-control"
										style={{ backgroundColor: "#f2f2f2" }}
									>
										<option value="histoire">
											Histoire
										</option>
										<option value="idee">Idée</option>
									</select>
									<FormError
										error={errors?.type_propos?.message}
									/>
								</div>
							</div>
							<div
								className={`mb-3 ${
									item || avatar ? "col-8" : "col-12"
								}`}
							>
								<label
									className="form-label label-form-admin"
									htmlFor="fileAdmin"
								>
									Image
								</label>
								<input
									id="fileAdmin"
									type="file"
									accept="image/*"
									onChange={(e) => handleChangeImage(e)}
									className="form-control form-control-input-admin"
								/>
							</div>
							{(item || avatar) && (
								<div className="col-md-4">
									<img
										src={
											avatar
												? URL.createObjectURL(
														avatar
												  )
												: getImage(item?.image)
										}
										id="fileAdmin"
										width="200"
										height="200"
									/>
								</div>
							)}
							<div className="col-12 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Message
										<span className="text-danger">*</span>
									</label>
									{item ? (
										<ReactQuill
											className="editor-cgu"
											value={description}
											onChange={handleChangeDescription}
											ref={descriptionRef}
										/>
									) : (
										<ReactQuill
											className="editor-cgu"
											defaultValue={description}
											onChange={handleChangeDescription}
											ref={descriptionRef}
										/>
									)}
									<FormError error={errors?.content?.message} />
								</div>
							</div>
						</div>
						<div className="offset-md-6 col-md-6 mb-4 mt-5">
							<BtnSubmit
								isLoading={isLoading}
								label={item ? "Modifier" : "Ajouter"}
							/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddAboutModal;
