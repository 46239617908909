import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { SocialMediaFormData } from "../../../../../utils/api/about/about.type";
import { useUpdateSocialMediaMutation } from "../../../../../utils/api/about/about.api";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";

function useSocialMediaForm() {
  const validationSchema = yup.object().shape({
    facebook: yup.string().url().nullable().label("Facebook").notRequired(),
    // .matches(
    // 	/(?:(?:http|https):\/\/)?(?:www.)?facebook.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[?\w\-]*\/)?(?:profile.php\?id=(?=\d.*))?([\w\-]*)?/,
    // 	"Lien facebook invalide"
    // )
    instagram: yup.string().url().nullable().label("Instagram"),
    // .matches(
    // 	/^\s*(http\:\/\/)?instagram\.com\/[a-z\d-_]{1,255}\s*$/i,
    // 	"Lien instagram  invalide"
    // )

    linkedin: yup.string().url().nullable().label("Linkedin"),
    // .matches(
    // 	/^(http(s)?:\/\/)?([\w]+\.)?linkedin\.com\/(pub|in|profile)/,
    // 	"Lien linkedin  invalide"
    // )
    twitter: yup.string().url().nullable().label("Twitter"),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<SocialMediaFormData>({
    resolver: yupResolver(validationSchema),
  });

  const [updateSocialMedia, { isLoading, isSuccess, error, isError }] =
    useUpdateSocialMediaMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: `Modifié avec succès`,
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message ? err?.data?.message : "Une erreur a survenu",
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);

  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: SocialMediaFormData) => {
    if (Object.values(data)?.some((val) => val)) {
      updateSocialMedia({ slug: data?.slug, data });
    } 
    
  };

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading: isLoading,
  };
}
export default useSocialMediaForm;
