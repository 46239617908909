import React from "react";
import "./Help.css";


const Help = () => {
	return (
		<>
			<div className="row">
				<div className="col-xl-12 m-b-5">
					<div className="card-dashboard m-b-15">
                        <div>
					        <h3 className="article-title">Paramètres</h3>
                        </div>
                       
					</div>
				</div>
			</div>
		</>
	);
};
export default Help;
