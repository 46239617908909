import "../ProjectListing/ProjectListing.css";
import { createMarkup, getImage, useLocationState } from "../../../../utils/utils";
import moment from "moment";

const DetailProjectTabs = () => {
	const project = useLocationState<any>(undefined);
	return (
		<div>
			<div className="flex-sb">
				<div>
					<h4 className="titre-annonce-projet">{project?.title}</h4>
				</div>
			</div>
			<div className="row mt-4 b-b-1">
				<div className="col-md-7">
					<div className="flex-sb align-items-center pb-2">
						<div className="d-flex">
							<div>
								<img
									src={getImage(project?.images[0]?.picture)}
									alt="project"
									className="listing-annonce-detail-img"
									height="40px"
									width="40px"
								/>
							</div>
							<div className="ps-2">
								<p className="texte-detail-annonce-projet">
									{project?.user?.firstname}
								</p>
								<p className="texte-detail-annonce-projet">
									{project?.country} {project?.region}
								</p>
							</div>
						</div>
						<div>
							<p className="texte-detail-annonce-projet">
								{moment(project?.created_at).fromNow()}
							</p>
						</div>
					</div>
				</div>
			</div>

			<div
				className="mb-2 pt-4 text-description-annonce-detail"
				dangerouslySetInnerHTML={createMarkup(project?.description)}
			/>
			<div className="pt-5">
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Grille salariale:
						</p>
					</div>
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-libelle">
							{project?.budget[0]?.name}
						</p>
					</div>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Type de contrat:
						</p>
					</div>
					<div className="col-md-4 pb-2">
						<p
							className={`texte-detail-annonce-info-libelle ${
								project?.type_contract === "cdi" ||
								project?.type_contract === "cdd"
									? "text-uppercase"
									: "text-capitalize"
							}`}
						>
							{project?.type_contract}
						</p>
					</div>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Date d’expiration:
						</p>
					</div>
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-libelle">25/04/2021</p>
					</div>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Compétences requises:
						</p>
					</div>
					<div
						className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
						dangerouslySetInnerHTML={createMarkup(project?.skills)}
					/>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Prérequis du projet :
						</p>
					</div>
					<div
						className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
						dangerouslySetInnerHTML={createMarkup(project?.prerequisite)}
					/>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Exigences du projet:
						</p>
					</div>
					<div
						className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
						dangerouslySetInnerHTML={createMarkup(project?.requirements)}
					/>
				</div>
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">
							Secteur et Metier:
						</p>
					</div>
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-libelle">
							{project?.sector?.name}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailProjectTabs;
