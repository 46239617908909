import DatePicker from "react-date-picker";
import { BiCalendar } from "react-icons/bi";
import { BsFillCameraFill } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import { IUser } from "../../../../redux/slice/User.slice";
import DefaultPp from "../../../assets/appImages/default-profil.png";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import "./AddUserModal.scss";
import useUserForm from "./forms/useUserForm";
type AddUserModalProps = {
  modalId: string;
  user?: IUser;
};
const AddUserModal = ({ modalId, user }: AddUserModalProps) => {
  const onHide = () => {
    document.getElementById(modalId)?.classList.remove("show");
    $(".modal-backdrop").remove();
    $(`#${modalId}`).hide();
    $(`body`).css({ overflow: "scroll" });
  };
  const {
    register,
    onSubmit,
    errors,
    isLoading,
    preview,
    handelImageChange,
    handleChangeDate,
    bornDate,
  } = useUserForm(onHide, user);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title modal-title-custom"
              id={`${modalId}Title`}
            >
              {user ? "Modifier" : "Ajouter"} un utilisateur
            </h5>
            <CloseModalBtn />
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="mb-4">
                <div className="profile-pic">
                  <label className="-label" htmlFor="file">
                    <span className="glyphicon glyphicon-camera">
                      <BsFillCameraFill />
                    </span>
                    <span>Change Image</span>
                  </label>
                  <input
                    accept="image/*"
                    id="file"
                    onChange={handelImageChange}
                    type="file"
                  />
                  <img src={preview || DefaultPp} id="output" width="200" />
                </div>
              </div>
              <div className="row auth-form-row">
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="nom" aria-labelledby="nom">
                      Nom
                    </label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="nom"
                      {...register("lastname")}
                      data-testid="lastnameId"
                    />
                    {errors.lastname?.message && (
                      <ErrorMessage message={errors.lastname.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="prenom" aria-labelledby="prenom">
                      Prénom
                    </label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="prenom"
                      {...register("firstname")}
                      data-testid="firstnameId"
                    />
                    {errors.firstname?.message && (
                      <ErrorMessage message={errors.firstname.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="civilite" aria-labelledby="civilite">
                      Civilité
                    </label>
                    <select
                      id="civilite"
                      {...register("civilite")}
                      data-testid="civiliteId"
                      className="form-select"
                      style={{
                        backgroundColor: "#F3F3F3",
                        borderColor: "#F3F3F3",
                        padding: "15px",
                        borderRadius: "5px",
                      }}
                    >
                      <option value="homme">Homme</option>
                      <option value="femme">Femme</option>
                    </select>
                    {errors.civilite?.message && (
                      <ErrorMessage message={errors.civilite.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group nice-dates-container">
                    <label
                      htmlFor="date_de_naissance"
                      aria-labelledby="date_de_naissance"
                    >
                      Date de naisssance
                    </label>
                    <span className="text-danger">*</span>

                    <DatePicker
                      className="form-control auth-form-control"
                      value={bornDate}
                      onChange={(date) => handleChangeDate(date)}
                      name="date"
                      maxDate={new Date()}
                      calendarIcon={
                        <BiCalendar
                          style={{
                            color: "#200E3280",
                            fontSize: "24px",
                            position: "absolute",
                            top: "25%",
                            right: "5",
                          }}
                        />
                      }
                      clearIcon={<IoClose />}
                    />
                    {errors.birthdate?.message && (
                      <ErrorMessage message={errors.birthdate.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="adresse" aria-labelledby="adresse">
                      Adresse
                    </label>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="adresse"
                      {...register("adresse")}
                      data-testid="adresseId"
                    />
                    {errors.adresse?.message && (
                      <ErrorMessage message={errors.adresse.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="code_postal" aria-labelledby="code_postal">
                      Code postal
                    </label>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="code_postal"
                      {...register("postal_code")}
                      data-testid="postalCodeId"
                    />
                    {errors.postal_code?.message && (
                      <ErrorMessage message={errors.postal_code.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="telephone" aria-labelledby="telephone">
                      Numéro de téléphone
                    </label>
                    <span className="text-danger">*</span>
                    <input
                      type="tel"
                      className="form-control auth-form-control"
                      id="telephone"
                      {...register("phone")}
                      data-testid="phoneId"
                    />
                    {errors.phone?.message && (
                      <ErrorMessage message={errors.phone.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label
                      htmlFor="numero-whatshapp"
                      aria-labelledby="numero-whatshapp"
                    >
                      Numéro whatsapp
                    </label>
                    <input
                      type="tel"
                      className="form-control auth-form-control"
                      id="numero-whatshapp"
                      {...register("whatsapp")}
                      data-testid="whatshappNumerId"
                    />
                    {errors.whatsapp?.message && (
                      <ErrorMessage message={errors.whatsapp.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="pseudo" aria-labelledby="pseudo">
                      Pseudonyme
                    </label>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="pseudo"
                      {...register("pseudonym")}
                      data-testid="pseudoId"
                    />
                    {errors.pseudonym?.message && (
                      <ErrorMessage message={errors.pseudonym.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-6 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="email" aria-labelledby="email">
                      Adresse Email
                    </label>
                    <span className="text-danger">*</span>
                    <input
                      type="email"
                      className="form-control auth-form-control"
                      id="email"
                      {...register("email")}
                      data-testid="emailId"
                    />
                    {errors.email?.message && (
                      <ErrorMessage message={errors.email.message} />
                    )}
                  </div>
                </div>
                {/* {!!!user && (
                  <>
                    <div className="col-md-6 auth-input-col">
                      <div className="form-group auth-form-group">
                        <label htmlFor="password" aria-labelledby="password">
                          Mot de passe
                        </label>
                        <span className="text-danger">*</span>
                        <input
                          type="password"
                          className="form-control auth-form-control"
                          id="password"
                          {...register("password")}
                          data-testid="password"
                        />
                        {errors.password?.message && (
                          <ErrorMessage message={errors.password.message} />
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 auth-input-col">
                      <div className="form-group auth-form-group">
                        <label
                          htmlFor="confirmPassword"
                          aria-labelledby="confirmPassword"
                        >
                          Confimer mot de passe
                        </label>
                        <span className="text-danger">*</span>
                        <input
                          type="password"
                          className="form-control auth-form-control"
                          id="confirmPassword"
                          {...register("confirmPassword")}
                          data-testid="confirmPassword"
                        />
                        {errors.confirmPassword?.message && (
                          <ErrorMessage
                            message={errors.confirmPassword.message}
                          />
                        )}
                      </div>
                    </div>
                  </>
                )} */}
                <div className="col-md-6 offset-md-3 auth-submit-btn-container">
                  {isLoading && (
                    <button
                      type="button"
                      className="btn btn-primary auth-submit-btn"
                    >
                      Chargement...
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn btn-primary auth-submit-btn"
                    >
                      {user ? "Modifier" : "Ajouter"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
