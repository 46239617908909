import { Outlet } from 'react-router-dom';
import HeaderTrader from './navBar/headerTrader/HeaderTrader';
import SidebarTrader from './navBar/SidebarTrader/SidebarTrader';
import './TraderStyle.css';

const TraderLayout = () => {
	return (
		<div className="container-fluid body-background-dashboard">
			<HeaderTrader />

			<div className="row">
				<div className="nav-container no-view-mobile">
					<SidebarTrader />
				</div>

				<div className="offset-xl-1 offset-lg-1 col-xl-11 col-lg-11 col-md-12 content-position-after-header px-md-3 px-0 h-90vh">
					<div className="container-fluid pt-5">
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TraderLayout;
