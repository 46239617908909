import { useEffect, useState } from 'react'
import {
  BsCartDash,
  BsFacebook,
  BsLinkedin,
  BsTelephone,
  BsTwitter,
} from 'react-icons/bs'
import { IoLocationOutline } from 'react-icons/io5'
import { useDispatch } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share'
import StarRatingComponent from 'react-star-rating-component'
import Swal from 'sweetalert2'
import { useAppSelector } from '../../../../redux/hooks'
import { addCartItem } from '../../../../redux/slice/cart.slice'
import {
  useAddProductFavoriteMutation,
  useDeleteFavoriteProductMutation,
  useProductVisitorFromLocation,
} from '../../../../utils/api/product/product.api'
import { APP_URL } from '../../../../utils/http'
import { Color } from '../../../../utils/theme'
import { getImage } from '../../../../utils/utils'
import product from '../../../assets/appImages/productf1.png'
import Divider from '../../../shared/Divider'
import {
  IconChatDots,
  IconDelete,
  IconSend,
  IconWallet,
} from '../../../shared/Icons'
import Annonce from "../ProjectListing/Annonce";
import MarketPlaceLayout from "./common/MarketPlaceLayout";
import MarketTabsContainer from "./MarketTabsContainer";

const MarketPlaceDetail = () => {
	const location = useLocation();
	const [addItem, { isLoading, isSuccess, isError, error, data }] =
		useAddProductFavoriteMutation();
	const [deleteItem, { isLoading: loading, isSuccess: success, isError: IsErr, error: fail }] =
		useDeleteFavoriteProductMutation();
	const [item, findBySlug] = useProductVisitorFromLocation();
	const { user, token } = useAppSelector((s) => s?.user);
	const [images, setImages] = useState<any>([]);
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		if (item) {
			console.log(item);
			let newImages = item?.images?.map((img) => getImage(img?.picture));
			if (newImages?.length < 4) {
				newImages.unshift(getImage(item?.background_image));
			}

			setImages(newImages);
		}
	}, [item]);

	useEffect(() => {
		if (IsErr) {
			console.log("err", fail);
			const err = fail as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [IsErr]);

	useEffect(() => {
		if (success) {
			console.log("success", data);

			Swal.fire({
				icon: "success",
				title: "Produit supprimé des favoris avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [success]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("success", data);

			Swal.fire({
				icon: "success",
				title: "Produit ajouté aux favoris avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isSuccess]);
	const isActive = (index) => {
		if (selectedIndex === index) return " active";
		return " ";
	};

	const onAddItem = (e, item) => {
		e.preventDefault();
		dispatch(addCartItem(item));
		Swal.fire({
			icon: "success",
			title: "Le produit a bien été ajouté au panier!",
			iconColor: Color.themeColor,
			confirmButtonText: "Finaliser ma commande",
			cancelButtonText: "Poursuivre mes achats",

			showConfirmButton: true,
			showCancelButton: true,
		}).then((result) => {
			if (result.isConfirmed) {
				navigate("/app/commandes/panier");
			} else {
				Swal.close();
			}
		});
	};

	const AddFavorite = (e, item) => {
		e.preventDefault();
		addItem({ user: user?.id, product: item?.id });
	};

	const DeleteFavorite = (e, item) => {
		e.preventDefault();
		let elem = item?.favorites?.find((f) => f?.user?.id === user?.id);
		deleteItem(elem?.slug);
	};

	const isAuthor = (item) => {
		return (
			user?.id &&
			(user?.id === item?.commerce?.author || user?.id === item?.commerce?.author?.id)
		);
	};

	const isFavorite = (item) => {
		if (!item?.favorites?.length) return false;
		let elem = item?.favorites?.find((f) => f?.user?.id === user?.id);
		return elem?.id ? true : false;
	};
	return (
		<MarketPlaceLayout>
			<div className="marketplace-detail">
				<div className="row">
					<div className="col-lg-6">
						<div className="row">
							<div className="col-12 mb-3">
								<div className="img-market-container h-full">
									<img
										src={images[selectedIndex]}
										alt="product"
										className="img-marketplace-det"
									/>
								</div>
							</div>
							{images?.map((img, index) => (
								<div
									className={`col-3 col-marketplace-det ${isActive(
										index
									)}`}
									key={index}
								>
									<div className="img-market-container h-full">
										<img
											src={img}
											alt="product"
											className={`img-marketplace-det `}
											onClick={() => {
												window.scrollTo(10, 10);
												setSelectedIndex(index);
											}}
										/>
									</div>
								</div>
							))}
						</div>
					</div>
					<div className="col-lg-6 px-lg-5 mt-3 mt-lg-0">
						<h2 className="marketplace-det-title">
							{item?.commerce?.name}
						</h2>
						<h3 className="marketplace-det-name">{item?.name}</h3>
						<div>
							<StarRatingComponent
								name="rate2"
								editing={false}
								starCount={5}
								value={parseFloat(item?.rate_average)}
								starColor="#FFA73C"
								emptyStarColor="#D4D6D5"
							/>
						</div>
						<Divider />
						<h5 className="product-det-price mt-3">{item?.price} FCFA</h5>
						<div>
							<div className="m-det-info d-flex gap-1 mb-2">
								<IoLocationOutline />
								<h6>{item?.commerce?.location}</h6>
							</div>
							<div className="m-det-info d-flex gap-1">
								<IconDelete />
								<h6>Livraison sous 48h</h6>
							</div>
							<div className="flex-m gap-3 mt-3 align-items-baseline">
								<h6 className="m-quantity m-0">Quantité</h6>
								<h6 className="market-select">{item?.quantity}</h6>

								<h6 className="m-stock-text m-0">En stock.</h6>
							</div>
							{!isAuthor(item) ? (
								<div className="mt-5">
									{token ? (
										<button
											onClick={() => {
												//TODO: psser le vendeur sous forme de state
												navigate("/app/mon-espace-client/messages", {
													state: item?.commerce?.author,
												});
											}}
											className="market-det-btn btn-send-message"
										>
											ENVOYER UN MESSAGE
										</button>
									) : null}

									<button
										className="market-det-btn btn-add-cart"
										onClick={(e) => onAddItem(e, item)}
									>
										<BsCartDash
											style={{
												fontSize: 24,
												marginRight: 25,
											}}
										/>
										AJOUTER AU PANIER
									</button>
									{token ? (
										isFavorite(item) ? (
											<button
												className="market-det-btn btn-add-bookmark"
												onClick={(e) =>
													DeleteFavorite(
														e,
														item
													)
												}
												disabled={loading}
											>
												Supprimer de mes favoris
											</button>
										) : (
											<button
												className="market-det-btn btn-add-bookmark"
												onClick={(e) =>
													AddFavorite(e, item)
												}
												disabled={isLoading}
											>
												Ajouter aux favoris
											</button>
										)
									) : null}
								</div>
							) : null}
							<div className="mt-4">
								<ShareSocialMedia
									title={item?.name}
									category="Produit"
								/>
								<div className="flex-sb-m mt-2 mt-5">
									<div className="wallet-container flex-col-c-m">
										<IconWallet />
										<span className="text-m">
											Paiement intégré
										</span>
									</div>
									<div className="send-container flex-col-c-m">
										<IconSend />
										<span className="text-m">
											Livraison internationale
										</span>
									</div>
									<div className="chat-container flex-col-c-m">
										<IconChatDots />
										<span className="text-m">
											Assistance
										</span>
									</div>
								</div>
								<div className="flex-c mt-5">
									<button className="bs-btn-call no-style-btn item-c">
										<BsTelephone
											style={{
												fontSize: 20,
											}}
										/>
										<span>
											Besoin d'un conseil ?
											Contactez-nous
										</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Divider styles="mt-4 mb-3" />
				<MarketTabsContainer item={item} />
			</div>
		</MarketPlaceLayout>
	);
};
export function ShareSocialMedia({
	title = "hello",
	category,
}: {
	title?: string;
	category: string;
}) {
	return (
		<>
			<h3 className="share-social-title">Partager sur les réseaux sociaux</h3>
			<Divider />
			<div className="flex-sb-m mt-2">
				<FacebookShareButton
					url={APP_URL + location?.pathname}
					quote={`Salut, ${
						category === "Annonce" ? "cette" : "ce"
					} ${category} ${title} pourrait vous intéresser`}
					hashtag="#Munganno"
				>
					<BsFacebook
						style={{
							color: "#1877F2",
							fontSize: 22,
						}}
					/>
					<span className="text-m">Facebook</span>
				</FacebookShareButton>
				<TwitterShareButton
					url={APP_URL + location?.pathname}
					title={`Salut, ${
						category === "Annonce" ? "l'" : "le"
					} ${category} ${title} pourrait vous intéresser`}
					hashtags={["Munganno"]}
				>
					<BsTwitter
						style={{
							color: "#1DA1F2",
							fontSize: 22,
						}}
					/>
					<span className="text-m">Twitter</span>
				</TwitterShareButton>
				<LinkedinShareButton
					url={APP_URL + location?.pathname}
					summary={`Salut, ${
						category === "Annonce" ? "cette" : "ce"
					} ${category} ${title} pourrait vous intéresser`}
					source="Munganno"
				>
					<BsLinkedin
						style={{
							color: "#0A66C2",
							fontSize: 22,
						}}
					/>
					<span className="text-m">Linkedin</span>
				</LinkedinShareButton>
			</div>
		</>
	);
}

export default MarketPlaceDetail
