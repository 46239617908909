import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import ShopListAdminTable from '../../Tables/Admin/ShopListAdminTable/ShopListAdminTable'
import './ShopListAdmin.css'

const ShopListAdmin = () => {
  const location = useLocation()
  const [word, setWord] = React.useState<string | any>('')
  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (location?.search && location?.search?.split('=')?.length) {
      setWord(location?.search?.split('=').pop())
    }
  }, [location])

  React.useEffect(() => {
    if (!user?.chat && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
    console.log('location', location)
  }, [user])
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Boutiques</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                className="search-input-admin"
                placeholder="Search.."
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ShopListAdminTable word={word} />
      </div>
    </div>
  )
}

export default ShopListAdmin
