import { CKEditor } from "ckeditor4-react";
import DropZoneImage from "../../../shared/DropzoneImage";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";

import Remove from "../../../assets/icons/remove.png";
import useBecomeTraderForm from "./UseBecomeTrader/UseBecomeTraderForm";
import ErrorMessage from "../../../common/ErrorMessage";
import { BtnSubmit } from "../../../common/Button";
import "./BecomeTrader.css";
import Select from "react-select";
import { useGetCategoriesByVisitorQuery } from "../../../../utils/api/category/category.api";
import { useState, useEffect, useCallback } from "react";
import Dropzone, { useDropzone } from "react-dropzone";
import { MdImage } from "react-icons/md";
import GoogleInput from "../../../common/GoogleInput";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate, useLocation } from "react-router-dom";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { FormError } from "../../../common/CustomInput";
import SouscriptionTraderModal from "../../modals/commercant/commandes/SouscriptionTraderModal";

const RemplirFormulaire = () => {
	const { data: categories = [], isSuccess } = useGetCategoriesByVisitorQuery();
	const { register, onSubmit, errors, setValue, setError, isLoading, data } =
		useBecomeTraderForm();
	const navigate = useNavigate();
	const location = useLocation();
	const { user } = useAppSelector((state) => state.user);
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [options, setOptions] = useState<any>([]);
	const [logo, setLogo] = useState<File | any>();
	const [cover, setCover] = useState<File | any>();
	const [files, setFiles] = useState<File[] | any>([]);

	const onDrop = useCallback((acceptedFiles) => {
		// Do something with the files
		console.log("logo", acceptedFiles);
		setLogo(acceptedFiles[0]);
		setValue("logo", acceptedFiles[0]);
	}, []);
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			"image/*": [".png", ".gif", ".jpeg", ".jpg"],
		},
		maxFiles: 1,
	});

	const handleCountrySelect = (option: any) => {
		setCountry(option);
		console.log("country", option);
		setValue("country", option.name);
	};

	const handleStateSelect = (option: any) => {
		setValue("region", option.name);
		setState(option);
	};

	const handleCitySelect = (option: any) => {
		setValue("city", option.name);
		setCity(option);
	};

	useEffect(() => {
		if (!location?.state) {
			setTimeout(() => {
				navigate("/devenir-commercant", { replace: false });
			}, 50);
		}
	}, [location]);

	useEffect(() => {
		if (user) {
			setValue("author", user?.id);
		}
	}, [user]);

	useEffect(() => {
		if (isSuccess) {
			setOptions(
				categories?.map((item) => {
					return {
						label: item?.name,
						value: item?.id,
					};
				})
			);
		}
	}, [categories, isSuccess]);

	const handleRemove = (e, i) => {
		e.preventDefault();
		let newFiles = files?.filter((file) => files?.indexOf(file) !== i);
		setFiles(newFiles);
		setValue("slides", newFiles);
	};
	const handleChange = (option) => {
		console.log("value", option);
		setValue("category", option?.value);
	};
	return (
		<div className="project-listing-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="content-page-container">
					<div className="container-munngano mt-5">
						<div className="text-center">
							<h2 className="titre-devenir-commercant">
								Intégrer notre réseau de commerçants
							</h2>
							<p className="sous-titre-devenir-commercant">
								Devenez un commerçant Munngano et profitez de nos
								nombreux avantages
							</p>
						</div>
						<div className="form-container px-3 pt-5">
							<form onSubmit={onSubmit}>
								<div className="row mb-4">
									<div className="col-md-6 mb-4 form-group">
										<label
											htmlFor="trading-title"
											className="espace-client-form-label"
										>
											Titre du commerce
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											placeholder="Titre du commerce"
											id="trading-title"
											{...register("name")}
										/>
										{errors.name?.message && (
											<ErrorMessage
												message={
													errors.name.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="pays"
											className="custom-form-label"
										>
											Pays
											<span className="text-danger">
												*
											</span>
										</label>
										<CountrySelector
											onChange={handleCountrySelect}
											name="country"
											placeholder="Choisir un pays"
											value={country}
											containerClass="tx country-select-container mt-2 p-0"
										/>
										{
											<FormError
												error={
													errors?.country
														?.message
												}
											/>
										}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="state"
											className="custom-form-label"
										>
											Région
											<span className="text-danger">
												*
											</span>
										</label>
										<StateSelector
											onChange={handleStateSelect}
											name="state"
											country={country}
											placeholder="Choisir une région"
											countryPlaceholder={
												"Choisir un pays"
											}
											value={state}
											containerClass="tx country-select-container mt-2 p-0"
										/>
										{
											<FormError
												error={
													errors?.region
														?.message
												}
											/>
										}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="state"
											className="custom-form-label"
										>
											Ville
											<span className="text-danger">
												*
											</span>
										</label>
										<CitySelector
											onChange={handleCitySelect}
											name="city"
											state={state}
											placeholder={"Choisir une ville"}
											statePlaceholder={
												"Choisir une région"
											}
											value={city}
											containerClass="tx country-select-container mt-2 p-0"
										/>
										{
											<FormError
												error={
													errors?.city?.message
												}
											/>
										}
									</div>
									<div className="col-md-6 mb-4 form-group">
										<label
											htmlFor="metiers"
											className="espace-client-form-label"
										>
											Métiers
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											placeholder="Métiers"
											id="metiers"
											{...register("profession")}
										/>
										{errors.profession?.message && (
											<ErrorMessage
												message={
													errors.profession
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 mb-4 form-group">
										<label
											htmlFor="metiers"
											className="espace-client-form-label"
										>
											Catégorie
											<span className="text-danger">
												*
											</span>
										</label>
										<Select
											name="category"
											options={options.sort(function (
												a,
												b
											) {
												return a.label.localeCompare(
													b.label
												);
											})}
											onChange={handleChange}
											classNamePrefix="selectionne"
										/>

										{errors.category?.message && (
											<ErrorMessage
												message={
													errors.category
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-12 mb-4 form-group">
										<label
											htmlFor="desc-commerce"
											className="espace-client-form-label"
										>
											Présentation du commerce
											<span className="text-danger">
												*
											</span>
										</label>
										<CKEditor
											initData={""}
											onChange={(e) =>
												setValue(
													"description",
													e.editor.getData()
												)
											}
										/>
										{errors.description?.message && (
											<ErrorMessage
												message={
													errors.description
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-12 mb-4">
										<div className="row px-2">
											{logo?.name ? (
												<div className="col-md-6 px-2 mb-3 d-flex pos-relative">
													<div className="image-area">
														<img
															src={URL.createObjectURL(
																logo
															)}
															className="img-upload-preview"
															alt="Preview"
															width={
																150
															}
															height={
																150
															}
														/>
														<a
															className="remove-image"
															href="#"
															onClick={(
																e
															) => {
																e.preventDefault();
																setLogo(
																	null
																);
																setValue(
																	"logo",
																	null
																);
															}}
															style={{
																display: "inline",
															}}
														>
															<img
																src={
																	Remove
																}
																alt="Remove"
																className="icon-remove-img-preview"
															/>
														</a>
													</div>
												</div>
											) : (
												<>
													<label
														className="consigne-title-steps mb-2 "
														htmlFor="logo"
													>
														Logo du commerce
														<span className="text-danger">
															*
														</span>
													</label>
													<label
														{...getRootProps(
															{
																className:
																	"dropzone container-dropzone-import col-md-12",
															}
														)}
														htmlFor="logo"
													>
														<input
															{...getInputProps()}
															multiple={
																false
															}
															accept="image/*"
															type="file"
															id="logo"
														/>
														<div className="label-img-container">
															<MdImage
																style={{
																	color: "#666666",
																	fontSize: "36px",
																}}
															/>
														</div>
														<p className="fw-700 fs-13 first-label-input-choose-file">
															Déposez
															une image
															ici ou
															sélectionnez
															un
															fichier.
														</p>
														<p className="second-label-input-choose-file fs-14">
															Format
															acceptés :
															JPG, PNG,
															GIF ou
															JPEG,
															inférieurs
															à 200Mo.
														</p>
													</label>
												</>
											)}
											{errors.logo?.message && (
												<ErrorMessage
													message={
														errors.logo
															.message
													}
												/>
											)}
										</div>
									</div>
									<div className="col-md-12 mb-4">
										{cover?.name ? (
											<div className="col-md-6 px-2 mb-3 d-flex pos-relative">
												<div className="image-area">
													<img
														src={URL.createObjectURL(
															cover
														)}
														className="img-upload-preview"
														alt="Preview"
														width={150}
														height={150}
													/>
													<a
														className="remove-image"
														href="#"
														onClick={(
															e
														) => {
															e.preventDefault();
															setValue(
																"background_image",
																null
															);
															setCover(
																null
															);
														}}
														style={{
															display: "inline",
														}}
													>
														<img
															src={
																Remove
															}
															alt="Remove"
															className="icon-remove-img-preview"
														/>
													</a>
												</div>
											</div>
										) : (
											<Dropzone
												accept={{
													"image/*": [
														".png",
														".gif",
														".jpeg",
														".jpg",
													],
												}}
												maxFiles={1}
												onDrop={(files) => {
													console.log(files);
													setCover(files[0]);
													setValue(
														"background_image",
														files[0]
													);
												}}
											>
												{({
													getRootProps,
													getInputProps,
												}) => (
													<div className="row px-2">
														<label
															className="consigne-title-steps mb-2 "
															htmlFor="cover"
														>
															Image
															couverture
															<span className="text-danger">
																*
															</span>
														</label>
														<label
															htmlFor="cover"
															{...getRootProps(
																{
																	className:
																		"dropzone container-dropzone-import col-md-12",
																}
															)}
														>
															<input
																{...getInputProps()}
																multiple={
																	false
																}
																accept="image/*"
																type="file"
																id="cover"
															/>
															<div className="label-img-container">
																<MdImage
																	style={{
																		color: "#666666",
																		fontSize: "36px",
																	}}
																/>
															</div>
															<p className="fw-700 fs-13 first-label-input-choose-file">
																Déposez
																une
																image
																ici
																ou
																sélectionnez
																un
																fichier.
															</p>
															<p className="second-label-input-choose-file fs-14">
																Format
																acceptés
																:
																JPG,
																PNG,
																GIF
																ou
																JPEG,
																inférieurs
																à
																200Mo.
															</p>
														</label>
													</div>
												)}
											</Dropzone>
										)}
										{errors.background_image?.message && (
											<ErrorMessage
												message={
													errors
														.background_image
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-12 mb-4">
										<Dropzone
											accept={{
												"image/*": [
													".png",
													".gif",
													".jpeg",
													".jpg",
												],
											}}
											maxFiles={4}
											onDrop={(file) => {
												console.log(file);
												if (
													file?.length === 0 ||
													[...files, ...file]
														?.length > 4
												) {
													setError("slides", {
														message: "Veuillez télécharger maximum 4 images",
													});
												}
												if (
													[...files, ...file]
														?.length <= 4
												) {
													setFiles([
														...files,
														...file,
													]);
													setValue("slides", [
														...files,
														...file,
													]);
												}
											}}
										>
											{({
												getRootProps,
												getInputProps,
											}) => (
												<div className="row px-2">
													<label
														className="consigne-title-steps mb-2 "
														htmlFor="slides-images"
													>
														Images slides du
														pro (4max)
													</label>
													<label
														htmlFor="slides-images"
														{...getRootProps(
															{
																className:
																	"dropzone container-dropzone-import col-md-12",
															}
														)}
													>
														<input
															{...getInputProps()}
															multiple={
																true
															}
															accept="image/*"
															type="file"
															id="slides-images"
														/>
														<div className="label-img-container">
															<MdImage
																style={{
																	color: "#666666",
																	fontSize: "36px",
																}}
															/>
														</div>
														<p className="fw-700 fs-13 first-label-input-choose-file">
															Déposez
															une image
															ici ou
															sélectionnez
															un
															fichier.
														</p>
														<p className="second-label-input-choose-file fs-14">
															Format
															acceptés :
															JPG, PNG,
															GIF ou
															JPEG,
															inférieurs
															à 200Mo.
														</p>
													</label>
												</div>
											)}
										</Dropzone>
										{errors.slides?.message && (
											<ErrorMessage
												message={
													errors.slides.message
												}
											/>
										)}
									</div>
									<div className="col-md-12 mb-4">
										<div className="d-flex">
											{files?.map((file, i) => (
												<div
													className="image-area"
													key={i}
												>
													<img
														src={URL.createObjectURL(
															file
														)}
														className="img-upload-preview"
														alt="Preview"
														width={100}
														height={100}
													/>
													<a
														className="remove-image"
														href="#"
														style={{
															display: "inline",
														}}
														onClick={(e) =>
															handleRemove(
																e,
																i
															)
														}
													>
														<img
															src={
																Remove
															}
															alt="Remove"
															className="icon-remove-img-preview"
														/>
													</a>
												</div>
											))}
										</div>
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="adresse"
											className="espace-client-form-label"
										>
											Adresse du commerce
											<span className="text-danger">
												*
											</span>
										</label>
										<GoogleInput
											value={""}
											onChange={(value) => {
												console.log(value);
												setValue(
													"location",
													value?.rue
												);
												setValue(
													"longitude",
													value?.longitude
												);
												setValue(
													"latitude",
													value?.latitude
												);
											}}
											className="form-control espace-client-form-control"
										/>
										{/* <input
											type="text"
											className="form-control espace-client-form-control"
											id="adresse"
											placeholder="Adresse du commerce"
											{...register("location")}
										/> */}
										{errors.location?.message && (
											<ErrorMessage
												message={
													errors.location
														.message
												}
											/>
										)}
									</div>

									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="email-commerce"
											className="espace-client-form-label"
										>
											Email du commerce
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="email"
											className="form-control espace-client-form-control"
											id="email-commerce"
											placeholder="Adresse email"
											{...register("email")}
										/>
										{errors.email?.message && (
											<ErrorMessage
												message={
													errors.email.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="phone-com"
											className="espace-client-form-label"
										>
											Téléphone du commerce
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="tel"
											className="form-control espace-client-form-control"
											id="phone-com"
											placeholder="Téléphone"
											{...register("phone")}
										/>
										{errors.phone?.message && (
											<ErrorMessage
												message={
													errors.phone.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="ninea-com"
											className="espace-client-form-label"
										>
											NINEA
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="ninea-com"
											placeholder="NINEA"
											{...register("ninea")}
										/>
										{errors.ninea?.message && (
											<ErrorMessage
												message={
													errors.ninea.message
												}
											/>
										)}
									</div>
									<div className="col-12 form-group mb-4">
										<label
											htmlFor="piece_jointe"
											className="espace-client-form-label"
										>
											Pièce Jointe
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="file"
											className="form-control espace-client-form-control"
											id="piece_jointe"
											accept=".pdf, image/*"
											onChange={(e) => {
												if (e.currentTarget.files) {
													setValue(
														"piece_jointe",
														e.currentTarget
															.files[0]
													);
												}
											}}
										/>
										{errors.piece_jointe?.message && (
											<ErrorMessage
												message={
													errors.piece_jointe
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="whatshapp-co;"
											className="espace-client-form-label"
										>
											Whatsapp du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="whatshapp-co;"
											placeholder="Whatsapp"
											{...register("whatsapp")}
										/>
										{errors.whatsapp?.message && (
											<ErrorMessage
												message={
													errors.whatsapp
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="Facebook"
											className="espace-client-form-label"
										>
											Facebook du pro
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="Facebook"
											placeholder="Facebook"
											{...register("facebook")}
										/>
										{errors.facebook?.message && (
											<ErrorMessage
												message={
													errors.facebook
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="Linkedin"
											className="espace-client-form-label"
										>
											Linkedin du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="Linkedin"
											placeholder="Linkedin"
											{...register("linkedin")}
										/>
										{errors.linkedin?.message && (
											<ErrorMessage
												message={
													errors.linkedin
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="twitter"
											className="espace-client-form-label"
										>
											Twitter du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="twitter"
											placeholder="Twitter"
											{...register("twitter")}
										/>
										{errors.twitter?.message && (
											<ErrorMessage
												message={
													errors.twitter.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor="instagram"
											className="espace-client-form-label"
										>
											Instagram du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="instagram"
											placeholder="Instagram"
											{...register("instagram")}
										/>
										{errors.instagram?.message && (
											<ErrorMessage
												message={
													errors.instagram
														.message
												}
											/>
										)}
									</div>
									<div className="col-md-6 form-group mb-4">
										<label
											htmlFor=""
											className="espace-client-form-label"
										>
											Site internet du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id=""
											placeholder="Site internet"
											{...register("site_web")}
										/>
										{errors.site_web?.message && (
											<ErrorMessage
												message={
													errors.site_web
														.message
												}
											/>
										)}
									</div>
									{/* <div className="col-md-6 form-group mb-4">
										<label
											htmlFor="iteneraire-commerce"
											className="espace-client-form-label"
										>
											Itinéraire du commerce
										</label>
										<input
											type="text"
											className="form-control espace-client-form-control"
											id="iteneraire-commerce"
											placeholder="I"
											{...register("longitude")}
										/>
										<span className="text-alert-custom">
											Afficher/Masquer les coordonnées! Utiliser ma position
										</span>
										{errors.longitude?.message && (
											<ErrorMessage message={errors.longitude.message} />
										)}
									</div> */}
									<div className="col-md-4 offset-md-8 mt-4">
										<BtnSubmit
											label="Valider"
											isLoading={false}
										/>
										{/* <button
											type="button"
											className="btn btn-primary auth-submit-btn "
											data-bs-toggle="modal"
											data-bs-target="#SouscriptionTraderModal"
										>
											Valider
										</button> */}
									</div>
								</div>
							</form>
						</div>
					</div>
					<SouscriptionTraderModal trader={data} />
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default RemplirFormulaire;
