import Dropzone from "react-dropzone-uploader";
import "react-dropzone-uploader/dist/styles.css";
import { getDroppedOrSelectedFiles } from "html5-file-selector";
import { MdImage } from "react-icons/md";
import { Color } from "../../utils/theme";

interface PropsType {
	label: string;
	onChange: (file: any) => void;
	id: any;
}

const DropZoneImage: React.FC<PropsType> = ({ label, onChange, id }) => {
	const getFilesFromEvent = async (e) => {
		const chosenFiles = await getDroppedOrSelectedFiles(e);
		console.log(
			"chosenFiles.map(f => f.fileObject)",
			chosenFiles.map((f) => f.fileObject)
		);
		return chosenFiles.map((f) => f.fileObject);
	};
	// const getFilesFromEvent = async (e: DragEvent<HTMLInputElement>) => {
	// 	return new Promise((resolve) => {
	// 	  getDroppedOrSelectedFiles(e).then((chosenFiles) => {
	// 		resolve(chosenFiles.map((f) => f.fileObject));
	// 	  });
	// 	});
	//   };
	const InputChooseFile = ({ accept, onFiles, files, getFilesFromEvent }) => {
		const text =
			files.length === 0 ? (
				// 'Ajouter  des photos' :
				<div className="start-label-input-choose-file">
					<div className="label-img-container">
						<MdImage style={{ color: "#666666", fontSize: "36px" }} />
					</div>
					<span className="first-label-input-choose-file">
						Déposez une image ici ou sélectionnez un fichier.
					</span>
					<span className="second-label-input-choose-file">
						Format acceptés : JPG, PNG, GIF, TIFF ou BMP, inférieurs à 200Mo.
					</span>
				</div>
			) : (
				<div className="row">
					<button
						style={{
							backgroundColor: Color.themeColor,
							color: "#FFFFFF",
							border: "1px solid",
							padding: "2px 6px",
							borderRadius: "10px",
						}}
						onClick={(e) => e.preventDefault()}
					>
						Ajouter
					</button>
				</div>
			);
		let buttonStartStyle: any = {
			background: "#rgb(243, 243, 243)",
			cursor: "pointer",
			borderRadius: 10,
			marginTop: 10,
			textAlign: "center",
			maxWidth: "100%",
			color: "#fff",
		};

		if (files.length <= 0) {
			buttonStartStyle = {
				cursor: "pointer",
				textAlign: "center",
			};
		}

		return (
			<div className="upload-dropzone-row pt-3">
				<div style={buttonStartStyle} className="position-relative">
					{/* {text} */}
					<label htmlFor={id} aria-labelledby="dropzoneImage">
						{text}
					</label>
					<input
						style={{
							opacity: 0,
							cursor: "pointer",
						}}
						className="file-input"
						type="file"
						name="image"
						// multiple
						accept={accept}
						onChange={async (e) => {
							const chosenFiles = await getFilesFromEvent(e);
							onChange(chosenFiles);
							onFiles(chosenFiles);
						}}
						id={id}
					/>
				</div>
			</div>
		);
	};
	return (
		<div className="form-group">
			<label htmlFor="product-image" className="mb-2">
				{label}
			</label>
			<Dropzone
				getUploadParams={() => ({
					url: "https://httpbin.org/post",
				})}
				InputComponent={InputChooseFile}
				getFilesFromEvent={getFilesFromEvent}
				accept="image/*"
				maxFiles={100}
				styles={{
					dropzone: {
						border: "0.2px solid #959595",
						background: "#F3F3F3",
						overflow: "hidden",
						opacity: 1,
						borderRadius: "7px",
					},
				}}
			/>
		</div>
	);
};

export default DropZoneImage;
