import React from "react";
import { BtnSubmit } from "../../../../common/Button";

const SendMessageModal = () => {
	return (
		<div
			className="modal fade"
			id="sendMessageModal"
			aria-labelledby="sendMessageModal"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg modal-dialog-centered">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Message</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="px-2 py-4">
						<form>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="title-projet"
									className="espace-client-form-label"
								>
									Nom du client
								</label>
								<input
									type="text"
									className="form-control espace-client-form-control"
									placeholder="Nom du client"
									id="title-projet"
								/>
							</div>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="title-projet"
									className="espace-client-form-label"
								>
									Message
								</label>
								<textarea
									name="message"
									id="message"
									placeholder="message"
									className="form-control espace-client-form-control"
									style={{
										minHeight: "200px",
										maxHeight: "200px",
									}}
								/>
							</div>
							<div className="col-6 offset-6 pb-4 px-2">
								<BtnSubmit label="Envoyer" isLoading={false} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
export default SendMessageModal;
