import { AiTwotoneHeart } from "react-icons/ai";
import { FaThumbsUp } from "react-icons/fa";
import StarRatingComponent from "react-star-rating-component";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import {
	calculteRating,
	createMarkup,
	formattedDate,
	formattedTypeAnnonce,
	getImage,
} from "../../../../utils/utils";
import CloseModalBtn from "../../../shared/CloseModalBtn";

const DetailAnnonceModal = ({ item }: { item?: IAnnonce }) => {
	return (
		<div
			className="modal fade"
			id="DetailAnnonceModal"
			aria-labelledby="DetailAnnonceModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id="DetailAnnonceModal"
						>
							{item?.title}
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body d-block">
						<div className="card-img-top text-center mb-4">
							{item && (
								<img
									src={getImage(item.images[0]?.picture)}
									alt={item.slug}
									className="card-img-top h-200 object-cover"
								/>
							)}
						</div>
						<div
							className="text-description-admin mb-2"
							dangerouslySetInnerHTML={createMarkup(item?.description)}
						/>
						<div className="d-flex pt-4">
							<p className="annonce-publie-libelle-tabs">
								Type d'annonce:
							</p>
							<p className="annonce-publie-value-tabs">
								{" "}
								{formattedTypeAnnonce(item?.type_announcement)}
							</p>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(item?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#F5F5F5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div className="d-flex align-items-center gap-3">
								<div className="d-flex align-items-center">
									<AiTwotoneHeart className="icon-like-annonce large" />
									<span className="annonce-like ps-1 large pt-1">
										{item?.favorites?.length}
									</span>
								</div>
								<div className="d-flex align-items-center">
									<FaThumbsUp className="icon-like-annonce large" />
									<span className="annonce-like ps-1 large pt-1">
										{item?.interets?.length}
									</span>
								</div>
							</div>
							<div>
								<p
									className="date-annonce"
									style={{ fontSize: "16px" }}
								>
									{formattedDate(item?.created_at)}
								</p>
							</div>
						</div>
						<div className="flex-r-m gap-2 author-container pt-4">
							<span>par</span>
							<strong>{`${item?.user?.firstname} ${item?.user?.lastname}`}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailAnnonceModal;
