import React from 'react'
import { BsFillCameraFill } from 'react-icons/bs'
import './AddUserModal.scss'
import CloseModalBtn from '../../../shared/CloseModalBtn'
import useAddSeller from '../../admin/SellerListAdmin/UseFormSellerAdmin/useAddSeller'
import { useEffect, useState } from 'react'
import ErrorMessage from '../../../common/ErrorMessage'
import { BtnSubmit } from '../../../common/Button'
import { createUrl, getAvatar } from '../../../../utils/utils'

const AddSellerModal = () => {
  const { register, onSubmit, errors, setValue, isLoading } = useAddSeller()
  const [avatar, setAvatar] = useState<File>()

  const handleChangeImage = (e) => {
    console.log(e.target.files[0])
    setValue('avatar', e.target.files[0])
    setAvatar(e.target.files[0])
  }

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title modal-title-custom"
            id="AddSellerModalTitre"
          >
            Ajouter un vendeur
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <div className="profile-pic">
                <label className="-label" htmlFor="file">
                  <span className="glyphicon glyphicon-camera">
                    <BsFillCameraFill />
                  </span>
                  <span>Change Image</span>
                </label>
                <input id="file" type="file" onChange={handleChangeImage} />
                {avatar && (
                  <img src={createUrl(avatar)} id="output" width="200" />
                )}
                {errors.phone?.message && (
                  <ErrorMessage message={errors.phone.message} />
                )}
              </div>
            </div>
            <div className="row">
              {/* <div className='col-md-6 mb-3'>
                            <div>
                                <label className='form-label label-form-admin'>N°</label>
                                <input type="text" className="form-control form-control-input-admin" placeholder='#876370' />
                            </div>
                        </div>
                        <div className='col-md-6 mb-3'>
                            <div>
                                <label className='form-label label-form-admin'>Nombre de boutiques</label>
                                <input type="number" className="form-control form-control-input-admin" placeholder='3' />
                            </div>
                        </div> */}
              <div className="col-md-6 mb-3">
                <div>
                  <label
                    className="form-label label-form-admin"
                    htmlFor="Prenom"
                  >
                    Prenom
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    id="Prenom"
                    placeholder="Arrona"
                    {...register('firstname')}
                  />
                  {errors.firstname?.message && (
                    <ErrorMessage message={errors.firstname.message} />
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin" htmlFor="Nom">
                    Nom
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    id="Nom"
                    placeholder="Gaur"
                    {...register('lastname')}
                  />
                  {errors.lastname?.message && (
                    <ErrorMessage message={errors.lastname.message} />
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label
                    className="form-label label-form-admin"
                    htmlFor="Email"
                  >
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    id="Email"
                    placeholder="Example@gmail.com"
                    {...register('email')}
                  />
                  {errors.email?.message && (
                    <ErrorMessage message={errors.email.message} />
                  )}
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label
                    className="form-label label-form-admin"
                    htmlFor="Téléphone"
                  >
                    Téléphone
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    id="Téléphone"
                    placeholder="77 700 00 00"
                    {...register('phone')}
                  />
                  {errors.phone?.message && (
                    <ErrorMessage message={errors.phone.message} />
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="offset-md-4 col-md-4">
                <BtnSubmit label="Ajouter" isLoading={isLoading} />
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddSellerModal
