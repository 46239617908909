import React from "react";
import Play from "../../assets/landingpage/play.png";
import Banner from "../../assets/landingpage/video.png";
import Actu1 from "../../assets/landingpage/actu1.png";
import Actu2 from "../../assets/landingpage/actu2.png";
import Actu3 from "../../assets/landingpage/actu3.png";
import ReactPlayer from "react-player";
const Events = () => {
  return (
    <div className="secteurs-component">
      <div className="container-section-landingpage">
        <div className="container-title-landingpage mb-5">
          <h2 className="title-section-landingpage position-relative text-center">
            Nos événements{" "}
          </h2>
        </div>

        <div className="row row-events-landingpage pt-5">
          <div className="col-lg-7 col-events-landingpage d-flex mb-4">
            <div className="content-col-events-landingpage w-100">
              <div className="container-react-player-video">
                <ReactPlayer
                  className="about-video"
                  light={Banner}
                  controls
                  url="video.mp4"
                  playing
                  playIcon={<img src={Play} alt="video" />}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-5 col-events-landingpage d-flex mb-4">
            <div className="content-col-events-landingpage w-100">
              <div className="row row-actu-event mb-2">
                <div className="col-lg-4 col-events-landingpage d-flex ps-0">
                  <div className="content-col-events-landingpage w-100">
                    <div className="content-img-icon-events-landingpage">
                      <img
                        src={Actu1}
                        alt="events"
                        className="img-icon-events-landingpage"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-events-landingpage d-flex">
                  <div className="content-col-events-landingpage w-100">
                    <div className="container-title-event-card">
                      <p className="title-events-card-landingpage mb-1">
                        Event BOA Dakar
                      </p>
                      <p className="text-events-card-landingpage">
                        Experience the inspiring vision and mission of
                        AfricaNenda in this video, as we drive financial
                      </p>
                      <p className="text-date-events-card-landingpage mb-0">
                        23/10/2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-actu-event mb-2">
                <div className="col-lg-4 col-events-landingpage d-flex ps-0">
                  <div className="content-col-events-landingpage w-100">
                    <div className="content-img-icon-events-landingpage">
                      <img
                        src={Actu2}
                        alt="events"
                        className="img-icon-events-landingpage"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-events-landingpage d-flex">
                  <div className="content-col-events-landingpage w-100">
                    <div className="container-title-event-card">
                      <p className="title-events-card-landingpage mb-1">
                        Event BOA Dakar
                      </p>
                      <p className="text-events-card-landingpage">
                        Experience the inspiring vision and mission of
                        AfricaNenda in this video, as we drive financial
                      </p>
                      <p className="text-date-events-card-landingpage mb-0">
                        23/10/2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row row-actu-event">
                <div className="col-lg-4 col-events-landingpage d-flex ps-0">
                  <div className="content-col-events-landingpage w-100">
                    <div className="content-img-icon-events-landingpage">
                      <img
                        src={Actu3}
                        alt="events"
                        className="img-icon-events-landingpage"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-8 col-events-landingpage d-flex">
                  <div className="content-col-events-landingpage w-100">
                    <div className="container-title-event-card">
                      <p className="title-events-card-landingpage mb-1">
                        Event BOA Dakar
                      </p>
                      <p className="text-events-card-landingpage">
                        Experience the inspiring vision and mission of
                        AfricaNenda in this video, as we drive financial
                      </p>
                      <p className="text-date-events-card-landingpage mb-0">
                        23/10/2023
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Events;
