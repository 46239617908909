import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import defaultImage from "../component/assets/appImages/NoImage.png";
import Avatar from "../component/assets/appImages/image.png";
import { ApiBaseUrl } from "./http";
import { TypeBoost } from "./api/abonnement/abonnement.type";
import countries from "./json/Countries.json";

export function cleannerError(errors: any, cleanner: any, timeOut: number = 3000) {
	if (errors) {
		setTimeout(() => Object.entries(errors).map(([key]: any) => cleanner(key)), timeOut);
	}
}

export function formattedDate(date: string | Date | undefined) {
	return date ? moment(date).format("DD/MM/YYYY") : "";
}
export function formattedBoostDate(date: string | Date | undefined) {
	return date ? moment(date).format("DD/MM/YYYY à k:mm ") : "";
}
export function calcEndDay(date: string | Date | undefined) {
	return date ? moment(date).endOf("day").fromNow() : "";
}
export function createMarkup(text: string | any, showDots?: boolean) {
	if (text) {
		return {
			__html: `${text}${showDots === true ? "..." : ""}`,
		};
	}
	return undefined;
}

export function useLocationState<T>(
	defaultValue: T | any,
	redirectToWhenNull: string | null = null
): T {
	const state = useLocation().state as T;
	const navigate = useNavigate();
	useEffect(() => {
		if (!state && redirectToWhenNull) {
			navigate(redirectToWhenNull);
		}
	}, [state]);
	return state || defaultValue;
}

export const getImage = (img: string | any) => {
	if (img && !img?.includes("default.png") && !img?.includes("static/media/")) {
		if (img.indexOf("http") === 0) return img;
		return ApiBaseUrl + img;
	}
	return defaultImage;
};

export const getAvatar = (avatar: string | any) => {
	if (avatar && !avatar?.includes("default.png")) {
		if (avatar.indexOf("http") === 0) return avatar;
		return ApiBaseUrl + avatar;
	}
	return Avatar;
};

export function createUrl(image: File) {
	return URL.createObjectURL(image);
}

export function QueryUrl(baseUrl: string, additionalSearchQuery: any) {
	if (additionalSearchQuery) {
		for (let key of Object.keys(additionalSearchQuery)) {
			let val = additionalSearchQuery[key];
			// if (["createdAt", "end_date"].includes(key) && val) {
			//   val = moment(val).format("YYYY-MM-DD");
			// }
			if (Array.isArray(val)) {
				if (val.length > 0) {
					const string = val.map((v) => `${key}=${v}`).join("&");
					if (baseUrl.includes("?")) {
						baseUrl += `&${string}`;
					} else {
						baseUrl += `?${string}`;
					}
				}
			} else if (typeof val === "boolean") {
				if (baseUrl.includes("?")) {
					baseUrl += `&${key}=${val}`;
				} else {
					baseUrl += `?${key}=${val}`;
				}
			} else {
				if (val) {
					if (baseUrl.includes("?")) {
						baseUrl += `&${key}=${val}`;
					} else {
						baseUrl += `?${key}=${val}`;
					}
				}
			}
		}
	}
	// console.log('baseUrl', baseUrl)
	return baseUrl;
}

export const formattedTypeAnnonce = (typeAnnonce: string | undefined) => {
	if (typeAnnonce === "recherche_client") {
		return "Rechercherche Client";
	} else if (typeAnnonce === "recherche_partenaire") {
		return "Rechercherche Partenaire";
	} else if (typeAnnonce === "recherche_fournisseur") {
		return "Rechercherche Fournisseur";
	} else if (typeAnnonce === "recherche_conseiller") {
		return "Rechercherche Conseiller";
	} else {
		return "Rechercherche Profil";
	}
};

function sortByDate(a, b) {
	if (moment(a?.created_at).toDate().getTime() < moment(b?.created_at).toDate().getTime()) {
		return 1;
	}
	if (moment(a?.created_at).toDate().getTime() > moment(b?.created_at).toDate().getTime()) {
		return -1;
	}
	return 0;
}
export function sortedByDate(arr) {
	const sorted = [...arr]?.sort(sortByDate);
	return sorted;
}

export const onHide = (modalId) => {
	document.getElementById(modalId)?.classList.remove("show");
	$(".modal-backdrop").remove();
	$(`#${modalId}`).hide();
	$(`#${modalId}`).click();
	$(`body`).css({ overflow: "scroll" });
};

export const onOpen = (modalId) => {
	document.getElementsByTagName("body")[0].classList.add("modal-open");
	document.getElementById(modalId)?.classList.add("show");
	$(".modal-backdrop").append();
	$(`#${modalId}`).show();
	$(`#${modalId}`).click();
};

export function calculteRating(rates) {
	if (rates?.length) {
		const rate = rates?.reduce((acc, item) => acc + item?.rating, 0) / rates?.length;
		return Math.floor(rate);
	}
	return 0;
}

export function removeNullFromObjects(obj) {
	if (obj) {
		for (const key in obj) {
			if (obj[key] === null) {
				delete obj[key];
			}
		}

		return obj;
	}
	return {};
}

export function useModal<T>() {
	const [isShowModal, setIsShowModal] = useState(false);
	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
	const [item, setItem] = useState<T>();
	const openModal = () => {
		setItem(undefined);
		toggle();
	};
	const openEditModal = (item: T) => {
		setItem(item);
		toggle();
	};
	const getItem = (item: T) => {
		setItem(item);
	};
	return {
		isShowModal,
		toggle,
		openModal,
		openEditModal,
		closeModal: openModal,
		item,
		getItem,
	};
}

export const formatMontant = (num?: number | string) => {
	return formatAmount(num);
};

export function formatAmount(amount?: number | string) {
	if (amount || amount === 0)
		return new Intl.NumberFormat("fr-FR", {
			style: "currency",
			currency: "EUR",
			maximumFractionDigits: 2,
			minimumFractionDigits: 0,
		}).format(parseFloat(String(amount)) || 0);

	return "-";
}

export function showOfferType(status: string) {
	if (status === TypeBoost.simple) {
		return "Boost sur Munngano Connect";
	}
	if (status === TypeBoost.reseaux) {
		return "Boost sur les réseaux sociaux";
	}
	if (status === TypeBoost.video_reseau) {
		return "Boost sur les réseaux avec vidéo";
	}
	return "";
}
export function getFlagEmoji(country?: string) {
	const countryCode = getCountryCodeByCountryName(country);
	if (countryCode) {
		// const codePoints = countryCode
		// 	.toUpperCase()
		// 	.split("")
		// 	.map((char) => 127397 + char.charCodeAt());
		return countryCode
			.toUpperCase()
			.replace(/./g, (char) => String.fromCodePoint(127397 + char.charCodeAt(0)));
	}
	return "";
}

export function getCountryCodeByCountryName(country?: string) {
	if (country) {
		const key = countries.find(
			(item) => item?.name?.toLowerCase() === country.toLocaleLowerCase()
		)?.iso2;
		return key;
	}
	return "";
}

export function useToggle() {
	const [isActive, setIsActive] = useState<boolean>(false);
	const toggleActive = () => {
		setIsActive((isActive) => !isActive);
	};

	return {
		isActive,
		toggleActive,
		isOpen: isActive,
		toggleModal: toggleActive,
	};
}

export function useShowPassword() {
	const [isShowPassword, setIsShowPassword] = useState<boolean>(false);
	const toggleShowPassword = () => setIsShowPassword((isShowPassword) => !isShowPassword);

	return {
		isShowPassword,
		toggleShowPassword,
	};
}
