import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Swal from 'sweetalert2'
import { AppLocalStorage } from '../../utils/storage'
import { Color } from '../../utils/theme'
import { Cart, CartItem, ToggleType } from '../cart.type'

export const CartSlice = createSlice({
  name: 'cart',
  initialState: {},
  reducers: {
    addCartItem: (state, action: PayloadAction<Cart | any>) => {
      let cart: Cart = AppLocalStorage.getItem('cart')
      let items: CartItem[] = cart?.cartItems || []
      let alreadyExists = false
      if (items?.length) {
        items.forEach((x) => {
          if (x?.item?.id === action.payload?.id) {
            alreadyExists = true
            x.count++
          }
        })
        if (!alreadyExists) {
          items.push({
            item: action?.payload,
            count: 1,
            price: parseFloat(action?.payload?.price),
          })
        }
      } else {
        items.push({
          item: action?.payload,
          count: 1,
          price: parseFloat(action?.payload?.price),
        })
      }

      let { total, count } = items?.reduce(
        (cartTotal, cartItem) => {
          const { price, count } = cartItem
          const itemTotal = parseFloat(price) * count

          cartTotal.total += itemTotal
          cartTotal.count += count

          return cartTotal
        },
        {
          total: 0,
          count: 0,
        },
      )

      AppLocalStorage.setItem('cart', {
        total: total,
        cartItems: items,
        countItems: items?.length,
      })
      state = {
        total: total,
        cartItems: items,
        countItems: items?.length,
      }

      return state
    },
    getCartItems: (state, action: PayloadAction<void>) => {
      let cart: Cart = AppLocalStorage.getItem('cart')
      let items: CartItem[] = cart?.cartItems || []

      let { total, count } = items?.reduce(
        (cartTotal, cartItem) => {
          const { price, count } = cartItem
          const itemTotal = parseFloat(price) * count

          cartTotal.total += itemTotal
          cartTotal.count += count

          return cartTotal
        },
        {
          total: 0,
          count: 0,
        },
      )

      AppLocalStorage.setItem('cart', {
        total: total,
        cartItems: items,
        countItems: items?.length,
      })
      state = {
        total: total,
        cartItems: items,
        countItems: items?.length,
      }
      return state
    },
    toggleItem: (state, action: PayloadAction<ToggleType>) => {
      let cart: Cart = AppLocalStorage.getItem('cart')
      let items: CartItem[] = cart?.cartItems || []
      let alreadyExists = false
      if (items?.length) {
        items.forEach((x) => {
          if (x?.item?.id === action.payload?.id) {
            alreadyExists = true
            if (action.payload.toggle === 'inc') {
              x.count++
            }
            if (action.payload.toggle === 'dec') {
              x.count--
            }
          }
        })
      }

      let { total, count } = items?.reduce(
        (cartTotal, cartItem) => {
          const { price, count } = cartItem
          const itemTotal = parseFloat(price) * count
          cartTotal.total += itemTotal
          cartTotal.count += count

          return cartTotal
        },
        {
          total: 0,
          count: 0,
        },
      )
      // console.log('item toggle', items, total, count)
      AppLocalStorage.setItem('cart', {
        total: total,
        cartItems: items,
        countItems: items?.length,
      })
      state = {
        total: total,
        cartItems: items,
        countItems: items?.length,
      }
      return state
    },
    deleteCartItem: (state, action) => {
      let cart: Cart = AppLocalStorage.getItem('cart')
      let items: CartItem[] = cart?.cartItems || []
      items = items?.filter((i) => i?.item?.id !== action.payload)
      let { total, count } = items?.reduce(
        (cartTotal, cartItem) => {
          const { price, count } = cartItem
          const itemTotal = parseFloat(price) * count

          cartTotal.total += itemTotal
          cartTotal.count += count

          return cartTotal
        },
        {
          total: 0,
          count: 0,
        },
      )
      // console.log('items', items)
      if (items?.length) {
        AppLocalStorage.setItem('cart', {
          total: total,
          cartItems: items,
          countItems: items?.length,
        })
        state = {
          total: total,
          cartItems: items,
          countItems: items?.length,
        }
      } else {
        AppLocalStorage.removeItem('cart')
        state = {
          total: null,
          cartItems: [],
          countItems: 0,
        }
      }

      return state
    },
    removeCart: (state, action: PayloadAction<void>) => {
      AppLocalStorage.removeItem('cart')
      state = {
        total: null,
        cartItems: [],
        countItems: 0,
      }
      return state
    },
  },
})

export const {
  addCartItem,
  deleteCartItem,
  toggleItem,
  getCartItems,
  removeCart,
} = CartSlice.actions
export default CartSlice.reducer
