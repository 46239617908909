import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { IUser, onSetUser } from '../../../../../redux/slice/User.slice'
import { useEditAdminMutation } from '../../../../../utils/api/admin/admin.api'
import { cleannerError } from '../../../../../utils/utils'
import { Color } from '../../../../../utils/theme'
import { useAppDispatch, useAppSelector } from '../../../../../redux/hooks'
import { useEditUserMutation } from '../../../../../utils/api/user/user.api'

function UseEditUserAdmin() {
  const validationSchema = yup.object().shape({
    adresse: yup.string().required().label("L'Adresse"),
    lastname: yup.string().required('Le nom est requis'),
    firstname: yup.string().required('Le prenom est requis'),
    phone: yup.string().required('Le numéro de téléphone est requis'),
    email: yup.string().email('email invalide').required("l'email est requis"),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
  } = useForm<IUser | any>({
    resolver: yupResolver(validationSchema),
  })
  const dispatch = useAppDispatch()
  const [avatar, setAvatar] = useState<any>(null)
  const [address, setAddress] = useState<any>('')

  const { user } = useAppSelector((s) => s?.user)
  const [
    editData,
    { isLoading, isSuccess, isError, error, data },
  ] = useEditAdminMutation()

  const [
    editDataAdmin,
    {
      isLoading: isLoadingAdmin,
      isSuccess: isSuccessAdmin,
      isError: isErrorAdmin,
      error: errorAdmin,
      data: dataAdmin,
    },
  ] = useEditUserMutation()

  useEffect(() => {
    if (errors) {
      for (let key of Object.keys(errors)) {
        if (key) {
          window.scrollTo(10, 10)
        }
      }
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  const onHide = (modalId) => {
    document.getElementById(modalId)?.classList.remove('show')
    $('.modal-backdrop').remove()
    $(`#${modalId}`).hide()
    $(`body`).css({ overflow: 'scroll' })
  }

  useEffect(() => {
    if (isSuccessAdmin) {
      console.log('data', dataAdmin)
      if (user?.id === dataAdmin?.id) {
        dispatch(onSetUser(dataAdmin))
      }
      onHide(`EditAdminModal${dataAdmin?.slug}`)
      Swal.fire({
        icon: 'success',
        title: 'Informations modifiées avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }, [isSuccessAdmin])
  useEffect(() => {
    if (isErrorAdmin) {
      console.log('err', errorAdmin)
      const err = errorAdmin as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isErrorAdmin])

  useEffect(() => {
    if (isSuccess) {
      console.log('data', data)
      if (user?.id === data?.id) {
        dispatch(onSetUser(data))
      }
      onHide(`EditAdminModal${data?.slug}`)
      Swal.fire({
        icon: 'success',
        title: 'Informations modifiées avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log(formValues)
    let userSlug = formValues?.slug

    let data = formValues
    const fd = new FormData()

    for (let key of Object.keys(data)) {
      const val = data[key]
      fd.append(key, val)
    }
    if (user?.user_type === 'superadmin') {
      await editDataAdmin({ userSlug: userSlug, userData: fd })
    } else {
      await editData({ slug: userSlug, data: fd })
    }
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading:
      isLoading === true
        ? isLoading
        : isLoadingAdmin === true
        ? isLoadingAdmin
        : isLoading,
    setError,
    avatar,
    setAvatar,
    address,
    setAddress,
  }
}

export default UseEditUserAdmin
