import React, { useEffect } from "react";
import { useEditCommerceFileMutation } from "../../../../utils/api/commerce/commerce.api";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import "./AddBannerShop.css";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Swal from "sweetalert2";
import { addCommerce } from "../../../../redux/slice/shop.slice";
import { Color } from "../../../../utils/theme";
import { FormError } from "../../../common/CustomInput";

function AddLogoShop() {
	const [
		sendData,
		{ isLoading, isSuccess, isError, error, data },
	] = useEditCommerceFileMutation();
	const dispatch = useAppDispatch();
	const commerce = useAppSelector<any>((state) => state?.commerce);
	const validationSchema = yup.object().shape({
		logo: yup.mixed().required().label("Le logo"),
	});
	const {
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
	} = useForm<any>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (isSuccess) {
			dispatch(addCommerce(data));
			$(".modal-backdrop").remove();
			$(`#addLogoShop`).hide();
			$(`#addLogoShop`).click();
			reset();
			Swal.fire({
				icon: "success",
				title: "Informations modifiées avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	const onSubmit = async (formData, e) => {
		let fd = new FormData();
		console.log(formData);
		fd.append("logo", formData?.logo);
		await sendData({ slug: commerce?.slug, data: fd });
	};

	return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="sous-titre-devenir-commercant" id="addLogoShopLabel">
						Modifier le logo de la boutique
					</h5>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				<div className="modal-body">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div>
							<div className="custom-file mb-5">
								<label className="espace-client-form-label" htmlFor="addBanner">
									Image
								</label>
								<input
									type="file"
									accept="image/*"
									onChange={(e: any) => {
										setValue("logo", e.target.files[0]);
									}}
									className="espace-client-form-control"
									id="addBanner"
								/>
								{<FormError error={errors?.logo?.message} />}
							</div>
						</div>
						<div className=" mt-3 text-center">
							<button
								className="btn btn-theme"
								disabled={isLoading ? true : false}
								type="submit"
							>
								{isLoading ? (
									<>
										<span
											className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
											role="status"
										></span>
										<span>En cours...</span>
									</>
								) : (
									"Enregistrer"
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
}

export default AddLogoShop;
