import React from 'react'
import './SidebarTrader.css'
import SidebarTraderContent from './SidebarTraderContent'

const SidebarTrader = () => {
    return (
        <div className="navbar-vertical-customisation-v2 no-view-mobile">
            <SidebarTraderContent />
        </div>
    )
}

export default SidebarTrader