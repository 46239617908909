import React, { useState } from "react";
import AchatsSkeleton from "./AchatsTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import moment from "moment";
import { FiEye } from "react-icons/fi";
import { useGetOrderByUserPaginateQuery } from "../../../../../utils/api/order/order.api";
import { Link } from "react-router-dom";
import { OrderType } from "../../../../../utils/api/order/order.type";
import { CustomPagination, statusFormatter } from "../../Trader/Commandes/TraderCommandesTable";

function AchatsTable() {
	const { orders, count, isLoading, page, setPage, setPerPage, perPage } = useOrdersByUser();
	const dateFormatter = (cell, row) => {
		return moment(cell).format("DD/MM/YYYY");
	};

	const actionFormatter: any = (cell, row: OrderType) => {
		return (
			<div className="table-actions-btn-container-projet">
				<Link
					className="no-style-btn hover-theme"
					to={`/app/mon-espace-client/mes-achats/${row?.slug}`}
					state={row}
				>
					{<FiEye />}
				</Link>
			</div>
		);
	};

	const columns = [
		{
			dataField: "order_code",
			text: "N° d'Achat",
			style: { textAlign: "left" },
		},
		{
			dataField: "order_date",
			text: "Date Achat",
			formatter: (cell: any, row: any) => dateFormatter(cell, row),
			headerStyle: () => {
				return { width: "350px" };
			},
		},
		{
			dataField: "shipping_address",
			text: "Adresse de livraison",
			headerStyle: () => {
				return { width: "350px" };
			},
		},
		{
			dataField: "delivery",
			text: "Mode de livraison",
			formatter: (cell, row) => cell?.name || "",
			headerStyle: () => {
				return { width: "350px" };
			},
		},
		{
			dataField: "order_total",
			text: "Total",
			headerStyle: () => {
				return { width: "200px" };
			},
		},
		{
			dataField: "status",
			text: "Statut",
			formatter: (cell, row) => statusFormatter(cell, row),
			headerStyle: () => {
				return { width: "300px" };
			},
		},
		{
			dataField: "slug",
			text: "Action",
			formatter: (cell: any, row: OrderType) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <AchatsSkeleton />}
			{!isLoading && (
				<BootstrapTable
					keyField="order_code"
					data={orders || []}
					columns={columns}
					striped
					noDataIndication="Aucune donnée disponible"
					bordered={false}
					condensed={false}
					responsive
					wrapperClasses="table-responsive seller-table"
					rowStyle={{
						fontFamily: "Poppins",
						fontStyle: "normal",
						fontWeight: "600",
						fontSize: "14px",
						lineHeight: "21px",
						color: "rgba(0, 0, 0, 0.7)",
					}}
					headerClasses="header-class"
				/>
			)}
			<CustomPagination
				nbPages={count}
				page={page}
				onChange={(page, perPage) => {
					setPerPage(perPage);
					setPage(page);
				}}
				perPage={perPage}
			/>
			{/* <Pagination page={page} onPageChange={setPage} total={data?.count} /> */}
		</>
	);
}

export function useOrdersByUser() {
	const [page, setPage] = useState(1);
	const [perPage, setPerPage] = React.useState(10);
	const { data: { results: orders, count } = { results: [], count: 0 }, isLoading } =
		useGetOrderByUserPaginateQuery({
			page,
			limit: perPage,
		});
	return {
		orders,
		count,
		isLoading,
		page,
		setPage,
		setPerPage,
		perPage,
	};
}

export default AchatsTable;
