import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

function TopAnnoncesTableSkeleton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Titre
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Proprietaire
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Date de boost
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Jours restants
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(5)]?.map((item, _i) => (
            <tr key={_i}>
              <th scope="row">
                <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
              </th>
              <td>
                <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
              </td>
              <td>
                <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
              </td>
              <td>
                <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
              </td>
              <td>
                <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default TopAnnoncesTableSkeleton;
