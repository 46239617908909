import { useState } from "react";
import "./DropdownCategoryList.css";
import { NavLink } from "react-router-dom";
import { useGetAnnuairesCategoriesByVisitorQuery } from "../../../utils/api/category/category.api";
import { EmptyMessage } from "../../../utils/EmptyMessage";
import { getImage } from "../../../utils/utils";

const DropdownCategoryList = ({ setShowDropDown }) => {
	const { data: categories } = useGetAnnuairesCategoriesByVisitorQuery();
	const [selectedIndex, setSelectedIndex] = useState(0);

	return (
		<div className="card-categorie-accueil">
			<div>
				<div className="">
					<h3 className="titre-toutes-categories">NOS CATEGORIES DE PROFESSIONNELS</h3>
				</div>
				<div className="row pt-2 content-category-dropdown content-category-border">
					<div className="col-md-6 px-0 categorie-sidebar-container">
						<ul className="list-group list-group-categorie">
							{categories?.length
								? categories?.map((item, index) => (
										<NavLink
											to={item?.subcategory?.length ? `#` : `/annuaires`}
											state={
												!item?.subcategory?.length && {
													category: item,
												}
											}
											className={`title-category-item no-link ${
												selectedIndex === index ? "activated" : ""
											}`}
											key={item?.id}
											onMouseEnter={() => {
												// if (
												// 	!item?.subcategory
												// 		?.length
												// ) {
												// 	setShowDropDown(
												// 		false
												// 	);
												// }
												setSelectedIndex(index);
											}}
										>
											<li className="list-group-item list-group-item-categorie d-flex align-items-center">
												<div>
													<img
														src={getImage(item?.picture)}
														alt="Catégorie"
														className="img-category-header"
													/>{" "}
												</div>
												<h6 className="ps-3 category-header-text m-0">
													{item?.name}{" "}
												</h6>
											</li>
										</NavLink>
								  ))
								: EmptyMessage("Aucune catégorie disponible!")}
						</ul>
					</div>
					<div className="col-md-6">
						<div className="">
							{categories?.length && categories[selectedIndex]?.subcategory?.length
								? categories[selectedIndex]?.subcategory?.map((item) => (
										<div className="" key={item?.id}>
											<div className="">
												<div className="d-flex align-items-center">
													<NavLink
														to="app/annuaires"
														state={{
															category: categories[
																selectedIndex
															],
															subcategory: item,
														}}
														className="sub-title-category-item no-link"
														onClick={() => {
															setShowDropDown(false);
														}}
													>
														{item?.name}
													</NavLink>
													<p className="text-number-category ps-2">
														({item?.nb_product})
													</p>
												</div>
											</div>
										</div>
								  ))
								: EmptyMessage("Aucune sous catégorie disponible!")}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DropdownCategoryList;
