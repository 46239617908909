import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnnonceFormData } from "../../../../../utils/api/annonce/annonce.type";
import { useEffect, useState } from "react";
import { cleannerError } from "../../../../../utils/utils";
import { useDepotAnnonceMutation } from "../../../../../utils/api/annonce/annonce.api";
import { useAppSelector } from "../../../../../redux/hooks";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";

const UseDeposerAnnonceForm = () => {
	const [dropAnonce, { data, isLoading, isSuccess, isError, error }] =
		useDepotAnnonceMutation();
	const { user } = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		title: yup.string().required("Le titre est requis"),
		description: yup.string().required("La Description est requise"),
		type_announcement: yup.string().required("Le type d'annonce est requis"),
		ville: yup.string().required("La ville est requise"),
		region: yup.string().required("La région est requise"),
		country: yup.string().required("Le pays est requise"),
		sector: yup.string().required("Les secteurs d'activités sont requises"),
		images: yup.array().max(4).required("Au moins une image"),
		tags: yup.array().required().min(1).label("Tags"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
		setError,
	} = useForm<AnnonceFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [images, setImages] = useState<File[]>([]);
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [isValidDescription, setIsValidDescription] = useState<boolean>(false);
	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		if (errors.images) {
			window.scrollTo(50, 50);
		}
		cleannerError(errors, clearErrors, 5000);
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Annonce ajoutée avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => navigate("/app/mon-espace-client/mes-annonces"));
			reset();
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		if (isSuccess) {
			setImages([]);
		}
	}, [isSuccess]);

	const handleCountrySelect = (option: any) => {
		setCountry(option);
		if (option.label) {
			setValue("country", option.name);
		}
	};

	const handleStateSelect = (option: any) => {
		setState(option);
		if (option.label) {
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		setCity(option);
		if (option.label) {
			setValue("ville", option.label);
		}
	};
	const handleChangeDescription = (e) => {
		const text = e.editor.getData();
		setValue("description", text);
		if (text.length >= 100) {
			setIsValidDescription(true);
		} else {
			setIsValidDescription(false);
		}
	};
	const handleChangeTags = (value: string[]) => {
		console.log({ value });
		setSelected(value);
		setValue("tags", value);
	};

	const onSubmit = async (data: AnnonceFormData) => {
		if (data?.description?.length < 100) {
			console.log("error", data?.description?.length);
			return setError("description", { message: "Au minimum 100 caratères" });
		}
		const { images, tags, ...rest } = data;
		console.log({ tags });

		const formData = new FormData();
		if (user?.id) {
			formData.append("user", JSON.stringify(user.id));
		}
		if (images?.length) {
			for (const image of images) {
				formData.append("images", image);
			}
		}
		if (tags) {
			formData.append("tags", JSON.stringify(tags));
		}
		for (let key of Object.keys(rest)) {
			const val = data[key];
			formData.append(key, val);
		}

		dropAnonce(formData);
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		isSuccess,
		images,
		setImages,
		state,
		country,
		city,
		isValidDescription,
		handleChangeDescription,
		handleStateSelect,
		handleCountrySelect,
		handleCitySelect,
		selected,
		handleChangeTags,
	};
};

export default UseDeposerAnnonceForm;
