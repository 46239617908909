import React from "react";
import { AiTwotoneHeart } from "react-icons/ai";
import StarRatingComponent from "react-star-rating-component";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import "./ProjectListing.css";
import { createMarkup, getImage } from "../../../../utils/utils";
import RecentAnnonces from "./RecentsAnnonces";
import { Color } from "../../../../utils/theme";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { ShareSocialMedia } from "../MarketPlace/MarketPlaceDetail";
import useAnnonce from "./UseAnnonceForm/useAnnonce";
import { FaThumbsUp } from "react-icons/fa";

const DetailAnnonce = () => {
	const {
		slug,
		annonce,
		rating,
		handleRateAnnonce,
		toggleFavorite,
		isFavorite,
		isLoading,
		isMyAnnonce,
		handleInterestAnnonce,
		isInterest,
	} = useAnnonce();
	return (
		<div>
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="content-page-container">
					<div className="row pt-2 flex-mobile-col-reverse">
						<div className="col-xl-4 col-lg-4 col-md-4">
							<div className="">
								<RecentAnnonces slug={slug} />
							</div>
						</div>
						<div className="col-xl-8 col-lg-8 col-md-8">
							<div className="col-tab-espace-client py-2">
								<div>
									<div className="flex-sb">
										<div className="d-flex gap-3">
											<h4 className="titre-annonce-projet fw-400">
												{annonce?.code}
											</h4>
											<h4 className="titre-annonce-projet fw-700">
												{annonce?.title}
											</h4>
										</div>
										<div className="d-flex">
											<div>
												<StarRatingComponent
													name="rate_annonce"
													editing={true}
													starCount={5}
													value={rating}
													starColor={
														Color.themeColor
													}
													emptyStarColor="#CCCCCC"
													onStarClick={(
														index
													) =>
														handleRateAnnonce(
															index
														)
													}
												/>
											</div>
											<button
												className="add-favorite-btn"
												title="Ajouter aux favoris"
												onClick={toggleFavorite}
											>
												<AiTwotoneHeart
													className={`icon-heart-detail-annonce ${
														isFavorite?.slug &&
														"color-theme"
													}`}
												/>
											</button>
										</div>
									</div>
									<div className="row mt-4 b-b-1">
										<div className="col-12">
											<div className="flex-sb align-items-center pb-2">
												<div className="d-flex">
													<div>
														<img
															src={getImage(
																annonce
																	?.images[0]
																	?.picture
															)}
															alt="Annonce"
															className="listing-project-detail-img"
														/>
													</div>
													{/* <div className="ps-2">
														<p className="texte-detail-annonce-projet">
															{
																annonce
																	?.user
																	?.firstname
															}
														</p>
														<p className="texte-detail-annonce-projet">
															{
																annonce?.country
															}{" "}
															{
																annonce?.region
															}
														</p>
													</div> */}
												</div>
												{!isMyAnnonce && (
													<div className="interest-btn-container">
														<button
															className={`btn-interest d-flex ${
																isInterest &&
																"btn-interest-active"
															}`}
															onClick={
																handleInterestAnnonce
															}
														>
															<FaThumbsUp />
															<span className="m-0 ms-1">
																{isInterest
																	? "Déjà intéressé(e)"
																	: "Je suis intéressé(e)"}
															</span>
														</button>
													</div>
												)}
											</div>
										</div>
									</div>
									{isLoading && (
										<div>
											<VolkenoSkeleton
												variant="rect"
												height={80}
												width="100%"
											/>
										</div>
									)}
									{!isLoading && (
										<div
											className="mb-2 pt-4 text-description-annonce-detail"
											dangerouslySetInnerHTML={createMarkup(
												annonce?.description
											)}
										/>
									)}
									<div className="">
										<div className="row pb-2">
											<div className="col-md-4 pb-2">
												<p className="texte-detail-annonce-info-value">
													Type de l'annonce:
												</p>
											</div>
											<div className="col-md-8 pb-2">
												<p className="texte-detail-annonce-info-libelle">
													{annonce?.type_announcement ===
													"recherche_profil"
														? "Recherche Profil"
														: annonce?.type_announcement ===
														  "recherche_partenaire"
														? "Recherche Partenaire"
														: "Recherche Client"}
												</p>
											</div>
										</div>
									</div>
									<div className="mt-5 py-5">
										<ShareSocialMedia
											category="Annonce"
											title={annonce?.title}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default DetailAnnonce;
