import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { useLazyGetOrderByUserQuery } from '../../../../utils/api/order/order.api'
import NoOrderImage from '../../../assets/appImages/orderEmpty.svg'
import NoLoginImage from '../../../assets/appImages/NoLogin.png'
import { GroupedItem } from './PanierTabs'
import { createMarkup, getImage } from '../../../../utils/utils'
import AchatsTable from '../../Tables/user/Achats/AchatsTable'

const CommandesTabs = () => {
  const { user, token } = useAppSelector((s) => s?.user)
  const [
    orderByUser,
    { isLoading, data: orders = [] },
  ] = useLazyGetOrderByUserQuery()

  React.useEffect(() => {
    if (user?.id) {
      orderByUser()
    }
  }, [user])

  React.useEffect(() => {
    console.log('orders', orders)
  }, [orders])
  return (
    <div className="content-card-account">
      <form>
        <div className="commande-item">
          {!token ? (
            <Nologin />
          ) : (
            <>
              <p className="text-commande-item m-b-30 fw-600 fs-14">
                Historique des achats
              </p>
              <div className="component-cart-item">
                <AchatsTable />
              </div>
              {/* {orders?.length ? (
                orders?.map((order) => (
                  <div
                    className="component-cart-item mb-3"
                    key={order?.id}
                    style={{ borderBottom: '1px solid' }}
                  >
                    <div className="mb-3 shop-cart"></div>
                    {GroupedItem(order?.cartitems)?.map((shop: any) => (
                      <div key={shop?.id} className="mb-3">
                        <p className="m-b-12 text-start text-up-title fs-13 fw-500">
                          Commerce: {shop?.commerce?.name}
                        </p>
                        {shop?.items?.map((item) => (
                          <div className="row m-b-20" key={item?.item?.id}>
                            <div className="col-xl-6 col-lg-5 col-md-12">
                              <div className="row">
                                <div className="col-sm-4 m-b-20">
                                  <img
                                    className="img-thumbs-commande-item"
                                    src={getImage(item?.item?.background_image)}
                                    alt=""
                                  />
                                </div>
                                <div className="col-sm-8 m-b-20">
                                  <p className="text-commande-item m-b-10 fw-300 fs-14">
                                    {item?.item?.name}
                                  </p>
                                  <p className="text-commande-item m-b-30 fw-300 fs-12">
                                    {item?.item?.marque || 'Sans marque'}
                                  </p>
                                  <p
                                    className="text-commande-item m-b-30 fw-300 fs-12"
                                    dangerouslySetInnerHTML={createMarkup(
                                      item?.item?.description || '',
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 m-b-20">
                              <div>
                                <p className="fw-600 text-up text-up-title">
                                  Quantité
                                </p>
                                <div className="btn-inc-dec-container">
                                  <span className="number-item mx-3">
                                    {item?.quantity}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-4 m-b-20 px-2">
                              <div>
                                <p className="fw-600 text-up text-up-title pb-1">
                                  Prix UNITAIRE
                                </p>
                                <p className="text-dark fs-14 ff-open fw-400 text-center p-2">
                                  {item?.price} FCFA
                                </p>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                    <div className="row">
                      <div className="col-lg-8 dis-flex itm-center">
                        <div className="row w-full m-b-30">
                          <div className="col-lg-8 col-md-10">
                            
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-4">
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Prix ​​sous-total :{' '}
                          <span className="value-resume-item fs-14 fw-700">
                            {order?.cart?.total} FCFA
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Frais de livraison (+) :{' '}
                          <span className="value-resume-item fs-14 fw-700">
                            {order?.shipping} FCFA
                          </span>
                        </p>
                        <p className="resume-item fw-400 fs-14 m-b-20">
                          Remise (-) :{' '}
                          <span className="value-resume-item fs-14 fw-700">
                            {' '}
                            0 FCFA
                          </span>
                        </p>
                        <p className="resume-item fw-700 itm-center m-b-20">
                          Total à payer :{' '}
                          <span className="value-resume-item-ttc">
                            {order?.order_total} FCFA
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <NoOrder />
              )} */}
            </>
          )}
        </div>
      </form>
    </div>
  )
}

export default CommandesTabs

const Nologin = () => {
  const location = useLocation()
  return (
		<section className="cart-content checkout">
			<img className="image-cart-empty" src={NoLoginImage} alt="" />
			<header>
				<h2 className="h-theme">Vous n'êtes pas connecté!</h2>
				<h4 className="">Veuillez vous connecter pour suivre l'évolution de vos commandes.</h4>
			</header>
			<div className="dis-flex justify-content-center mt-5">
				<div className="col-md-5 col-sm-7">
					<NavLink
						className="btn-theme dis-flex justify-content-center no-link"
						to="/app/se-connecter"
						state={location}
					>
						Se connecter
					</NavLink>
				</div>
			</div>
		</section>
  );
}

const NoOrder = () => {
  return (
    <section className="cart-content checkout">
      <img className="image-cart-empty" src={NoOrderImage} alt="" />
      <header>
        <h2 className="h-theme">
          Vous n'avez fait aucune commande pour l'instant!
        </h2>
        <h4 className="">
          Veuillez ajouter des produits pour pouvoir acheter.
        </h4>
      </header>
      <div className="dis-flex justify-content-center mt-5">
        <div className="col-md-5 col-sm-7">
          <NavLink
            className="btn-theme dis-flex justify-content-center no-link"
            to="/marketplace"
          >
            Commencer mes achats
          </NavLink>
        </div>
      </div>
    </section>
  )
}
