import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import {
  Commerce,
  CommerceFormData,
} from '../../../../../../utils/api/commerce/commerce.type'
import { useEditCommerceFileMutation } from '../../../../../../utils/api/commerce/commerce.api'
import { cleannerError, removeNullFromObjects } from "../../../../../../utils/utils";
import { Color } from "../../../../../../utils/theme";

function UseEditBoutique() {
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		step: yup.number().required(),
		name: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Le Nom "),
		}),
		email: yup.string().when("step", {
			is: 1,
			then: yup.string().email("Email Invalide").required().label("L'email"),
		}),
		phone: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Le Téléphone"),
		}),
		category: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("La catégorie"),
		}),
		whatsapp: yup.string().nullable().label("Le Whatshapp"),
		city: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("La ville"),
		}),
		region: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("La région"),
		}),
		location: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("L'Adresse"),
		}),

		profil: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Le type de profil"),
		}),
		country: yup
			.string()
			.when("step", {
				is: 2,
				then: yup.string().required().label("Le pays").nullable(),
			})
			.nullable(),
		isMoral: yup.boolean(),
		ninea: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup.string().required().label("Le ninea").nullable(),
			})
			.nullable(),
		account_name: yup.string().nullable().label("Le nom du compte"),
		legal_form: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup.string().required().label("Le nom légale").nullable(),
			})
			.nullable(),

		postal_card: yup.string().nullable().label("Le code postal"),
		company_register_number: yup
			.string()
			.nullable()
			.label("Le numéro d’Enrégistrement de l'entreprise"),
		bank_name: yup.string().nullable().label("Le nom de la banque"),
		account_number: yup.string().nullable().label("Le numéro de compte"),
		iban: yup.string().nullable().label("L'IBAN"),
		account_owner: yup.string().nullable().label("Le propriétaire du compte"),
		swift: yup.string().nullable().label("Le SWIFT"),
		logo: yup.mixed().label("Le logo").nullable(),
		background_image: yup.mixed().label("La bannière").nullable(),
	});

	const { register, handleSubmit, formState, clearErrors, setValue, setError } = useForm<
		CommerceFormData | any
	>({
		resolver: yupResolver(validationSchema),
	});
	const [dataForm, setDataForm] = useState<any>({
		slug: "",
		name: "",
		city: "",
		profession: "",
		description: "",
		latitude: "",
		longitude: "",
		location: "",
		email: "",
		phone: "",
		whatsapp: "",
		facebook: "",
		twitter: "",
		linkedin: "",
		site_web: "",
		condition: false,
		profil: null,
		ninea: "",
		account_name: "",
		legal_form: "",
		country: "",
		postal_card: "",
		number_of_products: null,
		company_register_number: "",
		bank_name: "",
		account_number: "",
		iban: "",
		account_owner: "",
		swift: "",
		author: null,
		category: null,
	});
	const [level, setLevel] = useState<any>(0);
	const [sendData, { isLoading, isSuccess, isError, error, data }] =
		useEditCommerceFileMutation();
	useEffect(() => {
		if (formState?.errors) {
			console.log("err", formState?.errors);
			cleannerError(formState?.errors, clearErrors, 7000);
		}
	}, [formState]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("success", data);
			Swal.fire({
				icon: "success",
				title: "Boutique modifiée avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/commercant/boutiques", { replace: false });
			});
		}
	}, [isSuccess]);

	const onSubmit = async (formValues: Commerce) => {
		// console.log({ formValues });
		// formValues['condition'] = true
		let data = removeNullFromObjects(formValues);
		console.log({ data });
		setDataForm(formValues)
		if (formValues?.step !== 3) {
		  for (let key of Object.keys(data)) {
		    const val = data[key]
		    setValue(key, val)
		  }
		  return setLevel(data.step)
		} else {
		  //   sendData(formValues)
		  let fd = new FormData()
		  for (let key of Object.keys(data)) {
		    const val = data[key]

		    fd.append(key, val)
		  }

		  await sendData({ slug: formValues?.slug, data: fd })
		}
		// let slug = data.slug;
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		formState,
		setValue,
		setError,
		isLoading,
		level,
		setLevel,
		dataForm,
	};
}

export default UseEditBoutique
