import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import CommandesListAdminTable from '../../Tables/Admin/CommandesListAdminTable/CommandesListAdminTable'
import './CommandesListAdmin.css'

const CommandesListAdmin = () => {
  const { user } = useAppSelector((s) => s?.user)
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user?.orders && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Commandes</h1>
        </div>
        {/* <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                className="search-input-admin"
                placeholder="Search.."
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div> */}
      </div>
      <div>
        <CommandesListAdminTable />
      </div>
    </div>
  )
}

export default CommandesListAdmin
