import { useEffect, useState } from "react";
import {
	AiFillMessage,
	AiFillNotification,
	AiOutlineFundProjectionScreen,
	AiOutlineMenuUnfold,
	AiTwotoneSetting,
} from "react-icons/ai";
import { GrFormClose } from "react-icons/gr";
import { TbNetwork } from "react-icons/tb";
import { BsFillCameraFill, BsFillFileEarmarkZipFill } from "react-icons/bs";
import { FiActivity } from "react-icons/fi";
import { MdFavorite, MdNotifications, MdOutlineDashboard } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { onSetUser } from "../../../../redux/slice/User.slice";
import { useChatCount } from "../../../../utils/api/chat/chat.api";
import { useEditUserMutation } from "../../../../utils/api/user/user.api";
import { getAvatar } from "../../../../utils/utils";
import { Badge, BadgePrestataire } from "../../../shared/Icons";
import "./MyCustumerArea.css";
import { useGetValidProQuery } from "../../../../utils/api/network/network.api";
import { INetwork } from "../../../../utils/api/network/network.type";
import { isPrestataire } from "../../../../routes/router";
import { RiBook3Fill } from "react-icons/ri";

const SidebarCustumerArea = () => {
	const count = useChatCount();
	const [avatar, setAvatar] = useState<File | undefined>(undefined);
	const [changeAvatar, { data }] = useEditUserMutation();
	const dispatch = useAppDispatch();
	const { user, isPro, is_prestataire } = useCheckIsPro();

	useEffect(() => {
		if (data) {
			dispatch(onSetUser(data));
		}
	}, [data]);

	const handleChangeAvatar = async (e) => {
		setAvatar(e.target.files[0]);
		const userData = new FormData();
		const userSlug = user?.slug;
		await userData.append("avatar", e.target.files[0]);
		changeAvatar({ userSlug, userData });
	};
	const isShowBadgeSeller = user && (user?.profil === "seller" || user.is_seller === true) && (
		<Badge />
	);
	const isShowBadgePrestataire = user &&
		(user.profil === "provider" || user.is_provider === true) && <BadgePrestataire />;
	return (
		<div>
			<div className="col-left-item">
				<div className="no-view-desktop">
					<div>
						<a
							className="btn btn-menu-mobile-float"
							data-bs-toggle="offcanvas"
							href="#offcanvasExample"
							role="button"
							aria-controls="offcanvasExample"
						>
							<AiOutlineMenuUnfold />
						</a>
					</div>
					<div
						className="offcanvas offcanvas-start"
						id="offcanvasExample"
						aria-labelledby="offcanvasExampleLabel"
					>
						<div className="offcanvas-header">
							<button
								type="button"
								className="btn-close btn-close-custom text-reset"
								data-bs-dismiss="offcanvas"
								aria-label="Close"
							>
								<GrFormClose />
							</button>
						</div>
						<div className="offcanvas-body">
							<div className="text-center pb-5">
								<div className="profile-pic">
									<label className="-label" htmlFor="avatar">
										<span className="glyphicon glyphicon-camera">
											<BsFillCameraFill />
										</span>
										<span>Changez votre photo</span>
									</label>
									<img
										src={
											avatar
												? URL.createObjectURL(avatar)
												: getAvatar(user?.avatar)
										}
										alt="profil"
										className="img-profil-client"
										loading="lazy"
										title="Cliquer pour changer votre photo de profil"
									/>
									<input
										type="file"
										name="avatar"
										id="avatar"
										className="avatar-user"
										onChange={handleChangeAvatar}
									/>
								</div>
								<div className="pt-3">
									<p className="profil-client-name">
										{`${user?.firstname} ${user?.lastname}`} {isShowBadgeSeller}{" "}
										{isShowBadgePrestataire}
									</p>
									<p className="profil-client-localisation">
										{user?.adresse ?? ""}
									</p>
								</div>
							</div>
							<div className="" id="scrollbar">
								<div className="content">
									<div className="nav flex-column nav-pills me-3 ms-4 w-100">
										{/* <NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/tableau-de-bord"
										>
											<span className="icon-tb-espace-client-container">
												<MdOutlineDashboard />
											</span>
											<span className="span-nav-espace-client">
												Mon tableau de bord
											</span>
										</NavLink> */}
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-annonces"
											// hidden={!is_prestataire}
										>
											<span className="icon-tb-espace-client-container">
												<AiFillNotification />
											</span>
											<span className="span-nav-espace-client">
												Mes annonces
											</span>
										</NavLink>
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-projets"
											hidden={!is_prestataire}
										>
											<span className="icon-tb-espace-client-container">
												<AiOutlineFundProjectionScreen />
											</span>
											<span className="span-nav-espace-client">
												Mes Appel d’offre
											</span>
										</NavLink>
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-annuaires"
										>
											<span className="icon-tb-espace-client-container">
												<RiBook3Fill />
											</span>
											<span className="span-nav-espace-client">
												Annuaire
											</span>
										</NavLink>
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mon-historique"
										>
											<span className="icon-tb-espace-client-container">
												<FiActivity />
											</span>
											<span className="span-nav-espace-client">
												Mon historique
											</span>
										</NavLink>
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-candidatures"
										>
											<span className="icon-tb-espace-client-container">
												<BsFillFileEarmarkZipFill />
											</span>
											<span className="span-nav-espace-client">
												Mes candidatures
											</span>
										</NavLink>
										{/* <NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-achats"
										>
											<span className="icon-tb-espace-client-container">
												<BsBasket2Fill />
											</span>
											<span className="span-nav-espace-client">
												Mes Achats
											</span>
										</NavLink> */}

										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/notifications"
										>
											<span className="icon-tb-espace-client-container">
												<MdNotifications />
											</span>
											<span className="span-nav-espace-client">
												Notifications
											</span>
										</NavLink>
										{isPro && (
											<NavLink
												className="nav-link nav-link-tb-espace-client"
												to="/app/mon-espace-client/messages"
												hidden={!is_prestataire}
											>
												<span className="icon-tb-espace-client-container">
													<AiFillMessage />
												</span>
												<span className="span-nav-espace-client">
													Messages
												</span>
												{count > 0 && (
													<span>
														<span className="statut-count">
															{count}
														</span>
													</span>
												)}
											</NavLink>
										)}
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/mes-favoris"
										>
											<span className="icon-tb-espace-client-container">
												<MdFavorite />
											</span>
											<span className="span-nav-espace-client">
												Mes favoris
											</span>
										</NavLink>
										{isPro && (
											<NavLink
												className="nav-link nav-link-tb-espace-client"
												to="/app/mon-espace-client/reseau"
											>
												<span className="icon-tb-espace-client-container">
													{/* <ImStatsDots /> */}
													<TbNetwork />
												</span>
												<span className="span-nav-espace-client">
													Réseau
												</span>
											</NavLink>
										)}
										<NavLink
											className="nav-link nav-link-tb-espace-client"
											to="/app/mon-espace-client/parametres"
										>
											<span className="icon-tb-espace-client-container">
												<AiTwotoneSetting />
											</span>
											<span className="span-nav-espace-client">
												Paramètres
											</span>
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="no-view-mobile">
					<div className="text-center pt-4 pb-5">
						<div className="profile-pic">
							<label className="-label" htmlFor="avatar">
								<span className="glyphicon glyphicon-camera">
									<BsFillCameraFill />
								</span>
								<span>Changez votre photo</span>
							</label>
							<img
								src={avatar ? URL.createObjectURL(avatar) : getAvatar(user?.avatar)}
								alt="profil"
								className="img-profil-client"
								loading="lazy"
								title="Cliquer pour changer votre photo de profil"
							/>
							<input
								type="file"
								name="avatar"
								id="avatar"
								className="avatar-user"
								onChange={handleChangeAvatar}
							/>
						</div>

						<div className="pt-3">
							<p className="profil-client-name">
								{`${user?.firstname} ${user?.lastname}`} {isShowBadgeSeller}{" "}
								{isShowBadgePrestataire}
							</p>
							<p className="profil-client-localisation">{user?.adresse ?? ""}</p>
						</div>
					</div>
					<div className="nav flex-column nav-pills me-3 ms-4 w-100">
						{/* <NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/tableau-de-bord"
						>
							<span className="icon-tb-espace-client-container">
								<MdOutlineDashboard />
							</span>
							<span className="span-nav-espace-client">
								Mon tableau de bord
							</span>
						</NavLink> */}
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-annonces"
							// hidden={!is_prestataire}
						>
							<span className="icon-tb-espace-client-container">
								<AiFillNotification />
							</span>
							<span className="span-nav-espace-client">Mes annonces</span>
						</NavLink>
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-projets"
							hidden={!is_prestataire}
						>
							<span className="icon-tb-espace-client-container">
								<AiOutlineFundProjectionScreen />
							</span>
							<span className="span-nav-espace-client">Mes Appel d’offre</span>
						</NavLink>
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-annuaires"
						>
							<span className="icon-tb-espace-client-container">
								<RiBook3Fill />
							</span>
							<span className="span-nav-espace-client">Annuaire</span>
						</NavLink>
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mon-historique"
						>
							<span className="icon-tb-espace-client-container">
								<FiActivity />
							</span>
							<span className="span-nav-espace-client">Mon historique</span>
						</NavLink>
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-candidatures"
						>
							<span className="icon-tb-espace-client-container">
								<BsFillFileEarmarkZipFill />
							</span>
							<span className="span-nav-espace-client">Mes candidatures</span>
						</NavLink>
						{/* <NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-achats"
						>
							<span className="icon-tb-espace-client-container">
								<BsBasket2Fill />
							</span>
							<span className="span-nav-espace-client">Mes Achats</span>
						</NavLink> */}
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/notifications"
						>
							<span className="icon-tb-espace-client-container">
								<MdNotifications />
							</span>
							<span className="span-nav-espace-client">Notifications</span>
						</NavLink>
						{isPro && (
							<NavLink
								className="nav-link nav-link-tb-espace-client"
								to="/app/mon-espace-client/messages"
							>
								<span className="icon-tb-espace-client-container">
									<AiFillMessage />
								</span>
								<span className="span-nav-espace-client">Messages</span>
								{count > 0 && (
									<span>
										<span className="statut-count">{count}</span>
									</span>
								)}
							</NavLink>
						)}
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/mes-favoris"
						>
							<span className="icon-tb-espace-client-container">
								<MdFavorite />
							</span>
							<span className="span-nav-espace-client">Mes favoris</span>
						</NavLink>
						{isPro && (
							<NavLink
								className="nav-link nav-link-tb-espace-client"
								to="/app/mon-espace-client/reseau"
							>
								<span className="icon-tb-espace-client-container">
									{/* <ImStatsDots /> */}
									<TbNetwork />
								</span>
								<span className="span-nav-espace-client">Réseau</span>
							</NavLink>
						)}
						<NavLink
							className="nav-link nav-link-tb-espace-client"
							to="/app/mon-espace-client/parametres"
						>
							<span className="icon-tb-espace-client-container">
								<AiTwotoneSetting />
							</span>
							<span className="span-nav-espace-client">Paramètres</span>
						</NavLink>
					</div>
				</div>
			</div>
		</div>
	);
};

export function useCheckIsPro() {
	const { user } = useAppSelector((state) => state.user);
	const { data: pros } = useGetValidProQuery();
	const [pro, setPro] = useState<INetwork>();
	const is_prestataire = isPrestataire(user);

	useEffect(() => {
		if (pros?.length) {
			const isPro = pros?.find(
				(pro) => pro?.user?.id === user?.id && pro.valid === true
			);
			setPro(isPro);
		}
	}, [pros]);
	return {
		isPro: pro,
		is_prestataire,
		user,
		pro,
	};
}

export default SidebarCustumerArea;
