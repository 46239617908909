import React, { useState } from "react";
import { BtnBack, BtnNext, BtnSkip } from "../../../../common/Button";
import { CustomInput, FormError } from "../../../../common/CustomInput";
import { CustomSelectInput } from "../../../../common/CustomSelectInput";
import GoogleInput from "../../../../common/GoogleInput";
import { TraderStepPropsType } from "./AddInfoTrader";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
export const Profil = [
	{
		label: "Personne physique",
		value: "personne_physique",
	},
	{
		label: "Personne morale",
		value: "personne_morale",
	},
];
const AddInfoCompany = ({
	navigation,
	register,
	formState,
	setValue,
	setAdress,
	adress,
	profil,
	setProfil,
	options,
	cityLabel,
	dataForm,
	country,
	setCountry,
	state,
	setState,
	city,
	setCity,
	countryLabel,
	stateLabel,
}) => {
	const handleCountrySelect = (option: any) => {
		setCountry(option);
		console.log("country", option);
		setValue("country", option.name);
	};

	const handleStateSelect = (option: any) => {
		setValue("region", option.name);
		setState(option);
	};

	const handleCitySelect = (option: any) => {
		setValue("city", option.name);
		setCity(option);
	};

	React.useEffect(() => {
		//   console.log(country)
		if (country?.name) {
			setValue("country", country?.name);
		}

		if (state?.name) {
			setValue("region", state?.name);
		}

		if (city?.name) {
			setValue("city", city?.name);
		}
	}, [country, state, city]);
	return (
		<>
			<h1 className="stepper-title">Ajouter les informations sur l’entreprise</h1>
			<div className="row">
				<div className="col-md-6 col-xl-5">
					<h3 className="stepper-title-l3">
						Veuillez sélectionner si vous êtes une personne physique ou une
						entité commerciale/entreprise
					</h3>
				</div>
			</div>
			<div>
				<div className="row">
					<CustomSelectInput
						label="Choisir une option"
						name="option"
						value={profil}
						required
						onChange={(e) => {
							console.log("value", e.target.value);
							setProfil(e.target.value);
							if (e.target.value === "personne_morale") {
								setValue("isMoral", true);
							} else {
								setValue("isMoral", false);
							}
						}}
						options={Profil}
						error={formState?.errors?.profil?.message}
					/>
					<CustomInput
						label="Nom légale / Raison sociale"
						name="legale-name"
						type="text"
						required={profil === "personne_morale" ? true : false}
						{...register("legal_form")}
						error={formState?.errors?.legal_form?.message}
					/>
					<CustomInput
						label="Personne responsable"
						name="resp-people"
						type="text"
						{...register("person_in_charge")}
						error={formState?.errors?.person_in_charge?.message}
					/>
					<CustomInput
						label="Le numéro d’Identificaion Nationale des Entreprises et des Associations
						(NINEA)"
						name="ninea"
						required={profil === "personne_morale" ? true : false}
						type="text"
						{...register("ninea")}
						error={formState?.errors?.ninea?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="Forme juridique"
						name="forme-juridique"
						type="text"
						{...register("forme_juridique")}
						error={formState?.errors?.forme_juridique?.message}
					/>
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="location" className="custom-form-label">
							Adresse
							<span className="text-danger">*</span>
						</label>
						<GoogleInput
							value={adress || ""}
							onChange={(value) => {
								setAdress(value.rue);
								setValue("location", value?.rue);
								setValue("longitude", value?.longitude);
								setValue("latitude", value?.latitude);
							}}
							className="form-control espace-client-form-control"
						/>
						{<FormError error={formState?.errors?.location?.message} />}
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="pays" className="custom-form-label">
							Pays
							<span className="text-danger">*</span>
						</label>
						<CountrySelector
							onChange={handleCountrySelect}
							name="country"
							placeholder={countryLabel || "Choisir un pays"}
							value={country}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.country?.message} />}
					</div>
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="state" className="custom-form-label">
							Région
							<span className="text-danger">*</span>
						</label>
						<StateSelector
							onChange={handleStateSelect}
							name="state"
							country={country}
							placeholder={stateLabel || "Choisir une région"}
							countryPlaceholder={stateLabel || "Choisir un pays"}
							value={state}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.region?.message} />}
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="state" className="custom-form-label">
							Ville
							<span className="text-danger">*</span>
						</label>
						<CitySelector
							onChange={handleCitySelect}
							name="city"
							state={state}
							placeholder={cityLabel || "Choisir une ville"}
							statePlaceholder={cityLabel || "Choisir une région"}
							value={city}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.city?.message} />}
					</div>
					<CustomInput
						label="Code postale"
						name="postal_card"
						type="text"
						{...register("postal_card")}
						error={formState?.errors?.postal_card?.message}
					/>
				</div>
				<div className="row">
					{/* <CustomInput
						label="Catégories principale de vos produits"
						name="categorie-principale"
						type="text"
					/> */}
					<CustomSelectInput
						label="Catégories principale de vos produits"
						name="category"
						required
						options={options}
						{...register("category")}
						error={formState?.errors?.category?.message}
					/>
				</div>
				<div className="row">
					{/* <CustomSelectInput
						label="N° d’Enrégistrement des Entreprises"
						name="num-company-register"
						options={Status}
					/> */}
				</div>
				<div className="flex-sb mt-5">
					<div>
						<BtnBack
							label="Retour"
							type="button"
							navigation={navigation}
							onClick={() => {}}
						/>
					</div>
					<div className="flex-r gap-3">
						<BtnSkip label="Passer l’étape" type="button" />
						<BtnNext label="Suivant" type="submit" />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddInfoCompany;
