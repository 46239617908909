import { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import { useStepType } from "../../../../utils/type";
import StepperHorizontal from "../../../common/StepperHorizontal";
import UseAddArticleForm from "./requestArticle/UseAddArticleForm";
import AddImage from "./Steps/AddImage";
import DetailProduct from "./Steps/DetailProduct";
import MoreDetail from "./Steps/MoreDetail";
import Tarification from "./Steps/Tarification";

const steps = [
	{
		id: "detail-product",
		Component: DetailProduct,
	},
	{
		id: "more-detail",
		Component: MoreDetail,
	},
	{
		id: "tarif",
		Component: Tarification,
	},
	{
		id: "add-image",
		Component: AddImage,
	},
];
const AddArticleTrader = () => {
	const {register, setValue, errors, dataForm, level, onSubmit, setError, isLoading} = UseAddArticleForm()
	const [pricipalColor, setPricipalColor] = useState<string>("")
	const [description, setDescription] = useState<string>("");
	const [files, setFiles] = useState<File[]>([])
	const [file, setFile] = useState<File>()
	const [category, setCategory] = useState<any>({})
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});
	const { Component } = step;
	const props = {
		navigation,
		register,
		setValue,
		errors,
		dataForm,
		pricipalColor,
		setPricipalColor,
		description,
		setDescription,
		files,
		setFiles,
		file,
		setFile,
		setError,
		category,
		setCategory,
		isLoading
	};

	useEffect(() => {
		window.scrollTo(10, 10);
		let newIndex = index + 1;
		setValue("step", newIndex);
	}, [index]);

	useEffect(() => {
		if (level) {
			if (level === 1) {
				navigation.go("more-detail");
			}

			if (level === 2) {
				navigation.go("tarif");
			}

			if (level === 3) {
				navigation.go("add-image");
			}
		}
	}, [level, dataForm]);
	return (
		<div className="article-card p-4">
			<div className="flex-sb">
				<div className="d-flex flex-column flex-md-row gap-md-3">
					<h3 className="article-title">Ajouter un produit</h3>
				</div>
			</div>
			<StepperHorizontal index={index} />
			<form onSubmit={onSubmit}>
				<Component {...props} />
			</form>
		</div>
	);
};

export default AddArticleTrader;
