import React, { useEffect, useState } from "react";
import StarRatingComponent from "react-star-rating-component";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import "./PartnerList.css";
import { createMarkup, getImage } from "../../../../utils/utils";
import bg from "../../../assets/appImages/bg.png";
import Specialites from "./DetailsPartnerTabs/Specialites";
import Expertises from "./DetailsPartnerTabs/Expertises";
import Carousel from "react-multi-carousel";
import Whatsapp from "../../../assets/icons/whatsapp.png";
import Phone from "../../../assets/icons/phone.png";
import Mail from "../../../assets/icons/mail.png";
import { Map, Marker } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { InfoWindow } from "google-maps-react";
import { usePartenaireFromLocation } from "../../../../utils/api/partenaire/partenaire.api";
import { useAppSelector } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import {
	useAddRatePartnerMutation,
	useUpdateRatePartnerMutation,
} from "../../../../utils/api/rate_partner/ratePartner.api";
import { IRatePartner } from "../../../../utils/api/rate_partner/ratePartner.type";
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 4,
		itemsToScroll: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		itemsToScroll: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		itemsToScroll: 1,
	},
};

const PartnerDetail = (props) => {
	const { user } = useAppSelector((s) => s.user);
	const navigate = useNavigate();
	const [active, setActive] = useState<number>(0);
	const tabs = ["Description", "Spécialités", "Expertise"];
	const [partner, isLoading, slug, findBySlug] = usePartenaireFromLocation();
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);
	const [activeMarker, setActiveMarker] = useState<any>({});
	const [selectedPlace, setSelectedPlace] = useState({});
	const [rating, setRating] = useState<number>(0);
	const [isRating, setIsRating] = useState<IRatePartner | null>(null);
	const [addRatePartner, { isSuccess: isSucRate }] = useAddRatePartnerMutation();
	const [updateRatePartner, { isSuccess: isSucRateupdate }] = useUpdateRatePartnerMutation();
	useEffect(() => {
		if (partner?.rates && partner?.rates?.length > 0) {
			const rate =
				partner?.rates?.reduce((acc, item) => acc + item?.rating, 0) /
				partner?.rates?.length;
			setRating(rate);
		}
	}, [partner?.rates]);
	useEffect(() => {
		if (partner?.rates) {
			const isRating = partner?.rates?.find((item) => item?.user === user?.id);
			setIsRating(isRating || null);
		}
	}, [partner]);
	useEffect(() => {
		if (isSucRate) {
			findBySlug(slug);
		}
	}, [isSucRate]);
	useEffect(() => {
		if (isSucRateupdate) {
			findBySlug(slug);
		}
	}, [isSucRateupdate]);
	const onMapClicked = () => {
		if (showingInfoWindow) {
			setActiveMarker(null);
			setShowingInfoWindow(false);
		}
	};
	const onMarkerClick = (props, marker) => {
		setActiveMarker(marker);
		setSelectedPlace(props);
		setShowingInfoWindow(true);
	};
	const onInfoWindowClose = () => {
		setActiveMarker(null);
		setShowingInfoWindow(false);
	};
	const handleRatePartner = async (index) => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		// setRating(index);
		if (!isRating) {
			const data = {
				rating: index,
				user: user?.id,
				partner: partner?.id,
			};
			await addRatePartner(data);
		} else {
			await updateRatePartner({ slug: isRating?.slug, rating: index });
		}
	};
	return (
		<div className="shop-list-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="mb-5">
					<div className="position-relative flex-c-m">
						<img
							src={getImage(partner?.background_image)}
							alt="bg"
							className="img-bg detail-partner-img-bg"
						/>
						<div className="position-absolute d-flex flex-column align-items-center detail-partner-cover">
							<h1 className="partner-name m-0">{partner?.name}</h1>
							<p className="partner-type">{partner?.title}</p>
							<p className="partner-type my-1">
								Hlm grand Yoff, Dakar, Sénégal
							</p>
							<div className="flex-c-m gap-2">
								<p className="partner-detail-note">{rating}</p>
								<StarRatingComponent
									name="ratePartner"
									editing={true}
									starCount={5}
									value={rating}
									starColor="#F6973F"
									emptyStarColor="#CCCCCC"
									onStarClick={(index) =>
										handleRatePartner(index)
									}
								/>
							</div>
							<div className="partner-detail-logo-container">
								<img
									src={getImage(partner?.icon)}
									alt="logo-company"
									width="100%"
								/>
							</div>
						</div>
					</div>
					<div className="listings-bar mb-4">
						<ul className="flex-c-m gap-4">
							{tabs.map((tab, index) => (
								<li
									role="button"
									key={index}
									className={`partner-tab ${
										active === index && "active"
									}`}
									onClick={() => setActive(index)}
								>
									<span>{tab}</span>
								</li>
							))}
						</ul>
					</div>
					<div className="ps-xl-3 ps-1 ps-sm-2 bloc-partner">
						{active === 0 && (
							<>
								<div className="col-tab-espace-client p-3 mb-3">
									<div className="pb-3 b-b-1">
										<p className="titre-liste-des-commerce fw-600">
											Ce que nous faisons
										</p>
									</div>
									<div className="">
										<Carousel
											swipeable={false}
											draggable={false}
											showDots={true}
											responsive={responsive}
											ssr={false}
											infinite={true}
											autoPlay={true}
											autoPlaySpeed={1000}
											keyBoardControl={true}
											arrows={true}
											customTransition="transform 1000ms ease-in-out"
											transitionDuration={2000}
											containerClass="carousel-container"
											removeArrowOnDeviceType={[
												"tablet",
												"mobile",
											]}
											dotListClass="custom-dot-list-style"
											itemClass="carousel-item-padding-40-px py-5"
										>
											{partner?.slides?.length > 0 &&
												partner?.slides?.map(
													(item) => {
														return (
															<div
																key={
																	item.slug
																}
															>
																<img
																	src={getImage(
																		item?.picture
																	)}
																	// alt={
																	// 	item.title
																	// }
																	className="card-partner-img"
																	height="250px"
																	width="100%"
																/>
															</div>
														);
													}
												)}
										</Carousel>
									</div>
								</div>
								<div className="col-tab-espace-client p-3 mb-3">
									<div className="pb-3 b-b-1">
										<p className="titre-liste-des-commerce">
											Description
										</p>
									</div>
									<div
										className="pt-2 texte-description-parter"
										dangerouslySetInnerHTML={createMarkup(
											partner?.description
										)}
									/>
								</div>
								<div className="row mb-3">
									<div className="col-md-6">
										<div className="col-tab-espace-client p-3 mb-3">
											<div className="pb-3 b-b-1">
												<p className="titre-liste-des-commerce">
													Intinéraire du
													commerce
												</p>
											</div>
											<div
												style={{
													height: "350px",
													width: "100%",
												}}
												className="container-map-partner mt-3 mb-2"
											>
												<Map
													google={props.google}
													zoom={14}
													initialCenter={{
														lat: parseFloat(
															partner?.latitude
														),
														lng: parseFloat(
															partner?.longitude
														),
													}}
													onClick={onMapClicked}
												>
													<Marker
														name={
															partner?.name
														}
														clickable
														onClick={
															onMarkerClick
														}
														position={{
															lat: parseFloat(
																partner?.latitude
															),
															lng: parseFloat(
																partner?.longitude
															),
														}}
													/>
													<InfoWindow
														marker={
															activeMarker
														}
														onClose={
															onInfoWindowClose
														}
														visible={
															showingInfoWindow
														}
														position={{
															lat: parseFloat(
																partner?.latitude
															),
															lng: parseFloat(
																partner?.longitude
															),
														}}
													>
														<div>
															<h4
																style={{
																	color: "#FFA73C",
																}}
															>
																{
																	partner?.name
																}
															</h4>
														</div>
													</InfoWindow>
												</Map>
											</div>
										</div>
									</div>
									<div className="col-md-6">
										<div className="col-tab-espace-client p-3 mb-3">
											<div className="pb-3 b-b-1">
												<p className="titre-liste-des-commerce">
													Contacter le
													partenaire
												</p>
											</div>
											<div className="my-4">
												<div className="flex-sa">
													<a
														href={`https://wa.me/${partner?.whatsapp}}`}
														className="constainer-icon-socials-media"
													>
														<img
															src={
																Whatsapp
															}
															alt="whatsapp"
															className="icon-socials-media"
														/>
														<p className="text-icon-socials-media">
															Whatsapp
														</p>
													</a>
													<a
														href={`tel:${partner?.phone}`}
														className="constainer-icon-socials-media"
													>
														<img
															src={
																Phone
															}
															alt="whatsapp"
															className="icon-socials-media"
														/>
														<p className="text-icon-socials-media">
															Appeler
														</p>
													</a>
													<a
														href={`mailto:${partner?.email}`}
														className="constainer-icon-socials-media"
													>
														<img
															src={Mail}
															alt="whatsapp"
															className="icon-socials-media"
														/>
														<p className="text-icon-socials-media">
															Envoyer un
															email
														</p>
													</a>
												</div>
											</div>
											<div className="py-3 b-b-1">
												<p className="titre-liste-des-commerce">
													Suivre sur les réseaux
													sociaux
												</p>
											</div>
											<div className="my-4">
												<div className="social-links flex-c">
													<a
														href={
															partner?.facebook
														}
														target="_blank"
													>
														<i className="fab fa-facebook-f"></i>
													</a>
													<a
														href={
															partner?.twitter
														}
														target="_blank"
													>
														<i className="fab fa-twitter"></i>
													</a>
													<a
														href={
															partner?.instagram
														}
														target="_blank"
													>
														<i className="fab fa-instagram"></i>
													</a>
													<a
														href={
															partner?.linkedin
														}
														target="_blank"
													>
														<i className="fab fa-linkedin-in"></i>
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="col-tab-espace-client p-3 mb-3">
									<div className="pb-3 b-b-1">
										<p className="titre-liste-des-commerce">
											Notation
										</p>
									</div>
									<div className="text-center p-4">
										<p className="card-partner-title">
											Cliquez sur une étoile pour noter{" "}
											{partner?.name}
										</p>
										<div className="py-3">
											<StarRatingComponent
												name="ratePartner"
												editing={true}
												starCount={5}
												value={rating}
												starColor="#F6973F"
												emptyStarColor="#CCCCCC"
												onStarClick={(index) =>
													handleRatePartner(
														index
													)
												}
											/>
										</div>
										{partner?.rates?.length === 0 && (
											<p
												className="texte-description-parter"
												style={{
													textAlign: "center",
												}}
											>
												Aucun vote pour l’instant!
												Soyez le premier à noter
												cette boutique
											</p>
										)}
									</div>
								</div>
							</>
						)}
						{active === 1 && <Specialites partner={partner} />}
						{active === 2 && <Expertises partner={partner} />}
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(PartnerDetail);
