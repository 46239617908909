import React, { useState } from "react";
import OffresTableTableSkeleton from "./OffresTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { createMarkup, formatMontant, getImage, showOfferType } from "../../../../../utils/utils";
import { useGetOffersQuery } from "../../../../../utils/api/abonnement/abonnement.api";
import { IOffer } from "../../../../../utils/api/abonnement/abonnement.type";
import { useDeleteOffer } from "../../../modals/adminModal/forms/useCrudBoostOffer";
import AddBoostOfferModal from "../../../modals/adminModal/AddBoostOfferModal";

function OffresTable() {
	const { isLoading, data } = useGetOffersQuery();
	const [item, setItem] = useState<IOffer>();
	const clean = () => {
		setItem(undefined);
	};
	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						data-bs-toggle="modal"
						data-bs-target={`#addBoostOfferModal`}
						onClick={() => setItem(row)}
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteOffer item={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "type_boost",
			text: "Type",
			style: { textAlign: "left" },
			formatter: (cell: string, row: IOffer) => <>{showOfferType(cell)}</>,
		},
		{
			dataField: "prix",
			text: "Prix",
			style: { textAlign: "left" },
			formatter: (cell: number, row: IOffer) => <>{formatMontant(cell)} </>,
		},
		{
			dataField: "duree",
			text: " Durée",
			style: { textAlign: "left" },
			formatter: (cell: number, row: IOffer) => <>{cell} jours</>,
		},
		{
			dataField: "description",
			text: " Description",
			style: { textAlign: "left" },
			formatter: (cell: string, row: IOffer) => (
				<div
					dangerouslySetInnerHTML={createMarkup(cell?.slice(0, 100))}
					className="descritpion-text"
				/>
			),
		},
		{
			dataField: "slug",
			text: "actions",
			headerStyle: () => {
				return { textAlign: "center" };
			},
			formatter: (cell: string, row: IOffer) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <OffresTableTableSkeleton />}
			{!isLoading && (
				<div>
					<BootstrapTable
						keyField="slug"
						data={data || []}
						columns={columns}
						striped
						noDataIndication="Aucune donnée disponible"
						bordered={false}
						condensed={false}
						responsive
						wrapperClasses="table-responsive admin-table"
						headerClasses="header-class"
					/>
				</div>
			)}
			<AddBoostOfferModal offer={item} clean={clean} />
		</>
	);
}

function DeleteOffer({ item }: { item: IOffer }) {
	const onDelete = useDeleteOffer(item);
	return (
		<button className="btn btn-action-user-admin" title="Supprimer" onClick={onDelete}>
			<RiDeleteBin7Fill />
		</button>
	);
}

export default OffresTable;
