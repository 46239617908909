import React from "react"
import Agriculture from "../../assets/landingpage/Agriculture.png"
import Numérique from "../../assets/landingpage/Numérique.png"
import tourisme from "../../assets/landingpage/tourisme.png"
import durable from "../../assets/landingpage/durable.png"
import immo from "../../assets/landingpage/immo.png"
import arti from "../../assets/landingpage/arti.png"
import sport from "../../assets/landingpage/sport.png"
const Secteurs = () => {
	return (
		<div className="secteurs-component">
			<div className="container-section-landingpage">
                <div className="container-title-landingpage">
                    <h2 className="title-section-landingpage position-relative text-center">Nos secteurs clés</h2>
                </div>
                <div className="container-text-title-concept-landingpage w-50 m-auto py-4">
                    <p className="text-concept-landingpage pt-4">Nous couvrons les secteurs clés suivants ... :</p>
                </div>
                <div className="row row-secteurs-landingpage mt-4">
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={Agriculture} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Agriculture</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={Numérique} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Numérique</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={tourisme} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Tourisme</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={durable} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Economie solidaire & Développement durable</p>
                                </div>
                            </div>
                        </div>
                </div>
                <div className="row row-secteurs-landingpage mt-4 justify-content-center">
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={immo} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Bâtiment</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={arti} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Artisanat</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 col-secteurs-landingpage d-flex mb-4">
                            <div className="content-col-secteurs-landingpage w-100">
                                <div className="content-img-icon-secteurs-landingpage">
                                    <img src={sport} alt="secteurs" className="img-icon-secteurs-landingpage" />
                                </div>
                                <div className="container-title-secteur-card">
                                    <p className="title-secteurs-card-landingpage">Métiers support aux entreprises* (Transport, commercial, administration)</p>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
		</div>
	);
};

export default Secteurs;
