import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";
import { ITeam, TeamFormData } from "../../../../../utils/api/about/about.type";
import { useAddOrEditTeamMutation } from "../../../../../utils/api/about/about.api";

function useCrudTeam(closeModal: () => void, item?: ITeam) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		fonction: yup.string().required().label("Fonction"),
		position: yup
			.number()
			.required()
			.label("Position")
			.typeError("Position est un champ obligatoire"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<TeamFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [avatar, setAvatar] = useState<any>(null);
	const [addData, { isLoading, isSuccess, isError, error, data, reset }] =
		useAddOrEditTeamMutation();

	useEffect(() => {
		if (errors) {
			for (let key of Object.keys(errors)) {
				if (key) {
					window.scrollTo(10, 10);
				}
			}
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		let fields: (keyof ITeam)[] = [
			"nom",
			"fonction",
			"facebook",
			"twitter",
			"linkedin",
			"position",
		];
		if (item) {
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
		} else {
			for (let field of fields) {
				register(field);
				setValue(field, "");
			}
		}
	}, [item]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			setAvatar(null);
			reset();
			Swal.fire({
				icon: "success",
				title: `Membre ${!item ? "ajouté" : "modifié"} avec succès!`,
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(async () => {
				await reset();
				closeModal();
			});
		}
	}, [isSuccess]);

	const handleChangeImage = (e) => {
		setAvatar(e.target.files[0]);
		setValue("image", e.target.files[0]);
	};

	const handleReset = () => {
		setAvatar(null);
		reset();
		closeModal();
	};

	const onSubmit = async (formValues: TeamFormData) => {
		console.log(formValues);
		const fd = new FormData();

		for (let key of Object.keys(formValues)) {
			const val = formValues[key];
			fd.append(key, val);
		}
		await addData({ slug: item?.slug, data: fd });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		avatar,
		reset: handleReset,
		handleChangeImage,
	};
}

export default useCrudTeam;
