import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { EditNetworKData } from "../../../../../utils/api/network/network.type";
import { useEditProMutation } from "../../../../../utils/api/network/network.api";
import { cleannerError } from "../../../../../utils/utils";
import { useCheckIsPro } from "../SidebarCustumerArea";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";

const useEditInfoPro = () => {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom du responsable"),
		prenom: yup.string().required().label("Prenom du responsable"),
		city: yup.string().required().label("Ville"),
		fonction: yup.string().required().label("Fonction"),
		country: yup.string().required().label("Pays"),
		region: yup.string().required().label("Région"),
		adress: yup.string().required().label("Adresse"),
		code_postal: yup.string().required().label("Code Postal"),
		siret: yup.string().required().label("Siret"),
		expertise_pro: yup.string().required().label("Description"),
	});
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [description, setDescription] = useState<string>("");
	const [isValidDescription, setIsValidDescription] = useState<boolean>(false);
	const { pro } = useCheckIsPro();
	const [editPro, { isLoading }] = useEditProMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<EditNetworKData>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			slug: pro?.slug,
		},
	});

	useEffect(() => {
		cleannerError(errors, clearErrors, 6000);
	}, [errors]);

	useEffect(() => {
		if (pro) {
			// console.log({ pro });
			setValue("expertise_pro", pro?.expertise_pro);
			setDescription(pro?.expertise_pro);
			setState(pro?.region);
			setCity(pro?.city);
			setCountry(pro?.country);
			setValue("siret", pro?.siret);
			setValue("raison_sociale", pro?.raison_sociale);
			setValue("nom", pro?.nom);
			setValue("prenom", pro?.prenom);
			setValue("code_postal", pro?.code_postal);
			setValue("adress", pro?.adress);
			setValue("fonction", pro?.fonction);
			setValue("country", pro?.country);
			setValue("region", pro?.region);
			setValue("city", pro?.city);
		}
	}, [pro]);

	const handleCountrySelect = (option: any) => {
		setCountry(option);
		setValue("country", option.name);
	};

	const handleStateSelect = (option: any) => {
		if (option.label) {
			setState(option);
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		if (option.label) {
			setCity(option);
			setValue("city", option.label);
		}
	};

	const handleChangeDescription = (data) => {
		setValue("expertise_pro", data);
		if (data.length >= 100) {
			setIsValidDescription(true);
		} else {
			setIsValidDescription(false);
		}
	};

	const onSubmit = async (data: EditNetworKData) => {
		console.log(data);
		const res = await editPro({ slug: pro?.slug as string, data });
		if ("data" in res) {
			Swal.fire({
				icon: "success",
				title: "Profil modifié avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			});
		} else if ("error" in res) {
			const err = res.error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		country,
		state,
		city,
		handleCountrySelect,
		handleCitySelect,
		handleStateSelect,
		handleChangeDescription,
		isValidDescription,
		pro,
		description,
		isLoading,
	};
};

export default useEditInfoPro;
