import React from "react";
import { Outlet } from "react-router-dom";
import { useGetConditionsQuery } from "../../../utils/api/conditions/condition.api";
import FrontFooter from "../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./ConditionGenerale.css";

const ConditionLayout = () => {
	return (
		<div className="app/condition-generale-component">
			<FrontHeader />
			<div className="py-5">
				<section className="container-munngano">
					<Outlet />
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default ConditionLayout;
