import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddFaqMutation, useDeleteFaqMutation } from "../../../../../utils/api/faq/faq.api";
import { IFaq, TFaqForm } from "../../../../../utils/api/faq/faq.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";

const useHelpForm = () => {
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Le titre"),
		content: yup.string().required().label("Le Contenu"),
	});

	const [addFaq, { isLoading, isError, isSuccess, error }] = useAddFaqMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<TFaqForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Question ajoutée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("AddQuestionModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de l'ajout",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: TFaqForm) => {
		addFaq(data);
	};
	return {
		register,
		onsubmit: handleSubmit(onsubmit),
		setValue,
		errors,
		isLoading,
	};
};
export function useDeleteFaq(item: IFaq) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteFaqMutation();

	const onDelete = useCallback(() => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cette question",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Question supprimée avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
export default useHelpForm;
