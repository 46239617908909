import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IBudget } from "../../utils/api/budget/budget.type";
import { AppStorage } from "../../utils/storage";

export const BudgetSlice = createSlice({
	name: "budgetSlice",
	initialState: {},
	reducers: {
		onSetBudgets: (state, action: PayloadAction<IBudget[]>) => {
			state = action.payload;
			AppStorage.setItem("budgets", state);
			return state;
		},
	},
});

export const { onSetBudgets } = BudgetSlice.actions;
