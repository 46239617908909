import { createMarkup, getImage } from "../../../../utils/utils";
import CloseModalBtn from "../../../shared/CloseModalBtn";

function DetailSectionAboutModal({ item }) {
	return (
		<div
			className="modal fade"
			id="DetailSectionAboutModal"
			aria-labelledby={`DetailSectionAboutModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog easypm-modal-dialog modal-lg">
				<div className="modal-content easypm-modal-content">
					<div className="modal-header easypm-modal-header">
						<CloseModalBtn />
					</div>
					<div className="p-3">
						<div className="add-family-form-container">
							<div className="row position-relative">
								<div className="justify-content-center crud-col-item  d-flex col-md-6">
									<div className="form-group align-items-center bloc-admin-profil position-relative text-center">
										<img
											src={getImage(item?.image)}
											alt="img admin"
											className="img-admin"
											width="300"
											height="300"
										/>
										<h3 className="profil-nom">
											{item?.nom}
										</h3>
									</div>
								</div>
								<div className="row col-md-6">
									<div className="col-md-12 crud-col-item">
										<div className="form-group crud-form-group group-text">
											<p className="texte-gray">
												Titre
											</p>
											<p className="bloc-text-profil">
												{item?.titre}
											</p>
										</div>
									</div>
									<div className="col-md-12 crud-col-item">
										<div className="form-group crud-form-group group-text">
											<p className="texte-gray">Type</p>
											<p className="bloc-text-profil">
												{item?.type_propos ===
												"histoire"
													? "Histoire"
													: "Idée"}
											</p>
										</div>
									</div>
								</div>
								<div className="col-12 crud-col-item">
									<div className="form-group crud-form-group group-text">
										<p className="texte-gray">
											Description
										</p>
										<div
											className="bloc-text-profil"
											dangerouslySetInnerHTML={createMarkup(
												item?.content
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailSectionAboutModal;
