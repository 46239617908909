import React from "react";
import { BsMessenger } from "react-icons/bs";
import ChatBoxModal from "../modals/user/ChatBoxModal";

var FloatButton = () => {
  return (
    <div className="position-relative">
      <div className="">
        <button
          className="btn-chat-float"
          data-bs-toggle="modal"
          data-bs-target="#ChatBoxModal"
        >
          <BsMessenger />
        </button>
      </div>
      <div
        className="modal fade"
        id="ChatBoxModal"
        aria-labelledby="ChatBoxModalLabel"
        aria-hidden="true"
      >
        <ChatBoxModal />
      </div>
    </div>
  );
};

export default FloatButton;
