import React, { useEffect } from "react";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import ShopListAdminTableSkeleton from "./ShopListAdminTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RiDeleteBin7Fill, RiEdit2Fill, RiCheckFill, RiCloseFill } from "react-icons/ri";
import {
	useGetCommerceByAdminQuery,
	useDeleteCommerceMutation,
	useValidCommerceMutation,
	useGetCommerceByAdminPaginateQuery,
	useEditCommerceMutation,
} from "../../../../../utils/api/commerce/commerce.api";
import { getImage } from "../../../../../utils/utils";
import Swal from "sweetalert2";
import moment from "moment";
import { array } from "yup";
import Pagination from "../../../../common/Pagination";
import { Color } from "../../../../../utils/theme";
import { MdDeleteForever } from "react-icons/md";
import { FiEye } from "react-icons/fi";
import { NavLink } from "react-router-dom";

function ShopListAdminTable({ word }) {
	const [pageActive, setPageActive] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);
	//   const [isLoading, setIsLoading] = React.useState(true)

	const {
		isSuccess,
		data: { results: commerces, count } = { results: [], count: 0 },
		isLoading,
	} = useGetCommerceByAdminPaginateQuery({
		word: word,
		page: pageActive,
		limit: perPage,
	});
	const [editCommerce] = useEditCommerceMutation();
	const [deleteCommerce] = useDeleteCommerceMutation();
	const [validCommerce] = useValidCommerceMutation();
	//const [item, findBySlug] = useCommerceFromLocation();
	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
	};

	const onArchive = async (item) => {
		let data = {
			slug: item?.slug,
			is_archived: !item?.is_archived,
			valid: item?.is_archived === true ? item?.valid : false,
		};
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir ${
				item?.is_archived === true ? "désarchiver" : "archiver"
			} cette boutique?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			preConfirm: () => {
				return editCommerce(data);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Boutique ${
						result?.value?.data?.is_archived ? "archivée" : "désarchivée"
					} avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const onValidCommerce = (slug) => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir activer cette boutique",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return validCommerce(slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
				Swal.fire({
					icon: "success",
					title: `boutique activée avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const onDisabledCommerce = (slug) => {
		let data = {
			slug: slug,
			valid: false,
		};
		Swal.fire({
			title: "Êtes vous sûr de vouloir désactiver cette boutique",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return editCommerce(data);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
				Swal.fire({
					icon: "success",
					title: `boutique désactivée avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	const nameFormatter = (cell: any, row: any) => {
		return (
			<div>
				<img
					src={getImage(row?.logo)}
					alt="Produit"
					className="img-produit-table-admin"
				/>
				<span className="ps-2">{cell}</span>
			</div>
		);
	};
	const authorFormatter = (cell: any, row: any) => {
		return (
			<div>
				<span className="ps-2">
					{" "}
					{row?.author?.firstname} {row?.author?.lastname}{" "}
				</span>
			</div>
		);
	};
	const stockDispoFormatter = (cell: any, row: any) => {
		return (
			<div>
				<span className="ps-2"> {row?.products.length} </span>
			</div>
		);
	};
	const dateCreationFormatter = (cell: moment.MomentInput, row: any) => {
		return <span className="date-formatter"> {moment(cell).format("DD-MM-YYYY")} </span>;
	};
	const actionFormatter: any = (cell: any, row) => {
		if (!row?.valid) {
			var line = (
				<button
					className="btn btn-action-user-admin"
					title="Activer la boutique"
					onClick={() => onValidCommerce(row?.slug)}
				>
					<RiCheckFill />
				</button>
			);
		} else {
			var line = (
				<button
					className="btn btn-action-user-admin"
					title="Desactiver la boutique"
					onClick={() => onDisabledCommerce(row?.slug)}
				>
					<RiCloseFill />
				</button>
			);
		}
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>{line}</div>
				<div>
					<NavLink
						className="btn btn-action-user-admin"
						title="Voir boutique"
						state={row}
						to={`/app/admin/boutiques/${row?.slug}`}
					>
						<FiEye />
					</NavLink>
				</div>
				<div>
					<button
						className="btn btn-action-user-admin"
						title={`${!row?.is_archived ? "Archiver" : "Désarchiver"}`}
						onClick={() => onArchive(row)}
					>
						{row?.is_archived ? <MdDeleteForever /> : <RiDeleteBin7Fill />}
					</button>
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "id",
			text: "N°",
			style: { textAlign: "left" },
			headerStyle: () => {
				return { width: "50px" };
			},
		},
		{
			dataField: "name",
			text: " Noms",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
			style: { textAlign: "left" },
		},
		{
			dataField: "author",
			text: "Proprietaire",
			formatter: (cell: any, row: any) => authorFormatter(cell, row),
		},
		{
			dataField: "created_at",
			text: "Date de création",
			formatter: (cell: any, row: any) => dateCreationFormatter(cell, row),
		},
		{
			dataField: "stock_disponible",
			text: "Stock disponnible",
			formatter: (cell: any, row: any) => stockDispoFormatter(cell, row),
		},
		{
			dataField: "nb_orders",
			text: "Nbre de commandes",
		},
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	const handleChangePage = ({ perPage, onPageChange }, newPage) => {
		setPageActive(newPage);
	};

	const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
		setPageActive(1);
		setPerPage(newSizePerPage);
	};

	const options = {
		custom: true,
		sizePerPage: perPage,
		totalSize: count,
	};

	useEffect(() => {
		setTimeout(() => {
			//   setIsLoading(false)
		}, 0);
	}, []);

	return (
		<>
			{isLoading && <ShopListAdminTableSkeleton />}
			{!isLoading && (
				<PaginationProvider pagination={paginationFactory(options)}>
					{({ paginationProps, paginationTableProps }) => (
						<div>
							<h3 className="total-items">{count} résultats</h3>
							<BootstrapTable
								keyField="id"
								data={commerces || []}
								columns={columns}
								striped
								noDataIndication="Aucune donnée disponible"
								bordered={false}
								condensed={false}
								responsive
								wrapperClasses="table-responsive admin-table"
								rowStyle={{
									fontFamily: "Montserrat",
									fontStyle: "normal",
									fontWeight: "600",
									fontSize: "14px",
									lineHeight: "21px",
									color: "rgba(0, 0, 0, 0.7)",
								}}
								headerClasses="header-class"
								{...paginationTableProps}
							/>
							<CustomPagination
								nbPages={count}
								page={pageActive}
								onChange={(page, perPage) => {
									handleSizePerPage(paginationProps, perPage);
									handleChangePage(paginationProps, page);
								}}
								perPage={perPage}
							/>
						</div>
					)}
				</PaginationProvider>
			)}
		</>
	);
}

export default ShopListAdminTable;

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				<select
					className="form-control select-perpage"
					name="limit"
					id="limit"
					value={perPage}
					onChange={(e) => {
						onChange(1, parseInt(e.target.value, 10));
					}}
				>
					<option value="10">10</option>
					<option value="30">30</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
