import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import {
  useAddOrUpdateSubCategoryMutation,
  useDeleteSubCategoryMutation,
} from "../../../../../utils/api/category/category.api";
import {
  Category,
  Subcategory,
  SubCategoryFormData,
} from "../../../../../utils/api/category/category.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, getImage } from "../../../../../utils/utils";

function useSubCategoryForm(
  category: Category,
  onHide: () => any,
  item?: Subcategory
) {
  const validationSchema = yup.object().shape({
    name: yup.string().required("Le nom est requis"),
    // position: yup.string().required("La position est requis"),
  });
  const [preview, setPreviw] = useState(
    item?.picture ? getImage(item.picture) : null
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<SubCategoryFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      category: category.id,
    },
  });

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setPreviw(URL.createObjectURL(file));
      register("picture");
      setValue("picture", file);
    }
  }, []);

  //  Calling the Register Mutation
  const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateSubCategoryMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: item
          ? "Sous-catégorie modifiée avec succès!"
          : "Sous-catégorie ajoutée avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!item) {
          reset();
          setPreviw(null);
        }
        onHide();
      });
    }
    const err = error as any;
    if (isError) {
      Swal.fire({
        icon: "error",
        title: err?.data?.message
          ? err?.data?.message
          : `Une erreur a survenue lors de ${
              item ? "la modification" : "l'ajout"
            }`,
        showConfirmButton: false,
        timer: 5000,
      });
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: SubCategoryFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      fd.append(key, data[key]);
    }
    addOrUpdate({ slug: item?.slug, data: fd });
  };

  useEffect(() => {
    if (item) {
      const fields: (keyof SubCategoryFormData)[] = ["name", "position"];
      for (let field of fields) {
        register(field);
        setValue(field, item[field]);
      }
    }
  }, [item]);
  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    preview,
    handelImageChange,
  };
}

export default useSubCategoryForm;

export function useDeleteSubCategory(item: Subcategory) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteSubCategoryMutation();

  const onDelete = useCallback(() => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette sous-catégorie",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn mx-2",
      },
      preConfirm: () => {
        return deleteItem(item.slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
      }
    });
  }, []);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "sous-catégorie supprimée avec succès",
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);

  return onDelete;
}
