import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { useLazyGlobalSearchQuery } from "../../../utils/api/user/user.api";
import { getImage } from "../../../utils/utils";
import "./SearchModal.css";
import { BigLoader } from "../../common/Loader";
type Props = {
	isOpen: boolean;
	closeModal: () => void;
};
export const items = [
	{
		label: "Annuaires",
		type: "annuaires",
	},
	{
		label: "Projets",
		type: "projets",
	},
	{
		label: "Annonces",
		type: "annonces",
	},
];
const SearchModal: React.FC<Props> = ({ isOpen, closeModal }) => {
	const [search, setSearch] = useState<string>("");
	const [active, setActive] = useState<string>();
	const [searchOnPlatform, { data, isLoading }] = useLazyGlobalSearchQuery();

	useEffect(() => {
		if (search && search?.length >= 2) {
			searchOnPlatform({ query: search, keyword: active });
		}
	}, [search, active]);

	return (
		<Modal isOpen={isOpen} size="lg">
			<div className="modal-content">
				<div className="modal-header align-items-center gap-3">
					<div className="container-input-search-modal flex-fill position-relative ">
						<input
							type="text"
							id="box"
							placeholder="Rechercher..."
							className="search__input"
							onChange={(e) => setSearch(e.target.value)}
						/>
						<i className="fas fa-search search__input__icon" id="icon"></i>
					</div>
					<button type="button" className="btn-close" onClick={closeModal}></button>
				</div>
				<div className="modal-body flex-column search-modal">
					{!isLoading && data ? (
						<div className="d-flex gap-3 mb-3">
							<button
								onClick={() => {
									setActive("");
								}}
								className={`filter-btn ${active === null ? "active-filter" : ""}`}
							>
								Tous
							</button>
							{items.map((item) => (
								<button
									key={item.type}
									className={`filter-btn ${
										active === item.type ? "active-filter" : ""
									}`}
									onClick={() => setActive(item?.type)}
								>
									{item.label}
								</button>
							))}
						</div>
					) : (
						<h5 className="annuaire-text fw-500">
							Entrer 2 caractères pour commencer votre recherche
						</h5>
					)}
					{isLoading && <BigLoader />}
					{!isLoading && data?.annuaires && data?.annuaires?.length > 0 && (
						<div className="bloc-item w-100 mb-2">
							{/* <h1 className="bloc-title">Annuaires</h1> */}
							<div className="results-containers ps-2">
								{data?.annuaires?.map((item) => (
									<Link
										to={`/annuaires/${item?.slug}`}
										state={item}
										key={item?.slug}
										onClick={closeModal}
									>
										<div className="annuaire-card d-flex shadow gap-4 mb-2">
											<div className="img-container shadow">
												<img
													src={getImage(item?.icon)}
													alt="card-img"
													height="90"
													className="w-100"
												/>
											</div>
											<div className="annuaire-card-infos pt-2">
												<h3 className="annuaire-name">Volkeno</h3>
												<h5 className="annuaire-text fw-500">
													Numérique
												</h5>
												<h5 className="annuaire-text">Dakar</h5>
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					)}
					{!isLoading && data?.annonces && data?.annonces?.length > 0 && (
						<div className="bloc-item w-100 mb-2">
							{/* <h1 className="bloc-title">Annonces</h1> */}
							<div className="results-containers ps-2">
								{data?.annonces?.map((item) => (
									<Link
										to={`/app/mon-espace-client/mes-annonces/${item?.slug}`}
										key={item?.slug}
										state={item}
										onClick={closeModal}
									>
										<div className="annuaire-card d-flex shadow gap-4 mb-2">
											<div className="img-container shadow">
												<img
													src={getImage(item?.images[0]?.picture)}
													alt="card-img"
													height="90"
													width="100"
												/>
											</div>
											<div className="annuaire-card-infos pt-2">
												<h3 className="annuaire-name">Volkeno</h3>
												<h5 className="annuaire-text fw-500">
													Numérique
												</h5>
												<h5 className="annuaire-text">Dakar</h5>
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					)}
					{!isLoading && data?.projets && data?.projets?.length > 0 && (
						<div className="bloc-item w-100 mb-2">
							{/* <h1 className="bloc-title">Projets</h1> */}
							<div className="results-containers ps-2">
								{data?.projets?.map((item) => (
									<Link
										to={`/app/mon-espace-client/mes-projets/${item?.slug}`}
										state={item}
										key={item?.slug}
										onClick={closeModal}
									>
										<div className="annuaire-card d-flex shadow gap-4 mb-2">
											<div className="img-container shadow">
												<img
													src={getImage(item?.images[0]?.picture)}
													alt="card-img"
													height="90"
													width="100"
												/>
											</div>
											<div className="annuaire-card-infos pt-2">
												<h3 className="annuaire-name">Volkeno</h3>
												<h5 className="annuaire-text fw-500">
													Numérique
												</h5>
												<h5 className="annuaire-text">Dakar</h5>
											</div>
										</div>
									</Link>
								))}
							</div>
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default SearchModal;
