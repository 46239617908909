import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import { useAppSelector } from "../../../../../redux/hooks";
import {
	useLazyFindAnnonceBySlugForVisitorQuery,
	useLazyFindAnnonceBySlugQuery,
} from "../../../../../utils/api/annonce/annonce.api";
import { IAnnonce } from "../../../../../utils/api/annonce/annonce.type";
import {
	useAddFavoriteAnnonceMutation,
	useDeleteFavoriteAnnonceMutation,
} from "../../../../../utils/api/favoriteAnnonce/favoriteAnnonce.api";
import { IFavorite } from "../../../../../utils/api/favoriteProject/favoriteProject.type";
import {
	useAddInterestAnnonceMutation,
	useDeleteInterestMutation,
} from "../../../../../utils/api/interet_annonce/interetAnnonce.api";
import { InterestAnnonce } from "../../../../../utils/api/interet_annonce/interetAnnonce.type";
import {
	useAddRateAnnonceMutation,
	useUpdateRateAnnonceMutation,
} from "../../../../../utils/api/rate_annonce/rateAnnonce.api";
import { IRate } from "../../../../../utils/api/rate_annonce/rateAnnonce.type";
import { Color } from "../../../../../utils/theme";

const useAnnonce = () => {
	const navigate = useNavigate();
	const { slug } = useParams();
	const [findAnonnceBySlugVisitor, { data: detailAnnonceData }] =
		useLazyFindAnnonceBySlugForVisitorQuery();
	const [annonce, SetAnnonce] = useState<IAnnonce>();
	const [rating, setRating] = useState<number>(0);
	const [isInterest, setIsInterest] = useState<InterestAnnonce | null>(null);
	const [isRating, setIsRating] = useState<IRate | null>(null);
	const [isFavorite, setIsFavorite] = useState<IFavorite | null>(null);
	const [addFavorite, { isSuccess }] = useAddFavoriteAnnonceMutation();
	const [deleteFavorite, { isSuccess: isSucdel }] = useDeleteFavoriteAnnonceMutation();
	const [findAnnonceBySlug, { data: dataAnnonce, isLoading }] = useLazyFindAnnonceBySlugQuery();
	const [addRateAnnonce, { isSuccess: isSucRate }] = useAddRateAnnonceMutation();
	const [addInterestAnnonce, { isSuccess: isSucInterest }] = useAddInterestAnnonceMutation();
	const [deleteInterest, { isSuccess: isSucdelInterest }] = useDeleteInterestMutation();

	const [updateRateAnnonce, { isSuccess: isSucRateupdate }] = useUpdateRateAnnonceMutation();
	const { user } = useAppSelector((state) => state?.user);
	const isMyAnnonce = user?.id === annonce?.user?.id;
	const { search } = useLocation();
	useEffect(() => {
		if (search?.includes("status=success")) {
			Swal.fire({
				icon: "success",
				title: "Votre produit  a été boosté avec succès",
				iconColor: Color.success,
				showConfirmButton: true,
			});
		} else if (search?.includes("status=error")) {
			Swal.fire({
				icon: "error",
				title: "Erreur de paiement",
				showConfirmButton: true,
				timer: 2500,
			});
		}
	}, [search]);
	useEffect(() => {
		if (slug) {
			findAnonnceBySlugVisitor(slug);
		}
	}, [annonce, slug]);
	useEffect(() => {
		if (dataAnnonce) {
			SetAnnonce(dataAnnonce);
		}
	}, [dataAnnonce]);
	useEffect(() => {
		if (detailAnnonceData) {
			SetAnnonce(detailAnnonceData);
		}
	}, [detailAnnonceData]);
	useEffect(() => {
		if (annonce?.rates) {
			const rate =
				annonce?.rates?.reduce((acc, item) => acc + item?.rating, 0) /
				annonce?.rates?.length;
			setRating(Math.round(rate));
		}
	}, [annonce?.rates]);
	useEffect(() => {
		if (annonce?.favorites) {
			const isFavorite = annonce?.favorites?.find((item) => item?.user === user?.id);
			setIsFavorite(isFavorite || null);
		}
	}, [annonce]);
	useEffect(() => {
		if (annonce?.rates) {
			const isRating = annonce?.rates?.find((item) => item?.user === user?.id);
			setIsRating(isRating || null);
		}
		if (annonce?.interets) {
			const interest = annonce?.interets?.find((item) => item?.user?.id === user?.id);
			setIsInterest(interest || null);
		}
	}, [annonce]);

	useEffect(() => {
		if (isSucdel) {
			findAnnonceBySlug(slug);
		}
	}, [isSucdel]);
	useEffect(() => {
		if (isSuccess) {
			findAnnonceBySlug(slug);
		}
	}, [isSuccess]);
	useEffect(() => {
		if (isSucRate) {
			findAnnonceBySlug(slug);
		}
	}, [isSucRate]);
	useEffect(() => {
		if (isSucRateupdate) {
			findAnnonceBySlug(slug);
		}
	}, [isSucRateupdate]);
	useEffect(() => {
		if (isSucInterest || isSucdelInterest) {
			findAnnonceBySlug(slug);
		}
	}, [isSucInterest, isSucdelInterest]);
	const toggleFavorite = async () => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		if (isMyAnnonce) {
			return null;
		}
		const data = {
			user: user?.id,
			announce: annonce?.id,
		};
		if (!isFavorite) {
			await addFavorite(data);
		} else {
			await deleteFavorite(isFavorite?.slug);
		}
	};
	const handleRateAnnonce = (index) => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		if (isMyAnnonce) {
			return null;
		}
		if (!isRating) {
			const data = {
				rating: index,
				user: user?.id,
				announce: annonce?.id,
			};
			addRateAnnonce(data);
		} else {
			updateRateAnnonce({ slug: isRating?.slug, rating: index });
		}
	};

	const handleInterestAnnonce = () => {
		if (!user?.id) {
			navigate("/app/se-connecter");
		}
		if (isMyAnnonce) {
			return null;
		}
		if (!isInterest) {
			const data = {
				user: user?.id,
				annonce: annonce?.id,
			};
			addInterestAnnonce(data);
		} else {
			deleteInterest(isInterest?.slug);
		}
	};
	return {
		slug,
		annonce,
		rating,
		handleRateAnnonce,
		toggleFavorite,
		isFavorite,
		isLoading,
		handleInterestAnnonce,
		isMyAnnonce,
		isInterest,
	};
};

export default useAnnonce;
