import { Tsector } from "../../../../utils/api/sector/sector.type";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import "./AddUserModal.scss";
import useSectorForm from "./forms/useSectorForm";
type AddCategoryModalProps = {
	modalId: string;
	item?: Tsector;
};
const AddSectorModal = ({ modalId, item }: AddCategoryModalProps) => {
	const onHide = () => {
		document.getElementById(modalId)?.classList.remove("show");
		$(".modal-backdrop").remove();
		$(`#${modalId}`).hide();
		$(`body`).css({ overflow: "scroll" });
	};
	const { register, onSubmit, errors, isLoading } = useSectorForm(onHide, item);
	return (
		<div
			className="modal fade"
			id={modalId}
			aria-labelledby={`${modalId}Label`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id={`${modalId}Title`}
						>
							{item ? "Modifier" : "Ajouter"} un secteur
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body">
						<form onSubmit={onSubmit} className="w-100">
							<div className="row auth-form-row">
								<div className="col-md-12 auth-input-col">
									<div className="form-group auth-form-group">
										<label
											htmlFor="nom"
											aria-labelledby="nom"
										>
											Nom du secteur d'activité
										</label>
										<span className="text-danger">*</span>
										<input
											type="text"
											className="form-control auth-form-control"
											id="nom"
											{...register("name")}
											defaultValue={item?.name}
										/>
										{errors.name?.message && (
											<ErrorMessage
												message={
													errors.name.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-md-6 offset-md-3 auth-submit-btn-container">
									{isLoading && (
										<button
											type="button"
											className="btn btn-primary auth-submit-btn"
										>
											Chargement...
										</button>
									)}
									{!isLoading && (
										<button
											type="submit"
											className="btn btn-primary auth-submit-btn"
										>
											{item ? "Modifier" : "Ajouter"}
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSectorModal;
