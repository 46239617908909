import React from "react";
import { FiAlertCircle } from "react-icons/fi";
type PropsType = {
	message: string;
};
export function AlertInfo({ message }: PropsType) {
	return (
		<div className="px-3">
			<div className="munganno-alert munganno-alert-info" role="alert">
				<FiAlertCircle style={{ fontSize: 24 }} />
				<h4>{message}</h4>
			</div>
		</div>
	);
}

export function AlertInfo2({ children }: { children: React.ReactNode }) {
	return (
		<div className="px-3">
			<div className="munganno-alert munganno-alert-info" role="alert">
				<FiAlertCircle style={{ fontSize: 24 }} />
				<h4>{children}</h4>
			</div>
		</div>
	);
}
