import { useState } from "react";
import { FiSearch } from "react-icons/fi";
import { IUser } from "../../../../redux/slice/User.slice";
import { useGetValidProForChatQuery } from "../../../../utils/api/network/network.api";
import { getFlagEmoji, getImage } from "../../../../utils/utils";
import "../../user/MyCustumerArea/MyCustumerArea.css";

const NewDiscussionModal = ({ onSelect }: { onSelect: (user: IUser) => any }) => {
	const [search, setSearch] = useState("");
	const { data: pros } = useGetValidProForChatQuery({
		code: search,
	});

	return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="px-4 pt-3">
					<div className="form-search-user-container position-relative">
						<input
							type="text"
							className="form-search-user form-control"
							value={search}
							onChange={(e) => setSearch(e.target.value)}
							placeholder="Entrer nom ou numero telephone client"
						/>
						<FiSearch
							style={{
								color: "#E6E6E6",
								fontSize: 22,
								position: "absolute",
								top: 15,
								right: 15,
							}}
						/>
					</div>
				</div>
				<hr className="mt-3 divide-maajjal" />
				<ul className="users-for-share-points-containers m-0  px-2">
					{pros?.map((pro) => (
						<li
							className="user-for-share-point d-flex gap-4 mb-2 px-3 py-1"
							key={pro?.identity_code}
							onClick={() => {
								onSelect(pro?.user);
								document
									.getElementById("closeModal")
									?.dispatchEvent(new Event("click"));
							}}
						>
							<div>
								<img
									src={getImage(pro?.user?.avatar)}
									alt="user-avatar"
									className="w-fluid img-profil-user-message"
								/>
							</div>
							<div className="user-for-share-point-infos">
								<h3>
									{pro?.user?.firstname} {pro?.user.lastname}
									{getFlagEmoji(pro?.country)}
								</h3>
								<h4>{pro?.fonction}</h4>
								<h4>{pro?.adress}</h4>
							</div>
						</li>
					))}
				</ul>

				<div className="modal-footer">
					<button
						type="button"
						id="closeModal"
						className="btn btn-cancel-share-point"
						data-bs-dismiss="modal"
					>
						Annuler
					</button>
				</div>
			</div>
		</div>
	);
};

export default NewDiscussionModal;
