import React from "react";
import "./Communautes.css";
import Img1 from "../../assets/landingpage/img1.png";
import Img2 from "../../assets/landingpage/img2.png";
import Img3 from "../../assets/landingpage/img3.png";
import Img4 from "../../assets/landingpage/img4.png";
import Logo from "../../assets/landingpage/logo.png";

const Communautes = () => {
	return (
		<div className="concept-component">
			<div className="container-section-landingpage">
				<div className="container-title-landingpage">
					<h2 className="title-section-landingpage position-relative text-center">
						Notre communauté
					</h2>
				</div>
				<div className="container-text-title-concept-landingpage w-50 m-auto py-4">
					<p className="text-concept-landingpage pt-4">La communauté, Munngano Connectt, c’est :</p>
				</div>
				<div className="container-section-communautes my-4 position-relative">
					<div className="wrapper">
						<img src={Img1} className="img-circle-communaute" alt="communaute" />
						<img src={Img2} className="img-circle-communaute" alt="communaute" />
						<img src={Img3} className="img-circle-communaute" alt="communaute" />
						<img src={Img4} className="img-circle-communaute" alt="communaute" />
					</div>
					<div className="container-img-logo-app-communaute">
						<img src={Logo} alt="Logo" className="communaute-img-logo-app" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Communautes;
