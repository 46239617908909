import React from 'react'
import { useState } from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { NavLink, useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import ProductListAdminTable from '../../Tables/Admin/ProductListAdminTable/ProductListAdminTable'
import './ProductListAdmin.css'

const ProductListAdmin = () => {
  const [search, setSearch] = useState('')
  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (!user?.products && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])

  return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container">
					<h1 className="admin-page-title">Produits</h1>
				</div>
				<div className="bloc-right-container gap-3">
					<div className="container-input-search-admin">
						<div className="search-box-admin">
							<input
								type="text"
								className="search-input-admin"
								value={search}
								onChange={(e) => setSearch(e.target.value)}
								placeholder="Search.."
							/>

							<button className="search-button-admin">
								<i className="fas fa-search"></i>
							</button>
						</div>
					</div>
					<div>
						<NavLink
							to="/app/admin/ajouter-produits"
							className="btn btn-theme p-2 btn-theme"
							data-testid="btnId"
						>
							<MdOutlineAddCircleOutline />
							<span className="ps-2">Ajouter un produit</span>
						</NavLink>
					</div>
				</div>
			</div>
			<div>
				<ProductListAdminTable word={search} />
			</div>
		</div>
  );
}

export default ProductListAdmin
