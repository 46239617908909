import { Partenaire } from "../../../../utils/api/partenaire/partenaire.type";
import { EditPartnerForm } from "../../admin/PartenaireAdmin/EditPartenaireAdmin";

const EditAnnuaire = ({ item }: { item?: Partenaire }) => {
	return (
		<div className="mes-annuaires-tabs-component">
			<div className="mb-4 bloc-title-page-tab">
				<div>
					<h5 className="titre-page-tab-content">Modifer l'annuaire</h5>
				</div>
			</div>
			<div className="form-container">
				<EditPartnerForm
					inputClassName="espace-client-form-control"
					itemState={item}
				/>
			</div>
		</div>
	);
};

export default EditAnnuaire;
