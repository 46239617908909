import React from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import AddSellerModal from '../../modals/adminModal/AddSellerModal'
import SellerListAdminTable from '../../Tables/Admin/SellerListAdminTable/SellerListAdminTable'
import './SellerListAdmin.css'

const SellerListAdmin = () => {
  const [word, setWord] = React.useState<string>('')
  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (!user?.vendeurs && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Vendeurs</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                className="search-input-admin"
                placeholder="Search.."
                value={word}
                onChange={(e) => setWord(e.target.value)}
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-theme p-2"
              data-bs-toggle="modal"
              data-bs-target="#AddSellerModal"
            >
              <MdOutlineAddCircleOutline />
              <span className="ps-2">Ajouter un vendeur</span>
            </button>
          </div>
        </div>
      </div>
      <div>
        <SellerListAdminTable word={word} />
      </div>
      <div
        className="modal fade"
        id="AddSellerModal"
        aria-labelledby="AddSellerModalLabel"
        aria-hidden="true"
      >
        <AddSellerModal />
      </div>
    </div>
  )
}

export default SellerListAdmin
