import React, { useEffect } from "react";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import "./CommandesPanier.css";
import Archive from "../../../assets/icons/archive.png";
import Panier from "../../../assets/icons/shopping-cart.png";
import CommandesTabs from "./CommandesTabs";
import PanierTabs from "./PanierTabs";
import FinaliserCommande from "./FinaliserCommande";
import { useStep } from "react-hooks-helper";
import { NavLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useStepType } from "../../../../utils/type";
import Swal from "sweetalert2";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { Color } from "../../../../utils/theme";
import { removeCart } from "../../../../redux/slice/cart.slice";
import { onlogout } from "../../../../redux/slice/User.slice";

const CommandesPanier = () => {
  const navigate = useNavigate();
  const params = useParams();
  const { idTabs } = params;

  const steps = [
    { id: "panier-step", Component: PanierTabs },
    { id: "finaliser-commande", Component: FinaliserCommande },
  ];
  const { step, navigation }: useStepType = useStep({
    initialStep: 0,
    steps,
  });
  const { Component } = step;
  const props = {
    navigation,
  };

  const dispatch = useAppDispatch();
  const { search } = useLocation();
  const { user, token } = useAppSelector((s) => s?.user);

  useEffect(() => {
    // console.log("params", search);
    if (search) {
      //   dispatch(fetchOrdersByUser(userID))
      if (search?.includes("success")) {
        // alert("cool");
        Swal.fire({
          icon: "success",
          title: "Paiement effectué avec succès!",
          text: token
            ? "Vous pouvez suivre l'évolution de votre commande."
            : "Veuillez-vous connecter pour suivre l'évolution de votre commande.",
          showConfirmButton: true,
          // timer: 3000,
          confirmButtonText: token
            ? "Voir les détails de votre commande"
            : "Se connecter",
          iconColor: Color.themeColor,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(removeCart());
            if (token) {
              await navigate(`/commandes/${"commandes"}`, { replace: true });
            } else {
              navigate(`/se-connecter`, { replace: true });
            }
          }
        });
      }

      if (search?.includes("error")) {
        // alert("cool");
        Swal.fire({
          icon: "error",
          title: "Paiement échoué!",
          text: "Veuillez réessayer de nouveau depuis les détails de la commande.",
          showConfirmButton: true,
          // timer: 3000,
          confirmButtonText: "Voir les détails de votre commande",
          // iconColor: "#B5853B",
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await dispatch(removeCart());
            await navigate(`/commandes/${"commandes"}`, { replace: false });
          }
        });
      }
    }
  }, [search, token]);

  return (
		<div className="my-customer-area-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<section className="content-page-container">
					<div className="mt-5">
						<div className="container-munngano">
							<div className="row">
								<div className="col-md-10">
									<div>
										<ul
											className="nav nav-tabs ul-boots-content"
											id="myTab"
											role="tablist"
										>
											<li
												className="nav-item nav-item-tabs-commandes-panier"
												role="presentation"
											>
												<button
													className={`nav-link nav-link-tabs-commandes-panier
                          ${idTabs === "commandes" ? "active" : ""}
                           `}
													id="commandes-tab"
													data-bs-toggle="tab"
													data-bs-target="#commandes"
													type="button"
													role="tab"
													aria-controls="commandes"
													aria-selected={
														idTabs === "commandes"
															? true
															: false
													}
													onClick={() => {
														navigate(
															"/app/commandes/commandes"
														);
													}}
												>
													<img
														src={Archive}
														alt="icon"
														className="icon-tabs-menu-commandes-panier"
													/>{" "}
													<span className="ps-2">Mes achats</span>
												</button>
											</li>
											<li
												className="nav-item nav-item-tabs-commandes-panier"
												role="presentation"
											>
												<button
													className={`nav-link nav-link-tabs-commandes-panier ${
														idTabs === "panier" ? " active" : ""
													}`}
													id="panier-tab"
													data-bs-toggle="tab"
													data-bs-target="#panier"
													type="button"
													role="tab"
													aria-controls="panier"
													aria-selected="false"
													onClick={() => {
														navigate("/app/commandes/panier");
													}}
												>
													<img
														src={Panier}
														alt="icon"
														className="icon-tabs-menu-commandes-panier"
													/>
													<span className="ps-2">Panier</span>
												</button>
											</li>
										</ul>
									</div>
								</div>
								<div className="col-md-2 d-flex flex-r-mobile justify-content-center itm-center p-t-14 p-b-14">
									<div>
										{token ? (
											<button
												className="logout-link-account btn"
												onClick={() => {
													dispatch(onlogout());
													window.location.pathname =
														"/app/se-connecter";
												}}
											>
												Déconnexion
											</button>
										) : (
											<NavLink
												to="/app/se-connecter"
												className="logout-link-account btn no-link"
											>
												Se connecter
											</NavLink>
										)}
									</div>
								</div>
							</div>
							<div className="tab-content mt-5" id="myTabContent">
								<div
									className={`tab-pane fade pt-5 ${
										idTabs === "commandes" ? " show active" : ""
									}`}
									id="commandes"
									role="tabpanel"
									aria-labelledby="commandes-tab"
								>
									<CommandesTabs />
								</div>
								<div
									className={`tab-pane fade pt-5  ${
										idTabs === "panier" ? " show active" : ""
									}`}
									id="panier"
									role="tabpanel"
									aria-labelledby="panier-tab"
								>
									<Component {...props} />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
  );
};

export default CommandesPanier;
