import React from 'react'
import { BiCategoryAlt } from 'react-icons/bi'
import { IoMdSettings } from 'react-icons/io'
import { IoBag } from 'react-icons/io5'
import { MdMessage } from 'react-icons/md'
import { RiBarChartBoxFill } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from '../../../../../redux/hooks'
import { useChatCount } from '../../../../../utils/api/chat/chat.api'
import { useGetOrderByCommerceQuery } from '../../../../../utils/api/order/order.api'
const SidebarTraderContent: React.FC = () => {
  const commerce = useAppSelector<any>((s) => s.commerce)
  const {
    isLoading,
    data: { results: orders, count: nbOrders } = { results: [], count: 0 },
  } = useGetOrderByCommerceQuery({
    page: 1,
    limit: 10,
    slug: commerce?.slug,
    status: status,
  })
  const count = useChatCount()
  return (
    <div className="hoverable">
      <ul className="nav nav-items-custom flex-column">
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/dashboard"
          >
            <RiBarChartBoxFill />
            <span className="hiddenable-navlink-label">Dashboard</span>
          </NavLink>
        </li>

        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/articles"
          >
            <BiCategoryAlt />
            <span className="hiddenable-navlink-label">Articles</span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom position-relative">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/commandes"
          >
            <IoBag />
            <span className="hiddenable-navlink-label">
              Commandes{' '}
              <span className="notif-nombre-commande">{nbOrders || 0}</span>
            </span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/boutiques"
          >
            <IoBag />
            <span className="hiddenable-navlink-label">Boutiques</span>
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/messages"
          >
            <MdMessage />
            <span className="hiddenable-navlink-label">Messages</span>
            {count > 0 && (
              <span>
                <span className="statut-count app">{count}</span>
              </span>
            )}
          </NavLink>
        </li>
        <li className="nav-item nav-item-vertical-custom">
          <NavLink
            className="nav-link nav-link-vertival-custom"
            to="/commercant/parametres"
          >
            <IoMdSettings />
            <span className="hiddenable-navlink-label">Paramètres</span>
          </NavLink>
        </li>
        {/* <li className="nav-item nav-item-vertical-custom">
					<NavLink
						className="nav-link nav-link-vertival-custom"
						to="/commercant/aide"
					>
						<MdPanTool />
						<span className="hiddenable-navlink-label">
                        Aide
						</span>
					</NavLink>
				</li> */}
      </ul>
    </div>
  )
}

export default SidebarTraderContent
