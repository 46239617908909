import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useGetAbonnementQuery } from "../../../../../utils/api/abonnement/abonnement.api";
import { useCreateProMutation } from "../../../../../utils/api/network/network.api";
import { TNetworkFormData } from "../../../../../utils/api/network/network.type";
import { APP_URL, CALLBACK_URL, INTECH_API_KEY } from "../../../../../utils/http";
import { Color } from "../../../../../utils/theme";
import { ServicePaymentCode } from "../../../../../utils/type";
import { createMarkup, onHide } from "../../../../../utils/utils";
import fmoney from "../../../../assets/appImages/fmoney.png";
import omoney from "../../../../assets/appImages/omoney.png";
import wmoney from "../../../../assets/appImages/wmoney.png";
// import cartegab from "../../../../assets/appImages/cartegab.png";
import { IAbonnnement } from "../../../../../utils/api/abonnement/abonnement.type";
import { BsFillInfoCircleFill } from "react-icons/bs";
import { useCheckTransactionStatus } from "../../../../../utils/intech";
import CloseModalBtn from "../../../../shared/CloseModalBtn";

const SouscriptionProviderModal = ({ pro }: { pro?: TNetworkFormData }) => {
	const { data: abonnements } = useGetAbonnementQuery();
	const [offer, setOffer] = useState<IAbonnnement>();
	const [checked, setChecked] = useState<boolean>();
	const [isSendRequest, setIsSendRequest] = useState<boolean>(false);
	const [err, setErr] = useState<boolean>(true);
	const [errModePaiement, setErrModePaiement] = useState<boolean>(false);
	const [externalTransactionId, setExternalTransactionId] = useState<number | string>();

	const [isLoadingPay, setIsLoading] = useState<boolean>(false);

	const [paymentMode, setPaymentMode] = useState<ServicePaymentCode>();

	const handleChecked = (item: IAbonnnement) => {
		if (item) {
			setOffer(item);
			setErr((err) => !err);
		}
	};
	const [sendData, { isLoading, isError, error, data }] = useCreateProMutation();

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	// useEffect(() => {
	// 	if (isSuccess && data) {
	// 		console.log("success", data);
	// 		window.location.href = data?.url;
	// 	}
	// }, [isSuccess]);

	const handleChangePayementMode = (value) => {
		setPaymentMode(value);
		setErrModePaiement(false);
	};

	const redirectUrl = `${APP_URL}/app/rejoindre-reseau?status=success`;

	const { onGetTransactionStatus, seconds, isFetching, isLoad } = useCheckTransactionStatus(
		redirectUrl,
		externalTransactionId
	);

	const onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (offer) {
			setChecked(true);
		}
		if (checked && !paymentMode) {
			return setErrModePaiement(true);
		}
		if (offer && paymentMode) {
			let fd = new FormData();
			fd.append("offer", String(offer?.id));
			fd.append("moyen_paiement", paymentMode);
			if (pro) {
				for (let key of Object.keys(pro)) {
					const val = pro[key];
					if (key === "images") {
						val.forEach((item) => {
							fd.append(key, item);
						});
					} else {
						fd.append(key, val);
					}
				}
			}
			const result = await sendData(fd);
			if ("data" in result) {
				if ("data" in result) {
					console.log({ data });
					setIsLoading(true);
					try {
						// console.log(result.data);
						let dataToSend = {
							phone: pro?.telephone,
							amount: offer?.price,
							codeService: paymentMode,
							externalTransactionId: result?.data?.subscription?.slug,
							callbackUrl: `${CALLBACK_URL}`,
							apiKey: INTECH_API_KEY,
							data: {
								env: "test",
							},
							sender: "Munngano",
							merchantName: "Munngano",
							operationDescription: "Paiement sur la plateforme de goana",
							successRedirectUrl: redirectUrl,
							errorRedirectUrl: `${APP_URL}/app/rejoindre-reseau/?status=error`,
							...(data ?? {}),
						};
						console.log({ dataToSend });
						const requestOptions: RequestInit = {
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Accept: "application/json",
							},
							body: JSON.stringify(dataToSend),
						};
						const res = await fetch(
							"https://api.intech.sn/api-services/operation",
							requestOptions
						).then((res) => {
							if (res.ok) {
								setIsLoading(false);
								return res.json();
							}
							throw {
								data: {
									message: `Une erreur est survenue code: ${res.status}`,
								},
							};
						});

						if (res?.error) {
							setIsLoading(false);
							// console.log("error");
							let message = "";
							if (res.data) {
								for (let [k, v] of Object.entries(res.data)) {
									if (Array.isArray(v)) {
										message = `${message}\n${k}: ${v.join(
											", "
										)}`;
									}
								}
							}
							if (message) {
								Swal.fire({
									icon: "error",
									title: message?.includes("error inconnu")
										? "Ce service n'est pas actuellement disponible"
										: message,
									showConfirmButton: false,
									timer: 5000,
								});
							} else if (res.msg) {
								Swal.fire({
									icon: "error",
									title: res.msg,
									showConfirmButton: false,
									timer: 5000,
								}).then(() => onHide("SouscriptionProviderModal"));
							} else {
								Swal.fire({
									icon: "error",
									title: res?.msg
										? res?.msg
										: `Erreur de statut code ${res?.code} est survenue!`,
									showConfirmButton: false,
									timer: 5000,
								});
							}
							return false;
						}
						if (!res?.error) {
							console.log({ res });
							const { deepLinkUrl } = res.data;
							const msg =
								"L'opération s'est effectuée avec succès, Vous allez recevoir un message de confirmation pour finaliser le paiement";
							if (deepLinkUrl) {
								Swal.fire({
									icon: "success",
									iconColor: Color.success,
									title: msg,
									showConfirmButton: false,
									timer: 4000,
								}).then(() => {
									window.location.href = deepLinkUrl;
								});
							} else {
								Swal.fire({
									icon: "success",
									title: msg,
									showConfirmButton: true,
									timer: 10000,
									confirmButtonColor: Color.theme,
								}).then(() => {
									setExternalTransactionId(
										res?.data?.externalTransactionId
									);
									setIsSendRequest(true);
								});
							}

							return true;
						}
					} catch (error: any) {
						const err = error?.data;
						console.log("error", err);
						return false;
					}
				}
			}
		}
	};
	return (
		<div
			className="modal fade"
			id="SouscriptionProviderModal"
			aria-labelledby="SouscriptionProviderModal"
			aria-hidden="true"
		>
			<div className="modal-dialog  modal-dialog-centered modal-lg">
				<div className="modal-content modal-shadow">
					{isSendRequest ? (
						<>
							<div className="modal-header">
								<h5 className="modal-title">
									Vérifier le statut de paiement
								</h5>
								<CloseModalBtn
									onClick={() =>
										onHide("SouscriptionProviderModal")
									}
								/>
							</div>
							<CheckPaiementStatus
								isLoading={isLoadingPay}
								seconds={seconds}
								onGetTransactionStatus={onGetTransactionStatus}
							/>
						</>
					) : (
						<>
							<div className="modal-header">
								<h5 className="modal-title">
									{checked
										? "Choisir un mode de paiement"
										: "Souscrire à un abonnement"}
								</h5>
								<CloseModalBtn
									onClick={() =>
										onHide("SouscriptionProviderModal")
									}
								/>
							</div>
							<div className="px-2 py-4">
								<form onSubmit={(e) => onSubmit(e)}>
									{checked ? (
										<div className="mode-paiement mt-5">
											<div className="mt-4 px-5">
												<div className="carte-mode-paiement-container">
													<div className="custom-carte-paiement-check-item mb-2">
														<label
															className="form-check-label"
															htmlFor="freemoney"
														>
															<img
																src={
																	fmoney
																}
																className="card-img-financer-campagne"
																style={{
																	marginTop: 0,
																}}
																alt="img campagne"
															/>
														</label>
														<input
															className="form-check-input m-0"
															type="radio"
															id="freemoney"
															value={
																ServicePaymentCode.FREE
															}
															name="moyen_paiment"
															data-testid="freemoney"
															onChange={() =>
																handleChangePayementMode(
																	ServicePaymentCode.FREE
																)
															}
														/>
													</div>
													<div className="custom-carte-paiement-check-item mb-2">
														<label
															className="form-check-label"
															htmlFor="wavemoney"
														>
															<img
																src={
																	wmoney
																}
																className="card-img-financer-campagne"
																style={{
																	marginTop: 0,
																}}
																alt="img campagne"
															/>
														</label>
														<input
															className="form-check-input m-0"
															type="radio"
															id="wavemoney"
															value={
																ServicePaymentCode.WAVE
															}
															name="moyen_paiment"
															onChange={() =>
																handleChangePayementMode(
																	ServicePaymentCode.WAVE
																)
															}
														/>
													</div>
													<div className="custom-carte-paiement-check-item mb-2">
														<label
															className="form-check-label"
															htmlFor="orangemoney"
														>
															<img
																src={
																	omoney
																}
																className="card-img-financer-campagne"
																style={{
																	marginTop: 0,
																}}
																alt="img campagne"
															/>
														</label>
														<input
															className="form-check-input m-0"
															type="radio"
															id="orangemoney"
															name="moyen_paiment"
															value={
																ServicePaymentCode.OM
															}
															onChange={() =>
																handleChangePayementMode(
																	ServicePaymentCode.OM
																)
															}
														/>
													</div>
												</div>
												<div className="text-center">
													{errModePaiement && (
														<small className="text-danger">
															Veuillez
															choisir un
															mode de
															paiement
														</small>
													)}
												</div>
											</div>
										</div>
									) : (
										<>
											<div className="row px-2 text-center">
												{abonnements?.map(
													(item) => (
														<div
															className="col-md-6 px-2"
															key={
																item?.slug
															}
														>
															<label
																key={
																	item?.id
																}
																className={`offer-bloc  ${
																	offer?.id ===
																		item?.id &&
																	"check"
																}  shadow py-2 px-3 mb-3`}
															>
																<h3 className="offer-title">
																	{
																		item?.title
																	}
																</h3>
																<h6 className="offer-price">
																	{
																		item.price
																	}
																	€/mois
																</h6>
																<div
																	className="offer-desc"
																	dangerouslySetInnerHTML={createMarkup(
																		item?.description
																	)}
																/>
																<input
																	type="radio"
																	className="form-check-input"
																	value={
																		item?.id
																	}
																	// {...register("offer")}
																	onChange={() =>
																		handleChecked(
																			item
																		)
																	}
																	hidden={
																		true
																	}
																/>
															</label>
														</div>
													)
												)}
											</div>
										</>
									)}
									<div className="col-md-6 col-xl-4 offset-md-6 offset-xl-8 px-2 pt-5">
										{isLoading || isLoadingPay ? (
											<button
												disabled={true}
												className="btn btn-primary auth-submit-btn"
											>
												<span
													className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
													role="status"
												></span>
												<span>Chargement...</span>
											</button>
										) : (
											<button
												type="submit"
												className="btn btn-primary auth-submit-btn"
												disabled={err}
											>
												{checked
													? "Envoyer"
													: "Valider"}
											</button>
										)}
									</div>
								</form>
							</div>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

type Props = { isLoading: boolean; seconds: number; onGetTransactionStatus: () => void };

export function CheckPaiementStatus({ isLoading, seconds, onGetTransactionStatus }: Props) {
	return (
		<div className="modal-investissement-description-content2 pb-5">
			<div className="modal-payment-body">
				<div className="my-5 flex-col itm-center">
					<BsFillInfoCircleFill
						className=" mb-4"
						style={{ fontSize: 40, color: Color.theme }}
					/>
					<p
						className="title-payment-modal mb-4 text-center"
						style={{ color: "#000" }}
					>
						Rassurer vous d'avoir confirmé la transaction depuis votre
						téléphone avant de vérifier le paiement
					</p>
				</div>
			</div>
			<div className="d-flex justify-content-center col-lg-6 offset-lg-3 px-2">
				<button
					type="button"
					className="btn auth-submit-btn"
					onClick={() => onGetTransactionStatus()}
					disabled={isLoading || (seconds > 0 && seconds < 20)}
				>
					{isLoading ? (
						<>
							<span
								className="spinner-border spinner-border-sm custom-spinner text-primary me-1 d-inline-block"
								role="status"
							></span>
							<span>Vérification...</span>
						</>
					) : (
						"Vérifier le paiement"
					)}
				</button>
				{seconds <= 0 || seconds === 20 ? null : (
					<div className="col-12">
						<small className="text-danger mt-1 py-2">
							Veuillez revérifier dans {seconds} secondes
						</small>
					</div>
				)}
			</div>
		</div>
	);
}
export default SouscriptionProviderModal;
