import React from 'react'
import './HeaderTrader.scss'
import Logo from '../../../../assets/appImages/logo.png'
import ShopIconHeader from '../../../../assets/icons/Home.png'
import { AiOutlineEye } from 'react-icons/ai'
import StarRatingComponent from 'react-star-rating-component'
import { FiSearch } from 'react-icons/fi'
import { useAppSelector } from '../../../../../redux/hooks'
import { useDispatch } from 'react-redux'
import { onlogout } from '../../../../../redux/slice/User.slice'
import { NavLink } from 'react-router-dom'
import { useGetCommerceByUserQuery } from '../../../../../utils/api/commerce/commerce.api'
import { Commerce } from '../../../../../utils/api/commerce/commerce.type'
import { addCommerce } from '../../../../../redux/slice/shop.slice'

const HeaderTrader: React.FC = () => {
  const commerce = useAppSelector<any>((state) => state?.commerce)
  const { data } = useGetCommerceByUserQuery()
  const dispatch = useDispatch()
  const handleSelectCommerce = (e) => {
    if (e.target.value) {
      let selected: any = data?.find(
        (item: Commerce) => item?.id === parseInt(e.target.value),
      )
      dispatch(addCommerce(selected))
    }
  }
  return (
		<div>
			<nav className="navbar navbar-expand-lg navbar-light empty-fluidy alling-header navbar navbar-dark fixed-top">
				<div className="headering-admin">
					<div className="navbar-brand">
						<div className="logo-item">
							<NavLink to={"/"} className="no-link logo-container dashboard-header-logo">
								<img src={Logo} className="logo-img" alt="ConnecTerre" />
							</NavLink>
						</div>
					</div>
					<button
						className="navbar-toggler"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#navbarSupportedContent"
						aria-controls="navbarSupportedContent"
						aria-expanded="false"
						aria-label="Toggle navigation"
					>
						<span className="navbar-toggler-icon"></span>
					</button>
					<div className="collapse navbar-collapse" id="navbarSupportedContent">
						<ul className="navbar-nav navbar-header-trader mb-2 mb-lg-0">
							<div className="search-form-container">
								<div className="search-form-content">
									<label>
										<FiSearch />
									</label>
									<input name="search" className="form-control search-input" />
								</div>
							</div>
							<div className="d-flex">
								<div className="itm-center mb-lg-0 mb-3">
									<img
										className="shop-icon-header-dashboard"
										src={ShopIconHeader}
										alt="Icon"
									/>
									<select
										className="shop-name-header-dashboard ms-2 border-none"
										onChange={(e) => handleSelectCommerce(e)}
										value={commerce?.id || ""}
									>
										<option value="" hidden>
											commerce
										</option>
										{data?.map((option: Commerce, index) => (
											<option key={option?.id} value={option?.id}>
												{option?.name}
											</option>
										))}
									</select>
								</div>
								<div className="link-rate-header-dashboard ml-lg-5 mb-lg-0 mb-3 ms-3">
									<StarRatingComponent
										name="rate2"
										editing={true}
										starCount={5}
										value={parseInt(commerce?.note_star)}
										starColor="#FFA73C"
										emptyStarColor="#f6973f83"
									/>
									<span className="rate-number-header-dashboard px-2">
										{parseInt(commerce?.note_star)}/5
									</span>
									<AiOutlineEye className="icon-header-dashboard" />
								</div>
							</div>
						</ul>
						<div className="pl-lg-0 pl-3 mb-lg-0 mb-3">
							<button
								className="btn logout-link-account"
								onClick={() => {
									dispatch(onlogout());
									window.location.pathname = "/app/se-connecter";
								}}
							>
								Déconnexion
							</button>
						</div>
					</div>
				</div>
			</nav>
			{/* <nav className="empty-fluidy alling-header navbar navbar-expand-md navbar-dark fixed-top">
      <div className="headering-admin">
        <div className="logo-item">
          <div className="logo-container dashboard-header-logo">
            <img src={Logo} className="logo-img" alt="ConnecTerre" />
          </div>
        </div>

        <div className="header-others-items">
          <div className="search-form-container">
            <div className="search-form-content">
              <label>
                <FiSearch />
              </label>
              <input name="search" className="form-control search-input" />
            </div>
          </div>
          <div
            className="notification-item-container linkable"
            onClick={() => onShowAndHideNotification()}
          >
            <div className="notification-item-content">
              <MdOutlineNotifications />
              <span className="notification-counter">1</span>
            </div>
          </div>
          <div className="connected-user-container">
            <div className="connected-user-content ml-auto no-view-mobile">
              <div className="dropdown dis-flex">
                <button
                  className="user-acount-dashboard-header"
                  type="button"
                  id="dropdownMenuButton"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="user-acount-dashboard-header-display">
                    <img
                      src={Profil}
                      width="45"
                      className="user-avatar"
                      alt="User Avatar"
                    />
                    <FiChevronDown />
                  </div>
                </button>
                <div
                  className="dropdown-menu dropdown-menu-custom"
                  aria-labelledby="dropdownMenuButton"
                >
                  <NavLink
                    to="/app/admin/parametre"
                    className="dropdown-item item-dropdown-custom"
                  >
                    User
                  </NavLink>
                  <div className="dropdown-divider"></div>
                  <button
                    type="button"
                    className="dropdown-item item-dropdown-custom"
                  >
                    {" "}
                    Deconnexion
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="hiddenable-nav-mobile-container no-view-desktop">
        <div className="hiddenable-nav-mobile-content">
        </div>
      </div>

      {showNotifications && (
        <div className={"header-hiddenable-notification-section"}>
          <div className="header-hiddenable-notification-title">
            Notifications
          </div>

          <ul className="header-hiddenable-notification-ul">
            <li className="header-hiddenable-notification-li">
              <NavLink to="#" className="notification-item-link">
                <p>Notif</p>
                <span>12/12/2021</span>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </nav> */}
		</div>
  );
}

export default HeaderTrader
