import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError } from "../../../../../utils/utils";
import { SectorFormData, Tsector } from "../../../../../utils/api/sector/sector.type";
import {
	useAddOrUpdateSectorMutation,
	useDeleteSectorMutation,
} from "../../../../../utils/api/sector/sector.api";

function useSectorForm(onHide: () => any, item?: Tsector) {
	const validationSchema = yup.object().shape({
		name: yup.string().required("Le nom est requis"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<SectorFormData>({
		resolver: yupResolver(validationSchema),
	});

	//  Calling the Register Mutation
	const [addOrUpdate, { isLoading, isSuccess, error, isError }] =
		useAddOrUpdateSectorMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item
					? "Secteur modifié avec succès!"
					: "Secteur ajouté avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				if (!item) {
					reset();
				}
				onHide();
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${
							item ? "la modification" : "l'ajout"
					  }`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: SectorFormData) => {
		console.log(data);
		addOrUpdate({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useSectorForm;

export function useDeleteSector(item: Tsector) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteSectorMutation();

	const onDelete = useCallback(() => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer ce secteur",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Secteur supprimé avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
