import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import { FormError } from "./CustomInput";

type InputPropsType = InputHTMLAttributes<HTMLSelectElement> & {
	label: string;
	error?: string | FieldError;
	options: {
		label: string;
		value: string | number;
	}[];
	required?: boolean | string;
	value?: any;
};
// export const CustomSelectInput = React.forwardRef<HTMLSelectElement, InputPropsType>(
// 	(props, ref) => {
// 		const { name, error, label, required, options, value, id, ...rest } = props;
// 		return (
// 			<div className="col-md-6 form-group mb-4">
// 				<label
// 					htmlFor="nombre_stock"
// 					className="custom-form-label"
// 					aria-labelledby={id}
// 				>
// 					{label} {required && <span className="text-danger">*</span>}
// 				</label>
// 				<select
// 					name={id}
// 					id={id}
// 					{...rest}
// 					ref={ref}
// 					defaultValue={value}
// 					className="form-select espace-client-form-select-control"
// 				>
// 					<option value=""> {label} </option>
// 					{options.map((option) => (
// 						<option value={option.value} key={option.value}>
// 							{option.label}
// 						</option>
// 					))}
// 				</select>

// 				{<FormError error={error} />}
// 			</div>
// 		);
// 	}
// );

export const CustomSelectInput = React.forwardRef<
  HTMLSelectElement,
  InputPropsType
>((props, ref) => {
  const { name, error, label, options, ...rest } = props;
  return (
    <div className="col-md-6 form-group mb-4">
      <label
        htmlFor="nombre_stock"
        className="custom-form-label"
        aria-labelledby={name}
      >
        {label}
      </label>
      <select
        name={name}
        id={name}
        {...rest}
        ref={ref}
        className="form-select espace-client-form-select-control"
      >
        <option value=""> {label} </option>
        {options.map((option) => (
          <option value={option.value} key={option.value}>
            {option.label}
          </option>
        ))}
      </select>

      {<FormError error={error} />}
    </div>
  );
});