import React, { useEffect } from 'react'
import { MdDeleteForever } from 'react-icons/md'
import paginationFactory, {
  PaginationProvider,
} from 'react-bootstrap-table2-paginator'
import BootstrapTable from 'react-bootstrap-table-next'
import {
  RiCheckFill,
  RiCloseFill,
  RiDeleteBin7Fill,
  RiEdit2Fill,
} from 'react-icons/ri'
import Swal from 'sweetalert2'
import {
  useDeletePaymentModeMutation,
  useEditPaymentModeMutation,
  useGetPaymentModeQuery,
} from '../../../../utils/api/paymentMode/paymentMode.api'
import { Color } from '../../../../utils/theme'
import { getAvatar } from '../../../../utils/utils'
import PaymentModeSkeleton from '../../../skeletons/PaymentModeSkeleton'
import Pagination from '../../../common/Pagination'
import EditPaymentMode from '../../modals/adminModal/EditPaymentMode'

function PaymentMode() {
  const [pageActive, setPageActive] = React.useState(1)
  const [perPage, setPerPage] = React.useState(10)
  const {
    isLoading,
    data: { results: paymentModes, count } = { results: [], count: 0 },
  } = useGetPaymentModeQuery({
    word: '',
    page: pageActive,
    limit: perPage,
  })

  const [
    editData,
    { isSuccess, isError, error, data },
  ] = useEditPaymentModeMutation()

  const [deleteData] = useDeletePaymentModeMutation()

  useEffect(() => {
    if (paymentModes) {
      console.log('payment', paymentModes)
    }
  }, [paymentModes])

  const onDelete = async (slug) => {
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir supprimer ce moyen de paiement?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI',
      cancelButtonText: 'NON',
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return deleteData(slug)
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, 'archiver')
      if (result?.value?.data) {
        Swal.fire({
          icon: 'success',
          title: `Moyen de paiement supprimé avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  const onActive = async (item) => {
    let data = {
      active: !item?.active,
      slug: item?.slug,
    }
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.active === true ? 'désactiver' : 'activer'
      } ce moyen de paiement?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI',
      cancelButtonText: 'NON',
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return editData(data)
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, 'archiver')
      if (result?.value?.data) {
        Swal.fire({
          icon: 'success',
          title: `Moyen de paiement ${
            result?.value?.data?.active ? 'activé' : 'désactivé'
          } avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <div>
          <button
            className="btn btn-action-user-admin"
            title={row?.active ? 'Désactiver' : 'Activer'}
            onClick={() => onActive(row)}
          >
            {row?.active ? <RiCloseFill /> : <RiCheckFill />}
          </button>
        </div>
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Modifier"
            data-bs-toggle="modal"
            data-bs-target={`#EditPaymentMode${row?.slug}`}
          >
            <RiEdit2Fill />
          </button>
        </div>
        <div>
          <button
            className="btn btn-action-user-admin"
            title="Supprimer"
            onClick={() => onDelete(row?.slug)}
          >
            <RiDeleteBin7Fill />
          </button>
        </div>
        <div
          className="modal fade"
          id={`EditPaymentMode${row?.slug}`}
          aria-labelledby="EditPaymentModeLabel"
          aria-hidden="true"
          data-bs-backdrop="static"
        >
          <EditPaymentMode paymentMode={row} />
        </div>
      </div>
    )
  }
  const columns = [
    {
      dataField: 'title',
      text: ' Nom',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'name',
      text: 'Type',
      style: { textAlign: 'left' },
    },

    {
      dataField: 'actions',
      text: 'actions',
      style: { textAlign: 'center' },
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
      headerStyle: () => {
        return { width: '150px' }
      },
    },
  ]

  const handleChangePage = ({ perPage, onPageChange }, newPage) => {
    setPageActive(newPage)
  }

  const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
    setPageActive(1)
    setPerPage(newSizePerPage)
  }

  const options = {
    custom: true,
    sizePerPage: perPage,
    totalSize: count,
  }
  return (
    <div className="tab-content-admin" id="paymentMode-component">
      {isLoading && <PaymentModeSkeleton />}
      {!isLoading && (
        <PaginationProvider pagination={paginationFactory(options)}>
          {({ paginationProps, paginationTableProps }) => (
            <div>
              <BootstrapTable
                keyField="id"
                data={paymentModes || []}
                columns={columns}
                striped
                noDataIndication="Aucune donnée disponible"
                bordered={false}
                condensed={false}
                responsive
                wrapperClasses="table-responsive admin-table"
                rowStyle={{
                  fontFamily: 'Poppins',
                  fontStyle: 'normal',
                  fontWeight: '600',
                  fontSize: '14px',
                  lineHeight: '21px',
                  color: 'rgba(0, 0, 0, 0.7)',
                }}
                headerClasses="header-class"
                {...paginationTableProps}
              />
              <CustomPagination
                nbPages={count}
                page={pageActive}
                onChange={(page, perPage) => {
                  handleSizePerPage(paginationProps, perPage)
                  handleChangePage(paginationProps, page)
                }}
                perPage={perPage}
              />
            </div>
          )}
        </PaginationProvider>
      )}
    </div>
  )
}

export default PaymentMode

type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
