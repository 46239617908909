import React, { useEffect, useState } from "react";
import { Outlet, useOutletContext } from "react-router-dom";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import { useLazyGetSectorQuery } from "../../../../utils/api/sector/sector.api";
import { Tsector } from "../../../../utils/api/sector/sector.type";
import "volkeno-react-country-state-city/dist/index.css";

const Annonce = () => {
	const [sectors, setSectors] = useState<Tsector[]>();
	const [getSector, { data }] = useLazyGetSectorQuery();
	useEffect(() => {
		if (!sectors?.length) {
			getSector();
		}
	}, [sectors]);
	useEffect(() => {
		if (data) {
			setSectors(data);
		}
	}, [data]);

	return (
		<div className="project-listing-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<Outlet context={sectors} />
			</div>
			<FrontFooter />
		</div>
	);
};
export function useSector() {
	return useOutletContext<Tsector[]>();
}

export default Annonce;
