import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError | any;
	required?: boolean | string;
	col?: string;
};
export const CustomInput = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, label, id, required, col, ...rest } = props;
	return (
		<>
			<div className={`${col ? col : "col-md-6"} form-group mb-2`}>
				<label htmlFor={id} className="custom-form-label " aria-labelledby={id}>
					{label}
					{required ? <span className="text-danger">*</span> : null}
				</label>
				<input
					className="form-control espace-client-form-control"
					name={name}
					id={id}
					{...rest}
					ref={ref}
				/>
				{<FormError error={error} />}
			</div>
		</>
	);
});

export const CustomInputField = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
	const { name, error, label, ...rest } = props;
	return (
		<>
			<div className="col-md-6 form-group mb-4">
				<label htmlFor={name} className="custom-form-label" aria-labelledby={name}>
					{label}
				</label>
				<input
					className="form-control espace-client-form-control"
					type="file"
					name={name}
					id={name}
					{...rest}
					ref={ref}
					multiple={false}
				/>
				{<FormError error={error} />}
			</div>
		</>
	);
});

export function FormError({ error }: { error?: string | FieldError | any }) {
	const message = typeof error === "string" ? error : error ? error.message : error;
	if (!message) return null;

	return <ErrorMessage message={message} />;
}
