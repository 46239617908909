import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ApiBaseUrl } from "../../http";
import { QueryUrl, useLocationState } from "../../utils";
import { PaginationResults, TypeQuery } from "../commerce/commerce.type";
import { Partenaire, PartenaireFormData, PartnerVisitorQuery } from "../partenaire/partenaire.type";
import { prepareHeaders } from "../user/user.api";

const tags = {
	partenaire: "partenaire",
	partenaireBySlug: "partenaireBySlug",
	partenerForVisitor: "partenerForVisitor",
	partnerbyuser: "partnerbyuser",
};
export const PartenaireApi = createApi({
	reducerPath: "partenaireAPi",
	tagTypes: Object.values(tags),
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getPartenaire: builder.query<
			PaginationResults<Partenaire>,
			{ page: number; word: string }
		>({
			query: (query) => QueryUrl("partnerbyfilteradmin/", query),
			providesTags: [tags.partenaire],
		}),
		getPartenaireByUser: builder.query<PaginationResults<Partenaire>, string>({
			query: (slug) => `partnerbyuser/${slug}/`,
			providesTags: [tags.partnerbyuser],
		}),
		addPartenaire: builder.mutation<Partenaire, PartenaireFormData | any>({
			query: (data) => ({
				url: "/partner/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [tags.partenaire],
		}),
		PartenaireBySlug: builder.query<Partenaire, string>({
			query: (slug) => `partner/${slug}/`,
			providesTags: [tags.partenaireBySlug],
		}),
		editPartenaire: builder.mutation<Partenaire, PartenaireFormData | any>({
			query: ({ slug, formData }) => ({
				url: `/partner/${slug}/`,
				body: formData,
				method: "PUT",
			}),
			invalidatesTags: [tags.partenaire, tags.partnerbyuser],
		}),
		deletePartenaire: builder.mutation<Partenaire, string>({
			query: (slug) => ({
				url: `partner/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.partenaire, tags.partnerbyuser],
		}),
		getPartnerForVisitor: builder.query<Partenaire[], PartnerVisitorQuery>({
			query: (query) => QueryUrl("partnerbyfilter/", query),
			providesTags: [tags.partenerForVisitor],
			transformResponse: ({ results }) => results,
		}),
		getVillePartner: builder.query<{ ville: Partenaire["ville"] }[], void>({
			query: () => "partner_ville/",
			providesTags: [tags.partenerForVisitor],
			// transformResponse: ({ results }) => results,
		}),
		getPaysPartner: builder.query<{ pays: Partenaire["pays"] }[], void>({
			query: () => "partner_pays/",
			providesTags: [tags.partenerForVisitor],
			// transformResponse: ({ results }) => results,
		}),
		getSectorPartner: builder.query<{ entreprise_type: Partenaire["pays"] }[], void>({
			query: () => "partner_secteur/",
			providesTags: [tags.partenerForVisitor],
			// transformResponse: ({ results }) => results,
		}),
		validPartenaire: builder.mutation<Partenaire, string>({
			query: (slug) => ({
				url: `partner/${slug}/validation`,
				method: "GET",
			}),
			invalidatesTags: [tags.partenaire, tags.partnerbyuser],
		}),
	}),
});

export const {
	useGetPartenaireQuery,
	useAddPartenaireMutation,
	useEditPartenaireMutation,
	useLazyPartenaireBySlugQuery,
	useDeletePartenaireMutation,
	useGetPartnerForVisitorQuery,
	useGetVillePartnerQuery,
	useValidPartenaireMutation,
	useGetPartenaireByUserQuery,
	useGetPaysPartnerQuery,
	useGetSectorPartnerQuery,
} = PartenaireApi;

export function usePartenaireFromLocation(): [Partenaire, boolean, string, (slug: string) => any] {
	const itemState = useLocationState<Partenaire | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyPartenaireBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as Partenaire, isLoading, slug as string, findBySlug];
}
