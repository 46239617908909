import "./FrontFooterLandingpage.css";
import ImgFoter from "../../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import Facebook from "../../../assets/icons/facebook.png";
import Instagram from "../../../assets/icons/instagram.png";
import { FaLinkedinIn } from "react-icons/fa";
import VersionChecker from "../../../../versionChecker/VersionChecker";
import "../../../shared/FrontFooter/FrontFooter.css"
import { useGetSocialMediaVisitorQuery } from "../../../../utils/api/about/about.api";

const FrontFooterLandingpage = () => {
	const { data } = useGetSocialMediaVisitorQuery();
	return (
    <footer className="component-front-footer b-t-1">
      <div className="container-munngano-footer">
        <div className="body-footer">
          <div className="row">
            <div className="col-xl-4 col-lg-4 mb-5 col-md-6 footer-grid">
              <div className="front-footer-text-container-landingpage">
                <img src={ImgFoter} alt="image logo footer" />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 mb-5 col-md-6 footer-grid">
              {/* <p className="link-footer trans-0-2 d-block mb-2 mt-3">
								121 king street, Melbourne 3000
							</p>
							<p className="link-footer trans-0-2 d-block mb-2">+61 3 8376 6284</p> */}
              <p className="link-footer trans-0-2 d-block mb-2">
                contact@munnganoconnectt.com
              </p>
              <a
                className="link-footer trans-0-2 d-block mb-3 no-link"
                href="https://www.munnganoconnectt.com"
              >
                www.munnganoconnectt.com
              </a>
              <div className="d-flex mb-4">
                <a
                  className="mx-2 trans-0-2"
                  href={data?.linkedin || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaLinkedinIn
                    className="icon-social-footer"
                    color="#000"
                    size={18}
                  />
                </a>
                <a
                  className="mx-2 trans-0-2"
                  href={data?.instagram || "#"}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Instagram}
                    alt="Instagram"
                    className="icon-social-footer"
                  />
                </a>
                {/* <a
                  className="me-2 trans-0-2"
                  href={data?.facebook}
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src={Facebook}
                    alt="Facebook"
                    className="icon-social-footer"
                  />
                </a> */}
              </div>
              {/* </div> */}
            </div>
            <div className="col-lg-3 offset-lg-1 mb-5 col-md-6 footer-grid">
              <NavLink
                className="link-footer-navigation trans-0-2 d-block mb-3"
                to={"/"}
              >
                Nos Tarifs
              </NavLink>
              <a
                className="link-footer-navigation trans-0-2 d-block mb-3"
                href={"/app/a-propos"}
              >
                À Propos de nous
              </a>
              <a
                className="link-footer-navigation trans-0-2 d-block mb-3"
                href="/app/nos-services"
              >
                Nos Services
              </a>
              <a
                className="link-footer-navigation trans-0-2 d-block mb-3"
                href="/app/contactez-nous"
              >
                Contactez-nous
              </a>
              <a
                className="link-footer-navigation trans-0-2 d-block mb-3"
                href="/app/condition-generale"
              >
                Conditions générales d’utilisations
              </a>
              <a
                className="link-footer-navigation trans-0-2 d-block mb-3"
                href="/app/politique-de-confidentialite"
              >
                Politiques de confidentialités
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="row mx-0 end-footer py-3 d-flex justify-content-center align-items-center position-relative">
        <div className="mt-lg-0 mt-5 d-flex justify-content-center">
          <p className="copyright">Munngano © – All rights reserved</p>
        </div>
        <div className="version-view-container">{"v" + VersionChecker()}</div>
      </div>
    </footer>
  );
};

export default FrontFooterLandingpage;
