import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import { IProject } from "../../../../utils/api/project/project.type";
import { useLocationState } from "../../../../utils/utils";
import useEditProjet from "./UseFormMyCustomArea/useEditProjet";
import { ApiBaseUrl } from "../../../../utils/http";
import { Tsector } from "../../../../utils/api/sector/sector.type";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import moment from "moment";
import CustomDatePicker from "../../../shared/DatePicker/CustomDatePicker";
import { IBudget } from "../../../../utils/api/budget/budget.type";
import { AppStorage } from "../../../../utils/storage";
import { TagsInput } from "react-tag-input-component";

const ModifierProjetEspaceClient = () => {
	const itemState = useLocationState<any>(undefined);

	const [project, setProject] = useState<IProject>(itemState?.project);

	const { register, onSubmit, errors, setValue, isLoading, selected, handleChangeTags } =
		useEditProjet(project);
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [expirationDate, setExpirationtDate] = useState<any>();
	const budgets = AppStorage.getItem<IBudget[]>("budgets");
	const sectors = AppStorage.getItem<Tsector[]>("sectors");

	useEffect(() => {
		if (project) {
			setProject(project);
			setValue("slug", project.slug);
			setValue("description", project.description);
			setValue("skills", project.skills);
			setValue("requirements", project.requirements);
			setValue("prerequisite", project.prerequisite);
			if (project?.date_expiration) {
				setValue("date_expiration", project?.date_expiration);
				setExpirationtDate(new Date(project?.date_expiration));
			}
			setState(project?.region);
			setCity(project?.ville);
		}
	}, [project]);
	const handleChangeImage = (e) => {
		setValue("images", e.target.files[0]);
	};
	const handleChangeDate = (date) => {
		setExpirationtDate(date);
		setValue("date_expiration", moment(date).format("YYYY-MM-DD"));
	};
	const handleCountrySelect = (option: any) => {
		setCountry(option);
	};

	const handleStateSelect = (option: any) => {
		if (option.label) {
			setState(option);
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		if (option.label) {
			setCity(option);
			setValue("ville", option.label);
		}
	};
	return (
		<>
			<div className="mb-4 ps-3">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<NavLink
								to="/mes-projects"
								className="breadcrumb-item-costum"
							>
								Mes appels d'offres{" "}
							</NavLink>
						</li>
						<li
							className="breadcrumb-item active breadcrumb-item-costum"
							aria-current="page"
						>
							Modifier un projet
						</li>
					</ol>
				</nav>
			</div>
			<div className="page-add-project-container">
				<div className="form-container px-3">
					<form onSubmit={onSubmit}>
						<div className="row mb-4">
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="title-project"
									className="espace-client-form-label"
								>
									Titre projet
								</label>
								<input
									type="text"
									className="form-control espace-client-form-control"
									placeholder="Titre project"
									id="title-project"
									multiple={false}
									{...register("title")}
									defaultValue={project?.title}
								/>
								{errors.title?.message && (
									<ErrorMessage
										message={errors.title.message}
									/>
								)}
							</div>
							<div className="col-md-12 mb-4">
								<label
									htmlFor="image-project"
									className="espace-client-form-label"
								>
									Image du projet
								</label>
								<input
									type="file"
									className="form-control espace-client-form-control"
									id="image-project"
									accept="image/*"
									onChange={handleChangeImage}
								/>
								{errors.images?.message && (
									<ErrorMessage
										message={errors.images.message}
									/>
								)}
								<div className="img-container pt-2">
									<img
										src={
											ApiBaseUrl +
											project?.images[0]?.picture
										}
										alt={project.slug}
										loading="lazy"
										className="img-thumbnail h-200 object-cover"
									/>
								</div>
							</div>
							<div className="col-md-12 form-group mb-4">
								<label
									htmlFor="secteur-activite"
									className="espace-client-form-label"
									aria-labelledby="secteur-activite"
								>
									Secteurs d’activité et Métiers
								</label>
								<select
									className="select-form-custom form-select espace-client-form-control"
									id="secteur-activite"
									data-testid="secteurActiviteId"
									{...register("sector")}
									defaultValue={project?.sector?.id}
								>
									{sectors?.map((sector) => (
										<option
											key={sector.slug}
											value={sector.id}
										>
											{sector.name}
										</option>
									))}
								</select>
								{errors.sector?.message && (
									<ErrorMessage
										message={errors.sector.message}
									/>
								)}
							</div>
							<div className="col-md-12 mb-4">
								<label
									htmlFor="image-project"
									className="espace-client-form-label"
								>
									Tags
								</label>
								<TagsInput
									value={selected}
									onChange={handleChangeTags}
									name="tags"
									placeHolder="Entrer les tags"
								/>
								<em>Appuyer sur Entrer pour ajouter un tag</em>
							</div>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="description"
									className="espace-client-form-label"
								>
									Description du projet
								</label>
								<CKEditor
									initData={project?.description}
									onChange={(e) =>
										setValue(
											"description",
											e.editor.getData()
										)
									}
								/>
								{errors.description?.message && (
									<ErrorMessage
										message={errors.description.message}
									/>
								)}
							</div>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="prerequisite"
									className="espace-client-form-label"
								>
									Prérequis du projet
								</label>
								<CKEditor
									initData={project?.prerequisite}
									onChange={(e) =>
										setValue(
											"prerequisite",
											e.editor.getData()
										)
									}
								/>
								{errors.prerequisite?.message && (
									<ErrorMessage
										message={errors.prerequisite.message}
									/>
								)}
							</div>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="requirements"
									className="espace-client-form-label"
								>
									Exigences du projet
								</label>
								<CKEditor
									initData={project?.requirements}
									onChange={(e) =>
										setValue(
											"requirements",
											e.editor.getData()
										)
									}
								/>
								{errors.requirements?.message && (
									<ErrorMessage
										message={errors.requirements.message}
									/>
								)}
							</div>
							<div className="col-md-12 form-group mb-4">
								<label
									htmlFor="skills"
									className="espace-client-form-label"
								>
									Compétences requises
								</label>
								<CKEditor
									initData={project?.skills}
									onChange={(e) =>
										setValue("skills", e.editor.getData())
									}
								/>
								{errors.skills?.message && (
									<ErrorMessage
										message={errors.skills.message}
									/>
								)}
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="type-contrat"
									className="espace-client-form-label"
									aria-labelledby="type-contrat"
								>
									Type de contrat
								</label>
								<select
									className="select-form-custom form-select espace-client-form-control"
									aria-label="Default select example"
									id="type-contrat"
									data-testid="typeContratId"
									{...register("type_contract")}
									defaultValue={project?.type_contract}
								>
									<option value="stage">Stage</option>
									<option value="cdd">CDD</option>
									<option value="cdi">CDI</option>
									<option value="prestation">Prestation</option>
								</select>
								{errors.type_contract?.message && (
									<ErrorMessage
										message={errors.type_contract.message}
									/>
								)}
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="budget"
									className="espace-client-form-label"
									aria-labelledby="budget"
								>
									Budget de l’emploi
								</label>
								<select
									className="select-form-custom form-select espace-client-form-control"
									id="budget"
									data-testid="budgetEmploiId"
									{...register("budget")}
									defaultValue={project?.budget?.id}
								>
									{budgets?.map((budget) => (
										<option
											value={budget.id}
											key={budget.slug}
										>
											{budget.name}
										</option>
									))}
								</select>
								{errors.budget?.message && (
									<ErrorMessage
										message={errors.budget.message}
									/>
								)}
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="date_expiration"
									className="espace-client-form-label"
									aria-labelledby="date_expiration"
								>
									Date d’expiration de l’offre d’emploi
								</label>
								<CustomDatePicker
									date={expirationDate}
									setDate={handleChangeDate}
									id="date_expiration"
									data-testid="dateExpirationId"
								/>
								{errors.date_expiration?.message && (
									<ErrorMessage
										message={errors.date_expiration.message}
									/>
								)}
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="experiences"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Pays
								</label>
								<CountrySelector
									onChange={handleCountrySelect}
									name="country"
									placeholder="Pays"
									value={country}
									containerClass="tx country-select-container p-0"
								/>
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="experiences"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Région
								</label>
								<StateSelector
									country={country}
									name="state"
									value={state}
									countryPlaceholder="Region"
									onChange={handleStateSelect}
									containerClass="tx country-select-container p-0"
								/>
								{errors.region?.message && (
									<ErrorMessage
										message={errors.region.message}
									/>
								)}
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="experiences"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Ville
								</label>
								<CitySelector
									state={state}
									name="city"
									value={city}
									statePlaceholder="Ville"
									onChange={handleCitySelect}
									containerClass="tx country-select-container p-0"
								/>
								{errors.ville?.message && (
									<ErrorMessage
										message={errors.ville.message}
									/>
								)}
							</div>
							<div className="offset-md-6 col-md-6">
								<BtnSubmit label="Modifier" isLoading={isLoading} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default ModifierProjetEspaceClient;
