import React, { useState, useEffect } from 'react'
import { RiDeleteBinLine } from 'react-icons/ri'
import { useAppDispatch } from '../../../../redux/hooks'
import { deleteCartItem, toggleItem } from '../../../../redux/slice/cart.slice'
import EmptyCartImage from '../../../assets/appImages/empty_cart.svg'
import { getImage } from '../../../../utils/utils'
import { NavLink } from 'react-router-dom'

function CartItem({ items }) {
  const dispatch = useAppDispatch()

  const toggleCountItem = (e, id, toggle) => {
    e.preventDefault()
    dispatch(toggleItem({ id: id, toggle: toggle }))
  }

  const removeItem = (e, id) => {
    e.preventDefault()
    dispatch(deleteCartItem(id))
  }
  return (
    <>
      {items?.length ? (
        items?.map((shop) => (
          <div key={shop?.id} className="mb-3">
            <p className="m-b-12 text-start text-up-title fs-13 fw-500">
              Commerce: {shop?.commerce?.name}
            </p>
            {shop?.items?.map((item) => (
              <div className="row m-b-20" key={item?.item?.id}>
                <div className="col-xl-6 col-lg-5 col-md-12">
                  <div className="row">
                    <div className="col-sm-4 m-b-20">
                      <img
                        className="img-thumbs-commande-item"
                        src={getImage(item?.item?.background_image)}
                        alt=""
                      />
                    </div>
                    <div className="col-sm-8 m-b-20">
                      <p className="text-commande-item m-b-10 fw-300 fs-14">
                        {item?.item?.name}
                      </p>
                      <p className="text-commande-item m-b-30 fw-300 fs-12">
                        {item?.item?.marque || 'Sans marque'}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-12 col-sm-12 m-b-20">
                  <div>
                    <p className="fw-600 text-up text-up-title">Quantité</p>
                    <div className="btn-inc-dec-container">
                      <button
                        className="btn-inc-dec"
                        disabled={item?.count === 1}
                        onClick={(e) =>
                          toggleCountItem(e, item?.item?.id, 'dec')
                        }
                      >
                        -
                      </button>
                      <span className="number-item mx-3">{item?.count}</span>
                      <button
                        className="btn-inc-dec"
                        disabled={item?.count >= item?.item?.quantity}
                        onClick={(e) =>
                          toggleCountItem(e, item?.item?.id, 'inc')
                        }
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4 m-b-20 px-2">
                  <div>
                    <p className="fw-600 text-up text-up-title pb-1">
                      Prix UNITAIRE
                    </p>
                    <p className="text-dark fs-14 ff-open fw-400 text-center p-2">
                      {item?.price} FCFA
                    </p>
                  </div>
                </div>
                <div className="col-xl-2 col-lg-2 col-md-12 col-sm-12">
                  <div>
                    <p className="fw-600 text-up text-up-title">Action</p>
                    <div className="d-flex justify-content-center p-2">
                      {/* <button className="btn-icon-action-commande">
                                <AiOutlineHeart className="icon-commande-favoris" />
                              </button> */}
                      <button
                        className="btn-icon-action-commande"
                        onClick={(e) => removeItem(e, item?.item?.id)}
                      >
                        <RiDeleteBinLine className="icon-commande-delete" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))
      ) : (
        <section className="cart-content checkout">
          <img className="image-cart-empty" src={EmptyCartImage} alt="" />
          <header>
            <h2 className="h-theme">Votre panier est vide!</h2>
            <h4 className="">
              Veuillez ajouter des produits pour pouvoir acheter.
            </h4>
          </header>
          <div className="dis-flex justify-content-center mt-5">
            <div className="col-md-5 col-sm-7">
              <NavLink
                className="btn-theme dis-flex justify-content-center no-link"
                to="/marketplace"
              >
                Commencer mes achats
              </NavLink>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default CartItem
