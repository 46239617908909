import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAddContactMutation } from "../../../utils/api/contact/contact.api";
import { TContactForm } from "../../../utils/api/contact/contact.type";
import { Color } from "../../../utils/theme";
import { cleannerError } from "../../../utils/utils";

const useContactForm = (setMessage: any) => {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Ce champ"),
		email: yup.string().email("Email invalide").required().label("Email"),
		subject: yup.string().required().label("Sujet"),
		contact_type: yup.string().required().label("Catégorie"),
		message: yup.string().required().label("Message"),
	});

	const [addContact, { isLoading, isError, isSuccess, error }] = useAddContactMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				reset();
				setMessage("");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de l'envoie",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: TContactForm) => {
		// console.log(data);
		addContact(data);
	};
	return {
		register,
		onsubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
	};
};

export default useContactForm;
