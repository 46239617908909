import "../ProjectListing/ProjectListing.css";
import { createMarkup, getImage, useLocationState } from "../../../../utils/utils";
import moment from "moment";
import { Link } from "react-router-dom";

const DetailAnnonceTabs = () => {
	const annonce = useLocationState<any>(undefined);

	return (
		<div>
			<div className="flex-sb">
				<div>
					<h4 className="titre-annonce-projet">{annonce?.title}</h4>
				</div>
			</div>
			<div className="row mt-4 b-b-1">
				<div className="col-md-12">
					<div className="flex-sb align-items-center pb-2">
						<div className="d-flex">
							<div>
								<img
									src={getImage(annonce?.images[0]?.picture)}
									alt="Annonce"
									className="listing-project-detail-img"
								/>
							</div>
							<div className="ps-2">
								<p className="texte-detail-annonce-projet">
									{annonce?.user?.firstname}
								</p>
								<p className="texte-detail-annonce-projet">
									{annonce?.country} {annonce?.region}
								</p>
							</div>
						</div>
						<div>
							<p className="texte-detail-annonce-projet">
								{moment(annonce?.created_at).fromNow()}
							</p>
						</div>
						<div className="boost-link-container">
							<Link
								to={`/app/mon-espace-client/mes-annonces/booster/${annonce?.slug}`}
								className="article-det-btn btn-theme-orange"
								state={annonce}
							>
								Booster
							</Link>
						</div>
					</div>
				</div>
			</div>

			<div
				className="mb-2 pt-4 text-description-annonce-detail"
				dangerouslySetInnerHTML={createMarkup(annonce?.description)}
			/>
			<div className="">
				<div className="row pb-2">
					<div className="col-md-4 pb-2">
						<p className="texte-detail-annonce-info-value">Type de l'annonce:</p>
					</div>
					<div className="col-md-8 pb-2">
						<p className="texte-detail-annonce-info-libelle">
							{annonce?.type_announcement === "recherche_profil"
								? "Recherche Profil"
								: annonce?.type_announcement === "recherche_partenaire"
								? "Recherche Partenaire"
								: "Recherche Client"}
						</p>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailAnnonceTabs;
