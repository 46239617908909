import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import { AbonnementFormData, IAbonnnement, IOffer, OfferFormData } from "./abonnement.type";

export const AbonnementApi = createApi({
	tagTypes: ["abonnement", "offreBoost"],
	reducerPath: "abonnementApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getAbonnement: builder.query<IAbonnnement[], void>({
			query: () => "offer/",
			providesTags: ["abonnement"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateAbonnement: builder.mutation<
			IAbonnnement,
			{ slug?: string; data: AbonnementFormData }
		>({
			invalidatesTags: ["abonnement"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `offer/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `offer/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteAbonnement: builder.mutation<IAbonnnement, string>({
			query: (slug) => ({
				url: `offer/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["abonnement"],
		}),
		getOffers: builder.query<IOffer[], void>({
			query: () => "offreboost/",
			providesTags: ["offreBoost"],
			transformResponse: ({ results }) => results,
		}),
		addOrUpdateOffer: builder.mutation<
			IAbonnnement,
			{ slug?: string; data: OfferFormData }
		>({
			invalidatesTags: ["offreBoost"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `offreboost/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `offreboost/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteOffer: builder.mutation<IOffer, string>({
			query: (slug) => ({
				url: `offreboost/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["offreBoost"],
		}),
	}),
});

export const {
	useGetAbonnementQuery,
	useLazyGetAbonnementQuery,
	useAddOrUpdateAbonnementMutation,
	useDeleteAbonnementMutation,
	useGetOffersQuery,
	useAddOrUpdateOfferMutation,
	useDeleteOfferMutation,
} = AbonnementApi;
