import { useState } from "react";
import { NavLink } from "react-router-dom";
import { CKEditor } from "ckeditor4-react";
import { BtnSubmit } from "../../../common/Button";
import useAjouterProjet from "./UseFormMyCustomArea/useAjouterProjet";
import ErrorMessage from "../../../common/ErrorMessage";
import { createUrl } from "../../../../utils/utils";
import CustomDatePicker from "../../../shared/DatePicker/CustomDatePicker";
import moment from "moment";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { IBudget } from "../../../../utils/api/budget/budget.type";
import { Tsector } from "../../../../utils/api/sector/sector.type";
import { AppStorage } from "../../../../utils/storage";
import { useCheckIfCurrentUserIsPro } from "../ReseauPro/RejoindreReseau";
import { AlertInfo } from "../../../common/Alert";
import { TagsInput } from "react-tag-input-component";

const AjouterProjetEspaceClient = () => {
	const { register, onSubmit, errors, setValue, isLoading, selected, handleChangeTags } =
		useAjouterProjet();
	const { isCandidateToBePro, isLoad } = useCheckIfCurrentUserIsPro();
	const [image, setImage] = useState<File>();
	const [expirationDate, setExpirationtDate] = useState<any>();
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const budgets = AppStorage.getItem<IBudget[]>("budgets");
	const sectors = AppStorage.getItem<Tsector[]>("sectors");

	const handleChangeImage = (e) => {
		console.log(e.target.files[0]);
		setValue("images", e.target.files[0]);
		setImage(e.target.files[0]);
	};
	const handleChangeDate = (date) => {
		setExpirationtDate(date);
		setValue("date_expiration", moment(date).format("YYYY-MM-DD"));
	};
	const handleCountrySelect = (option: any) => {
		setCountry(option);
	};

	const handleStateSelect = (option: any) => {
		if (option.label) {
			setState(option);
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		if (option.label) {
			setCity(option);
			setValue("ville", option.label);
		}
	};
	return (
		<>
			<div className="mb-4 ps-3">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<NavLink
								to="/app/mon-espace-client/mes-projets"
								className="breadcrumb-item-costum"
							>
								Mes appels d'offres{" "}
							</NavLink>
						</li>
						<li className="breadcrumb-item active breadcrumb-item-costum" aria-current="page">
							Ajouter un appel d’offre
						</li>
					</ol>
				</nav>
			</div>
			{!isLoad && !isCandidateToBePro && (
				<div className="py-5">
					<AlertInfo message="Pour pouvoir ajouter un appel d’offre, vous devez rejoindre le reseau" />
				</div>
			)}
			{!isLoad && isCandidateToBePro && !isCandidateToBePro.valid && (
				<div className="py-5">
					<AlertInfo message="Votre requête n'a pas été encore validée, s'il vous-plait revérifier plus tard!" />
				</div>
			)}
			{!isLoad && isCandidateToBePro && isCandidateToBePro?.valid && (
				<div className="page-add-project-container">
					<div className="form-container px-3">
						<form onSubmit={onSubmit}>
							<div className="row mb-4">
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="title-projet"
										className="espace-client-form-label"
									>
										Titre projet
									</label>
									<input
										type="text"
										className="form-control espace-client-form-control"
										placeholder="Titre projet"
										id="title-projet"
										multiple={false}
										{...register("title")}
										data-testid="titleProjetId"
									/>
									{errors.title?.message && (
										<ErrorMessage message={errors.title.message} />
									)}
								</div>
								<div className="col-md-12 mb-4">
									<label
										htmlFor="image-project"
										className="espace-client-form-label"
									>
										Image du projet
									</label>
									<input
										type="file"
										className="form-control espace-client-form-control"
										id="image-project"
										accept="image/*"
										onChange={handleChangeImage}
									/>
									{image && (
										<div className="col-12">
											<img
												src={createUrl(image)}
												alt={image.name}
												loading="lazy"
												className="img-thumbnail h-200"
											/>
										</div>
									)}

									{errors.images?.message && (
										<ErrorMessage message={errors.images.message} />
									)}
								</div>
								<div className="col-md-12 mb-4">
									<label
										htmlFor="image-project"
										className="espace-client-form-label"
									>
										Tags
									</label>
									<TagsInput
										value={selected}
										onChange={handleChangeTags}
										name="tags"
										placeHolder="Entrer les tags"
									/>
									<em>Appuyer sur Entrer pour ajouter un tag</em>
									{errors.tags?.message && (
										<ErrorMessage message={errors.tags.message} />
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="secteur-activite"
										className="espace-client-form-label"
										aria-labelledby="secteur-activite"
									>
										Secteurs d’activité et Métiers
									</label>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="secteur-activite"
										data-testid="secteurActiviteId"
										{...register("sector")}
									>
										{sectors?.map((sector) => (
											<option key={sector.slug} value={sector.id}>
												{sector.name}
											</option>
										))}
									</select>
									{errors.sector?.message && (
										<ErrorMessage message={errors.sector.message} />
									)}
								</div>
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="description"
										className="espace-client-form-label"
										aria-labelledby="description"
									>
										Description du projet
									</label>
									<CKEditor
										initData={""}
										onChange={(e) =>
											setValue("description", e.editor.getData())
										}
									/>
									{errors.description?.message && (
										<ErrorMessage message={errors.description.message} />
									)}
								</div>
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="prerequisite"
										className="espace-client-form-label"
									>
										Prérequis du projet
									</label>
									<CKEditor
										initData={""}
										onChange={(e) =>
											setValue("prerequisite", e.editor.getData())
										}
									/>
									{errors.prerequisite?.message && (
										<ErrorMessage message={errors.prerequisite.message} />
									)}
								</div>
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="requirements"
										className="espace-client-form-label"
									>
										Exigences du projet
									</label>
									<CKEditor
										initData={""}
										onChange={(e) =>
											setValue("requirements", e.editor.getData())
										}
									/>
									{errors.requirements?.message && (
										<ErrorMessage message={errors.requirements.message} />
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label htmlFor="skills" className="espace-client-form-label">
										Compétences requises
									</label>
									<CKEditor
										initData={""}
										onChange={(e) => setValue("skills", e.editor.getData())}
									/>
									{errors.skills?.message && (
										<ErrorMessage message={errors.skills.message} />
									)}
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="type-contrat"
										className="espace-client-form-label"
										aria-labelledby="type-contrat"
									>
										Type de contrat
									</label>
									<select
										className="select-form-custom form-select espace-client-form-control"
										aria-label="Default select example"
										id="type-contrat"
										data-testid="typeContratId"
										{...register("type_contract")}
									>
										<option value="stage">Stage</option>
										<option value="cdd">CDD</option>
										<option value="cdi">CDI</option>
										<option value="prestation">Prestation</option>
									</select>
									{errors.type_contract?.message && (
										<ErrorMessage message={errors.type_contract.message} />
									)}
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="budget"
										className="espace-client-form-label"
										aria-labelledby="budget"
									>
										Budget de l’emploi
									</label>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="budget"
										data-testid="budgetEmploiId"
										{...register("budget")}
									>
										{budgets?.map((budget) => (
											<option value={budget.id} key={budget.slug}>
												{budget.name}
											</option>
										))}
									</select>
									{errors.budget?.message && (
										<ErrorMessage message={errors.budget.message} />
									)}
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="date_expiration"
										className="espace-client-form-label"
										aria-labelledby="date_expiration"
									>
										Date d’expiration de l’offre d’emploi
									</label>
									<CustomDatePicker
										date={expirationDate}
										setDate={handleChangeDate}
										id="date_expiration"
										data-testid="dateExpirationId"
									/>
									{errors.date_expiration?.message && (
										<ErrorMessage message={errors.date_expiration.message} />
									)}
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="experiences"
										className="espace-client-form-label"
										aria-labelledby="experiences"
									>
										Pays
									</label>
									<CountrySelector
										onChange={handleCountrySelect}
										name="country"
										placeholder="Pays"
										value={country}
										containerClass="tx country-select-container p-0"
									/>
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="experiences"
										className="espace-client-form-label"
										aria-labelledby="experiences"
									>
										Région
									</label>
									<StateSelector
										country={country}
										name="state"
										value={state}
										countryPlaceholder="Region"
										onChange={handleStateSelect}
										containerClass="tx country-select-container p-0"
									/>
									{errors.region?.message && (
										<ErrorMessage message={errors.region.message} />
									)}
								</div>
								<div className="col-md-6 form-group mb-4">
									<label
										htmlFor="experiences"
										className="espace-client-form-label"
										aria-labelledby="experiences"
									>
										Ville
									</label>
									<CitySelector
										state={state}
										name="city"
										value={city}
										statePlaceholder="Ville"
										onChange={handleCitySelect}
										containerClass="tx country-select-container p-0"
									/>
									{errors.ville?.message && (
										<ErrorMessage message={errors.ville.message} />
									)}
								</div>
								<div className="offset-md-6 col-md-6 pt-4">
									<BtnSubmit label="Ajouter" isLoading={isLoading} />
								</div>
							</div>
						</form>
					</div>
				</div>
			)}
		</>
	);
};

export default AjouterProjetEspaceClient;
