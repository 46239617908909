import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { SellerFormData } from '../../../../../utils/api/seller/seller.type'
import { cleannerError } from '../../../../../utils/utils'
import { useAppSelector } from '../../../../../redux/hooks'
import { useAddSellerMutation } from '../../../../../utils/api/seller/seller.api'
import { Color } from '../../../../../utils/theme'

function useAddSeller() {
  //  Calling the add Seller user Mutation
  const [
    addSeller,
    { isLoading, isSuccess, error, isError, reset },
  ] = useAddSellerMutation()
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.user)
  const validationSchema = yup.object().shape({
    firstname: yup.string().required().label('Prenom'),
    lastname: yup.string().required().label('Nom'),
    phone: yup.string().required().label('Téléphone'),
    email: yup.string().required().label('Email'),
  })

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<SellerFormData>({
    resolver: yupResolver(validationSchema),
  })
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: 'success',
        title: 'Vendeur ajouté avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 1500,
      }).then(() => {
        reset()
        document.getElementById('AddSellerModal')?.classList.remove('show')
        $('.modal-backdrop').remove()
        $(`#AddSellerModal`).hide()
        $(`#AddSellerModal`).click()
      })
    }
    if (isError) {
      const err = error as any
      const { message = "Une erreur inconnue s'est produite" } = err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 5000,
      })
    }
  }, [isLoading])

  const onSubmit = (data) => {
    console.log(data)
    const formData = new FormData()
    if (user?.id) {
      formData.append('user', JSON.stringify(user.id))
    }
    for (let key of Object.keys(data)) {
      const val = data[key]
      formData.append(key, val)
    }
    addSeller(formData)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading,
  }
}

export default useAddSeller
