import { AiOutlineLogout } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { onlogout } from "../../../redux/slice/User.slice";
import { useChatCount } from "../../../utils/api/chat/chat.api";
import { useUnreadNotifCount } from "../../../utils/api/notification/notification.api";
import { Color } from "../../../utils/theme";
import { useToggle } from "../../../utils/utils";
import Login from "../../assets/icons/login.png";
import Message from "../../assets/icons/Message.png";
import Notif from "../../assets/icons/Notification.png";
import SearchModal from "./SearchModal";

const FirstHeader = ({ token }) => {
	// const { countItems } = useAppSelector<any>((state) => state.cart);
	const count = useChatCount();
	const countUnReadNotif = useUnreadNotifCount();
	const toggleShow = (e: any) => {
		var el: any = document.getElementById("box");
		el.classList.toggle("show");
	};
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { isOpen, toggleModal } = useToggle();

	return (
		<div className="first-header-component py-2 px-4">
			<div className="w-100">
				<div className="bloc-first-header-right">
					<div className="bloc-first-header-space-between">
						<div className="container-input-search no-view-mobile" onClick={toggleModal}>
							<input
								type="text"
								id="box"
								placeholder="Rechercher..."
								className="search__box"
							/>
							<i
								className="fas fa-search search__icon"
								id="icon"
								onClick={(e) => toggleShow(e)}
							></i>
						</div>
						<div className="w-100">
							<ul className="navbar-nav-first-header mb-0">
								{!token && (
									<li className="nav-item-first-header">
										<a
											className="nav-link-first-header"
											href="/app/se-connecter"
										>
											<img
												src={Login}
												alt="icon login"
												className="icon-first-header"
											/>
											<span className="ps-2">Se connecter</span>
										</a>
									</li>
								)}
								{/* <li className="nav-item-first-header">
									<NavLink
										className="nav-link-first-header"
										to="/app/commandes/panier"
									>
										<img
											src={Achat}
											alt="icon first header"
											className="icon-first-header"
										/>
										<span className="ps-2">
											Panier
											<div className="amount-container">
												<p className="total-amount">
													{countItems || 0}
												</p>
											</div>
										</span>
									</NavLink>
								</li> */}
								<>
									<li className="nav-item-first-header">
										<a
											className="nav-link-first-header"
											href="/app/mon-espace-client/messages"
										>
											<img
												src={Message}
												alt="icon first header"
												className="icon-first-header"
											/>
											<span className="ps-2">Messages({count})</span>
										</a>
									</li>
									<li
										className="nav-item-first-header"
										// onClick={() => readNotification()}
									>
										<a
											className="nav-link-first-header"
											href="/app/mon-espace-client/notifications"
										>
											<img
												src={Notif}
												alt="icon first header"
												className="icon-first-header"
											/>
											<span className="ps-2">
												Notifications(
												{countUnReadNotif})
											</span>
										</a>
									</li>
									{token && (
										<div className="text-center">
											<button
												onClick={() => {
													dispatch(onlogout());
													navigate("/app/se-connecter");
												}}
												className="logout-btn no-style-btn"
											>
												<AiOutlineLogout
													style={{
														fontSize: 20,
														color: Color.themeColor,
													}}
												/>
												<span className="item-nav-menu">
													Se Déconnecter
												</span>
											</button>
										</div>
									)}
								</>
								{/* )} */}
							</ul>
						</div>
					</div>
				</div>
			</div>
			<SearchModal isOpen={isOpen} closeModal={toggleModal} />
		</div>
	);
};
export default FirstHeader;
