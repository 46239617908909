import React, { useCallback, useEffect, useState } from "react";
import { MdOutlineAddCircleOutline, MdOutlineMoreHoriz } from "react-icons/md";
import StarRatingComponent from "react-star-rating-component";
import { AiTwotoneHeart } from "react-icons/ai";
import {
  useDeleteAnnonceMutation,
  useLazyAnnonceByUserQuery,
} from "../../../../utils/api/annonce/annonce.api";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import { Link, useLocation } from "react-router-dom";
import VolkenoSkeleton from "volkeno-react-skeleton";
import "volkeno-react-skeleton/dist/index.css";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import {
  calculteRating,
  createMarkup,
  formattedDate,
  formattedTypeAnnonce,
} from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import { useAppSelector } from "../../../../redux/hooks";
import { IUser } from "../../../../redux/slice/User.slice";
import { FaThumbsUp } from "react-icons/fa";

const AnnoncesTabs = () => {
	const [annonces, setAnnonces] = useState<IAnnonce[]>([]);
	const [findAnnonceBySlug, { data, isLoading }] = useLazyAnnonceByUserQuery();
	const { user } = useAppSelector((s) => s.user);
	const { pathname } = useLocation();

	useEffect(() => {
		if (!annonces.length) {
			findAnnonceBySlug(user?.slug);
		}
	}, [annonces]);
	useEffect(() => {
		if (data) {
			setAnnonces(data);
		}
	}, [data]);

	return (
		<div className="annonce-tabs-component">
			<div className="bloc-title-page-tab pb-4">
				<div>
					<p className="titre-page-tab-content">Mes annonces</p>
				</div>
				<div>
					<Link to="/app/annoncedeposer-annonce" className="btn btn-send-newsletter">
						<MdOutlineAddCircleOutline />
						<span className="ps-2">Ajouter une annonce</span>
					</Link>
				</div>
			</div>
			<div className="annonce-content-page">
				<div className="row">
					{!isLoading && annonces?.length === 0 && <AlertInfo message="Aucune annonce trouvée" />}
					{!isLoading &&
						annonces?.length > 0 &&
						annonces?.map((annonce) => (
							<div className="col-md-6 pb-3" key={annonce.slug}>
								<AnnonceItem annonce={annonce} user={user} pathname={pathname} />
							</div>
						))}
					{isLoading &&
						!annonces?.length &&
						[...Array(4)].map((elem, index) => (
							<div className="col-md-6  pb-3" key={index}>
								<AnnonceItemSkeleton />
							</div>
						))}
				</div>
			</div>
		</div>
	);
};
type Propstype = {
	annonce: IAnnonce;
	user?: IUser;
	pathname?: string;
};
export function AnnonceItem({ annonce, user, pathname }: Propstype) {
	return (
		<div className="col-item-annonce-tabs mb-3">
			<div className="card-annonce-tabs">
				<div>
					<div className="flex-sb">
						<div className="gap-2 content-title-annonce-header">
							<h4 className="titre-card-annonce fw-400 m-0">{annonce?.code}</h4>
							<Link
								to={
									pathname && pathname?.includes("mon-espace-client")
										? `/app/mon-espace-client/mes-annonces/${annonce?.slug}`
										: `/app/categories/annonces/${annonce?.slug}`
								}
								className="titre-card-annonce decoration-none link-hover-orange"
								state={annonce}
							>
								{annonce?.title}
							</Link>
						</div>
						{user && user?.id === annonce?.user?.id ? (
							<div className="dropdown">
								<button
									className="no-style-btn"
									type="button"
									id="dropdownMenuButton"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<MdOutlineMoreHoriz />
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<li>
										<Link
											to={`/app/annonce/modifier-annonce/${annonce?.slug}`}
											state={{ annonce: annonce }}
											className="dropdown-item dropdown-item-hover"
										>
											Modifier
										</Link>
									</li>
									<li>
										<DeleteAnnonce slug={annonce?.slug} />
									</li>
								</ul>
							</div>
						) : (
							<button className="no-style-btn" type="button">
								<MdOutlineMoreHoriz />
							</button>
						)}
					</div>
					<Link
						to={
							pathname && pathname?.includes("mon-espace-client")
								? `/app/mon-espace-client/mes-annonces/${annonce?.slug}`
								: `/app/categories/annonces/${annonce?.slug}`
						}
						className="titre-card-annonce decoration-none link-hover-orange"
						state={annonce}
					>
						<div className="">
							<div
								className="annonce-publie-value-tabes mb-2"
								dangerouslySetInnerHTML={createMarkup(
									annonce?.description.slice(0, 100),
									true
								)}
							/>
							<div className="d-flex pb-2">
								<p className="annonce-publie-libelle-tabs">Type d'annonce:</p>
								<p className="annonce-publie-value-tabs">
									{" "}
									{formattedTypeAnnonce(annonce?.type_announcement)}
								</p>
							</div>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(annonce?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#F5F5F5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div className="d-flex align-items-center gap-3">
								<div>
									<AiTwotoneHeart className="icon-like-annonce" />
									<span className="annonce-like ps-1">
										{annonce?.favorites?.length}
									</span>
								</div>
								<div>
									<FaThumbsUp className="icon-like-annonce" />
									<span className="annonce-like ps-1">
										{annonce?.interets?.length}
									</span>
								</div>
							</div>
							<div>
								<p className="date-annonce">{formattedDate(annonce?.created_at)}</p>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
}
export function AnnonceItemSkeleton() {
  return (
    <div className="col-item-annonce-tabs">
      <div className="card-annonce-tabs">
        <div>
          <div className="flex-sb">
            <div>
              <VolkenoSkeleton variant="rect" width={200} height={10} />
            </div>
            <div className="dropdown">
              <VolkenoSkeleton variant="rect" width={20} height={10} />
            </div>
          </div>
          <div>
            <VolkenoSkeleton
              variant="rect"
              width="100%"
              height={50}
              style={{ marginRight: 10 }}
            />
          </div>
          <div className="d-flex pb-2">
            <VolkenoSkeleton
              variant="rect"
              width={150}
              height={10}
              style={{ marginRight: 10 }}
            />
            <VolkenoSkeleton variant="rect" width={150} height={10} />
          </div>
          <div className="flex-sb pt-3 align-items-center">
            <div>
              <VolkenoSkeleton variant="rect" width={100} height={10} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" width={30} height={10} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" width={100} height={10} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AnnoncesTabs;

export function DeleteAnnonce({ slug }: { slug: IAnnonce["slug"] }) {
  const onDeleteItem = useDeleteAnnonce(slug);
  return (
    <button
      className="dropdown-item dropdown-item-hover"
      onClick={onDeleteItem}
    >
      Supprimer
    </button>
  );
}

export function useDeleteAnnonce(slug: IAnnonce["slug"]) {
  const [deleteAnnonce, { isSuccess, isError, error }] =
    useDeleteAnnonceMutation();

  const onDeleteItem = useCallback(() => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer cette annonce",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn mx-2",
      },
      preConfirm: () => {
        return deleteAnnonce(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  }, []);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Annonce supprimée avec succès",
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);
  return onDeleteItem;
}
