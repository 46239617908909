import { BsFolder } from "react-icons/bs";
import { Link, useParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetCandidatureByProjectQuery } from "../../../../utils/api/candidature/candidature.api";
import { ICandidature } from "../../../../utils/api/candidature/canditure.type";
import { formattedDate } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";

const CandidaturesByProject = () => {
	const { user } = useAppSelector((s) => s.user);

	const { data, isLoading } = useGetCandidatureByProjectQuery(user?.slug);
	const { slug } = useParams();
	console.log({ slug });

	return (
		<div className="row">
			{!isLoading ? (
				data && data?.length > 0 ? (
					data?.map((item) => (
						<div className="col-md-6 col-xxl-4 mb-3" key={item?.slug}>
							<CandidatureCard
								item={item}
								url={`/app/mon-espace-client/mes-projets/${slug}/candidatures`}
							/>
						</div>
					))
				) : (
					<AlertInfo message="Aucune candidature trouvée" />
				)
			) : null}
		</div>
	);
};

export const CandidatureCard = ({ item, url }: { item: ICandidature; url: string }) => {
	return (
		<Link
			to={url}
			className="candidature-card-item-link"
			state={item}
			style={{ textDecoration: "none" }}
		>
			<div className="candidature-card-item shadow">
				<div className="card-candidature-header d-flex align-items-center justify-content-between mb-3 py-2 px-2 px-xl-3">
					<div className="icon-container">
						<BsFolder style={{ fontSize: 24 }} />
					</div>
					<h3 className="card-candidature-title m-0">{item?.project?.title}</h3>
				</div>
				<div className="card-candidature-body  px-2 px-xl-3 py-3">
					<div className="d-flex gap-3 mb-3">
						<p className="card-candidature-label">De:</p>
						<p className="card-candidature-value">
							{item?.candidat?.firstname +
								" " +
								item?.candidat?.lastname}
						</p>
					</div>
					<div className="d-flex gap-3 mb-3">
						<p className="card-candidature-label">Pièces jointes :</p>
						<p className="card-candidature-value">
							{item?.cv && "Curriculum Vitae"}{" "}
							{item?.modification_letter && ", Lettre de motivation"}
						</p>
					</div>
					<div className="d-flex justify-content-between">
						<div className="d-flex gap-3">
							<p className="card-candidature-label">Envoyé le:</p>
							<p className="card-candidature-value">
								{formattedDate(item?.created_at)}
							</p>
						</div>
						<div className="d-flex gap-3">
							<p className="card-candidature-label">Statut:</p>
							<p className="card-candidature-value text-capitalized">
								{item?.status}
							</p>
						</div>
					</div>
				</div>
			</div>
		</Link>
	);
};

export default CandidaturesByProject;
