import { AiTwotoneHeart } from "react-icons/ai";
import { FaThumbsUp } from "react-icons/fa";
import { MdOutlineMoreHoriz } from "react-icons/md";
import StarRatingComponent from "react-star-rating-component";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import {
	calculteRating,
	createMarkup,
	formattedDate,
	formattedTypeAnnonce,
} from "../../../../utils/utils";
import DetailAnnonceModal from "../../modals/adminModal/DetailAnnonceModal";
import ListOfUsersInterestedAnnonceModal from "../../modals/adminModal/ListOfUsersInterestedAnnonceModal";
import { DeleteAnnonce } from "../../user/MyCustumerArea/AnnoncesTabs";
import { CustomPagination } from "../SettingAdmin/DeliveryMode";
import useAnnoncesList from "./useAnnoncesList";

const AnnoncesList = () => {
	const {
		data,
		isLoading,
		item,
		openModal,
		page,
		perPage,
		setPage,
		setPerPage,
		isShowModal,
		closeModal,
		setItem,
	} = useAnnoncesList();

	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container d-flex align-items-center gap-1">
					<h1 className="admin-page-title">Annonces</h1>
					<h3 className="total-items mb-1">
						| {!isLoading && data?.count} résultats
					</h3>
				</div>
				<div className="bloc-right-container gap-3">
					<div className="container-input-search-admin">
						<div className="search-box-admin">
							<input
								type="search"
								className="search-input-admin"
								placeholder="Search..."
							/>
							<button className="search-button-admin">
								<i className="fas fa-search"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				{!isLoading &&
					data?.results?.map((item) => (
						<AnnonceItem
							annonce={item}
							openModal={openModal}
							setItem={setItem}
							key={item?.slug}
						/>
					))}
				{isLoading &&
					[...Array(6)]?.map((elem, i) => <AnnonceItemSkeleton key={i} />)}
				{!isLoading && data?.results && (
					<CustomPagination
						nbPages={data?.count}
						page={page}
						perPage={perPage}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
					/>
				)}
			</div>
			<DetailAnnonceModal item={item} />
			<ListOfUsersInterestedAnnonceModal
				interets={item?.interets}
				slug={item?.slug}
				isOpen={isShowModal}
				closeModal={closeModal}
			/>
		</div>
	);
};

function AnnonceItem({
	annonce,
	openModal,
	setItem,
}: {
	annonce: IAnnonce;
	openModal: (val: IAnnonce) => void;
	setItem: (val: IAnnonce) => void;
}) {
	return (
		<div className="col-lg-6 col-xl-4 pb-3">
			<div className="col-item-annonce-tabs">
				<div className="card-annonce-tabs">
					<div>
						<div className="flex-sb">
							<div>
								<h3 className="titre-card-projet">
									{annonce?.title}
								</h3>
							</div>
							<div className="dropdown">
								<button
									className="no-style-btn"
									type="button"
									id="dropdownMenuButton"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<MdOutlineMoreHoriz />
								</button>
								<ul
									className="dropdown-menu"
									aria-labelledby="dropdownMenuButton"
								>
									<li>
										<button
											className="dropdown-item dropdown-item-hover"
											data-bs-toggle="modal"
											data-bs-target="#DetailAnnonceModal"
											onClick={() => setItem(annonce)}
										>
											Voir
										</button>
									</li>
									<li>
										<DeleteAnnonce slug={annonce?.slug} />
									</li>
								</ul>
							</div>
						</div>
						<div className="">
							<div
								className="annonce-publie-value-tabes mb-2"
								dangerouslySetInnerHTML={createMarkup(
									annonce?.description.slice(0, 100),
									true
								)}
							/>
							<div className="d-flex pb-2 pt-2">
								<p className="annonce-publie-libelle-tabs">
									Type d'annonce:
								</p>
								<p className="annonce-publie-value-tabs">
									{" "}
									{formattedTypeAnnonce(
										annonce?.type_announcement
									)}
								</p>
							</div>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(annonce?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#F5F5F5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div className="d-flex align-items-center gap-3">
								<div>
									<AiTwotoneHeart className="icon-like-annonce" />
									<span className="annonce-like ps-1 medium">
										{annonce?.favorites?.length}
									</span>
								</div>
								<div
									role="button"
									onClick={() => openModal(annonce)}
								>
									<FaThumbsUp className="icon-like-annonce" />
									<span className="annonce-like ps-1 medium">
										{annonce?.interets?.length}
									</span>
								</div>
							</div>
						</div>
						<div className="flex-sb mt-3">
							<div>
								<p className="date-annonce">
									{formattedDate(annonce?.created_at)}
								</p>
							</div>
							<div className="flex-r-m gap-2 author-container">
								<span>par</span>
								<strong>{`${annonce?.user?.firstname} ${annonce?.user?.lastname}`}</strong>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export function AnnonceItemSkeleton() {
	return (
		<div className="col-lg-6 col-xl-4  pb-3">
			<div className="col-item-annonce-tabs">
				<div className="card-annonce-tabs">
					<div>
						<div className="flex-sb">
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={200}
									height={10}
								/>
							</div>
							<div className="dropdown">
								<VolkenoSkeleton
									variant="rect"
									width={20}
									height={10}
								/>
							</div>
						</div>
						<div>
							<VolkenoSkeleton
								variant="rect"
								width="100%"
								height={50}
								style={{ marginRight: 10 }}
							/>
						</div>
						<div className="d-flex pb-2">
							<VolkenoSkeleton
								variant="rect"
								width={150}
								height={10}
								style={{ marginRight: 10 }}
							/>
							<VolkenoSkeleton variant="rect" width={150} height={10} />
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={100}
									height={10}
								/>
							</div>
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={30}
									height={10}
								/>
							</div>
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={100}
									height={10}
								/>
							</div>
						</div>
					</div>
					<div className="flex-r-m gap-2 author-container">
						<div>
							<VolkenoSkeleton variant="rect" width={30} height={10} />
						</div>
						<div>
							<VolkenoSkeleton variant="rect" width={100} height={10} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}
export default AnnoncesList;
