import React from "react";
import TeamListTable from "../../Tables/Admin/Team/TeamListTable";

const Team = () => {
	return (
		<div>
			<TeamListTable />
		</div>
	);
};

export default Team;
