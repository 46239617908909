import React from "react";
import { Color } from "../../../../../utils/theme";
import { BtnBack, BtnNext, BtnSkip } from "../../../../common/Button";
import { CustomInput } from "../../../../common/CustomInput";
import { IconDelete3 } from "../../../../shared/Icons";
import { TraderStepPropsType } from "../../BecomeTrader/Steps/AddInfoTrader";

const Tarification = ({ navigation, register, errors, setValue, file, setFile }) => {
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		navigation.go("add-image");
	};
	return (
		<div>
			<h4 className="stepper-title">Tarification</h4>
			<div>
				<div className="row">
					<CustomInput
						label="Prix"
						type="number"
						step={"any"}
						name="price"
						min={0}
						required
						{...register("price")}
						error={errors?.price?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Quantités"
						type="number"
						min={1}
						required
						{...register("quantity")}
						error={errors?.quantity?.message}
						name="quantity"
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Image couverture"
						type="file"
						name="background_image"
						required
						accept="image/*"
						// {...register("background_image")}
						// value={file}
						onChange={(e) => {
							if (e.target.files) {
								setValue("background_image", e.target.files[0]);
								setFile(e.target.files[0]);
							}
						}}
						error={errors?.background_image?.message}
						style={{ backgroundColor: Color.bgGray }}
					/>
					{file && (
						<div className="col-md-6 form-group mb-4">
							<div className="product-img-card">
								
								<img src={URL.createObjectURL(file)} alt="product-img" width={100} height={100} />
							</div>
						</div>
					)}
				</div>
				<div className="flex-r gap-3 mt-4">
					<BtnBack label="Retour" type="button" navigation={navigation} />
					<BtnNext label="Suivant" type="submit" />
				</div>
			</div>
		</div>
	);
};

export default Tarification;

const btnStyle = {
	position: "absolute",
	top: "3px",
	right: "3px",
};
