import React, { useEffect, useState } from "react";
import AbonnementsTableSkeleton from "./AbonnementsTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { Link } from "react-router-dom";
import { FiEye } from "react-icons/fi";
import moment from "moment";
import ChangeStatusOrder from "../../../modals/adminModal/ChangeStatusOrder";
import { useGetAbonnementQuery } from "../../../../../utils/api/abonnement/abonnement.api";
import AddAbonnementModal from "../../../modals/adminModal/AddAbonnementModal";
import { RiDeleteBin2Fill, RiEdit2Fill } from "react-icons/ri";
import { createMarkup } from "../../../../../utils/utils";
import { IAbonnnement } from "../../../../../utils/api/abonnement/abonnement.type";
import { useDeleteAbonnement } from "../../../modals/adminModal/forms/useAbonnementForm";

function AbonnementsTable() {
  const { isLoading, data } = useGetAbonnementQuery();
  console.log(data);
  const [item, setItem] = useState<IAbonnnement>();
  const actionFormatter: any = (cell, row: IAbonnnement) => {
    return (
		<div className="table-actions-btn-container-commandes gap-2 d-flex">
			{/* <Link
					title="Voir commande"
					state={row}
					to={`/app/admin/commandes/${row?.slug}`}
					className="btn btn-action-user-admin"
				>
					<FiEye />
				</Link> */}
			<div>
				<button
					className="btn btn-action-user-admin"
					data-bs-toggle="modal"
					data-bs-target="#addAbonnementModal"
					onClick={() => setItem(row)}
				>
					<RiEdit2Fill />
				</button>
			</div>
			<div>
				<DeleteAbonnement item={row} />
			</div>
		</div>
    );
  };
  const columns = [
    {
      dataField: "title",
      text: "Nom",
      style: { textAlign: "left" },
      headerStyle: {
        width: "20%",
      },
    },
    {
      dataField: "duration",
      text: "Durée",
      style: { textAlign: "left" },
      formatter: (cell, row) => moment(cell).format("DD/MM/YYYY"),
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "price",
      text: "Montant",
      formatter: (cell, row) => cell + "€",
      headerStyle: {
        width: "8%",
      },
    },
    {
      dataField: "description",
      text: "Description",
      formatter: (cell: string, row: any) => (
        <div
          dangerouslySetInnerHTML={createMarkup(cell)}
          className="desc-abonnement"
        ></div>
      ),
    },
    {
      dataField: "actions",
      text: "actions",
      formatter: (cell: any, row: IAbonnnement) => actionFormatter(cell, row),
    },
  ];

  return (
    <>
      {isLoading && <AbonnementsTableSkeleton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data || []}
          columns={columns}
          striped
          noDataIndication="Aucune donnée disponible"
          bordered={false}
          condensed={false}
          responsive
          wrapperClasses="table-responsive admin-table"
          rowStyle={{
            fontFamily: "Montserrat",
            fontStyle: "normal",
            fontWeight: "600",
            fontSize: "14px",
            lineHeight: "21px",
            color: "rgba(0, 0, 0, 0.7)",
          }}
          headerClasses="header-class"
        />
      )}
      <AddAbonnementModal abonnement={item} />
    </>
  );
}

function DeleteAbonnement({ item }: { item: IAbonnnement }) {
  const onDelete = useDeleteAbonnement(item);
  return (
    <button className="btn btn-action-user-admin" onClick={onDelete}>
      <RiDeleteBin2Fill />
    </button>
  );
}

export default AbonnementsTable;
