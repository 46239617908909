import { MdOutlineAddCircleOutline, MdOutlineMoreHoriz } from "react-icons/md";
import VolkenoSkeleton from "volkeno-react-skeleton";
import "volkeno-react-skeleton/dist/index.css";
import StarRatingComponent from "react-star-rating-component";
import { AiTwotoneHeart } from "react-icons/ai";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useCallback, useEffect, useState } from "react";
import { IProject } from "../../../../utils/api/project/project.type";
import {
  useDeleteProjectMutation,
  useLazyGetProjectByUserQuery,
} from "../../../../utils/api/project/project.api";
import {
  calculteRating,
  createMarkup,
  formattedDate,
} from "../../../../utils/utils";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { AlertInfo } from "../../../common/Alert";
import AjoutBudgetModal from "../../modals/Budget/AjoutBudgetModal";
import { IUser } from "../../../../redux/slice/User.slice";
import { useAppSelector } from "../../../../redux/hooks";

const MesProjetsTabs = () => {
  const [projects, setProjects] = useState<IProject[]>([]);
  const [getProjects, { data, isLoading }] = useLazyGetProjectByUserQuery();
  const { user } = useAppSelector((s) => s.user);
  const { pathname } = useLocation();

  useEffect(() => {
    if (!projects.length) {
      getProjects();
    }
  }, [projects.length]);
  useEffect(() => {
    if (data) {
      setProjects(data["results"]);
    }
  }, [data]);
  return (
		<div className="mes-projets-tabs-component">
			<div className="bloc-title-page-tab pb-4">
				<div className="mb-3">
					<p className="titre-page-tab-content">Mes appels d'offres</p>
				</div>
				<div className="d-flex d-flex-mobile">
					<button
						className="btn btn-send-newsletter mb-3"
						data-bs-toggle="modal"
						data-bs-target="#ajoutBudgetModal"
					>
						<MdOutlineAddCircleOutline />
						<span className="ps-2">Ajouter un budget</span>
					</button>
					<NavLink
						to="/app/mon-espace-client/mes-projets/ajouter"
						className="btn btn-send-newsletter mb-3"
					>
						<MdOutlineAddCircleOutline />
						<span className="ps-2">Ajouter un appel d’offre</span>
					</NavLink>
				</div>
			</div>
			<div className="annonce-content-page">
				<div className="row">
					{!isLoading && projects?.length === 0 && <AlertInfo message="Aucun projet trouvé" />}
					{!isLoading &&
						projects.length > 0 &&
						projects.map((project) => (
							<div className="col-md-6 pb-3" key={project.slug}>
								<ProjectItem project={project} user={user} />
							</div>
						))}
					{isLoading &&
						[...Array(4)].map((item, index) => (
							<div className="col-md-6  pb-3" key={index}>
								<ProjectItemSkeleton />
							</div>
						))}
				</div>
			</div>
			<AjoutBudgetModal />
		</div>
  );
};

export const ProjectItem = ({
  project,
  user,
  pathname,
}: {
  project: IProject;
  user?: IUser;
  pathname?: string;
}) => {
  return (
		<div className="col-item-annonce-tabs">
			<div className="card-annonce-tabs">
				<div>
					<div className="flex-sb">
						<div className="content-title-annonce-header gap-3">
							<h4 className="titre-card-projet fw-400 m-0">{project?.code}</h4>
							<Link
								className="titre-card-projet decoration-none link-hover-orange"
								to={
									pathname && pathname?.includes("mon-espace-client")
										? `/app/mon-espace-client/mes-projets/${project?.slug}`
										: `/app/categories/projets/${project?.slug}`
								}
								state={project}
							>
								{project?.title}
							</Link>
						</div>
						{user && user?.id === project?.user?.id ? (
							<div className="dropdown">
								<button
									className="no-style-btn"
									type="button"
									id="dropdownMenuButton"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<MdOutlineMoreHoriz />
								</button>
								<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton">
									<li>
										<Link
											to={`/app/mon-espace-client/mes-projets/modifier/${project?.slug}`}
											state={{ project: project }}
											className="dropdown-item dropdown-item-hover"
										>
											Modifier
										</Link>
									</li>
									<li>
										<DeleteProject slug={project?.slug} />
									</li>
								</ul>
							</div>
						) : (
							<button className="no-style-btn" type="button">
								<MdOutlineMoreHoriz />
							</button>
						)}
					</div>
					<Link
						className="titre-card-projet decoration-none link-hover-orange"
						to={
							pathname && pathname?.includes("mon-espace-client")
								? `/app/mon-espace-client/mes-projets/${project?.slug}`
								: `/app/categories/projets/${project?.slug}`
						}
						state={project}
					>
						<div className="pt-3">
							<div className="d-flex pb-2 gap-1">
								<p className="annonce-publie-libelle-tabs">Budget:</p>
								<p className="annonce-publie-value-tabs ps-0 pe-5 text-right">
									{" "}
									{project?.budget[0]?.name}
								</p>
							</div>
							<div className="d-flex pb-2">
								<p className="annonce-publie-libelle-tabs">Type de contrat: </p>
								<p
									className={`annonce-publie-value-tabs ${
										project?.type_contract === "cdi" ||
										project?.type_contract === "cdd"
											? "text-uppercase"
											: "text-capitalize"
									}`}
								>
									{project?.type_contract}
								</p>
							</div>
							<div className="d-flex pb-2">
								<p className="annonce-publie-libelle-tabs">Date d'expiration: </p>
								<p className="annonce-publie-value-tabs">
									{formattedDate(project?.date_expiration)}
								</p>
							</div>
							<div className="flex-sb">
								<p className="annonce-publie-libelle-tabs">Compétences requises:</p>
								<div
									className="text-card-projet-tabs"
									dangerouslySetInnerHTML={createMarkup(
										project?.skills.slice(0, 30)
									)}
								/>
							</div>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(project?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#f5f5f5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div>
								<AiTwotoneHeart className="icon-like-annonce" />
								<span className="annonce-like ps-1">{project?.favorites?.length}</span>
							</div>
							<div>
								<p className="date-annonce">{formattedDate(project?.created_at)}</p>
							</div>
						</div>
					</Link>
				</div>
			</div>
		</div>
  );
};
export function ProjectItemSkeleton() {
  return (
    <div className="col-item-annonce-tabs">
      <div className="card-annonce-tabs">
        <div>
          <div className="flex-sb">
            <div>
              <VolkenoSkeleton variant="rect" width={200} height={10} />
            </div>
            <div className="dropdown">
              <VolkenoSkeleton variant="rect" width={20} height={10} />
            </div>
          </div>
          {[...Array(4)].map((elem, index) => (
            <div className="d-flex pb-2" key={index}>
              <VolkenoSkeleton
                variant="rect"
                width={150}
                height={10}
                style={{ marginRight: 10 }}
              />
              <VolkenoSkeleton variant="rect" width={150} height={10} />
            </div>
          ))}
          <div className="flex-sb pt-3">
            <VolkenoSkeleton variant="rect" width={30} height={10} />
            <VolkenoSkeleton variant="rect" width={30} height={10} />
          </div>
          <div className="flex-sb pt-3 align-items-center">
            <div>
              <VolkenoSkeleton variant="rect" width={100} height={10} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" width={30} height={10} />
            </div>
            <div>
              <VolkenoSkeleton variant="rect" width={100} height={10} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export function DeleteProject({ slug }: { slug: IProject["slug"] }) {
  const onDeleteItem = useDeleteProject(slug);
  return (
    <button
      className="dropdown-item dropdown-item-hover"
      onClick={onDeleteItem}
    >
      Supprimer
    </button>
  );
}

export function useDeleteProject(slug: IProject["slug"]) {
  const [deleteProject, { isSuccess, isError, error }] =
    useDeleteProjectMutation();

  const onDeleteItem = useCallback(() => {
    Swal.fire({
      title: "Êtes vous sûr de vouloir supprimer ce projet",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Oui",
      cancelButtonText: "Non",
      showLoaderOnConfirm: true,
      focusCancel: true,
      customClass: {
        confirmButton: "swal-custom-btn mx-2",
        cancelButton: "swal-custom-btn mx-2",
      },
      preConfirm: () => {
        return deleteProject(slug);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        console.log("res", result);
      }
    });
  }, []);
  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: "Projet supprimé avec succès",
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      const err = error as any;
      console.log(err);

      Swal.fire({
        icon: "error",
        title: `Une erreur de status ${err?.status} est survenue!`,
        showConfirmButton: false,
        timer: 2000,
      });
    }
  }, [isError]);
  return onDeleteItem;
}
export default MesProjetsTabs;
