import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import {  InterestAnnonce, TInterestAnnonceData, TUpdateInterestAnnonceData } from "./interetAnnonce.type";
import { prepareHeaders } from "../user/user.api";

export const InterestAnnonceApi = createApi({
	reducerPath: "InterestAnnonceApi",
	tagTypes: ["InterestAnnonce", "findAnnonceBySlug", "annonce"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addInterestAnnonce: builder.mutation<TInterestAnnonceData, any>({
			query: (data) => ({
				url: "interet/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["InterestAnnonce", "findAnnonceBySlug", "annonce"],
		}),
		updateInterestAnnonce: builder.mutation<
			TUpdateInterestAnnonceData,
			InterestAnnonce | any
		>({
			query: ({ slug, ...data }) => ({
				url: `interet/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["InterestAnnonce", "findAnnonceBySlug", "annonce"],
		}),
		deleteInterest: builder.mutation<InterestAnnonce, string>({
			query: (slug) => ({
				url: `interet/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["InterestAnnonce", "findAnnonceBySlug", "annonce"],
		}),
	}),
});

export const {
	useAddInterestAnnonceMutation,
	useUpdateInterestAnnonceMutation,
	useDeleteInterestMutation,
} = InterestAnnonceApi;
