import React, { useEffect } from 'react'
import { Status } from '../../../../utils/constantes'
import { FormError } from '../../../common/CustomInputAdmin'
import CloseModalBtn from '../../../shared/CloseModalBtn'
import UseEditStatusOrder from '../../Tables/Admin/CommandesListAdminTable/requestOrder/UseEditStatusOrder'

function ChangeStatusOrder({ order }) {
  const {
    register,
    setValue,
    onSubmit,
    errors,
    isLoading,
  } = UseEditStatusOrder()

  useEffect(() => {
    if (order?.id) {
      setValue('slug', order?.slug)
      setValue('status', order?.status)
    }
  }, [order])
  return (
    <div className="modal-dialog modal-dialog-centered modal-md">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title modal-title-custom fw-500 fs-20"
            id="ChangeStatusOrderModal"
          >
            Changer le statut de la commande
          </h5>
          <CloseModalBtn onClick={() => console.log('click')} />
        </div>
        <div className="modal-body justify-content-start w-100">
          <form className="w-100" onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Statut de la commande
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control form-control-input-admin py-3"
                    id="name"
                    {...register('status')}
                  >
                    <option value="">Choisir un type</option>
                    {Status?.map((option) => (
                      <option value={option?.value}>{option?.label}</option>
                    ))}
                  </select>
                  <FormError error={errors?.status?.message} />
                </div>
              </div>
            </div>
            <div className="text-center mb-4">
              <button
                className="btn btn-theme"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Valider`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Chargement...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ChangeStatusOrder
