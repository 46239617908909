import { useAppSelector } from "../../../../../redux/hooks";
import { BtnSubmit } from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import { CitySelector, CountrySelector, StateSelector } from "volkeno-react-country-state-city";
import ReactQuill from "react-quill";
import GoogleInput from "../../../../common/GoogleInput";
import useEditInfoPro from "./useEditInfoPro";

const EditProfilPro = () => {
	const {
		register,
		onSubmit,
		errors,
		setValue,
		country,
		state,
		city,
		handleCountrySelect,
		handleCitySelect,
		handleStateSelect,
		handleChangeDescription,
		isValidDescription,
		pro,
		description,
		isLoading,
	} = useEditInfoPro();
	return (
		<div>
			<div className="bloc-title-page-tab pb-4">
				<div>
					<p className="titre-page-tab-content">
						Mes informations professionnels
					</p>
				</div>
			</div>
			<div className="form-content">
				<form onSubmit={onSubmit}>
					<div className="row mb-4">
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="raison_sociale"
								className="espace-client-form-label"
								aria-labelledby="raison_sociale"
							>
								Raison Sociale
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Raison Sociale"
								id="raison_sociale"
								{...register("raison_sociale")}
								data-testid="titleProId"
								defaultValue={pro?.raison_sociale}
							/>
							{errors.raison_sociale?.message && (
								<ErrorMessage
									message={errors.raison_sociale.message}
								/>
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="siret"
								className="espace-client-form-label"
								aria-labelledby="siret"
							>
								Siret
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Siret"
								id="siret"
								{...register("siret")}
								data-testid="siretId"
								defaultValue={pro?.siret}
							/>
							{errors.siret?.message && (
								<ErrorMessage message={errors.siret.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="nom"
								className="espace-client-form-label"
								aria-labelledby="nom"
							>
								Nom du responsable
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Nom du responsable"
								id="nom"
								{...register("nom")}
								data-testid="nomId"
								defaultValue={pro?.nom}
							/>
							{errors.nom?.message && (
								<ErrorMessage message={errors.nom.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="prenom"
								className="espace-client-form-label"
								aria-labelledby="prenom"
							>
								Prénom du responsable
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Prénom du responsable"
								id="prenom"
								{...register("prenom")}
								data-testid="prenomId"
								defaultValue={pro?.prenom}
							/>
							{errors.prenom?.message && (
								<ErrorMessage message={errors.prenom.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="fonction"
								className="espace-client-form-label"
								aria-labelledby="fonction"
							>
								Fonction
								<span className="text-danger">*</span>
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Fonction"
								id="fonction"
								{...register("fonction")}
								data-testid="fonctionId"
								defaultValue={pro?.fonction}
							/>
							{errors.fonction?.message && (
								<ErrorMessage message={errors.fonction.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="city"
								className="espace-client-form-label"
							>
								Adresse
							</label>
							<span className="text-danger">*</span>
							<GoogleInput
								value={pro?.adress as string}
								onChange={(value) => {
									console.log(value);
									setValue("adress", value?.rue);
									setValue("longitude", value?.longitude);
									setValue("latitude", value?.latitude);
								}}
								className="form-control espace-client-form-control"
							/>
							{errors.adress?.message && (
								<ErrorMessage message={errors.adress.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="code_postal"
								className="espace-client-form-label"
							>
								Code Postal
							</label>
							<span className="text-danger">*</span>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Code Postal"
								id="code_postal"
								{...register("code_postal")}
								data-testid="codePostalId"
								defaultValue={pro?.code_postal}
							/>
							{errors.code_postal?.message && (
								<ErrorMessage
									message={errors.code_postal.message}
								/>
							)}
						</div>
						<div className="row">
							<div className="col-md-4 form-group mb-4">
								<label
									htmlFor="experiences"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Pays
								</label>
								<CountrySelector
									onChange={handleCountrySelect}
									name="country"
									placeholder="Pays"
									value={country}
									containerClass="tx country-select-container p-0"
								/>
							</div>
							<div className="col-md-4 form-group mb-4">
								<label
									htmlFor="experiences"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Région
								</label>
								<StateSelector
									country={country}
									name="state"
									value={state}
									countryPlaceholder="Region"
									onChange={handleStateSelect}
									containerClass="tx country-select-container p-0"
								/>
								{errors.region?.message && (
									<ErrorMessage
										message={errors.region.message}
									/>
								)}
							</div>
							<div className="col-md-4 form-group mb-4">
								<label
									htmlFor="Ville"
									className="espace-client-form-label"
									aria-labelledby="experiences"
								>
									Ville
								</label>
								<CitySelector
									state={state}
									name="city"
									value={city}
									statePlaceholder="Ville"
									onChange={handleCitySelect}
									containerClass="tx country-select-container p-0"
								/>
								{errors.city?.message && (
									<ErrorMessage message={errors.city.message} />
								)}
							</div>
						</div>
						<div className="col-12 mb-4 form-group">
							<label htmlFor="tel" className="espace-client-form-label">
								Téléphone
							</label>
							<input
								type="text"
								className="form-control espace-client-form-control"
								placeholder="Téléphone"
								id="tel"
								{...register("telephone")}
								data-testid="telephonesId"
								defaultValue={pro?.telephone}
							/>
							{errors.telephone?.message && (
								<ErrorMessage message={errors.telephone.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="portable"
								className="espace-client-form-label"
							>
								Portable
							</label>
							<input
								type="tel"
								className="form-control espace-client-form-control"
								placeholder="Portable"
								id="portable"
								{...register("portable")}
								data-testid="portablesId"
								defaultValue={pro?.portable}
							/>
							{errors.portable?.message && (
								<ErrorMessage message={errors.portable.message} />
							)}
						</div>
						<div className="col-12 mb-4 form-group">
							<label
								htmlFor="expertise-pro"
								className="espace-client-form-label"
							>
								Description
								<span
									style={{
										fontSize: "14px",
										opacity: 0.7,
									}}
								>
									(Qui vous êtes, ce que vous faites)
								</span>
							</label>
							<ReactQuill
								className="editor-cgu"
								value={description}
								onChange={handleChangeDescription}
							/>
							<small
								className={`${
									isValidDescription ? "text-success" : ""
								}`}
								style={{ fontWeight: "normal" }}
							>
								minimum 100 caractères
							</small>
							{errors.expertise_pro?.message && (
								<ErrorMessage
									message={errors.expertise_pro.message}
								/>
							)}
						</div>
						<div className="col-md-6 form-group mb-4">
							<label
								htmlFor="linkedin"
								className="espace-client-form-label"
							>
								Linkedin du pro
							</label>
							<input
								type="url"
								className="form-control espace-client-form-control"
								id="linkedin"
								placeholder="linkedin.com"
								{...register("linkedin")}
								data-testid="linkedinProId"
								defaultValue={pro?.linkedin}
							/>
							{errors.linkedin?.message && (
								<ErrorMessage message={errors.linkedin.message} />
							)}
						</div>
						<div className="col-md-6 form-group mb-4">
							<label
								htmlFor="facebook"
								className="espace-client-form-label"
							>
								Facebook du pro
							</label>
							<input
								type="url"
								className="form-control espace-client-form-control"
								id="facebook"
								placeholder="facebook.com"
								{...register("facebook")}
								data-testid="facebookProId"
								defaultValue={pro?.facebook}
							/>
							{errors.facebook?.message && (
								<ErrorMessage message={errors.facebook.message} />
							)}
						</div>
						<div className="col-md-6 form-group mb-4">
							<label
								htmlFor="twitter"
								className="espace-client-form-label"
							>
								Twitter du pro
							</label>
							<input
								type="url"
								className="form-control espace-client-form-control"
								id="twitter"
								placeholder="twitter.com"
								{...register("twitter")}
								data-testid="twitterProId"
								defaultValue={pro?.twitter}
							/>
							{errors.twitter?.message && (
								<ErrorMessage message={errors.twitter.message} />
							)}
						</div>
						<div className="col-md-6 form-group mb-4">
							<label
								htmlFor="instagram"
								className="espace-client-form-label"
							>
								Instagram du pro
							</label>
							<input
								type="url"
								className="form-control espace-client-form-control"
								id="instagram"
								placeholder="instagram.com"
								{...register("instagram")}
								data-testid="instagramProId"
								defaultValue={pro?.instagram}
							/>
							{errors.instagram?.message && (
								<ErrorMessage message={errors.instagram.message} />
							)}
						</div>
						<div className="col-md-4 offset-md-8 mt-5">
							<BtnSubmit label="Modifier" isLoading={isLoading} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
export default EditProfilPro;
