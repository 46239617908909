import { Outlet } from "react-router-dom";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import SidebarCustumerArea from "./SidebarCustumerArea";
import TitlePage from "./TitlePage";

const CustomerZone = () => {
	return (
		<div className="my-customer-area-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<TitlePage label="Bienvenue sur votre espace client" />
				<section className="content-page-container">
					<div>
						<div className="row">
							<div className="col-xl-3 col-lg-3 col-md-4">
								<SidebarCustumerArea />
							</div>
							<div className="col-xl-9 col-lg-9 col-md-12">
								<div className="col-tab-espace-client mt-3">
									<Outlet />
								</div>
							</div>
						</div>
					</div>
				</section>
			</div>
			<FrontFooter />
		</div>
	);
};

export default CustomerZone;
