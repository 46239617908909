import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddServiceMutation } from "../../../../utils/api/service/service.api";
import { TServiceForm } from "../../../../utils/api/service/service.type";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/utils";

const useServiceForm = () => {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		prenom: yup.string().required().label("Prenom"),
		email: yup.string().email("Email invalide").required().label("Email"),
		object: yup.string().required().label("Objet"),
		service: yup.string().required().label("Service"),
		message: yup.string().required().label("Message"),
		captcha_value: yup.string().required("Ce champ est obligatoire"),
	});

	const [country, setCountry] = useState<any>();
	const [phoneWhatshap, setPhoneWhatshap] = useState<string>();
	const [message, setMessage] = useState<any>();
	const { user } = useAppSelector((s) => s.user);
	const messageRef = useRef<any>(null);
	const [sendRequestService, { isLoading, isError, isSuccess, error }] =
		useAddServiceMutation();
	const regionNames = new Intl.DisplayNames(["fr"], { type: "region" });

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
		clearErrors,
		setValue,
	} = useForm<TServiceForm>({
		resolver: yupResolver(validationSchema),
		defaultValues: {
			prenom: user?.firstname,
			nom: user?.lastname,
			email: user?.email,
			created_by: user?.id,
		},
	});
	useEffect(() => {
		if (isSuccess) {
			setMessage("");
			Swal.fire({
				icon: "success",
				title: "Demande envoyée avec succès, nous vous contacterons bientôt",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				setCountry(null);
				setPhoneWhatshap("");
				reset();
				if (messageRef) {
					messageRef?.current?.editor.setText("");
				}
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de l'envoie",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const handleCountrySelect = (option: any) => {
		const country = regionNames.of(option);
		setCountry(option);
		setValue("pays", country);
	};
	const handleChangePhone = (phone: string) => {
		console.log(phone);
		setPhoneWhatshap(phone);
		setValue("phone", phone);
	};

	const onChangeRecaptcha = (value: any) => {
		setValue("captcha_value", value);
	};
	const handleChangeMessage = (value: any) => {
		setMessage(value);
		setValue("message", value);
	};

	const onsubmit = (data: TServiceForm) => {
		const validData = { ...data, created_by: user?.id };
		sendRequestService(validData);
	};
	return {
		register,
		onsubmit: handleSubmit(onsubmit),
		errors,
		isLoading,
		setValue,
		country,
		handleCountrySelect,
		phoneWhatshap,
		handleChangePhone,
		onChangeRecaptcha,
		message,
		handleChangeMessage,
		user,
		messageRef,
	};
};

export default useServiceForm;
