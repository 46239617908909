import React from "react";
import { BtnBack, BtnNext, BtnSkip } from "../../../../common/Button";
import { CustomInput } from "../../../../common/CustomInput";
import { CustomSelectInput } from "../../../../common/CustomSelectInput";
import { Status, TraderStepPropsType } from "./AddInfoTrader";

const AddAccountBank = ({ navigation, register, formState }) => {
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		navigation.go("summary");
	};
	return (
		<>
			<h1 className="stepper-title">Ajouter un compte bancaire</h1>

			<div>
				<div className="row">
					<CustomInput
						label="Nom de votre banque"
						name="bank-name"
						type="text"
						{...register("bank_name")}
						error={formState?.errors?.bank_name?.message}
					/>
					<CustomInput
						label="Numéro de compte"
						name="account_number"
						type="number"
						{...register("account_number")}
						error={formState?.errors?.account_number?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="IBAN"
						name="iban"
						type="text"
						{...register("iban")}
						error={formState?.errors?.iban?.message}
					/>
					<CustomInput
						label="Nom du titulaire de compte"
						name="resp-account"
						type="text"
						{...register("account_owner")}
						error={formState?.errors?.account_owner?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="SWIFT"
						name="swift"
						type="text"
						{...register("swift")}
						error={formState?.errors?.swift?.message}
					/>
				</div>
				<div className="flex-sb mt-5">
					<div>
						<BtnBack label="Retour" type="button" navigation={navigation} />
					</div>
					<div className="flex-r gap-3">
						<BtnSkip label="Passer l’étape" type="button" />
						<BtnNext label="Suivant" type="submit" />
					</div>
				</div>
			</div>
		</>
	);
};

export default AddAccountBank;
