import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useUpdateContactMutation } from "../../../../utils/api/contact/contact.api";
import { IContact, TEditContactForm } from "../../../../utils/api/contact/contact.type";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import * as yup from "yup";
import { cleannerError, onHide } from "../../../../utils/utils";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { FormError } from "../../../common/CustomInputAdmin";

const Status = [
	{
		value: "new",
		label: "Nouveau",
	},
	{
		value: "en_cours",
		label: "En cours",
	},
	{
		value: "traité",
		label: "Traité",
	},
];
function ChangeStatusMessageModal({ item }: { item?: IContact }) {
	const validationSchema = yup.object().shape({
		status: yup.string().required().label("La réponse"),
	});
	const [changeStatus, { isSuccess, isError, error, isLoading }] = useUpdateContactMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<TEditContactForm>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item?.id) {
			setValue("slug", item?.slug);
			setValue("status", item?.status);
		}
	}, [item]);

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Statut changé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("ChangeStatusMessageModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: "Une erreur a survenue lors du changement",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: TEditContactForm) => {
		console.log(data);
		changeStatus({ slug: data?.slug, data: data });
	};
	return (
		<div
			className="modal fade"
			id="ChangeStatusMessageModal"
			aria-labelledby="ChangeStatusMessageModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-md">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom fw-500 fs-20"
							id="ChangeStatusMessageModal"
						>
							Changer le statut du message
						</h5>
						<CloseModalBtn onClick={() => console.log("click")} />
					</div>
					<div className="modal-body justify-content-start w-100">
						<form className="w-100" onSubmit={handleSubmit(onSubmit)}>
							<div className="row">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Statut du message
											<span className="text-danger">
												*
											</span>
										</label>
										<select
											className="form-control form-control-input-admin py-3"
											id="name"
											{...register("status")}
										>
											<option value="">
												Choisir un type
											</option>
											{Status?.map((option) => (
												<option
													value={option?.value}
													key={option.value}
												>
													{option?.label}
												</option>
											))}
										</select>
										<FormError
											error={errors?.status?.message}
										/>
									</div>
								</div>
							</div>
							<div className="text-center mb-4">
								<button
									className="btn btn-theme"
									type="submit"
									disabled={isLoading}
								>
									{!isLoading ? (
										`Valider`
									) : (
										<>
											<span
												className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
												role="status"
											></span>
											<span>Chargement...</span>
										</>
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ChangeStatusMessageModal;
