import CloseModalBtn from "../../../shared/CloseModalBtn";
import { CKEditor } from "ckeditor4-react";
import "../../admin/MessageAdmin/MessageAdmin.css";
import ErrorMessage from "../../../common/ErrorMessage";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import React, { useEffect } from "react";
import useHelpEditForm from "./forms/useHelpEditForm";
import ReactQuill from "react-quill";
const EditQuestionModal = ({ item }: { item?: IFaq }) => {
	const { register, onsubmit, errors, setValue, isLoading } = useHelpEditForm();
	const [response, setResponse] = React.useState<any>("");
	useEffect(() => {
		if (item) {
			setValue("slug", item?.slug);
			setValue("title", item?.title);
			setValue("content", item?.content);
			setResponse(item?.content);
		}
	}, [item]);
	const handleChange = (value: any) => {
		setResponse(value);
		setValue("content", value);
	};
	return (
		<div
			className="modal fade"
			id="editQuestionModal"
			aria-labelledby={`editQuestionModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content py-2">
					<div className="munngano-modal-header d-flex justify-content-between align-items-center px-3">
						<h1 className="munngano-modal-title">Modifier la question</h1>
						<CloseModalBtn />
					</div>
					<div className="form-container px-3">
						<form onSubmit={onsubmit}>
							<div className="row mb-4">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Titre
										</label>
										<input
											type="text"
											className="form-control form-control-input-admin"
											placeholder="Titre"
											{...register("title")}
										/>
										{errors?.title && (
											<ErrorMessage
												message={
													errors.title.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12 form-group">
									<label
										htmlFor="desc-mision"
										className="form-label label-form-admin"
									>
										Réponse
									</label>
									{/* <CKEditor
										initData={`${response}`}
										onChange={(e) => {
											setValue(
												"content",
												e.editor.getData()
											);
										}}
									/> */}
									<ReactQuill
										className="editor-cgu"
										value={response || " "}
										onChange={handleChange}
									/>
									{errors?.content && (
										<ErrorMessage
											message={errors.content.message}
										/>
									)}
								</div>
							</div>
							<div className="btn-container gap-3 justify-content-end d-flex">
								<button className="admin-btn-cancel admin-btn-typo">
									Annuler
								</button>
								<button className="admin-btn-add admin-btn-typo">
									{isLoading ? (
										<>
											<i className="fas fa-spin fa-spinner"></i>
											"Chargement..."
										</>
									) : (
										"Modifier"
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default EditQuestionModal;
