import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ApiBaseUrl } from '../../http'
import { QueryUrl } from '../../utils'
import {
  PaginationResults,
  TypeQuery,
  UserTypeQuery,
} from '../commerce/commerce.type'
import { prepareHeaders } from '../user/user.api'
import { PaymentMode, PaymentModeFormData } from './paymentMode.type'

const tags = {
  paymentModeByVisitor: 'paymentModeByVisitor',
  paymentMode: 'paymentMode',
}
export const PaymentModeApi = createApi({
  reducerPath: 'paymentModeAPi',
  tagTypes: Object.values(tags),
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getPaymentModeByVisitor: builder.query<PaymentMode[], void>({
      query: () => `paymentmodebyvisitor/`,
      providesTags: [tags.paymentModeByVisitor],
    }),
    getPaymentMode: builder.query<
      PaginationResults<PaymentMode>,
      UserTypeQuery
    >({
      query: (query) => QueryUrl('/paymentmode/', query),
      providesTags: [tags.paymentMode],
    }),
    addPaymentMode: builder.mutation<PaymentMode, PaymentModeFormData | any>({
      query: (data) => ({
        url: '/paymentmode/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
    }),
    editPaymentMode: builder.mutation<PaymentMode, PaymentModeFormData | any>({
      query: ({ slug, ...data }) => ({
        url: `/paymentmode/${slug}/`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
    }),
    deletePaymentMode: builder.mutation<PaymentMode, string>({
      query: (slug) => ({
        url: `paymentmode/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [tags.paymentMode, tags.paymentModeByVisitor],
    }),
  }),
})

export const {
  useGetPaymentModeByVisitorQuery,
  useGetPaymentModeQuery,
  useAddPaymentModeMutation,
  useEditPaymentModeMutation,
  useDeletePaymentModeMutation,
} = PaymentModeApi
