import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { prepareHeaders } from "../user/user.api";
import { ApiBaseUrl } from "../../http";
import { SectorFormData, Tsector } from "./sector.type";
import { PaginationResults } from "../commerce/commerce.type";
import { QueryUrl } from "../../utils";

export const SectorApi = createApi({
	tagTypes: ["sector", "adminSectors"],
	reducerPath: "sectorApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getSector: builder.query<Tsector[], void>({
			query: () => ({ url: "sector/" }),
			providesTags: ["sector"],
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.position) - parseInt(b?.position)),
		}),
		getSectorForVistors: builder.query<Tsector[], void>({
			query: () => ({ url: "sectorbyvisitor/" }),
			providesTags: ["sector"],
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.position) - parseInt(b?.position)),
		}),
		getSectorAdmin: builder.query<
			PaginationResults<Tsector>,
			{ page: number; word: string }
		>({
			query: (query) => QueryUrl(`/sectorbyfilteradmin/`, query),
			providesTags: ["adminSectors"],
		}),
		addOrUpdateSector: builder.mutation<
			Tsector,
			{ slug?: string; data: SectorFormData | FormData }
		>({
			invalidatesTags: ["adminSectors"],
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `sector/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `sector/`,
					method: "POST",
					body: data,
				};
			},
		}),
		deleteSector: builder.mutation<Tsector, string>({
			query: (slug) => ({
				url: `sector/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["adminSectors"],
		}),
	}),
});

export const {
	useGetSectorQuery,
	useLazyGetSectorQuery,
	useGetSectorAdminQuery,
	useAddOrUpdateSectorMutation,
	useDeleteSectorMutation,
	useGetSectorForVistorsQuery,
} = SectorApi;
