import React from "react";
import OffresTable from "../../Tables/Admin/Offres/OffresTable";

const Offres = () => {
	return (
		<div className="tab-content-admin">
			<OffresTable />
		</div>
	);
};

export default Offres;
