import React from "react";
import { createMarkup } from "../../../../../utils/utils";

function Specialites({ partner }) {
	return (
		<div
			className="specialites-text px-xl-5 px-md-3 px-2"
			dangerouslySetInnerHTML={createMarkup(partner?.expertise)}
		/>
	);
}

export default Specialites;
