import React from 'react'
import { Authorisations } from '../../../../utils/constantes'
import { getAvatar } from '../../../../utils/utils'
import CloseModalBtn from '../../../shared/CloseModalBtn'

function DetailsAdminModal({ admin }) {
  return (
    <div className="modal-dialog easypm-modal-dialog modal-lg">
      <div className="modal-content easypm-modal-content">
        <div className="modal-header easypm-modal-header">
          <CloseModalBtn />
        </div>
        <div className="modal-body easypm-modal-body">
          <div className="add-family-form-container">
            <form className="crud-form">
              <div className="row position-relative">
                <div className="justify-content-center crud-col-item  d-flex">
                  <div className="form-group align-items-center bloc-admin-profil position-relative text-center">
                    <img
                      src={getAvatar(admin?.avatar)}
                      alt="img admin"
                      className="img-admin"
                      width="150"
                      height="150"
                      style={{ borderRadius: '100%' }}
                    />

                    <h3 className="profil-nom">
                      {admin?.firstname + ' ' + admin?.lastname}
                    </h3>
                  </div>
                </div>
                <div className="col-md-2  position-relative">
                  <div className="bloc-profil-right"></div>
                </div>
              </div>
              <div className="pb-3">
                <h4 className="title-info-profil">Informations</h4>
              </div>
              <div className="row position-relative">
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Prénom et Nom</p>
                    <p className="bloc-text-profil">
                      {admin?.firstname + ' ' + admin?.lastname}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Email</p>
                    <p className="bloc-text-profil">{admin?.email}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Adresse</p>
                    <p className="bloc-text-profil">{admin?.adresse}</p>
                  </div>
                </div>
                <div className="col-md-6 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <p className="texte-gray">Téléphone</p>
                    <p className="bloc-text-profil">{admin?.phone}</p>
                  </div>
                </div>
                <div className="col-md-12 crud-col-item">
                  <div className="form-group crud-form-group group-text">
                    <h6 className="title-info-profil mb-3">Permissions</h6>
                    <div className="row">
                      {/* {admin.dashboard ? ( */}

                      {Authorisations?.map((elem, i) => (
                        <div className="col-md-4 bloc-text-profil" key={i}>
                          <div
                            className="custom-control custom-checkbox mb-3 d-flex justify-content-start align-items-baseline"
                            style={{ whiteSpace: 'normal' }}
                          >
                            <input
                              type="checkbox"
                              className="custom-control-input"
                              name="dashboard"
                              checked={!!admin[`${elem?.value}`]}
                            />
                            <label
                              className="custom-control-label "
                              style={{ fontSize: '16px' }}
                            >
                              {elem?.label}
                            </label>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DetailsAdminModal
