import { useEffect, useState } from "react";
import { useStep } from "react-hooks-helper";
import { useAppSelector } from "../../../../../redux/hooks";
import { useGetCategoriesByVisitorQuery } from "../../../../../utils/api/category/category.api";
import { useStepType } from "../../../../../utils/type";
import StepperHorizontal from "../../../../common/StepperHorizontal";
import UseAddBoutique from "./requestCrudBoutique/UseAddBoutique";
import CompanyInfos from "./steps/CompanyInfos";
import InfosBancaire from "./steps/InfosBancaire";
import InfosBoutique from "./steps/InfosBoutique";

const steps = [
	{
		id: "detail-boutique",
		Component: InfosBoutique,
	},
	{
		id: "more-detail",
		Component: CompanyInfos,
	},
	{
		id: "infos-bank",
		Component: InfosBancaire,
	},
];
function AddBoutiqueTrader() {
	const { user } = useAppSelector((state) => state.user);
	const {
		register,
		onSubmit,
		formState,
		setValue,
		level,
		isLoading,
		setLevel,
		dataForm,
		setError,
	} = UseAddBoutique();
	const { data: categories = [], isSuccess } = useGetCategoriesByVisitorQuery();
	const [adress, setAdress] = useState<string>();
	const [profil, setProfil] = useState<string>();
	const [options, setOptions] = useState<any>([]);
	const [cityLabel, setCityLabel] = useState<string>("Choisir une ville");
	const [stateLabel, setStateLabel] = useState<string>("Choisir une région");
	const [logo, setLogo] = useState<File>();
	const [file, setFile] = useState<File>();
	const [option, setOption] = useState<any>();
	const [countryLabel, setCountryLabel] = useState<string>("Choisir un pays");
	const { step, navigation, index }: useStepType = useStep({
		initialStep: 0,
		steps,
	});

	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const { Component } = step;
	const props = {
		navigation,
		register,
		setValue,
		formState,
		user: user,
		setAdress,
		adress,
		setProfil,
		profil,
		options,
		setOptions,
		cityLabel,
		setCityLabel,
		setLevel,
		dataForm,
		country,
		setCountry,
		state,
		setState,
		city,
		setCity,
		isLoading,
		countryLabel,
		stateLabel,
		logo,
		setLogo,
		file,
		setFile,
		option,
		setOption,
		setError,
	};

	useEffect(() => {
		window.scrollTo(10, 10);
		let newIndex = index + 1;
		console.log("level index", level, step);
		setValue("step", newIndex);
	}, [index]);

	useEffect(() => {
		if (level) {
			console.log("level", level);
			if (level === 1) {
				navigation.go("more-detail");
			}

			if (level === 2) {
				navigation.go("infos-bank");
			}
		}
	}, [level, dataForm]);

	useEffect(() => {
		if (isSuccess) {
			setOptions(
				categories?.map((item) => {
					return {
						label: item?.name,
						value: item?.id,
					};
				})
			);
		}
	}, [categories, isSuccess]);

	useEffect(() => {
		if (dataForm?.name) {
			for (let key of Object.keys(dataForm)) {
				const val = dataForm[key];

				setValue(key, val);
			}
		}
	}, [location, user, dataForm]);
	return (
		<div className="article-card p-4">
			<div className="flex-sb">
				<div className="d-flex flex-column flex-md-row gap-md-3">
					<h3 className="article-title">Ajouter une boutique</h3>
				</div>
			</div>
			<StepperHorizontal index={index} />
			<form onSubmit={onSubmit}>
				<Component {...props} />
			</form>
		</div>
	);
}
export default AddBoutiqueTrader;
