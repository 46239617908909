import React, { useEffect, useState } from 'react'
import './SettingTrader.css'
import AddBannerShop from '../../modals/traderModals/AddBannerShop'
import { BsUpload } from 'react-icons/bs'
import { useAppSelector } from '../../../../redux/hooks'
import { getImage } from '../../../../utils/utils'
import UseEditInfosCommerce from './UseEditInfosCommerce'
import { FormError } from '../../../common/CustomInput'
import { useGetCategoriesByVisitorQuery } from '../../../../utils/api/category/category.api'
import GoogleInput from '../../../common/GoogleInput'
import AddLogoShop from '../../modals/traderModals/AddLogoShop'

const SettingTrader = () => {
  const { data: categories = [], isSuccess } = useGetCategoriesByVisitorQuery()
  const [category, setCategory] = useState<number | string>()
  const [adresse, setAdresse] = useState<string>('')
  const commerce = useAppSelector<any>((state) => state?.commerce)
  const { user } = useAppSelector((state) => state?.user)
  const {
    register,
    setValue,
    errors,
    onSubmit,
    isLoading,
    loading,
  } = UseEditInfosCommerce()

  useEffect(() => {
    if (commerce) {
      setAdresse(commerce?.location)
      setCategory(commerce?.category?.id)
      for (let key of Object.keys(commerce)) {
        const val = commerce[key]
        if (
          key !== 'likes' &&
          key !== 'products' &&
          key !== 'logo' &&
          key !== 'background_image' &&
          key !== 'slides' &&
          key !== 'subscribers' &&
          key !== 'notes'
        ) {
          if (key === 'author' || key === 'category') {
            setValue(key, val?.id)
          } else {
            setValue(key, val)
          }
        }
      }
    }
  }, [commerce])

  useEffect(() => {
    if (user) {
      setValue('lastname', user?.lastname)
      setValue('firstname', user?.firstname)
      setValue('phoneUser', user?.phone)
      setValue('emailUser', user?.email)
      setValue('slugUser', user?.slug)
    }
  }, [user])
  return (
    <>
      <div className="row">
        <div className="col-xl-12 m-b-5">
          <div className="card-dashboard m-b-15">
            <div>
              <h3 className="article-title">Paramètres</h3>
            </div>
            <div>
              <div className="text-legend-position">
                <p className="legende-banner-logo">Bannière de la boutique</p>
              </div>
              <div className="container-banner-shop">
                <img
                  className="banner-shop-dashboard"
                  src={getImage(commerce?.background_image)}
                  alt="Baniere boutique"
                />
                <div className="middle">
                  <button
                    className="ajouter-btn-modal"
                    type="button"
                    data-bs-toggle="modal"
                    data-bs-target="#addBannerShop"
                  >
                    Ajouter
                  </button>
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-5">
                  <p className="legende-banner-logo">Logo de la boutique</p>

                  <div className="container-banner-shop">
                    <img
                      className="logo-shop-dashboard"
                      src={getImage(commerce?.logo)}
                      alt="logo"
                    />
                    <button
                      className="ajouter-btn-modal middle"
                      data-bs-toggle="modal"
                      data-bs-target="#addLogoShop"
                    >
                      <span className="pe-3">Changer</span>
                      <BsUpload />
                    </button>
                  </div>
                </div>
              </div>
              {/* <div className="pt-3"></div> */}
              <div className="parametre-form mt-5">
                <form onSubmit={onSubmit}>
                  <div>
                    <p className="titre-form-info">
                      Informations du compte du vendeur
                    </p>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          placeholder="Prénom"
                          type="text"
                          {...register('firstname')}
                        />

                        {/* <p className="info-trader-setting">Abdou Diop</p> */}
                      </div>
                      {<FormError error={errors?.firstname?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="Nom"
                          {...register('lastname')}
                        />
                        {/* <p className="info-trader-setting">Abdou Diop</p> */}
                      </div>
                      {<FormError error={errors?.lastname?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          type="email"
                          className="form-control input-form-trader"
                          placeholder="Adresse mail"
                          {...register('emailUser')}
                        />
                        {/* <p className="info-trader-setting">
													ngoshop@gmail.com
												</p> */}
                      </div>
                      {<FormError error={errors?.emailUser?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          type="tel"
                          className="form-control input-form-trader"
                          placeholder="Téléphone"
                          {...register('phoneUser')}
                        />
                        {/* <p className="info-trader-setting">77 0000000</p> */}
                      </div>
                      {<FormError error={errors?.phoneUser?.message} />}
                    </div>
                  </div>
                  <div className="mt-5">
                    <p className="titre-form-info">Informations d'affaires</p>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <select
                          id="profil"
                          className="form-control input-form-trader w-100"
                          {...register('profil')}
                          onChange={(e) => {
                            setValue('profil', e.target.value)
                            if (e.target.value === 'personne_morale') {
                              setValue('isMoral', true)
                            } else {
                              setValue('isMoral', false)
                            }
                          }}
                        >
                          <option value="">
                            Personne physique ou Personne morale
                          </option>
                          <option value="personne_physique">
                            Personne physique
                          </option>
                          <option value="personne_morale">
                            Entité commerciale / Entreprise
                          </option>
                        </select>
                        {/* <input
													className="form-control input-form-trader"
													placeholder="Statut"
												/>
												<p className="info-trader-setting">
													Entité commerciale
												</p> */}
                      </div>
                      {<FormError error={errors?.profil?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="Commune / Ville "
                          {...register('city')}
                        />
                        {/* <p className="info-trader-setting">Dakar</p> */}
                      </div>
                      {<FormError error={errors?.city?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          placeholder="Nom de la boutique"
                          type="text"
                          {...register('name')}
                        />
                        {/* <p className="info-trader-setting">Ngo Shop</p> */}
                      </div>
                      {<FormError error={errors?.name?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          {...register('postal_card')}
                          placeholder="Code postal"
                        />
                        {/* <p className="info-trader-setting">12344</p> */}
                      </div>
                      {<FormError error={errors?.postal_card?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="NINEA"
                          {...register('ninea')}
                        />
                        {/* <p className="info-trader-setting">342546556</p> */}
                      </div>
                      {<FormError error={errors?.ninea?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="Personne responsable"
                          {...register('person_in_charge')}
                        />
                        {/* <p className="info-trader-setting">Abdou Diop</p> */}
                      </div>
                      {<FormError error={errors?.person_in_charge?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="Le nom légale"
                          {...register('legal_form')}
                        />
                        {/* <p className="info-trader-setting">GIE</p> */}
                      </div>
                      {<FormError error={errors?.legal_form?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <select
                          className="form-control input-form-trader w-100"
                          //   {...register('category')}
                          onChange={(e) => {
                            setValue('category', e.target.value)
                            setCategory(e.target.value)
                          }}
                          value={category}
                          id="category"
                        >
                          <option value="">
                            Catégorie Principale de vos produits
                          </option>
                          {categories?.map((item) => (
                            <option value={item?.id} key={item?.id}>
                              {' '}
                              {item?.name}{' '}
                            </option>
                          ))}
                        </select>
                      </div>
                      {<FormError error={errors?.category?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <GoogleInput
                          className="form-control input-form-trader w-100"
                          value={adresse}
                          onChange={(value) => {
                            setAdresse(value?.rue)
                            setValue('location', value?.rue)
                            setValue('longitude', value?.longitude)
                            setValue('latitude', value?.latitude)
                          }}
                        />
                      </div>
                      {<FormError error={errors?.category?.message} />}
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          type="text"
                          placeholder="Pays *"
                          {...register('country')}
                        />
                      </div>
                      {<FormError error={errors?.pays?.message} />}
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="form-setting-trader-costom">
                        <input
                          className="form-control input-form-trader"
                          placeholder="N° d'Enregistrement des Entreprises"
                          type="text"
                          {...register('company_register_number')}
                        />
                      </div>
                      {
                        <FormError
                          error={errors?.company_register_number?.message}
                        />
                      }
                    </div>
                  </div>
                  <div>
                    <p className="titre-form-info">Compte bancaire</p>
                  </div>
                  <div className="row mt-4">
                    <div className="col-md-5 mb-4">
                      <div className="">
                        <input
                          className="form-control form-setting-trader-costom input-info-bancaire"
                          placeholder="Nom de votre banque"
                          type="text"
                          {...register('bank_name')}
                        />
                      </div>
                      <FormError error={errors?.bank_name?.message} />
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="">
                        <input
                          className="form-control form-setting-trader-costom  input-info-bancaire"
                          placeholder="Nom du titulaire de compte"
                          type="text"
                          {...register('account_owner')}
                        />
                      </div>
                      <FormError error={errors?.account_owner?.message} />
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="">
                        <input
                          className="form-control form-setting-trader-costom  input-info-bancaire"
                          placeholder="Numéro de compte"
                          type="text"
                          {...register('account_number')}
                        />
                      </div>
                      <FormError error={errors?.account_number?.message} />
                    </div>
                    <div className="col-md-5 offset-md-2 mb-4">
                      <div className="">
                        <input
                          className="form-control form-setting-trader-costom  input-info-bancaire"
                          type="text"
                          placeholder="SWIFT"
                          {...register('swift')}
                        />
                      </div>
                      <FormError error={errors?.swift?.message} />
                    </div>
                    <div className="col-md-5 mb-4">
                      <div className="">
                        <input
                          className="form-control form-setting-trader-costom  input-info-bancaire"
                          type="text"
                          placeholder="IBAN"
                          {...register('iban')}
                        />
                      </div>
                      <FormError error={errors?.iban?.message} />
                    </div>
                  </div>
                  <div className="d-flex justify-content-end">
                    <button
                      className="btn btn-theme trans-0-2"
                      disabled={isLoading || loading ? true : false}
                      type="submit"
                    >
                      {isLoading || loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                            role="status"
                          ></span>
                          <span>En cours...</span>
                        </>
                      ) : (
                        'Enregistrer'
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="addBannerShop"
        aria-labelledby="addBannerShopLabel"
        aria-hidden="true"
      >
        <AddBannerShop />
      </div>
      <div
        className="modal fade"
        id="addLogoShop"
        aria-labelledby="addLogoShopLabel"
        aria-hidden="true"
      >
        <AddLogoShop />
      </div>
    </>
  )
}
export default SettingTrader
