import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { cleannerError, onOpen } from "../../../../utils/utils";
import { TNetworkFormData } from "../../../../utils/api/network/network.type";
import { useLocation } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";

const useRejoindreReseau = () => {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom du responsable"),
		prenom: yup.string().required().label("Prenom du responsable"),
		city: yup.string().required().label("Ville"),
		fonction: yup.string().required().label("Fonction"),
		country: yup.string().required().label("Pays"),
		region: yup.string().required().label("Région"),
		adress: yup.string().required().label("Adresse"),
		code_postal: yup.string().required().label("Code Postal"),
		siret: yup.string().required().label("Siret"),
		expertise_pro: yup.string().required().label("Description"),
		telephone: yup.string().required().label("Téléphone"),
	});
	const [pro, setPro] = useState<TNetworkFormData>();
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [isShowSocialInput, setIsShowSocialInput] = useState<boolean>(false);
	const [isValidDescription, setIsValidDescription] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>();

	const { search } = useLocation();
	useEffect(() => {
		if (search?.includes("status=success")) {
			Swal.fire({
				icon: "success",
				title: "Votre paiement a été effectué avec succès, vous recvrez une notification vous information de la validation de votre demande",
				iconColor: Color.success,
				showConfirmButton: true,
			});
		} else if (search?.includes("status=error")) {
			Swal.fire({
				icon: "error",
				title: "Erreur de paiement",
				showConfirmButton: true,
				timer: 2500,
			});
		}
	}, [search]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<TNetworkFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors, 6000);
	}, [errors]);

	const handleCountrySelect = (option: any) => {
		setCountry(option);
		setValue("country", option.name);
	};

	const handleStateSelect = (option: any) => {
		if (option.label) {
			setState(option);
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		if (option.label) {
			setCity(option);
			setValue("city", option.label);
		}
	};

	const handleShowSocialInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		if (e.target.checked === true) {
			setIsShowSocialInput(true);
			setValue("have_social", true);
		} else {
			setIsShowSocialInput(false);
			setValue("have_social", false);
		}
	};

	const handleChangeDescription = (data) => {
		setValue("expertise_pro", data);
		if (data.length >= 100) {
			setIsValidDescription(true);
		} else {
			setIsValidDescription(false);
		}
	};

	const onSubmit = async (data: TNetworkFormData) => {
		console.log(data);
		const { have_social, facebook, linkedin, instagram, twitter } = data;
		if (
			have_social === true &&
			facebook === "" &&
			instagram === "" &&
			linkedin === "" &&
			twitter === ""
		) {
			return setError("linkedin", {
				message: "Vous avez decidé de  renseignez au moins un réseau",
			});
		}

		onOpen("SouscriptionProviderModal");
		setPro({ ...data, payment_mode: 2, env: window.location.origin });
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		pro,
		country,
		state,
		city,
		handleCountrySelect,
		handleCitySelect,
		handleStateSelect,
		isShowSocialInput,
		handleShowSocialInput,
		handleChangeDescription,
		isValidDescription,
		phone,
		setPhone,
	};
};

export default useRejoindreReseau;
