import { BiArrowBack } from "react-icons/bi";
import { Link } from "react-router-dom";
import { IUser } from "../../../../redux/slice/User.slice";
import { getAvatar, useLocationState } from "../../../../utils/utils";
import { Badge, BadgePrestataire } from "../../../shared/Icons";
import "../SettingAdmin/SettingAdmin.css";

const UserInfo = () => {
	const { user, slug } = useLocationState<{ user: IUser; slug: string }>(undefined);

	const isShowBadgeSeller = user && (user?.profil === "seller" || user.is_seller === true) && (
		<Badge />
	);
	const isShowBadgePrestataire = user &&
		(user.profil === "provider" || user.is_provider === true) && <BadgePrestataire />;
	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container d-flex align-items-center">
					<Link
						to="/app/admin/annonces"
						style={{
							color: "#FFA73C",
							fontSize: "24px",
						}}
						state={slug}
					>
						<BiArrowBack />
					</Link>
					<h1 className="admin-page-title m-0">
						Utilisateurs/
						{`${user?.firstname} ${user?.lastname}`} {isShowBadgeSeller}{" "}
						{isShowBadgePrestataire}
					</h1>
				</div>
			</div>
			<div className="tab-content-admin mt-5">
				<div className="row">
					<div className="mb-4">
						<div className="profile-container flex-c">
							<img
								src={getAvatar(user?.avatar)}
								id="output"
								width="200"
								style={{ borderRadius: "50%" }}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<div className="position-relative">
							<label className="form-label label-form-admin">Prénom</label>
							<input
								type="text"
								className="form-control form-control-input-admin"
								placeholder="Prénom"
								value={user?.firstname}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<div className="position-relative">
							<label className="form-label label-form-admin">Nom</label>
							<input
								type="text"
								className="form-control form-control-input-admin"
								placeholder="Nom"
								value={user?.lastname}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<div className="position-relative">
							<label className="form-label label-form-admin">Email</label>
							<input
								type="email"
								className="form-control form-control-input-admin"
								placeholder="Email"
								value={user?.email}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<div className="position-relative">
							<label className="form-label label-form-admin">Téléphone</label>
							<input
								type="tel"
								className="form-control form-control-input-admin"
								placeholder="Téléphone"
								value={user?.phone}
								disabled={true}
							/>
						</div>
					</div>
					<div className="col-md-6 mb-3">
						<div className="position-relative">
							<label className="form-label label-form-admin">Adresse</label>
							<input
								type="tel"
								className="form-control form-control-input-admin"
								value={user?.adresse}
								disabled={true}
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserInfo;
