import React, { useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { ProductFormData } from "../../../../../utils/api/product/product.type";
import { useEditProductMutation } from "../../../../../utils/api/product/product.api";
import { cleannerError } from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";

function useEditProductFormAdmin() {

    const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Le nom du produit").nullable(),

		category: yup.string().required().label("La catégorie du produit").nullable(),

		subcategory: yup.string().required().label("La sous catégorie du produit").nullable(),
		description: yup.string().required().label("La description du produit").nullable(),
		price: yup.string().required().label("Le prix du produit").nullable(),
		quantity: yup.string().required().label("La quantité du produit").nullable(),

		background_image: yup.mixed().label("L'image de couverture").nullable(),

		images: yup.mixed().nullable(),

		modele: yup.string().label("Le modèle").nullable(),
		marque: yup.string().label("La marque").nullable(),
		weight: yup.string().label("Le poids").nullable(),
		dimensions: yup.string().label("La dimension").nullable(),
		main_color: yup.string().label("La couleur principale").nullable(),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		setError,
	} = useForm<ProductFormData | any>({
		resolver: yupResolver(validationSchema),
	});

    const [sendData, { isLoading, isSuccess, isError, error, data }] = useEditProductMutation();
	useEffect(() => {
		if (errors) {
			for (let key of Object.keys(errors)) {
				if (key) {
					window.scrollTo(10, 10);
				}
			}
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("success", data);
			Swal.fire({
				icon: "success",
				title: "Produit modifié avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/app/admin/produits", { replace: false });
			});
		}
	}, [isSuccess]);
	const onSubmit = async (formValues) => {
		console.log(formValues);

		let data = formValues;
		const fd = new FormData();

		for (let key of Object.keys(data)) {
			const val = data[key];
			if (key === "images") {
				val.forEach((item) => {
					fd.append(key, item);
				});
			} else {
				fd.append(key, val);
			}
		}

		await sendData({ slug: formValues?.slug, data: fd });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		setError,
	};
}

export default useEditProductFormAdmin