import React from "react";
import { ApiBaseUrl } from "../../../../utils/http";

const PreviewFileModal = ({ fileUrl }: { fileUrl: string }) => {
	return (
		<div
			className="modal"
			id="PreviewFileModal"
			data-bs-backdrop="static"
			data-bs-keyboard="false"
			aria-labelledby="staticBackdropLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-lg">
				<div className="modal-content mb-3">
					<div className="modal-header">
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="modal-body">
						<iframe
							src={ApiBaseUrl + fileUrl}
							title="description"
							className="w-100"
							height="600px"
						/>
					</div>
					<div className="modal-footer"></div>
				</div>
			</div>
		</div>
	);
};

export default PreviewFileModal;
