import React from 'react'
import ReactQuill from 'react-quill'
import { FormError } from '../../../common/CustomInputAdmin'
import CloseModalBtn from '../../../shared/CloseModalBtn'
import UseEditPaymentMode from '../../admin/SettingAdmin/requestSettings/UseEditPaymentMode'

function EditPaymentMode({ paymentMode }) {
  const {
    register,
    setValue,
    onSubmit,
    errors,
    isLoading,
    reset,
    isSuccess,
  } = UseEditPaymentMode()
  const [description, setDescription] = React.useState<any>('')
  const handleChange = (value: any) => {
    setDescription(value)
    setValue('description', value)
  }

  React.useEffect(() => {
    if (paymentMode?.id) {
      setValue('slug', paymentMode?.slug)
      setValue('name', paymentMode?.name)
      setValue('title', paymentMode?.title)
      setValue('description', paymentMode?.description)
      setDescription(paymentMode?.description)
    }
  }, [paymentMode])
  const handleReset = () => {
    reset()
    setDescription('')
  }
  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5 className="modal-title modal-title-custom">
            Modifier un moyen de paiement
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    placeholder="Nom"
                    {...register('title')}
                  />
                  <FormError error={errors?.title?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Type
                    <span className="text-danger">*</span>
                  </label>
                  <select
                    className="form-control form-control-input-admin"
                    id="name"
                    {...register('name')}
                  >
                    <option value="">Choisir un type</option>
                    <option value="espece">Paiement à la livraison</option>
                    <option value="paypal">Paypal</option>
                    <option value="paydunya">Paydunya</option>
                    <option value="paytech">Paytech</option>
                  </select>
                  <FormError error={errors?.name?.message} />
                </div>
              </div>
              <div className="col-md-12 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Description
                  </label>
                  <ReactQuill
                    className="editor-cgu"
                    value={description}
                    onChange={handleChange}
                  />
                  <FormError error={errors?.description?.message} />
                </div>
              </div>
            </div>
            <div className="text-center mb-4">
              <button
                className="btn btn-theme"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Modifier`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Chargement...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditPaymentMode
