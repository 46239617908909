import React from "react";
import { useGetMentionsLegalesQuery } from "../../../utils/api/conditions/condition.api";
import { createMarkup } from "../../../utils/utils";
import LoaderSuspense from "../../common/Loader";
import "./ConditionGenerale.css";

const MentionLegale = () => {
	const { data, isLoading } = useGetMentionsLegalesQuery();

	return (
		<>
			<div className="pb-5 text-center">
				<p className="titre-cgu">Mention légale</p>
			</div>
			{isLoading && <LoaderSuspense />}
			{!isLoading && (
				<div
					className="text-cgu"
					dangerouslySetInnerHTML={createMarkup(data?.text)}
				/>
			)}
		</>
	);
};

export default MentionLegale;
