import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCheckDouble } from "react-icons/fa";
import { useParams } from "react-router-dom";
import Swal from "sweetalert2";
import VolkenoSkeleton from "volkeno-react-skeleton";
import {
	useCommerceFromLocation,
	useLazyBoostCommerceQuery,
} from "../../../../../utils/api/commerce/commerce.api";
import { TBoostData } from "../../../../../utils/api/commerce/commerce.type";
import { appEnv } from "../../../../../utils/constantes";
import { formatMontant, formattedBoostDate } from "../../../../../utils/utils";
import BoostModal from "../../../modals/commercant/commandes/BoostModal";
import { SocialBoost, SocialBoostPromo, useOfferBoost } from "./Boost";
import "./Boost.css";

function BoostBoutique() {
	const { slug } = useParams();
	const [value, setValues] = useState<TBoostData>();
	const [item] = useCommerceFromLocation();
	const onSubmit = useBoostBoutique({ value });
	const { AppBoost, networkBoost, networkWithVideoBoost, isLoading } = useOfferBoost();

	return (
		<div className="article-card p-4">
			<div className="flex-sb mb-4">
				<div className="d-flex gap-4">
					<h3 className="article-title">Choisir une option</h3>
				</div>
			</div>
			<div className="container-page-boost">
				<div className="row">
					<div className="col-lg-4 col-md-6 mb-3">
						<div className="col-boost-content">
							<div className="content-boost-item">
								<div className="header-boost-content header-boost-content-color3">
									<p className="title-boost">
										Top des entreprises
									</p>
								</div>
								<div className="content-boost-text">
									{isLoading && (
										<VolkenoSkeleton
											variant="rect"
											width="100%"
											height={50}
										/>
									)}
									{AppBoost?.map((offer, i) => (
										<div
											key={offer?.slug}
											className="offer-boost-item"
										>
											<div className="content-price-boost">
												<p className="price-boost">
													{formatMontant(
														offer?.prix
													)}
												</p>
											</div>
											<div className="content-description-boost">
												<p className="description-boost">
													Chaque jour pendant{" "}
													<span
														style={{
															fontWeight: 700,
														}}
													>
														{offer?.duree}
													</span>{" "}
													jours
												</p>
											</div>
											<div className="container-btn-choise-boost">
												{!item?.is_boosted && (
													<div className="container-btn-choise-boost">
														<button
															className="btn btn-choice-boost"
															data-bs-toggle="modal"
															data-bs-target="#BoostModal"
															onClick={() => {
																setValues(
																	{
																		slug: slug,
																		duration: offer?.duree,
																		amount: offer?.prix,
																		endpoint: "boost-commerce",
																	}
																);
															}}
														>
															Choisir
														</button>
													</div>
												)}
											</div>
											{AppBoost?.length - 1 !== i && (
												<div className="b-b-1 mb-2"></div>
											)}
										</div>
									))}
									<div className="d-flex flex-column align-items-center mt-3">
										{item?.is_boosted && (
											<>
												<button className="badge-boost">
													<FaCheckDouble />
													Déjà boostée
												</button>
												<div className="mt-2 text-dark">
													<span className="me-2">
														expire dans
													</span>
													<span>
														{formattedBoostDate(
															item
																?.boost
																?.boost
																?.date_fin
														)}
													</span>
												</div>
											</>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					<SocialBoost
						item={item}
						setValue={setValues}
						url="socialboost-commerce"
						data={networkBoost}
						isLoading={isLoading}
					/>
					<SocialBoostPromo
						item={item}
						setValue={setValues}
						url="socialboost-commerce"
						data={networkWithVideoBoost}
						isLoading={isLoading}
					/>
				</div>
			</div>
			<div
				className="modal fade"
				id="BoostModal"
				aria-labelledby="BoostModalLabel"
				aria-hidden="true"
			>
				<BoostModal item={value} onSubmit={onSubmit} />
			</div>
		</div>
	);
}

function useBoostBoutique({ value }: { value?: TBoostData }) {
	const [boostProduct, { isSuccess, isError, data, error }] = useLazyBoostCommerceQuery();

	const onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const data = { ...value, env: appEnv };
		console.log(data);
		boostProduct(data);
	};

	// useEffect(() => {
	// 	if (isSuccess && data) {
	// 		window.location.href = data?.url;
	// 	}
	// }, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onSubmit;
}

export default BoostBoutique;
