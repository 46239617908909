import {
  Category,
  Subcategory,
} from "../../../../utils/api/category/category.type";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import "./AddUserModal.scss";
import useSubCategoryForm from "./forms/useSubCategoryForm";
type AddSubCategoryModalProps = {
  modalId: string;
  item?: Subcategory;
  category: Category;
};
const AddSubCategoryModal = ({
  modalId,
  category,
  item,
}: AddSubCategoryModalProps) => {
  const onHide = () => {
    document.getElementById(modalId)?.classList.remove("show");
    $(".modal-backdrop").remove();
    $(`#${modalId}`).hide();
    $(`body`).css({ overflow: "scroll" });
  };
  const { register, onSubmit, errors, isLoading, preview, handelImageChange } =
    useSubCategoryForm(category, onHide, item);
  return (
    <div
      className="modal fade"
      id={modalId}
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg">
        <div className="modal-content">
          <div className="modal-header">
            <h5
              className="modal-title modal-title-custom"
              id={`${modalId}Title`}
            >
              {item ? "Modifier" : "Ajouter"} une sous-catégorie
            </h5>
            <CloseModalBtn />
          </div>
          <div className="modal-body">
            <form onSubmit={onSubmit}>
              <div className="row auth-form-row">
                <div className="col-md-12 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="nom" aria-labelledby="nom">
                      Nom
                    </label>
                    <span className="text-danger">*</span>
                    <input
                      type="text"
                      className="form-control auth-form-control"
                      id="nom"
                      {...register("name")}
                    />
                    {errors.name?.message && (
                      <ErrorMessage message={errors.name.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="prenom" aria-labelledby="prenom">
                      Position (ordre d'affichage)
                    </label>
                    {/* <span className="text-danger">*</span> */}
                    <input
                      type="number"
                      className="form-control auth-form-control"
                      id="prenom"
                      {...register("position")}
                    />
                    {errors.position?.message && (
                      <ErrorMessage message={errors.position.message} />
                    )}
                  </div>
                </div>
                <div className="col-md-12 auth-input-col">
                  <div className="form-group auth-form-group">
                    <label htmlFor="image" aria-labelledby="prenom">
                      Image
                    </label>
                    <input
                      type="file"
                      className="form-control auth-form-control"
                      id="image"
                      onChange={(e) => handelImageChange(e)}
                      accept="image/*"
                    />
                    {errors.picture?.message && (
                      <ErrorMessage message={errors.picture.message} />
                    )}
                  </div>
                  {preview && (
                    <div className="row mb-2">
                      <div className="col-md-6">
                        <img
                          src={preview}
                          id="output"
                          width="100%"
                          height={"auto"}
                        />
                      </div>
                    </div>
                  )}
                </div>
                <div className="col-md-6 offset-md-3 auth-submit-btn-container">
                  {isLoading && (
                    <button
                      type="button"
                      className="btn btn-primary auth-submit-btn"
                    >
                      Chargement...
                    </button>
                  )}
                  {!isLoading && (
                    <button
                      type="submit"
                      className="btn btn-primary auth-submit-btn"
                    >
                      {item ? "Modifier" : "Ajouter"}
                    </button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSubCategoryModal;
