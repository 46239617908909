import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ApiBaseUrl } from '../../http'
import { QueryUrl } from '../../utils'
import { PaginationResults, UserTypeQuery } from '../commerce/commerce.type'
import { prepareHeaders } from '../user/user.api'
import { Delivery, DeliveryFormData } from './delivery.type'

const tags = {
  deliveryByVisitor: 'deliveryByVisitor',
  delivery: 'delivery',
}
export const DeliveryApi = createApi({
  reducerPath: 'deliveryApi',
  tagTypes: Object.values(tags),
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getDeliveryByVisitor: builder.query<Delivery[], void>({
      query: () => `deliverybyvisitor/`,
      providesTags: [tags.deliveryByVisitor],
    }),
    getDelivery: builder.query<PaginationResults<Delivery>, UserTypeQuery>({
      query: (query) => QueryUrl('/delivery/', query),
      providesTags: [tags.delivery],
    }),
    addDelivery: builder.mutation<Delivery, DeliveryFormData | any>({
      query: (data) => ({
        url: '/delivery/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [tags.delivery, tags.deliveryByVisitor],
    }),
    editDelivery: builder.mutation<Delivery, DeliveryFormData | any>({
      query: ({ slug, ...data }) => ({
        url: `/delivery/${slug}/`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: [tags.delivery, tags.deliveryByVisitor],
    }),
    deleteDelivery: builder.mutation<Delivery, string>({
      query: (slug) => ({
        url: `delivery/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [tags.delivery, tags.deliveryByVisitor],
    }),
  }),
})

export const {
  useGetDeliveryByVisitorQuery,
  useGetDeliveryQuery,
  useAddDeliveryMutation,
  useEditDeliveryMutation,
  useDeleteDeliveryMutation,
} = DeliveryApi
