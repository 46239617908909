import React, { InputHTMLAttributes } from "react";
import { FieldError } from "react-hook-form";
import ErrorMessage from "./ErrorMessage";
type InputPropsType = InputHTMLAttributes<HTMLInputElement> & {
	label: string;
	error?: string | FieldError;
	required?: boolean | string
};

export const CustomInputAdmin = React.forwardRef<HTMLInputElement, InputPropsType>((props, ref) => {
    const { name, error, label,required, placeholder, ...rest } = props;
    return (
        <>
			<div className="col-md-6 form-group mb-4">
				<label htmlFor="nombre_stock" className="label-form-admin" aria-labelledby={name}>
					{label}
					{required && <span className="text-danger">*</span>}
				</label>
				<input
					className="form-control form-control-input-admin"
					name={name}
					id={name}
					{...rest}
					ref={ref}
                    placeholder={placeholder}
				/>
				{<FormError error={error} />}
			</div>
		</>
    )
})

export function FormError({ error }: { error?: string | FieldError | any }) {
	const message = typeof error === "string" ? error : error ? error.message : error;
	if (!message) return null;

	return <ErrorMessage message={message} />;
}
