import React from "react";
import AboutListTable from "../../Tables/Admin/About/AboutListTable";

const AboutList = () => {
	return (
		<div>
			<AboutListTable />
		</div>
	);
};

export default AboutList;
