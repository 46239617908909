import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ApiBaseUrl } from '../../http'
import { QueryUrl } from '../../utils'
import { CommerceApi } from '../commerce/commerce.api'
import { PaginationResults, TypeQuery } from '../commerce/commerce.type'
import { prepareHeaders } from '../user/user.api'
import { SubscriberFormData, SubscriberType } from './subscriber.type'

const tags = {
  subscribtionsByCommerce: 'subscribtionsByCommerce',
}
export const SubscribtionApi = createApi({
  reducerPath: 'subscribtionAPi',
  tagTypes: Object.values(tags),
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSubscribtionByCommerce: builder.query<
      PaginationResults<SubscriberType> | any,
      TypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`subscriberbycommerce/${slug}/`, query),
      providesTags: [tags.subscribtionsByCommerce],
    }),
    addSubscribtion: builder.mutation<SubscriberType, SubscriberFormData | any>(
      {
        query: (data) => ({
          url: '/subscriber/',
          body: data,
          method: 'POST',
        }),
        invalidatesTags: [tags.subscribtionsByCommerce],
        onCacheEntryAdded(arg, { dispatch }) {
          dispatch(
            CommerceApi.util.invalidateTags(['commercesByVisitorBySlug']),
          )
        },
      },
    ),
    editSubscribtion: builder.mutation<
      SubscriberType,
      SubscriberFormData | any
    >({
      query: ({ slug, ...data }) => ({
        url: `/subscriber/${slug}/`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: [tags.subscribtionsByCommerce],
    }),
    deleteSubscribtion: builder.mutation<SubscriberType, string>({
      query: (slug) => ({
        url: `subscriber/${slug}`,
        method: 'DELETE',
      }),
      invalidatesTags: [tags.subscribtionsByCommerce],
      onCacheEntryAdded(arg, { dispatch }) {
        dispatch(CommerceApi.util.invalidateTags(['commercesByVisitorBySlug']))
      },
    }),
  }),
})

export const {
  useGetSubscribtionByCommerceQuery,
  useAddSubscribtionMutation,
  useDeleteSubscribtionMutation,
} = SubscribtionApi
