import { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { useAppSelector } from "../../../redux/hooks";
import { BtnSubmit } from "../../common/Button";
import { CustomInput } from "../../common/CustomInput";
import ErrorMessage from "../../common/ErrorMessage";
import useContactForm from "./useContactForm";
const categories = [
	{
		label: "Mise en relation",
		value: "mise_en_relation",
	},
	{
		label: "Plainte",
		value: "plainte",
	},
];
const ContactForm = () => {
	const [message, setMessage] = useState<any>("");
	const { register, onsubmit, errors, isLoading, setValue } = useContactForm(setMessage);
	const { user } = useAppSelector((s) => s?.user);
	useEffect(() => {
		if (user) {
			setValue("user", user?.id);
		}
	}, [user]);
	const handleChange = (value: any) => {
		setMessage(value);
		setValue("message", value);
	};

	return (
		<form onSubmit={onsubmit}>
			<div className="row mb-3">
				<CustomInput
					label="Prenoms et Nom"
					required
					id="name"
					type="text"
					{...register("name")}
					error={errors?.name?.message}
					defaultValue={user && user?.firstname + " " + user?.lastname}
				/>
				<CustomInput
					label="Email"
					required
					id="email"
					type="email"
					{...register("email")}
					error={errors?.email?.message}
					defaultValue={user && user?.email}
				/>
			</div>
			<div className="row mb-3">
				<div className="col-md-6 form-group mb-4">
					<label
						htmlFor="nombre_stock"
						className="custom-form-label"
						aria-labelledby={"category"}
					>
						Catégorie <span className="text-danger">*</span>
					</label>
					<select
						id={"category"}
						className="form-select espace-client-form-select-control"
						{...register("contact_type")}
					>
						{categories.map((option) => (
							<option value={option.value} key={option.value}>
								{option.label}
							</option>
						))}
					</select>

					{errors?.contact_type && (
						<ErrorMessage message={errors?.contact_type?.message} />
					)}
				</div>
				<CustomInput
					label="Sujet"
					required
					type="text"
					id="subject"
					{...register("subject")}
					error={errors?.subject?.message}
				/>
			</div>
			<div className="row mb-3">
				<div className="col-12">
					<label htmlFor="message" className="custom-form-label">
						Message
					</label>
					<ReactQuill
						className="editor-cgu"
						value={message}
						onChange={handleChange}
					/>
					{errors?.message && (
						<ErrorMessage message={errors?.message?.message} />
					)}
				</div>
			</div>
			<div className="col-md-6  offset-md-6 my-5">
				<BtnSubmit label="Envoyer" isLoading={isLoading} />
			</div>
		</form>
	);
};

export default ContactForm;
