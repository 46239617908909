import React, { useEffect } from 'react'
import { BsFillCameraFill } from 'react-icons/bs'
import './AddUserModal.scss'
import DefaultPp from '../../../assets/appImages/default-profil.png'
import CloseModalBtn from '../../../shared/CloseModalBtn'
import UseAddAdminUser from '../../admin/SettingAdmin/requestSettings/UseAddAdminUser'
import { FormError } from '../../../common/CustomInputAdmin'
import GoogleInput from '../../../common/GoogleInput'
import { getAvatar } from '../../../../utils/utils'
import UseEditUserAdmin from '../../admin/SettingAdmin/requestSettings/UseEditUserAdmin'
import { Authorisations } from '../../../../utils/constantes'

function EditAdminModal({ item }) {
  const {
    register,
    setValue,
    onSubmit,
    errors,
    isLoading,
    avatar,
    setAvatar,
    address,
    setAddress,
  } = UseEditUserAdmin()

  const handleChangeImage = (e) => {
    console.log('image', e.target.files[0])
    setAvatar(e.target.files[0])
    setValue('avatar', e.target.files[0])
  }

  useEffect(() => {
    if (item?.id) {
      setValue('slug', item?.slug)
      setValue('firstname', item?.firstname)
      setValue('lastname', item?.lastname)
      setValue('phone', item?.phone)
      setValue('email', item?.email)
      setValue('adresse', item?.adresse)
      setAddress(item?.adresse)
      setValue('dashboard', item?.dashboard)
      setValue('users', item?.users)
      setValue('orders', item?.orders)
      setValue('chat', item?.chat)
      setValue('help', item?.help)
      setValue('vendeurs', item?.vendeurs)
      setValue('products', item?.products)
      setValue('partners', item?.partners)
      setValue('networks', item?.networks)
      setValue('parameter', item?.parameter)
      setValue('sectors', item?.sectors)
      setValue('commerces', item?.commerces)
      setValue('categories', item?.categories)
    }
  }, [item])

  const handleReset = () => {
    setAvatar(null)
  }

  return (
    <div className="modal-dialog modal-dialog-centered modal-lg">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title modal-title-custom"
            id={`EditAdminModal${item?.slug}`}
          >
            Modifier un administrateur
          </h5>
          <CloseModalBtn onClick={() => handleReset()} />
        </div>
        <div className="modal-body">
          <form onSubmit={onSubmit}>
            <div className="mb-4">
              <div className="profile-pic">
                <label className="-label" htmlFor={`fileAdmin${item?.slug}`}>
                  <span className="glyphicon glyphicon-camera">
                    <BsFillCameraFill />
                  </span>
                  <span>Change Image</span>
                </label>
                <input
                  id={`fileAdmin${item?.slug}`}
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleChangeImage(e)}
                />
                <img
                  src={
                    avatar
                      ? URL.createObjectURL(avatar)
                      : getAvatar(item?.avatar)
                  }
                  id={`fileAdmin${item?.slug}`}
                  width="200"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Prénom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    placeholder="Prénom"
                    {...register('firstname')}
                  />
                  <FormError error={errors?.firstname?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Nom
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    placeholder="Nom"
                    {...register('lastname')}
                  />
                  <FormError error={errors?.lastname?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Email
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control form-control-input-admin"
                    placeholder="Email"
                    {...register('email')}
                  />
                  <FormError error={errors?.lastname?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Téléphone
                    <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control form-control-input-admin"
                    placeholder="Téléphone"
                    {...register('phone')}
                  />
                  <FormError error={errors?.phone?.message} />
                </div>
              </div>
              <div className="col-md-6 mb-3">
                <div>
                  <label className="form-label label-form-admin">
                    Adresse
                    <span className="text-danger">*</span>
                  </label>
                  <GoogleInput
                    value={address}
                    onChange={(value) => {
                      setAddress(value?.rue)
                      setValue('adresse', value?.rue)
                      setValue('latitude', value?.latitude)
                      setValue('longitude', value?.longitude)
                    }}
                    className="form-control form-control-input-admin"
                  />
                  <FormError error={errors?.phone?.message} />
                </div>
              </div>
              <div className="col-md-12">
                <label htmlFor="text" className="admin-form-label">
                  Permissions
                </label>
                <div className="row">
                  {Authorisations?.map((elem, i) => (
                    <div className="col-md-4 test" key={i}>
                      <div
                        className="custom-control custom-radio mb-3 d-flex justify-content-start align-items-baseline"
                        style={{ whiteSpace: 'normal' }}
                      >
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          {...register(elem.value)}
                          id={`customControlEdit${item?.slug}${i}`}
                        />
                        <label
                          className="custom-control-label-input-radio custom-control-label"
                          htmlFor={`customControlEdit${item?.slug}${i}`}
                        >
                          {elem?.label}
                        </label>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="text-center mb-4">
              <button
                className="btn btn-theme"
                type="submit"
                disabled={isLoading}
              >
                {!isLoading ? (
                  `Modifier`
                ) : (
                  <>
                    <span
                      className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                      role="status"
                    ></span>
                    <span>Chargement...</span>
                  </>
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default EditAdminModal
