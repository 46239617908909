import { CKEditor } from "ckeditor4-react";
import { BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import TitlePage from "../MyCustumerArea/TitlePage";
import UseDeposerAnnonceForm from "./UseAnnonceForm/UseDeposerAnnonceForm";
import Dropzone, { ImageGrid } from "../../../shared/MyDropzone";
import { useSector } from "./Annonce";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { TagsInput } from "react-tag-input-component";

export const typeAnnonces = [
	{
		label: "Recherche de profil",
		value: "recherche_profil",
	},
	{
		label: "Recherche de client",
		value: "recherche_client",
	},
	{
		label: "Recherche de partenaire",
		value: "recherche_partenaire",
	},
	{
		label: "Recherche de fournisseur",
		value: "recherche_fournisseur",
	},
];

const DeposerAnnonce = () => {
	// const { isCandidateToBePro, isLoad } = useCheckIfCurrentUserIsPro();
	const sectors = useSector();

	const {
		register,
		onSubmit,
		errors,
		setValue,
		isLoading,
		images,
		setImages,
		state,
		country,
		city,
		isValidDescription,
		handleChangeDescription,
		handleStateSelect,
		handleCountrySelect,
		handleCitySelect,
		selected,
		handleChangeTags,
	} = UseDeposerAnnonceForm();

	const props = {
		setValue,
		setImages,
	};

	return (
		<>
			<TitlePage label="Déposer une annonce" />
			<section className="content-page-container">
				<div className="container-munngano">
					{/* {!isLoad && !isCandidateToBePro && (
						<div className="py-5 text-justify">
							<div className="px-3">
								<div
									className="munganno-alert munganno-alert-info"
									role="alert"
								>
									<h4 style={{ lineHeight: "28px" }}>
										Vous essayez d'accéder à un espace privé
										et réservé seulement aux adhérents. Nous
										vous invitons à{" "}
										<Link
											to="/app/rejoindre-reseau"
											style={{
												color: Color?.themeColor,
											}}
										>
											rejoindre
										</Link>{" "}
										le réseau de Munngano-connectt pour
										accéder à cette fonctionnalité.
									</h4>
								</div>
							</div>
						</div>
					)}
					{!isLoad && isCandidateToBePro && !isCandidateToBePro.valid && (
						<div className="py-5">
							<AlertInfo message="Votre requête n'a pas été encore validée, s'il vous-plait revérifier plus tard!" />
						</div>
					)}
					{!isLoad && isCandidateToBePro && isCandidateToBePro?.valid && ( */}
					<div className="form-container px-3 pt-3 pt-md-5">
						<form data-testid="deposerAnnonceFormId" onSubmit={onSubmit}>
							<div className="row mb-4">
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="type_announcement"
										className="espace-client-form-label"
										aria-labelledby="type_announcement"
									>
										Type d'annonce
									</label>
									<span className="text-danger">*</span>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="type_announcement"
										data-testid="typeAnnouncementId"
										{...register("type_announcement")}
									>
										{typeAnnonces.map((item) => (
											<option value={item.value} key={item.value}>
												{item.label}
											</option>
										))}
									</select>
									{errors.type_announcement?.message && (
										<ErrorMessage message={errors.type_announcement.message} />
									)}
								</div>
								<div className="col-md-12 mb-4 form-group">
									<label
										htmlFor="titre_annonce"
										className="espace-client-form-label"
										aria-labelledby="titre_annonce"
									>
										Titre annonce
									</label>
									<span className="text-danger">*</span>
									<input
										type="text"
										className="form-control espace-client-form-control"
										placeholder="Titre annonce"
										id="titre_annonce"
										data-testid="TitreAnnonceId"
										{...register("title")}
									/>
									{errors.title?.message && (
										<ErrorMessage message={errors.title.message} />
									)}
								</div>
								<div className="col-md-12 mb-4" data-testid="imagesId">
									<Dropzone {...props} />
									<div className="row">
										<ImageGrid images={images} />
									</div>

									{errors.images?.message && (
										<ErrorMessage message={errors.images.message} />
									)}
								</div>
								<div
									className="col-md-12 mb-4 form-group"
									data-testid="descriptionAnnonceId"
								>
									<label
										htmlFor="desc-produit"
										className="espace-client-form-label"
										aria-labelledby="desc-produit"
									>
										Description de l’annonce
									</label>
									<span className="text-danger">*</span>
									<CKEditor initData={""} onChange={handleChangeDescription} />
									<small
										className={`${isValidDescription ? "text-success" : ""}`}
										style={{ fontWeight: "normal" }}
									>
										minimum 100 caractères
									</small>
									{errors.description?.message && (
										<ErrorMessage message={errors.description.message} />
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="secteur-activite"
										className="espace-client-form-label"
										aria-labelledby="secteur-activite"
									>
										Secteurs d’activité et Métiers
									</label>
									<span className="text-danger">*</span>
									<select
										className="select-form-custom form-select espace-client-form-control"
										id="secteur-activite"
										data-testid="secteurActiviteId"
										{...register("sector")}
									>
										{sectors?.map((sector) => (
											<option key={sector.slug} value={sector.id}>
												{sector.name}
											</option>
										))}
									</select>
									{errors.sector?.message && (
										<ErrorMessage message={errors.sector.message} />
									)}
								</div>
								<div className="col-md-12 mb-4">
									<label
										htmlFor="image-project"
										className="espace-client-form-label"
									>
										Tags
									</label>
									<TagsInput
										value={selected}
										onChange={handleChangeTags}
										name="tags"
										placeHolder="Entrer les tags"
									/>
									<em>Appuyer sur Entrer pour ajouter un tag</em>
									{errors.tags?.message && (
										<ErrorMessage message={errors.tags.message} />
									)}
								</div>
								<div className="col-md-12 form-group mb-4">
									<label
										htmlFor="experiences"
										className="espace-client-form-label"
										aria-labelledby="experiences"
									>
										Localisation
									</label>
									<span className="text-danger">*</span>
									<div className="row">
										<div className="col-md-4">
											<CountrySelector
												onChange={handleCountrySelect}
												name="country"
												placeholder="Pays"
												value={country}
												containerClass="tx country-select-container p-0"
											/>
											{errors.country?.message && (
												<ErrorMessage
													message={errors.country.message}
												/>
											)}
										</div>
										<div className="col-md-4">
											<StateSelector
												country={country}
												name="state"
												value={state}
												countryPlaceholder="Region"
												onChange={handleStateSelect}
												containerClass="tx country-select-container p-0"
											/>
											{errors.region?.message && (
												<ErrorMessage message={errors.region.message} />
											)}
										</div>
										<div className="col-md-4">
											<CitySelector
												state={state}
												name="city"
												value={city}
												statePlaceholder="Ville"
												onChange={handleCitySelect}
												containerClass="tx country-select-container p-0"
											/>
										</div>
										{errors.ville?.message && (
											<ErrorMessage message={errors.ville.message} />
										)}
									</div>
								</div>
								<div className="col-md-6 offset-md-6 mt-4">
									<BtnSubmit label="Ajouter" isLoading={isLoading} />
								</div>
							</div>
						</form>
					</div>
					{/* )} */}
				</div>
			</section>
		</>
	);
};

export default DeposerAnnonce;
