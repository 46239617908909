import { useEffect, useState } from "react";
import { useGetAnnoncementQuery } from "../../../../utils/api/annonce/annonce.api";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import { useLocationState } from "../../../../utils/utils";

const useAnnoncesList = () => {
	const [perPage, setPerPage] = useState<number>(12);
	const [page, setPage] = useState<number>(1);
	const [item, setItem] = useState<IAnnonce>();
	const [isShowModal, setIsShowModal] = useState<boolean>(false);

	const { data, isLoading } = useGetAnnoncementQuery({
		page,
		limit: perPage,
	});
	const slug = useLocationState<string>(undefined);
	useEffect(() => {
		if (slug) {
			const item = data?.results?.find((item) => item?.slug === slug);
			!!item && openModal(item);
		}
	}, [slug]);

	const toggle = () => setIsShowModal((isShowModal) => !isShowModal);
	const openModal = (item: IAnnonce) => {
		setItem(item);
		toggle();
	};
	return {
		data,
		slug,
		item,
		isLoading,
		page,
		perPage,
		setPage,
		setPerPage,
		isShowModal,
		openModal,
		closeModal: toggle,
		setItem,
	};
};

export default useAnnoncesList;
