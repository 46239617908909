import { useEffect, useState } from 'react'
import { useLocation, useRoutes } from 'react-router-dom'
import 'volkeno-react-country-state-city/dist/index.css'
import './App.css'

import { ClearBrowserCacheBoundary } from 'react-clear-browser-cache'
import 'volkeno-react-skeleton/dist/index.css'
import { useAppDispatch, useAppSelector } from './redux/hooks'
import { onSetBudgets } from './redux/slice/budget.slice'
import { getCartItems } from './redux/slice/cart.slice'
import { onSetSectors } from './redux/slice/sector.slice'
import { addCommerce } from './redux/slice/shop.slice'
import { onSetUser } from './redux/slice/User.slice'
import AppRoutes, { isAdminUser, isSeller } from './routes/router'
import { useLazyGetBudgetByUserQuery } from './utils/api/budget/budget.api'
import { IBudget } from './utils/api/budget/budget.type'
import { useGetCommerceByUserQuery } from './utils/api/commerce/commerce.api'
import { useLazyGetSectorQuery } from './utils/api/sector/sector.api'
import { Tsector } from './utils/api/sector/sector.type'
import { useMeAdminQuery, useMeQuery } from './utils/api/user/user.api'
import { useChatListener } from './utils/pusher'
import LoaderSuspense from './component/common/Loader'

function App() {
  const isConnected = useAppSelector((s) => !!s.user?.user?.id)
  const is_seller = useAppSelector((s) => isSeller(s.user?.user))
  const is_admin = useAppSelector((s) => isAdminUser(s.user?.user))
  const routes = useRoutes(AppRoutes)
  const dispatch = useAppDispatch()
  useEffect(() => {
		// console.log('isCOnn', !!isConnected, is_admin)
		dispatch(getCartItems());
  }, [isConnected, is_admin])
  return (
    <div className="mungano-app">
      {!!isConnected && is_seller && <FetchUserSeller />}
      {!!isConnected && <FetchBudget />}
      {!!isConnected && <FetchSector />}
      {!!isConnected && is_admin && <FetchUserAdmin />}
      {isConnected && <FetchChat />}
      <ScrollToTop />
      <ClearBrowserCacheBoundary
        auto
        fallback={<LoaderSuspense />}
        duration={60000}
      >
        {routes}
      </ClearBrowserCacheBoundary>
    </div>
  )
}
function ScrollToTop() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname, location.state])
  return null
}

export default App

function FetchChat() {
  useChatListener()
  return null
}

function FetchUserAdmin() {
  const { isSuccess, data, error, isError } = useMeAdminQuery()
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (data && isSuccess) {
      // console.log('admin', data)
      dispatch(onSetUser(data))
    }

    if (isError) {
      console.log('admin error', error)
    }
  }, [isSuccess, data, isError])

  return null
}

function FetchUserSeller() {
  const { data: commerces, isSuccess: success } = useGetCommerceByUserQuery()
  const commerce = useAppSelector<any>((s) => s.commerce)
  const { isSuccess, data } = useMeQuery()
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (data && isSuccess) {
      dispatch(onSetUser(data))
    }
  }, [isSuccess, data])

  useEffect(() => {
    if (commerces?.length > 0 && !commerce?.id) {
      dispatch(
        addCommerce(
          commerces
            ?.slice()
            ?.sort((a, b) => parseInt(a?.id) - parseInt(b?.id))[0],
        ),
      )
    }
  }, [commerces])

  return null
}

function FetchBudget() {
  const [budgets, setBudgets] = useState<IBudget[]>([])
  const dispatch = useAppDispatch()
  const [getBudgetByUser, { data }] = useLazyGetBudgetByUserQuery()
  useEffect(() => {
    if (!budgets.length) {
      getBudgetByUser()
    }
  }, [budgets])
  useEffect(() => {
    if (data) {
      setBudgets(data)
    }
  }, [data])
  useEffect(() => {
    if (budgets?.length) {
      dispatch(onSetBudgets(budgets))
    }
  })
  return null
}

function FetchSector() {
  const [sectors, setSectors] = useState<Tsector[]>([])
  const [getSector, { data }] = useLazyGetSectorQuery()
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (!sectors.length) {
      getSector()
    }
  }, [sectors])
  useEffect(() => {
    if (data) {
      setSectors(data)
    }
  }, [data])
  useEffect(() => {
    if (sectors?.length) {
      dispatch(onSetSectors(sectors))
    }
  }, [sectors])
  return null
}
