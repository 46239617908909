import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { useGetSectorAdminQuery } from "../../../../../utils/api/sector/sector.api";
import { Tsector } from "../../../../../utils/api/sector/sector.type";
import Pagination from "../../../../common/Pagination";
import AddSectorModal from "../../../modals/adminModal/AddSectorModal";
import { useDeleteSector } from "../../../modals/adminModal/forms/useSectorForm";
import SectorTableSkelaton from "./SectorTableSkelaton";

function SectorTable({ word }: { word: string }) {
	const [page, setPage] = React.useState(1);
	const { data = { results: [] }, isLoading } = useGetSectorAdminQuery({
		page,
		word,
	});
	useEffect(() => setPage(1), [word]);

	const actionFormatter: any = (cell, row: Tsector) => {
		const modalId = `UpdateSector${row.id}`;
		return (
			<div className="gap-2 d-flex">
				<AddSectorModal modalId={modalId} item={row} />
				<div>
					<button
						data-bs-toggle="modal"
						data-bs-target={`#${modalId}`}
						className="btn btn-action-user-admin"
						title="Editer"
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteSectorButton item={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "name",
			text: "Nom",
			style: { textAlign: "left" },
		},
		{
			dataField: "actions",
			text: "Actions",
			formatter: (cell: any, row: Tsector) => actionFormatter(cell, row),
			style: { textAlign: "center" },
		},
	];

	return (
		<>
			{isLoading && <SectorTableSkelaton />}
			{!isLoading && (
				<BootstrapTable
					keyField="slug"
					data={data.results}
					columns={columns}
					bordered={true}
					condensed={false}
					responsive={true}
					wrapperClasses="table-responsive admin-table"
				/>
			)}
			<Pagination onPageChange={setPage} page={page} total={data.count} />
		</>
	);
}

export default SectorTable;

function DeleteSectorButton({ item }: { item: Tsector }) {
	const onDelete = useDeleteSector(item);
	return (
		<button onClick={onDelete} className="btn btn-action-user-admin" title="Supprimer">
			<RiDeleteBin7Fill />
		</button>
	);
}
