import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { TImage } from "../../type";
import { AnnonceApi } from "../annonce/annonce.api";
import { prepareHeaders } from "../user/user.api";

export const ImageApi = createApi({
	tagTypes: ["image", "findAnnonceBySlug"],
	reducerPath: "imageApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		deleteImage: builder.mutation<TImage, string>({
			query: (slug) => ({
				url: `image/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["image", "findAnnonceBySlug"],
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(
					AnnonceApi.util.invalidateTags([
						"annonce",
						"getAnnonceByUser",
						"findAnnonceBySlug",
					])
				);
			},
		}),
	}),
});

export const { useDeleteImageMutation } = ImageApi;
