import moment from 'moment'
import { useState } from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { FiEye } from 'react-icons/fi'
import { MdDeleteForever } from 'react-icons/md'
import { RiDeleteBin7Fill, RiEdit2Fill } from 'react-icons/ri'
import { NavLink } from 'react-router-dom'
import Swal from 'sweetalert2'
import {
  useActiveProductMutation,
  useDeleteProductMutation,
  useProductByFilterQuery,
} from '../../../../../utils/api/product/product.api'
import { Color } from '../../../../../utils/theme'
import Pagination from '../../../../common/Pagination'
import ProductListAdminTableSkeleton from './ProductListAdminTableSkeleton'

function ProductListAdminTable({ word }: { word: string }) {
  const [page, setPage] = useState(1)
  const [perPage, setPerPage] = useState(10)
  const { data = { results: [] }, isLoading } = useProductByFilterQuery({
    page,
    limit: perPage,
    word,
  })
  const [
    editProject,
    { isSuccess, error, isError },
  ] = useActiveProductMutation()
  // const [deleteProduct, { isSuccess, isError }] = useDeleteProductMutation()

  const priceFormatter = (cell: any, row: any) => {
    return <span>{`${cell} FCFA`}</span>
  }
  const dateFormatter = (cell: any, row: any) => {
    return <span>{moment(cell).format('YYYY-MM-DD')}</span>
  }
  const statusFormatter = (cell: any, row: any) => {
    if (cell === 'En rupture de stock') {
      return <span className="status-user-admin-complet">{cell}</span>
    }
    if (cell === 'attente') {
      return <span className="status-user-admin-en-attente">{cell}</span>
    }
    if (cell === 'Retourné') {
      return <span className="status-user-admin-rejete">{cell}</span>
    }
  }

  const onArchive = async (item) => {
    let data = {
      online: item?.is_archive,
      slug: item?.slug,
      is_archive: !item?.is_archive,
    }
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        item?.is_archive === true ? 'désarchiver' : 'archiver'
      } ce produit?`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'OUI',
      cancelButtonText: 'NON',
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return editProject(data)
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, 'archiver')
      if (result?.value?.data) {
        Swal.fire({
          icon: 'success',
          title: `Produit ${
            result?.value?.data?.is_archive ? 'archivé' : 'désarchivé'
          } avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        })
      }
    })
  }

  const actionFormatter: any = (cell, row) => {
    return (
		<div className="table-actions-btn-container-commandes gap-2 d-flex">
			<NavLink
				title="Voir"
				state={row}
				to={`/app/admin/produits/${row?.slug}`}
				className="btn btn-action-user-admin"
			>
				<FiEye />
			</NavLink>
			<div>
				<NavLink
					to="/app/admin/modifier-produits"
					className="btn btn-action-user-admin"
					data-testid="btnId"
					state={row}
					title="Editer"
				>
					<RiEdit2Fill />
				</NavLink>
			</div>
			<div>
				<button
					className="btn btn-action-user-admin"
					title={row?.is_archive ? "Désarchiver" : "Archiver"}
					onClick={() => onArchive(row)}
				>
					{row?.is_archive ? <MdDeleteForever /> : <RiDeleteBin7Fill />}
				</button>
			</div>
		</div>
    );
  }
  const columns = [
    {
      dataField: 'code',
      text: 'N°',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'name',
      text: ' Noms',
      style: { textAlign: 'left' },
    },
    {
      dataField: 'created_at',
      text: 'Date d’ajout',
      formatter: (cell: any, row: any) => dateFormatter(cell, row),
    },
    {
      dataField: `commerce.name`,
      text: 'Boutiques',
    },
    {
      dataField: 'price',
      text: 'Prix',

      formatter: (cell: any, row: any) => priceFormatter(cell, row),
    },
    ,
    {
      dataField: 'statut',
      text: 'Status',
      formatter: (cell: any, row: any) => statusFormatter(cell, row),
    },
    {
      dataField: 'actions',
      text: 'Actions',
      formatter: (cell: any, row: any) => actionFormatter(cell, row),
    },
  ]

  return (
		<>
			{isLoading && <ProductListAdminTableSkeleton />}
			{!isLoading && (
				<>
					<h3 className="total-items">{data?.count} résultats</h3>
					<BootstrapTable
						keyField="id"
						data={data.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						noDataIndication={() => "Pas de résultats"}
						wrapperClasses="table-responsive admin-table"
					/>
				</>
			)}
			{/* <Pagination page={page} onPageChange={setPage} total={data.count} /> */}
			<CustomPagination
				nbPages={data.count}
				page={page}
				onChange={(page, perPage) => {
					setPerPage(perPage);
					setPage(page);
				}}
				perPage={perPage}
			/>
		</>
  );
}

export default ProductListAdminTable
type CustomPaginationProps = {
  nbPages: number | any
  page: number
  onChange: (page: number, perPage: number) => any
  perPage: number
}
export function CustomPagination({
  nbPages,
  page,
  perPage,
  onChange,
}: CustomPaginationProps) {
  if (parseInt(nbPages) <= 0) return null
  return (
    <div className="d-flex align-items-baseline justify-content-between">
      <div className="footer-form d-flex mt-3">
        <select
          className="form-control select-perpage"
          name="limit"
          id="limit"
          value={perPage}
          onChange={(e) => {
            onChange(1, parseInt(e.target.value, 10))
          }}
        >
          <option value="10">10</option>
          <option value="30">30</option>
          <option value="50">50</option>
          <option value="100">100</option>
        </select>
      </div>

      {parseInt(nbPages) > 0 ? (
        <Pagination
          page={page}
          total={nbPages}
          perPage={perPage}
          onPageChange={(page: number) => onChange(page, perPage)}
        />
      ) : null}
    </div>
  )
}
