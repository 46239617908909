import CloseModalBtn from "../../../shared/CloseModalBtn";
import { CKEditor } from "ckeditor4-react";
import "../../admin/MessageAdmin/MessageAdmin.css";
import useHelpForm from "./forms/useHelpForm";
import ErrorMessage from "../../../common/ErrorMessage";
const AddQuestionModal = () => {
	const { register, onsubmit, errors, setValue, isLoading } = useHelpForm();

	return (
		<div
			className="modal fade"
			id="AddQuestionModal"
			aria-labelledby={`AddQuestionModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content py-2">
					<div className="munngano-modal-header d-flex justify-content-between align-items-center px-3">
						<h1 className="munngano-modal-title">Ajouter une question</h1>
						<CloseModalBtn />
					</div>
					<div className="form-container px-3">
						<form onSubmit={onsubmit}>
							<div className="row mb-4">
								<div className="col-md-12 mb-3">
									<div>
										<label className="form-label label-form-admin">
											Titre
										</label>
										<input
											type="text"
											className="form-control form-control-input-admin"
											placeholder="Titre"
											{...register("title")}
										/>
										{errors?.title && (
											<ErrorMessage
												message={
													errors.title.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12 form-group">
									<label
										htmlFor="desc-mision"
										className="form-label label-form-admin"
									>
										Réponse
									</label>
									<CKEditor
										initData={""}
										onChange={(e) => {
											setValue(
												"content",
												e.editor.getData()
											);
										}}
									/>
									{errors?.content && (
										<ErrorMessage
											message={errors.content.message}
										/>
									)}
								</div>
							</div>
							<div className="btn-container gap-3 justify-content-end d-flex">
								<button className="admin-btn-cancel admin-btn-typo">
									Annuler
								</button>
								<button className="admin-btn-add admin-btn-typo">
									{isLoading ? (
										<>
											<i className="fas fa-spin fa-spinner"></i>
											"Chargement..."
										</>
									) : (
										"Ajouter"
									)}
								</button>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddQuestionModal;
