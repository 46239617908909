import { useGetPolitiqueConfidentialteQuery } from "../../../utils/api/conditions/condition.api";
import { createMarkup } from "../../../utils/utils";
import LoaderSuspense from "../../common/Loader";

const PolitiqueConfidentialite = () => {
	const { data, isLoading } = useGetPolitiqueConfidentialteQuery();
	return (
		<>
			<div className="pb-5 text-center">
				<p className="titre-cgu">Politique de confidentialités</p>
			</div>
			{isLoading && <LoaderSuspense />}
			{!isLoading && (
				<div
					className="text-cgu"
					dangerouslySetInnerHTML={createMarkup(data?.text)}
				/>
			)}
		</>
	);
};

export default PolitiqueConfidentialite;
