import React, { useState } from "react";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import "./Login.css";
// import ImgAuth from '../../assets/appImages/auth.png'
import { Link, NavLink } from "react-router-dom";
import useLoginForm from "./useAuthForm/useLoginForm";
import ErrorMessage from "../../common/ErrorMessage";
import { BtnSubmit } from "../../common/Button";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useShowPassword } from "../../../utils/utils";

interface FormProps {
	register: any;
	errors: any;
	onSubmit: any;
	isLoading: any;
}

export const LoginForm: React.FC<FormProps> = ({ onSubmit, errors, register, isLoading }) => {
	const {isShowPassword, toggleShowPassword} = useShowPassword()
	return (
		<form id="auth-form" onSubmit={onSubmit}>
			<div className="row auth-form-row">
				<div className="col-md-12 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="email-or-username">Email</label>
						<input
							type={`email`}
							className="form-control auth-form-control"
							id="email-or-username"
							{...register("email")}
							data-testid="emailId"
						/>
						{errors.email?.message && <ErrorMessage message={errors.email.message} />}
					</div>
				</div>
				<div className="col-md-12 auth-input-col">
					<div className="form-group auth-form-group">
						<label htmlFor="password">Mot de passe</label>
						<div className="position-relative">
							<input
								type={`${isShowPassword ? "text" : "password"}`}
								className="form-control auth-form-control"
								id="password"
								{...register("password")}
								data-testid="passwordId"
							/>
							<span
								className="btn-toggle-password"
								role="button"
								onClick={toggleShowPassword}
							>
								{isShowPassword ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
							</span>
						</div>
						{errors.password?.message && <ErrorMessage message={errors.password.message} />}
					</div>
				</div>
				<div className="col-md-4 offset-md-8 d-flex justify-content-end mb-3">
					<Link to={"/app/recuperer-mot-de-passe"} className="forget-password-link">
						Mot de passe oublié ?
					</Link>
				</div>
				<div className="col-md-6 offset-md-6 auth-submit-btn-container">
					<BtnSubmit label="Connexion" isLoading={isLoading} />
				</div>
			</div>
		</form>
	);
};

const Login = () => {
	const { register, onSubmit, errors, isLoading } = useLoginForm();
	return (
		<div className="auth-component">
			<FrontHeader />
			<div className="fixed-after-header" style={{ minHeight: "45rem" }}>
				<div className="auth-container">
					<div className="auth-row row">
						<div className="col-md-4 auth-col auth-left-side-col no-view-l-mobile">
							<div className="auth-left-side-container"></div>
						</div>
						<div className="col-md-8 auth-col auth-right-side-col">
							<div className="auth-right-side-container">
								<div className="auth-form-container">
									<h1 className="auth-form-title">Connectez-vous</h1>
									<LoginForm
										register={register}
										onSubmit={onSubmit}
										errors={errors}
										isLoading={isLoading}
									/>
									<div className="d-flex justify-content-end align-items-center mt-4">
										<p className="text-inscription mb-0">
											Vous n’avez pas de compte?
										</p>
										<NavLink
											to="/app/inscription"
											className="btn-inscription-color ps-2"
										>
											Inscrivez-vous
										</NavLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Login;
