import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, createUrl, onHide } from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";
import { ISlide, SlideEditFormData } from "../../../../../utils/api/slide/slide.type";
import { useEditSlideMutation } from "../../../../../utils/api/slide/slide.api";

function useEditSlide(item?: ISlide) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Le nom"),
		fonction: yup.string().required().label("La fonction"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<SlideEditFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [image, setImage] = useState<string>("");
	const [editData, { isLoading, isSuccess, isError, error, data, reset }] =
		useEditSlideMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (item) {
			setValue("nom", item?.nom);
			setValue("fonction", item?.fonction);
		}
	}, [item]);

	const handleChangeImage = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			setImage(createUrl(e.currentTarget.files[0]));
			setValue("image_couverture", e.currentTarget.files[0]);
		}
	};

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Slide modifiée  avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(async () => {
				reset();
				onHide("EditSlideModal");
			});
		}
	}, [isSuccess]);
	const onSubmit = async (formValues: SlideEditFormData) => {
		console.log(formValues);
		const fd = new FormData();
		for (let key of Object.keys(formValues)) {
			const val = formValues[key];
			fd.append(key, val);
		}
		await editData({ slug: item?.slug, data: fd });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		isLoading,
		reset,
		image,
		handleChangeImage,
	};
}
export default useEditSlide;
