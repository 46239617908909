import React from 'react'
import { Color } from '../../../../../../utils/theme'
import { BtnBack, BtnNext } from '../../../../../common/Button'
import { CustomInput, FormError } from '../../../../../common/CustomInput'
import { CustomSelectInput } from '../../../../../common/CustomSelectInput'
import { Profil } from '../../../BecomeTrader/Steps/AddInfoCompany'
import {
  CountrySelector,
  StateSelector,
  CitySelector,
} from 'volkeno-react-country-state-city'
import GoogleInput from '../../../../../common/GoogleInput'

function CompanyInfos({
  navigation,
  register,
  formState,
  setValue,
  setAdress,
  adress,
  profil,
  setProfil,
  options,
  cityLabel,
  dataForm,
  country,
  setCountry,
  state,
  setState,
  city,
  setCity,
  countryLabel,
  stateLabel,
}) {
  const handleCountrySelect = (option: any) => {
    setCountry(option)
    console.log('country', option)
    setValue('country', option.name)
  }

  const handleStateSelect = (option: any) => {
    setValue('region', option.name)
    setState(option)
  }

  const handleCitySelect = (option: any) => {
    setValue('city', option.name)
    setCity(option)
  }

  React.useEffect(() => {
    if (country?.name) {
      setValue('country', country?.name)
    }

    if (state?.name) {
      setValue('region', state?.name)
    }

    if (city?.name) {
      setValue('city', city?.name)
    }
  }, [country, state, city])
  return (
    <div>
      <h4 className="stepper-title">Plus de détails</h4>
      <div>
        <div className="row">
          <CustomSelectInput
            label="Personne physique ou Personne morale"
            name="option"
            value={profil}
            required
            onChange={(e) => {
              console.log('value', e.target.value)
              setValue('profil', e.target.value)
              setProfil(e.target.value)
              if (e.target.value === 'personne_morale') {
                setValue('isMoral', true)
              } else {
                setValue('isMoral', false)
              }
            }}
            options={Profil}
            error={formState?.errors?.profil?.message}
          />
          <CustomInput
            label="Nom légale / Raison sociale"
            name="legale-name"
            type="text"
            required={profil === 'personne_morale' ? true : false}
            {...register('legal_form')}
            error={formState?.errors?.legal_form?.message}
          />
          <CustomInput
            label="NINEA"
            name="ninea"
            type="text"
            required={profil === 'personne_morale' ? true : false}
            {...register('ninea')}
            error={formState?.errors?.ninea?.message}
          />
          <CustomInput
            label="Quel est le nom du / des compte (s) ?"
            name="account-name"
            type="text"
            {...register('account_name')}
            error={formState?.errors?.account_name?.message}
          />
          <CustomInput
            label="Forme juridique"
            name="forme-juridique"
            type="text"
            {...register('legal_form')}
            error={formState?.errors?.legal_form?.message}
          />
          <div className="col-md-6 form-group mb-4">
            <label htmlFor="location" className="custom-form-label">
              Adresse
              <span className="text-danger">*</span>
            </label>
            <GoogleInput
              value={adress || ''}
              onChange={(value) => {
                setAdress(value.rue)
                setValue('location', value?.rue)
                setValue('longitude', value?.longitude)
                setValue('latitude', value?.latitude)
              }}
              className="form-control espace-client-form-control"
            />
            {<FormError error={formState?.errors?.location?.message} />}
          </div>
          <div className="col-md-6 form-group mb-4">
            <label htmlFor="pays" className="custom-form-label">
              Pays
              <span className="text-danger">*</span>
            </label>
            <CountrySelector
              onChange={handleCountrySelect}
              name="country"
              placeholder={countryLabel || 'Choisir un pays'}
              value={country}
              containerClass="tx country-select-container my-0 p-0"
            />
            {<FormError error={formState?.errors?.country?.message} />}
          </div>
          <div className="col-md-6 form-group mb-4">
            <label htmlFor="state" className="custom-form-label">
              Région
              <span className="text-danger">*</span>
            </label>
            <StateSelector
              onChange={handleStateSelect}
              name="state"
              country={country}
              placeholder={stateLabel || 'Choisir une région'}
              countryPlaceholder={stateLabel || 'Choisir un pays'}
              value={state}
              containerClass="tx country-select-container my-0 p-0"
            />
            {<FormError error={formState?.errors?.region?.message} />}
          </div>
          <div className="col-md-6 form-group mb-4">
            <label htmlFor="state" className="custom-form-label">
              Ville
              <span className="text-danger">*</span>
            </label>
            <CitySelector
              onChange={handleCitySelect}
              name="city"
              state={state}
              placeholder={cityLabel || 'Choisir une ville'}
              statePlaceholder={cityLabel || 'Choisir une région'}
              value={city}
              containerClass="tx country-select-container my-0 p-0"
            />
            {<FormError error={formState?.errors?.city?.message} />}
          </div>
          <CustomInput
            label="Code postale"
            name="postal_card"
            type="text"
            {...register('postal_card')}
            error={formState?.errors?.postal_card?.message}
          />
        </div>
        <div className="flex-r gap-3 mt-4">
          <BtnBack label="Retour" type="button" navigation={navigation} />
          <BtnNext label="Suivant" type="submit" />
        </div>
      </div>
    </div>
  )
}

export default CompanyInfos
