import avatar from '../../../../assets/appImages/avatar.png'
import { HiOutlineReply } from 'react-icons/hi'
import {
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
} from 'react-icons/bs'
import FormAddCommentProduct from '../FormAddCommentProduct'
import { useEffect, useState } from 'react'
import { EmptyMessage } from '../../../../../utils/EmptyMessage'
import moment from 'moment'
import StarRatingComponent from 'react-star-rating-component'
import { createMarkup, getImage } from '../../../../../utils/utils'
import { useAppSelector } from '../../../../../redux/hooks'

function AvisMarket({ product }) {
  const [itemToShow, setItemToShow] = useState(4)
  const { user, token } = useAppSelector((s) => s?.user)
  const fetchMoreData = (e) => {
    e.preventDefault()
    if (itemToShow >= product?.rates?.length) {
      setItemToShow(4)
      return
    } else {
      setItemToShow(itemToShow + 4)
    }
  }
  useEffect(() => {
    if (product?.id) {
      console.log(product)
    }
  }, [product])

  const isAuthor = (item) => {
    return (
      user?.id === item?.commerce?.author ||
      user?.id === item?.commerce?.author?.id
    )
  }
  return (
    <div>
      <div className="avis-component">
        {product?.rates?.length ? (
          product?.rates
            ?.slice(0, itemToShow)
            ?.map((avis, index) => <AvisItem avis={avis} key={avis?.id} />)
        ) : (
          <div className="avis-item mb-4">
            {EmptyMessage('Aucun avis disponible pour le moment')}
          </div>
        )}
        <div className="flex-c">
          {product?.rates?.length && product?.rates?.length > 4 ? (
            product?.rates?.length > itemToShow ? (
              <button
                className="btn-voir-plus no-style-btn"
                onClick={(e) => fetchMoreData(e)}
              >
                <BsFillArrowDownCircleFill style={{ fontSize: 16 }} />
                <span>Voir plus d’avis</span>
              </button>
            ) : (
              <button
                className="btn-voir-plus no-style-btn"
                onClick={(e) => fetchMoreData(e)}
              >
                <BsFillArrowUpCircleFill style={{ fontSize: 16 }} />
                <span>Voir moins d’avis</span>
              </button>
            )
          ) : null}
        </div>
      </div>
      {token && !isAuthor(product) ? (
        <FormAddCommentProduct product={product} />
      ) : null}
    </div>
  )
}

export function AvisItem({ avis, key }) {
  return (
		<div className="avis-item mb-4" key={key}>
			<div className="d-flex gap-2 gap-md-3">
				<div className="avis-avatar-container">
					<img
						src={getImage(avis?.user?.avatar)}
						height="50"
						width="50"
						style={{ borderRadius: "50%" }}
						alt="avatar"
					/>
				</div>
				<div className="flex-sb flex-fill">
					<div className="user-infos">
						<h4 className="avis-user-name">
							{avis?.user?.firstname || avis?.user?.lastname
								? avis?.user?.firstname + " " + avis?.user?.lastname
								: "Anonyme"}
						</h4>
						<h6 className="avis-created">
							{" "}
							{moment(avis?.created_at)?.format("DD/MM/YYYY")}
						</h6>
					</div>
					{/* <button className="m-btn-reply no-style-btn">
            <HiOutlineReply style={{ marginRight: 7 }} />
            <span className="d-none d-md-inline">Répondre</span>
          </button> */}
				</div>
			</div>
			<div className="avis-container mt-2">
				<p className="avis-text"></p>
				<div>
					<StarRatingComponent
						name="rate2"
						editing={false}
						starCount={5}
						value={avis?.rating}
						starColor="#FFA73C"
						emptyStarColor="rgba(180, 180, 180, 0.8)"
					/>
				</div>
				<p
					className="avis-text"
					dangerouslySetInnerHTML={createMarkup(avis?.comment)}
				/>
			</div>
		</div>
  );
}

export default AvisMarket
