import React, { useState, useEffect } from "react";
import "./PartenaireAdmin.css";
import "../SellerListAdmin/SellerListAdmin.css";
import { CKEditor } from "ckeditor4-react";
import useEditPartenaire from "./UseFormPartenaireAdmin/useEditPartenaire";
import ErrorMessage from "../../../common/ErrorMessage";
import { BtnSubmit } from "../../../common/Button";
import { getImage } from "../../../../utils/utils";
import { useLocationState } from "../../../../utils/utils";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { FiPlus } from "react-icons/fi";
import GoogleInput from "../../../common/GoogleInput";
import { GridImages } from "./AddPartenaireAdmin";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/router";
import { Partenaire } from "../../../../utils/api/partenaire/partenaire.type";
import { useGetAnnuairesCategoriesByVisitorQuery } from "../../../../utils/api/category/category.api";
import { TagsInput } from "react-tag-input-component";

const EditPartenaireAdmin = () => {
	const { user } = useAppSelector((s) => s?.user);
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
	const navigate = useNavigate();

	React.useEffect(() => {
		if (!user?.partners && !is_SuperAdmin) {
			setTimeout(() => {
				navigate(-1);
			}, 50);
		}
	}, [user]);
	const itemState = useLocationState<Partenaire>(undefined);

	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container">
					<h1 className="admin-page-title">Modifié un annuaire</h1>
				</div>
			</div>
			<div className="mx-2 tab-content-admin py-4 px-5">
				<div className="form-container px-3">
					<EditPartnerForm
						inputClassName="form-control-input-admin"
						itemState={itemState}
					/>
				</div>
			</div>
		</div>
	);
};

export function EditPartnerForm({
	inputClassName,
	itemState,
}: {
	inputClassName: string;
	itemState?: Partenaire;
}) {
	const { register, onSubmit, errors, setValue, isLoading } = useEditPartenaire();
	const [icon, setIcon] = useState<File>();
	const [country, setCountry] = useState<any>("");
	const [state, setState] = useState<any>("");
	const [city, setCity] = useState<any>("");
	const [images, setImages] = useState<File[]>([]);
	const [bgImage, setBgImage] = useState<File>();
	const [selected, setSelected] = useState<string[]>([]);

	useEffect(() => {
		if (itemState) {
			setValue("slug", itemState.slug);
			setValue("description", itemState.description);
			setValue("title", itemState.title);
			setValue("ville", itemState.ville);
			setValue("region", itemState.region);
			setValue("pays", itemState.pays);
			setValue("name", itemState.name);
			setValue("link_url", itemState.link_url);
			setValue("linkedin", itemState.linkedin);
			setValue("facebook", itemState.facebook);
			setValue("twitter", itemState.twitter);
			setValue("instagram", itemState.instagram);
			setValue("whatsapp", itemState.whatsapp);
			setValue("phone", itemState.phone);
			setValue("email", itemState.email);
			setValue("email", itemState.email);
			setValue("description", itemState?.description);
			setValue("entreprise_type", itemState?.entreprise_type);
			setValue("expertise", itemState?.expertise);
			setValue("specialite", itemState?.specialite);
			setValue("category", itemState?.category?.id);
		}
	}, [itemState]);
	useEffect(() => {
		if (itemState) {
			const tags = [...itemState?.tags];
			setSelected(tags);
			setValue("tags", tags);
		}
	}, [itemState]);

	const handleChangeImage = (e) => {
		// console.log(e.target.files[0]);
		setValue("icon", e.target.files[0]);
		setIcon(e.target.files[0]);
	};
	const handleChangeBgImage = (e) => {
		// console.log(e.target.files[0]);
		setValue("background_image", e.target.files[0]);
		setBgImage(e.target.files[0]);
	};
	const handleCountrySelect = (option: any) => {
		setCountry(option);
		setValue("pays", option.name);
	};

	const handleStateSelect = (option: any) => {
		if (option.label) {
			setState(option);
			setValue("region", option.label);
		}
	};

	const handleCitySelect = (option: any) => {
		if (option.label) {
			setCity(option);
			setValue("ville", option.label);
		}
	};
	const handleChangeImageSlides = (e) => {
		// console.log(Array.of(e.target.files));
		const slides = Object.keys(e.target.files).map((key) => {
			return e.target.files[key];
		});
		setImages(slides);
		setValue("slides", slides);
	};

	const handleChangeTags = (value: string[]) => {
		console.log({ value });
		setSelected(value);
		setValue("tags", value);
	};

	const { data: categories = [] } = useGetAnnuairesCategoriesByVisitorQuery();
	return (
		<form onSubmit={onSubmit}>
			{/* <p className="label-form-admin pb-3">Service</p> */}
			<div className="row align-items-center">
				<div className="col-md-6 mb-4">
					<div>
						<label className="form-label label-form-admin" htmlFor="name">
							Nom de l'entreprise
						</label>
						<span className="text-danger">*</span>
						<input
							type="text"
							id="name"
							className={`form-control ${inputClassName}`}
							placeholder="Nom"
							data-testid="nameId"
							{...register("name")}
						/>
						{errors.name?.message && (
							<ErrorMessage message={errors.name.message} />
						)}
					</div>
				</div>
				<div className="col-md-6">
					<div className="form-group mb-4">
						<label htmlFor="title" className="label-form-admin">
							Phrase d'accroche
						</label>
						<span className="text-danger">*</span>
						<input
							id="title"
							className={`form-control ${inputClassName}`}
							data-testid="titleId"
							{...register("title")}
						/>
						{errors.title?.message && (
							<ErrorMessage message={errors.title.message} />
						)}
					</div>
				</div>
			</div>
			<div className="row mb-4">
				<div className="col-md-4">
					<div className="form-group mb-4">
						<label htmlFor="log" className="label-form-admin">
							Logo
						</label>
						<span className="text-danger">*</span>
						<input
							id="log"
							className={`form-control ${inputClassName}`}
							type="file"
							data-testid="logoId"
							accept="image/*"
							onChange={handleChangeImage}
						/>
						{errors.icon?.message && (
							<ErrorMessage message={errors.icon.message} />
						)}
					</div>
				</div>
				<div className="col-md-2 pt-4">
					<img
						src={
							icon
								? URL.createObjectURL(icon)
								: getImage(itemState?.icon)
						}
						alt={itemState?.name}
						loading="lazy"
						className="img-thumbnail h-80"
					/>
				</div>
				<div className="col-md-4">
					<div className="form-group mb-4">
						<label htmlFor="background_image" className="label-form-admin">
							Couverture
						</label>
						<span className="text-danger">*</span>
						<input
							id="background_image"
							className={`form-control ${inputClassName}`}
							type="file"
							data-testid="backgroundImg"
							accept="image/*"
							onChange={handleChangeBgImage}
						/>
						{errors.background_image?.message && (
							<ErrorMessage message={errors.background_image.message} />
						)}
					</div>
				</div>
				<div className="col-md-2 pt-4">
					<img
						src={
							bgImage
								? URL.createObjectURL(bgImage)
								: getImage(itemState?.background_image)
						}
						alt={itemState?.name}
						loading="lazy"
						className="img-thumbnail h-80"
					/>
				</div>
				<div className="col-md-6 mb-4 form-group">
					<label htmlFor="pays" className="label-form-admin">
						Pays
						<span className="text-danger">*</span>
					</label>
					<CountrySelector
						onChange={handleCountrySelect}
						name="country"
						placeholder="Pays"
						value={country}
						containerClass="tx country-select-container p-0"
					/>
					{errors.pays?.message && (
						<ErrorMessage message={errors.pays.message} />
					)}
				</div>
				<div className="col-md-6 mb-4 form-group">
					<label htmlFor="region" className="label-form-admin">
						Région
						<span className="text-danger">*</span>
					</label>
					<StateSelector
						country={country}
						name="state"
						value={state}
						countryPlaceholder="Region"
						onChange={handleStateSelect}
						containerClass="tx country-select-container p-0"
					/>
					{errors.region?.message && (
						<ErrorMessage message={errors.region.message} />
					)}
				</div>
				<div className="col-md-6 mb-4 form-group">
					<label htmlFor="ville" className="label-form-admin">
						Ville
						<span className="text-danger">*</span>
					</label>
					<CitySelector
						state={state}
						name="city"
						value={city}
						statePlaceholder="Ville"
						onChange={handleCitySelect}
						containerClass="tx country-select-container p-0"
					/>
					{errors.ville?.message && (
						<ErrorMessage message={errors.ville.message} />
					)}
				</div>
				<div className="col-6 mb-4 form-group">
					<label htmlFor="city" className="label-form-admin">
						Iteneraire
					</label>
					<span className="text-danger">*</span>
					<GoogleInput
						value={""}
						onChange={(value) => {
							console.log(value);
							setValue("location", value?.rue);
							setValue("longitude", value?.longitude);
							setValue("latitude", value?.latitude);
						}}
						className={`form-control ${inputClassName}`}
					/>
					{errors.location?.message && (
						<ErrorMessage message={errors.location.message} />
					)}
				</div>
				<div className="col-12">
					<div className="form-group mb-4">
						<label htmlFor="entreprise_type" className="label-form-admin">
							Secteur d'activité
						</label>
						<span className="text-danger">*</span>
						<input
							id="entreprise_type"
							className={`form-control ${inputClassName}`}
							data-testid="titleId"
							{...register("entreprise_type")}
							placeholder="Numérique"
						/>
						{errors.entreprise_type?.message && (
							<ErrorMessage message={errors.entreprise_type.message} />
						)}
					</div>
				</div>
				<div className="col-12">
					<div className="form-group mb-4">
						<label htmlFor="entreprise_type" className="label-form-admin">
							Catégories
						</label>
						<span className="text-danger">*</span>
						<select
							className="form-select espace-client-form-select-control"
							{...register("category")}
							id="category"
							defaultValue={itemState?.category?.id}
						>
							<option value="">
								Catégorie Principale de vos produits
							</option>
							{categories?.map((item) => (
								<option value={item?.id} key={item?.slug}>
									{" "}
									{item?.name}{" "}
								</option>
							))}
						</select>
						{errors.category?.message && (
							<ErrorMessage message={errors.category.message} />
						)}
					</div>
				</div>
				<div className="col-md-12 mb-4">
					<label htmlFor="image-project" className="label-form-admin">
						Tags
						<span className="text-danger">*</span>
					</label>
					<TagsInput
						value={selected}
						onChange={handleChangeTags}
						name="tags"
						placeHolder="Entrer les tags"
					/>
					<em>Appuyer sur Entrer pour ajouter un tag</em>
					{errors.tags?.message && (
						<ErrorMessage message={errors.tags.message} />
					)}
				</div>
				<div className="col-12">
					<label htmlFor="slides" className="slide-label">
						<FiPlus style={{ fontSize: 20 }} />
						<span>Ajouter des images pour illustrer son activité</span>
					</label>
					<input
						type="file"
						name="slides"
						id="slides"
						className="visibility-none"
						multiple={true}
						max={4}
						accept="image/*"
						onChange={handleChangeImageSlides}
					/>
					{/* {errors.slides?.message && (
									<ErrorMessage
										message={errors.slides.message}
									/>
								)} */}
				</div>
				<div className="col-12">
					<GridImages images={images} />
				</div>
				<div className="col-12 form-group mb-4">
					<label htmlFor="desc-mision" className="admin-form-label">
						Description
					</label>
					<CKEditor
						initData={itemState?.description}
						onChange={(e) => setValue("description", e.editor.getData())}
					/>
					{errors.description?.message && (
						<ErrorMessage message={errors.description.message} />
					)}
				</div>
				<div className="col-12 form-group mb-4">
					<label htmlFor="specialite" className="admin-form-label">
						Spécialités
					</label>
					<CKEditor
						initData={itemState?.specialite}
						onChange={(e) => setValue("specialite", e.editor.getData())}
					/>
					{errors.specialite?.message && (
						<ErrorMessage message={errors.specialite.message} />
					)}
				</div>
				<div className="col-12 form-group mb-4">
					<label htmlFor="expertise" className="admin-form-label">
						Expertises
					</label>
					<CKEditor
						initData={itemState?.expertise}
						onChange={(e) => setValue("expertise", e.editor.getData())}
					/>
					{errors.expertise?.message && (
						<ErrorMessage message={errors.expertise.message} />
					)}
				</div>
				<div className="col-md-6 mb-4">
					<div>
						<label className="form-label label-form-admin">Lien URL</label>
						<input
							type="url"
							className={`form-control ${inputClassName}`}
							placeholder="Lien URL"
							{...register("link_url")}
						/>
						{errors.link_url?.message && (
							<ErrorMessage message={errors.link_url.message} />
						)}
					</div>
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="linkedin" className="label-form-admin">
						Linkedin
					</label>
					<input
						type="url"
						id="linkedin"
						className={`form-control ${inputClassName}`}
						placeholder="linkedin.com"
						{...register("linkedin")}
					/>
					{errors.linkedin?.message && (
						<ErrorMessage message={errors.linkedin.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="facebook" className="label-form-admin">
						Facebook
					</label>
					<input
						type="url"
						className={`form-control ${inputClassName}`}
						id="facebook"
						placeholder="facebook.com"
						{...register("facebook")}
					/>
					{errors.facebook?.message && (
						<ErrorMessage message={errors.facebook.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="twitter" className="label-form-admin">
						Twitter
					</label>
					<input
						type="url"
						className={`form-control ${inputClassName}`}
						id="twitter"
						placeholder="twitter.com"
						{...register("twitter")}
					/>
					{errors.twitter?.message && (
						<ErrorMessage message={errors.twitter.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="instagram" className="label-form-admin">
						Instagram
					</label>
					<input
						type="url"
						className={`form-control ${inputClassName}`}
						id="instagram"
						placeholder="instagram.com"
						{...register("instagram")}
					/>
					{errors.instagram?.message && (
						<ErrorMessage message={errors.instagram.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="whatsapp" className="label-form-admin">
						Whatsapp
					</label>
					<input
						type="tel"
						className={`form-control ${inputClassName}`}
						id="whatsapp"
						placeholder="Whatsapp"
						{...register("whatsapp")}
					/>
					{errors.whatsapp?.message && (
						<ErrorMessage message={errors.whatsapp.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="telephone" className="label-form-admin">
						Numéro de téléphone
					</label>
					<span className="text-danger">*</span>
					<input
						type="tel"
						className={`form-control ${inputClassName}`}
						id="telephone"
						placeholder="Numéro de téléphone"
						data-testid="telephoneId"
						{...register("phone")}
					/>
					{errors.phone?.message && (
						<ErrorMessage message={errors.phone.message} />
					)}
				</div>
				<div className="col-md-6 form-group mb-4">
					<label htmlFor="email" className="label-form-admin">
						Adresse mail
					</label>
					<span className="text-danger">*</span>
					<input
						type="email"
						className={`form-control ${inputClassName}`}
						id="email"
						placeholder="Adresse mail"
						data-testid="emailId"
						{...register("email")}
					/>
					{errors.email?.message && (
						<ErrorMessage message={errors.email.message} />
					)}
				</div>
			</div>
			<div className="btn-container gap-3 justify-content-end d-flex">
				<div className="col-md-2">
					<BtnSubmit label="Modifier" isLoading={isLoading} />
				</div>
			</div>
		</form>
	);
}

export default EditPartenaireAdmin;
