import moment from 'moment'
import React, { useState, useEffect } from 'react'
import { BsArrowDown, BsArrowUp } from 'react-icons/bs'
import StarRatingComponent from 'react-star-rating-component'
import { EmptyMessage } from '../../../../utils/EmptyMessage'
import { createMarkup, getImage } from '../../../../utils/utils'

const Comments = ({ commerce }) => {
  const [itemToShow, setItemToShow] = useState(6)
  const fetchMoreData = (e) => {
    e.preventDefault()
    if (itemToShow >= commerce?.notes?.length) {
      setItemToShow(6)
      return
    } else {
      setItemToShow(itemToShow + 6)
    }
  }
  return (
		<div>
			<div className="blog-comments-container card-blog mt-4 pt-3 pb-2">
				<div className="mb-2 px-3"></div>
				{commerce?.notes?.length ? (
					commerce?.notes?.slice(0, itemToShow)?.map((note) => (
						<div
							className={`blog-comment-item p-3 border-comment-item`}
							key={note?.id}
						>
							<div className="comment-details">
								<div className="author-comment-info-container">
									<div className="autor-comment-avatar-container">
										<img
											src={getImage(note?.user?.avatar)}
											alt="avatar"
											height="50"
											width="50"
											style={{ borderRadius: "50%" }}
										/>
									</div>
									<div className="author-comment-info">
										<h2 className="author-comment-name">
											{note?.user?.firstname ||
											note?.user?.lastname
												? note?.user?.firstname +
												  " " +
												  note?.user?.lastname
												: "Anonyme"}
											{}
										</h2>
										<h6 className="date-comment">
											{moment(note?.created_at)?.format(
												"DD/MM/YYYY"
											)}
										</h6>
									</div>
								</div>
							</div>
							<div className="comment-text-container mt-2">
								<div>
									<StarRatingComponent
										name="rate2"
										editing={false}
										starCount={5}
										value={note?.rating}
										starColor="#FFA73C"
										emptyStarColor="rgba(180, 180, 180, 0.8)"
									/>
								</div>
								<p
									className="comment-text"
									dangerouslySetInnerHTML={createMarkup(
										note?.comment
									)}
								/>
							</div>
						</div>
					))
				) : (
					<div className={`blog-comment-item p-3 border-comment-item`}>
						{EmptyMessage("Aucun avis disponible pour le moment")}
					</div>
				)}

				<div className="see-more-button-container">
					{commerce?.notes?.length && commerce?.notes?.length > 6 ? (
						commerce?.notes?.length > itemToShow ? (
							<button
								className="see-more-button"
								onClick={(e) => fetchMoreData(e)}
							>
								<span className="arrow-down-container">
									<BsArrowDown />
								</span>
								<span className="see-more-container ps-1">
									<span className="see-more-comments">
										Voir plus d’avis
									</span>
								</span>
							</button>
						) : (
							<button
								className="see-more-button"
								onClick={(e) => fetchMoreData(e)}
							>
								<span className="arrow-down-container">
									<BsArrowUp />
								</span>
								<span className="see-more-container ps-1">
									<span className="see-more-comments">
										Voir moins d’avis
									</span>
								</span>
							</button>
						)
					) : null}
				</div>
			</div>
		</div>
  );
}

export default Comments
