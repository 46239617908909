import "./FrontFooter.css";
import ImgFoter from "../../assets/appImages/munngano.png";
import { Link, NavLink } from "react-router-dom";
import Facebook from "../../assets/icons/facebook.png";
import Instagram from "../../assets/icons/instagram.png";
import { MdNotificationsNone } from "react-icons/md";
import VersionChecker from "../../../versionChecker/VersionChecker";
import { useGetPartnerForVisitorQuery } from "../../../utils/api/partenaire/partenaire.api";
import { getImage } from "../../../utils/utils";
import { FaLinkedinIn } from "react-icons/fa";
import { useGetSocialMediaVisitorQuery } from "../../../utils/api/about/about.api";
import { Partenaire } from "../../../utils/api/partenaire/partenaire.type";

const FrontFooter = () => {
	const { data: partners } = useGetPartnerForVisitorQuery({});
	const { data } = useGetSocialMediaVisitorQuery();
	return (
		<footer className="component-front-footer">
			<div className="container-munngano-footer">
				<div className="body-footer">
					<div className="row">
						<div className="col-xl-4 col-lg-4 mb-5 col-md-6 footer-grid">
							<img src={ImgFoter} alt="image logo footer" />
							<div className="front-footer-text-container" style={{ maxWidth: "400px" }}>
								<p className="link-footer trans-0-2 d-block mb-2 mt-3">
									la plateforme Munnagano Connectt est destinée aux particuliers et
									aux professionnels - Trouver le bon professionnel en quelques
									clics.
								</p>
							</div>
							{/* <p className="link-footer trans-0-2 d-block mb-2 mt-3">
								121 king street, Melbourne 3000
							</p>
							<p className="link-footer trans-0-2 d-block mb-2">
								+61 3 8376 6284
							</p>
							<p className="link-footer trans-0-2 d-block mb-2">
								contact@munngano.fr
							</p>
							<p className="link-footer trans-0-2 d-block mb-3">
								www.munngano.fr
							</p> */}
							<NavLink
								className="link-footer-nous-contacter btn w-50 trans-0-2 mb-4"
								to="/app/contactez-nous"
							>
								<MdNotificationsNone />
								<span className="ps-2">Créer une alerte</span>
							</NavLink>
							<div className="d-flex mb-4">
								<a
									className="mx-2 trans-0-2"
									href={data?.linkedin || "#"}
									target="_blank"
									rel="noreferrer"
								>
									<FaLinkedinIn
										className="icon-social-footer"
										color="#000"
										size={18}
									/>
								</a>
								<a
									className="mx-2 trans-0-2"
									href={data?.instagram || "#"}
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={Instagram}
										alt="Instagram"
										className="icon-social-footer"
									/>
								</a>
								{/* <a
									className="me-2 trans-0-2"
									href={data?.facebook}
									target="_blank"
									rel="noreferrer"
								>
									<img
										src={Facebook}
										alt="Facebook"
										className="icon-social-footer"
									/>
								</a> */}
							</div>
						</div>
						<div className="col-xl-4 col-lg-4 mb-5 col-md-6 footer-grid">
							<p className="title-footer mb-4">Annuaires</p>
							{/* <div className="no-view-mobile">
								<div className="row content-partner-footer-bloc">
									{partners?.slice(0, 4)?.map((partner) => (
										<div
											className="col-xxl-3 col-4"
											key={partner?.slug}
										>
											<AnnuaireItem partner={partner} />
										</div>
									))}
								</div>
							</div> */}
							{/* <div className="no-view-desktop"> */}
							<div className="overflow-hidden">
								<div className="slider-partner-footer-container gap-4">
									{partners?.map((partner) => (
										<AnnuaireItem partner={partner} key={partner?.slug} />
									))}
								</div>
							</div>
							{/* </div> */}
						</div>
						<div className="col-lg-3 offset-lg-1 mb-5 col-md-6 footer-grid">
							<p className="title-footer mb-4">Informations</p>

							<NavLink className="link-footer-navigation trans-0-2 d-block mb-3" to={"/"}>
								Nos Tarifs
							</NavLink>
							<NavLink
								className="link-footer-navigation trans-0-2 d-block mb-3"
								to={"/app/a-propos"}
							>
								À Propos de nous
							</NavLink>
							<NavLink
								className="link-footer-navigation trans-0-2 d-block mb-3"
								to="/app/nos-services"
							>
								Nos Services
							</NavLink>
							<NavLink
								className="link-footer-navigation trans-0-2 d-block mb-3"
								to={"/app/contactez-nous"}
							>
								Contactez-nous
							</NavLink>
							<NavLink
								className="link-footer-navigation trans-0-2 d-block mb-3"
								to="/app/condition-generale"
							>
								Conditions générales d’utilisations
							</NavLink>
							<NavLink
								className="link-footer-navigation trans-0-2 d-block mb-3"
								to="/app/politique-de-confidentialite"
							>
								Politiques de confidentialités
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			<div className="row mx-0 end-footer py-3 d-flex justify-content-center align-items-center position-relative">
				<div className="mt-lg-0 mt-5 d-flex justify-content-center">
					<p className="copyright">Munngano © – All rights reserved</p>
				</div>
				<div className="version-view-container">{"v" + VersionChecker()}</div>
			</div>
		</footer>
	);
};
function AnnuaireItem({ partner }: { partner: Partenaire }) {
	return (
		<Link to={`/annuaires/${partner?.slug}`} state={partner}>
			<div className="partner-footer-item mb-3">
				<div className="annuaire-infos">
					<h3>{partner?.name}</h3>
					<p>{partner?.entreprise_type}</p>
				</div>
				<div className="img-container">
					<img
						src={getImage(partner?.icon)}
						alt={partner?.title}
						className="partner-footer-img"
					/>
				</div>
			</div>
		</Link>
	);
}
export default FrontFooter;
