import { MdOutlineAddCircleOutline } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { useGetPartenaireByUserQuery } from "../../../../utils/api/partenaire/partenaire.api";
import { AlertInfo } from "../../../common/Alert";
import EditAnnuaire from "./EditAnnuaire";

const AnnuaireTab = () => {
	const { user } = useAppSelector((s) => s?.user);
	const { data, isLoading } = useGetPartenaireByUserQuery(user?.slug);
	return (
		<div className="mes-annuaires-tabs-component">
			<div className="mb-4 bloc-title-page-tab">
				{data && data?.results?.length < 1 && (
					<div>
						<Link to="/app/app/annuaires/demande" className="btn btn-send-newsletter">
							<MdOutlineAddCircleOutline />
							<span className="ps-2">Intégrer l'annuaire</span>
						</Link>
					</div>
				)}
			</div>
			<div className="row">
				{!isLoading && data && data?.results?.length > 0 && (
					<>
						{data?.results[0]?.valider ? (
							<>
								<EditAnnuaire item={data?.results[0]} />
							</>
						) : (
							<>
								<AlertInfo message="Votre demande d'intégration de l'annuaire des entreprises est en attente de validation" />
							</>
						)}
					</>
				)}
				{!isLoading && data && data?.results?.length === 0 && (
					<AlertInfo message="Aucun annuaire trouvé" />
				)}
			</div>
		</div>
	);
};
export default AnnuaireTab;
