import { createMarkup, getAvatar } from "../../../../utils/utils";
import CloseModalBtn from "../../../shared/CloseModalBtn";

function DetailsTestimonialModal({ item }) {
	return (
		<div
			className="modal fade"
			id="DetailsTestimonialModal"
			aria-labelledby={`DetailsTestimonialModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog easypm-modal-dialog modal-lg">
				<div className="modal-content easypm-modal-content">
					<div className="modal-header easypm-modal-header">
						<CloseModalBtn />
					</div>
					<div className="p-3">
						<div className="add-family-form-container">
							<div className="row position-relative">
								<div className="justify-content-center crud-col-item  d-flex">
									<div className="form-group align-items-center bloc-admin-profil position-relative text-center">
										<img
											src={getAvatar(item?.avatar)}
											alt="img admin"
											className="img-admin"
											width="150"
											height="150"
											style={{ borderRadius: "100%" }}
										/>

										<h3 className="profil-nom">
											{item?.nom}
										</h3>
									</div>
								</div>
								<div className="col-md-2  position-relative">
									<div className="bloc-profil-right"></div>
								</div>
							</div>
							<div className="pb-3">
								<h4 className="title-info-profil">Informations</h4>
							</div>
							<div className="row position-relative">
								<div className="col-md-6 crud-col-item">
									<div className="form-group crud-form-group group-text">
										<p className="texte-gray">
											Nom complet
										</p>
										<p className="bloc-text-profil">
											{item?.nom}
										</p>
									</div>
								</div>
								<div className="col-md-6 crud-col-item">
									<div className="form-group crud-form-group group-text">
										<p className="texte-gray">Fonction</p>
										<p className="bloc-text-profil">
											{item?.fonction}
										</p>
									</div>
								</div>
								<div className="col-12 crud-col-item">
									<div className="form-group crud-form-group group-text">
										<p className="texte-gray">Message</p>
										<div
											className="bloc-text-profil"
											dangerouslySetInnerHTML={createMarkup(
												item?.content
											)}
										/>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsTestimonialModal;
