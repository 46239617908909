import React from "react";
import { BtnBack, BtnNext, BtnSkip } from "../../../../common/Button";
import { TraderStepPropsType } from "../../BecomeTrader/Steps/AddInfoTrader";
import image from "../../../../assets/appImages/image51.png";
import { IconDelete3, IconImage } from "../../../../shared/Icons";
import { FormError } from "../../../../common/CustomInput";

const AddImage = ({ navigation, setValue, files, setFiles, errors, setError, isLoading }) => {
	const deleteFile = (index) => {
		const newArr = [...files];
		newArr.splice(index, 1);
		setFiles(newArr);
		setValue("images", newArr);
	};
	return (
		<div>
			<h4 className="stepper-title">Ajouter des images</h4>
			<div className="row">
				{files?.length
					? files?.map((file, index) => (
							<div className="col-md-4 col-xl-3" key={index}>
								<div className="product-img-card">
									<button
										className="no-style-btn del-product-img"
										title="Supprimer"
										onClick={() => deleteFile(index)}
									>
										<IconDelete3 />
									</button>
									<img
										src={URL.createObjectURL(file)}
										alt="product-img"
										style={{ width: "100%", height: "15vw", objectFit: "fill" }}
									/>
								</div>
							</div>
					  ))
					: null}
			</div>
			{<FormError error={errors?.images?.message} />}
			<div>
				<div className="d-flex flex-column align-items-end">
					<label htmlFor="image" className="btn-parcourir">
						<IconImage />
						<span>Parcourir</span>
					</label>
					<input
						type="file"
						className="hide-input op-0-0"
						title="images"
						accept="image/*"
						multiple
						onChange={(e: any) => {
							const newFiles = [...e.target.files];
							console.log([...files, ...newFiles]);
							if ([...files, ...newFiles]?.length > 4) {
								setError("images", {
									message: "Veuillez télécharger maximum 4 images",
								});
							}
							if ([...files, ...newFiles]?.length <= 4) {
								setFiles([...files, ...newFiles]);
								setValue("images", [...files, ...newFiles]);
							}
						}}
						id="image"
					/>
				</div>
				<div className="flex-r gap-3 mt-4">
					<BtnBack label="Retour" type="button" navigation={navigation} />
					<BtnNext label="Suivant" type="submit" isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
};

export default AddImage;
