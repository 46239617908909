import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { IUser } from "../../../redux/slice/User.slice";
import { ApiBaseUrl } from '../../http'
import { IDashboardAdminData } from "../../type";
import { QueryUrl } from "../../utils";
import { PaginationResults, UserTypeQuery } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";

export const AdminApi = createApi({
	reducerPath: "adminApi",
	tagTypes: ["admin", "admin_slug", "find_admin", "dashboardbyadmin"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		adminBySlug: builder.query<IUser, string>({
			query: (slug) => `/adminuser/${slug}/`,
			providesTags: ["admin_slug"],
		}),
		getAdmin: builder.query<PaginationResults<IUser> | any, UserTypeQuery>({
			query: (query) => QueryUrl("/adminbyfilter/", query),
			providesTags: ["find_admin"],
		}),
		getAdminDashboardData: builder.query<IDashboardAdminData, void>({
			query: () => "dashboardbyadmin",
			providesTags: ["find_admin", "dashboardbyadmin"],
		}),
		addAdmin: builder.mutation<IUser, IUser | any>({
			query: (data) => ({
				url: "/adminuser/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["find_admin"],
		}),
		editAdmin: builder.mutation<IUser, IUser | any>({
			query: ({ slug, data }) => {
				return {
					url: `adminuser/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
			invalidatesTags: ["find_admin"],
		}),
	}),
});

export const {
	useAdminBySlugQuery,
	useEditAdminMutation,
	useAddAdminMutation,
	useGetAdminQuery,
	useGetAdminDashboardDataQuery,
} = AdminApi;
