import React, { useCallback, useState } from "react";
import "./MessageAdmin.css";
import "../SellerListAdmin/SellerListAdmin.css";
import DetailsMessageModal from "../../modals/adminModal/DetailMessageModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/router";
import { useGetContactMessagesQuery } from "../../../../utils/api/contact/contact.api";
import moment from "moment";
import { IContact } from "../../../../utils/api/contact/contact.type";
import { createMarkup, sortedByDate } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import ChangeStatusMessageModal from "../../modals/adminModal/ChangeStatusMessageModal";
import VolkenoSkeleton from "volkeno-react-skeleton";
import Pagination from "../../../common/Pagination";

const typeMessages = [
  {
    label: "Plainte",
    value: "plainte",
  },
  {
    label: "Mise en relation",
    value: "mise_en_relation",
  },
];

const MessageAdmin = () => {
  const [page, setPage] = useState(1);
  const [item, setItem] = useState<IContact>();
  const [sortedMessages, setSortedMessages] = useState<IContact[] | any>();
  const [searchParams, setSearchParams] = useSearchParams();
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const subject = searchParams.get("subject");
  const category = searchParams.get("category");
  const { data: messages, isLoading } = useGetContactMessagesQuery({
    subject,
    category,
  });
  console.log(sortedMessages);
  React.useEffect(() => {
    if (!user?.chat && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);

  React.useEffect(() => {
    if (messages?.results?.length) {
      const sortedMessages = sortedByDate(messages?.results);
      setSortedMessages(sortedMessages);
    }
  }, [messages]);

  const handleFilterMessagesBySubject = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value !== "") {
      if (category) {
        setSearchParams({ category: category, subject: e.currentTarget.value });
      } else {
        setSearchParams({ subject: e.currentTarget.value });
      }
    } else {
      setSearchParams({});
    }
  };
  const handleFilterByCategory = useCallback(
    (item): void => {
      if (subject) {
        setSearchParams({
          subject: subject,
          category: item?.value,
        });
      } else {
        setSearchParams({ category: item?.value });
      }
    },
    [searchParams]
  );
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Messages</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                className="search-input-admin"
                placeholder="Search.."
                onChange={(e) => handleFilterMessagesBySubject(e)}
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="my-3 d-flex gap-3">
        <button
          onClick={() => {
            setSearchParams({});
          }}
          className={`btn-filter ${category === null && "active"}`}
        >
          Tous
        </button>
        {typeMessages?.map((item) => (
          <button
            className={`btn-filter ${category === item?.value && "active"}`}
            key={item?.value}
            onClick={() => {
              handleFilterByCategory(item);
            }}
          >
            {item?.label}
          </button>
        ))}
      </div>
      <div className="mx-2">
        {!isLoading &&
          sortedMessages?.length &&
          sortedMessages?.map((item) => (
            <div key={item?.slug} className="mb-3">
              <div className="px-2 py-3 admin-detail-mission-card mb-2 flex-m gap-1">
                <button
                  className="no-style-btn accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#responseContact${item?.slug}`}
                  aria-expanded="false"
                  aria-controls="responseContact"
                ></button>
                <div className="row w-100 align-items-center">
                  <div className="col-md-5">
                    <button
                      className="admin-detail-mission-card-description btn p-0"
                      data-bs-toggle="modal"
                      data-bs-target="#DetailsMessageModal"
                      onClick={() => setItem(item)}
                      style={{
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.subject}
                    </button>
                    <p className="admin-detail-mission-card-frequence fz-14 mb-0">
                      {moment(item?.created_at).format("DD/MM/YYYY à HH:mm:ss")}
                    </p>
                  </div>
                  <div className="col-md-2 mb-2">
                    <button
                      className={`message-btn ${
                        item?.contact_type === "plainte"
                          ? "badge-danger"
                          : "bg-info"
                      }`}
                    >
                      {item?.contact_type === "plainte"
                        ? "Plainte"
                        : "Mise en relation"}
                    </button>
                  </div>
                  <div className="col-md-2 mb-2">
                    <button
                      className={`btn message-btn ${
                        item?.status === "new"
                          ? "badge-danger"
                          : item?.status === "en_cours"
                          ? "bg-secondary"
                          : "bg-success"
                      }`}
                      onClick={() => setItem(item)}
                      title="Changer le statut"
                      data-bs-toggle="modal"
                      data-bs-target="#ChangeStatusMessageModal"
                      aria-expanded="false"
                    >
                      {item?.status === "new"
                        ? "Nouveau"
                        : item?.status === "en_cours"
                        ? "En cours"
                        : "Traité"}
                    </button>
                  </div>
                  <div className="col-md-3 mb-2">
                    <p className="admin-detail-mission-card-description mb-0">
                      {item?.name}
                    </p>
                  </div>
                </div>
              </div>
              <div className="collapse" id={`responseContact${item?.slug}`}>
                {item?.responses?.length > 0 &&
                  item?.responses?.map((elem) => (
                    <div
                      className="card card-body mb-1 message-text"
                      key={elem?.id}
                      dangerouslySetInnerHTML={createMarkup(elem?.response)}
                    />
                  ))}
                {item?.responses?.length === 0 && (
                  <AlertInfo message="Aucune réponse" />
                )}
              </div>
            </div>
          ))}
        {isLoading &&
          [...Array(4)].map((elem, _i) => <MessageItemSkelton key={_i} />)}
        {messages?.results?.length === 0 && (
          <AlertInfo message="Aucun message de contact trouvé" />
        )}
        <Pagination
          page={page}
          onPageChange={setPage}
          total={messages?.count}
        />
      </div>
      <div
        className="modal fade"
        id="DetailsMessageModal"
        aria-labelledby="DetailsMessageModalLabel"
        aria-hidden="true"
      >
        <DetailsMessageModal item={item} />
      </div>
      <ChangeStatusMessageModal item={item} />
    </div>
  );
};
export function MessageItemSkelton() {
  return (
    <div className="mb-3">
      <div className="px-2 py-3 admin-detail-mission-card mb-2 flex-m gap-1">
        <VolkenoSkeleton variant="rect" width={10} height={10} />
        <div className="row w-100 align-items-center">
          <div className="col-md-5 mb-2">
            <VolkenoSkeleton variant="rect" width="100%" height={12} />
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
          <div className="col-md-2 mb-2">
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
          <div className="col-md-2 mb-2">
            <VolkenoSkeleton variant="rect" width="100%" height={10} />
          </div>
          <div className="col-md-3 mb-2">
            <p className="admin-detail-mission-card-description mb-0">
              <VolkenoSkeleton variant="rect" width="100%" height={10} />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
export default MessageAdmin;
