import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import BoostedAnnoucements from "./BoostedAnnoucements";

const steps = [
	{ id: "boosted_annnouncements", Component: BoostedAnnoucements },
	// { id: "boosted_boutiques", Component: BoostedCommerces },
	// { id: "boosted_products", Component: BoostedProducts },
];

const tabs = ["Annonces", "Boutiques", "Produits"];
const Boosted = () => {
	const {
		step,
		navigation: { go },
		index,
	}: useStepType = useStep({ initialStep: 0, steps });
	const { Component } = step;
	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container">
					<h1 className="admin-page-title">
						{index === 0 && "Annonces boostés"}
						{index === 1 && "Boutiques boostés"}
						{index === 2 && "Produits boostés"}
					</h1>
				</div>
			</div>
			{/* <ul className="ul-boots-content d-flex gap-3 mt-4 p-0">
        {tabs.map((tab, key) => (
          <li
            className={`boost-tab ${index === key && "boost-tab-active"}`}
            key={key}
            onClick={() => go(key)}
            role="button"
          >
            {tab}
          </li>
        ))}
      </ul> */}
			<div className="tabs-component-container mt-4">
				<Component />
			</div>
		</div>
	);
};

export default Boosted;
