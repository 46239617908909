import React, { useState } from "react";
import moment from "moment";
import { useEffect } from "react";
import DatePicker from "react-date-picker";
import { useAppSelector } from "../../../../../redux/hooks";
import { BtnSubmit } from "../../../../common/Button";
import ErrorMessage from "../../../../common/ErrorMessage";
import UseFormTableauDeBord from "../UseFormMyCustomArea/UseFormTableauDeBord";
import { BiCalendar } from "react-icons/bi";
import { IoClose } from "react-icons/io5";

const InformationsPersonnelles = () => {
	const { user } = useAppSelector((state) => state.user);
	const [bornDate, setBornDate] = useState<Date>();

	const { register, onSubmit, errors, isLoading, setValue } = UseFormTableauDeBord();
	useEffect(() => {
		if (user) {
			setValue("slug", user?.slug);
			setBornDate(new Date(user?.birthdate));
		}
	}, [user]);
	const handleChangeDate = (date) => {
		setBornDate(date);
		setValue("birthdate", date);
	};
	return (
		<div>
			<div className="bloc-title-page-tab pb-4">
				<div>
					<p className="titre-page-tab-content">
						Mes informations personnelles
					</p>
				</div>
			</div>
			<div className="form-content">
				<form onSubmit={onSubmit}>
					<div className="row">
						<div className="col-md-6 mb-3">
							<label
								className="form-label form-label-tabs"
								htmlFor="civilite"
								aria-labelledby="civilite"
							>
								Civilité
							</label>
							<select
								id="civilite"
								defaultValue={user?.civilite}
								className="form-select form-control-input-select-tabs"
								data-testid="civilite"
								{...register("civilite")}
							>
								<option value="femme">Femme</option>
								<option value="homme">Homme</option>
							</select>
							{errors.civilite?.message && (
								<ErrorMessage message={errors.civilite.message} />
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Date de naissance
							</label>
							<DatePicker
								className="form-control form-control-input-tabs"
								value={bornDate}
								onChange={(date) => handleChangeDate(date)}
								name="date"
								maxDate={new Date()}
								calendarIcon={
									<BiCalendar
										style={{
											color: "#200E3280",
											fontSize: "24px",
											position: "absolute",
											top: "25%",
											right: "5",
										}}
									/>
								}
								clearIcon={<IoClose />}
							/>
							{/* <input
								type="date"
								className="form-control form-control-input-tabs"
								defaultValue={birthDate}
								{...register("birthdate")}
								max={moment(new Date()).format("yyyy-mm-dd")}
							/> */}
							{errors.birthdate?.message && (
								<ErrorMessage message={errors.birthdate.message} />
							)}
						</div>

						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">Nom</label>
							<input
								type="text"
								className="form-control form-control-input-tabs"
								placeholder="Nom"
								defaultValue={user?.lastname}
								{...register("lastname")}
							/>
							{errors.lastname?.message && (
								<ErrorMessage message={errors.lastname.message} />
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Prénom
							</label>
							<input
								type="text"
								className="form-control form-control-input-tabs"
								placeholder="Prénom"
								defaultValue={user?.firstname}
								{...register("firstname")}
							/>
							{errors.firstname?.message && (
								<ErrorMessage message={errors.firstname.message} />
							)}
						</div>
						{/* <div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Pseudonyme
							</label>
							<input
								type="text"
								className="form-control form-control-input-tabs"
								placeholder="Pseudonyme"
								defaultValue={user?.pseudonym}
								{...register("pseudonym")}
							/>
							{errors.pseudonym?.message && (
								<ErrorMessage message={errors.pseudonym.message} />
							)}
						</div> */}
						<div className="col-md-6 mb-3">
							<label
								htmlFor="code_postal"
								aria-labelledby="code_postal"
								className="form-label form-label-tabs"
							>
								Code postal
							</label>
							<input
								type="text"
								className="form-control form-control-input-tabs"
								id="code_postal"
								{...register("postal_code")}
								data-testid="postalCodeId"
								defaultValue={user?.postal_code}
							/>
							{errors.postal_code?.message && (
								<ErrorMessage
									message={errors.postal_code.message}
								/>
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Email
							</label>
							<input
								type="email"
								className="form-control form-control-input-tabs"
								placeholder="mail@gmail.com"
								defaultValue={user?.email}
								{...register("email")}
							/>
							{errors.email?.message && (
								<ErrorMessage message={errors.email.message} />
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Adresse
							</label>
							<input
								type="text"
								className="form-control form-control-input-tabs"
								placeholder="2 rue moulin à papier"
								defaultValue={user?.adresse}
								{...register("adresse")}
							/>
							{errors.adresse?.message && (
								<ErrorMessage message={errors.adresse.message} />
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								Numéro de téléphone
							</label>
							<input
								type="tel"
								className="form-control form-control-input-tabs"
								placeholder="0629414733"
								defaultValue={user?.phone}
								{...register("phone")}
							/>
							{errors.phone?.message && (
								<ErrorMessage message={errors.phone.message} />
							)}
						</div>
						<div className="col-md-6 mb-3">
							<label className="form-label form-label-tabs">
								N° whatsapp
							</label>
							<input
								type="tel"
								className="form-control form-control-input-tabs"
								placeholder="0629414733"
								defaultValue={user?.whatsapp}
								{...register("whatsapp")}
							/>
							{errors.whatsapp?.message && (
								<ErrorMessage message={errors.whatsapp.message} />
							)}
						</div>
						<div className="col-md-6 offset-md-6 col-xl-4 offset-xl-8 pt-5">
							<BtnSubmit label="Modifer" isLoading={isLoading} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};
export default InformationsPersonnelles;
