import React from 'react'
import VolkenoSkeleton from 'volkeno-react-skeleton'

function ProcessSkeleton() {
  return (
		<div className="col-lg-4 col-md-4 m-b-20 px-md-2 dis-flex">
			<div className="item-step-checkout h-full w-100">
				<div className="row m-b-25">
					<div className="col d-flex align-items-center">
						<VolkenoSkeleton
							variant="circle"
							borderRadius={`50%`}
							width={35}
							height={35}
							style={{ border: "solid #eee", background: "#FFA73C" }}
						/>
						<VolkenoSkeleton
							className="title-proccess-checkout-card"
							variant="text"
							width={`15rem`}
							height={30}
							style={{ marginLeft: "1rem" }}
						/>
					</div>
				</div>
				<VolkenoSkeleton
					variant="rect"
					width={`95%`}
					height={15}
					className="fs-12 fw-400 text-dark lh-1-4 m-t-3 m-b-10"
				/>
				{Array(2)
					.fill(2)
					.map((item, i) => {
						return (
							<div
								className="custom-control custom-radio m-b-20"
								key={i}
							>
								<div className="d-flex align-items-center">
									<VolkenoSkeleton
										variant="circle"
										borderRadius={`50%`}
										width={20}
										height={20}
										style={{ border: "solid #eee" }}
										className="custom-control-input"
									/>
									<VolkenoSkeleton
										className="input-item-step-checkout fw-600 fs-14 lh-1-4 mb-10"
										variant="rect"
										width={200}
										height={25}
										style={{ marginLeft: "1rem" }}
									/>
								</div>

								<VolkenoSkeleton
									className=" fs-12 fw-300 lh-1"
									height={15}
									width={`90%`}
									variant="text"
									count={2}
									style={{ marginLeft: "1.7rem" }}
								/>
							</div>
						);
					})}
			</div>
		</div>
  );
}

export default ProcessSkeleton
