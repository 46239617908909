import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthState } from "../../../redux/slice/User.slice";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../utils";
import {
	PaginationResults,
	TBoostData,
	TBoostResponse,
	TypeQuery,
} from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import { AnnonceFormData, AnnonceEditFormData, IAnnonce, IFavAnnonce } from "./annonce.type";

export const AnnonceApi = createApi({
	reducerPath: "annonceApi",
	tagTypes: [
		"annonce",
		"getAnnonceByUser",
		"findAnnonceBySlug",
		"favoritesAnnonceByUser",
		"boostedAnnonce",
		"socialBoostedAnnonce",
	],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (build) => ({
		// get all annoncement
		getAnnoncement: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: (query) => ({
				url: QueryUrl("announcement/", query),
				method: "GET",
			}),
			providesTags: ["annonce"],
		}),
		// get all annoncement for visitors
		getAnnonceVisitors: build.query<IAnnonce[], void>({
			query: () => ({
				url: "announcementbyvisitor/",
				method: "GET",
			}),
			providesTags: ["annonce"],
			transformResponse: ({ results }) => results,
		}),
		// get all annoncement for visitors by filter type_annonce
		getAnnonceVisitorsFilter: build.query<IAnnonce[], { type_annonce: string | null }>({
			query: (query) => ({
				url: QueryUrl("announcementbyvisitor/", query),
				method: "GET",
			}),
			providesTags: ["annonce"],
			transformResponse: ({ results }) => results,
		}),
		// post Annonce mutation
		depotAnnonce: build.mutation<IAnnonce, AnnonceFormData | any>({
			query: (data) => ({
				url: "announcement/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["annonce"],
			transformResponse: ({ data }) => data,
		}),
		// update Annonce mutation
		editAnnonce: build.mutation<IAnnonce, AnnonceEditFormData | any>({
			query: ({ slug, formData }) => ({
				url: `announcement/${slug}/`,
				method: "PUT",
				body: formData,
			}),
			invalidatesTags: ["annonce", "getAnnonceByUser"],
		}),
		// delete annonce
		deleteAnnonce: build.mutation<IAnnonce, string>({
			query: (slug) => ({
				url: `announcement/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["getAnnonceByUser", "annonce"],
		}),
		// get Annonce by user Query
		annonceByUser: build.query<IAnnonce[], string>({
			query: (slug) => `announcementbyuser/${slug}`,
			transformResponse: ({ results }) => results,
			providesTags: ["getAnnonceByUser"],
		}),
		getSimilarAnnonce: build.query<IAnnonce[], string>({
			query: (slug) => `announcement_similarbyvisitor/${slug}`,
			providesTags: ["getAnnonceByUser"],
		}),
		// get Annonce by slug
		findAnnonceBySlug: build.query<IAnnonce, string | undefined>({
			query: (slug) => ({
				url: `announcement/${slug}/`,
			}),
			providesTags: ["findAnnonceBySlug"],
		}),
		findAnnonceBySlugForVisitor: build.query<IAnnonce, string | undefined>({
			query: (slug) => ({
				url: `announcementbyvisitor/${slug}/`,
			}),
			providesTags: ["findAnnonceBySlug"],
		}),
		getAnnoncesFavoriteByUser: build.query<IFavAnnonce[], void>({
			query: () =>
				`favorite_announcebyuser/${
					AppStorage.getItem<AuthState>("user", {}).user?.slug
				}`,
			providesTags: ["favoritesAnnonceByUser"],
			transformResponse: ({ results }) => results,
		}),
		getBoostedAnnonce: build.query<IAnnonce[], void>({
			query: () => `boostedannouncement`,
			providesTags: ["boostedAnnonce"],
			transformResponse: ({ results }) => results,
		}),
		getSocialBoostedAnnonce: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: (query) => QueryUrl(`socialboostedannouncement`, query),
			providesTags: ["socialBoostedAnnonce"],
		}),
		getTopAnnonce: build.query<PaginationResults<IAnnonce>, TypeQuery>({
			query: (query) => QueryUrl(`boostedannouncement`, query),
			providesTags: ["boostedAnnonce"],
		}),
		// delete social boost annonce
		deleteSocialBoostAnnonce: build.mutation<IAnnonce, string>({
			query: (slug) => ({
				url: `removesocialboostannouncement/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: ["socialBoostedAnnonce", "annonce"],
		}),
		// delete boost annonce
		deleteBoostAnnonce: build.mutation<IAnnonce, string>({
			query: (slug) => ({
				url: `removeboostannouncement/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: ["boostedAnnonce", "annonce"],
		}),
		boostAnnonce: build.query<TBoostResponse, TBoostData>({
			query: (data) => ({
				url: QueryUrl(`${ApiBaseUrl}api/${data?.endpoint}/${data?.slug}`, {
					amount: data?.amount,
					env: data?.env,
					duration: data?.duration,
					promo: data?.type,
				}),
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetAnnoncementQuery,
	useGetAnnonceVisitorsQuery,
	useLazyGetAnnoncementQuery,
	useLazyAnnonceByUserQuery,
	useDepotAnnonceMutation,
	useDeleteAnnonceMutation,
	useEditAnnonceMutation,
	useLazyFindAnnonceBySlugQuery,
	useLazyGetAnnoncesFavoriteByUserQuery,
	useGetAnnonceVisitorsFilterQuery,
	useGetBoostedAnnonceQuery,
	useGetSocialBoostedAnnonceQuery,
	useGetTopAnnonceQuery,
	useDeleteSocialBoostAnnonceMutation,
	useDeleteBoostAnnonceMutation,
	useLazyBoostAnnonceQuery,
	useFindAnnonceBySlugForVisitorQuery,
	useLazyFindAnnonceBySlugForVisitorQuery,
	useGetSimilarAnnonceQuery,
	useAnnonceByUserQuery,
} = AnnonceApi;

export function useAnnonceFromLocation(): [IAnnonce, boolean, string, (slug: string) => any] {
	const itemState = useLocationState<IAnnonce | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyFindAnnonceBySlugQuery();
	const navigate = useNavigate();

	// useEffect(() => {
	// 	if (slug) {
	// 		findBySlug(slug as string);
	// 	}
	// }, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as IAnnonce, isLoading, slug as string, findBySlug];
}