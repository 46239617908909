import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { RegisterFormData } from "../../../../utils/api/auth/auth.type";
import { useRegisterUserMutation } from "../../../../utils/api/auth/auth.api";
import moment from "moment";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import { cleannerError } from "../../../../utils/utils";

export function validatePassword(Yup, message) {
	return Yup.string()
		.required(message)
		.matches(/[a-z]+/, "Le mot de passe doit contenir au moins une lettre minuscule.")
		.matches(/[A-Z]+/, "Le mot de passe doit contenir au moins une lettre majuscule.")
		.matches(
			/[!@#$%^&*(),;-_+*/.?":{}|<>]+/,
			"Le mot de passe doit contenir au moins un caractère spécial."
		)
		.matches(/\d+/, "Le mot de passe doit contenir au moins un chiffre.")
		.min(8, "Le mot de passe doit être au moins 8 caractères");
}

function useRegisterForm() {
	const validationSchema = yup.object().shape({
		lastname: yup.string().required("Le nom est requis"),
		firstname: yup.string().required("Le prenom est requis"),
		civilite: yup.string(),
		adresse: yup.string().required("L'adresse est obligatoire"),
		// postal_code: yup.string(),
		birthdate: yup.date().typeError("Invalide Date"),
		phone: yup.string().required("Le numéro de téléphone est requis"),
		whatsapp: yup.string(),
		email: yup.string().email("email invalide").required("l'email est requis"),
		password: validatePassword(yup, "Le mot de passe est requis"),
		confirmPassword: yup
			.string()
			.oneOf(
				[yup.ref("password"), null],
				"veuillez entrer des mots de passe identiques."
			),
		condition: yup.bool().oneOf([true], "Accepter les conditions est requis"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<RegisterFormData>({
		resolver: yupResolver(validationSchema),
	});

	const navigate = useNavigate();

	//  Calling the Register Mutation
	const [registerUser, { isLoading, isSuccess, error, isError }] = useRegisterUserMutation();

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Inscription réussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/app/se-connecter", { replace: true });
			});
		}
		const err = error as any;
		if (isError) {
			if (err?.data?.email?.includes("user with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "Cet email existe déjà.",
					showConfirmButton: false,
					timer: 2000,
				});
			} else {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de votre inscription",
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isLoading]);
	useEffect(() => {
		if (errors.adresse) {
			window.scrollTo(50, 50);
		}
		cleannerError(errors, clearErrors, 5000);
	}, [errors]);

	const onSubmit = (data: RegisterFormData) => {
		if (data.birthdate) {
			const formatBirthDate = moment(data.birthdate).format("YYYY-MM-DD");
			const userData = { ...data, birthdate: formatBirthDate };
			registerUser(userData);
		} else {
			registerUser(data);
		}
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
}

export default useRegisterForm;
