import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { ApiBaseUrl } from '../../http'
import { AppStorage } from '../../storage'
import { prepareHeaders } from '../user/user.api'
import { Seller, SellerFormData } from '../seller/seller.type'
import { PaginationResults, UserTypeQuery } from '../commerce/commerce.type'
import { QueryUrl } from '../../utils'

const tags = {
  seller: 'seller',
  sellers: 'sellers',
  sellerBySlug: 'sellerBySlug',
  sellerForVisitor: 'sellerForVisitor',
}
export const SellerApi = createApi({
  reducerPath: 'sellerAPi',
  tagTypes: Object.values(tags),
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getSeller: builder.query<Seller[], void>({
      query: () => 'seller/',
      transformResponse: ({ results }) => results,
      providesTags: [tags.seller],
    }),
    getSellerFilter: builder.query<PaginationResults<Seller>, UserTypeQuery>({
      query: (query) => QueryUrl(`sellerbyfilter/`, query),
      providesTags: [tags.sellers],
    }),
    addSeller: builder.mutation<Seller, SellerFormData | any>({
      query: (data) => ({
        url: '/seller/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [tags.sellers],
    }),
    SellerBySlug: builder.query<Seller, string>({
      query: (slug) => `seller/${slug}/`,
      providesTags: [tags.sellerBySlug],
    }),
    editSeller: builder.mutation<Seller, SellerFormData | any>({
      query: ({ slug, formData }) => ({
        url: `/user/${slug}/`,
        body: formData,
        method: 'PUT',
      }),
      invalidatesTags: [tags.sellers],
    }),
    archiveSeller: builder.mutation<Seller, SellerFormData | any>({
      query: ({ slug, ...data }) => ({
        url: `/user/${slug}/`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: [tags.sellers],
    }),
    deleteSeller: builder.mutation<Seller, string>({
      query: (id) => ({
        url: `user/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: [tags.sellers],
    }),
    getSellerForVisitor: builder.query<Seller[], void>({
      query: () => 'sellerbyvisitor/',
      providesTags: [tags.sellerForVisitor],
      transformResponse: ({ results }) => results,
    }),
  }),
})

export const {
  useGetSellerQuery,
  useAddSellerMutation,
  useEditSellerMutation,
  useLazySellerBySlugQuery,
  useDeleteSellerMutation,
  useGetSellerForVisitorQuery,
  useGetSellerFilterQuery,
  useArchiveSellerMutation,
} = SellerApi
