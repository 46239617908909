import React from 'react'
import { Outlet } from 'react-router-dom'
import SideBarAdmin from './Sidebar/SidebarAdmin'
import TopBar from './TopBar/TopBar'
import './AdminLayout.css'
import '../../user/MyCustumerArea/MyCustumerArea.css'
import '../../homePage/HomePage.css'
const AdminLayout = () => {
  return (
    <div className="admin-page">
      <TopBar />
      <SideBarAdmin />
      <div className="admin-component flex-r">
        <div className="admin-page-container">
          <div className="px-2">
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  )
}

export default AdminLayout
