import { useEffect } from 'react'
import { BtnNext } from '../../../../../common/Button'
import { CustomInput, FormError } from '../../../../../common/CustomInput'
import { CustomSelectInput } from '../../../../../common/CustomSelectInput'

function InfosBoutique({
  setValue,
  register,
  formState,
  options,
  option,
  setOption,
}) {
  useEffect(() => {
    console.log('option', option)
  }, [option])
  let cat = option
  return (
    <div>
      <h4 className="stepper-title">Détails de la boutique </h4>
      <div>
        <div className="row">
          <CustomInput
            label="Nom d’affichage / Nom de la boutique"
            type="text"
            required
            {...register('name')}
            error={formState?.errors?.name?.message}
          />
          <div className="col-md-6 form-group mb-4">
            <label htmlFor="nombre_stock" className="custom-form-label">
              Catégorie principale de vos produits{' '}
              <span className="text-danger">*</span>
            </label>

            <select
              className="form-select espace-client-form-select-control"
              //   {...register('category')}
              onChange={(e) => {
                setValue('category', e.target.value)
                setOption(e.target.value)
              }}
              value={option}
              id="category"
            >
              <option value="">Catégorie Principale de vos produits</option>
              {options
                ?.sort(function (a, b) {
                  return a?.label?.localeCompare(b?.label)
                })
                ?.map((item) => (
                  <option value={item?.value} key={item?.value}>
                    {' '}
                    {item?.label}{' '}
                  </option>
                ))}
            </select>
            <FormError error={formState?.errors?.category?.message} />
          </div>

          <CustomInput
            label="Adresse mail"
            type="email"
            name="email"
            required
            {...register('email')}
            error={formState?.errors?.email?.message}
          />
          <CustomInput
            label="N° téléphone"
            name="phone"
            type="tel"
            required
            {...register('phone')}
            error={formState?.errors?.phone?.message}
          />
          <CustomInput
            label="Whatsapp / N° téléphone alternatif"
            name="whatsapp"
            type="tel"
            {...register('whatsapp')}
            error={formState?.errors?.whatsapp?.message}
          />
          <CustomInput
            label="Personne responsable"
            name="resp-people"
            type="text"
            {...register('person_in_charge')}
            error={formState?.errors?.person_in_charge?.message}
          />
        </div>
        <div className="flex-r gap-3 mt-4">
          {/* <BtnSkip label="Passer l’étape" type="button" /> */}
          <BtnNext label="Suivant" type="submit" />
        </div>
      </div>
    </div>
  )
}

export default InfosBoutique
