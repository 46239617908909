import AddSlideModal from "../../modals/adminModal/AddSlideModal";
import SlidesTable from "../../Tables/Admin/SlidesTables/SlidesTable";
import "./SettingAdmin.css";

const BannerSlider = () => {
	return (
		<div className="tab-content-admin">
			<SlidesTable />
			<AddSlideModal />
		</div>
	);
};

export default BannerSlider;
