import moment from "moment";
import { INetwork } from "../../../../utils/api/network/network.type";
import {
	createMarkup,
	getCountryCodeByCountryName,
	getImage,
	useLocationState,
} from "../../../../utils/utils";
import { useAnnonceByUserQuery } from "../../../../utils/api/annonce/annonce.api";
import { AnnonceItem, AnnonceItemSkeleton } from "./AnnoncesTabs";
import { AlertInfo } from "../../../common/Alert";
import { BsChatDots, BsTelephone, BsWhatsapp } from "react-icons/bs";
import { MdEdit, MdOutlineEmail } from "react-icons/md";
import { Link } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import ReactCountryFlag from "react-country-flag";

const DetailPro = () => {
	const item = useLocationState<INetwork>(undefined);
	const { user } = useAppSelector((s) => s?.user);
	const { data, isLoading } = useAnnonceByUserQuery(item?.user?.slug);

	return (
		<div className="reseau-tab">
			<div className="row">
				<div className="col-12 col-xl-10 offset-xl-1">
					<div className="b-b-1 mb-4">
						<div className="flex-c mb-3">
							<img
								src={getImage(item?.user?.avatar)}
								alt="user-name"
								className="reseau-item-img"
							/>
						</div>
						<div className="text-center">
							<h4 className="reseau-user-role fw-600 m-0">{item?.identity_code}</h4>
							<h4 className="reseau-user-name">
								{item?.user?.firstname} {item?.user?.lastname}
								<ReactCountryFlag
									countryCode={getCountryCodeByCountryName(item?.country) ?? ""}
									style={{
										fontSize: "24px",
										lineHeight: "24px",
									}}
								/>
							</h4>
							<h4 className="reseau-user-role fw-300">{item?.fonction}</h4>
							<h4 className="reseau-user-role fw-300">{item?.metier}</h4>
							<h4 className="reseau-user-role fw-300">{item?.adress}</h4>
						</div>
						<div className="about-pro-container mt-4">
							<div className="d-flex justify-content-between align-items-center">
								<h5 className="titre-page-tab-content">A propos</h5>
								{item?.user?.id === user?.id && (
									<Link
										to="/app/mon-espace-client/parametres"
										className="btn-edit-pro"
										state={1}
									>
										<MdEdit />
									</Link>
								)}
							</div>
							<div
								className="about-pro"
								dangerouslySetInnerHTML={createMarkup(item?.expertise_pro)}
							/>
						</div>
						<div className="flex-sb-m mt-4 mb-2">
							<p className="pro-detail-text fw-300">Société</p>
							<p className="pro-detail-text fw-500">{item?.society_name}</p>
						</div>
						<div className="flex-sb-m mb-5">
							<p className="pro-detail-text fw-300">Membre depuis</p>
							<p className="pro-detail-text fw-500">
								{moment(item?.created_at).format("MMMM YYYY")}
							</p>
						</div>
					</div>
					<div className="contacts mb-5">
						<h5 className="titre-page-tab-content">Contacts</h5>
						<div className="flex-sa py-3 my-3 detail-pro-contact">
							<Link
								to="/app/mon-espace-client/messages"
								className="constainer-icon-socials-media"
								state={item?.user}
							>
								<div className="icon-socials-media">
									<BsChatDots />
								</div>
								<p className="text-icon-socials-media">Envoyer un message</p>
							</Link>
							{item?.user?.email && (
								<a
									href={`mailto:${item?.user?.email}`}
									className="constainer-icon-socials-media"
								>
									<div className="icon-socials-media">
										<MdOutlineEmail />
									</div>
									<p className="text-icon-socials-media">Envoyer un email</p>
								</a>
							)}
							{item?.user?.whatsapp && (
								<a
									href={`https://wa.me/${item?.user?.whatsapp}}`}
									className="constainer-icon-socials-media"
									target="_blank"
								>
									<div className="icon-socials-media">
										<BsWhatsapp />
									</div>
									<p className="text-icon-socials-media">Whatsapp</p>
								</a>
							)}
							{item?.user?.phone && (
								<a
									href={`tel:${item?.user?.phone}`}
									className="constainer-icon-socials-media"
								>
									<div className="icon-socials-media">
										<BsTelephone />
									</div>
									<p className="text-icon-socials-media">Appeler</p>
								</a>
							)}
						</div>
					</div>
				</div>
				<div className="col-12 col-xl-10 offset-xl-1">
					<div>
						<h4 className="pro-detail-text">
							<span className="fw-400">Les Annonces de </span>
							<span className="fw-600">{item?.user?.firstname}</span>
						</h4>
					</div>
					<div className="row mt-4">
						{isLoading && (
							<>
								{[...Array(4)].map((elem, index) => (
									<AnnonceItemSkeleton key={index} />
								))}
							</>
						)}
						{!isLoading && data && data?.length > 0 ? (
							data?.map((annonce) => (
								<div className="col-md-6" key={annonce?.slug}>
									<AnnonceItem key={annonce.id} annonce={annonce} />
								</div>
							))
						) : (
							<AlertInfo message="Aucune annonce trouvée" />
						)}
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailPro;
