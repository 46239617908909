import Divider from "../../../shared/Divider";
import pdf from "../../../assets/icons/pdf.png";
import { useState } from "react";
import PreviewFileModal from "../../modals/user/PreviewFileModal";
import ChangeCandidatureStatusModal from "../../modals/user/ChangeCandidatureStatusModal";
import { useCandidatureFromLocation } from "../../../../utils/api/candidature/candidature.api";

const DetailCandidatureForProject = () => {
	const [item] = useCandidatureFromLocation();
	const [file, setFile] = useState<string>("");
	const handleChangeFile = (file: string) => {
		setFile(file);
	};
	return (
		<div className="mes-candidatures-tabs-component detail-candidature">
			<div className="candidatures-list">
				<div className="flex-sb mb-4">
					<h3 className="detail-candidature-title">
						Recrutement Infographe expérimenté - CDD
					</h3>
					<button
						className={`bg-status-candidature ${
							item?.status === "nouvelle"
								? "pending"
								: item?.status === "acceptee"
								? "bg-success"
								: "bg-danger"
						}`}
						data-bs-toggle="modal"
						data-bs-target="#ChangeCandidatureStatusModal"
					>
						{item?.status === "nouvelle"
							? "En attente"
							: item?.status === "acceptee"
							? "Acceptée"
							: "Réfusée"}
					</button>
				</div>
				<div className="candidat-info-bloc mb-3">
					<h5>Titre candidat</h5>
					<h6>{item?.titre_candidat}</h6>
				</div>
				<div className="row">
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Adresse candidat</h5>
						<h6>{item?.adresse}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Téléphone candidat</h5>
						<h6>{item?.phone}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Whatshapp candidat</h5>
						<h6>{item?.candidat?.whatsapp}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Email candidat</h5>
						<h6>{item?.candidat?.email}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Prétention salariale</h5>
						<h6>
							{item?.pretention_salariale}{" "}
							{item?.device ? item?.device : "FCFA"}
						</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Années d’expériences</h5>
						<h6>{item?.experience_years}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Niveau d’étude</h5>
						<h6 className="text-capitalized">{item?.level_study}</h6>
					</div>
					<Divider />
					<p className="pieces-jointes my-2">Pièces jointes</p>
					<Divider />
					<div className="d-flex gap-3 mt-3">
						{item?.cv && (
							<button
								className="button-file p-1 gap-3"
								onClick={() => handleChangeFile(item.cv)}
								data-bs-toggle="modal"
								data-bs-target="#PreviewFileModal"
							>
								<img src={pdf} alt="icon-pdf" />
								<span>Curriculum vitae</span>
							</button>
						)}
						{item?.modification_letter && (
							<button
								className="button-file p-1 gap-3"
								onClick={() =>
									handleChangeFile(item.modification_letter)
								}
								data-bs-toggle="modal"
								data-bs-target="#PreviewFileModal"
							>
								<img src={pdf} alt="icon-pdf" />
								<span>Lettre de motivation</span>
							</button>
						)}
					</div>
				</div>
				<PreviewFileModal fileUrl={file} />
				<ChangeCandidatureStatusModal item={item} />
			</div>
		</div>
	);
};

export default DetailCandidatureForProject;
