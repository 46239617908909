import CloseModalBtn from "../../../shared/CloseModalBtn";
import * as yup from "yup";
import "../../admin/MessageAdmin/MessageAdmin.css";
import { IContact, TResponseContact } from "../../../../utils/api/contact/contact.type";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import ErrorMessage from "../../../common/ErrorMessage";
import React, { useEffect } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { cleannerError, createMarkup, onHide } from "../../../../utils/utils";
import { useResponseContactMutation } from "../../../../utils/api/contact/contact.api";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import ReactQuill from "react-quill";

const DetailsMessageModal = ({ item }: { item?: IContact }) => {
	const [response, setResponse] = React.useState<any>("");
	const validationSchema = yup.object().shape({
		response: yup.string().required().label("La réponse"),
	});
	const { user } = useAppSelector((s) => s.user);
	const [responseContact, { isSuccess, isError, error, isLoading }] =
		useResponseContactMutation();
	const {
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<TResponseContact>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (item) {
			setValue("message", item?.id);
			setValue("user", user?.id);
		}
	}, [item]);
	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Réponse envoyé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("DetailsMessageModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: "Une erreur a survenue lors de l'envoie",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		setResponse("");
	}, []);

	const handleChange = (value: any) => {
		setResponse(value);
		setValue("response", value);
	};

	const onSubmit = (data: TResponseContact) => {
		responseContact(data);
	};
	return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content py-2">
				<div className="munngano-modal-header d-flex justify-content-between align-items-center px-3">
					<h1 className="munngano-modal-title">
						Réponse à <span>{item?.name}</span>
					</h1>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<form onSubmit={handleSubmit(onSubmit)}>
						<div className="row border-bottom pb-2">
							<div className="col-md-12 form-group">
								<h2 className="munngano-modal-title title-message">
									Contenu du message
								</h2>
								<div
									className="admin-form-label"
									dangerouslySetInnerHTML={createMarkup(
										item?.message
									)}
								/>
							</div>
						</div>
						<div className="row mb-4 mt-4">
							<div className="col-12 form-group">
								<label
									htmlFor="desc-mision"
									className="admin-form-label"
								>
									Réponse
								</label>
								<ReactQuill
									className="editor-cgu"
									value={response}
									onChange={handleChange}
								/>
								{errors?.response && (
									<ErrorMessage
										message={errors?.response?.message}
									/>
								)}
							</div>
						</div>
						<div className="btn-container gap-3 justify-content-end d-flex">
							<button className="admin-btn-add admin-btn-typo">
								{isLoading ? "Chargement..." : "Envoyer"}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default DetailsMessageModal;
