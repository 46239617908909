import { NavLink } from 'react-router-dom'
import Logo from '../../../../assets/appImages/logo.png'
import AdminMenu from '../../AdminRoutes/AdminRoutes'
import './SidebarAdmin.css'

const SideBarAdmin = () => {
  return (
		<div className="sidebar-admin d-none d-md-block" id="scrollbar">
			<NavLink to="/" className="logo-container flex-c-m pt-4">
				<img src={Logo} alt="logo" className="app-logo" />
			</NavLink>
			<AdminMenu />
		</div>
  );
}

export default SideBarAdmin
