import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { IActivite } from "./activite.type";

export const activiteApi = createApi({
	reducerPath: "activiteApi",
	tagTypes: ["activityByUser"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getActivityByUser: builder.query<IActivite[], string>({
			query: (slug) => `activitybyuser/${slug}/`,
			providesTags: ["activityByUser"],
		}),
		deleteActivity: builder.mutation<IActivite, string | number>({
			query: (id) => ({
				url: `activity/${id}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["activityByUser"],
			onCacheEntryAdded(arg, { dispatch }) {
				dispatch(activiteApi.util.invalidateTags(["activityByUser"]));
			},
		}),
	}),
});

export const { useLazyGetActivityByUserQuery, useDeleteActivityMutation } = activiteApi;
