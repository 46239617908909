import CloseModalBtn from '../../../shared/CloseModalBtn'
import '../../admin/MessageAdmin/MessageAdmin.css'
import { Conditions } from '../../../../utils/api/conditions/condition.type'
import React from 'react'
import UseEditConditionForm from '../../admin/SettingAdmin/requestSettings/UseEditConditionForm'
import ReactQuill from 'react-quill'
import ErrorMessage from '../../../common/ErrorMessage'

type PropsType = {
  contenu: Conditions
}
const EditContenuModal = ({ contenu }: PropsType) => {
  const {
    register,
    setValue,
    clearErrors,
    onSubmit,
    formState,
    isLoading,
    isSuccess,
    reset,
  } = UseEditConditionForm()
  const [description, setDescription] = React.useState<any>('')
  const handleChange = (value: any) => {
    setDescription(value)
    setValue('text', value)
  }

  React.useEffect(() => {
    if (contenu) {
      setValue('type', contenu?.type)
      setValue('slug', contenu?.slug)
      setValue('text', contenu?.text)
      setDescription(contenu?.text || '')
    }
  }, [contenu])

  React.useEffect(() => {
    if (formState.errors) {
      setTimeout(() => {
        Object.entries(formState.errors).map(([key]: any) => {
          return clearErrors(key)
        })
      }, 3000)
    }
  }, [formState])

  return (
		<div className="modal-dialog modal-dialog-centered modal-lg">
			<div className="modal-content py-2">
				<div className="munngano-modal-header d-flex justify-content-between align-items-center px-3">
					<h1 className="munngano-modal-title">
						Modifier{" "}
						{contenu?.type === "cgu"
							? "conditions générales d’utilisation"
							: contenu?.type === "pc"
							? "politiques de confidentialités"
							: "Mentions légales"}
					</h1>
					<CloseModalBtn />
				</div>
				<div className="form-container px-3">
					<form onSubmit={onSubmit}>
						<div className="row mb-4">
							<div className="col-12 form-group">
								<label
									htmlFor="desc-mision"
									className="admin-form-label"
								>
									Text
								</label>
								<ReactQuill
									className="editor-cgu"
									value={description}
									onChange={handleChange}
								/>
								{formState?.errors.text && (
									<ErrorMessage
										message={
											formState?.errors.text?.message
										}
									/>
								)}
							</div>
						</div>
						<div className="btn-container gap-3 justify-content-end d-flex">
							<button
								className="admin-btn-cancel admin-btn-typo"
								data-bs-dismiss="modal"
								id="btn-close"
								onClick={(e) => {
									e.preventDefault();
								}}
							>
								Annuler
							</button>
							<button
								className="admin-btn-add admin-btn-typo"
								type="submit"
								disabled={isLoading}
							>
								{!isLoading ? (
									"Modifier"
								) : (
									<>
										En cours&nbsp;
										<i className="fas fa-spin fa-spinner"></i>
									</>
								)}
							</button>
						</div>
					</form>
				</div>
			</div>
		</div>
  );
}

export default EditContenuModal
