import { InfoWindow } from "google-maps-react";
import { GoogleApiWrapper } from "google-maps-react";
import { Map, Marker } from "google-maps-react";
import { useState } from "react";
import { BsTelephoneFill } from "react-icons/bs";
import { HiHome } from "react-icons/hi";
import { MdEmail } from "react-icons/md";
import { Color } from "../../../utils/theme";
import Divider from "../Divider";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
import ContactForm from "./ContactForm";
import "./NousContactez.css";

const iconStyle = { color: Color.themeColor, fontSize: 22 };
const NousContactez = (props) => {
	const [showingInfoWindow, setShowingInfoWindow] = useState(false);
	const [activeMarker, setActiveMarker] = useState<any>({});
	const [selectedPlace, setSelectedPlace] = useState({});
	const onMapClicked = () => {
		if (showingInfoWindow) {
			setActiveMarker(null);
			setShowingInfoWindow(false);
		}
	};
	const onMarkerClick = (props, marker) => {
		setActiveMarker(marker);
		setSelectedPlace(props);
		setShowingInfoWindow(true);
	};
	const onInfoWindowClose = () => {
		setActiveMarker(null);
		setShowingInfoWindow(false);
	};
	return (
		<>
			<FrontHeader />

			<div className="nous-contactez">
				<h1 className="my-4">Contactez-nous</h1>
				<Divider />
				<div className="row py-5  container-munngano">
					<div className="col-md-5">
						<div className="mb-4">
							<h3>Entrer en contact</h3>
							<p className="contact-text">
								Lorem ipsum dolor sit amet, consectetur adipiscing
								elit, sed do eiusmod tempor incididunt ut labore et
								dolore magna aliqua.
							</p>
						</div>
						<div className="d-flex mb-3 gap-3">
							<div className="icon-contact">
								<HiHome style={iconStyle} />
							</div>
							<div>
								<h5>Rejoingnez-nous</h5>
								<address>
									17-19 Avenue Kaira, Centre commercial Madiba,
									Brufut Gardens Estate, PO Case postale 121,
									Gambie
								</address>
							</div>
						</div>
						<div className="d-flex mb-3 gap-3">
							<div className="icon-contact">
								<MdEmail style={iconStyle} />
							</div>
							<div>
								<h5> Laissez-nous un mail</h5>
								<a
									href="mailto:info@munnago.com"
									className="contact-link"
								>
									info@munnago.com
								</a>
								<a
									href="mailto:info@munnago.com"
									className="contact-link"
								>
									info@munnago.com
								</a>
							</div>
						</div>
						<div className="d-flex mb-3 gap-3">
							<div className="icon-contact">
								<BsTelephoneFill style={iconStyle} />
							</div>
							<div>
								<h5> Appelez-nous </h5>
								<a
									href="mailto:info@munnago.com"
									className="contact-link"
								>
									+34 60776-2333
								</a>
								<a
									href="mailto:info@munnago.com"
									className="contact-link"
								>
									+34 648058094194
								</a>
							</div>
						</div>
					</div>
					<div className="col-md-7">
						<ContactForm />
					</div>
				</div>
				<div
					style={{
						height: "500px",
						width: "100%",
					}}
					className="mt-3 mb-5"
				>
					<Map
						google={props.google}
						zoom={14}
						initialCenter={{
							lat: 14.66927,
							lng: -17.43661,
						}}
						style={{ with: "100%", height: "500px" }}
						onClick={onMapClicked}
					>
						<Marker
							name=""
							clickable
							onClick={onMarkerClick}
							position={{
								lat: 14.66927,
								lng: -17.43661,
							}}
						/>
						<InfoWindow
							marker={activeMarker}
							onClose={onInfoWindowClose}
							visible={showingInfoWindow}
							position={{
								lat: 14.66927,
								lng: -17.43661,
							}}
						>
							<div>
								<h4
									style={{
										color: "#FFA73C",
									}}
								>
									Munngano
								</h4>
							</div>
						</InfoWindow>
					</Map>
				</div>
				<Divider styles="my-3" />
			</div>
			<FrontFooter />
		</>
	);
};

export default GoogleApiWrapper({
	apiKey: "AIzaSyD0klc1PQS8QK--Be3rF3i8kW_idy04AVQ",
})(NousContactez);
