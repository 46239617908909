import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoginFormData } from "../../../../../utils/api/auth/auth.type";
import { useLoginUserMutation } from "../../../../../utils/api/auth/auth.api";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useLocation, useNavigate } from "react-router-dom";
import { onSetUserToken } from "../../../../../redux/slice/User.slice";
import { useAppDispatch } from "../../../../../redux/hooks";
function useLoginForm() {
	//  Calling the login user Mutation
	const [loginUser, { isLoading, isSuccess, data, error, isError }] = useLoginUserMutation();
	const navigate = useNavigate();
	const dispatch = useAppDispatch();
	const location = useLocation();
	const from = ((location.state as any)?.from.pathname as string) || "/app/admin/tableau-de-bord";
	const validationSchema = yup.object().shape({
		email: yup.string().email("email invalide").required("L'email est requis"),
		password: yup.string().required("Le mot de passe est requis"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setError,
		clearErrors,
	} = useForm<LoginFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (data) {
			dispatch(onSetUserToken({ user: data.data, token: data.token }));
			setTimeout(() => {
				navigate(from, { replace: true });
			}, 1200);
		}
	}, [data]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Connexion réussie!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1000,
			});
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			setError("email", { message: message });
		}
	}, [isLoading]);

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 3000);
		}
	}, [errors]);

	const onSubmit = (data) => {
		loginUser(data);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		isLoading,
	};
}

export default useLoginForm;
