import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { DeliveryFormData } from '../../../../../utils/api/deliveyMode/delivery.type'
import { useEditDeliveryMutation } from '../../../../../utils/api/deliveyMode/delivery.api'
import { cleannerError, onHide } from '../../../../../utils/utils'
import { Color } from '../../../../../utils/theme'

function UseEditDeliveryMode() {
  const validationSchema = yup.object().shape({
    duration: yup.string().required().label('La durée'),
    name: yup.string().required().label('Le nom'),
    amount: yup.string().required().label('Le montant'),
    description: yup.string().nullable(),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm<DeliveryFormData | any>({
    resolver: yupResolver(validationSchema),
  })
  const [
    editData,
    { isLoading, isSuccess, isError, error, data },
  ] = useEditDeliveryMutation()
  const [description, setDescription] = useState<string>('')

  useEffect(() => {
    if (errors) {
      for (let key of Object.keys(errors)) {
        if (key) {
          window.scrollTo(10, 10)
        }
      }
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      console.log('data', data)

      onHide(`EditDeliveryMode${data?.slug}`)
      Swal.fire({
        icon: 'success',
        title: 'Mode de livraison modifié avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {})
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log(formValues)

    await editData(formValues)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading,
    setError,
    reset,
    description,
    setDescription,
  }
}

export default UseEditDeliveryMode
