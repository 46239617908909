import React from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";

function OffresTableTableSkeleton() {
	return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								fontFamily: "Poppins",
								fontStyle: "normal",
								fontWeight: "600",
								fontSize: "14px",
								lineHeight: "21px",
								color: "rgba(0, 0, 0, 0.7)",
							}}
						>
							Type
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Poppins",
								fontStyle: "normal",
								fontWeight: "600",
								fontSize: "14px",
								lineHeight: "21px",
								color: "rgba(0, 0, 0, 0.7)",
							}}
						>
							Prix
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Poppins",
								fontStyle: "normal",
								fontWeight: "600",
								fontSize: "14px",
								lineHeight: "21px",
								color: "rgba(0, 0, 0, 0.7)",
							}}
						>
							Durée
						</th>
						<th
							scope="col"
							style={{
								fontFamily: "Poppins",
								fontStyle: "normal",
								fontWeight: "600",
								fontSize: "14px",
								lineHeight: "21px",
								color: "rgba(0, 0, 0, 0.7)",
							}}
						>
							Actions
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton variant="rect" width={30} height={30} />
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton variant="rect" width={30} height={30} />
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton variant="rect" width={30} height={30} />
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton variant="rect" width={30} height={30} />
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
	);
}

export default OffresTableTableSkeleton;
