import { AiTwotoneHeart } from "react-icons/ai";
import StarRatingComponent from "react-star-rating-component";
import { IAnnonce } from "../../../../utils/api/annonce/annonce.type";
import { IProject } from "../../../../utils/api/project/project.type";
import {
	calculteRating,
	createMarkup,
	formattedDate,
	formattedTypeAnnonce,
	getImage,
} from "../../../../utils/utils";
import CloseModalBtn from "../../../shared/CloseModalBtn";

const DetailProjetModal = ({ item }: { item?: IProject }) => {
	return (
		<div
			className="modal fade"
			id="DetailProjetModal"
			aria-labelledby="DetailProjetModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id="DetailProjetModal"
						>
							{item?.title}
						</h5>
						<CloseModalBtn />
					</div>
					<div className="modal-body d-block">
						<div className="card-img-top text-center mb-4">
							{item && (
								<img
									src={getImage(item.images[0]?.picture)}
									alt={item.slug}
									className="card-img-top h-200 object-cover"
								/>
							)}
						</div>
						<div
							className="text-description-admin mb-2"
							dangerouslySetInnerHTML={createMarkup(item?.description)}
						/>
						<div className="pt-3 projet-admin-detail">
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Grille salariale:
									</p>
								</div>
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-libelle">
										{item?.budget[0]?.name}
									</p>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Type de contrat:
									</p>
								</div>
								<div className="col-md-4 pb-2">
									<p
										className={`texte-detail-annonce-info-libelle ${
											item?.type_contract === "cdi" ||
											item?.type_contract === "cdd"
												? "text-uppercase"
												: "text-capitalize"
										}`}
									>
										{item?.type_contract}
									</p>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Date d’expiration:
									</p>
								</div>
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-libelle">
										{formattedDate(item?.date_expiration)}
									</p>
								</div>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Compétences requises:
									</p>
								</div>
								<div
									className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
									dangerouslySetInnerHTML={createMarkup(
										item?.skills
									)}
								/>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Prérequis du projet :
									</p>
								</div>
								<div
									className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
									dangerouslySetInnerHTML={createMarkup(
										item?.prerequisite
									)}
								/>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Exigences du projet:
									</p>
								</div>
								<div
									className="col-md-8 pb-2 texte-detail-annonce-info-libelle"
									dangerouslySetInnerHTML={createMarkup(
										item?.requirements
									)}
								/>
							</div>
							<div className="row pb-2">
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-value">
										Secteur et Metier:
									</p>
								</div>
								<div className="col-md-4 pb-2">
									<p className="texte-detail-annonce-info-libelle">
										{item?.sector?.name}
									</p>
								</div>
							</div>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(item?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#F5F5F5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div>
								<AiTwotoneHeart className="icon-like-annonce" />
								<span className="annonce-like ps-1">
									{item?.favorites?.length}
								</span>
							</div>
							<div>
								<p className="date-annonce">
									{formattedDate(item?.created_at)}
								</p>
							</div>
						</div>
						<div className="flex-r-m gap-2 author-container pt-4">
							<span>par</span>
							<strong>{`${item?.user?.firstname} ${item?.user?.lastname}`}</strong>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailProjetModal;
