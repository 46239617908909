import React from "react";
import { createMarkup } from "../../../../../utils/utils";

function Expertises({ partner }) {
	return (
		<div className="col-tab-espace-client p-3 mb-3">
			<div className="pb-3 b-b-1">
				<p className="titre-liste-des-commerce">Expertises</p>
			</div>
			<div
				className="pt-2 texte-description-parter"
				dangerouslySetInnerHTML={createMarkup(partner?.expertise)}
			/>
		</div>
	);
}

export default Expertises;
