import Divider from "../Divider";
import FrontFooter from "../FrontFooter/FrontFooter";
import FrontHeader from "../FrontHeader/FrontHeader";
// import about from "../../assets/appImages/about.png";
// import about2 from "../../assets/appImages/about2.png";
import "./About.css";
import Carousel from "react-multi-carousel";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import Team, { TeamItemSkeleton } from "./Team";
import { createMarkup, getAvatar, getImage } from "../../../utils/utils";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useGetAboutQuery } from "../../../utils/api/about/about.api";
import { ITestimonial } from "../../../utils/api/about/about.type";

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 2,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 2,
		itemsToScroll: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		itemsToScroll: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		itemsToScroll: 1,
	},
};

const About = () => {
	const location = useLocation();
	useEffect(() => {
		if ((location.state as any)?.scroll) {
			window.scrollTo(0, (location.state as any).scroll);
		}
	}, [location]);
	const { data, isLoading } = useGetAboutQuery();
	const ideaSection = data?.apropos?.find((item) => item?.type_propos === "idee");
	const historySection = data?.apropos?.find((item) => item?.type_propos === "histoire");
	return (
		<>
			<FrontHeader />

			<div className="about-page">
				<h1 className="my-4">A propos</h1>
				<Divider />
				<div className="py-5  container-munngano">
					{ideaSection && (
						<div className="row mb-5 d-md-flex align-items-center">
							<div className="col-md-6">
								<div className="img-container d-flex justify-content-center justify-content-md-start">
									<img
										src={getImage(ideaSection?.image)}
										alt={ideaSection?.titre}
										className="about-img"
									/>
								</div>
							</div>
							<div className="col-md-6 mt-4">
								<h3 className="about-sous-title">
									{ideaSection?.titre}
								</h3>
								<div
									className="about-text"
									dangerouslySetInnerHTML={createMarkup(
										ideaSection?.content
									)}
								/>
							</div>
						</div>
					)}
					{historySection && (
						<div className="row mb-5 pt-4 d-flex flex-row-reverse ">
							<div className="col-md-6">
								<div className="img-container d-flex justify-content-center justify-content-md-end">
									<img
										src={getImage(historySection?.image)}
										alt={historySection?.titre}
										className="about-img"
									/>
								</div>
							</div>
							<div className="col-md-6 mt-4">
								<h3 className="about-sous-title">
									{historySection.titre}
								</h3>
								<div
									className="about-text"
									dangerouslySetInnerHTML={createMarkup(
										historySection?.content
									)}
								/>
							</div>
						</div>
					)}
					{/* Teams */}
					{!isLoading ? (
						data?.teams &&
						data?.teams?.length > 0 && <Team team={data?.teams} />
					) : (
						<div className="row">
							{[...Array(4)]?.map((item, i) => (
								<TeamItemSkeleton key={i} />
							))}
						</div>
					)}
					{!isLoading ? (
						data?.testimonials &&
						data?.testimonials?.length > 0 && (
							<section className="testimonials-section pt-5">
								<h2 className="about-section-title mb-5">
									Ils parlent de nous
								</h2>
								<Testimonials testimonials={data?.testimonials} />
							</section>
						)
					) : (
						<div className="row">
							{[...Array(2)]?.map((item, i) => (
								<TestmonialItemSkeleton key={i} />
							))}
						</div>
					)}
				</div>
			</div>
			<FrontFooter />
		</>
	);
};

function Testimonials({ testimonials }: { testimonials: ITestimonial[] }) {
	return (
		<div className="row">
			<Carousel
				swipeable={false}
				draggable={false}
				showDots={false}
				responsive={responsive}
				ssr={false}
				infinite={true}
				autoPlay={true}
				autoPlaySpeed={2000}
				keyBoardControl={true}
				arrows={true}
				customTransition="transform 1000ms ease-in-out"
				transitionDuration={2000}
				removeArrowOnDeviceType={["tablet", "mobile"]}
			>
				{testimonials?.map((item, i) => (
					<div className="px-2" key={item?.slug + i}>
						<div className="testimonial-card shadow p-4">
							<div className="testimonial-img-container flex-c">
								<img
									src={getAvatar(item?.avatar)}
									alt=""
									className="rounded-circle"
									style={{ width: "80px", height: "80px" }}
								/>
							</div>
							<div
								className="testimonial-description mt-4 text-center"
								dangerouslySetInnerHTML={createMarkup(
									item?.content.slice(0, 250)
								)}
								style={{ height: "90px" }}
							/>

							<div className="testimonial-infos mt-4 text-center">
								<h5 className="testimonial-name fw-700">
									{item?.nom}
								</h5>
								<h6 className="testimonial-function fw-500">
									{item?.fonction}
								</h6>
							</div>
						</div>
					</div>
				))}
			</Carousel>
		</div>
	);
}

function TestmonialItemSkeleton() {
	return (
		<div className="px-2 col-md-6">
			<div className="testimonial-card shadow p-4">
				<div className="testimonial-img-container flex-c">
					<VolkenoSkeleton
						variant="rect"
						style={{ width: "100px", height: "100px", borderRadius: "50%" }}
					/>
				</div>
				<VolkenoSkeleton variant="rect" style={{ height: "100px" }} />
				<div className="testimonial-infos d-flex flex-column align-items-center mt-3 text-center">
					<VolkenoSkeleton variant="rect" height={10} width={100} />
					<VolkenoSkeleton variant="rect" height={10} width={100} />
				</div>
			</div>
		</div>
	);
}

export default About;
