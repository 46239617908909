import { useEffect, useState } from "react";
import "./PartenaireAdmin.css";
import "../SellerListAdmin/SellerListAdmin.css";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
	useDeletePartenaireMutation,
	useGetPartenaireQuery,
	useValidPartenaireMutation,
} from "../../../../utils/api/partenaire/partenaire.api";
import { Color } from "../../../../utils/theme";
import { getImage } from "../../../../utils/utils";
import "../../user/PartnerList/PartnerList.css";
import Pagination from "../../../common/Pagination";
import "../SellerListAdmin/SellerListAdmin.css";
import "./PartenaireAdmin.css";
import { Partenaire } from "../../../../utils/api/partenaire/partenaire.type";
import VolkenoSkeleton from "volkeno-react-skeleton";
import React from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/router";
const PartenaireAdmin = () => {
	const { data, page, word, setPage, setWord, isLoading } = usePartners();
	const { user } = useAppSelector((s) => s?.user);
	const navigate = useNavigate();
	const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

	React.useEffect(() => {
		if (!user?.partners && !is_SuperAdmin) {
			setTimeout(() => {
				navigate(-1);
			}, 50);
		}
	}, [user]);

	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container d-flex align-items-center gap-1">
					<h1 className="admin-page-title">Annuaires</h1>
					<h3 className="total-items mb-1">| {!isLoading && data?.count} résultats</h3>
				</div>
				<div className="bloc-right-container gap-3">
					<div className="container-input-search-admin">
						<div className="search-box-admin">
							<input
								type="text"
								value={word}
								onChange={(e) => setWord(e.target.value)}
								className="search-input-admin"
								placeholder="Search.."
							/>
							<button className="search-button-admin">
								<i className="fas fa-search"></i>
							</button>
						</div>
					</div>
					<div>
						{/* <NavLink
							className="btn btn-theme p-2"
							to="/app/admin/ajouter-partenaire"
						>
							<MdOutlineAddCircleOutline />
							<span className="ps-2">Ajouter un partenaire</span>
						</NavLink> */}
					</div>
				</div>
			</div>
			<div className="mx-2 tab-content-admin py-4 px-2 px-lg-4">
				<div className="row">
					{isLoading && [...Array(4)].map((elem, index) => <PartnerItemSkeleton key={index} />)}
					{data.results.map((item) => (
						<div className="col-lg-4 col-sm-6 col-xxl-3 my-3" key={item?.slug}>
							<div
								className={`card card-partner-item ${
									!item?.valider ? "non-active" : ""
								}`}
							>
								<Link
									to={`/app/admin/annuaires/${item?.slug}`}
									className="no-link"
									state={{ partner: item }}
								>
									<div className="position-relative">
										<img
											src={getImage(item?.background_image)}
											className="card-img-top card-img-partner"
											alt={item?.slug}
											loading="lazy"
										/>
										<div className="partner-logo-container p-3">
											<img
												src={getImage(item?.icon)}
												alt={item?.slug}
												width="100%"
											/>
										</div>
									</div>
									<div className="card-body p-t-80">
										<h5 className="partner-card-title m-0">{item?.name}</h5>
										<p className="partner-card-text my-2 fw-500 h-20">
											{item?.entreprise_type}
										</p>
										<p className="fw-400 partner-card-text">{item?.ville}</p>
									</div>
								</Link>
								<div className="d-flex justify-content-between align-items-center ps-2">
									<div className="flex-fill">
										{!item?.valider && <ToggleValidPartner item={item} />}
									</div>
									<div className="d-flex gap-1">
										{/* <div>
											<NavLink
												className="btn btn-edit-partner-admin"
												to={
													"/app/admin/modifier-partenaire/" +
													item?.slug
												}
												state={item}
												title="Editer"
											>
												<MdModeEdit />
											</NavLink>
										</div> */}
										<div>
											<DeletePartner item={item} />
										</div>
									</div>
								</div>
							</div>
						</div>
					))}
				</div>
				<Pagination {...{ page, onPageChange: setPage, total: data.count }} />
			</div>
		</div>
	);
};

export default PartenaireAdmin;

export const PartnerItemSkeleton = () => {
	return (
		<div className="col-xl-3 col-lg-4 col-sm-6 my-3">
			<div className="card card-partner-item">
				<div className="position-relative">
					<VolkenoSkeleton variant="rect" height={150} width="100%" />
					<div className="partner-logo-container p-3">
						<VolkenoSkeleton variant="rect" height={30} width="100%" />
					</div>
				</div>
				<div className="card-body p-t-4">
					<VolkenoSkeleton variant="rect" height={10} width="100%" />
					<div className="partner-card-text my-2 fw-500">
						<VolkenoSkeleton variant="rect" height={10} width="100%" />
					</div>
					<VolkenoSkeleton variant="rect" height={10} width="100%" />
				</div>
				<div className="d-flex justify-content-end gap-4 px-2">
					<div>
						<VolkenoSkeleton variant="rect" height={20} width={50} />
					</div>
					<div>
						<VolkenoSkeleton variant="rect" height={20} width={12} />
					</div>
					<div>
						<VolkenoSkeleton variant="rect" height={20} width={12} />
					</div>
				</div>
			</div>
		</div>
	);
};

function ToggleValidPartner({ item }: { item?: Partenaire }) {
	const [validPartner, { isSuccess, isError, error }] = useValidPartenaireMutation();

	const onValidPartner = () => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir valider cette demande",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return validPartner(item?.slug as string);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
			}
		});
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Annuaire validé avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			console.log(err);

			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);
	return (
		<button
			className={`btn btn-valid-partner-admin w-50 py-1 px-1 ${
				item?.valider ? "btn-danger" : "btn-success"
			}`}
			title={
				item?.valider
					? "Cliquer pour rétirer l'annuaire"
					: "Cliquer pour valider l'annuaire"
			}
			onClick={onValidPartner}
		>
			{item?.valider ? "Rétirer" : "Valider"}
		</button>
	);
}

export function DeletePartner({ item }: { item?: Partenaire }) {
	const [deletePartenaire, { isSuccess, isError, error }] = useDeletePartenaireMutation();

	const onDeleteItem = (slug) => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cet annuaire",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deletePartenaire(slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
			}
		});
	};
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Annuaire supprimé avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			console.log(err);

			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);
	return (
		<button
			onClick={() => onDeleteItem(item?.slug)}
			className="btn btn-delete-partner-admin"
			title="Supprimer"
		>
			<RiDeleteBin7Fill />
		</button>
	);
}

export function usePartners() {
	const [page, setPage] = useState(1);
	const [word, setWord] = useState("");
	const [count, setCount] = React.useState(0);
	const { data = { results: [] }, isLoading } = useGetPartenaireQuery({
		page,
		word,
	});
	useEffect(() => {
		if (data) {
			let unvalidPartner = data?.results?.filter((item) => !item?.valider);
			setCount(unvalidPartner?.length);
		}
	}, [data]);

	console.log({ count });

	return {
		data,
		page,
		word,
		setPage,
		setWord,
		isLoading,
		count,
	};
}
