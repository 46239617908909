import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { EditNetworKData, ICreateProvider, INetwork, TNetworkFormData } from "./network.type";
import { prepareHeaders } from "../user/user.api";
import { PaginationResults } from "../commerce/commerce.type";
import { QueryUrl } from "../../utils";

export const NetworkApi = createApi({
	reducerPath: "networkApi",
	tagTypes: ["network", "networkByUser"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		// add pro
		addPro: builder.mutation<TNetworkFormData, any>({
			query: (data) => ({
				url: "network/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["network"],
		}),
		editPro: builder.mutation<TNetworkFormData, { slug: string; data: EditNetworKData }>({
			query: ({ slug, data }) => ({
				url: `network/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["network"],
		}),
		getNetworkByUser: builder.query<INetwork[], string>({
			query: (slug) => `networkbyuser/${slug}`,
			providesTags: ["networkByUser"],
			transformResponse: ({ results }) => results,
		}),
		getPro: builder.query<
			PaginationResults<INetwork[]>,
			{ code: string | null; profil: string | null; page: number }
		>({
			query: (query) => QueryUrl(`network/`, query),
			providesTags: ["network"],
		}),
		getValidPro: builder.query<INetwork[], void>({
			query: () => `validatednetworks/`,
			providesTags: ["network"],
			transformResponse: ({ results }) =>
				results.sort((a, b) =>
					parseInt(b?.id) > parseInt(a?.id)
						? -1
						: parseInt(b?.id) < parseInt(a?.id)
						? 1
						: 0
				),
		}),
		getValidFilterPro: builder.query<
			PaginationResults<INetwork>,
			{ code: string | null; profil?: string | null }
		>({
			query: (query) => QueryUrl(`network/`, query),
			providesTags: ["network"],
		}),
		validPro: builder.mutation<INetwork, string>({
			query: (slug) => ({
				url: `validnetwork/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: ["network"],
		}),
		unValidPro: builder.mutation<INetwork, string>({
			query: (slug) => ({
				url: `invalidnetwork/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: ["network"],
		}),
		deletePro: builder.mutation<INetwork, string>({
			query: (slug) => ({
				url: `network/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["network"],
		}),
		createPro: builder.mutation<ICreateProvider, TNetworkFormData | FormData>({
			query: (data) => ({
				url: "create-provider/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["network"],
		}),
		getValidProForChat: builder.query<INetwork[], { code: string | null }>({
			query: (query) => QueryUrl(`networklist/`, query),
			providesTags: ["network"],
			transformResponse: ({ results }) => results,
		}),
	}),
});

export const {
	useAddProMutation,
	useLazyGetNetworkByUserQuery,
	useLazyGetProQuery,
	useValidProMutation,
	useDeleteProMutation,
	useGetProQuery,
	useGetValidProQuery,
	useGetNetworkByUserQuery,
	useUnValidProMutation,
	useGetValidFilterProQuery,
	useCreateProMutation,
	useGetValidProForChatQuery,
	useEditProMutation,
} = NetworkApi;
