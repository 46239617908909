import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { BecomeTraderFormData } from "../../../../../utils/api/Trading/trading.type";
import { cleannerError, onOpen } from "../../../../../utils/utils";
function useBecomeTraderForm() {
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Le Titre du commerce"),
		city: yup.string().required().label("La ville"),
		region: yup.string().required().label("La région"),
		country: yup.string().required().label("Le pays"),
		profession: yup.string().required().label(""),
		description: yup.string().required().label("La Description"),
		logo: yup.mixed().required().label("Le Logo"),
		background_image: yup.mixed().required().label("L'Image de couverture"),
		slides: yup.mixed(),
		location: yup.string().required().label("L'Adresse"),
		date_creation: yup.string().nullable().label("La Date de creation"),
		email: yup.string().email("Email Invalide").required().label("L'email"),
		phone: yup.string().required().label("Le Téléphone"),
		whatsapp: yup.string().nullable().label("Le Whatshapp"),
		facebook: yup.string().nullable().label("L'Adresse de votre compte facebook"),
		twitter: yup.string().nullable().label("L'Adresse de votre compte twitter"),
		linkedin: yup.string().nullable().label("L'Adresse de votre compte linkedin"),
		site_web: yup.string().nullable().label("L'Adresse de votre site"),
		instagram: yup.string().nullable().label("L'Instagram de votre site"),
		category: yup.string().required().label("La catégorie"),
		ninea: yup
			.string()
			.matches(
				new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
				"Le NINEA doit être de format alpha-numérique."
			)
			.min(10, "Le ninea doit comporter minimum 10 caractères")
			.max(14, "Le ninea doit comporter maximum 14 caractères")
			.required("Le NINEA est requis"),
	});

	const [data, setData] = useState<BecomeTraderFormData>();

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		setError,
	} = useForm<BecomeTraderFormData | any>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			console.log("err", errors);
			cleannerError(errors, clearErrors, 7000);
		}
	}, [errors]);

	const onSubmit = async (data) => {
		onOpen("SouscriptionTraderModal");
		console.log(data);
		setData({ ...data, payment_mode: 2, env: window.location.origin });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		setError,
		isLoading: false,
		data,
	};
}

export default useBecomeTraderForm;
