import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { IFavorite } from "../favoriteProject/favoriteProject.type";
import { prepareHeaders } from "../user/user.api";
import { TFavoriteAnnonce } from "./favoriteAnnonce.type";

export const FavoriteAnnonceApi = createApi({
	tagTypes: ["favoriteAnnonce", "favoritesAnnonceByUser"],
	reducerPath: "favoriteAnnonceApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addFavoriteAnnonce: builder.mutation<TFavoriteAnnonce, any>({
			query: (data) => ({
				url: "favorite_announce/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["favoriteAnnonce"],
		}),
		deleteFavoriteAnnonce: builder.mutation<IFavorite, any>({
			query: (slug) => ({
				url: `favorite_announce/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["favoriteAnnonce", "favoritesAnnonceByUser"],
		}),
	}),
});

export const { useAddFavoriteAnnonceMutation, useDeleteFavoriteAnnonceMutation } =
	FavoriteAnnonceApi;
