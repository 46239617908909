import ReactQuill from "react-quill";
import { IOffer } from "../../../../utils/api/abonnement/abonnement.type";
import { TypeBoostOptions } from "../../../../utils/constantes";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useCrudBoostOffer from "./forms/useCrudBoostOffer";

const AddBoostOfferModal = ({ clean, offer }: { clean: () => void; offer?: IOffer }) => {
	const { register, onSubmit, errors, isLoading, description, handleChange, reset, ref } =
		useCrudBoostOffer(clean, offer);
	return (
		<div
			className="modal fade"
			id={"addBoostOfferModal"}
			aria-labelledby={`addBoostOfferModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id={`addBoostOfferModalTitle`}
						>
							{offer ? "Modifier" : "Ajouter"} une offre
						</h5>
						<CloseModalBtn onClick={reset} />
					</div>
					<div className="modal-body">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="mb-3">
									<div className="form-group">
										<label
											htmlFor="nom"
											aria-labelledby="nom"
											className="admin-form-label"
										>
											Type de l'offre
										</label>
										<span className="text-danger">*</span>
										<select
											id="type_boost"
											className="form-select"
											{...register("type_boost")}
										>
											<option value="">
												Choisissez un type
											</option>
											{TypeBoostOptions.map(
												(option) => (
													<option
														key={
															option.value
														}
														value={
															option.value
														}
													>
														{option.label}
													</option>
												)
											)}
										</select>
										{errors.type_boost?.message && (
											<ErrorMessage
												message={
													errors.type_boost
														.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12  mb-3">
									<div className="form-group">
										<label
											htmlFor="duration"
											aria-labelledby="duration"
											className="admin-form-label"
										>
											Durée en jours
										</label>
										<span className="text-danger">*</span>
										<input
											type="number"
											className="form-control "
											id="duration"
											{...register("duree")}
										/>
										{errors.duree?.message && (
											<ErrorMessage
												message={
													errors.duree.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12  mb-3">
									<div className="form-group">
										<label
											htmlFor="prix"
											aria-labelledby="duration"
											className="admin-form-label"
										>
											Coût de l'offre
										</label>
										<span className="text-danger">*</span>
										<input
											type="number"
											className="form-control "
											id="prix"
											{...register("prix")}
										/>
										{errors.prix?.message && (
											<ErrorMessage
												message={
													errors.prix.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12  mb-3 form-group">
									<label
										htmlFor="desc-mision"
										className="admin-form-label"
									>
										Description
									</label>
									{offer ? (
										<ReactQuill
											className="editor-cgu"
											value={description}
											onChange={handleChange}
											ref={ref}
										/>
									) : (
										<ReactQuill
											className="editor-cgu"
											defaultValue={description}
											onChange={handleChange}
											ref={ref}
										/>
									)}
									{errors.description && (
										<ErrorMessage
											message={
												errors.description?.message
											}
										/>
									)}
								</div>
								<div className="col-12 col-md-4 offset-md-8 pt-4">
									{isLoading && (
										<button
											type="button"
											className="btn btn-primary auth-submit-btn"
										>
											Chargement...
										</button>
									)}
									{!isLoading && (
										<button
											type="submit"
											className="btn btn-primary auth-submit-btn"
										>
											{offer ? "Modifier" : "Ajouter"}
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddBoostOfferModal;
