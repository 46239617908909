import React, { useState } from "react";
import { getImage } from "../../../../../../utils/utils";
import { BtnBack, BtnNext } from "../../../../../common/Button";
import { CustomInput } from "../../../../../common/CustomInput";

const loadImage = (setImageDimensions, imageUrl) => {
	const img = new Image();
	img.src = imageUrl;

	img.onload = () => {
		setImageDimensions({
			height: img.height,
			width: img.width,
		});
	};
	img.onerror = (err) => {
		console.log("img error");
		console.error(err);
	};
};

function InfosBancaire({
	navigation,
	register,
	formState,
	logo,
	setLogo,
	file,
	setFile,
	setValue,
	isLoading,
	item,
	setError,
}) {
	React.useEffect(() => {
		console.log(file, logo);
	}, []);
	const [imageDimensions, setImageDimensions] = useState<{ height: number; width: number }>();

	const handleChangeShopBanner = (e) => {
		if (e.target.files) {
			const imageUrl = URL.createObjectURL(e.target.files[0]);
			loadImage(setImageDimensions, imageUrl);
			console.log(imageDimensions);
			if (imageDimensions?.width === 1200 && imageDimensions?.height === 350) {
				setValue("background_image", e.target.files[0]);
				setFile(e.target.files[0]);
			} else {
				setError("background_image", {
					message: "Les dimensions de l'image sont incorrectes",
				});
				setFile(undefined);
			}
		}
	};
	return (
		<div>
			<h4 className="stepper-title">Ajouter un compte bancaire</h4>

			<div>
				<div className="row">
					<CustomInput
						label="Nom de votre banque"
						name="bank-name"
						type="text"
						{...register("bank_name")}
						error={formState?.errors?.bank_name?.message}
					/>
					<CustomInput
						label="Numéro de compte"
						name="account_number"
						type="number"
						{...register("account_number")}
						error={formState?.errors?.account_number?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="IBAN"
						name="iban"
						type="text"
						{...register("iban")}
						error={formState?.errors?.iban?.message}
					/>
					<CustomInput
						label="Nom du titulaire de compte"
						name="resp-account"
						type="text"
						{...register("account_owner")}
						error={formState?.errors?.account_owner?.message}
					/>
				</div>
				<div className="row">
					<div className="col-md-6">
						<CustomInput
							label="Logo de la boutique"
							type="file"
							name="logo"
							required
							accept="image/*"
							col="col-12"
							onChange={(e) => {
								if (e.target.files) {
									setValue("logo", e.target.files[0]);
									setLogo(e.target.files[0]);
								}
							}}
							error={formState?.errors?.logo?.message}
						/>
						{((logo && logo !== undefined) || item?.id) && (
							<div className="col-12 form-group mb-3">
								<div className="product-img-card">
									<img
										src={
											logo
												? URL.createObjectURL(logo)
												: getImage(item?.logo)
										}
										alt="product-img"
										width={100}
										height={100}
									/>
								</div>
							</div>
						)}
					</div>
					<div className="col-md-6">
						<CustomInput
							label="Bannière de la boutique(1200*350px)"
							type="file"
							name="background_image"
							required
							accept="image/*"
							col="col-12"
							onChange={handleChangeShopBanner}
							error={formState?.errors?.background_image?.message}
						/>
						{((file && file !== undefined) || item?.id) && (
							<div className="col-12 form-group mb-3">
								<div className="product-img-card">
									<img
										src={
											file
												? URL.createObjectURL(file)
												: getImage(
														item?.background_image
												  )
										}
										alt="product-img"
										width={100}
										height={100}
									/>
								</div>
							</div>
						)}
					</div>
					<CustomInput
						label="SWIFT"
						name="swift"
						type="text"
						{...register("swift")}
						error={formState?.errors?.swift?.message}
					/>
				</div>

				<div className="flex-r gap-3 mt-5">
					<BtnBack label="Retour" type="button" navigation={navigation} />
					<BtnNext label="Suivant" type="submit" isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
}

export default InfosBancaire;
