import React from 'react'
import { useEffect, useState } from 'react'
import { useStep } from 'react-hooks-helper'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import { useStepType } from '../../../../utils/type'
import StepperHorizontal from '../../../common/StepperHorizontal'
import AddBoutique from '../../trader/AddArticle/Steps/AddBoutique'
import AddImage from '../../trader/AddArticle/Steps/AddImage'
import DetailProduct from '../../trader/AddArticle/Steps/DetailProduct'
import MoreDetail from '../../trader/AddArticle/Steps/MoreDetail'
import Tarification from '../../trader/AddArticle/Steps/Tarification'
import useAddProductForm from './useProductForm/useAddProductForm'

const steps = [
  {
    id: 'detail-product',
    Component: DetailProduct,
  },
  {
    id: 'more-detail',
    Component: MoreDetail,
  },
  {
    id: 'tarif',
    Component: Tarification,
  },
  {
    id: 'boutique',
    Component: AddBoutique,
  },
  {
    id: 'add-image',
    Component: AddImage,
  },
]

function AddProductAdmin() {
  const {
    register,
    setValue,
    errors,
    dataForm,
    level,
    onSubmit,
    setError,
    isLoading,
  } = useAddProductForm()
  const [pricipalColor, setPricipalColor] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [files, setFiles] = useState<File[]>([])
  const [file, setFile] = useState<File>()
  const [category, setCategory] = useState<any>({})
  const [commerce, setCommerce] = useState<any>({})
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  })
  const { Component } = step
  const props = {
    navigation,
    register,
    setValue,
    errors,
    dataForm,
    pricipalColor,
    setPricipalColor,
    description,
    setDescription,
    files,
    setFiles,
    file,
    setFile,
    setError,
    category,
    setCategory,
    isLoading,
    commerce,
    setCommerce,
  }

  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (!user?.products && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])

  useEffect(() => {
    window.scrollTo(10, 10)
    let newIndex = index + 1
    setValue('step', newIndex)
  }, [index])

  useEffect(() => {
    if (level) {
      if (level === 1) {
        navigation.go('more-detail')
      }

      if (level === 2) {
        navigation.go('tarif')
      }

      if (level === 3) {
        navigation.go('boutique')
      }

      if (level === 4) {
        navigation.go('add-image')
      }
    }
  }, [level, dataForm])

  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="article-card p-4 w-100">
          <div className="flex-sb">
            <div className="d-flex flex-column flex-md-row gap-md-3">
              <h3 className="article-title">Ajouter un produit</h3>
            </div>
          </div>
          <StepperHorizontal index={index} />
          <form onSubmit={onSubmit}>
            <Component {...props} />
          </form>
        </div>
      </div>
    </div>
  )
}

export default AddProductAdmin
