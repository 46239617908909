import React from 'react'
import { useGetSimilaryProductQuery } from '../../../../../utils/api/product/product.api'
import { Product } from '../../../../../utils/api/product/product.type'
import { EmptyMessage } from '../../../../../utils/EmptyMessage'
import ProductItemSkeleton from '../../../../skeletons/ProductItemSkeleton'
import MarketPlaceCard from "../common/MarketPlaceCard";

function MoreProduct({ item }: { item: Product }) {
  const { data: products = [], isLoading } = useGetSimilaryProductQuery(
    item?.slug,
  )
  return (
    <div className="row p-t-80">
      {isLoading &&
        Array(6)
          .fill(6)
          .map((item, i) => (
            <div className="col-md-6 col-lg-4" key={item?.id}>
              <div className="marketplace-card">
                <ProductItemSkeleton />
              </div>
            </div>
          ))}
      {!isLoading &&
        (products?.length ? (
          products?.map((product) => (
            <div className="col-md-6 col-lg-4" key={product.slug}>
              <MarketPlaceCard
                slug={product?.slug}
                category={product?.category?.name}
                image={product?.background_image}
                name={product?.name}
                price={product?.price}
                rate={product?.rate_average}
                item={product}
              />
            </div>
          ))
        ) : (
          <div> {EmptyMessage('Aucun produit disponible')} </div>
        ))}
    </div>
  )
}

export default MoreProduct
