import React, { useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RiDeleteBin7Fill, RiCheckFill, RiCloseFill } from "react-icons/ri";
import Swal from "sweetalert2";
import {
	useDeleteProMutation,
	useGetProQuery,
	useUnValidProMutation,
	useValidProMutation,
} from "../../../../../utils/api/network/network.api";
import { Color } from "../../../../../utils/theme";
import { AlertInfo } from "../../../../common/Alert";
import Pagination from "../../../../common/Pagination";
import ProfessionnelsTableSkeleton from "./ProfessionnelsTableSkeleton";

function ProfessionnelsTable({
	searchWord: code,
	profil,
}: {
	searchWord: string | null;
	profil: string | null;
}) {
	const [page, setPage] = useState(1);
	const { data, isLoading } = useGetProQuery({
		code: code,
		profil,
		page,
	});
	const [deletePro, { isSuccess: isSucDel, isError, error }] = useDeleteProMutation();
	const [validPro, { isSuccess }] = useValidProMutation();
	const [unValidPro] = useUnValidProMutation();

	useEffect(() => {
		if (isSucDel) {
			Swal.fire({
				title: "Supprimé avec succès!",
				icon: "success",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isSucDel]);
	useEffect(() => {
		if (isError) {
			const err = error as any;
			if (isError) {
				Swal.fire({
					icon: "error",
					title: err?.data?.message
						? err?.data?.message
						: "Une erreur a survenue lors de votre inscription",
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isError]);
	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
	};

	const onDeletePro = (slug) => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cet utilisateur du réseau",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deletePro(slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
			}
		});
	};
	const onToggleValidPro = (row) => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir ${
				!row?.valid ? "valider la demande?" : "annuler la validation"
			}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},

			preConfirm: () => {
				return !row?.valid ? validPro(row?.slug) : unValidPro(row?.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
				console.log("res", result);
			}
		});
	};
	const nameFormatter = (cell: any, row: any) => {
		return row?.user?.firstname + " " + row?.user?.lastname;
	};
	const societyNameFormatter = (cell: any, row: any) => {
		return row?.society_name;
	};
	const statusFormatter = (cell: any, row: any) => {
		if (cell === true) {
			return <span className="status-user-admin-complet">Valide</span>;
		}
		if (cell === false) {
			return <span className="status-user-admin-en-attente">En Attente..</span>;
		}
	};
	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				{!row?.valid ? (
					<button
						className="btn btn-action-user-admin"
						onClick={() => onToggleValidPro(row)}
						title="Valider"
					>
						<RiCheckFill />
					</button>
				) : (
					<button
						className="btn btn-action-user-admin"
						onClick={() => onToggleValidPro(row)}
						title="Annuler"
					>
						<RiCloseFill />
					</button>
				)}
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Supprimer"
						onClick={() => onDeletePro(row?.slug)}
					>
						<RiDeleteBin7Fill />
					</button>
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "identity_code",
			text: "N°",
			style: { textAlign: "left" },
		},
		{
			dataField: "name",
			text: "Prenoms et Noms",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
			style: { textAlign: "left" },
		},
		{
			dataField: "author",
			text: "Nom Société",
			formatter: (cell: any, row: any) => societyNameFormatter(cell, row),
		},
		{
			dataField: "metier",
			text: "Metiers",
		},
		{
			dataField: "adress",
			text: "Adresse",
		},
		{
			dataField: "valid",
			text: "Statut",
			formatter: (cell: any, row: any) => statusFormatter(cell, row),
		},
		{
			dataField: "actions",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <ProfessionnelsTableSkeleton />}
			{!isLoading && (
				<>
					<h3 className="total-items">{data?.count} résultats</h3>
					<BootstrapTable
						keyField="identity_code"
						data={data?.results}
						columns={columns}
						bordered={true}
						condensed={false}
						responsive={true}
						selectRow={selectRow}
						wrapperClasses="table-responsive admin-table"
						noDataIndication={<AlertInfo message="Aucun membre trouvé" />}
					/>
				</>
			)}
			<Pagination page={page} onPageChange={setPage} total={data?.count} />
		</>
	);
}

export default ProfessionnelsTable;
