import {} from "react";
import { Link } from "react-router-dom";
import { Modal } from "reactstrap";
import { InterestAnnonce } from "../../../../utils/api/interet_annonce/interetAnnonce.type";
import { getImage } from "../../../../utils/utils";
import { AlertInfo } from "../../../common/Alert";
import CloseModalBtn from "../../../shared/CloseModalBtn";

const ListOfUsersInterestedAnnonceModal = ({
	interets,
	slug,
	isOpen,
	closeModal,
}: {
	interets?: InterestAnnonce[];
	slug?: string;
	isOpen: boolean;
	closeModal: () => void;
}) => {
	return (
		<Modal isOpen={isOpen} centered size="lg">
			<div className="modal-header">
				<h5 className="modal-title modal-title-custom fw-500" id="ListOfUsersInterestedAnnonceModal">
					Liste des utilisateurs qui s'interessent à l'annonce
				</h5>
				<CloseModalBtn onClick={closeModal} />
			</div>
			<div className="modal-body d-block">
				{interets && interets?.length > 0 ? (
					<>
						<ul className="users-for-share-points-containers m-0  px-2">
							{interets.map((item) => (
								<li key={item.slug} data-bs-dismiss="modal">
									<Link
										to={`/app/admin/utilisateurs/${item?.slug}`}
										className="user-for-share-point d-flex gap-4 mb-2 px-3 py-1"
										state={{
											user: item?.user,
											slug: slug,
										}}
									>
										<div>
											<img
												src={getImage(item?.user.avatar)}
												alt="user-avatar"
												className="w-fluid img-profil-user-message"
											/>
										</div>
										<div className="user-for-share-point-infos row flex-fill">
											<h3 className="col-md-3">
												{item?.user.firstname} {item?.user.lastname}
											</h3>
											<h4 className="col-md-3">{item?.user.phone}</h4>
											<h4 className="col-md-3">{item?.user.email}</h4>
											<h4 className="col-md-3">{item?.user.adresse}</h4>
										</div>
									</Link>
								</li>
							))}
						</ul>
					</>
				) : (
					<AlertInfo message="Aucune donnée trouvée" />
				)}
			</div>
		</Modal>
	);
};

export default ListOfUsersInterestedAnnonceModal;
