import React, { useState, useEffect } from 'react'
import { useGetCategoryQuery } from '../../../../utils/api/category/category.api'
import { CKEditor } from 'ckeditor4-react'
import { Color } from '../../../../utils/theme'
import { CustomInput, FormError } from '../../../common/CustomInput'
import { BtnNext } from '../../../common/Button'
import { IconDelete3, IconImage } from '../../../shared/Icons'
import { useNavigate } from 'react-router-dom'
import { IoMdArrowRoundBack } from 'react-icons/io'
import { useProductFromLocation } from '../../../../utils/api/product/product.api'
import { getImage } from '../../../../utils/utils'
import { useDeleteImageMutation } from '../../../../utils/api/media/image.api'
import useEditProductFormAdmin from './useProductForm/useEditProductFormAdmin'
import { useGetCommerceByAdminQuery } from '../../../../utils/api/commerce/commerce.api'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'

function EditProductAdmin() {
  const {
    register,
    setValue,
    errors,
    onSubmit,
    setError,
    isLoading,
  } = useEditProductFormAdmin()
  const [item] = useProductFromLocation()
  const [pricipalColor, setPricipalColor] = useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [newImage, setNewImage] = useState<any>(null)
  const [files, setFiles] = useState<File[] | any>([])
  const [file, setFile] = useState<File | any>()
  const [category, setCategory] = useState<any>({})
  const [commerce, setCommerce] = useState<any>({})
  const [subCategory, setSubCategory] = useState<any>({})
  const [deleteImage, { isSuccess: success }] = useDeleteImageMutation()
  const [options, setOptions] = React.useState<any>([])
  const [optionsCommerce, setOptionsCommerce] = React.useState<any>([])
  const [subcategories, setSubcategories] = React.useState<any>([])
  const { data: categories, isSuccess } = useGetCategoryQuery()
  const { data: commerces } = useGetCommerceByAdminQuery()
  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (!user?.products && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])

  React.useEffect(() => {
    if (isSuccess) {
      setOptions(
        categories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
            subcategories: item?.subcategory,
          }
        }),
      )
    }
  }, [categories, isSuccess])

  React.useEffect(() => {
    if (isSuccess) {
      setOptionsCommerce(
        commerces?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          }
        }),
      )
    }
  }, [commerces, isSuccess])

  useEffect(() => {
    if (item?.id) {
      let data: any = item
      setCategory({
        label: item?.category?.name,
        value: item?.category?.id,
        subcategories: item?.category?.subcategory,
      })
      setCommerce({
        label: item?.commerce?.name,
        value: item?.commerce?.id,
      })
      setFile(item?.background_image)
      setFiles(item?.images)
      setDescription(item?.description)
      setSubCategory(item?.subcategory?.id)
      setPricipalColor(item?.main_color)
      for (let key of Object.keys(data)) {
        const val = data[key]
        if (key !== 'likes' && key !== 'images' && key !== 'background_image') {
          if (
            (key === 'commerce' && val?.id) ||
            (key === 'category' && val?.id) ||
            (key === 'subcategory' && val?.id)
          ) {
            setValue(key, val?.id)
          } else {
            setValue(key, val)
          }
        }
      }
    }
  }, [item])

  React.useEffect(() => {
    if (category?.subcategories) {
      setSubcategories(
        category?.subcategories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          }
        }),
      )
    }
  }, [category])
  const deleteFile = (index, item) => {
    const newArr = [...files]
    newArr.splice(index, 1)
    setFiles(newArr)
    setValue(
      'images',
      newArr?.filter((img) => !img.picture),
    )
    if (item?.picture) {
      deleteImage(item?.slug)
    }
  }

  const handleChange = (e) => {
    setValue('category', e.target.value)

    let categoryItem = options?.find(
      (item) => parseInt(item?.value) === parseInt(e.target.value),
    )
    if (
      categoryItem?.subcategories?.find(
        (sub) => parseInt(sub?.id) === parseInt(subCategory),
      )?.id
    ) {
      console.log('here')
      setValue('subcategory', subCategory)
    } else {
      console.log('no here')
      setValue('subcategory', '')
    }
    setCategory(categoryItem)
  }
  const handleChangeCommerce = (e) => {
    setValue('commerce', e.target.value)

    let commerceItem = optionsCommerce?.find(
      (item) => parseInt(item?.value) === parseInt(e.target.value),
    )

    setCommerce(commerceItem)
  }
  return (
    <div className="article-card p-4">
      <div className="flex-sb">
        <div className="d-flex flex-column flex-md-row gap-md-3">
          <h3 className="article-title d-flex align-items-center">
            <IoMdArrowRoundBack
              className="linkable"
              style={{ color: Color.themeColor }}
              onClick={() => navigate(-1)}
            />
            Modifier le produit
          </h3>
        </div>
      </div>
      <form onSubmit={onSubmit}>
        <div>
          <h4 className="stepper-title pt-5">Détails du produit </h4>
          <div>
            <div className="row">
              <div className="col-md-6 form-group mb-4">
                <label htmlFor="category" className="custom-form-label">
                  Choisir une catégorie <span className="text-danger">*</span>
                </label>
                <select
                  name="category"
                  id="category"
                  className="form-select espace-client-form-select-control"
                  value={category?.value || ''}
                  onChange={(e) => handleChange(e)}
                  style={{ backgroundColor: Color.bgGray }}
                >
                  <option value=""> Choisir une catégorie </option>
                  {options
                    ?.sort(function (a, b) {
                      return a?.label?.localeCompare(b?.label)
                    })
                    .map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>

                {<FormError error={errors?.category?.message} />}
              </div>
              <div className="col-md-6 form-group mb-4">
                <label htmlFor="subcategory" className="custom-form-label">
                  Choisir une sous catégorie{' '}
                  <span className="text-danger">*</span>
                </label>
                <select
                  name="subcategory"
                  id="subcategory"
                  className="form-select espace-client-form-select-control"
                  value={subCategory || ''}
                  onChange={(e) => {
                    setValue('subcategory', e.target.value)
                    setSubCategory(e.target.value)
                  }}
                  style={{ backgroundColor: Color.bgGray }}
                >
                  <option value=""> Choisir une sous catégorie </option>
                  {subcategories
                    ?.sort(function (a, b) {
                      return a?.label?.localeCompare(b?.label)
                    })
                    .map((option) => (
                      <option value={option.value} key={option.value}>
                        {option.label}
                      </option>
                    ))}
                </select>

                {<FormError error={errors?.subcategory?.message} />}
              </div>

              <CustomInput
                label="Nom du produit"
                type="text"
                required
                {...register('name')}
                error={errors?.name?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Modèle"
                type="text"
                {...register('modele')}
                error={errors?.modele?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Marque"
                type="text"
                {...register('marque')}
                error={errors?.marque?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Couleur principale"
                type="color"
                // {...register("color_pricipal")}
                onChange={(e) => {
                  setValue('main_color', e.target.value)
                  console.log('color', e.target.value)
                  setPricipalColor(e.target.value)
                }}
                error={errors?.main_color?.message}
                name="modele"
                style={{
                  backgroundColor: pricipalColor || Color.bgGray,
                  padding: '1.3rem',
                }}
                className="form-control espace-client-form-control input-color-form-control"
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="stepper-title">Plus de détails</h4>
          <div>
            <div className="col-md-12 mb-4 form-group">
              <label htmlFor="desc-produit" className="custom-form-label">
                Description du produit
                <span className="text-danger">*</span>
              </label>
              <CKEditor
                initData={item?.description || ''}
                onChange={(e) => {
                  setValue('description', e.editor.getData())
                  setDescription(e.editor.getData())
                }}
              />
              {<FormError error={errors?.description?.message} />}
            </div>
            <div className="row">
              <CustomInput
                label="Poids"
                type="poids"
                {...register('weight')}
                error={errors?.weight?.message}
                name="weight"
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Dimensions"
                type="dimensions"
                {...register('dimensions')}
                error={errors?.dimensions?.message}
                name="dimensions"
                style={{ backgroundColor: Color.bgGray }}
              />
            </div>
          </div>
        </div>
        <div>
          <h4 className="stepper-title">Tarification</h4>
          <div>
            <div className="row">
              <CustomInput
                label="Prix"
                type="number"
                step={'any'}
                min={0}
                required
                {...register('price')}
                error={errors?.price?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Quantités"
                type="number"
                min={1}
                required
                {...register('quantity')}
                error={errors?.quantity?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              <CustomInput
                label="Image couverture"
                type="file"
                name="background_image"
                required
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files) {
                    setValue('background_image', e.target.files[0])
                    setNewImage(e.target.files[0])
                  }
                }}
                error={errors?.background_image?.message}
                style={{ backgroundColor: Color.bgGray }}
              />
              {file && (
                <div className="col-md-6 form-group mb-4">
                  <div className="product-img-card">
                    <img
                      src={
                        !newImage
                          ? getImage(file)
                          : URL.createObjectURL(newImage)
                      }
                      alt="product-img"
                      width={100}
                      height={100}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-md-6 form-group mb-4">
          <label htmlFor="commerce" className="custom-form-label">
            Choisir la boutique <span className="text-danger">*</span>
          </label>
          <select
            name="commerce"
            id="commerce"
            className="form-select espace-client-form-select-control"
            value={commerce?.value || ''}
            onChange={(e) => handleChangeCommerce(e)}
            style={{ backgroundColor: Color.bgGray }}
          >
            <option value=""> Choisir une boutique </option>
            {optionsCommerce
              ?.sort(function (a, b) {
                return a?.label?.localeCompare(b?.label)
              })
              .map((optionsCommerce) => (
                <option
                  value={optionsCommerce.value}
                  key={optionsCommerce.value}
                >
                  {optionsCommerce.label}
                </option>
              ))}
          </select>

          {<FormError error={errors?.commerce?.message} />}
        </div>

        <div>
          <h4 className="stepper-title">Ajouter des images</h4>
          <div className="row mb-4">
            {files?.length
              ? files?.map((file, index) => (
                  <div className="col-md-4 col-xl-3" key={index}>
                    <div className="product-img-card">
                      <button
                        className="no-style-btn del-product-img"
                        title="Supprimer"
                        onClick={() => deleteFile(index, file)}
                      >
                        <IconDelete3 />
                      </button>
                      <img
                        src={
                          file?.picture
                            ? getImage(file?.picture)
                            : URL.createObjectURL(file)
                        }
                        alt="product-img"
                        style={{
                          width: '100%',
                          height: '15vw',
                          objectFit: 'fill',
                        }}
                      />
                    </div>
                  </div>
                ))
              : null}
          </div>
          {<FormError error={errors?.images?.message} />}
          <div>
            <div className="d-flex flex-column align-items-end">
              <label htmlFor="image" className="btn-parcourir">
                <IconImage />
                <span>Parcourir</span>
              </label>
              <input
                type="file"
                className="hide-input op-0-0"
                title="images"
                accept="image/*"
                multiple
                onChange={(e: any) => {
                  const newFiles = [...e.target.files]
                  console.log([...files, ...newFiles])
                  let images = [...files, ...newFiles]
                  if (images?.length > 4) {
                    setError('images', {
                      message: 'Veuillez télécharger maximum 4 images',
                    })
                  }
                  const imgNewURL = images?.filter((img) => !img.picture)
                  if ([...files, ...newFiles]?.length <= 4) {
                    setFiles([...files, ...newFiles])
                    setValue('images', imgNewURL)
                  }
                }}
                id="image"
              />
            </div>
            <div className="flex-r gap-3 mt-4">
              <BtnNext label="Valider" type="submit" isLoading={isLoading} />
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  //   return (
  //     <div className="bloc-entete-admin-container">
  //         <div className="bloc-entete-admin py-4">
  //             <div className="article-card p-4 w-100">
  //                 <div className="flex-sb">
  //                     <div className="d-flex flex-column flex-md-row gap-md-3">
  //                         <h3 className="article-title">Modifier le produit</h3>
  //                     </div>
  //                 </div>

  //                 <div>
  // 			<h4 className="stepper-title">Détails du produit </h4>
  // 			<div>
  // 				<div className="row">
  // 					<CustomSelectInput
  // 						label="Choisir une catégorie"
  // 						options={options?.sort(function (a, b) {
  // 							return a?.label?.localeCompare(b?.label);
  // 						})}
  // 						required
  // 						onChange={(e) => handleChange(e)}
  // 						// {...register("category")}
  // 						error={errors?.category?.message}
  // 						style={{ backgroundColor: Color.bgGray }}
  // 					/>
  // 					<CustomSelectInput
  // 						label="Choisir une sous catégorie"
  // 						options={subcategories?.sort(function (a, b) {
  // 							return a?.label?.localeCompare(b?.label);
  // 						})}
  // 						required
  // 						{...register("subcategory")}
  // 						error={errors?.subcategory?.message}
  // 						style={{ backgroundColor: Color.bgGray }}
  // 					/>
  // 					<CustomInput
  // 						label="Nom du produit"
  // 						type="text"
  // 						required
  // 						name="nom_produit"
  // 						{...register("name")}
  // 						error={errors?.name?.message}
  // 						style={{ backgroundColor: Color.bgGray }}
  // 					/>
  // 					<CustomInput
  // 						label="Modèle"
  // 						type="text"
  // 						name="modele"
  // 						{...register("modele")}
  // 						error={errors?.modele?.message}
  // 						style={{ backgroundColor: Color.bgGray }}
  // 					/>
  // 					<CustomInput
  // 						label="Marque"
  // 						type="text"
  // 						name="modele"
  // 						{...register("marque")}
  // 						error={errors?.marque?.message}
  // 						style={{ backgroundColor: Color.bgGray }}
  // 					/>
  // 					<CustomInput
  // 						label="Couleur principale"
  // 						type="color"
  // 						// {...register("color_pricipal")}
  // 						onChange={(e) => {
  // 							setValue("main_color", e.target.value);
  // 							console.log("color", e.target.value);
  // 							setPricipalColor(e.target.value);
  // 						}}
  // 						error={errors?.main_color?.message}
  // 						name="modele"
  // 						style={{
  // 							backgroundColor: pricipalColor || Color.bgGray,
  // 							padding: "1.3rem",
  // 						}}
  // 						className="form-control espace-client-form-control input-color-form-control"
  // 					/>
  // 				</div>
  // 				<div className="flex-r gap-3 mt-4">
  // 					{/* <BtnSkip label="Passer l’étape" type="button" /> */}
  // 					<BtnNext label="Suivant" type="submit" />
  // 				</div>
  // 			</div>
  // 		</div>
  //             </div>

  //         </div>
  //     </div>
  //   )
}

export default EditProductAdmin
