import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import React, { useCallback } from "react";
import { useAppSelector } from "../../../redux/hooks";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { INotification, NotificationFormData } from "./notification.type";

export const notificationApi = createApi({
	reducerPath: "notificationApi",
	tagTypes: ["notification", "notificationAdmin"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getNotifByUser: builder.query<INotification[], string>({
			query: (slug) => `notificationbyuser/${slug}/`,
			providesTags: ["notification"],
		}),
		getNotifByAdmin: builder.query<INotification[], string>({
			query: (slug) => `notificationbyadmin/${slug}/`,
			transformResponse: (results: any) => results?.sort((a, b) => b?.id - a?.id),
			providesTags: ["notificationAdmin"],
		}),
		readNotification: builder.mutation<INotification, NotificationFormData>({
			query: ({ slug, ...data }) => ({
				url: `/notification/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["notification", "notificationAdmin"],
		}),
	}),
});

export const { useLazyGetNotifByUserQuery, useGetNotifByAdminQuery, useReadNotificationMutation } =
	notificationApi;

export function useUnreadNotifCount() {
	const [count, setCount] = React.useState(0);
	const user = useAppSelector((s) => s.user.user);
	const [getNotifByUser, { data, isLoading }] = useLazyGetNotifByUserQuery();
	React.useEffect(() => {
		if (user?.id) {
			getNotifByUser(user?.slug);
		}
	}, [user?.id]);
	const functionCountunreadNotif = useCallback(() => {
		let count = 0;
		data?.map((notif) => {
			if (!notif?.read) {
				count++;
			}
			return count;
		});
		setCount(count);
	}, []);
	React.useEffect(() => {
		functionCountunreadNotif();
	});
	return count;
}

export function useReadUserNotifications() {
	const user = useAppSelector((s) => s.user.user);
	const [getNotifByUser, { data }] = useLazyGetNotifByUserQuery();
	console.log({ data });
	const [readMessage, { isSuccess, isError, error }] = useReadNotificationMutation();
	React.useEffect(() => {
		if (user?.id) {
			getNotifByUser(user?.slug);
		}
	}, [user?.id]);
	const updateNotif = () => {
		let count = 0;
		data?.map((notif) => {
			if (!notif?.read) {
				readMessage({ slug: notif?.slug, read: true });
			}
			return count;
		});
	};

	return updateNotif;
}
