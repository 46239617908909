import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { PartenaireFormData } from "../../../../../utils/api/partenaire/partenaire.type";
import { cleannerError } from "../../../../../utils/utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useAddPartenaireMutation } from "../../../../../utils/api/partenaire/partenaire.api";
import { Color } from "../../../../../utils/theme";
import { isAdmin } from "../../../../../routes/router";

function useAjouterPartenaire() {
	//  Calling the add Partenaire user Mutation
	const [addPartenaire, { isLoading, isSuccess, error, isError }] = useAddPartenaireMutation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Nom de l'entreprise"),
		icon: yup.mixed().required().label("Logo"),
		title: yup.string().required().label("Phrase d'accroche"),
		region: yup.string().required().label("Région"),
		ville: yup.string().required().label("Ville"),
		pays: yup.string().required().label("Pays"),
		phone: yup.string().required().label("Numéro de téléphone"),
		email: yup.string().email("Mail invalide").required().label("Adresse mail"),
		background_image: yup.mixed().required().label("Image de couverture"),
		location: yup
			.string()
			.required()
			.label("Iteneraire")
			.typeError("Iteneraire est obligatoire"),
		entreprise_type: yup.string().required().label("Iteneraire"),
		linkedin: yup.string().url("url invalide"),
		facebook: yup.string().url("url invalide"),
		twitter: yup.string().url("url invalide"),
		instagram: yup.string().url("url invalide"),
		link_url: yup.string().url("url invalide"),
		slides: yup.array().max(4).label("Images"),
		description: yup.string().required().label("Description"),
		specialite: yup.string().required().label("Spécialités"),
		expertise: yup.string().required().label("Expertises"),
		category: yup
			.number()
			.required()
			.label("Catégorie")
			.typeError("Catégorie est un champ obligatoire"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<PartenaireFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${
					isAdmin(user)
						? "Annuaire ajouté avec succès!"
						: "Demande envoyé avec succès"
				}`,
				text: `${
					!isAdmin(user) &&
					"Vous recevrez un email dès que la demande est acceptée"
				}`,
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 3000,
			}).then(() => {
				if (user?.user_type === "admin" || user?.user_type === "superadmin") {
					navigate("/app/admin/annuaires");
				} else {
					navigate("/app/mon-espace-client/mes-annuaires");
				}
			});
		}
		if (isError) {
			const err = error as any;
			console.log(err);
			if (err?.data?.email[0]?.includes("partner with this email already exists.")) {
				Swal.fire({
					icon: "error",
					title: "L'email choisi est déjà utilisé par un annuaire",
					showConfirmButton: false,
					timer: 5000,
				});
			} else {
				const { message = "Une erreur inconnue s'est produite" } = err.data || {};
				Swal.fire({
					icon: "error",
					title: message,
					showConfirmButton: false,
					timer: 5000,
				});
			}
		}
	}, [isLoading]);

	const onSubmit = (data: PartenaireFormData) => {
		console.log(data);
		const { tags, ...rest } = data;
		const formData = new FormData();
		if (user?.id) {
			formData.append("user", JSON.stringify(user.id));
			formData.append("author", JSON.stringify(user.id));
		}
		if (data?.slides !== undefined && data?.slides?.length) {
			for (const slide of data?.slides) {
				formData.append("slides", slide);
			}
		}
		if (tags) {
			formData.append("tags", JSON.stringify(tags));
		}
		for (let key of Object.keys(rest)) {
			const val = rest[key];
			formData.append(key, val);
		}
		addPartenaire(formData);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		user,
	};
}

export default useAjouterPartenaire;
