import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AuthState } from "../../../redux/slice/User.slice";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { QueryUrl, useLocationState } from "../../utils";
import {
	PaginationResults,
	TBoostData,
	TBoostResponse,
	TypeQuery,
} from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import {
	FavoriteProduct,
	FavoriteProductFormData,
	Product,
	ProductFormData,
	ProductQuery,
	RateProduct,
	RateProductFormData,
} from "./product.type";

const tags = {
	productsByVisitor: "productsByVisitor",
	productsByCommerce: "productsByCommerce",
	productsByVisitorBySlug: "productsByVisitorBySlug",
	products: "products",
	productBySlug: "productBySlug",
	favoritesProductByUser: "favoritesProductByUser",
	productsByVisitorByCommerce: "productsByVisitorByCommerce",
	productbysamecategory: "productbysamecategory",
	boostedProduct: "boostedProduct",
	socialBoostedProduct: "socialBoostedProduct",
};
export const ProductApi = createApi({
	reducerPath: "productAPi",
	tagTypes: Object.values(tags),
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getProductByCommerce: builder.query<PaginationResults<Product> | any, TypeQuery>({
			query: ({ slug, ...query }) => QueryUrl(`productbycommerce/${slug}/`, query),
			providesTags: [tags.productsByCommerce],
		}),
		ProductsByVisitorBySlug: builder.query<Product, string>({
			query: (slug) => `productbyvisitor/${slug}/`,
			providesTags: [tags.productsByVisitorBySlug],
		}),
		productByFilter: builder.query<
			PaginationResults<Product>,
			{ page: number; word: string; limit: number }
		>({
			query: (query) => QueryUrl(`productbyfilteradmin/`, query),
			providesTags: [tags.products],
		}),
		ProductBySlug: builder.query<Product, string>({
			query: (slug) => `product/${slug}/`,
			providesTags: [tags.productBySlug],
		}),
		rateProduct: builder.mutation<RateProduct, RateProductFormData | any>({
			query: (data) => ({
				url: "/rate_product/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [
				tags.productsByVisitorBySlug,
				tags.productsByVisitor,
				tags.productsByCommerce,
			],
		}),
		addProductFavorite: builder.mutation<FavoriteProduct, FavoriteProductFormData | any>({
			query: (data) => ({
				url: "/favorite/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [
				tags.productsByVisitorBySlug,
				tags.productsByVisitor,
				tags.productsByCommerce,
			],
		}),
		addProduct: builder.mutation<Product, ProductFormData | any>({
			query: (data) => ({
				url: "/product/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [tags.productsByVisitor, tags.productsByCommerce, tags.products],
		}),
		editProduct: builder.mutation<Product, ProductFormData | any>({
			query: ({ slug, data }) => ({
				url: `/product/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: [tags.productsByVisitor, tags.productsByCommerce, tags.products],
		}),
		activeProduct: builder.mutation<Product, ProductFormData | any>({
			query: ({ slug, ...data }) => ({
				url: `/product/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: [tags.productsByVisitor, tags.productsByCommerce, tags.products],
		}),
		deleteProduct: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `product/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.productsByVisitor, tags.productsByCommerce, tags.products],
		}),
		deleteFavoriteProduct: builder.mutation<FavoriteProduct, string>({
			query: (slug) => ({
				url: `favorite/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: [
				tags.productsByVisitorBySlug,
				tags.productsByVisitor,
				tags.productsByCommerce,
				tags.products,
			],
		}),
		getproductFavoriteByUser: builder.query<FavoriteProduct[] | any, void>({
			query: () =>
				`favoritebyuser/${AppStorage.getItem<AuthState>("user", {}).user?.slug}`,
			providesTags: [tags.favoritesProductByUser],
			transformResponse: ({ results }) => results,
		}),
		getProductByCommerceByVisitor: builder.query<Product[] | any, string>({
			query: (slug) => `productbycommercebyvisitor/${slug}`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(b?.id) - parseInt(a?.id)),
			providesTags: [tags.productsByVisitorByCommerce],
		}),
		getProductsByVisitor: builder.query<Product[], ProductQuery>({
			query: (query) => QueryUrl("/productbyfilter/", query),
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(b?.id) - parseInt(a?.id)),
			providesTags: [tags.productsByVisitor],
		}),
		getSimilaryProduct: builder.query<Product[] | any, string>({
			query: (slug) => `productbysamecategory/${slug}`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(b?.id) - parseInt(a?.id)),
			providesTags: [tags.productbysamecategory],
		}),
		getBoostedProduct: builder.query<Product[] | any, void>({
			query: () => `boostedproduct/`,
			transformResponse: ({ results }) => results,
			providesTags: [tags.boostedProduct],
		}),
		getSocialBoostedProduct: builder.query<PaginationResults<Product>, TypeQuery>({
			query: (query) => QueryUrl(`socialboostedproduct`, query),
			providesTags: [tags.socialBoostedProduct],
		}),
		getTopProduct: builder.query<PaginationResults<Product>, TypeQuery>({
			query: (query) => QueryUrl(`boostedproduct`, query),
			providesTags: [tags.boostedProduct],
		}),
		// delete social boost Product
		deleteSocialBoostProduct: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `removesocialboostproduct/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: [tags.socialBoostedProduct],
		}),
		// delete boost Product
		deleteBoostProduct: builder.mutation<Product, string>({
			query: (slug) => ({
				url: `removeboostproduct/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: [tags.boostedProduct],
		}),
		boostProduct: builder.query<TBoostResponse, TBoostData>({
			query: (data) => ({
				url: QueryUrl(`${ApiBaseUrl}api/${data?.endpoint}/${data?.slug}`, {
					amount: data?.amount,
					env: data?.env,
					duration: data?.duration,
					promo: data?.type,
				}),
				method: "GET",
			}),
		}),
	}),
});

export const {
	useGetProductByCommerceQuery,
	useLazyGetProductByCommerceQuery,
	useGetProductsByVisitorQuery,
	useLazyProductsByVisitorBySlugQuery,
	useAddProductMutation,
	useEditProductMutation,
	useDeleteProductMutation,
	useActiveProductMutation,
	useLazyProductBySlugQuery,
	useProductByFilterQuery,
	useLazyGetproductFavoriteByUserQuery,
	useGetProductByCommerceByVisitorQuery,
	useAddProductFavoriteMutation,
	useDeleteFavoriteProductMutation,
	useRateProductMutation,
	useGetSimilaryProductQuery,
	useGetBoostedProductQuery,
	useGetTopProductQuery,
	useGetSocialBoostedProductQuery,
	useDeleteBoostProductMutation,
	useDeleteSocialBoostProductMutation,
	useBoostProductQuery,
	useLazyBoostProductQuery,
} = ProductApi;
export function useProductFromLocation(): [Product, boolean, string, (slug: string) => any] {
	const itemState = useLocationState<Product | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyProductBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		} else {
			console.log("slug", itemState);
		}
	}, [itemState]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/app/commercant/articles", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			console.log("slug", data);
			setItem(data);
		}
	}, [data]);

	return [item as Product, isLoading, slug as string, findBySlug];
}

export function useProductVisitorFromLocation(): [Product, boolean, string, (slug: string) => any] {
	const itemState = useLocationState<Product | undefined>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyProductsByVisitorBySlugQuery();
	const navigate = useNavigate();

	useEffect(() => {
		findBySlug(slug as string);
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/marketplace", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as Product, isLoading, slug as string, findBySlug];
}