import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useForgetPasswordMutation } from "../../../../utils/api/auth/auth.api";
import { ForgetPasswordFormData } from "../../../../utils/api/auth/auth.type";
import { Color } from "../../../../utils/theme";
export function UseForgetPasswordForm() {
	const [forgetPassword, { error, isSuccess, isLoading, originalArgs }] =
		useForgetPasswordMutation();
	const navigate = useNavigate();
	const validationSchema = useMemo(
		() =>
			yup.object().shape({
				email: yup.string().email().required().label("L'email"),
			}),
		[]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setError,
	} = useForm<ForgetPasswordFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (error) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			setError("email", { message: message });
		}
	}, [error]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				iconColor: Color.success,
				confirmButtonColor: Color.success,
				title: "Un email vous a été envoyé, merci de vérifier",
			}).then(() =>
				navigate("/app/modifier-mot-de-passe", {
					replace: true,
					state: { email: originalArgs?.email },
				})
			);
		}
	}, [isSuccess]);

	useEffect(() => {
		if (errors) {
			setTimeout(() => {
				Object.entries(errors).map(([key]: any) => clearErrors(key));
			}, 3000);
		}
	}, [errors]);

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(forgetPassword),
		clearErrors,
		isLoading,
	};
}
