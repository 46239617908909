import TitlePage from "../MyCustumerArea/TitlePage";
import { AddPartnerForm } from "../../admin/PartenaireAdmin/AddPartenaireAdmin";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import FrontFooter from "../../../shared/FrontFooter/FrontFooter";

const IntegrerAnnuaire = () => {
	return (
		<>
			<div className="project-listing-component">
				<FrontHeader />
				<div className="fixed-after-header">
					<TitlePage label="Réferencer votre entreprise" />
					<section className="content-page-container">
						<div className="container-munngano">
							<div className="form-container px-3 pt-3 pt-md-5">
								<AddPartnerForm inputClassName="espace-client-form-control" />
							</div>
						</div>
					</section>
				</div>
				<FrontFooter />
			</div>
		</>
	);
};

export default IntegrerAnnuaire;
