import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { cleannerError } from "../../../../../utils/utils";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";
import { TCandidatureFormData } from "../../../../../utils/api/candidature/canditure.type";
import { useDropCandidatureMutation } from "../../../../../utils/api/candidature/candidature.api";

const useApplyForm = () => {
	const [dropCandidature, { isLoading, isSuccess, isError, error }] =
		useDropCandidatureMutation();
	const navigate = useNavigate();
	const validationSchema = yup.object().shape({
		titre_candidat: yup.string().required().label("Titre candidat"),
		nom: yup.string().required().label("Nom candidat"),
		prenom: yup.string().required().label("Prenom candidat"),
		adresse: yup.string().required().label("Adresse candidat"),
		phone: yup.string().required().label("Téléphone candidat"),
		cv: yup.mixed().required().label("CV candidat"),
		modification_letter: yup.mixed(),
		pretention_salariale: yup.string().required().label("Prétenton salariale"),
		level_study: yup.string().required().label("Années d’expériences"),
		experience_years: yup.string(),
		project: yup.string().required(),
		candidat: yup.string().required(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<TCandidatureFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors, 5000);
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Candidature soumise avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => navigate("/app/mon-espace-client/mes-candidatures"));
			reset();
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = async (data: TCandidatureFormData) => {
		// console.log(data);
		const { cv, modification_letter, ...rest } = data;
		console.log(cv, modification_letter);
		const formData = new FormData();
		if (modification_letter) {
			formData.append("modification_letter", modification_letter);
		}
		if (cv) {
			formData.append("cv", cv);
		}
		for (let key of Object.keys(rest)) {
			const val = data[key];
			formData.append(key, val);
		}

		dropCandidature(formData);
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
	};
};

export default useApplyForm;
