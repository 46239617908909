import React from "react";
import CloseModalBtn from "../../../shared/CloseModalBtn";

var ChatBoxModal = () => {
  return (
    <div className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h5
            className="modal-title sous-titre-devenir-commercant"
            id="ChatBoxModal"
          >
            Envoyer un message
          </h5>
          <CloseModalBtn />
        </div>
        <div className="modal-body">
          <div className="py-2">
            <form>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group my-4">
                    <input
                      type="text"
                      name="fullname"
                      className="espace-client-form-control form-control"
                      placeholder="Prénom & Nom "
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group my-4">
                    <input
                      type="email"
                      name="email"
                      className="espace-client-form-control form-control"
                      placeholder="Email"
                    />
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group my-4">
                    <textarea
                      className="espace-client-form-control form-control"
                      id="exampleFormControlTextarea1"
                      rows={6}
                      placeholder="Message"
                    ></textarea>
                  </div>
                </div>
                <div className="col-md-12 d-flex justify-content-end">
                  <button type="submit" className="btn btn-theme">
                    Envoyer
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChatBoxModal;
