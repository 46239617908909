import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Tsector } from "../../utils/api/sector/sector.type";
import { AppStorage } from "../../utils/storage";

export const SectorSlice = createSlice({
	name: "sectorSlice",
	initialState: {},
	reducers: {
		onSetSectors: (state, action: PayloadAction<Tsector[]>) => {
			state = action.payload;
			AppStorage.setItem("sectors", state);
			return state;
		},
	},
});

export const { onSetSectors } = SectorSlice.actions;
