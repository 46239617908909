import React, { useEffect, useRef, useState } from "react";
import "./FrontHeader.css";
import Logo from "../../assets/appImages/logo.png";
import { NavLink } from "react-router-dom";
import Category from "../../assets/icons/Category.png";
import FirstHeader from "./FirstHeader";
import { useAppSelector } from "../../../redux/hooks";
import DropdownCategoryList from "./DropdownCategoryList";
import { CgMenuRight } from "react-icons/cg";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";
import { useCheckIfCurrentUserIsPro } from "../../modules/user/ReseauPro/RejoindreReseau";

const FrontHeader: React.FC = () => {
	const { token } = useAppSelector((state) => state.user);

	return (
		<div className="header">
			<FirstHeader token={token} />
			<Navbar />
		</div>
	);
};
export const Navbar = () => {
	const ref = useRef<any>(null);
	const [showDropDown, setShowDropDown] = useState<boolean>(false);

	const handleClickOutside = (event) => {
		if (ref.current && !ref.current?.contains(event.target)) {
			setShowDropDown(false);
		}
	};

	showDropDown ? disableBodyScroll(document) : enableBodyScroll(document);

	useEffect(() => {
		if (showDropDown) {
			document.addEventListener("click", handleClickOutside, true);
		}
		return () => {
			document.removeEventListener("click", handleClickOutside, true);
		};
	}, []);
	const toggle = () => {
		setShowDropDown((showDropDown) => !showDropDown);
	};
	const { isMember } = useCheckIfCurrentUserIsPro();
	return (
		<nav className="navbar navbar-expand-lg navbar-light nav-header-custom">
			<div className="container-fluid">
				<div className="logo-and-version-container">
					<div className="logo-container">
						<a className="navbar-brand" href="/">
							<img src={Logo} alt="Munngano" className="app-logo" />
						</a>
					</div>
				</div>
				<button
					className="navbar-toggler"
					type="button"
					data-bs-toggle="collapse"
					data-bs-target="#navbarNav"
					aria-controls="navbarNav"
					aria-expanded="false"
					aria-label="Toggle navigation"
				>
					<span className="navbar-toggler-icon">
						<CgMenuRight />{" "}
					</span>
				</button>
				<div className="collapse navbar-collapse munganno-menu px-2 mt-1" id="navbarNav">
					<div className="border-bottom  first-menu pb-3">
						<ul className="navbar-nav navbar-nav-menu-link-header">
							<li className="nav-item nav-item-menu-link">
								<NavLink className="nav-link menu-link" aria-current="page" to="/">
									Accueil
								</NavLink>
							</li>
							{/* {token && ( */}
							<li className="nav-item nav-item-menu-link">
								<a
									className="nav-link menu-link"
									// to="/app/mon-espace-client/tableau-de-bord"
									href="/app/mon-espace-client/parametres"
								>
									Mon espace client
								</a>
							</li>
							{/* )} */}
						</ul>
						<ul className="navbar-nav navbar-nav-btn-menu-header">
							<li className="nav-item-btn-end">
								<a
									className="nav-link menu-link-btn btn"
									href="/app/annonce/deposer-annonce"
								>
									Déposer une annonce
								</a>
							</li>
							<li className="nav-item-btn-end">
								{isMember ? (
									<a
										className="nav-link menu-link-btn btn"
										href="/app/mon-espace-client/reseau"
									>
										Mon réseau
									</a>
								) : (
									<a
										className="nav-link menu-link-btn btn"
										href="/app/rejoindre-reseau"
									>
										Rejoindre le réseau
									</a>
								)}
							</li>
							{/* <li className="nav-item-btn-end">
								{is_seller && commerce?.valid ? (
									<a
										className="nav-link menu-link-btn btn"
										href="/app/commercant/dashboard"
									>
										Mon espace commerçant
									</a>
								) : !is_admin ? (
									<a
										className="nav-link menu-link-btn btn"
										href="/app/devenir-commercant"
									>
										Devenir commerçant
									</a>
								) : (
									<a
										className="nav-link menu-link-btn btn"
										href="/app/admin/tableau-de-bord"
									>
										Mon tableau de bord
									</a>
								)}
							</li> */}
						</ul>
					</div>

					<div className="w-menu2">
						<ul className="navbar-nav header-navbar-items">
							<li className="nav-item nav-item-menu2 allow-focus" id="dropdown-category">
								<button
									className="nav-link menu-link-header btn dropdown-toggle"
									type="button"
									id="toutesLesCategories"
									data-bs-toggle="dropdown"
									aria-expanded="false"
									onClick={toggle}
								>
									<img
										src={Category}
										alt="icon category"
										className="icon-category d-none d-sm-inline"
									/>
									<span className="ps-2">TOUTES LES CATÉGORIES</span>
								</button>
								{showDropDown && (
									<ul
										className="dropdown-menu show pb-0"
										aria-labelledby="toutesLesCategories"
										onMouseLeave={toggle}
										ref={ref}
									>
										<DropdownCategoryList setShowDropDown={setShowDropDown} />
									</ul>
								)}
							</li>
							{/* <li className="nav-item nav-item-menu2">
								<NavLink
									className="nav-link menu-link-header"
									to="/app/liste-des-commerces"
								>
									COMMERCES
								</NavLink>
							</li> */}
							<li className="nav-item nav-item-menu2">
								<a className="nav-link menu-link-header" href="/app/annuaires">
									Annuaires 
								</a>
							</li>
							<li className="nav-item nav-item-menu2">
								<a className="nav-link menu-link-header" href="/app/categories">
									ANNONCES ET APPEL D'OFFRES
								</a>
							</li>
							{/* <li className="nav-item nav-item-menu2">
								<a
									className="nav-link menu-link-header"
									href="/marketplace"
								>
									MARKETPLACE
								</a>
							</li> */}
						</ul>
					</div>
				</div>
			</div>
		</nav>
	);
};
export default FrontHeader;
