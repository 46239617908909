import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import { IService, TServiceForm } from "./service.type";

const tags = {
	service: "service",
};

export const ServiceApi = createApi({
	reducerPath: "serviceApi",
	tagTypes: [tags.service],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getServiceMessages: builder.query<PaginationResults<IService>, TypeQuery & {statut: string | null}>({
			query: (query) => QueryUrl(`service/`, query),
			providesTags: [tags.service],
		}),
		addService: builder.mutation<IService, TServiceForm>({
			invalidatesTags: [tags.service],
			query: (data) => {
				return {
					url: `service/`,
					method: "POST",
					body: data,
				};
			},
		}),
		updateService: builder.mutation<IService, { slug?: string; data: TServiceForm }>({
			invalidatesTags: [tags.service],
			query: ({ slug, data }) => {
				return {
					url: `service/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),

		deleteService: builder.mutation<IService, string>({
			query: (slug) => ({
				url: `service/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.service],
		}),
	}),
});

export const {
	useAddServiceMutation,
	useGetServiceMessagesQuery,
	useUpdateServiceMutation,
	useDeleteServiceMutation,
} = ServiceApi;
