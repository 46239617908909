import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults, TypeQuery } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import {
	AboutFormData,
	IAbout,
	IAboutVisitor,
	ISocialMedia,
	ITeam,
	ITestimonial,
	SocialMediaFormData,
	TeamFormData,
	TestimonialFormData,
} from "./about.type";

export const AboutApi = createApi({
	tagTypes: ["about", "aproposbyvisitor", "team", "testimonial", "socialMedia"],
	reducerPath: "aboutApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getAbouts: builder.query<PaginationResults<IAbout>, TypeQuery>({
			query: () => `apropos/`,
			providesTags: ["about"],
		}),
		addOrEditAbout: builder.mutation<
			AboutFormData,
			{ slug?: string; data: AboutFormData | FormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `apropos/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `apropos/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["about"],
		}),
		deleteAbout: builder.mutation<IAbout, string>({
			query: (slug) => ({
				url: `apropos/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["about"],
		}),
		getAbout: builder.query<IAboutVisitor, void>({
			query: () => `aproposbyvisitor/`,
			providesTags: ["aproposbyvisitor"],
		}),
		getTeams: builder.query<PaginationResults<ITeam>, TypeQuery>({
			query: () => `team/`,
			providesTags: ["team"],
		}),
		addOrEditTeam: builder.mutation<
			TeamFormData,
			{ slug?: string; data: TeamFormData | FormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `team/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `team/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["team"],
		}),
		deleteTeam: builder.mutation<ITeam, string>({
			query: (slug) => ({
				url: `team/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["team"],
		}),

		getTestimonials: builder.query<PaginationResults<ITestimonial>, TypeQuery>({
			query: () => `testimonial/`,
			providesTags: ["testimonial"],
		}),
		addOrEditTestimonial: builder.mutation<
			TestimonialFormData,
			{ slug?: string; data: TestimonialFormData | FormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `testimonial/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `testimonial/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["testimonial"],
		}),
		deleteTestimonial: builder.mutation<ITestimonial, string>({
			query: (slug) => ({
				url: `testimonial/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["testimonial"],
		}),
		updateSocialMedia: builder.mutation<
			ISocialMedia,
			{ slug?: string; data: SocialMediaFormData }
		>({
			query: ({ slug, data }) => {
				if (slug) {
					return {
						url: `reseausocial/${slug}/`,
						method: "PUT",
						body: data,
					};
				}
				return {
					url: `reseausocial/`,
					method: "POST",
					body: data,
				};
			},
			invalidatesTags: ["socialMedia"],
		}),
		getSocialMedia: builder.query<ISocialMedia, void>({
			query: () => "reseausocial/",
			transformResponse: ({ results }) => results[0],
			providesTags: ["socialMedia"],
		}),
		getSocialMediaVisitor: builder.query<ISocialMedia, void>({
			query: () => "reseauxsociauxbyvisitor/",
			transformResponse: ({ results }) => results[0],
			providesTags: ["socialMedia"],
		}),
	}),
});

export const {
	useAddOrEditAboutMutation,
	useDeleteAboutMutation,
	useGetAboutsQuery,
	useGetAboutQuery,
	useGetTeamsQuery,
	useAddOrEditTeamMutation,
	useDeleteTeamMutation,
	useAddOrEditTestimonialMutation,
	useDeleteTestimonialMutation,
	useGetTestimonialsQuery,
	useGetSocialMediaQuery,
	useUpdateSocialMediaMutation,
	useGetSocialMediaVisitorQuery,
} = AboutApi;
