import TopAnnoncesTableSkeleton from "./TopAnnoncesTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { RiDeleteBin7Fill } from "react-icons/ri";
import { calcEndDay, formattedDate } from "../../../../../utils/utils";
import {
  useDeleteBoostAnnonceMutation,
  useGetTopAnnonceQuery,
} from "../../../../../utils/api/annonce/annonce.api";
import { useState } from "react";
import { CustomPagination } from "../AdminListTable/AdminListTable";
import { useDelete } from "../../../admin/Boosted/UseBoosted";
import { IAnnonce } from "../../../../../utils/api/annonce/annonce.type";

function TopAnnoncesTable() {
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const { data, isLoading } = useGetTopAnnonceQuery({
    page,
    limit: perPage,
  });

  const actionFormatter: any = (cell, row) => {
    return (
      <div className="table-actions-btn-container-commandes gap-2 d-flex">
        <DeleteBoostedAnnoncement item={row} />
      </div>
    );
  };
  const columns = [
		{
			dataField: "title",
			text: "Titre",
			style: { textAlign: "left" },
		},
		{
			dataField: "user",
			text: "Proprietaire",
			formatter: (cell: any, row: any) =>
				row?.user?.firstname + " " + row?.user?.lastname,
		},
		{
			dataField: "created_at",
			text: "Date de boost",
			formatter: (cell: string, row: IAnnonce) =>
				formattedDate(row?.boost?.boost?.date_debut),
		},
		{
			dataField: "date_fin",
			text: "Jours restants",
			formatter: (cell: string, row: any) => calcEndDay(row?.boost?.boost?.date_fin),
		},
		{
			dataField: "slug",
			text: "actions",
			formatter: (cell: any, row: IAnnonce) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "8px" };
			},
		},
  ];

  return (
    <>
      {isLoading && <TopAnnoncesTableSkeleton />}
      {!isLoading && (
        <>
          <BootstrapTable
            keyField="id"
            data={data?.results || []}
            columns={columns}
            striped
            noDataIndication="Aucune donnée disponible"
            bordered={false}
            condensed={false}
            responsive
            wrapperClasses="table-responsive admin-table"
            rowStyle={{
              fontFamily: "Montserrat",
              fontStyle: "normal",
              fontWeight: "600",
              fontSize: "14px",
              lineHeight: "21px",
              color: "rgba(0, 0, 0, 0.7)",
            }}
            headerClasses="header-class"
          />
          {data?.results && data?.results?.length > 0 && (
            <CustomPagination
              nbPages={data?.count}
              page={page}
              perPage={perPage}
              onChange={(page, perPage) => {
                setPerPage(perPage);
                setPage(page);
              }}
            />
          )}
        </>
      )}
    </>
  );
}

export function DeleteBoostedAnnoncement({ item }: { item: IAnnonce }) {
  const [deleteItem, { isSuccess, isError, error }] =
    useDeleteBoostAnnonceMutation();
  const onDelete = useDelete<IAnnonce>({
    item,
    deleteItem,
    isSuccess,
    isError,
    error,
    question: "le boost de cette annonce?",
    successMessage: "Le boost de l'annonce supprimé",
  });
  return (
    <button
      className="btn btn-action-user-admin"
      title="Supprimer"
      onClick={onDelete}
    >
      <RiDeleteBin7Fill />
    </button>
  );
}

export default TopAnnoncesTable;
