import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { EditProjectFormData, IFavProject, IProject, ProjectFormData } from "./project.type";
import { prepareHeaders } from "../user/user.api";
import { AppStorage } from "../../storage";
import { AuthState } from "../../../redux/slice/User.slice";
import { Tsector } from "../sector/sector.type";
import { QueryUrl } from "../../utils";
import { PaginationResults, TypeQuery } from "../commerce/commerce.type";

export const ProjectApi = createApi({
	tagTypes: [
		"project",
		"projectByUser",
		"findProjectBySlug",
		"projectVisitors",
		"favoritesProjectsByUser",
	],
	reducerPath: "projectAPi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		// Add Project mutation
		addProject: builder.mutation<IProject, ProjectFormData | any>({
			query: (data) => ({
				url: "/project/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["project"],
		}),
		// get Projetcts
		getProject: builder.query<PaginationResults<IProject>, TypeQuery>({
			query: (query) => QueryUrl("project/", query),
			providesTags: ["project"],
		}),
		// get projectbyuser
		getProjectByUser: builder.query<IProject[], void>({
			query: () => `projectbyuser/${AppStorage.getItem<any>("user", {}).user?.slug}/`,
			providesTags: ["projectByUser"],
			// transformResponse:
		}),
		// get project for visitors
		getProjectVisitors: builder.query<IProject[], void>({
			query: () => `projectbyvisitor/`,
			providesTags: ["projectVisitors"],
			transformResponse: ({ results }) => results,
		}),
		getSimilarProjects: builder.query<IProject[], string>({
			query: (slug) => `project_similarbyvisitor/${slug}`,
			providesTags: ["projectVisitors"],
		}),
		// get project for visitors filter by sector
		getProjectVisitorsFilterBySector: builder.query<
			IProject[],
			{ secteur: Tsector["name"] | null }
		>({
			query: (query) => QueryUrl(`projectbyvisitor/`, query),
			providesTags: ["projectVisitors"],
			transformResponse: ({ results }) => results,
		}),
		// delete Projetct by slug
		deleteProject: builder.mutation<IProject, string>({
			query: (slug) => ({
				url: `project/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["projectByUser", "project"],
		}),
		// edit Projetct by slug
		editProject: builder.mutation<IProject, EditProjectFormData | any>({
			query: ({ slug, formData }) => ({
				url: `/project/${slug}/`,
				body: formData,
				method: "PUT",
			}),
			invalidatesTags: ["project", "projectByUser"],
		}),
		// get project by slug
		findProjectBySlug: builder.query<IProject, string | undefined>({
			query: (slug) => ({
				url: `project/${slug}/`,
			}),
			providesTags: ["findProjectBySlug"],
		}),
		findProjectBySlugVisitor: builder.query<IProject, string | undefined>({
			query: (slug) => ({
				url: `projectbyvisitor/${slug}/`,
			}),
			providesTags: ["findProjectBySlug"],
		}),
		getProjectsFavoriteByUser: builder.query<IFavProject[], void>({
			query: () =>
				`favorite_projectbyuser/${
					AppStorage.getItem<AuthState>("user", {}).user?.slug
				}`,
			providesTags: ["favoritesProjectsByUser"],
			transformResponse: ({ results }) => results,
		}),
	}),
});

export const {
	useAddProjectMutation,
	useGetProjectQuery,
	useLazyGetProjectByUserQuery,
	useGetProjectVisitorsQuery,
	useDeleteProjectMutation,
	useEditProjectMutation,
	useLazyFindProjectBySlugQuery,
	useLazyGetProjectsFavoriteByUserQuery,
	useGetProjectVisitorsFilterBySectorQuery,
	useLazyFindProjectBySlugVisitorQuery,
	useGetSimilarProjectsQuery,
} = ProjectApi;
