import "../homePage/HomePage.css";
import FloatButton from "../homePage/FloatButton";
import "./LandingPage.css";
import Concept from "./Concept";
import Secteurs from "./Secteurs";
import Communautes from "./Communautes";
import Events from "./Events";
import FrontFooterLandingpage from "./FrontFooterLandingpage/FrontFooterLandingpage";
import HeaderLandingpage from "./HeaderLandingpage/HeaderLandingpage";
const LandingPage = () => {
	return (
		<div className="homepage-component">
			<HeaderLandingpage />
			<div className="fixed-after-header landingpage-fixed-after-header">
				<div className="container-banner-landinpage">
                    <div className="content-bg-banner-landingpage">
                        <h2 className="title-banner-landingpage">Ensemble, connectons vos <br /> ambitions avec l'Afrique !</h2>
						<div className="content-btn-banner-landingpage pt-4 text-center">
							<button className="btn btn-banner-landingpage">Boostez Votre Réussite</button>
						</div>
                    </div>
                </div>
				<div className="position-relative">
					<FloatButton />
				</div>
				<Concept />
				<Secteurs />
				<Communautes />
				<Events />
			</div>
			
			<FrontFooterLandingpage />
		</div>
	);
};

export default LandingPage;
