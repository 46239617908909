import React, { useState } from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { OrderTypeFormData } from '../../../../../../utils/api/order/order.type'
import { useEditOrderMutation } from '../../../../../../utils/api/order/order.api'
import { cleannerError, onHide } from '../../../../../../utils/utils'
import { Color } from '../../../../../../utils/theme'

function UseEditStatusOrder() {
  const validationSchema = yup.object().shape({
    status: yup.string().required().label('Le statut').nullable(),
    slug: yup.string().nullable(),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
  } = useForm<OrderTypeFormData | any>({
    resolver: yupResolver(validationSchema),
  })
  const [
    sendData,
    { isLoading, isSuccess, isError, error, data },
  ] = useEditOrderMutation()
  const [level, setLevel] = useState<any>(0)
  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      console.log('success', data)
      onHide(`ChangeStatusOrderModal${data?.slug}`)
      Swal.fire({
        icon: 'success',
        title: 'Statut modifié avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log('formValues', formValues)

    await sendData(formValues)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading,
  }
}

export default UseEditStatusOrder
