import { BiChevronUp } from 'react-icons/bi'
import ReactQuill from 'react-quill'
import StarRatingComponent from 'react-star-rating-component'
import { FormError } from '../../../common/CustomInput'
import UseAddRateComment from './requestRate/UseAddRateComment'

const FormAddComment = ({ commerce }) => {
  const {
    rate,
    setRate,
    comment,
    setComment,
    setValue,
    onSubmit,
    errors,
    isLoading,
  } = UseAddRateComment()

  const onChangeRate = (nextValue, prevValue, name) => {
    setRate(nextValue)
    setValue('rating', nextValue)
    setValue('commerce', commerce?.id)
  }
  const handleChange = (value: any) => {
    setComment(value)
    setValue('comment', value)
    setValue('commerce', commerce?.id)
  }
  return (
		<div className="form-add-comment-container card-blog mt-3 py-3">
			<div className="px-3 write-comment-container mb-3">
				<h2 className="write-comment-text">Laisser votre avis</h2>
				<BiChevronUp />
			</div>
			<form className="px-3" onSubmit={onSubmit}>
				<div className="row mb-2">
					<div className="col-md-12 mb-3">
						<label htmlFor="rate" className="mb-3 fs-16 fw-500">
							Donner une note à ce commerce
						</label>
						<div className="form-group fs-35">
							<StarRatingComponent
								name="rate2"
								editing={true}
								starCount={5}
								value={rate}
								onStarClick={onChangeRate}
								starColor="#FFA73C"
								emptyStarColor="#f6973f96"
							/>
						</div>
						{<FormError error={errors?.rating?.message} />}
					</div>
					<div className="col-12 mb-3">
						<label htmlFor="rate" className="mb-3 fs-16 fw-500">
							Laisser un commentaire
						</label>
						<ReactQuill
							theme="snow"
							value={comment}
							onChange={(value) => handleChange(value)}
						/>
						{<FormError error={errors?.comment?.message} />}
					</div>
					<div className="col-md-4 offset-md-4 mt-3">
						<button className="btn btn-theme trans-0-2 w-100">
							{!isLoading ? (
								"Envoyer"
							) : (
								<>
									<span
										className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
										role="status"
									></span>
									<span>En cours...</span>
								</>
							)}
						</button>
					</div>
				</div>
			</form>
		</div>
  );
}

export default FormAddComment
