import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IRate, TRateAnnonceData, TUpdateRateAnnonceData } from "./rateAnnonce.type";
import { prepareHeaders } from "../user/user.api";

export const rateAnnonceApi = createApi({
	reducerPath: "rateAnnonceApi",
	tagTypes: ["rateAnnonce", "findAnnonceBySlug", "annonce"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addRateAnnonce: builder.mutation<TRateAnnonceData, any>({
			query: (data) => ({
				url: "rate_announce/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["rateAnnonce", "findAnnonceBySlug", "annonce"],
		}),
		updateRateAnnonce: builder.mutation<TUpdateRateAnnonceData, IRate | any>({
			query: ({ slug, ...data }) => ({
				url: `rate_announce/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["rateAnnonce", "findAnnonceBySlug", "annonce"],
		}),
	}),
});

export const { useAddRateAnnonceMutation, useUpdateRateAnnonceMutation } = rateAnnonceApi;
