import React from "react";
import AbonnementsTable from "../../Tables/Admin/Abonnements/AbonnementsTable";

const Abonnements = () => {
  return (
    <div className="tab-content-admin">
      <AbonnementsTable />
    </div>
  );
};

export default Abonnements;
