import Divider from '../../../../shared/Divider'
import Whatsapp from '../../../../assets/icons/whatsapp.png'
import Phone from '../../../../assets/icons/phone.png'
import Mail from '../../../../assets/icons/mail.png'
import {
  Facebook,
  Instagram,
  Linkedin,
  Twitter,
} from '../../../../shared/Icons'
import { Product } from '../../../../../utils/api/product/product.type'
import { getAvatar } from '../../../../../utils/utils'

function SellerInfo({ product }: { product: Product }) {
  console.log(product)
  return (
    <div className="flex-m flex-column container-munngano">
      <div className="seller-img-container">
        <img
          src={getAvatar(product?.commerce?.author?.avatar)}
          alt="vendeur"
          className="seller-img"
        />
      </div>
      <div className="my-4">
        <h3 className="seller-info fw-700">
          {product?.commerce?.author?.firstname}{' '}
          {product?.commerce?.author?.lastname}
        </h3>
        <address className="seller-info fw-400">
          {product?.commerce?.author?.adresse}
        </address>
      </div>
      <div className="shadow w-100">
        <div className="flex-sb links-seller px-3 py-3">
          <h5>Contacter {product?.commerce?.author?.firstname}</h5>
          <h5>Suivre sur les réseaux sociaux</h5>
        </div>
        <Divider />
        <div className="row py-4 align-items-baseline">
          <div className="flex-sa col-md-6 col-xxl-5">
            <a
              href={`https://wa.me/${product?.commerce?.author?.whatsapp}`}
              target="_blank"
              className="constainer-icon-socials-media"
            >
              <img
                src={Whatsapp}
                alt="whatsapp"
                className="icon-socials-media"
              />
              <p className="text-icon-socials-media">Whatsapp</p>
            </a>
            <a
              href={`tel:${product?.commerce?.author?.phone}`}
              target="_blank"
              className="constainer-icon-socials-media"
            >
              <img src={Phone} alt="whatsapp" className="icon-socials-media" />
              <p className="text-icon-socials-media">Appeler</p>
            </a>
            <a
              href={`mailto:${product?.commerce?.author?.email}`}
              target="_blank"
              className="constainer-icon-socials-media"
            >
              <img src={Mail} alt="whatsapp" className="icon-socials-media" />
              <p className="text-icon-socials-media">Envoyer un email</p>
            </a>
          </div>
          <div className="flex-sa col-md-6 col-xxl-5 offset-xxl-2">
            <a
              href={product?.commerce?.facebook}
              className="icon-social-container"
              target="_blank"
            >
              <Facebook />
            </a>
            <a
              href={product?.commerce?.twitter}
              className="icon-social-container"
              target="_blank"
            >
              <Twitter />
            </a>
            <a
              href={product?.commerce?.instagram}
              className="icon-social-container"
            >
              <Instagram />
            </a>
            <a
              href={product?.commerce?.linkedin}
              className="icon-social-container"
              target="_blank"
            >
              <Linkedin />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}
export default SellerInfo
