import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { cleannerError, createUrl, onHide } from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";
import { ISlide, SlideFormData } from "../../../../../utils/api/slide/slide.type";
import {
	useAddSlideMutation,
	useDeleteSlideMutation,
} from "../../../../../utils/api/slide/slide.api";

function useAddSlide() {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Le nom"),
		fonction: yup.string().required().label("La fonction"),
		image_couverture: yup.mixed().required().label("L'Image"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<SlideFormData>({
		resolver: yupResolver(validationSchema),
	});
	const [image, setImage] = useState<string>("");
	const [addData, { isLoading, isSuccess, isError, error, data, reset }] =
		useAddSlideMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	const handleChangeImage = (e: React.FormEvent<HTMLInputElement>) => {
		if (e.currentTarget.files) {
			console.log(e.currentTarget.files);
			setImage(createUrl(e.currentTarget.files[0]));
			setValue("image_couverture", e.currentTarget.files[0]);
		}
	};

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			Swal.fire({
				icon: "success",
				title: "Slide ajoutée avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(async () => {
				reset();
				onHide("AddSlideModal");
				setImage("");
			});
		}
	}, [isSuccess]);
	const onSubmit = async (formValues: SlideFormData) => {
		console.log(formValues);
		const fd = new FormData();
		for (let key of Object.keys(formValues)) {
			const val = formValues[key];
			fd.append(key, val);
		}
		await addData(fd);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		isLoading,
		reset,
		image,
		handleChangeImage,
	};
}

export function useDeleteSlide(item: ISlide) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteSlideMutation();

	const onDelete = useCallback(() => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cette slide",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Slide supprimée avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
export default useAddSlide;
