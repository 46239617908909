import React, { useState } from "react";
import MesMessagesTabs from "../../user/MyCustumerArea/MesMessagesTabs";

const ChatAdmin = () => {
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4"></div>
      <div>
        <MesMessagesTabs />
      </div>
    </div>
  );
};

export default ChatAdmin;
