import { BsFillCameraFill } from "react-icons/bs";
import "./AddUserModal.scss";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { FormError } from "../../../common/CustomInputAdmin";
import { Modal, ModalProps } from "reactstrap";
import { IModalProps } from "../../../../utils/type";
import { BtnSubmit } from "../../../common/Button";
import useCrudTeam from "../../admin/About/useForm/useCrudTeam";
import { getAvatar } from "../../../../utils/utils";
import { ITeam } from "../../../../utils/api/about/about.type";

// @ts-ignore
const AddMemberModal: React.FC<ModalProps & IModalProps<ITeam>> = ({
	isOpen,
	closeModal,
	item,
}) => {
	const { register, onSubmit, errors, isLoading, avatar, handleChangeImage, reset } =
		useCrudTeam(closeModal, item);

	return (
		<Modal isOpen={isOpen} size="lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title modal-title-custom" id="AddMemberModal">
						{item ? "Modifier" : "Ajouter"} un membre de l'équipe
					</h5>
					<CloseModalBtn onClick={reset} />
				</div>
				<div className="modal-body">
					<form onSubmit={onSubmit}>
						<div className="mb-4">
							<div className="profile-pic">
								<label className="-label" htmlFor="fileAdmin">
									<span className="glyphicon glyphicon-camera">
										<BsFillCameraFill />
									</span>
									<span>Change Image</span>
								</label>
								<input
									id="fileAdmin"
									type="file"
									accept="image/*"
									onChange={(e) => handleChangeImage(e)}
								/>
								<img
									src={
										avatar
											? URL.createObjectURL(avatar)
											: getAvatar(item?.image)
									}
									id="fileAdmin"
									width="200"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Nom complet
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Nom complet"
										{...register("nom")}
									/>
									<FormError error={errors?.nom?.message} />
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Fontion
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Fontion"
										{...register("fonction")}
									/>
									<FormError
										error={errors?.fonction?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Position
										<span className="text-danger">*</span>
									</label>
									<input
										type="number"
										className="form-control form-control-input-admin"
										placeholder="Position"
										{...register("position")}
									/>
									<FormError
										error={errors?.position?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Facebook
									</label>
									<input
										type="url"
										className="form-control form-control-input-admin"
										placeholder="Facebook"
										{...register("facebook")}
									/>
									<FormError
										error={errors?.facebook?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Twitter
									</label>
									<input
										type="url"
										className="form-control form-control-input-admin"
										placeholder="Twitter"
										{...register("twitter")}
									/>
									<FormError error={errors?.twitter?.message} />
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Linkedin
									</label>
									<input
										type="url"
										className="form-control form-control-input-admin"
										placeholder="Linkedin"
										{...register("linkedin")}
									/>
									<FormError
										error={errors?.linkedin?.message}
									/>
								</div>
							</div>
						</div>
						<div className="offset-md-6 col-md-6 mb-4 mt-5">
							<BtnSubmit
								isLoading={isLoading}
								label={item ? "Modifier" : "Ajouter"}
							/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddMemberModal;
