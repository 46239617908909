import React from 'react'
import { createMarkup } from '../../../../../utils/utils'

function MarketPlaceDescription({ text }: { text: string }) {
  return (
    <div
      className="market-detail p-2"
      dangerouslySetInnerHTML={createMarkup(text)}
    />
  )
}

export default MarketPlaceDescription
