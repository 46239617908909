import { Product } from "./api/product/product.type";

export interface useStepType {
	step: any;
	navigation: any;
	index: number;
}
export type TraderStepPropsType = {
	navigation: any;
};

export type TImage = {
	created_at: string;
	deleted: null | boolean;
	id: number | string;
	picture: string;
	slug: string;
};

export interface IDashboardAdminData {
	users_count: number;
	products_count: number;
	shops_count: number;
	partners_count: number;
	providers_count: number;
	sellers_count: number;
	top_products: Product[];
	graph: [];
}

export interface DashboardCommerceData {
	users_count: number;
	products_count: number;
	orders_count: number;
	revenus: number;
}

export interface IModalProps<T> {
	isOpen: boolean;
	closeModal: () => void;
	item?: T;
	setItem?: any;
}

export enum ServicePaymentCode {
	OM = "ORANGE_SN_API_CASH_OUT",
	WAVE = "WAVE_SN_API_CASH_OUT",
	FREE = "FREE_SN_WALLET_CASH_OUT",
	CARTE = "BANK_CARD_API_CASH_OUT",
}

export enum ApiMoyenPaiement {
	OM = "ORANGE_MONEY",
	WAVE = "WAVE",
	FREE = "FREE_MONEY",
	CARTE = "VIREMENT_BANCAIRE",
	EMONEY = "E_MONEY",
}