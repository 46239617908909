import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Commerce } from "../../utils/api/commerce/commerce.type";
import { AppStorage } from "../../utils/storage";

export const commerceSlice = createSlice({
	name: "commerce",
	initialState: {},
	reducers: {
		addCommerce: (state, action: PayloadAction<Commerce | any>) => {
			state = action.payload;
			AppStorage.setItem("commerce", state);
			return state;
		},
		deleteCommerce: (state, action) => {
			state = action.payload;
			AppStorage.removeItem("commerce");
			return state;
		},
	},
});

export const { addCommerce, deleteCommerce } = commerceSlice.actions;
export default commerceSlice.reducer;
