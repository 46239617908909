import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { AppStorage } from "../../storage";
import { prepareHeaders } from "../user/user.api";
import { BudgetEditFormData, BudgetFormData, IBudget } from "./budget.type";

export const BudgetApi = createApi({
	tagTypes: ["budget", "budgetByUser"],
	reducerPath: "budgetApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addBudget: builder.mutation<BudgetFormData, any>({
			query: (data) => ({
				url: "budget/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["budget", "budgetByUser"],
		}),
		getBudgetByUser: builder.query<IBudget[], void>({
			query: () => `budgetbyuser/${AppStorage.getItem<any>("user", {}).user?.slug}/`,
			providesTags: ["budgetByUser"],
		}),
		deleteBudget: builder.mutation<IBudget, string>({
			query: (slug) => ({
				url: `budget/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["budgetByUser"],
		}),
		editdBudget: builder.mutation<BudgetEditFormData, IBudget | any>({
			query: ({ slug, data }) => ({
				url: `budget/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["budgetByUser"],
		}),
	}),
});

export const {
	useAddBudgetMutation,
	useLazyGetBudgetByUserQuery,
	useDeleteBudgetMutation,
	useEditdBudgetMutation,
} = BudgetApi;
