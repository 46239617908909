import React from 'react'
import { useState } from 'react'
import { MdOutlineAddCircleOutline } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import AddCategoryModal from '../../modals/adminModal/AddCategoryModal'
import AddSectorModal from '../../modals/adminModal/AddSectorModal'
import SectorTable from '../../Tables/Admin/SectorsTable/SectorTable'
import '../Categories/categories.css'

const Secteurs = () => {
  const [search, setSearch] = useState('')
  const modalId = 'AddSectorModal'
  const { user } = useAppSelector((s) => s?.user)
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user?.sectors && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Secteurs</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                className="search-input-admin"
                placeholder="Search.."
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-theme p-2"
              data-bs-toggle="modal"
              data-bs-target={`#${modalId}`}
            >
              <MdOutlineAddCircleOutline />
              <span className="ps-2">Ajouter un secteur</span>
            </button>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-lg-10 col-xl-8">
          <SectorTable word={search} />
        </div>
      </div>
      <AddSectorModal modalId={modalId} />
    </div>
  )
}

export default Secteurs
