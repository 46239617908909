import { BsFillCameraFill } from "react-icons/bs";
import "./AddUserModal.scss";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import { FormError } from "../../../common/CustomInputAdmin";
import { Modal, ModalProps } from "reactstrap";
import { IModalProps } from "../../../../utils/type";
import { BtnSubmit } from "../../../common/Button";
import { getAvatar } from "../../../../utils/utils";
import useCrudTestimonial from "../../admin/About/useForm/useCrudTestimonial";
import ReactQuill from "react-quill";
import { ITestimonial } from "../../../../utils/api/about/about.type";

// @ts-ignore
const AddTestimonialModal: React.FC<ModalProps & IModalProps<ITestimonial>> = ({
	isOpen,
	closeModal,
	item,
}) => {
	const {
		register,
		onSubmit,
		errors,
		isLoading,
		avatar,
		handleChangeImage,
		reset,
		handleChangeMessage,
		message,
		messageRef,
	} = useCrudTestimonial(closeModal, item);

	return (
		<Modal isOpen={isOpen} size="lg">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="modal-title modal-title-custom"
						id="AddTestimonialModal"
					>
						{item ? "Modifier" : "Ajouter"} un testimonial
					</h5>
					<CloseModalBtn onClick={reset} />
				</div>
				<div className="px-3">
					<form onSubmit={onSubmit}>
						<div className="mb-4 mt-2">
							<div className="profile-pic">
								<label className="-label" htmlFor="fileAdmin">
									<span className="glyphicon glyphicon-camera">
										<BsFillCameraFill />
									</span>
									<span>Change Image</span>
								</label>
								<input
									id="fileAdmin"
									type="file"
									accept="image/*"
									onChange={(e) => handleChangeImage(e)}
								/>
								<img
									src={
										avatar
											? URL.createObjectURL(avatar)
											: getAvatar(item?.avatar)
									}
									id="fileAdmin"
									width="200"
								/>
							</div>
						</div>
						<div className="row">
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Nom complet
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Nom complet"
										{...register("nom")}
									/>
									<FormError error={errors?.nom?.message} />
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Fontion
										<span className="text-danger">*</span>
									</label>
									<input
										type="text"
										className="form-control form-control-input-admin"
										placeholder="Fontion"
										{...register("fonction")}
									/>
									<FormError
										error={errors?.fonction?.message}
									/>
								</div>
							</div>
							<div className="col-md-6 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Position
										<span className="text-danger">*</span>
									</label>
									<input
										type="number"
										className="form-control form-control-input-admin"
										placeholder="Position"
										{...register("position")}
									/>
									<FormError
										error={errors?.position?.message}
									/>
								</div>
							</div>
							<div className="col-12 mb-3">
								<div>
									<label className="form-label label-form-admin">
										Message
										<span className="text-danger">*</span>
									</label>
									{item ? (
										<ReactQuill
											className="editor-cgu"
											value={message}
											onChange={handleChangeMessage}
											ref={messageRef}
										/>
									) : (
										<ReactQuill
											className="editor-cgu"
											defaultValue={message}
											onChange={handleChangeMessage}
											ref={messageRef}
										/>
									)}
									<FormError
										error={errors?.fonction?.message}
									/>
								</div>
							</div>
						</div>
						<div className="offset-md-6 col-md-6 mb-4 mt-5">
							<BtnSubmit
								isLoading={isLoading}
								label={item ? "Modifier" : "Ajouter"}
							/>
						</div>
					</form>
				</div>
			</div>
		</Modal>
	);
};

export default AddTestimonialModal;
