import { CKEditor } from "ckeditor4-react";
import React from "react";
import { Color } from "../../../../../utils/theme";
import { BtnBack, BtnNext, BtnSkip } from "../../../../common/Button";
import { CustomInput, FormError } from "../../../../common/CustomInput";
import { TraderStepPropsType } from "../../BecomeTrader/Steps/AddInfoTrader";

const MoreDetail = ({ navigation, register, setValue, errors, description, setDescription }) => {
	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		navigation.go("tarif");
	};
	return (
		<div>
			<h4 className="stepper-title">Plus de détails</h4>
			<div>
				<div className="col-md-12 mb-4 form-group">
					<label htmlFor="desc-produit" className="custom-form-label">
						Description du produit
						<span className="text-danger">*</span>
					</label>
					<CKEditor
						initData={description || ""}
						onChange={(e) => {
							setValue("description", e.editor.getData());
							setDescription(e.editor.getData());
						}}
					/>
					{<FormError error={errors?.description?.message} />}
				</div>
				<div className="row">
					<CustomInput
						label="Poids"
						type="poids"
						{...register("weight")}
						error={errors?.weight?.message}
						name="weight"
						style={{ backgroundColor: Color.bgGray }}
					/>
					<CustomInput
						label="Dimensions"
						type="dimensions"
						{...register("dimensions")}
						error={errors?.dimensions?.message}
						name="dimensions"
						style={{ backgroundColor: Color.bgGray }}
					/>
				</div>
				<div className="flex-r gap-3 mt-4">
					<BtnBack label="Retour" type="button" navigation={navigation} />
					<BtnNext label="Suivant" type="submit" />
				</div>
			</div>
		</div>
	);
};

export default MoreDetail;
