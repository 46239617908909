import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBaseUrl } from '../../http'
import { AppStorage } from '../../storage'
import { QueryUrl, useLocationState } from '../../utils'
import {
  PaginationResults,
  TypeQuery,
  UserTypeQuery,
} from '../commerce/commerce.type'
import { prepareHeaders } from '../user/user.api'
import {
  CartItemType,
  CartItemTypeFormData,
  CartType,
  CartTypeFormData,
  OrderType,
  OrderTypeFormData,
  OrderTypeQuery,
  PaymentQuery,
  PaymentUrl,
} from './order.type'

const tags = {
  orderByUser: 'orderByUser',
  orders: 'orders',
  orderBySlug: 'orderBySlug',
  orderByCommerce: 'orderByCommerce',
}
export const OrderApi = createApi({
  reducerPath: 'orderAPi',
  tagTypes: Object.values(tags),
  baseQuery: fetchBaseQuery({
    baseUrl: `${ApiBaseUrl}api/`,
    prepareHeaders,
  }),
  endpoints: (builder) => ({
    getPaymentUrl: builder.query<PaymentUrl, PaymentQuery>({
      query: (query) => QueryUrl(`${ApiBaseUrl}api/payment/`, query),
    }),
    getOrders: builder.query<PaginationResults<OrderType>, UserTypeQuery>({
      query: (query) => QueryUrl('/order/', query),
      providesTags: [tags.orders],
    }),
    getOrderByCommerce: builder.query<
      PaginationResults<OrderType> | any,
      OrderTypeQuery
    >({
      query: ({ slug, ...query }) =>
        QueryUrl(`orderbycommerce/${slug}/`, query),
      providesTags: [tags.orderByCommerce],
    }),
    getOrderByUser: builder.query<OrderType[] | any, void>({
      query: () =>
        `orderbyuser/${AppStorage.getItem<any>('user', {}).user?.slug}/`,
      transformResponse: ({ results }) => results,
      providesTags: [tags.orderByUser],
    }),
    getOrderByUserPaginate: builder.query<
      PaginationResults<OrderType[]> | any,
      TypeQuery
    >({
      query: (query) =>
        QueryUrl(
          `orderbyuser/${AppStorage.getItem<any>('user', {}).user?.slug}/`,
          query,
        ),
      providesTags: [tags.orderByUser],
    }),
    OrderBySlug: builder.query<OrderType, string>({
      query: (slug) => `order/${slug}/`,
      providesTags: [tags.orderBySlug],
    }),
    addOrderCart: builder.mutation<CartType, CartTypeFormData | any>({
      query: (data) => ({
        url: 'cartbyvisitor/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [tags.orderByUser, tags.orders],
    }),
    addCartItem: builder.mutation<CartItemType, CartItemTypeFormData>({
      query: (data) => ({
        url: 'cartitembyvistor/',
        body: data,
        method: 'POST',
      }),
      invalidatesTags: [tags.orderByUser, tags.orders],
    }),
    editOrder: builder.mutation<OrderType, OrderTypeFormData | any>({
      query: ({ slug, ...data }) => ({
        url: `order/${slug}/`,
        body: data,
        method: 'PUT',
      }),
      invalidatesTags: [tags.orderByUser, tags.orders],
    }),
  }),
})

export const {
  useAddCartItemMutation,
  useAddOrderCartMutation,
  useLazyGetPaymentUrlQuery,
  useLazyGetOrderByUserQuery,
  useGetOrdersQuery,
  useLazyOrderBySlugQuery,
  useGetOrderByCommerceQuery,
  useGetOrderByUserPaginateQuery,
  useEditOrderMutation,
} = OrderApi

export function useOrderFromLocation(): [
  OrderType,
  boolean,
  string,
  (slug: string) => any,
] {
  const itemState = useLocationState<OrderType | undefined>(undefined)
  const [item, setItem] = useState(itemState)
  const { slug } = useParams<{ slug: string }>()
  const [findBySlug, { data, isError, isLoading }] = useLazyOrderBySlugQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (slug) {
      // findBySlug(slug as string)
    } else {
      console.log('slug', itemState)
    }
  }, [itemState])
  useEffect(() => {
    if (isError && !itemState) {
      navigate(-1)
    }
  }, [isError])
  useEffect(() => {
    if (data) {
      console.log('slug', data)
      setItem(data)
    }
  }, [data])

  return [item as OrderType, isLoading, slug as string, findBySlug]
}
