import moment from 'moment'
import React from 'react'
import { NavLink } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { useOrderFromLocation } from '../../../../utils/api/order/order.api'
import { getImage } from '../../../../utils/utils'
import SendMessageModal from '../../modals/commercant/commandes/SendMessageModal'
import './TraderCommandes.css'

const DetailCommande = () => {
  const [item] = useOrderFromLocation()
  const { user } = useAppSelector((s) => s.user)
  const isAdmin =
    user && (user.user_type === 'admin' || user.user_type === 'superadmin')
  return (
    <div className="detail-commande bg-white p-3 pt-4 pb-5">
      <div className="flex-sb">
        <h1 className="title">Détail commande</h1>
        {!isAdmin && (
          <NavLink
            className="send-message-btn no-link"
            to={'/commercant/messages'}
            state={item?.user}
          >
            ENVOYER UN MESSAGE Au client
          </NavLink>
        )}
        {/* {isAdmin && (
          <div className="d-flex gap-2">
            <button className="article-det-btn btn-theme-white">
              Modifier
            </button>
            <button className="article-det-btn btn-theme-white">
              Supprimer
            </button>
          </div>
        )} */}
      </div>
      <div className="flex-m gap-2 mt-3">
        <h3 className="label-num-commande">N° de la commande</h3>
        <p className="num-commande">{item?.order_code}</p>
      </div>
      <div className="row pt-4">
        <div className="table-container col-md-7">
          <table className="table">
            <thead>
              <tr>
                <th scope="col">Résumé de l’article</th>
                <th scope="col">Quantité</th>
                <th scope="col">Prix unitaire</th>
                <th scope="col">Prix total</th>
              </tr>
            </thead>
            <tbody>
              {item?.cartitems.map((elem, i) => (
                <tr key={i}>
                  <th scope="row">
                    <img
                      src={getImage(elem?.item?.background_image)}
                      alt="img-commande"
                      className="img-40"
                    />
                    <span className="article-com-name fw-400 ms-2">
                      {elem?.item?.name}
                    </span>
                  </th>
                  <td>{elem?.quantity}</td>
                  <td>{elem?.price}FCFA</td>
                  <td>{elem?.price * elem?.quantity}FCFA</td>
                </tr>
              ))}
            </tbody>
          </table>
          <table className="table pt-3">
            <thead>
              <tr>
                <th scope="col">Informations du client</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="flex-sb">
                  <span>Noms</span>
                  <span className="fw-600">
                    {item?.user?.firstname + ' ' + item?.user?.lastname}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Adresse</span>
                  <span className="fw-600">{item?.user?.adresse}</span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Téléphone</span>
                  <span className="fw-600">{item?.user?.phone}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="table-container no-border col-md-5">
          <table className="table pt-3 ">
            <thead>
              <tr>
                <th scope="col">Résumé de la commande</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="flex-sb">
                  <span>Date de création</span>
                  <span className="fw-600">
                    {moment(item?.order_date).format('DD MMMM YYYY')}{' '}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Heure</span>
                  <span className="fw-600">
                    {moment(item?.order_date).format('HH:mm')}
                  </span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Prix total</span>
                  <span className="fw-600">{item?.amount_total} FCFA</span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Prix livraison</span>
                  <span className="fw-600">
                    {item?.delivery?.amount || '0'} FCFA
                  </span>
                </td>
              </tr>
              <tr>
                <td className="flex-sb">
                  <span>Payée</span>
                  <span className="fw-600">{item?.paid ? 'Oui' : 'Non'}</span>
                </td>
              </tr>
            </tbody>
          </table>
          <div className="total-container flex-sb">
            <span className="fw-600">Total</span>
            <span className="fw-700">
              {user.user_type === 'admin' || user.user_type === 'superadmin'
                ? item?.order_total
                : item?.amount_total + parseFloat(item?.shipping)}{' '}
              FCFA{' '}
            </span>
          </div>
          <div className="adresse-livraison mt-4">
            <p className="fw-400">Adresse de livraison</p>
            <address className="mt-2 mb-0 fw-600">
              {item?.shipping_address}
            </address>
          </div>
        </div>
      </div>
      <SendMessageModal />
    </div>
  )
}

export default DetailCommande
