import React, { useEffect, useState } from 'react'
import StarRatingComponent from 'react-star-rating-component'
import '../../user/PartnerList/./PartnerList.css'
import { getImage, useLocationState } from "../../../../utils/utils";
import { Partenaire } from '../../../../utils/api/partenaire/partenaire.type'
import bg from '../../../assets/appImages/bg.png'
import Description from './DetailsPartnerTabs/Description'
import Specialites from './DetailsPartnerTabs/Specialites'
import Expertises from './DetailsPartnerTabs/Expertises'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'

const PartnerDetailAdmin = (props) => {
  const [active, setActive] = useState<number>(0)
  const tabs = ['Description', 'Spécialités', 'Expertises']
  const [partner, setPartner] = useState<Partenaire>()
  const itemState = useLocationState<any>(undefined)
  const { user } = useAppSelector((s) => s?.user)
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))
  const navigate = useNavigate()

  React.useEffect(() => {
    if (!user?.partners && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])
  useEffect(() => {
    if (itemState) {
      setPartner(itemState?.partner)
    }
  }, [itemState])

  return (
		<div className="shop-list-component">
			<div className="fixed-after-header">
				<section className="mb-5">
					<div className="position-relative flex-c-m">
						<img
							src={getImage(partner?.background_image)}
							alt="bg"
							className="img-bg"
						/>
						<div className="position-absolute">
							<h1 className="partner-name m-0">{partner?.name}</h1>
							<p className="partner-type">{partner?.entreprise_type}</p>
							<p className="partner-type my-1">{partner?.location}</p>
							<div className="flex-c-m gap-2">
								<p className="partner-detail-note">3,5</p>
								<StarRatingComponent
									name="rate2"
									editing={true}
									starCount={5}
									value={3}
									starColor="#F6973F"
									emptyStarColor="rgba(180, 180, 180, 0.8);"
								/>
							</div>
						</div>
					</div>
					<div className="listings-bar mb-4">
						<ul className="flex-c-m gap-4">
							{tabs.map((tab, index) => (
								<li
									role="button"
									key={index}
									className={`partner-tab ${
										active === index && "active"
									}`}
									onClick={() => setActive(index)}
								>
									<span>{tab}</span>
								</li>
							))}
						</ul>
					</div>
					<div className="ps-xl-3 ps-1 ps-sm-2 bloc-partner">
						{active === 0 && <Description partner={partner} />}
						{active === 1 && <Specialites partner={partner} />}
						{active === 2 && <Expertises partner={partner} />}
					</div>
				</section>
			</div>
		</div>
  );
}

export default PartnerDetailAdmin
