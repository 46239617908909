import { GrPaypal } from "react-icons/gr";
import { TBoostData } from "../../../../../utils/api/commerce/commerce.type";
import { ServicePaymentCode } from "../../../../../utils/type";
import fmoney from "../../../../assets/appImages/fmoney.png";
import omoney from "../../../../assets/appImages/omoney.png";
import wmoney from "../../../../assets/appImages/wmoney.png";
import cartegab from "../../../../assets/appImages/cartegab.png";
import { useState } from "react";
import { useLazyBoostAnnonceQuery } from "../../../../../utils/api/annonce/annonce.api";
import { FormError } from "../../../../common/CustomInputAdmin";
import { appEnv } from "../../../../../utils/constantes";
import { ApiBaseUrl, APP_URL, CALLBACK_URL, INTECH_API_KEY } from "../../../../../utils/http";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { CheckPaiementStatus } from "./SouscriptionProviderModal";
import { useCheckTransactionStatus } from "../../../../../utils/intech";

const BoostModal = ({
	item,
	onSubmit,
}: {
	item?: TBoostData;
	onSubmit?: (e: React.FormEvent<HTMLFormElement>) => void;
}) => {
	const [errModePaiement, setErrModePaiement] = useState<boolean>(false);
	const [paymentMode, setPaymentMode] = useState<ServicePaymentCode>();
	const [isCheckPayment, setIsCheckPayment] = useState<boolean>(false);
	const [phone, setPhone] = useState<string>();
	const [isErrPhone, setIsErrPhone] = useState<boolean>(false);
	const [isLoadingPay, setIsLoading] = useState<boolean>(false);
	const [externalTransactionId, setExternalTransactionId] = useState<number | string>();
	const [isSendRequest, setIsSendRequest] = useState<boolean>(false);

	const handleChangePayementMode = (value) => {
		setPaymentMode(value);
		setErrModePaiement(false);
	};
	const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
		setPhone(e.currentTarget.value);
		if (e.currentTarget.value) {
			setIsErrPhone(false);
		} else {
			setIsErrPhone(true);
		}
	};
	const [boost, { isLoading }] = useLazyBoostAnnonceQuery();

	const baseRedirectUrl = `${APP_URL}/app/mon-espace-client/mes-annonces/booster/${item?.slug}`;
	const redirectUrl = `${baseRedirectUrl}/status=success`;

	const { onGetTransactionStatus, seconds, isFetching, isLoad } = useCheckTransactionStatus(
		redirectUrl,
		externalTransactionId
	);

	const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		if (paymentMode && !isCheckPayment) return setIsCheckPayment(true);

		if (!phone?.length) return setIsErrPhone(true);
		if (paymentMode && phone) {
			const data = { ...item, env: appEnv };
			const result = await boost(data);
			if ("data" in result) {
				console.log({ data });
				setIsLoading(true);
				try {
					console.log(result.data);
					let dataToSend = {
						phone: phone,
						amount: item?.amount,
						codeService: paymentMode,
						externalTransactionId: result?.data?.slug,
						callbackUrl: `${CALLBACK_URL}`,
						apiKey: INTECH_API_KEY,
						data: {
							env: "test",
						},
						sender: "Munngano",
						merchantName: "Munngano",
						operationDescription: "Paiement sur la plateforme de goana",
						successRedirectUrl: `${redirectUrl}`,
						errorRedirectUrl: `${baseRedirectUrl}/?status=error`,
						...(data ?? {}),
					};
					const requestOptions: RequestInit = {
						method: "POST",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
						},
						body: JSON.stringify(dataToSend),
					};
					const res = await fetch(
						"https://api.intech.sn/api-services/operation",
						requestOptions
					).then((res) => {
						if (res.ok) {
							setIsLoading(false);
							return res.json();
						}
						throw {
							data: {
								message: `Une erreur est survenue code: ${res.status}`,
							},
						};
					});

					if (res?.error) {
						setIsLoading(false);
						// console.log("error");
						let message = "";
						if (res.data) {
							for (let [k, v] of Object.entries(res.data)) {
								if (Array.isArray(v)) {
									message = `${message}\n${k}: ${v.join(", ")}`;
								}
							}
						}
						if (message) {
							Swal.fire({
								icon: "error",
								title: message?.includes("error inconnu")
									? "Ce service n'est pas actuellement disponible"
									: message,
								showConfirmButton: false,
								timer: 5000,
							});
						} else if (res.msg) {
							Swal.fire({
								icon: "error",
								title: res.msg,
								showConfirmButton: false,
								timer: 5000,
							});
						} else {
							Swal.fire({
								icon: "error",
								title: res?.msg
									? res?.msg
									: `Erreur de statut code ${res?.code} est survenue!`,
								showConfirmButton: false,
								timer: 5000,
							});
						}
						return false;
					}
					if (!res?.error) {
						console.log({ res });
						const { deepLinkUrl } = res.data;
						const msg =
							"L'opération s'est effectuée avec succès, Vous allez recevoir un message de confirmation pour finaliser le paiement";
						if (deepLinkUrl) {
							Swal.fire({
								icon: "success",
								iconColor: Color.success,
								title: msg,
								showConfirmButton: false,
								timer: 4000,
							}).then(() => {
								window.location.href = deepLinkUrl;
							});
						} else {
							Swal.fire({
								icon: "success",
								title: msg,
								showConfirmButton: true,
								timer: 10000,
								confirmButtonColor: Color.theme,
							}).then(() => {
								setExternalTransactionId(
									res?.data?.externalTransactionId
								);
								setIsSendRequest(true);
							});
						}

						return true;
					}
				} catch (error: any) {
					const err = error?.data;
					console.log("error", err);
					return false;
				}
			}
		}
	};
	return (
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content">
				<div className="modal-header">
					<h5 className="modal-title">Payer</h5>
					<button
						type="button"
						className="btn-close"
						data-bs-dismiss="modal"
						aria-label="Close"
					></button>
				</div>
				{isSendRequest ? (
					<>
						<h5 className="modal-title text-center">
							Vérifier le statut de paiement
						</h5>
						<CheckPaiementStatus
							isLoading={isLoadingPay}
							seconds={seconds}
							onGetTransactionStatus={onGetTransactionStatus}
						/>
					</>
				) : (
					<form className="px-2 py-4" onSubmit={handleSubmit}>
						<div className="row">
							<div className="col-md-12 mb-4">
								<div>
									{!isCheckPayment && (
										<p className="espace-client-form-label">
											Choisir une méthode de paiement
										</p>
									)}
									<div className="col-12 mb-4">
										{/* <div className="content-text-facture-modal">
								<p className="espace-client-form-label mb-0">
									Votre facture est de{" "}
									<span style={{ fontWeight: 700 }}>
										{" "}
										{item ? item?.amount : "6"} €
									</span>{" "}
									pour une durée de{" "}
									<span style={{ fontWeight: 700 }}>
										{" "}
										{item ? item?.duration : "6"} jours.
									</span>
								</p>
							</div> */}
										{isCheckPayment ? (
											<>
												<div className="col-12">
													<label
														htmlFor="phone"
														className="custom-form-label "
													>
														Téléphone
														<span className="text-danger">
															*
														</span>
													</label>
													<input
														type="tel"
														id="phone"
														className="form-control espace-client-form-control"
														onChange={
															handleChangePhone
														}
													/>
													<small>
														Entre le numéro
														de téléphone
														correspondant au
														service choisi
													</small>

													{isErrPhone && (
														<FormError
															error="Ce champ est
													obligatoire"
														/>
													)}
												</div>
											</>
										) : (
											<div className="mode-paiement mt-5">
												<div className="mt-4 px-5">
													<div className="carte-mode-paiement-container">
														<div className="custom-carte-paiement-check-item mb-2">
															<label
																className="form-check-label"
																htmlFor="freemoney"
															>
																<img
																	src={
																		fmoney
																	}
																	className="card-img-financer-campagne"
																	style={{
																		marginTop: 0,
																	}}
																	alt="img campagne"
																/>
															</label>
															<input
																className="form-check-input m-0"
																type="radio"
																id="freemoney"
																value={
																	ServicePaymentCode.FREE
																}
																name="moyen_paiment"
																data-testid="freemoney"
																onChange={() =>
																	handleChangePayementMode(
																		ServicePaymentCode.FREE
																	)
																}
															/>
														</div>
														<div className="custom-carte-paiement-check-item mb-2">
															<label
																className="form-check-label"
																htmlFor="wavemoney"
															>
																<img
																	src={
																		wmoney
																	}
																	className="card-img-financer-campagne"
																	style={{
																		marginTop: 0,
																	}}
																	alt="img campagne"
																/>
															</label>
															<input
																className="form-check-input m-0"
																type="radio"
																id="wavemoney"
																value={
																	ServicePaymentCode.WAVE
																}
																name="moyen_paiment"
																onChange={() =>
																	handleChangePayementMode(
																		ServicePaymentCode.WAVE
																	)
																}
															/>
														</div>
														<div className="custom-carte-paiement-check-item mb-2">
															<label
																className="form-check-label"
																htmlFor="orangemoney"
															>
																<img
																	src={
																		omoney
																	}
																	className="card-img-financer-campagne"
																	style={{
																		marginTop: 0,
																	}}
																	alt="img campagne"
																/>
															</label>
															<input
																className="form-check-input m-0"
																type="radio"
																id="orangemoney"
																name="moyen_paiment"
																value={
																	ServicePaymentCode.OM
																}
																onChange={() =>
																	handleChangePayementMode(
																		ServicePaymentCode.OM
																	)
																}
															/>
														</div>
														{/* <div className="custom-carte-paiement-check-item mb-2">
											<label className="form-check-label" htmlFor="cartegab">
												<img
													src={cartegab}
													className="card-img-financer-campagne"
													style={{ marginTop: 0, width: "45px" }}
													alt="img campagne"
												/>
											</label>
				
											<span className="custom-text-carte-bancaire">carte bancaire</span>
											<input
												className="form-check-input m-0"
												type="radio"
												id="cartegab"
												value={ServicePaymentCode.CARTE}
												{...register("moyen_paiment")}
												onChange={() => handleChangePayementMode(ServicePaymentCode.CARTE)}
											/>
										</div> */}
													</div>
													<div className="text-center">
														{errModePaiement && (
															<small className="text-danger">
																Veuillez
																choisir
																un
																mode
																de
																paiement
															</small>
														)}
													</div>
												</div>
											</div>
										)}
									</div>
									{/* <div className="container-input-label-payment">
									<div className="ck">
										<label>
											<input
												type="radio"
												value="1"
												name="meme"
											/>
											<span>
												<GrPaypal />
											</span>
											<p className="moyen-paiement">
												Paypal
											</p>
										</label>
									</div>
								</div> */}
								</div>
							</div>
						</div>
						<div className="row d-flex align-items-center justify-content-end">
							<div className="col-md-4 mb-4">
								{isLoading ? (
									<button
										disabled={true}
										className="btn btn-primary auth-submit-btn"
									>
										<span
											className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
											role="status"
										></span>
										<span>Chargement...</span>
									</button>
								) : (
									<button
										className="btn btn-primary auth-submit-btn "
										type="submit"
									>
										{isCheckPayment ? "Envoyer" : "Suivant"}
									</button>
								)}
							</div>
						</div>
					</form>
				)}
			</div>
		</div>
	);
};
export default BoostModal;
