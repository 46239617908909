import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'
import { Commerce } from '../../../../../utils/api/commerce/commerce.type'
import { cleannerError } from '../../../../../utils/utils'
import { useEditCommerceMutation } from '../../../../../utils/api/commerce/commerce.api'
import { Color } from '../../../../../utils/theme'

function UseCompleteCommerce() {
  const navigate = useNavigate()
  const validationSchema = yup.object().shape({
		step: yup.number().required(),
		name: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Le Nom du commerce"),
		}),
		email: yup.string().when("step", {
			is: 1,
			then: yup.string().email("Email Invalide").required().label("L'email"),
		}),
		phone: yup.string().when("step", {
			is: 1,
			then: yup.string().required().label("Le Téléphone"),
		}),
		condition: yup.boolean().when("step", {
			is: 1,
			then: yup
				.boolean()
				.oneOf([true])
				.required()
				.label("Les conditions générales d'utilisations"),
		}),
		whatsapp: yup.string().nullable().label("Le Whatshapp"),
		city: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("La ville"),
		}),
		region: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("La région"),
		}),
		// description: yup.string().label('La Description'),
		location: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("L'Adresse"),
		}),

		category: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("La catégorie"),
		}),

		profil: yup.string().when("step", {
			is: 2,
			then: yup.string().required().label("Le type de profil"),
		}),
		country: yup
			.string()
			.when("step", {
				is: 2,
				then: yup.string().required().label("Le pays").nullable(),
			})
			.nullable(),
		isMoral: yup.boolean(),
		ninea: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup
					.string()
					.matches(
						new RegExp(/^(?=.*\d)(?=.*[a-zA-Z]).{2,}$/),
						"Le NINEA doit être de format alpha-numérique."
					)
					.min(10, "Le ninea doit comporter minimum 10 caractères")
					.max(14, "Le ninea doit comporter maximum 14 caractères")
					.required("Le NINEA est requis"),
			})
			.nullable(),
		legal_form: yup
			.string()
			.when("isMoral", {
				is: true,
				then: yup.string().required().label("Le nom légale").nullable(),
			})
			.nullable(),
		postal_card: yup.string().nullable().label("Le code postal"),
		company_register_number: yup
			.string()
			.nullable()
			.label("Le numéro d’Enrégistrement de l'entreprise"),
		bank_name: yup.string().nullable().label("Le nom de la banque"),
		account_number: yup.string().nullable().label("Le numéro de compte"),
		iban: yup.string().nullable().label("L'IBAN"),
		account_owner: yup.string().nullable().label("Le propriétaire du compte"),
		swift: yup.string().nullable().label("Le SWIFT"),
  });

  const {
    register,
    handleSubmit,
    formState,
    clearErrors,
    setValue,
    setError,
  } = useForm<Commerce | any>({
    resolver: yupResolver(validationSchema),
  })
  const [dataForm, setDataForm] = useState<any>({
    slug: '',
    name: '',
    city: '',
    profession: '',
    description: '',
    latitude: '',
    longitude: '',
    location: '',
    email: '',
    phone: '',
    whatsapp: '',
    facebook: '',
    twitter: '',
    linkedin: '',
    site_web: '',
    condition: false,
    profil: null,
    ninea: '',
    account_name: '',
    legal_form: '',
    country: '',
    postal_card: '',
    number_of_products: null,
    company_register_number: '',
    bank_name: '',
    account_number: '',
    iban: '',
    account_owner: '',
    swift: '',
    author: null,
    category: null,
  })
  const [level, setLevel] = useState<any>(0)
  const [
    sendData,
    { isLoading, isSuccess, isError, error, data },
  ] = useEditCommerceMutation()
  useEffect(() => {
    if (formState?.errors) {
      console.log('err', formState?.errors)
      cleannerError(formState?.errors, clearErrors, 7000)
    }
  }, [formState])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      console.log('success', data)
      Swal.fire({
        icon: 'success',
        title: 'Informations complétées avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        navigate('/commercant/dashboard', { replace: false })
      })
    }
  }, [isSuccess])

  const onSubmit = async (formValues: Commerce) => {
    console.log(formValues)
    let data = formValues
    setDataForm(formValues)
    if (formValues?.step !== 4) {
      for (let key of Object.keys(data)) {
        const val = data[key]
        setValue(key, val)
      }
      return setLevel(data.step)
    } else {
      sendData(formValues)
    }
    // let slug = data.slug;
    // let fd = new FormData();
    // for (let key of Object.keys(data)) {
    // 	const val = data[key];

    // 	fd.append(key, val);
    // }

    // await sendData({ slug, fd });
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    formState,
    setValue,
    setError,
    isLoading,
    level,
    setLevel,
    dataForm,
  }
}

export default UseCompleteCommerce
