import { FiMenu } from "react-icons/fi";
import "./TopBar.css";
import Logo from "../../../../assets/appImages/logo.png";
import AdminMenu from "../../AdminRoutes/AdminRoutes";

const TopBar = () => {
	return (
		<div className="top-bar-admin">
			<div className="maajjal-card py-3">
				<div className="px-2 d-flex justify-content-between justify-content-md-end ">
					<button
						className="top-bar-menu d-md-none"
						type="button"
						data-bs-toggle="collapse"
						data-bs-target="#menu-mobile"
						aria-expanded="false"
						aria-controls="menu-mobile"
					>
						<FiMenu />
					</button>
					{/* <div className="auth-user-container gap-3">
						<Link to="/app/admin/messages" className="icon-notif-container">
							<IconChat />
							<div className="dot-indicatif"></div>
						</Link>
						<div className="dropdown-center">
							<button
								className="icon-notif-container"
								id="notificationDropdown"
								data-bs-toggle="dropdown"
								aria-expanded="false"
							>
								<IconNotif />
								<div className="dot-indicatif"></div>
							</button>
							<ul
								className="dropdown-menu mt-2"
								aria-labelledby="notificationDropdown"
							>
								<li>
									<Link
										className="dropdown-item custom-dropdown-item py-2"
										to="/"
									>
										Notification
									</Link>
								</li>
								<li>
									<Link
										className="dropdown-item custom-dropdown-item py-2"
										to="/"
									>
										Vous avez 2 nouveaux utilisateurs
									</Link>
								</li>
								<li>
									<Link
										className="dropdown-item custom-dropdown-item py-2"
										to="/"
									>
										Un nouveau produit a été ajouter
									</Link>
								</li>
							</ul>
						</div>
						<div className="auth-user-image-container">
							<img src={profile} alt="auth-user-avatar" className="auth-user-image" />
						</div>
						<h3 className="auth-user-name">Sara Sarr</h3>
					</div> */}
				</div>
			</div>
			<div className="collapse d-md-none" id="menu-mobile">
				<div className="menu-mobile pb-3">
					<div className="sidebar-admin-mobile">
						<div className="logo-container flex-c-m pt-4">
							<img src={Logo} alt="logo" className="app-logo" />
						</div>
						<AdminMenu />
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopBar;
