import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { CandidatureEditFormData, ICandidature, TCandidatureFormData } from "./canditure.type";
import { prepareHeaders } from "../user/user.api";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLocationState } from "../../utils";

export const CandidatureApi = createApi({
	tagTypes: ["candidature", "findCandidatureBySlug"],
	reducerPath: "candidature",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCandidatureByUser: builder.query<ICandidature[], string | undefined>({
			query: (slug) => ({
				url: `candidaturebyuser/${slug}`,
				method: "GET",
			}),
			providesTags: ["candidature"],
			transformResponse: ({ results }) => results,
		}),
		getCandidatureByProject: builder.query<ICandidature[], string | undefined>({
			query: (slug) => ({
				url: `candidatureprojectbyuser/${slug}`,
				method: "GET",
			}),
			providesTags: ["candidature"],
			transformResponse: ({ results }) => results,
		}),
		dropCandidature: builder.mutation<TCandidatureFormData, any>({
			query: (data) => ({
				url: "candidature/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["candidature"],
		}),
		deleteCandidature: builder.mutation<ICandidature, string>({
			query: (slug) => ({
				url: `candidature/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: ["candidature"],
		}),
		findCandidatureBySlug: builder.query<ICandidature, string | undefined>({
			query: (slug) => ({
				url: `candidature/${slug}/`,
			}),
			providesTags: ["findCandidatureBySlug"],
		}),
		editCandidature: builder.mutation<
			ICandidature,
			{ slug?: string; data: CandidatureEditFormData }
		>({
			query: ({ slug, data }) => ({
				url: `candidature/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["candidature", "findCandidatureBySlug"],
		}),
	}),
});

export function useCandidatureFromLocation(): [
	ICandidature,
	boolean,
	string,
	(slug: string) => any
] {
	const itemState = useLocationState<ICandidature>(undefined);
	const [item, setItem] = useState(itemState);
	const { slug } = useParams<{ slug: string }>();
	const [findBySlug, { data, isError, isLoading }] = useLazyFindCandidatureBySlugQuery();
	const navigate = useNavigate();
	useEffect(() => {
		if (slug) {
			findBySlug(slug as string);
		}
	}, [slug]);
	useEffect(() => {
		if (isError && !itemState) {
			navigate("/", { replace: false });
		}
	}, [isError]);
	useEffect(() => {
		if (data) {
			setItem(data);
		}
	}, [data]);

	return [item as ICandidature, isLoading, slug as string, findBySlug];
}

export const {
	useGetCandidatureByUserQuery,
	useGetCandidatureByProjectQuery,
	useDropCandidatureMutation,
	useDeleteCandidatureMutation,
	useLazyFindCandidatureBySlugQuery,
	useEditCandidatureMutation,
} = CandidatureApi;
