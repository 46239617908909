import { IconArrowFillDown, IconArrowFillRight } from "../shared/Icons";

type BtnPropsType = {
  label: string;
  type?: "submit" | "button" | "reset";
  onClick?: (e?: any) => void;
  isLoading?: boolean;
};
type BtnPropsTypeGoBack = {
  navigation: any;
};
type BtnCollapsePropsType = {
  hideRightIcon: boolean;
  id: string;
};
type BtnLoadingType = {
  isLoading: boolean;
};
export function BtnNext({ label, type, isLoading, onClick }: BtnPropsType) {
  const handleClick = (e) => {
    e.preventDefault();
  };
  return (
    <button className="m-btn m-btn-next" type={type || "button"}>
      {!isLoading ? (
        `${label}`
      ) : (
        <>
          <span
            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
            role="status"
          ></span>
          <span>Chargement...</span>
        </>
      )}
    </button>
  );
}
export function BtnBack({
  label,
  navigation,
}: BtnPropsType & BtnPropsTypeGoBack) {
  return (
    <button className="m-btn m-btn-back" onClick={() => navigation.previous()}>
      {label}
    </button>
  );
}
export function BtnSkip({ label }: BtnPropsType) {
  return <button className="m-btn m-btn-back">{label}</button>;
}

export function BtnCollapse({
  id,
  hideRightIcon,
  onClick,
  label,
}: BtnPropsType & BtnCollapsePropsType) {
  return (
    <button
      className="m-collapse-btn no-style-btn flex-c-m gap-1"
      data-bs-toggle="collapse"
      data-bs-target={`#${id}`}
      aria-expanded="false"
      aria-controls={id}
      onClick={onClick}
    >
      {hideRightIcon ? <IconArrowFillDown /> : <IconArrowFillRight />}
      <span>{label}</span>
    </button>
  );
}

export function BtnSubmit({ isLoading, label }: BtnPropsType & BtnLoadingType) {
  return (
    <button type="submit" className="btn btn-primary auth-submit-btn ">
      {!isLoading ? (
        `${label}`
      ) : (
        <>
          <span
            className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
            role="status"
          ></span>
          <span>Chargement...</span>
        </>
      )}
    </button>
  );
}
