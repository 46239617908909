import React from 'react'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect } from 'react'
import Swal from 'sweetalert2'
import { PaymentModeFormData } from '../../../../../utils/api/paymentMode/paymentMode.type'
import {
  useAddPaymentModeMutation,
  useEditPaymentModeMutation,
} from '../../../../../utils/api/paymentMode/paymentMode.api'
import { cleannerError, onHide } from '../../../../../utils/utils'
import { Color } from '../../../../../utils/theme'

function UseEditPaymentMode() {
  const validationSchema = yup.object().shape({
    title: yup.string().required().label('Le nom'),
    name: yup.string().required().label('Le type'),
    description: yup.string().nullable(),
  })
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    clearErrors,
    setError,
    reset,
  } = useForm<PaymentModeFormData | any>({
    resolver: yupResolver(validationSchema),
  })
  const [
    editData,
    { isLoading, isSuccess, isError, error, data },
  ] = useEditPaymentModeMutation()

  useEffect(() => {
    if (errors) {
      for (let key of Object.keys(errors)) {
        if (key) {
          window.scrollTo(10, 10)
        }
      }
      cleannerError(errors, clearErrors, 5000)
    }
  }, [errors])

  useEffect(() => {
    if (isError) {
      console.log('err', error)
      const err = error as any
      const { message = `Une erreur de statut ${err?.status} s'est produite` } =
        err.data || {}
      Swal.fire({
        icon: 'error',
        title: message,
        showConfirmButton: false,
        timer: 3000,
      })
    }
  }, [isError])

  useEffect(() => {
    if (isSuccess) {
      console.log('data', data)

      onHide(`EditPaymentMode${data?.slug}`)
      //   reset()
      Swal.fire({
        icon: 'success',
        title: 'Moyen de paiement modifié avec succès!',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        // reset()
      })
    }
  }, [isSuccess])
  const onSubmit = async (formValues) => {
    console.log(formValues)

    await editData(formValues)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    isLoading,
    setError,
    reset,
    isSuccess,
  }
}

export default UseEditPaymentMode
