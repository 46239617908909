import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { EditProjectFormData, IProject } from "../../../../../utils/api/project/project.type";
import { cleannerError } from "../../../../../utils/utils";
import { useAppSelector } from "../../../../../redux/hooks";
import { useEditProjectMutation } from "../../../../../utils/api/project/project.api";
import { Color } from "../../../../../utils/theme";
function useEditProjet(project?: IProject) {
	//  Calling the add Project user Mutation
	const [editProject, { isLoading, isSuccess, error, isError }] = useEditProjectMutation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Titre"),
		description: yup.string().required().label("description"),
		prerequisite: yup.string().required().label("Prérequis"),
		budget: yup
			.number()
			.required()
			.label("Budget")
			.typeError("Budget est un champ obligatoire"),
		requirements: yup.string().required().label("Exigences"),
		skills: yup.string().required().label("Compétences").max(500),
		images: yup.mixed().label("Images"),
		date_expiration: yup.mixed().required().label("Images"),
		type_contract: yup.string().required().label("Type contrat"),
		ville: yup.string().required().label("Ville"),
		region: yup.string().required().label("Region"),
		sector: yup.number().required().label("Secteur"),
	});

	const [selected, setSelected] = useState<string[]>([]);

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<EditProjectFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (project) {
			const tags = [...project?.tags];
			setSelected(tags);
			setValue("tags", tags);
		}
	}, [project]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Projet modifié avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => navigate("/app/mon-espace-client/mes-projets"));
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	const handleChangeTags = (value: string[]) => {
		console.log({ value });
		setSelected(value);
		setValue("tags", value);
	};

	const onSubmit = async (data: EditProjectFormData) => {
		const { slug, images, tags, ...rest } = data;
		console.log(slug, "\n", rest);
		const formData = new FormData();
		if (user?.id) {
			formData.append("user", JSON.stringify(user.id));
		}
		if (images) {
			formData.append("images", data.images);
		}
		if (tags) {
			formData.append("tags", JSON.stringify(tags));
		}
		for (let key of Object.keys(rest)) {
			const val = data[key];
			formData.append(key, val);
		}
		await editProject({ slug, formData: formData });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		selected,
		handleChangeTags,
	};
}

export default useEditProjet;
