import React from "react";
import { BtnSubmit } from "../../common/Button";
import ErrorMessage from "../../common/ErrorMessage";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { UseForgetPasswordForm } from "./useAuthForm/useForgetPasswordForm";

const ForgetPassword = () => {
	const { register, onSubmit, errors, isLoading } = UseForgetPasswordForm();
	return (
		<div className="auth-component">
			<FrontHeader />
			{/* <div className="fixed-after-header"> */}
			<div className="auth-container">
				<div className="auth-row row">
					<div className="col-md-4 auth-col auth-left-side-col no-view-l-mobile ">
						<div className="auth-left-side-container min-h-auth"></div>
					</div>
					<div className="col-md-8 auth-col auth-right-side-col">
						<div className="row">
							<div className="auth-form-container col-12 col-xl-8 offset-xl-2 p-t-120">
								<div className="mb-4">
									<h1 className="auth-form-title">
										Récupération de mot de passe
									</h1>
									<h4 className="auth-form-message">
										Veuillez entrez votre email, nous vous
										enverrons un code pour pouvoir modifier
										le mot de passe
									</h4>
								</div>
								<form onSubmit={onSubmit}>
									<div className="row auth-form-row">
										<div className="col-md-12 auth-input-col">
											<div className="form-group auth-form-group">
												<label htmlFor="email-or-username">
													Email
												</label>
												<input
													type={`email`}
													className="form-control auth-form-control"
													id="email-or-username"
													{...register("email")}
													data-testid="emailId"
												/>
												{errors.email?.message && (
													<ErrorMessage
														message={
															errors
																.email
																.message
														}
													/>
												)}
											</div>
										</div>

										<div className="col-md-6 offset-md-6 auth-submit-btn-container">
											<BtnSubmit
												isLoading={isLoading}
												label="Envoyer"
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		// </div>
	);
};

export default ForgetPassword;
