import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { ApiBaseUrl } from '../../http'
import { AppStorage } from '../../storage'
import { DashboardCommerceData } from "../../type";
import { QueryUrl, useLocationState } from '../../utils'
import { prepareHeaders } from '../user/user.api'
import {
	Commerce,
	CommerceFormData,
	CommerceQuery,
	IBoostCommerce,
	PaginationResults,
	RateCommerce,
	RateCommerceFormData,
	TBoostData,
	TBoostResponse,
	TypeQuery,
	UserTypeQuery,
} from "./commerce.type";

const tags = {
	comVst: "commercesByVisitor",
	comByUser: "commercesByUser",
	comByAdmin: "commercesByAdmin",
	comByAdminFilter: "commercesByAdminFilter",
	comVstBySlug: "commercesByVisitorBySlug",
	commerceVille: "commerceVille",
	boostedCommerce: "boostedCommerce",
	socialBoostedCommerce: "socialBoostedCommerce",
};

export const CommerceApi = createApi({
	reducerPath: "commerceAPi",
	tagTypes: Object.values(tags),
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getCommercesByVisitor: builder.query<Commerce[] | any, CommerceQuery>({
			// query: () => `/commercebyvisitor/`,
			query: (query) => QueryUrl("/commercebyfilter/", query),
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(b?.id) - parseInt(a?.id)),
			providesTags: [tags.comVst],
		}),
		getCommerceByUser: builder.query<Commerce[] | any, void>({
			query: () => `commercebyuser/${AppStorage.getItem<any>("user", {}).user?.slug}/`,
			transformResponse: ({ results }) => results,
			providesTags: [tags.comByUser],
		}),
		getCommerceByUserPaginate: builder.query<PaginationResults<Commerce> | any, TypeQuery>({
			query: (query) =>
				QueryUrl(
					`commercebyuser/${AppStorage.getItem<any>("user", {}).user?.slug}/`,
					query
				),
			// transformResponse: ({ results }) => results,
			providesTags: [tags.comByUser],
		}),
		getCommerceByAdminPaginate: builder.query<PaginationResults<Commerce>, UserTypeQuery>({
			query: (query) => QueryUrl(`commercebyfilteradmin/`, query),
			providesTags: [tags.comByAdminFilter],
		}),
		getCommerceByAdmin: builder.query<Commerce[] | any, void>({
			query: () => `/commerce/`,
			transformResponse: ({ results }) =>
				results?.sort((a, b) => parseInt(a?.id) - parseInt(b?.id)),
			providesTags: [tags.comByAdmin],
		}),
		getCommerceVille: builder.query<any, void>({
			query: () => `/commerce_ville/`,
			providesTags: [tags.commerceVille],
		}),
		commercesByVisitorBySlug: builder.query<Commerce, string>({
			query: (slug) => `commercebyvisitor/${slug}/`,
			providesTags: [tags.comVstBySlug],
		}),
		rateCommerce: builder.mutation<RateCommerce, RateCommerceFormData | any>({
			query: (data) => ({
				url: "/ratecommerce/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [
				tags.comVstBySlug,
				tags.comByUser,
				tags.comVst,
				tags.commerceVille,
			],
		}),
		addCommerce: builder.mutation<Commerce, CommerceFormData | any>({
			query: (data) => ({
				url: "/commerce/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [
				tags.comByUser,
				tags.comVst,
				tags.commerceVille,
				tags.comByAdminFilter,
			],
		}),
		editCommerce: builder.mutation<Commerce, CommerceFormData | any>({
			query: ({ slug, ...data }) => ({
				url: `/commerce/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: [
				tags.comByUser,
				tags.comByAdmin,
				tags.comVst,
				tags.commerceVille,
				tags.comByAdminFilter,
			],
		}),
		editCommerceFile: builder.mutation<Commerce, CommerceFormData | any>({
			query: ({ slug, data }) => ({
				url: `/commerce/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: [tags.comByUser, tags.comVst, tags.comByAdminFilter],
		}),
		validCommerce: builder.mutation<Commerce, string>({
			query: (slug) => ({
				url: `/valid_commerce/${slug}/`,
				mothod: "GET",
			}),
			invalidatesTags: [tags.comByUser, tags.comVst, tags.comByAdminFilter],
		}),
		deleteCommerce: builder.mutation<Commerce, string>({
			query: (slug) => ({
				url: `commerce/${slug}`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.comByUser, tags.comVst, tags.comByAdminFilter],
		}),
		getBoostedCommerce: builder.query<IBoostCommerce[], void>({
			query: () => ({
				url: "boostedcommerce/",
				mothod: "GET",
			}),
			transformResponse: ({ results }) => results,
			providesTags: [tags.boostedCommerce],
		}),
		createCommerce: builder.mutation<{ url: string }, CommerceFormData | any>({
			query: (data) => ({
				url: "/create-seller/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: [
				tags.comByUser,
				tags.comVst,
				tags.commerceVille,
				tags.comByAdminFilter,
			],
		}),
		getSocialBoostedCommerce: builder.query<PaginationResults<Commerce>, TypeQuery>({
			query: (query) => QueryUrl(`socialboostedcommerce`, query),
			providesTags: [tags.socialBoostedCommerce],
		}),
		getTopCommerce: builder.query<PaginationResults<Commerce>, TypeQuery>({
			query: (query) => QueryUrl(`boostedcommerce`, query),
			providesTags: [tags.boostedCommerce],
		}),
		// delete social boost commerce
		deleteSocialBoostCommerce: builder.mutation<Commerce, string>({
			query: (slug) => ({
				url: `removesocialboostcommerce/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: [tags.socialBoostedCommerce],
		}),
		// delete boost commerce
		deleteBoostCommerce: builder.mutation<Commerce, string>({
			query: (slug) => ({
				url: `removeboostcommerce/${slug}/`,
				method: "GET",
			}),
			invalidatesTags: [tags.boostedCommerce],
		}),
		boostCommerce: builder.query<TBoostResponse, TBoostData>({
			query: (data) => ({
				url: QueryUrl(`${ApiBaseUrl}api/${data?.endpoint}/${data?.slug}`, {
					amount: data?.amount,
					env: data?.env,
					duration: data?.duration,
					promo: data?.type,
				}),
				method: "GET",
			}),
		}),
		getDashboardCommerceDataByUser: builder.query<DashboardCommerceData, void>({
			query: () =>
				`dashboardbycommercant/${AppStorage.getItem<any>("user", {}).user?.slug}/`,
			providesTags: [tags.comByUser],
		}),
	}),
});
export const {
	useGetCommercesByVisitorQuery,
	useGetCommerceByUserQuery,
	useGetCommerceByAdminQuery,
	useAddCommerceMutation,
	useEditCommerceMutation,
	useValidCommerceMutation,
	useDeleteCommerceMutation,
	useLazyGetCommerceByUserQuery,
	useLazyCommercesByVisitorBySlugQuery,
	useEditCommerceFileMutation,
	useGetCommerceVilleQuery,
	useRateCommerceMutation,
	useGetCommerceByUserPaginateQuery,
	useGetCommerceByAdminPaginateQuery,
	useGetBoostedCommerceQuery,
	useCreateCommerceMutation,
	useGetSocialBoostedCommerceQuery,
	useGetTopCommerceQuery,
	useDeleteBoostCommerceMutation,
	useDeleteSocialBoostCommerceMutation,
	useLazyBoostCommerceQuery,
	useGetDashboardCommerceDataByUserQuery,
} = CommerceApi;

export function useCommerceFromLocation(): [
  Commerce,
  boolean,
  string,
  (slug: string) => any,
] {
  const itemState = useLocationState<Commerce | undefined>(undefined)
  const [item, setItem] = useState(itemState)
  const { slug } = useParams<{ slug: string }>()
  const [
    findBySlug,
    { data, isError, isLoading },
  ] = useLazyCommercesByVisitorBySlugQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (slug) {
      findBySlug(slug as string)
    }
  }, [slug])
  useEffect(() => {
    if (isError && !itemState) {
      navigate('/', { replace: false })
    }
  }, [isError])
  useEffect(() => {
    if (data) {
      setItem(data)
    }
  }, [data])

  return [item as Commerce, isLoading, slug as string, findBySlug]
}
