import { useState } from "react";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { useGetProjectQuery } from "../../../../utils/api/project/project.api";
import { AiTwotoneHeart } from "react-icons/ai";
import { MdOutlineMoreHoriz } from "react-icons/md";
import StarRatingComponent from "react-star-rating-component";
import { calculteRating, createMarkup, formattedDate } from "../../../../utils/utils";
import { IProject } from "../../../../utils/api/project/project.type";
import { DeleteProject } from "../../user/MyCustumerArea/MesProjetsTabs";
import { CustomPagination } from "../SettingAdmin/DeliveryMode";
import DetailProjetModal from "../../modals/adminModal/DetailProjetModal";

const ProjetsList = () => {
	const [perPage, setPerPage] = useState(12);
	const [page, setPage] = useState(1);
	const [item, setItem] = useState<IProject>();
	const { data, isLoading } = useGetProjectQuery({
		page,
		limit: perPage,
	});
	return (
		<div className="bloc-entete-admin-container">
			<div className="bloc-entete-admin py-4">
				<div className="title-admin-container d-flex align-items-center gap-1">
					<h1 className="admin-page-title">Appel d’offre</h1>
					<h3 className="total-items mb-1">
						| {!isLoading && data?.count} résultats
					</h3>
				</div>
				<div className="bloc-right-container gap-3">
					<div className="container-input-search-admin">
						<div className="search-box-admin">
							<input
								type="search"
								className="search-input-admin"
								placeholder="Search..."
							/>
							<button className="search-button-admin">
								<i className="fas fa-search"></i>
							</button>
						</div>
					</div>
				</div>
			</div>
			<div className="row">
				{!isLoading &&
					data?.results?.map((item) => (
						<ProjectItem project={item} setItem={setItem} />
					))}
				{isLoading &&
					[...Array(6)]?.map((elem, i) => <ProjectItemSkeleton key={i} />)}
				{!isLoading && data?.results && (
					<CustomPagination
						nbPages={data?.count}
						page={page}
						perPage={perPage}
						onChange={(page, perPage) => {
							setPerPage(perPage);
							setPage(page);
						}}
					/>
				)}
			</div>
			<DetailProjetModal item={item} />
		</div>
	);
};

const ProjectItem = ({
	project,
	setItem,
}: {
	project: IProject;
	setItem: (val: IProject) => void;
}) => {
	return (
		<div className="col-lg-6 col-xl-4 pb-3">
			<div className="col-item-annonce-tabs">
				<div className="card-annonce-tabs">
					<div>
						<div className="flex-sb">
							<div>
								<h3 className="titre-card-projet">
									{project?.title}
								</h3>
							</div>
							<div className="dropdown">
								<button
									className="no-style-btn"
									type="button"
									id="dropdownMenuButton"
									data-bs-toggle="dropdown"
									aria-expanded="false"
								>
									<MdOutlineMoreHoriz />
								</button>
								<ul
									className="dropdown-menu"
									aria-labelledby="dropdownMenuButton"
								>
									<li>
										<button
											className="dropdown-item dropdown-item-hover"
											data-bs-toggle="modal"
											data-bs-target="#DetailProjetModal"
											onClick={() => setItem(project)}
										>
											Voir
										</button>
									</li>
									<li>
										<DeleteProject slug={project?.slug} />
									</li>
								</ul>
							</div>
						</div>

						<div className="pt-3">
							<div className="d-flex pb-2 gap-1">
								<p className="annonce-publie-libelle-tabs">
									Budget:
								</p>
								<p className="annonce-publie-value-tabs ps-0 pe-5 text-right">
									{" "}
									{project?.budget[0]?.name}
								</p>
							</div>
							<div className="d-flex pb-2">
								<p className="annonce-publie-libelle-tabs">
									Type de contrat:{" "}
								</p>
								<p
									className={`annonce-publie-value-tabs ${
										project?.type_contract === "cdi" ||
										project?.type_contract === "cdd"
											? "text-uppercase"
											: "text-capitalize"
									}`}
								>
									{project?.type_contract}
								</p>
							</div>
							<div className="d-flex pb-2">
								<p className="annonce-publie-libelle-tabs">
									Date d'expiration:{" "}
								</p>
								<p className="annonce-publie-value-tabs">
									{formattedDate(project?.date_expiration)}
								</p>
							</div>
							<div className="flex-sb">
								<p className="annonce-publie-libelle-tabs">
									Compétences requises:
								</p>
								<div
									className="text-card-projet-tabs"
									dangerouslySetInnerHTML={createMarkup(
										project?.skills.slice(0, 30)
									)}
								/>
							</div>
						</div>
						<div className="flex-sb pt-3 align-items-center">
							<div>
								<StarRatingComponent
									name="rate2"
									editing={false}
									starCount={5}
									value={calculteRating(project?.rates)}
									starColor="#FFC30B"
									emptyStarColor="#f5f5f5"
									renderStarIcon={() => {
										return (
											<span>
												<i className="fas fa-star" />
											</span>
										);
									}}
									renderStarIconHalf={() => {
										return (
											<span>
												<span
													style={{
														position: "absolute",
													}}
												>
													<i className="far fa-star" />
												</span>
												<span>
													<i className="fas fa-star-half" />
												</span>
											</span>
										);
									}}
								/>
							</div>
							<div>
								<AiTwotoneHeart className="icon-like-annonce" />
								<span className="annonce-like ps-1">
									{project?.favorites?.length}
								</span>
							</div>
							<div>
								<p className="date-annonce">
									{formattedDate(project?.created_at)}
								</p>
							</div>
						</div>
					</div>
					<div className="flex-r-m gap-2 author-container">
						<span>par</span>
						<strong>{`${project?.user?.firstname} ${project?.user?.lastname}`}</strong>
					</div>
				</div>
			</div>
		</div>
	);
};
function ProjectItemSkeleton() {
	return (
		<div className="col-lg-6 col-xl-4 	pb-3">
			<div className="col-item-annonce-tabs">
				<div className="card-annonce-tabs">
					<div>
						<div className="flex-sb">
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={200}
									height={10}
								/>
							</div>
							<div className="dropdown">
								<VolkenoSkeleton
									variant="rect"
									width={20}
									height={10}
								/>
							</div>
						</div>
						{[...Array(4)].map((elem, index) => (
							<div className="d-flex" key={index}>
								<VolkenoSkeleton
									variant="rect"
									width={150}
									height={10}
									style={{ marginRight: 10 }}
								/>
								<VolkenoSkeleton
									variant="rect"
									width={150}
									height={10}
								/>
							</div>
						))}
						<div className="flex-sb">
							<VolkenoSkeleton variant="rect" width={30} height={10} />
							<VolkenoSkeleton variant="rect" width={30} height={10} />
						</div>
						<div className="flex-sb align-items-center">
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={100}
									height={10}
								/>
							</div>
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={30}
									height={10}
								/>
							</div>
							<div>
								<VolkenoSkeleton
									variant="rect"
									width={100}
									height={10}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default ProjetsList;
