import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useCallback, useEffect, useRef, useState } from "react";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";
import { IOffer, OfferFormData } from "../../../../../utils/api/abonnement/abonnement.type";
import {
	useAddOrUpdateOfferMutation,
	useDeleteOfferMutation,
} from "../../../../../utils/api/abonnement/abonnement.api";

function useCrudBoostOffer(clean: () => void, item?: IOffer) {
	const validationSchema = yup.object().shape({
		type_boost: yup.string().required().label("Type de boost"),
		prix: yup.string().required().label("Prix"),
		duree: yup.string().required().label("Durée").max(2),
		description: yup.string().label("Description"),
	});
	const [description, setDescription] = useState<any>("");
	const ref = useRef<any>(null);
	const handleChange = (value: any) => {
		setDescription(value);
		setValue("description", value);
	};
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<OfferFormData>({
		resolver: yupResolver(validationSchema),
	});

	//  Calling the Register Mutation
	const [addOrUpdate, { isLoading, isSuccess, error, isError }] = useAddOrUpdateOfferMutation();

	useEffect(() => {
		if (item) {
			setValue("type_boost", item?.type_boost);
			setValue("prix", item?.prix);
			setValue("duree", item?.duree);
			setValue("description", item?.description);
			setDescription(item?.description);
		}
	}, [item]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: item ? "Offre modifié avec succès!" : "Offre ajouté avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				if (ref) {
					ref?.current?.editor.setText("");
				}
				clean();
				onHide("addBoostOfferModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: `Une erreur a survenue lors de ${
							item ? "la modification" : "l'ajout"
					  }`,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);

	const onSubmit = (data: OfferFormData) => {
		console.log(data);
		addOrUpdate({ slug: item?.slug, data: data });
	};

	return {
		register,
		errors: errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		handleChange,
		description,
		reset,
		ref,
	};
}

export default useCrudBoostOffer;

export function useDeleteOffer(item: IOffer) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteOfferMutation();

	const onDelete = useCallback(() => {
		Swal.fire({
			title: "Êtes vous sûr de vouloir supprimer cet abonnement?",
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed) {
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Offre supprimé avec succès",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
