import Divider from "../../../shared/Divider";
import pdf from "../../../assets/icons/pdf.png";
import { useState } from "react";
import PreviewFileModal from "../../modals/user/PreviewFileModal";
import ChangeCandidatureStatusModal from "../../modals/user/ChangeCandidatureStatusModal";
import { createMarkup, useLocationState } from "../../../../utils/utils";
import { ICandidature } from "../../../../utils/api/candidature/canditure.type";
import { MdDelete } from "react-icons/md";
import { useDelete } from "../../admin/Boosted/UseBoosted";
import { useDeleteCandidatureMutation } from "../../../../utils/api/candidature/candidature.api";

const DetailCandidature = () => {
	const item = useLocationState<ICandidature>(undefined);
	const [file, setFile] = useState<string>("");
	const handleChangeFile = (file: string) => {
		setFile(file);
	};
	return (
		<div className="mes-candidatures-tabs-component detail-candidature">
			<div className="candidatures-list">
				<div className="flex-sb mb-4">
					<h3 className="detail-candidature-title">
						{item?.project?.title}-{item?.project?.type_contract}
					</h3>
					<div className="d-flex gap-2">
						<button
							className={`bg-status-candidature ${
								item?.status === "nouvelle"
									? "pending"
									: item?.status === "acceptee"
									? "bg-success"
									: "bg-danger"
							}`}
							data-bs-toggle="modal"
							data-bs-target="#ChangeCandidatureStatusModal"
						>
							{item?.status === "nouvelle"
								? "En attente"
								: item?.status === "acceptee"
								? "Acceptée"
								: "Réfusée"}
						</button>
						<DeleteCandidature item={item} />
					</div>
				</div>
				<div
					className="mb-5 pt-4 text-description-annonce-detail"
					dangerouslySetInnerHTML={createMarkup(item?.project?.description)}
				/>
				<div className="candidat-info-bloc mb-3">
					<h5>Secteur d'activité</h5>
					<h6>{item?.project.sector.name}</h6>
				</div>
				<div className="row">
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Compétences</h5>
						<div
							className="text-description-annonce-detail"
							dangerouslySetInnerHTML={createMarkup(
								item?.project?.skills
							)}
						/>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Région</h5>
						<h6>{item?.project?.region}</h6>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Prérequis</h5>
						<div
							className="text-description-annonce-detail"
							dangerouslySetInnerHTML={createMarkup(
								item?.project?.prerequisite
							)}
						/>
					</div>
					<div className="candidat-info-bloc col-md-6 mb-3">
						<h5>Exigences</h5>
						<div
							className="text-description-annonce-detail"
							dangerouslySetInnerHTML={createMarkup(
								item?.project?.requirements
							)}
						/>
					</div>
					<Divider />
					<p className="pieces-jointes my-2">Pièces jointes</p>
					<Divider />
					<div className="d-flex gap-3 mt-3">
						{item?.cv && (
							<button
								className="button-file p-1 gap-3"
								onClick={() => handleChangeFile(item.cv)}
								data-bs-toggle="modal"
								data-bs-target="#PreviewFileModal"
							>
								<img src={pdf} alt="icon-pdf" />
								<span>Curriculum vitae</span>
							</button>
						)}
						{item?.modification_letter && (
							<button
								className="button-file p-1 gap-3"
								onClick={() =>
									handleChangeFile(item.modification_letter)
								}
								data-bs-toggle="modal"
								data-bs-target="#PreviewFileModal"
							>
								<img src={pdf} alt="icon-pdf" />
								<span>Lettre de motivation</span>
							</button>
						)}
					</div>
				</div>
				<PreviewFileModal fileUrl={file} />
				<ChangeCandidatureStatusModal item={item} />
			</div>
		</div>
	);
};
function DeleteCandidature({ item }: { item: ICandidature }) {
	const [deleteItem, { isSuccess, isError, error }] = useDeleteCandidatureMutation();
	const onDelete = useDelete<ICandidature>({
		item,
		deleteItem,
		isSuccess,
		isError,
		error,
		question: "cette candidature?",
		successMessage: "Candidature supprimée",
		redirectUrl: "/app/mon-espace-client/mes-candidatures/",
	});
	return (
		<div>
			<button className="bg-transparent border-none" onClick={onDelete}>
				<MdDelete style={{ fontSize: 24, color: "red" }} />
			</button>
		</div>
	);
}

export default DetailCandidature;
