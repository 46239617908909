import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'
import {
  ConditionFormData,
  Conditions,
  ConditionsUpdateFormData,
} from './condition.type'
import { prepareHeaders } from '../user/user.api'
import { ApiBaseUrl } from '../../http'

const tags = {
	cnds: "conditionsList",
	cgu: "cgu",
	ml: "ml",
	pc: "pc",
};

export const ConditionApi = createApi({
	reducerPath: "conditionsApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	tagTypes: [tags.cnds],
	endpoints: (build) => ({
		getConditions: build.query<Conditions[], void>({
			query: () => `condition/`,
			transformResponse: ({ results }) => results,
			providesTags: [tags.cnds],
		}),
		addCondition: build.mutation<Conditions, ConditionFormData | any>({
			query: (data) => ({
				url: `condition/`,
				method: "POST",
				body: data,
			}),
			invalidatesTags: [tags.cnds],
		}),
		editCondition: build.mutation<Conditions, ConditionsUpdateFormData | any>({
			query: ({ slug, ...data }) => ({
				url: `condition/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: [tags.cnds],
		}),
		deleteCondition: build.mutation<void, Conditions>({
			query: (slug) => ({
				url: `condition/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.cnds],
		}),
		getConditionsGenerales: build.query<Conditions, void>({
			query: () => `condition_generale_utilisation/`,
			transformResponse: ({ results }) => results[0],
			providesTags: [tags.cnds, tags.cgu],
		}),
		getMentionsLegales: build.query<Conditions, void>({
			query: () => `mention_legale/`,
			transformResponse: ({ results }) => results[0],
			providesTags: [tags.cnds, tags.ml],
		}),
		getPolitiqueConfidentialte: build.query<Conditions, void>({
			query: () => `politique_confidentialite/`,
			transformResponse: ({ results }) => results[0],
			providesTags: [tags.cnds, tags.pc],
		}),
	}),
});

export const {
	useAddConditionMutation,
	useDeleteConditionMutation,
	useEditConditionMutation,
	useGetConditionsQuery,
	useGetConditionsGeneralesQuery,
	useGetMentionsLegalesQuery,
	useGetPolitiqueConfidentialteQuery,
} = ConditionApi;
