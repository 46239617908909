import React, { useEffect } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { IBudget } from "../../../../utils/api/budget/budget.type";
import { BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import useEditBudgetModal from "./useEditBudgetModal";

const EditBudgetModal = ({ budget }) => {
	const { register, onSubmit, errors, isLoading, setValue } = useEditBudgetModal();
	const { user } = useAppSelector((state) => state.user);
	useEffect(() => {
		if (user) {
			setValue("user", user?.id);
		}
	}, [user]);
	useEffect(() => {
		if (budget) {
			setValue("slug", budget?.slug);
		}
	}, [budget]);
	return (
		<div
			className="modal fade"
			id="editBudgetModal"
			aria-labelledby="ajoutBudgetModal"
			aria-hidden="true"
		>
			<div className="modal-dialog">
				<div className="modal-content">
					<div className="modal-header">
						<h5 className="modal-title">Modifier le Budget</h5>
						<button
							type="button"
							className="btn-close"
							data-bs-dismiss="modal"
							aria-label="Close"
						></button>
					</div>
					<div className="px-2 py-4">
						<form onSubmit={onSubmit}>
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="title-projet"
									className="espace-client-form-label"
								>
									Budget
								</label>
								<input
									type="text"
									className="form-control espace-client-form-control"
									placeholder="100000 à 200000 par exemple"
									id="title-projet"
									{...register("name")}
									data-testid="titleProjetId"
									defaultValue={budget?.name}
								/>
								{errors.name?.message && (
									<ErrorMessage message={errors.name.message} />
								)}
							</div>
							<div className="col-6 offset-6 pb-4 px-2">
								<BtnSubmit label="Modifier" isLoading={isLoading} />
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};
export default EditBudgetModal;
