import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { IRateProject, TRateProjectData, TUpdateRateProjectData } from "./rateProject.type";
import { prepareHeaders } from "../user/user.api";

export const RateProjectApi = createApi({
	reducerPath: "rateProjectApi",
	tagTypes: ["rateProject"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addRateProject: builder.mutation<TRateProjectData, any>({
			query: (data) => ({
				url: "rate_project/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["rateProject"],
		}),
		updateRateProject: builder.mutation<TUpdateRateProjectData, IRateProject | any>({
			query: ({ slug, ...data }) => ({
				url: `rate_project/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["rateProject"],
		}),
	}),
});

export const { useAddRateProjectMutation, useUpdateRateProjectMutation } = RateProjectApi;
