import React, { useState } from "react";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import "./HelpAdmin.css";
import "../SellerListAdmin/SellerListAdmin.css";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import AddQuestionModal from "../../modals/adminModal/AddQuestionModal";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAppSelector } from "../../../../redux/hooks";
import { isSuperAdmin } from "../../../../routes/router";
import { useGetFaqQuery } from "../../../../utils/api/faq/faq.api";
import Pagination from "../../../common/Pagination";
import moment from "moment";
import { createMarkup } from "../../../../utils/utils";
import { IFaq } from "../../../../utils/api/faq/faq.type";
import EditQuestionModal from "../../modals/adminModal/EditQuestionModal";
import { useDeleteFaq } from "../../modals/adminModal/forms/useHelpForm";
import VolkenoSkeleton from "volkeno-react-skeleton";

const HelpAdmin = () => {
  const [page, setPage] = React.useState(1);
  const [faq, setFaq] = React.useState<IFaq>();
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const title = searchParams.get("title");
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const { data, isLoading } = useGetFaqQuery({ title });
  React.useEffect(() => {
    if (!user?.help && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  const handleFilterByTitleFaq = (
    e: React.FormEvent<HTMLInputElement>
  ): void => {
    if (e.currentTarget.value) {
      setSearchParams({ title: e.currentTarget.value });
    } else {
      setSearchParams({});
    }
  };
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Aide</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                className="search-input-admin"
                placeholder="Search.."
                onChange={(e) => handleFilterByTitleFaq(e)}
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-theme p-2"
              data-bs-toggle="modal"
              data-bs-target="#AddQuestionModal"
            >
              <MdOutlineAddCircleOutline />
              <span className="ps-2">Ajouter une quesion</span>
            </button>
          </div>
        </div>
      </div>
      <div className="mx-2 tab-content-admin tab-content-admin-help-mobile py-4 px-5">
        {isLoading && [...Array(4)].map((_, i) => <FaqSkeletton key={i} />)}
        {!isLoading &&
          data?.results?.length &&
          data?.results?.map((item) => (
            <div key={item?.slug} className="mb-4">
              <div className="p-3 row admin-detail-mission-card align-items-center mb-3">
                <div className="col-md-6 pb-2">
                  <div
                    className="admin-detail-mission-card-description p-0"
                    style={{ textOverflow: "ellipsis" }}
                    role="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#helpItemCollapse${item?.slug}`}
                    aria-expanded="false"
                    aria-controls={`helpItemCollapse${item?.slug}`}
                  >
                    {item?.title}?
                  </div>
                  <p className="admin-detail-mission-card-frequence mb-0">
                    Le {moment(item?.created_at).format("DD-MM-YYYY")}
                  </p>
                </div>
                <div className="col-md-3 pb-2">
                  <p className="chiffre-question-aide-admin">{item?.nb_view}</p>
                  <p className="admin-detail-mission-card-frequence mb-0">
                    Consultations
                  </p>
                </div>
                <div className="col-md-3 pb-2">
                  <div className="table-actions-btn-container-commandes gap-2 d-flex justify-content-center">
                    <div>
                      <button
                        className="btn btn-action-user-admin"
                        data-bs-toggle="modal"
                        onClick={() => setFaq(item)}
                        data-bs-target="#editQuestionModal"
                      >
                        <RiEdit2Fill />
                      </button>
                    </div>
                    <DeleteFaqButton item={item} />
                  </div>
                </div>
              </div>
              <div className="collapse" id={`helpItemCollapse${item?.slug}`}>
                <div
                  className="card card-body help-response"
                  dangerouslySetInnerHTML={createMarkup(item?.content)}
                />
              </div>
            </div>
          ))}
      </div>
      <div className="px-2 mt-3">
        <Pagination page={page} onPageChange={setPage} total={data?.count} />
      </div>
      <AddQuestionModal />
      <EditQuestionModal item={faq} />
    </div>
  );
};

function DeleteFaqButton({ item }: { item: IFaq }) {
  const onDelete = useDeleteFaq(item);
  return (
    <div>
      <button className="btn btn-action-user-admin" onClick={onDelete}>
        <RiDeleteBin7Fill />
      </button>
    </div>
  );
}

function FaqSkeletton() {
  return (
    <div className="p-3 row mb-3 admin-detail-mission-card align-items-center">
      <div className="col-md-6">
        <div>
          <VolkenoSkeleton variant="rect" width="100%" height={12} />
        </div>
        <div className="">
          <VolkenoSkeleton variant="rect" width="100%" height={12} />
        </div>
      </div>
      <div className="col-md-3">
        <div>
          <VolkenoSkeleton variant="rect" width="10px" height={12} />
        </div>
        <div className="mt-2">
          <VolkenoSkeleton variant="rect" width="100%" height={12} />
        </div>
      </div>
      <div className="col-md-3">
        <div className="table-actions-btn-container-commandes gap-2 d-flex justify-content-center">
          <div>
            <VolkenoSkeleton variant="rect" width={10} height={10} />
          </div>
          <div>
            <VolkenoSkeleton variant="rect" width={10} height={10} />
          </div>
        </div>
      </div>
    </div>
  );
}
export default HelpAdmin;
