import { getAvatar } from "../../../../utils/utils";
import CloseModalBtn from "../../../shared/CloseModalBtn";

function DetailsUserModal({ user }) {
	console.log({ user });
	return (
		<div
			className="modal fade"
			id="DetailsUserModal"
			aria-labelledby="DetailsAdminModalLabel"
			aria-hidden="true"
		>
			<div className="modal-dialog easypm-modal-dialog modal-lg">
				<div className="modal-content easypm-modal-content">
					<div className="modal-header easypm-modal-header">
						<CloseModalBtn />
					</div>
					<div className="modal-body easypm-modal-body">
						<div className="justify-content-center  d-flex">
							<div className="form-group align-items-center bloc-admin-profil position-relative text-center">
								<img
									src={getAvatar(
										user?.avatar
											? user?.avatar
											: user?.created_by?.avatar
									)}
									alt="img admin"
									className="img-admin"
									width="150"
									height="150"
									style={{
										borderRadius: "100%",
									}}
								/>

								<h3 className="profil-nom">
									{user?.prenom + " " + user?.nom}
								</h3>
							</div>
						</div>
					</div>
					<div className="row px-2 pb-4">
						<h4 className="title-info-profil">Informations</h4>
						<div className="col-md-6">
							<div className="form-group crud-form-group group-text">
								<p className="texte-gray">Prénom et Nom</p>
								<p className="bloc-text-profil">
									{user?.prenom + " " + user?.nom}
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group crud-form-group group-text">
								<p className="texte-gray">Email</p>
								<p className="bloc-text-profil">{user?.email}</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group crud-form-group group-text">
								<p className="texte-gray">Adresse</p>
								<p className="bloc-text-profil">
									{user?.adresse
										? user?.adresse
										: user?.created_by?.adresse}
								</p>
							</div>
						</div>
						<div className="col-md-6">
							<div className="form-group crud-form-group group-text">
								<p className="texte-gray">Téléphone</p>
								<p className="bloc-text-profil">{user?.phone}</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default DetailsUserModal;
