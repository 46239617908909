import { useStepType } from '../../../../../utils/type'
import AddInfoCompany from './AddInfoCompany'
import AddInfoTrader from './AddInfoTrader'
import SummaryStep from './SummaryStep'
import { useStep } from 'react-hooks-helper'
import FrontHeader from '../../../../shared/FrontHeader/FrontHeader'
import FrontFooter from '../../../../shared/FrontFooter/FrontFooter'
import AddAccountBank from './AddAccountBank'
import StepperHorizontal from '../../../../common/StepperHorizontal'
import { Navigate, useLocation, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useAppSelector } from '../../../../../redux/hooks'
import useBecomeTraderForm from '../UseBecomeTrader/UseBecomeTraderForm'
import { BecomeTraderFormData } from '../../../../../utils/api/Trading/trading.type'
import UseCompleteCommerce from '../UseBecomeTrader/UseCompleteCommerce'
import { useGetCategoriesByVisitorQuery } from '../../../../../utils/api/category/category.api'

const steps = [
  {
    id: 'add-info-trader',
    Component: AddInfoTrader,
  },
  {
    id: 'add-info-company',
    Component: AddInfoCompany,
  },
  {
    id: 'add-account-bank',
    Component: AddAccountBank,
  },
  {
    id: 'summary',
    Component: SummaryStep,
  },
]

const StepsToBecomeTraderLayout = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { user } = useAppSelector((state) => state.user)
  const {
    register,
    onSubmit,
    formState,
    setValue,
    level,
    isLoading,
    setLevel,
    dataForm,
  } = UseCompleteCommerce()
  const { data: categories = [], isSuccess } = useGetCategoriesByVisitorQuery()
  const [adress, setAdress] = useState<string>()
  const [profil, setProfil] = useState<string>()
  const [options, setOptions] = useState<any>([])
  const [cityLabel, setCityLabel] = useState<string>('Choisir une ville')
  const [stateLabel, setStateLabel] = useState<string>('Choisir une région')
  const [countryLabel, setCountryLabel] = useState<string>('Choisir un pays')
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  })

  const [country, setCountry] = useState<any>('')
  const [state, setState] = useState<any>('')
  const [city, setCity] = useState<any>('')
  const { Component } = step
  const props = {
    navigation,
    register,
    setValue,
    formState,
    user: user,
    setAdress,
    adress,
    setProfil,
    profil,
    options,
    setOptions,
    cityLabel,
    setCityLabel,
    setLevel,
    dataForm,
    country,
    setCountry,
    state,
    setState,
    city,
    setCity,
    isLoading,
    countryLabel,
    stateLabel,
  }

  useEffect(() => {
    window.scrollTo(10, 10)
    let newIndex = index + 1
    console.log('level index', level, step)
    setValue('step', newIndex)
  }, [index])

  useEffect(() => {
    if (level) {
      console.log('level', level)
      if (level === 1) {
        navigation.go('add-info-company')
      }

      if (level === 2) {
        navigation.go('add-account-bank')
      }

      if (level === 3) {
        navigation.go('summary')
      }
    }
  }, [level, dataForm])

  useEffect(() => {
    if (isSuccess) {
      setOptions(
        categories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          }
        }),
      )
    }
  }, [categories, isSuccess])

  useEffect(() => {
    if (location?.state) {
      console.log('location', location?.state)
      let data: any = location?.state
      delete data.slides
      delete data.subscribers
      delete data.products
      delete data.notes
      delete data.logo
      delete data.background_image
      setAdress(data?.location)
      setProfil(data?.profil)
      setCityLabel(data?.city)
      setStateLabel(data?.region)
      setCountryLabel(data?.country)
      setValue('index', index)
      if (dataForm?.id) {
        for (let key of Object.keys(dataForm)) {
          const val = dataForm[key]
          if (key === 'author' || key === 'category') {
            setValue(key, parseInt(val))
          } else {
            setValue(key, val)
          }
        }
      } else {
        for (let key of Object.keys(data)) {
          const val = data[key]
          if (key === 'author' || key === 'category') {
            setValue(key, val?.id)
          } else {
            setValue(key, val)
          }
        }
      }
    } else {
      setTimeout(() => {
        navigate(-1)
      }, 100)
    }
  }, [location, user, dataForm])
  return (
    <>
      <FrontHeader />
      <div className="steps-to-become-trader mb-5">
        <div className="step-card px-2 py-4">
          <StepperHorizontal index={index} />
          <form className="stepper-trader-form-container" onSubmit={onSubmit}>
            <Component {...props} />
          </form>
        </div>
      </div>
      <FrontFooter />
    </>
  )
}

export default StepsToBecomeTraderLayout
