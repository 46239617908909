import React, { useState } from "react";
import paginationFactory, { PaginationProvider } from "react-bootstrap-table2-paginator";
import SellerListAdminTableSkeleton from "./SellerListAdminTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { MdDeleteForever, MdEmail } from "react-icons/md";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import {
	useArchiveSellerMutation,
	useGetSellerFilterQuery,
} from "../../../../../utils/api/seller/seller.api";
import { Color } from "../../../../../utils/theme";
import { Seller } from "../../../../../utils/api/seller/seller.type";
import Swal from "sweetalert2";
import { getAvatar } from "../../../../../utils/utils";
import EditSellerModal from "../../../modals/adminModal/EditSellerModal";
import Pagination from "../../../../common/Pagination";

function SellerListAdminTable({ word }) {
	const [pageActive, setPageActive] = React.useState(1);
	const [perPage, setPerPage] = React.useState(10);

	const {
		isSuccess,
		data: { results: vendeurs, count } = { results: [], count: 0 },
		isLoading,
	} = useGetSellerFilterQuery({
		word: word,
		page: pageActive,
		limit: perPage,
	});
	const [vendeur, setVendeur] = useState<Seller>();

	const [editSeller, { isError, error }] = useArchiveSellerMutation();

	const onArchive = async (item) => {
		let data = {
			is_active: item?.is_archive,
			is_archive: !item?.is_archive,
			slug: item?.slug,
		};
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir ${
				item?.is_archive === true ? "désarchiver" : "archiver"
			} ce vendeur?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			preConfirm: () => {
				return editSeller(data);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.value?.data) {
				Swal.fire({
					icon: "success",
					title: `Vendeur ${
						result?.value?.data?.is_archive ? "archivé" : "désarchivé"
					} avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};

	const selectRow = {
		mode: "checkbox",
		clickToSelect: true,
	};

	const getVendeur = (row: any) => {
		setVendeur(row);
	};

	const mailFormatter = (cell: any, row: any) => {
		return (
			<>
				<span style={{ fontSize: 18 }}>
					<MdEmail style={{ color: "#FFA73C" }} />{" "}
				</span>
				<span className="ps-1">{row?.email}</span>
			</>
		);
	};

	const nameFormatter = (cell: any, row: any) => {
		return (
			<div>
				<img
					src={getAvatar(row.avatar)}
					alt=""
					className="img-produit-table-admin"
				/>
				<span className="ps-2">
					{row?.firstname} {row?.lastname}
				</span>
			</div>
		);
	};

	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
					<button
						onClick={() => getVendeur(row)}
						className="btn btn-action-user-admin"
						data-bs-toggle="modal"
						data-bs-target="#EditSellerModal"
						title="Editer"
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<button
						className="btn btn-action-user-admin"
						title={row?.is_archive ? "Désarchiver" : "Archiver"}
						onClick={() => onArchive(row)}
					>
						{row?.is_archive ? <MdDeleteForever /> : <RiDeleteBin7Fill />}
					</button>
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom_user",
			text: " Noms",
			formatter: (cell: any, row: any) => nameFormatter(cell, row),
			style: { textAlign: "left" },
			headerStyle: () => {
				return { width: "200px" };
			},
		},
		{
			dataField: "phone",
			text: "Téléphone",
			headerStyle: () => {
				return { width: "150px" };
			},
		},
		{
			dataField: "mail",
			text: "Email",
			formatter: (cell: any, row: any) => mailFormatter(cell, row),
		},
		{
			dataField: "adresse",
			text: "Adresse",
			headerStyle: () => {
				return { width: "200px" };
			},
		},
		{
			dataField: "nombre_boutique",
			text: "Nbre boutiques",
			formatter: (cell, row) => "2",
			headerStyle: () => {
				return { width: "100px" };
			},
		},

		{
			dataField: "slug",
			text: "actions",
			formatter: (cell: any, row: any) => actionFormatter(cell, row),
			headerStyle: () => {
				return { width: "100px" };
			},
		},
	];

	const handleChangePage = ({ perPage, onPageChange }, newPage) => {
		setPageActive(newPage);
	};

	const handleSizePerPage = ({ page, onSizePerPageChange }, newSizePerPage) => {
		setPageActive(1);
		setPerPage(newSizePerPage);
	};

	const options = {
		custom: true,
		sizePerPage: perPage,
		totalSize: count,
	};

	return (
		<>
			{isLoading && <SellerListAdminTableSkeleton />}
			{!isLoading && (
				<PaginationProvider pagination={paginationFactory(options)}>
					{({ paginationProps, paginationTableProps }) => (
						<div>
							<h3 className="total-items">{count} résultats</h3>
							<BootstrapTable
								keyField="id"
								data={vendeurs || []}
								columns={columns}
								striped
								noDataIndication="Aucune donnée disponible"
								bordered={false}
								condensed={false}
								responsive
								wrapperClasses="table-responsive admin-table"
								rowStyle={{
									fontFamily: "Montserrat",
									fontStyle: "normal",
									fontWeight: "600",
									fontSize: "14px",
									lineHeight: "21px",
									color: "rgba(0, 0, 0, 0.7)",
								}}
								headerClasses="header-class"
								{...paginationTableProps}
							/>
							<CustomPagination
								nbPages={count}
								page={pageActive}
								onChange={(page, perPage) => {
									handleSizePerPage(paginationProps, perPage);
									handleChangePage(paginationProps, page);
								}}
								perPage={perPage}
							/>
						</div>
					)}
				</PaginationProvider>
			)}
			<div
				className="modal fade"
				id="EditSellerModal"
				aria-labelledby="EditSellerModalLabel"
				aria-hidden="true"
			>
				<EditSellerModal vendeur={vendeur} />
			</div>
		</>
	);
}

export default SellerListAdminTable;

type CustomPaginationProps = {
	nbPages: number | any;
	page: number;
	onChange: (page: number, perPage: number) => any;
	perPage: number;
};
export function CustomPagination({ nbPages, page, perPage, onChange }: CustomPaginationProps) {
	if (parseInt(nbPages) <= 0) return null;
	return (
		<div className="d-flex align-items-baseline justify-content-between">
			<div className="footer-form d-flex mt-3">
				<select
					className="form-control select-perpage"
					name="limit"
					id="limit"
					value={perPage}
					onChange={(e) => {
						onChange(1, parseInt(e.target.value, 10));
					}}
				>
					<option value="10">10</option>
					<option value="30">30</option>
					<option value="50">50</option>
					<option value="100">100</option>
				</select>
			</div>

			{parseInt(nbPages) > 0 ? (
				<Pagination
					page={page}
					total={nbPages}
					perPage={perPage}
					onPageChange={(page: number) => onChange(page, perPage)}
				/>
			) : null}
		</div>
	);
}
