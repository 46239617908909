import React, { useRef, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { cleannerError } from "../../../../../utils/utils";
import { Color } from "../../../../../utils/theme";
import { ITestimonial, TestimonialFormData } from "../../../../../utils/api/about/about.type";
import { useAddOrEditTestimonialMutation } from "../../../../../utils/api/about/about.api";

function useCrudTestimonial(closeModal: () => void, item?: ITestimonial) {
	const validationSchema = yup.object().shape({
		nom: yup.string().required().label("Nom"),
		fonction: yup.string().required().label("Fonction"),
		content: yup.string().required().label("Message"),
		position: yup
			.number()
			.required()
			.label("Position")
			.typeError("Position est un champ obligatoire"),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<TestimonialFormData>({
		resolver: yupResolver(validationSchema),
	});

	const [avatar, setAvatar] = useState<any>(null);
	const [message, setMessage] = useState<string>("");
	const messageRef = useRef<any>(null);

	const [addData, { isLoading, isSuccess, isError, error, data, reset }] =
		useAddOrEditTestimonialMutation();

	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		let fields: (keyof ITestimonial)[] = ["nom", "fonction", "content", "position"];
		if (item) {
			for (let field of fields) {
				register(field);
				setValue(field, item[field]);
			}
			setMessage(item?.content);
		} else {
			for (let field of fields) {
				register(field);
				setValue(field, "");
			}
		}
	}, [item]);

	useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("data", data);
			setAvatar(null);
			reset();
			Swal.fire({
				icon: "success",
				title: `Testimonial ${!item ? "ajouté" : "modifié"} avec succès!`,
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(async () => {
				reset();
				closeModal();
				setMessage("");
				if (messageRef) {
					messageRef?.current?.editor.setText("");
				}
			});
		}
	}, [isSuccess]);

	const handleChangeImage = (e) => {
		setAvatar(e.target.files[0]);
		setValue("avatar", e.target.files[0]);
	};
	const handleChangeMessage = (data) => {
		setMessage(data);
		setValue("content", data);
	};

	const handleReset = () => {
		setAvatar(null);
		setMessage("");
		reset();
		closeModal();
	};

	const onSubmit = async (formValues: TestimonialFormData) => {
		console.log(formValues);
		const fd = new FormData();

		for (let key of Object.keys(formValues)) {
			const val = formValues[key];
			fd.append(key, val);
		}
		await addData({ slug: item?.slug, data: fd });
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
		avatar,
		reset: handleReset,
		handleChangeImage,
		message,
		handleChangeMessage,
		messageRef,
	};
}

export default useCrudTestimonial;
