import React from 'react'
import AdminListTable from '../../Tables/Admin/AdminListTable/AdminListTable'

const AdminList = ({ word }) => {
  return (
    <div className="tab-content-admin" id="admin-list-component">
      <AdminListTable word={word} />
    </div>
  )
}

export default AdminList
