import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import * as yup from "yup";
import { useUpdateFaqMutation } from "../../../../../utils/api/faq/faq.api";
import { TFaqFormEdit } from "../../../../../utils/api/faq/faq.type";
import { Color } from "../../../../../utils/theme";
import { cleannerError, onHide } from "../../../../../utils/utils";

const useHelpEditForm = () => {
	const validationSchema = yup.object().shape({
		title: yup.string().required().label("Le titre"),
		content: yup.string().required().label("Le Contenu"),
	});

	const [editFaq, { isLoading, isError, isSuccess, error }] = useUpdateFaqMutation();

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		reset,
		clearErrors,
	} = useForm<TFaqFormEdit>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Question modifiée avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("editQuestionModal");
			});
		}
		const err = error as any;
		if (isError) {
			Swal.fire({
				icon: "error",
				title: err?.data?.message
					? err?.data?.message
					: "Une erreur a survenue lors de la modification",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	useEffect(() => {
		cleannerError(errors, clearErrors);
	}, [errors]);
	const onsubmit = (data: TFaqFormEdit) => {
		editFaq({ slug: data?.slug, data: data });
	};
	return {
		register,
		onsubmit: handleSubmit(onsubmit),
		setValue,
		errors,
		isLoading,
	};
};

export default useHelpEditForm;
