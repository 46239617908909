import FrontFooter from "../../../../shared/FrontFooter/FrontFooter";
import FrontHeader from "../../../../shared/FrontHeader/FrontHeader";
import '../MarketPlace.css';

interface PropsType {
	children: React.ReactNode;
}

const MarketPlaceLayout = ({ children }: PropsType) => {
	return (
		<div className="marketplace">
			<FrontHeader />
			<div className="container-munngano">
				<div className="mungano-card px-3  py-4">
					{children}
				</div>
			</div>
			<FrontFooter />
		</div>
	);
};

export default MarketPlaceLayout;
