import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import moment from "moment";
import { ChangeEvent, useCallback, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { IUser, IUserFormData } from "../../../../../redux/slice/User.slice";
import { useAddOrUpdateUserMutation } from "../../../../../utils/api/user/user.api";
import { Color } from "../../../../../utils/theme";
import { cleannerError, getAvatar } from "../../../../../utils/utils";

function useUserForm(onHide: () => any, user?: IUser) {
  // const passwords = !!!user
  //   ? {
  //       password: yup.string().required("Le mot de passe est requis").min(5),
  //       confirmPassword: yup
  //         .string()
  //         .oneOf(
  //           [yup.ref("password"), null],
  //           "veuillez entrer des mots de passe identiques."
  //         ),
  //     }
  //   : null;
  const validationSchema = yup.object().shape({
    lastname: yup.string().required("Le nom est requis"),
    firstname: yup.string().required("Le prenom est requis"),
    civilite: yup.string().required(),
    birthdate: user ? yup.string() : yup.string().required("Date invalide"),
    phone: yup.string().required("Le numéro de téléphone est requis"),
    email: yup.string().email("email invalide").required("l'email est requis"),
    // ...passwords,
  });
  const [preview, setPreviw] = useState(
    user?.avatar ? getAvatar(user.avatar) : null
  );
  const [bornDate, setBornDate] = useState<Date | undefined>(
    user?.birthdate ? moment(user.birthdate).toDate() : undefined
  );

  const {
    register,
    unregister,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    clearErrors,
  } = useForm<IUserFormData>({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      civilite: "homme",
      profil: "user",
    },
  });

  const handelImageChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files ? e.target.files[0] : null;
    if (file) {
      setPreviw(URL.createObjectURL(file));
      register("avatar");
      setValue("avatar", file);
    }
  }, []);
  const handleChangeDate = useCallback((date: Date) => {
    setBornDate(date);
    register("birthdate");
    const formatted = moment(date).format("YYYY-MM-DD");
    setValue("birthdate", formatted);
  }, []);
  //  Calling the Register Mutation
  const [addOrUpdateUser, { isLoading, isSuccess, error, isError }] =
    useAddOrUpdateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: "success",
        title: user
          ? "Utilisateur modifié avec succès!"
          : "Utilisateur ajouté avec succès!",
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 2000,
      }).then(() => {
        if (!user) {
          reset();
          setPreviw(null);
        }
        onHide();
      });
    }
    const err = error as any;
    if (isError) {
      if (err?.data?.email?.includes("user with this email already exists.")) {
        Swal.fire({
          icon: "error",
          title: "Cet email existe déjà.",
          showConfirmButton: false,
          timer: 2000,
        });
      } else {
        Swal.fire({
          icon: "error",
          title: err?.data?.message
            ? err?.data?.message
            : `Une erreur a survenue lors de ${
                user ? "la modification" : "l'ajout"
              }`,
          showConfirmButton: false,
          timer: 5000,
        });
      }
    }
  }, [isLoading]);
  useEffect(() => {
    cleannerError(errors, clearErrors);
  }, [errors]);

  const onSubmit = (data: IUserFormData) => {
    const fd = new FormData();
    for (let key of Object.keys(data)) {
      if ((bornDate && key === "birthdate") || key !== "birthdate") {
        fd.append(key, data[key]);
      }
    }
    addOrUpdateUser({ slug: user?.slug, data: fd });
  };

  useEffect(() => {
    if (user) {
      const fields: (keyof IUserFormData)[] = [
        "lastname",
        "firstname",
        "pseudonym",
        "civilite",
        "adresse",
        "postal_code",
        "phone",
        "whatsapp",
        "email",
      ];
      for (let field of fields) {
        register(field);
        setValue(field, user[field]);
      }
    }
  }, [user]);

  return {
    register,
    errors: errors,
    onSubmit: handleSubmit(onSubmit),
    setValue,
    isLoading,
    preview,
    bornDate,
    handleChangeDate,
    handelImageChange,
  };
}

export default useUserForm;

export function useDeleteUser(user: IUser) {
  const [editData] = useAddOrUpdateUserMutation();
  const onArchive = async () => {
    let data: IUserFormData = {
      is_active: user.is_archive,
      is_archive: !user.is_archive,
    };
    await Swal.fire({
      title: `Êtes-vous sûr de vouloir ${
        user?.is_archive === true ? "désarchiver" : "archiver"
      } cet utilisateur?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "OUI",
      cancelButtonText: "NON",
      showLoaderOnConfirm: true,
      iconColor: Color.themeColor,
      preConfirm: () => {
        return editData({ slug: user.slug, data: data });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result: any) => {
      console.log(result, "archiver");
      if (result?.value?.data) {
        Swal.fire({
          icon: "success",
          title: `Utilisateur ${
            result?.value?.data?.is_archive ? "archivé" : "désarchivé"
          } avec succès!`,
          iconColor: Color.themeColor,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    });
  };
  return onArchive;
}
