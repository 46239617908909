import React from "react";
import { FaExclamationTriangle } from "react-icons/fa";
import { FiEdit3 } from "react-icons/fi";
import { createMarkup } from "../../../../utils/utils";
import AddCguModal from "../../modals/adminModal/AddCguModal";
import EditContenuModal from "../../modals/adminModal/EditContenuModal";

const PolitiqueConfidentialiteAdmin = ({ isContent, contenu }) => {
  if (isContent) {
    return <IsPolitiqueConfidentialite contenu={contenu} />;
  }
  return <IsEmptyPolitiqueConfidentialite />;
};

export default PolitiqueConfidentialiteAdmin;

function IsEmptyPolitiqueConfidentialite(props) {
  return (
    <div className="tab-content-admin">
      <div
        className="alert alert-danger d-flex align-items-center"
        role="alert"
      >
        <FaExclamationTriangle className="bi flex-shrink-0 me-2" />
        <div>
          Cette page est vide pour le moment!
          <button
            className="alert-link btn"
            data-bs-toggle="modal"
            data-bs-target="#AddCguModal"
            style={{ textDecoration: "underline" }}
          >
            Cliquez ici pour ajouter un contenu
          </button>
        </div>
      </div>
      <div
        className="modal fade"
        id="AddCguModal"
        aria-labelledby="AddCguModalLabel"
        aria-hidden="true"
      >
        <AddCguModal type="pc" />
      </div>
    </div>
  );
}

function IsPolitiqueConfidentialite({ contenu }) {
  return (
    <div className="tab-content-admin">
      <div>
        <div className="flex-sb no-view-mobile">
          <div>
            <p className="titre-cgu-admin">Politiques de confidentialités</p>
          </div>
          <div>
            <button
              className="btn btn-theme p-2"
              data-bs-toggle="modal"
              data-bs-target={`#editContent${contenu?.slug}`}
            >
              <FiEdit3 />
              <span className="ps-2">Modifier PC</span>
            </button>
          </div>
        </div>
        <div className="no-view-desktop">
          <div className="flex-r pb-3">
            <button
              className="btn btn-theme p-2"
              data-bs-toggle="modal"
              data-bs-target={`#editContent${contenu?.slug}`}
            >
              <FiEdit3 />
              <span className="ps-2">Modifier PC</span>
            </button>
          </div>
          <div>
            <p className="titre-cgu-admin">Politiques de confidentialités</p>
          </div>
        </div>
        <div className="pt-3">
          <p
            className="text-cgu-admin pb-2"
            dangerouslySetInnerHTML={createMarkup(contenu?.text)}
          />
        </div>
      </div>
      <div
        className="modal fade"
        id={`editContent${contenu?.slug}`}
        aria-labelledby="editContentLabel"
        aria-hidden="true"
      >
        <EditContenuModal contenu={contenu} />
      </div>
    </div>
  );
}
