import React, { Suspense } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import LoaderSuspense from "../component/common/Loader";
import CommandesListAdmin from "../component/modules/admin/CommandesListAdmin/CommandesListAdmin";
import HelpAdmin from "../component/modules/admin/HelpAdmin/HelpAdmin";
import AdminLayout from "../component/modules/admin/Layouts/AdminLayout";
import EditPasswordAdmin from "../component/modules/admin/LoginAdmin/EditPasswordAdmin";
import ForgetPasswordAdmin from "../component/modules/admin/LoginAdmin/ForgetPasswordAdmin";
import LoginAdmin from "../component/modules/admin/LoginAdmin/LoginAdmin";
import AddPartenaireAdmin from "../component/modules/admin/PartenaireAdmin/AddPartenaireAdmin";
import EditPartenaireAdmin from "../component/modules/admin/PartenaireAdmin/EditPartenaireAdmin";
import PartenaireAdmin from "../component/modules/admin/PartenaireAdmin/PartenaireAdmin";
import Categories from "../component/modules/admin/Categories/Categories";
import AddProductAdmin from "../component/modules/admin/ProductListAdmin/AddProductAdmin";
import EditProductAdmin from "../component/modules/admin/ProductListAdmin/EditProductAdmin";
import ProductListAdmin from "../component/modules/admin/ProductListAdmin/ProductListAdmin";
import SellerListAdmin from "../component/modules/admin/SellerListAdmin/SellerListAdmin";
import SettingAdmin from "../component/modules/admin/SettingAdmin/SettingAdmin";
import ShopListAdmin from "../component/modules/admin/ShopListAdmin/ShopListAdmin";
import UserList from "../component/modules/admin/UserList/UserList";
import ForgetPassword from "../component/modules/auth/ForgetPassword";
import Login from "../component/modules/auth/Login";
import Register from "../component/modules/auth/Register";
import ResetPassword from "../component/modules/auth/ResetPassword";
import ConditionGenerale from "../component/modules/ConditionGenerale/ConditionGenerale";
import MentionLegale from "../component/modules/ConditionGenerale/MentionLegale";
import EditArticleSeller from "../component/modules/trader/EditArticle/EditArticleSeller";
import SettingTrader from "../component/modules/trader/SettingTrader/SettingTrader";
import AddBoutiqueTrader from "../component/modules/trader/TraderBoutiques/CrudBoutique/AddBoutiqueTrader";
import EditBoutiqueTrader from "../component/modules/trader/TraderBoutiques/CrudBoutique/EditBoutiqueTrader";
import TraderLayout from "../component/modules/trader/TraderLayout";
import CustomerZone from "../component/modules/user/MyCustumerArea/CustomerZone";
import Annonce from "../component/modules/user/ProjectListing/Annonce";
import { useAppSelector } from "../redux/hooks";
import { IUser } from "../redux/slice/User.slice";
import ReseauPro from "../component/modules/admin/ReseauPro/ReseauPro";
import DetailCommande from "../component/modules/trader/TraderCommandes/DetailCommande";
import DetailBoutique from "../component/modules/trader/TraderBoutiques/TraderDetailsBoutique";
import PartnerDetailAdmin from "../component/modules/admin/PartenaireAdmin/PartnerDetailAdmin";
import Secteurs from "../component/modules/admin/Secteurs/Secteurs";
import MessageAdmin from "../component/modules/admin/MessageAdmin/MessageAdmin";
import ConditionLayout from "../component/modules/ConditionGenerale/ConditionLayout";
import PolitiqueConfidentialite from "../component/modules/ConditionGenerale/PolitiqueConfidentialite";
import { Authorisations } from "../utils/constantes";
import NousContactez from "../component/shared/NousContactez/NousContactez";
import NotificationsAdmin from "../component/modules/admin/NotificationsAdmin/NotificationsAdmin";
import Boost from "../component/modules/trader/TraderBoutiques/Boost/Boost";
import BoostBoutique from "../component/modules/trader/TraderBoutiques/Boost/BoostBoutique";
import BoostProduct from "../component/modules/trader/TraderBoutiques/Boost/BoostProduct";
import Boosted from "../component/modules/admin/Boosted/Boosted";
import AnnoncesList from "../component/modules/admin/Annonces/AnnoncesList";
import ProjetsList from "../component/modules/admin/Projets/ProjetsList";
import About from "../component/shared/About/About";
import HowItWorks from "../component/shared/About/HowItWorks";
import ChatAdmin from "../component/modules/admin/ChatAdmin/ChatAdmin";
import DetailCandidature from "../component/modules/user/MyCustumerArea/DetailCandidature";
import Services from "../component/shared/About/Services";
import ServiceAdmin from "../component/modules/admin/ServiceAdmin/ServiceAdmin";
import UserInfo from "../component/modules/admin/UserList/UserInfo";
import AdminAbout from "../component/modules/admin/About/About";
import DetailCandidatureForProject from "../component/modules/user/MyCustumerArea/DetailCandidatureForProject";
import AnnuaireTab from "../component/modules/user/MyCustumerArea/AnnuaireTab";
import EditAnnuaire from "../component/modules/user/MyCustumerArea/EditAnnuaire";
import BecomeTrader from "../component/modules/trader/BecomeTrader/BecomeTrader";
import RemplirFormulaire from "../component/modules/trader/BecomeTrader/RemplirFormulaire";
import StepsToBecomeTraderLayout from "../component/modules/trader/BecomeTrader/Steps/StepsToBecomeTraderLayout";
import CommandesPanier from "../component/modules/user/CommandesPanier/CommandesPanier";
import MarketPlaceDetail from "../component/modules/user/MarketPlace/MarketPlaceDetail";
import PartnerDetail from "../component/modules/user/PartnerList/PartnerDetail";
import DeposerAnnonce from "../component/modules/user/ProjectListing/DeposerAnnonce";
import EditAnnonce from "../component/modules/user/ProjectListing/EditAnnonce";
import DetailProject from "../component/modules/user/ProjectListing/DetailProject";
import DetailAnnonce from "../component/modules/user/ProjectListing/DetailAnnonce";
import ShopDetail from "../component/modules/user/ShopList/ShopDetail";
import DetailProjectTabs from "../component/modules/user/MyCustumerArea/DetailProjectTabs";
import AjouterProjetEspaceClient from "../component/modules/user/MyCustumerArea/AjouterProjetEspaceClient";
import DetailAnnonceTabs from "../component/modules/user/MyCustumerArea/DetailAnnonceTabs";
import RejoindreReseau from "../component/modules/user/ReseauPro/RejoindreReseau";
import ModifierProjetEspaceClient from "../component/modules/user/MyCustumerArea/ModifierProjetEspaceClient";
import AjouterProduitEspaceClient from "../component/modules/user/MyCustumerArea/AjouterProduitEspaceClient";
import DetailAchat from "../component/modules/user/MyCustumerArea/DetailAchatTabs";
import ParametreTabs from "../component/modules/user/MyCustumerArea/ParametreTabs";
import DetailPro from "../component/modules/user/MyCustumerArea/DetailPro";
import AddArticleTrader from "../component/modules/trader/AddArticle/AddArticleTrader";
import TraderDetailsBoutique from "../component/modules/trader/TraderBoutiques/TraderDetailsBoutique";
import Help from "../component/modules/trader/Help/Help";
import IntegrerAnnuaire from "../component/modules/user/ProjectListing/IntegrerAnnuaire";
import PageNotAvailable from "../component/common/PageNotAvailable";
import LandingPage from "../component/modules/LandingPage/LandingPage";
import { currentEnv } from "../utils/http";
const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<LoaderSuspense />}>
      <Component {...props} />
    </Suspense>
  );
// UserRoutes
const Home = Loader(
  React.lazy(() => import("../component/modules/homePage/HomePage"))
);
const MarketPlace = Loader(
  React.lazy(() => import("../component/modules/user/MarketPlace/MarketPlace"))
);
const AnnoncesTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/AnnoncesTabs")
  )
);
const MesFavorisTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MesFavorisTabs")
  )
);
const MesMessagesTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MesMessagesTabs")
  )
);
const MesNotificationsTabs = Loader(
  React.lazy(
    () =>
      import("../component/modules/user/MyCustumerArea/MesNotificationsTabs")
  )
);
const MesProduitsTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MesProduitsTabs")
  )
);
const MesProjetsTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MesProjetsTabs")
  )
);
const MesActivitesTabs = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MesActivitesTabs")
  )
);
const MesCandidatures = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/Candidatures")
  )
);
const Reseau = Loader(
  React.lazy(() => import("../component/modules/user/MyCustumerArea/Reseau"))
);
const MyCustumerArea = Loader(
  React.lazy(
    () => import("../component/modules/user/MyCustumerArea/MyCustumerArea")
  )
);
const PartnerList = Loader(
  React.lazy(() => import("../component/modules/user/PartnerList/PartnerList"))
);
const ProjectListing = Loader(
  React.lazy(
    () => import("../component/modules/user/ProjectListing/ProjectListing")
  )
);
const AnnoncesVisitors = Loader(
  React.lazy(() => import("../component/modules/user/ProjectListing/Annonces"))
);
const ProjectsVisitors = Loader(
  React.lazy(() => import("../component/modules/user/ProjectListing/Projects"))
);
const ShopList = Loader(
  React.lazy(() => import("../component/modules/user/ShopList/ShopList"))
);

//Trader Routes
const TraderDashbord = Loader(
  Loader(
    React.lazy(
      () => import("../component/modules/trader/TraderDashbord/TraderDashbord")
    )
  )
);
const TraderArticle = Loader(
  React.lazy(
    () => import("../component/modules/trader/TraderArticles/TraderArticle")
  )
);
const TraderArticleDetail = Loader(
  React.lazy(
    () =>
      import("../component/modules/trader/TraderArticles/TraderArticleDetail")
  )
);
const TraderCommandes = Loader(
  React.lazy(
    () => import("../component/modules/trader/TraderCommandes/TraderCommandes")
  )
);
const TraderBoutiques = Loader(
  React.lazy(
    () => import("../component/modules/trader/TraderBoutiques/TraderBoutiques")
  )
);

// Admin Routes
const DashboardAdmin = Loader(
  React.lazy(
    () => import("../component/modules/admin/Dashboard/DashboardAdmin")
  )
);

interface PropsType {
  children?: any;
  userType?: string[];
  profil?: string[];
}
export const ProtectedRoutes = ({ children, userType, profil }: PropsType) => {
  let location = useLocation();
  const { token, user } = useAppSelector((state) => state.user);
  const isAccess =
    token &&
    userType?.includes(user?.user_type) &&
    profil?.includes(user?.profil);
  const auth = token && user?.id;

  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/app/se-connecter" state={{ from: location }} replace />
  );
};

export const ProtectedSellerRoutes = ({ children, userType }: PropsType) => {
  let location = useLocation();
  const { token, user } = useAppSelector((state) => state.user);
  const isAccess =
    token && (userType?.includes(user?.user_type) || user.is_seller === true);
  const auth = token && user?.id;

  return isAccess && isAccess !== undefined ? (
    children
  ) : auth && auth !== undefined ? (
    <Navigate to="/" replace />
  ) : (
    <Navigate to="/app/se-connecter" state={{ from: location }} replace />
  );
};

export const RedirectAuthRoute = ({ children }: { children: any }) => {
  const { token, user } = useAppSelector((state) => state.user);
  let path = "/app/admin/";
  if (user?.user_type === "admin") {
    for (let index = 0; index < Authorisations.length; index++) {
      const element = Authorisations[index];
      if (user[`${element?.value}`] === true) {
        path = element.path;
        break;
      }
    }
  }
  return !token ? (
    children
  ) : user?.profil !== "seller" &&
    user?.user_type !== "admin" &&
    user?.user_type !== "superadmin" ? (
    <Navigate to="/" replace />
  ) : user?.user_type !== "admin" && user?.user_type !== "superadmin" ? (
    <Navigate
      to={
        location?.pathname !== "/"
          ? location?.pathname
          : "/app/commercant/dashboard"
      }
      replace
    />
  ) : (
    <Navigate to={path || "/"} replace />
  );
};

const MessagesVendeur = () => {
  return (
    <div style={{ backgroundColor: "#FFF", padding: 15 }}>
      <MesMessagesTabs />
    </div>
  );
};

const DetailCommandeAdmin = () => {
  const { user } = useAppSelector((s) => s?.user);
  const navigate = useNavigate();
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  React.useEffect(() => {
    if (!user?.orders && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  return (
    <div className="pt-5">
      <DetailCommande />
    </div>
  );
};

const DetailBoutiqueAdmin = () => {
  const { user } = useAppSelector((s) => s?.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user?.chat && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  return (
    <div className="pt-5">
      <DetailBoutique />
    </div>
  );
};
const DetailProduitAdmin = () => {
  const { user } = useAppSelector((s) => s?.user);
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user));
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user?.products && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1);
      }, 50);
    }
  }, [user]);
  return (
    <div className="pt-5">
      <TraderArticleDetail />
    </div>
  );
};

const AccueilPage = () => {
  if (currentEnv === "app-prod") {
    return <Home />;
  }

  return <LandingPage />;
};

export const AppRoutes = [
  // {
  // 	path: "/",
  // 	element: <PageNotAvailable />,
  // },
  {
    path: "/",
    element: <AccueilPage />,
  },
//   {
//     path: "/app",
//     element: <Home />,
//   },
  {
    path: "app/se-connecter",
    element: (
      <RedirectAuthRoute>
        <Login />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "app/inscription",
    element: (
      <RedirectAuthRoute>
        <Register />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "app/contactez-nous",
    element: <NousContactez />,
  },
  {
    path: "app/a-propos",
    element: <About />,
  },
  {
    path: "app/nos-services",
    element: <Services />,
  },
  {
    path: "app/comment-ca-marche",
    element: <HowItWorks />,
  },
  {
    path: "app/recuperer-mot-de-passe",
    element: (
      <RedirectAuthRoute>
        <ForgetPassword />
      </RedirectAuthRoute>
    ),
  },
  {
    path: "app/modifier-mot-de-passe",
    element: <ResetPassword />,
  },
  {
    path: "app/admin/connexion",
    element: <LoginAdmin />,
  },
  {
    path: "app/admin/modifier-mot-de-passe",
    element: <EditPasswordAdmin />,
  },
  {
    path: "admin/mot-de-passe-oublie",
    element: <ForgetPasswordAdmin />,
  },
  {
    path: "app/devenir-commercant",
    element: (
      <ProtectedRoutes
        userType={["user"]}
        profil={["user", "provider", "seller"]}
      >
        <BecomeTrader />
      </ProtectedRoutes>
    ),
  },
  {
    path: "devenir-app/commercant/remplir-formulaire",
    element: (
      <ProtectedRoutes userType={["user"]} profil={["user", "provider"]}>
        <RemplirFormulaire />
      </ProtectedRoutes>
    ),
  },
  {
    path: "/app/devenir-app/commercant/inscription",
    element: (
      <ProtectedSellerRoutes userType={["user"]} profil={["user", "seller"]}>
        <StepsToBecomeTraderLayout />
      </ProtectedSellerRoutes>
    ),
  },
  {
    path: "app/commandes/:idTabs",
    element: <CommandesPanier />,
  },
  {
    path: "app/marketPlace",
    element: <MarketPlace />,
  },
  {
    path: "app/marketplace/:slug",
    element: <MarketPlaceDetail />,
  },
  {
    path: "app/annuaires",
    element: <PartnerList />,
  },
  {
    path: "app/annuaires/:slug",
    element: <PartnerDetail />,
  },
  {
    path: "app/annonce/",
    element: (
      <ProtectedRoutes
        profil={["user", "provider", "seller"]}
        userType={["user"]}
      >
        <Annonce />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "deposer-annonce",
        element: <DeposerAnnonce />,
      },
      {
        path: "modifier-annonce/:slug",
        element: <EditAnnonce />,
      },
    ],
  },
  {
    path: "app/categories/",
    element: <ProjectListing />,
    children: [
      {
        element: <Navigate to="/app/categories/annonces" replace />,
        index: true,
      },
      {
        path: "annonces",
        element: <AnnoncesVisitors />,
      },
      {
        path: "projets",
        element: <ProjectsVisitors />,
      },
    ],
  },
  {
    path: "app/categories/projets/:slug",
    element: <DetailProject />,
  },
  {
    path: "app/categories/annonces/:slug",
    element: <DetailAnnonce />,
  },
  {
    path: "app/rejoindre-reseau",
    element: (
      <ProtectedRoutes
        userType={["user"]}
        profil={["user", "seller", "provider"]}
      >
        <RejoindreReseau />
      </ProtectedRoutes>
    ),
  },
  {
    path: "app/annuaires/demande",
    element: <IntegrerAnnuaire />,
  },
  {
    path: "app/liste-des-commerces",
    element: <ShopList />,
  },
  {
    path: "app/detail-boutique/:slug",
    element: <ShopDetail />,
  },
  {
    element: <ConditionLayout />,
    children: [
      {
        path: "app/mention-legale",
        element: <MentionLegale />,
      },
      {
        path: "app/condition-generale",
        element: <ConditionGenerale />,
      },
      {
        path: "app/politique-de-confidentialite",
        element: <PolitiqueConfidentialite />,
      },
    ],
  },
  {
    path: "app/mon-espace-client/",
    element: (
      <ProtectedRoutes
        userType={["user"]}
        profil={["user", "provider", "seller"]}
      >
        <CustomerZone />
      </ProtectedRoutes>
    ),
    children: [
      {
        path: "tableau-de-bord",
        element: <MyCustumerArea />,
      },
      {
        path: "mes-annonces",
        element: <AnnoncesTabs />,
      },
      {
        path: "mes-annonces/:slug",
        element: <DetailAnnonceTabs />,
      },
      {
        path: "mes-annonces/booster/:slug",
        element: <Boost />,
      },
      {
        path: "mes-projets",
        element: <MesProjetsTabs />,
      },
      {
        path: "mes-projets/:slug",
        element: <DetailProjectTabs />,
      },
      {
        path: "mes-projets/ajouter",
        element: <AjouterProjetEspaceClient />,
      },
      {
        path: "mes-projets/modifier/:slug",
        element: <ModifierProjetEspaceClient />,
      },
      {
        path: "ajouter-produit",
        element: <AjouterProduitEspaceClient />,
      },
      {
        path: "mon-historique",
        element: <MesActivitesTabs />,
      },

      {
        path: "mes-achats",
        element: <MesProduitsTabs />,
      },
      {
        path: "mes-achats/:slug",
        element: <DetailAchat />,
      },
      {
        path: "notifications",
        element: <MesNotificationsTabs />,
      },
      {
        path: "messages",
        element: <MesMessagesTabs />,
      },
      {
        path: "parametres",
        element: <ParametreTabs />,
      },
      {
        path: "mes-favoris",
        element: <MesFavorisTabs />,
      },
      {
        path: "reseau",
        element: <Reseau />,
      },
      {
        path: "mes-annuaires",
        element: <AnnuaireTab />,
      },
      {
        path: "mes-annuaires/modifier/:slug",
        element: <EditAnnuaire />,
      },
      {
        path: "reseau/:slug",
        element: <DetailPro />,
      },
      {
        path: "mes-candidatures",
        element: <MesCandidatures />,
      },
      {
        path: "mes-candidatures/:slug",
        element: <DetailCandidature />,
      },
      {
        path: "mes-projets/:slug/candidatures",
        element: <DetailCandidatureForProject />,
      },
    ],
  },
  {
    path: "app/commercant",
    element: (
      <ProtectedSellerRoutes userType={["user"]} profil={["seller"]}>
        <TraderLayout />
      </ProtectedSellerRoutes>
    ),
    children: [
      {
        path: "dashboard",
        element: <TraderDashbord />,
      },
      {
        path: "articles",
        element: <TraderArticle />,
      },
      {
        path: "ajout-article",
        element: <AddArticleTrader />,
      },
      {
        path: "articles/:slug",
        element: <TraderArticleDetail />,
      },
      {
        path: "articles/booster/:slug",
        element: <BoostProduct />,
      },
      {
        path: "modifier-article/:slug",
        element: <EditArticleSeller />,
      },
      {
        path: "parametres",
        element: <SettingTrader />,
      },
      {
        path: "aide",
        element: <Help />,
      },
      {
        path: "commandes",
        element: <TraderCommandes />,
      },
      {
        path: "commandes/:slug",
        element: <DetailCommande />,
      },
      {
        path: "messages",
        element: <MessagesVendeur />,
      },
      {
        path: "boutiques",
        element: <TraderBoutiques />,
      },
      {
        path: "boutiques/:slug",
        element: <TraderDetailsBoutique />,
      },
      {
        path: "boutiques/booster/:slug",
        element: <BoostBoutique />,
      },
      {
        path: "booster",
        element: <Boost />,
      },
      {
        path: "ajout-boutique",
        element: <AddBoutiqueTrader />,
      },
      {
        path: "modifier-boutique/:slug",
        element: <EditBoutiqueTrader />,
      },
    ],
  },
  {
    path: "app/admin",
    element: (
      <ProtectedRoutes
        userType={["admin", "superadmin"]}
        profil={["user", "seller", "provider"]}
      >
        <AdminLayout />
      </ProtectedRoutes>
    ),
    children: [
      {
        element: <Navigate to="/app/admin/tableau-de-bord" replace />,
        index: true,
      },
      {
        path: "tableau-de-bord",
        element: <DashboardAdmin />,
      },
      {
        path: "utilisateurs",
        element: <UserList />,
      },
      {
        path: "utilisateurs/:slug",
        element: <UserInfo />,
      },
      {
        path: "boutiques",
        element: <ShopListAdmin />,
      },
      {
        path: "chat",
        element: <ChatAdmin />,
      },
      {
        path: "boutiques/:slug",
        element: <DetailBoutiqueAdmin />,
      },
      {
        path: "produits",
        element: <ProductListAdmin />,
      },
      {
        path: "produits/:slug",
        element: <DetailProduitAdmin />,
      },
      {
        path: "ajouter-produits",
        element: <AddProductAdmin />,
      },
      {
        path: "modifier-produits",
        element: <EditProductAdmin />,
      },
      {
        path: "commandes",
        element: <CommandesListAdmin />,
      },
      {
        path: "commandes/:slug",
        element: <DetailCommandeAdmin />,
      },
      {
        path: "vendeurs",
        element: <SellerListAdmin />,
      },
      {
        path: "categories",
        element: <Categories />,
      },
      {
        path: "parametres",
        element: <SettingAdmin />,
      },
      {
        path: "aide",
        element: <HelpAdmin />,
      },
      {
        path: "annuaires",
        element: <PartenaireAdmin />,
      },
      {
        path: "annuaires/:slug",
        element: <PartnerDetailAdmin />,
      },
      {
        path: "ajouter-partenaire",
        element: <AddPartenaireAdmin />,
      },
      {
        path: "modifier-partenaire/:slug",
        element: <EditPartenaireAdmin />,
      },
      {
        path: "professionnels",
        element: <ReseauPro />,
      },
      {
        path: "secteurs",
        element: <Secteurs />,
      },
      {
        path: "messages",
        element: <MessageAdmin />,
      },
      {
        path: "services",
        element: <ServiceAdmin />,
      },
      {
        path: "notifications",
        element: <NotificationsAdmin />,
      },
      {
        path: "boosts",
        element: <Boosted />,
      },
      {
        path: "annonces",
        element: <AnnoncesList />,
      },
      {
        path: "projets",
        element: <ProjetsList />,
      },
      {
        path: "a-propos",
        element: <AdminAbout />,
      },
    ],
  },
];

export default AppRoutes;

export function isSeller(user?: IUser) {
  return user && (user?.profil === "seller" || user?.is_seller === true);
}

export function isPrestataire(user?: IUser) {
  return user && (user.profil === "provider" || user?.is_provider === true);
}

export function isAdmin(user?: IUser) {
  return (
    user && (user.user_type === "admin" || user.user_type === "superadmin")
  );
}

export function isAdminUser(user?: IUser) {
  return user && user.user_type === "admin";
}

export function isSuperAdmin(user?: IUser) {
  return user && user.user_type === "superadmin";
}

export function isAuthorized(user?: IUser, authName?: string) {
  if (!user) return false;
  // if (authName === "profil") return true;
  return user[`${authName}`];
}
