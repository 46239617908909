import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { PaginationResults } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import { SlideEditFormData, SlideFormData, ISlide } from "./slide.type";

export const SlideApi = createApi({
	tagTypes: ["slide"],
	reducerPath: "slideApi",
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getSlides: builder.query<PaginationResults<ISlide>, void>({
			query: () => `slide/`,
			providesTags: ["slide"],
		}),
		getHomeSlides: builder.query<ISlide[], void>({
			query: () => `slides/`,
			providesTags: ["slide"],
		}),
		addSlide: builder.mutation<SlideFormData, any>({
			query: (data) => ({
				url: "slide/",
				body: data,
				method: "POST",
			}),
			invalidatesTags: ["slide"],
		}),
		deleteSlide: builder.mutation<ISlide, string>({
			query: (slug) => ({
				url: `slide/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: ["slide"],
		}),
		editSlide: builder.mutation<
			SlideEditFormData,
			{ slug?: ISlide["slug"]; data: SlideEditFormData | FormData }
		>({
			query: ({ slug, data }) => ({
				url: `slide/${slug}/`,
				body: data,
				method: "PUT",
			}),
			invalidatesTags: ["slide"],
		}),
	}),
});

export const {
	useGetSlidesQuery,
	useAddSlideMutation,
	useDeleteSlideMutation,
	useEditSlideMutation,
	useGetHomeSlidesQuery,
} = SlideApi;
