import React, { useMemo, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../../../../redux/hooks";
import { Product } from "../../../../../utils/api/product/product.type";
import { cleannerError } from "../../../../../utils/utils";
import { useAddProductMutation } from "../../../../../utils/api/product/product.api";
import { Color } from "../../../../../utils/theme";

function useAddProductForm() {
    const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
    const commerce = useAppSelector<any>((state) => state.commerce);
	const validationSchema = yup.object().shape({
		step: yup.number().required(),
		name: yup
			.string()
			.when("step", {
				is: 1,
				then: yup.string().required().label("Le nom du produit").nullable(),
			})
			.nullable(),
		category: yup
			.string()
			.when("step", {
				is: 1,
				then: yup.string().required().label("La catégorie du produit").nullable(),
			})
			.nullable(),
		subcategory: yup
			.string()
			.when("step", {
				is: 1,
				then: yup.string().required().label("La sous catégorie du produit").nullable(),
			})
			.nullable(),
		description: yup
			.string()
			.when("step", {
				is: 2,
				then: yup.string().required().label("La description du produit").nullable(),
			})
			.nullable(),
		price: yup
			.string()
			.when("step", {
				is: 3,
				then: yup.string().required().label("Le prix du produit").nullable(),
			})
			.nullable(),
		quantity: yup
			.string()
			.when("step", {
				is: 3,
				then: yup.string().required().label("La quantité du produit").nullable(),
			})
			.nullable(),
		background_image: yup
			.mixed()
			.when("step", {
				is: 3,
				then: yup.mixed().required().label("L'image de couverture").nullable(),
			})
			.nullable(),
			commerce: yup
			.string()
			.when("step", {
				is: 4,
				then: yup.string().required().label("La boutique est requis").nullable(),
			})
			.nullable(),
		images: yup
			.mixed()
			.when("step", {
				is: 5,
				then: yup.mixed().required("Veuillez télécharger maximum 4 images").nullable(),
			})
			.nullable(),
		modele: yup.string().label("Le modèle").nullable(),
		marque: yup.string().label("La marque").nullable(),
		weight: yup.string().label("Le poids").nullable(),
		dimensions: yup.string().label("La dimension").nullable(),
		main_color: yup.string().label("La couleur principale").nullable(),
	});
	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
        setError
	} = useForm<Product | any>({
		resolver: yupResolver(validationSchema),
	});
    const [dataForm, setDataForm] = useState<any>({
		name: "",
		description: "",
		price: "",
		quantity: null,
		commerce: null,
		category: null,
		images: [],
		modele: "",
		marque: "",
		weight: "",
		dimensions: "",
		subcategory: null,
		main_color: "",
	});
    const [sendData, {isLoading, isSuccess, isError, error, data}] = useAddProductMutation()
	const [level, setLevel] = useState<any>(0);
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

    useEffect(() => {
		if (isError) {
			console.log("err", error);
			const err = error as any;
			const { message = `Une erreur de statut ${err?.status} s'est produite` } =
				err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 3000,
			});
		}
	}, [isError]);

	useEffect(() => {
		if (isSuccess) {
			console.log("success", data);
			Swal.fire({
				icon: "success",
				title: "Produit ajouté avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => {
				navigate("/app/admin/produits", { replace: false });
			});
		}
	}, [isSuccess]);
	const onSubmit = async(formValues) => {
		console.log('formValues', formValues)

		let data = formValues;
		setDataForm(formValues);
		const fd = new FormData();
		if (formValues?.step !== 5) {
			for (let key of Object.keys(formValues)) {
				const val = data[key];
				setValue(key, val);
			}
			return setLevel(data.step);
		} else {
			for (let key of Object.keys(data)) {
				const val = data[key];
                if (key === "images") {
					val.forEach((item) => {
						fd.append(key, item);
					});
				} else {
					fd.append(key, val);
				}
			}

            await sendData(fd)
		}
		
		
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading,
        level,
        dataForm,
        setError
	};
}

export default useAddProductForm;

