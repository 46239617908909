import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { LoginFormData } from '../../../../utils/api/auth/auth.type'
import { useLoginUserMutation } from '../../../../utils/api/auth/auth.api'
import { useEffect, useState } from 'react'
import Swal from 'sweetalert2'
import { Color } from '../../../../utils/theme'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthState, onSetUserToken } from '../../../../redux/slice/User.slice'
import { useAppDispatch } from '../../../../redux/hooks'
import { cleannerError } from '../../../../utils/utils'
import { useLazyMeAdminQuery } from '../../../../utils/api/user/user.api'
import { AppStorage } from '../../../../utils/storage'
function useLoginForm() {
  //  Calling the login user Mutation
  const [
    loginUser,
    { isLoading, isSuccess, data, error, isError },
  ] = useLoginUserMutation()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location: any = useLocation()
  let from = location?.state?.from?.pathname || location?.state?.pathname || '/'
  const validationSchema = yup.object().shape({
    email: yup.string().email('email invalide').required("L'email est requis"),
    password: yup.string().required('Le mot de passe est requis'),
  })

  const [userInfos, setUserInfos] = useState<any>()

  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    clearErrors,
  } = useForm<LoginFormData>({
    resolver: yupResolver(validationSchema),
  })
  const [
    fetchMe,
    { data: userAdmin, isSuccess: success, isError: IsErr },
  ] = useLazyMeAdminQuery()

  useEffect(() => {
    if (userAdmin) {
      console.log('admin', userAdmin)
      dispatch(onSetUserToken({ user: userAdmin, token: userInfos.token }))
    }
  }, [userAdmin])

  useEffect(() => {
    if (IsErr) {
      if (userInfos) {
        dispatch(
          onSetUserToken({ user: userInfos.data, token: userInfos.token }),
        )
      }
    }
  }, [isLoading])

  useEffect(() => {
    if (data) {
      setUserInfos(data)
      AppStorage.setItem('user', {
        user: data.data,
        token: data.token,
      })
      if (data?.data?.user_type === 'admin') {
        fetchMe()
      } else {
        dispatch(onSetUserToken({ user: data.data, token: data.token }))
        // setTimeout(() => {
        navigate(from, { replace: true })
        // }, 100)
      }
    }
  }, [data])

  useEffect(() => {
    if (isSuccess) {
      Swal.fire({
        icon: 'success',
        title: 'Connexion réussie!',
        iconColor: Color.success,
        showConfirmButton: false,
        timer: 1200,
      })
    }
    if (isError) {
      const err = error as any
      const { message = "Une erreur inconnue s'est produite" } = err.data || {}
      setError('email', { message: message })
    }
  }, [isLoading])

  useEffect(() => {
    if (errors) {
      cleannerError(errors, clearErrors)
    }
  }, [errors])

  const onSubmit = (data) => {
    loginUser(data)
  }
  return {
    register,
    onSubmit: handleSubmit(onSubmit),
    errors,
    isLoading,
  }
}

export default useLoginForm
