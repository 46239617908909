import React from 'react'
import VolkenoSkeleton from 'volkeno-react-skeleton'

function ProductItemSkeleton() {
  return (
    <div className="top-product-market-place-item pos-relative">
      <VolkenoSkeleton
        className="img-top-product-marketplace"
        variant="rect"
        width={`100%`}
        height={`20vw`}
      />
      <div className="top-product-market-place-card-container">
        <div className="text-top-product-market-place-container p-0">
          <VolkenoSkeleton
            variant="text"
            className="top-product-name"
            width={`50%`}
            height={25}
          />
          <VolkenoSkeleton
            variant="text"
            className="top-product-marque"
            width={`70%`}
            height={30}
          />
          <div>
            <div className="d-flex justify-content-start align-items-baseline mx-2">
              <VolkenoSkeleton
                variant="rect"
                height={10}
                width={10}
                className="mx-1"
                borderRadius={0}
              />
              <VolkenoSkeleton
                variant="rect"
                height={10}
                width={10}
                className="mx-1"
                borderRadius={0}
              />
              <VolkenoSkeleton
                variant="rect"
                height={10}
                width={10}
                className="mx-1"
                borderRadius={0}
              />
              <VolkenoSkeleton
                variant="rect"
                height={10}
                width={10}
                className="mx-1"
                borderRadius={0}
              />
              <VolkenoSkeleton
                variant="rect"
                height={10}
                width={10}
                className="mx-1"
                borderRadius={0}
              />
            </div>
          </div>
          <div className="top-product-footer">
            <div className="d-flex">
              <VolkenoSkeleton
                variant="rect"
                className="top-product-price-unity mb-4"
                width={15}
                height={25}
              />

              <VolkenoSkeleton
                variant="text"
                className="top-product-price mt-2"
                width={100}
                height={25}
                style={{ marginLeft: '1rem' }}
              />
            </div>
            <div>
              <VolkenoSkeleton
                variant="circle"
                className="btn btn-add-product-panier trans-0-2"
                width={30}
                height={30}
                borderRadius={`100%`}
              />
              {/* <NavLink to="" className="btn btn-add-product-panier trans-0-2">
                <img src={ImgAdd} className="img-add" alt="Icon add" />
              </NavLink> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ProductItemSkeleton
