import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { civiliteUserType } from "../../utils/api/user/user.type";
import { AppStorage } from "../../utils/storage";

export interface IUser {
  id: number;
  firstname: string;
  lastname: string;
  slug: string | any;
  email: string;
  birthdate: Date;
  postal_code: string;
  phone: string;
  whatsapp: string;
  pseudonym: string;
  latitude?: string;
  longitude?: string;
  civilite?: civiliteUserType;
  adresse?: string;
  user_type?: "superadmin" | "admin" | "user" | "seller";
  profil?: "user" | "seller" | "provider";
  password?: string;
  condition: boolean;
  avatar: string;
  password_reset_count: number;
  first_connexion: boolean;
  is_provider: boolean;
  is_seller: boolean;
  created_at: string;
  is_active: boolean;
  is_archive: boolean;
}

export type IUserFormData = Partial<{
  id: number;
  firstname: string;
  lastname: string;
  slug: string | any;
  email: string;
  birthdate: string;
  postal_code: string;
  phone: string;
  whatsapp: string;
  pseudonym: string;
  civilite: civiliteUserType;
  adresse: string;
  profil: "user" | "seller" | "provider";
  condition: boolean;
  avatar: File;
  is_active: boolean;
  is_archive: boolean;
  password?: string;
  confirmPassword?: string;
}>;

export interface AuthState {
  user: IUser | any;
  token: string | null;
}
const initialState = AppStorage.getItem<AuthState>("user", {
  user: undefined,
  token: undefined,
});
export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    onSetUser: (state, action: PayloadAction<AuthState["user"]>) => {
      state.user = action.payload;
      AppStorage.setItem("user", state);
    },
    onSetToken: (state, action: PayloadAction<AuthState["token"]>) => {
      state.token = action.payload;
      AppStorage.setItem("token", state.token);
    },
    onSetUserToken: (state, action: PayloadAction<AuthState>) => {
      state.user = action.payload.user;
      state.token = action.payload.token;
      AppStorage.setItem("user", state);
    },
    onlogout: (state) => {
      state.user = null;
      state.token = null;
      AppStorage.setItem("user", state);
      AppStorage.clear();
    },
  },
});

export const { onSetUser, onSetUserToken, onSetToken, onlogout } =
  UserSlice.actions;
export default UserSlice.reducer;
