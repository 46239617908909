import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { QueryUrl } from "../../utils";
import { PaginationResults } from "../commerce/commerce.type";
import { prepareHeaders } from "../user/user.api";
import { IContact, TContactForm, TEditContactForm, TResponseContact } from "./contact.type";

const tags = {
	contact: "contact",
};

export const ContactApi = createApi({
	reducerPath: "contactApi",
	tagTypes: [tags.contact],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		getContactMessages: builder.query<
			PaginationResults<IContact>,
			{ subject: IContact["subject"] | null; category: IContact["contact_type"] | null }
		>({
			query: (query) => QueryUrl(`contacts/`, query),
			providesTags: [tags.contact],
		}),
		addContact: builder.mutation<IContact, TContactForm>({
			invalidatesTags: [tags.contact],
			query: (data) => {
				return {
					url: `contact/`,
					method: "POST",
					body: data,
				};
			},
		}),
		updateContact: builder.mutation<
			IContact,
			{ slug?: string; data: TEditContactForm | FormData }
		>({
			invalidatesTags: [tags.contact],
			query: ({ slug, data }) => {
				return {
					url: `contact/${slug}/`,
					method: "PUT",
					body: data,
				};
			},
		}),
		deleteContact: builder.mutation<IContact, string>({
			query: (slug) => ({
				url: `contact/${slug}/`,
				method: "DELETE",
			}),
			invalidatesTags: [tags.contact],
		}),
		responseContact: builder.mutation<IContact, TResponseContact>({
			invalidatesTags: [tags.contact],
			query: (data) => {
				return {
					url: `responsecontact/`,
					method: "POST",
					body: data,
				};
			},
		}),
	}),
});

export const {
	useAddContactMutation,
	useGetContactMessagesQuery,
	useDeleteContactMutation,
	useUpdateContactMutation,
	useResponseContactMutation,
} = ContactApi;
