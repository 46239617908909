import React, { useState, useEffect } from 'react'
import { BsFillCameraFill } from 'react-icons/bs'
import { FiEdit3 } from 'react-icons/fi'
import { useAppSelector } from '../../../../redux/hooks'
import { getAvatar } from "../../../../utils/utils";
import { FormError } from '../../../common/CustomInputAdmin'
import GoogleInput from "../../../common/GoogleInput";
import ChangePassword from '../../user/MyCustumerArea/ParametresTabs/ChangePassword'
import UseEditUserAdmin from './requestSettings/UseEditUserAdmin'
import './SettingAdmin.css'

const PersonalInformation = () => {
  const { register, setValue, onSubmit, errors, isLoading } = UseEditUserAdmin()
  const { user } = useAppSelector((s) => s?.user)
  const [avatar, setAvatar] = useState<any>(null)
  const [address, setAddress] = useState<any>('')
  useEffect(() => {
    if (user?.id) {
      setValue('slug', user?.slug)
      setValue('firstname', user?.firstname)
      setValue('lastname', user?.lastname)
      setValue('phone', user?.phone)
      setValue('email', user?.email)
      setValue('adresse', user?.adresse)
      setAddress(user?.adresse)
    }
  }, [user])
  const handleChangeImage = (e) => {
    setAvatar(e.target.files[0])
    setValue('avatar', e.target.files[0])
  }
  return (
    <div className="tab-content-admin">
      <form onSubmit={onSubmit}>
        <div className="row">
          <div className="mb-4">
            <div className="profile-pic">
              <label className="-label" htmlFor="file">
                <span className="glyphicon glyphicon-camera">
                  <BsFillCameraFill />
                </span>
                <span>Change Image</span>
              </label>
              <input
                id="file"
                type="file"
                accept="image/*"
                onChange={(e) => handleChangeImage(e)}
              />
              <img
                src={
                  avatar ? URL.createObjectURL(avatar) : getAvatar(user?.avatar)
                }
                id="output"
                width="200"
              />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label className="form-label label-form-admin">Prénom</label>
              <input
                type="text"
                className="form-control form-control-input-admin"
                placeholder="Prénom"
                {...register('firstname')}
              />
              <span className="icon-edit-admin">
                <FiEdit3 />
              </span>
              <FormError error={errors?.firstname?.message} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label className="form-label label-form-admin">Nom</label>
              <input
                type="text"
                className="form-control form-control-input-admin"
                placeholder="Nom"
                {...register('lastname')}
              />
              <span className="icon-edit-admin">
                <FiEdit3 />
              </span>
              <FormError error={errors?.lastname?.message} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label className="form-label label-form-admin">Email</label>
              <input
                type="email"
                className="form-control form-control-input-admin"
                placeholder="Email"
                {...register('email')}
              />
              <span className="icon-edit-admin">
                <FiEdit3 />
              </span>
              <FormError error={errors?.email?.message} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label className="form-label label-form-admin">Téléphone</label>
              <input
                type="tel"
                className="form-control form-control-input-admin"
                placeholder="Téléphone"
                {...register('phone')}
              />
              <span className="icon-edit-admin">
                <FiEdit3 />
              </span>
              <FormError error={errors?.phone?.message} />
            </div>
          </div>
          <div className="col-md-6 mb-3">
            <div className="position-relative">
              <label className="form-label label-form-admin">Adresse</label>
              <GoogleInput
                value={address}
                onChange={(value) => {
                  setAddress(value?.rue)
                  setValue('adresse', value?.rue)
                  setValue('latitude', value?.latitude)
                  setValue('longitude', value?.longitude)
                }}
                className="form-control form-control-input-admin"
              />

              <span className="icon-edit-admin">
                <FiEdit3 />
              </span>
              <FormError error={errors?.adresse?.message} />
            </div>
          </div>

          <div className="d-flex justify-content-end mt-3">
            <button
              className="admin-btn-add admin-btn-typo"
              type="submit"
              disabled={isLoading}
            >
              {!isLoading ? (
                `Enregistrer`
              ) : (
                <>
                  <span
                    className="spinner-border spinner-border-sm text-light me-1 d-inline-block"
                    role="status"
                  ></span>
                  <span>Chargement...</span>
                </>
              )}
            </button>
          </div>
        </div>
      </form>
      <ChangePassword />
    </div>
  )
}

export default PersonalInformation
