import { TypeBoost } from "./api/abonnement/abonnement.type";

export const Authorisations = [
	{
		value: "dashboard",
		label: "Accés au tableau de bord",
		path: "/app/admin/tableau-de-bord",
	},
	{
		value: "users",
		label: "Accés à la gestion des utilisateurs",
		path: "/app/admin/utilisateurs",
	},
	{
		value: "vendeurs",
		label: "Accés à la gestion des vendeurs",
		path: "/app/admin/vendeurs",
	},
	{
		value: "commerces",
		label: "Accés à la gestion des boutiques",
		path: "/app/admin/boutiques",
	},
	{
		value: "products",
		label: "Accés à la gestion des produits",
		path: "/app/admin/produits",
	},
	{
		value: "orders",
		label: "Accés à la gestion des commandes",
		path: "/app/admin/commandes",
	},
	{
		value: "chat",
		label: "Accés à la gestion des plaintes utilisateurs",
		path: "/app/admin/messages",
	},
	{
		value: "notifications",
		label: "Accés à la gestion des notifications",
		path: "/app/admin/notifications",
	},
	{
		value: "partners",
		label: "Accés à la gestion des annuaires",
		path: "/app/admin/annuaires",
	},
	{
		value: "networks",
		label: "Accés à la gestion des professionnels",
		path: "/app/admin/professionnels",
	},
	{
		value: "categories",
		label: "Accés à la gestion des catégories",
		path: "/app/admin/categories",
	},
	{
		value: "sectors",
		label: "Accés à la gestion des sectors",
		path: "/app/admin/secteurs",
	},
	{
		value: "parameter",
		label: "Accés aux paramètres",
		path: "/app/admin/parametres",
	},
	{
		value: "help",
		label: "Accés aux aides",
		path: "/app/admin/aide",
	},
];
export const Status = [
	{
		value: "created",
		label: "Nouveau",
	},
	{
		value: "placed",
		label: "En cours de préparation",
	},
	{
		value: "ready",
		label: "Prêt à être livrer",
	},
	{
		value: "shipped",
		label: "En cours de livraison",
	},
	{
		value: "delivered",
		label: "Livrer",
	},
	{
		value: "canceled",
		label: "Annuler",
	},
	{
		value: "returned",
		label: "Retourner",
	},
	{
		value: "failed",
		label: "Echec de livraison",
	},
];
export const statusOrder = {
	created: "Nouveau",
	placed: "En cours de préparation",
	shipped: "En cours de livraison",
	delivered: "Livrer",
	canceled: "Annuler",
	failed: "Echec de livraison",
	returned: "Retourner",
	ready: "Prêt à être livrer",
};

export const regexValidateTel =
	/^([+]?1[\s]?)?((?:[(](?:[2-9]1[02-9]|[2-9][02-8][0-9])[)][\s]?)|(?:(?:[2-9]1[02-9]|[2-9][02-8][0-9])[\s.-]?)){1}([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2}[\s.-]?){1}([0-9]{4}){1}$/;

export const telRegex = /^(?:\+\d{1,2}|\d{1,2})[\d\s.-]{8,}$/;

export const appEnv = window.location.origin;

export const TypeBoostOptions = [
	{
		value: TypeBoost.simple,
		label: "Boost sur Munngano Connect",
	},
	{
		value: TypeBoost.reseaux,
		label: "Boost sur les réseaux sociaux",
	},
	{
		value: TypeBoost.video_reseau,
		label: "Boost sur les réseaux avec vidéo",
	},
];

export const API_AVATAR_DEFAULT_VALUE = "/mediafiles/avatars/default.png";
