import React, { useEffect, useState } from "react";
import { FaRegEdit } from "react-icons/fa";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "./StylesInput.scss";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { GroupedItem } from "./PanierTabs";
import CartItem from "./CartItem";
import { useGetDeliveryByVisitorQuery } from "../../../../utils/api/deliveyMode/delivery.api";
import ProcessSkeleton from "../../../skeletons/ProcessSkeleton";
import { EmptyMessage } from "../../../../utils/EmptyMessage";
import { createMarkup } from "../../../../utils/utils";
import { useGetPaymentModeByVisitorQuery } from "../../../../utils/api/paymentMode/paymentMode.api";
import UseAddOrderClient from "./requestOrder/UseAddOrderClient";
import ErrorMessage from "../../../common/ErrorMessage";
import GoogleInput from "../../../common/GoogleInput";

const FinaliserCommande = () => {
	const {
		register,
		onSubmit,
		setValue,
		formState,
		shipping,
		setShipping,
		itemLoading,
		paymentMode,
		setPaymentMode,
	} = UseAddOrderClient();
	const { cartItems, total } = useAppSelector<any>((state) => state.cart);
	const { user, token } = useAppSelector<any>((s) => s?.user);
	const [items, setItems] = useState<any[]>([]);
	const [address, setAddress] = useState<string>("");
	const [phone, setPhone] = useState<string>();

	const { isLoading, data: deliveries = [] } = useGetDeliveryByVisitorQuery();
	const {
		isLoading: loading,
		isSuccess,
		data: paymentModes = [],
	} = useGetPaymentModeByVisitorQuery();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (user?.id) {
			setAddress(user?.adresse);
			setValue("shipping_address", user?.adresse);
			setValue("billing_address", user?.adresse);
			setValue("adresse", user?.adresse);
			setValue("phone", user?.phone);
			setValue("email", user?.email);
			setValue("firstname", user?.firstname);
			setValue("lastname", user?.lastname);
		}
	}, [user]);
	useEffect(() => {
		if (cartItems?.length) {
			setItems(GroupedItem(cartItems));
		} else {
			setItems([]);
		}
	}, [cartItems]);
	return (
		<div className="mb-5">
			<form onSubmit={onSubmit}>
				<div className="component-checkout-process-steps">
					<div className="row px-md-0 m-b-15">
						<div className="col-lg-4 col-md-4 m-b-20 px-md-2 dis-flex">
							<div className="item-step-checkout w-100">
								<div className="flex-sb m-b-25 align-items-center">
									<div className="">
										<span className="step-item-process-card">
											1
										</span>
										<p className="title-proccess-checkout-card m-b-10">
											Informations{" "}
										</p>
									</div>
									<div
										className="btn-edit-step-item-process-card m-b-10"
										data-toggle="modal"
										data-target="#exampleModal"
									>
										<FaRegEdit />
									</div>
								</div>
								<div className="pb-4">
									<p className="client-info-proccess-checkout">
										Renseigner les informations de livraison
									</p>
								</div>
								<input
									type="text"
									{...register("firstname")}
									id="firstname"
									className="form-control auth-form-control mb-3"
									placeholder="Prénom"
								/>
								{formState?.errors?.firstname && (
									<ErrorMessage
										message={
											formState?.errors?.firstname
												?.message
										}
									/>
								)}
								<input
									type="text"
									{...register("lastname")}
									id="lastname"
									className="form-control auth-form-control mb-3"
									placeholder="Nom"
								/>
								{formState?.errors?.lastname && (
									<ErrorMessage
										message={
											formState?.errors?.lastname
												?.message
										}
									/>
								)}
								<input
									// type="email"
									{...register("email")}
									id="email"
									className="form-control auth-form-control mb-3"
									placeholder="Email"
								/>
								{formState?.errors?.email && (
									<ErrorMessage
										message={
											formState?.errors?.email?.message
										}
									/>
								)}
								{/* <input
									type="tel"
									{...register("phone")}
									id="phone"
									className="form-control auth-form-control mb-3"
									placeholder="Téléphone"
								/> */}
								<PhoneInput
									country={"fr"}
									value={phone}
									onChange={(phone) => {
										setPhone(phone);
										setValue("phone", phone);
									}}
									containerClass="form-control auth-form-control mb-3 p-0"
									inputClass="react-custom-tel-input"
								/>
								{formState?.errors?.phone && (
									<ErrorMessage
										message={
											formState?.errors?.phone?.message
										}
									/>
								)}
								<GoogleInput
									value={address}
									onChange={(value) => {
										setValue(
											"shipping_address",
											value?.rue
										);
										setValue("billing_address", value?.rue);
										setValue("adresse", value?.rue);
										setAddress(value?.rue);
									}}
									className="form-control auth-form-control"
								/>
								{formState?.errors?.shipping_address && (
									<ErrorMessage
										message={
											formState?.errors
												?.shipping_address?.message
										}
									/>
								)}
							</div>
						</div>

						{isLoading ? (
							<ProcessSkeleton />
						) : (
							<div className="col-lg-4 col-md-4 m-b-20 px-md-2 dis-flex">
								<div className="item-step-checkout">
									<div className="row m-b-25">
										<div className="col">
											<span className="step-item-process-card-reverse">
												2
											</span>
											<p className="title-proccess-checkout-card m-b-10">
												Mode de livraison
											</p>
										</div>
									</div>
									<div className="pb-4">
										<p className="client-info-proccess-checkout">
											Choisissez un mode de livraison
										</p>
									</div>
									{deliveries?.length ? (
										deliveries
											?.slice()
											?.sort((a, b) => a?.id - b?.id)
											?.map((item) => (
												<div
													className="custom-control custom-radio m-b-35"
													key={item?.id}
												>
													<input
														type="radio"
														className="custom-control-input radio-input"
														id={`rdo${item?.slug}`}
														value={item?.id}
														onChange={(
															e
														) => {
															setShipping(
																item
															);
															setValue(
																"delivery",
																e
																	.target
																	.value
															);
														}}
														name="delivery"
														// {...register('delivery')}
													/>
													<label
														htmlFor={`rdo${item?.slug}`}
														className="custom-control-label-input-radio radio-label m-b-10"
													>
														<span className="radio-border"></span>
														{item?.name} (
														{item?.amount}{" "}
														FCFA)
													</label>
													<p
														className="input-item-step-checkout-p"
														dangerouslySetInnerHTML={createMarkup(
															item?.description
														)}
													/>
												</div>
											))
									) : (
										<div className="custom-control custom-radio m-b-35">
											{EmptyMessage(
												"Aucun mode de livraison disponible pour le moment"
											)}
										</div>
									)}
									{formState?.errors?.delivery && (
										<ErrorMessage
											message={
												formState?.errors?.delivery
													?.message
											}
										/>
									)}
								</div>
							</div>
						)}
						{loading ? (
							<ProcessSkeleton />
						) : (
							<div className="col-lg-4 col-md-4 m-b-20 px-md-2 dis-flex">
								<div className="item-step-checkout">
									<div className="row m-b-25">
										<div className="col">
											<span className="step-item-process-card-reverse">
												3
											</span>
											<p className="title-proccess-checkout-card m-b-10">
												Mode de paiement
											</p>
										</div>
									</div>
									<div className="pb-4">
										<p className="client-info-proccess-checkout">
											Quel moyen de paiement voulez-vous
											utiliser?
										</p>
									</div>
									{paymentModes?.length ? (
										paymentModes?.map((item) => (
											<div
												className="custom-control custom-radio m-b-35"
												key={item?.id}
											>
												<input
													type="radio"
													className="custom-control-input radio-input"
													id={`payment${item?.slug}`}
													value={item?.id}
													// {...register('payment_mode')}
													onChange={(e) => {
														setPaymentMode(
															item
														);
														setValue(
															"payment_mode",
															e.target
																.value
														);
													}}
													name="payment_mode"
												/>
												<label
													htmlFor={`payment${item?.slug}`}
													className="radio-label custom-control-label-input-radio m-b-10"
												>
													<span className="radio-border"></span>
													{item?.title}:
												</label>
												<p
													className="input-item-step-checkout-p"
													dangerouslySetInnerHTML={createMarkup(
														item?.description
													)}
												/>
											</div>
										))
									) : (
										<div className="custom-control custom-radio m-b-35">
											{EmptyMessage(
												"Aucun mode de paiement disponible pour le moment"
											)}
										</div>
									)}
									{formState?.errors?.payment_mode && (
										<ErrorMessage
											message={
												formState?.errors
													?.payment_mode
													?.message
											}
										/>
									)}
								</div>
							</div>
						)}
					</div>
				</div>
				<div className="commande-item mt-4">
					<p className="text-commande-item m-b-30 fw-600 fs-14">
						Détails de la livraison
					</p>

					<div className="component-cart-item b-b-1 mb-3">
						<CartItem items={items} />
						<div className="row">
							<div className="col-lg-8 dis-flex itm-center">
								<div className="row w-full m-b-30">
									<div className="col-lg-8 col-md-10">
										<p className="resume-item fw-300 fs-14">
											Date de livraison
										</p>
										<p className="resume-item fw-600 fs-14 ">
											Livré entre jeudi 3 sept. et
											vendredi 4 sept.
										</p>
									</div>
								</div>
							</div>
							<div className="col-lg-4">
								<p className="resume-item fw-400 fs-14 m-b-20">
									Prix ​​sous-total :{" "}
									<span className="value-resume-item fs-14 fw-700">
										{total} FCFA
									</span>
								</p>
								<p className="resume-item fw-400 fs-14 m-b-20">
									Frais de livraison (+) :{" "}
									<span className="value-resume-item fs-14 fw-700">
										{shipping?.id &&
											(shipping?.amount === 0
												? "Livraison offerte"
												: shipping?.amount +
												  " " +
												  "FCFA")}
									</span>
								</p>
								<p className="resume-item fw-400 fs-14 m-b-20">
									Remise (-) :{" "}
									<span className="value-resume-item fs-14 fw-700">
										{" "}
										0 FCFA
									</span>
								</p>
								{/* <p className="resume-item fw-700 itm-center m-b-20">
                  Total à payer :{' '}
                  <span className="value-resume-item-ttc">{total} FCFA</span>
                </p> */}
								<div className="flex-sb">
									<p className="text-dark-prix-value mb-2">
										Total à payer :{" "}
									</p>
									<p className="fw-700 text-up text-up-title fs-16">
										{total +
											(parseFloat(shipping?.amount) ||
												0)}{" "}
										FCFA
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className="row justify-content-end mt-5">
						<div className="col-xl-3 col-lg-4 col-md-6">
							{/* <button className="btn-user-info-form-reverse">
                Poursuivre mes achats
              </button> */}
						</div>
						<div className="col-lg-4 col-md-6">
							<button
								type="submit"
								className="btn btn-theme trans-0-2 w-100"
								disabled={itemLoading}
							>
								{itemLoading ? (
									<>
										Confirmation en cours&nbsp;
										<i className="fas fa-spin fa-spinner"></i>
									</>
								) : (
									"Confirmer ma commande"
								)}
							</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	);
};

export default FinaliserCommande;
