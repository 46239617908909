import React, { useEffect } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import { FiChevronRight, FiChevronUp } from "react-icons/fi";
import { MdOutlineAddCircleOutline } from "react-icons/md";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { useGetCategoryAdminQuery } from "../../../../../utils/api/category/category.api";
import { Category } from "../../../../../utils/api/category/category.type";
import { Color } from "../../../../../utils/theme";
import { getImage } from "../../../../../utils/utils";
import Pagination from "../../../../common/Pagination";
import AddCategoryModal from "../../../modals/adminModal/AddCategoryModal";
import AddSubCategoryModal from "../../../modals/adminModal/AddSubCategoryModal";
import { useDeleteCategory } from "../../../modals/adminModal/forms/useCategoryForm";
import SubCategoryTable from "../SubCategorieTable/SubCategoryTable";
import CategorieTableSkelaton from "./CategorieTableSkelaton";

function CategorieTable({ word }: { word: string }) {
  const [page, setPage] = React.useState(1);
  const { data = { results: [] }, isLoading } = useGetCategoryAdminQuery({
    page,
    word,
  });
  useEffect(() => setPage(1), [word]);

  const nameFormatter = (cell: any, row: Category) => {
    return (
      <div>
        <img
          src={getImage(row.picture)}
          alt="Produit"
          className="img-produit-table-admin"
        />
        <span className="ps-2">{row.name}</span>
      </div>
    );
  };

  const actionFormatter: any = (cell, row: Category) => {
    const modalId = `UpdateCategory${row.id}`;
    return (
		<div className="table-actions-btn-container-commandes gap-2 d-flex">
			<AddCategoryModal modalId={modalId} item={row} />
			<div>
				<button
					data-bs-toggle="modal"
					data-bs-target={`#${modalId}`}
					className="btn btn-action-user-admin"
					title="Editer"
				>
					<RiEdit2Fill />
				</button>
			</div>
			<div>
				<DeleteCategoryButton item={row} />
			</div>
		</div>
    );
  };
  const columns = [
		{
			dataField: "name",
			text: "Nom",
			formatter: (cell: any, row: Category) => nameFormatter(cell, row),
			style: { textAlign: "left" },
		},
		{
			dataField: "type_category",
			text: "Type catégorie",
			formatter: (cell: string) => (cell === "partner" ? "Annuaire" : "Commerce"),
		},
		{
			dataField: "subcategory.length",
			text: "Sous-catégorie",
		},
		{
			dataField: "position",
			text: "Position",
		},
		{
			dataField: "actions",
			text: "Actions",
			formatter: (cell: any, row: Category) => actionFormatter(cell, row),
		},
  ];
  const expandRow = {
    renderer: (row) => {
      const modalId = `AddSubCategory${row.id}`;
      return (
        <div style={{ backgroundColor: "#FFF" }} className="p-4">
          <div
            style={{ backgroundColor: "#FFF" }}
            className="bloc-entete-admin"
          >
            <div className="title-admin-container">
              <h4>Sous-catégories</h4>
            </div>
            <div className="bloc-right-container gap-3">
              <div className="container-input-search-admin" />
              <div>
                <button
                  className="btn btn-theme-outline p-2"
                  style={{ fontSize: 13 }}
                  data-bs-toggle="modal"
                  data-bs-target={`#${modalId}`}
                >
                  <MdOutlineAddCircleOutline />
                  <span className="ps-2">Ajouter une sous-catégorie</span>
                </button>
              </div>
            </div>
          </div>
          <div>
            <SubCategoryTable category={row} />
          </div>
          <AddSubCategoryModal category={row} modalId={modalId} />
        </div>
      );
    },
    showExpandColumn: true,
    expandByColumnOnly: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
      if (isAnyExpands) {
        return <b className="d-none">-</b>;
      }
      return <b className="d-none">+</b>;
    },
    expandColumnRenderer: ({ expanded }) => {
      if (expanded) {
        return (
          <b>
            <FiChevronUp size={24} color={Color.themeColor} />
          </b>
        );
      }
      return (
        <b>
          <FiChevronRight size={24} color={Color.themeColor} />
        </b>
      );
    },
  };

  return (
    <>
      {isLoading && <CategorieTableSkelaton />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={data.results}
          columns={columns}
          bordered={true}
          condensed={false}
          responsive={true}
          expandRow={expandRow}
          noDataIndication={() => "Pas de résultats"}
          wrapperClasses="table-responsive admin-table"
        />
      )}
      <Pagination onPageChange={setPage} page={page} total={data.count} />
    </>
  );
}

export default CategorieTable;

function DeleteCategoryButton({ item }: { item: Category }) {
  const onDelete = useDeleteCategory(item);
  return (
		<button onClick={onDelete} className="btn btn-action-user-admin" title="Supprimer">
			<RiDeleteBin7Fill />
		</button>
  );
}
