import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { useSendMessageToAllUsersMutation } from "../../../../utils/api/chat/chat.api";
import { ChatGroupData } from "../../../../utils/api/chat/chat.type";
import { Color } from "../../../../utils/theme";
import { cleannerError, onHide } from "../../../../utils/utils";
import { BtnSubmit } from "../../../common/Button";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import "../../user/MyCustumerArea/MyCustumerArea.css";
import * as yup from "yup";
import { FormError } from "../../../common/CustomInputAdmin";

const SendMessageToAllUsersModal = () => {
	const { register, errors, onSubmit, isLoading } = useSendMessage();
	return (
		<div className="modal-dialog">
			<div className="modal-content">
				<div className="modal-header">
					<h5
						className="modal-title modal-title-custom fw-500 fs-20"
						id="SendMessageToAllUsersModal"
					>
						Envoyer un message aux membres
					</h5>
					<CloseModalBtn />
				</div>
				<div className="modal-body">
					<form className="w-100" onSubmit={onSubmit}>
						<div className="form-group">
							<label htmlFor="message" className="form-label">
								Message
							</label>
							<textarea
								id="message"
								className="form-control w-100"
								{...register("content")}
								style={{ minHeight: "200px" }}
							/>
							<FormError error={errors?.content} />
						</div>
						<div className="modal-footer">
							<BtnSubmit label="Envoyer" isLoading={isLoading} />
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

function useSendMessage() {
	const validationSchema = yup.object().shape({
		content: yup.string().required().label("La réponse"),
	});
	const [sendMessage, { isSuccess, isError, error, isLoading }] =
		useSendMessageToAllUsersMutation();
	const {
		register,
		handleSubmit,
		formState: { errors },
		clearErrors,
		setValue,
		reset,
	} = useForm<ChatGroupData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		if (errors) {
			return cleannerError(errors, clearErrors);
		}
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Message envoyé avec succès!",
				iconColor: Color.success,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				onHide("SendMessageToAllUsersModal");
			});
		}
		if (isError) {
			Swal.fire({
				icon: "error",
				title: "Une erreur a survenue lors du changement",
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);
	const onSubmit = (data: ChatGroupData) => {
		sendMessage(data);
	};

	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		isLoading,
	};
}

export default SendMessageToAllUsersModal;
