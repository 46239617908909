import React, { useState } from "react";
import Ingenierie from "../../../../assets/appImages/computer.png";
import Design from "../../../../assets/appImages/ux-design.png";
import Markting from "../../../../assets/appImages/digital-marketing.png";
import Carousel from "react-multi-carousel";
import { createMarkup, getImage } from "../../../../../utils/utils";

const Data = [
	{
		id: 1,
		title: "INGÉNIERIE LOGICIELLE",
		image: Ingenierie,
	},
	{
		id: 2,
		title: "UX/UI DESIGN",
		image: Design,
	},
	{
		id: 3,
		title: "MARKETING DIGITAL",
		image: Markting,
	},
	{
		id: 4,
		title: "INFOGRAPHIE / VIDEO EDITING",
		image: Ingenierie,
	},
	{
		id: 5,
		title: "INGÉNIERIE LOGICIELLE",
		image: Design,
	},
	{
		id: 6,
		title: "UX/UI DESIGN",
		image: Markting,
	},
];
const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 3,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
		itemsToScroll: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 464 },
		items: 2,
		itemsToScroll: 1,
	},
	mobile: {
		breakpoint: { max: 464, min: 0 },
		items: 1,
		itemsToScroll: 1,
	},
};
const Description = ({ partner }) => {
	return (
		<>
			<div className="col-tab-espace-client p-3 mb-3">
				<div className="pb-3 b-b-1">
					<p className="titre-liste-des-commerce fw-600">Slides</p>
				</div>
				<div className="">
					<Carousel
						swipeable={false}
						draggable={false}
						showDots={true}
						responsive={responsive}
						ssr={false}
						infinite={true}
						autoPlay={true}
						autoPlaySpeed={1000}
						keyBoardControl={true}
						arrows={true}
						customTransition="transform 1000ms ease-in-out"
						transitionDuration={2000}
						containerClass="carousel-container"
						removeArrowOnDeviceType={["tablet", "mobile"]}
						dotListClass="custom-dot-list-style"
						itemClass="carousel-item-padding-40-px py-5"
					>
						{partner?.slides?.length > 0 &&
							partner?.slides?.map((item) => {
								return (
									<div key={item.slug}>
										<img
											src={getImage(item?.picture)}
											alt={item.title}
											className="card-partner-img"
											height="250px"
											width="100%"
										/>
									</div>
								);
							})}
					</Carousel>
				</div>
			</div>
			<div className="col-tab-espace-client p-3 mb-3">
				<div className="pb-3 b-b-1">
					<p className="titre-liste-des-commerce">Description</p>
				</div>
				<div
					className="pt-2 texte-description-parter"
					dangerouslySetInnerHTML={createMarkup(partner?.description)}
				/>
			</div>
			<div className="row px-2">
				<div className="col-lg-6  bg-white py-2 px-3">
					<div className="mb-3">
						<p className="text-desc fw-600">Lien URL</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={partner?.link_url}
							target="_blank"
						>
							{partner?.link_url}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Adresse mail</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={`mailto:${partner?.email}`}
						>
							{partner?.email}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Whatshapp</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={`https://wa.me/${partner?.whatsapp}}`}
							target="_blank"
						>
							{partner?.whatsapp}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Téléphone</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={`tel:${partner?.phone}`}
							target="_blank"
						>
							{partner?.phone}
						</a>
					</div>
				</div>
				<div className="col-lg-6 bg-white py-2 px-3">
					<div className="mb-3">
						<p className="text-desc fw-600">Facebook</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={partner?.facebook}
							target="_blank"
						>
							{partner?.facebook}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Twitter</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={partner?.twitter}
							target="_blank"
						>
							{partner?.twitter}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Instagram</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={partner?.instagram}
							target="_blank"
						>
							{partner?.instagram}
						</a>
					</div>
					<div className="mb-3">
						<p className="text-desc fw-600">Linkedin</p>
						<a
							className="text-desc fw-400 hover-theme"
							href={partner?.linkedin}
							target="_blank"
						>
							{partner?.linkedin}
						</a>
					</div>
				</div>
			</div>
		</>
	);
};

export default Description;
