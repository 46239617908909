import { NavLink } from "react-router-dom";
import "./LoginAdmin.css";
import Logo from "../../../assets/appImages/logo.png";

const ForgetPasswordAdmin = () => {
	return (
		<div className="login-component">
			<div className="">
				<div className="row login-page-row">
					<div className="col-md-4 offset-md-4 right-side-col">
						<div className="login-form-container">
							<div className="login-form-introduction d-flex justify-content-center">
								<div className="pb-3">
									<img src={Logo} alt="logo" className="app-logo" />
								</div>
								<h1 className="login-title-label">Connexion</h1>
								<p className="text-forget-password-admin">
									Veuillez entrer votre email Nous vous enverrons un lien pour
									modifier le mot de passe
								</p>
							</div>
							<form id="login-form">
								<div className="form-group my-4">
									<label className="espace-client-form-label">Adresse email</label>
									<input
										name="email"
										id="email"
										type="text"
										className="form-control input-with-icon pl-4"
										placeholder="example@gmail.com"
										data-testid="emailId"
									/>
								</div>
								<div className="row submit-btn-row">
									<div className="col-md-12 submit-btn-col">
										<NavLink
											to="/app/admin/tableau-de-bord"
											type="submit"
											className="btn-connexion"
											data-testid="btnId"
										>
											Récuperer mon mot de passe
										</NavLink>
									</div>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ForgetPasswordAdmin;
