import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import { BudgetFormData } from "../../../../utils/api/budget/budget.type";
import { cleannerError } from "../../../../utils/utils";
import { Color } from "../../../../utils/theme";
import { useAppSelector } from "../../../../redux/hooks";
import { useAddBudgetMutation } from "../../../../utils/api/budget/budget.api";
function useAjoutBudgetModal() {
	const [addBudget, { isLoading, isSuccess, error, isError }] = useAddBudgetMutation();
	const navigate = useNavigate();
	const { user } = useAppSelector((state) => state.user);
	const validationSchema = yup.object().shape({
		name: yup.string().required().label("Budget"),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
		reset,
	} = useForm<BudgetFormData>({
		resolver: yupResolver(validationSchema),
	});
	useEffect(() => {
		if (errors) {
			cleannerError(errors, clearErrors, 5000);
		}
	}, [errors]);

	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Budget ajouté avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			}).then(() => {
				reset();
				document.getElementById("ajoutBudgetModal")?.classList.remove("show");
				$(".modal-backdrop").remove();
				$(`#ajoutBudgetModal`).hide();
				$(`#ajoutBudgetModal`).click();
			});
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading]);

	const onSubmit = (data) => {
		const newData = { ...data, user: user?.id };
		console.log(newData);

		addBudget(newData);
	};
	return {
		register,
		onSubmit: handleSubmit(onSubmit),
		errors,
		setValue,
		isLoading: isLoading,
	};
}

export default useAjoutBudgetModal;
