import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import { RiDeleteBin6Line } from "react-icons/ri";
import StarRatingComponent from "react-star-rating-component";
import { useCommerceFromLocation } from "../../../../utils/api/commerce/commerce.api";
import { getImage } from "../../../../utils/utils";
import { EmptyMessage } from "../../../../utils/EmptyMessage";
import { Link, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";
import { useDeleteProductMutation } from "../../../../utils/api/product/product.api";
import { useAppSelector } from "../../../../redux/hooks";
import { isSeller } from "../../../../routes/router";
import "./TraderBoutiques.css";

function TraderDetailsBoutique() {
	const [item, isLoading, slug, findBySlug] = useCommerceFromLocation();
	const is_Seller = useAppSelector((s) => isSeller(s.user?.user));
	return (
		<div className="fixed-after-header">
			<section className="">
				<div className="col-tab-espace-client custom-page-detail-boutique">
					<p className="name-shop pe-3 custom-name-shop mt-0 mb-4">
						{item?.name}
					</p>
					<div className="banner-container position-relative mb-5">
						<div className="banner-img-shop-container">
							<img
								src={getImage(item?.background_image)}
								alt="banniere"
								className="banner-shop-img custom-banner-shop-img"
							/>
							{is_Seller && (
								<div className="pt-3 d-flex justify-content-end">
									{/* <button className="btn btn-send-newsletter">
                  <span>S’abonner</span>
                </button> */}
									<NavLink
										to={`/commercant/boutiques/booster/${item?.slug}`}
										state={item}
										className="btn m-btn-next"
									>
										<span>Booster</span>
									</NavLink>
								</div>
							)}
						</div>
						<div className="img-logo-shop-container">
							<img
								src={getImage(item?.logo)}
								width="150"
								height="150"
								alt="Logo"
								className="logo-shop-img"
							/>
						</div>
					</div>
					<div className="row pt-4">
						<div className="col-md-6">
							<div className="trader-detail-boutique-info-container">
								<div className="trader-detail-boutique-info-para">
									<span className="trader-detail-boutique-info-span">
										{item?.subscribers?.length}
									</span>{" "}
									Abonnés
								</div>
							</div>
						</div>
					</div>
					<div className="row">
						{/* <div className="col-md-12">
              <div className="trader-commande-btn-filtre-container mt-4 mb-3">
                <button className="btn trader-commande-btn-filtre trader-commande-btn-filtre-active ">
                  Produits diponibles
                </button>
                <button className="btn trader-commande-btn-filtre">
                  Produits rejetés
                </button>
                <button className="btn trader-commande-btn-filtre">
                  En rupture de stock
                </button>
                <button className="btn trader-commande-btn-filtre">
                  Top produits
                </button>
                <button className="btn trader-commande-btn-filtre">
                  Inactifs
                </button>
                <button className="btn trader-commande-btn-filtre">
                  Non autorisé
                </button>
              </div>
            </div> */}
						<div className="col-md-12">
							<div className="content-page-produit">
								<div>
									<div className="row mt-5">
										{item?.products?.length ? (
											item?.products?.map((product) => (
												<ProductShopItem
													item={product}
													findBySlug={() =>
														findBySlug(
															item?.slug
														)
													}
												/>
											))
										) : (
											<div>
												{" "}
												{EmptyMessage(
													<>
														<span className="me-2">
															Aucun
															produit
															disponible
															pour le
															moment.
														</span>
														{is_Seller && (
															<Link to="/commercant/ajout-article">
																Ajouter
															</Link>
														)}
													</>
												)}{" "}
											</div>
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
	);
}

export default TraderDetailsBoutique;

const ProductShopItem = ({ item, findBySlug }) => {
	const [deleteProject, { isSuccess, error, isError, data }] = useDeleteProductMutation();
	const onDelete = async (slug) => {
		await Swal.fire({
			title: `Êtes-vous sûr de vouloir supprimer ce produit?`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: "OUI",
			cancelButtonText: "NON",
			showLoaderOnConfirm: true,
			iconColor: Color.themeColor,
			preConfirm: () => {
				return deleteProject(slug);
			},
			allowOutsideClick: () => !Swal.isLoading(),
		}).then((result: any) => {
			console.log(result, "archiver");
			if (result?.isConfirmed) {
				findBySlug();
				Swal.fire({
					icon: "success",
					title: `Produit supprimé avec succès!`,
					iconColor: Color.themeColor,
					showConfirmButton: false,
					timer: 1500,
				});
			}
		});
	};
	return (
		<div className="col-xl-4 col-lg-4 col-md-6 pb-4 pt-5" key={item?.id}>
			<div className="product-tabs-item pos-relative">
				<div className="container-img-product-tabs">
					<img
						src={getImage(item?.background_image)}
						alt="Mes produit"
						className="img-product-tabs"
					/>
				</div>
				<div className="product-tabs-card-container">
					<div className="text-top-product-market-place-container">
						<p className="top-product-name">{item?.category?.name}</p>
						<p className="top-product-marque">{item?.name}</p>
						<div>
							<StarRatingComponent
								name="rate2"
								editing={false}
								starCount={5}
								value={parseFloat(item?.rate_average)}
								starColor="#FFA73C"
								emptyStarColor="#f6973f96"
							/>
						</div>
						<div className="top-product-footer">
							<div className="d-flex">
								<p className="top-product-price-unity mb-4">FCFA</p>
								<p className="top-product-price mt-2">
									{item?.price}
								</p>
							</div>
							<div>
								<NavLink
									to={`/commercant/modifier-article/${item?.slug}`}
									state={item}
									className="btn btn-product-tabs no-link trans-0-2 mx-1"
								>
									<MdModeEditOutline />
								</NavLink>
								<button
									className="btn btn-product-tabs trans-0-2 mx-1"
									onClick={(e) => {
										e.preventDefault();
										onDelete(item?.slug);
									}}
								>
									{" "}
									<RiDeleteBin6Line />
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
