import React, { useCallback } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useAppSelector } from '../../../../redux/hooks'
import { isSuperAdmin } from '../../../../routes/router'
import ProfessionnelsTable from '../../Tables/Admin/Professionnels/ProfessionnelsTable'
export const profils = [
  {
    label: 'Prestataire',
    profil: 'provider',
  },
  {
    label: 'Commerçant',
    profil: 'seller',
  },
  {
    label: 'Autre',
    profil: 'user',
  },
]
const ReseauPro = () => {
  const { user } = useAppSelector((s) => s?.user)
  const navigate = useNavigate()
  const location = useLocation()
  const is_SuperAdmin = useAppSelector((s) => isSuperAdmin(s.user?.user))

  React.useEffect(() => {
    if (!user?.networks && !is_SuperAdmin) {
      setTimeout(() => {
        navigate(-1)
      }, 50)
    }
  }, [user])
  const [searchParams, setSearchParams] = useSearchParams()
  const activeProfil = searchParams.get('profil')
  const search = searchParams.get('numero')
  // React.useEffect(() => {
  //   if (location?.search && location?.search?.split('=')?.length) {
  //     let elem: any = location?.search?.split('=').pop()
  //     setSearchParams({
  //       numero: elem,
  //     })
  //   }
  // }, [location])
  const handleFilterByCodeIdentity = (
    e: React.FormEvent<HTMLInputElement>,
  ): void => {
    if (activeProfil) {
      setSearchParams({
        profil: activeProfil,
        numero: e.currentTarget.value,
      })
    } else {
      setSearchParams({
        numero: e.currentTarget.value,
      })
    }
  }
  const handleFilterByProfil = useCallback(
    (item): void => {
      if (search) {
        setSearchParams({
          numero: search,
          profil: item.profil,
        })
      } else {
        setSearchParams({ profil: item.profil })
      }
    },
    [activeProfil],
  )
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Professionnels</h1>
        </div>
        <div className="bloc-right-container gap-3">
          <div className="container-input-search-admin">
            <div className="search-box-admin">
              <input
                type="text"
                onChange={(e) => {
                  handleFilterByCodeIdentity(e)
                }}
                className="search-input-admin"
                placeholder="Rechercher..."
              />

              <button className="search-button-admin">
                <i className="fas fa-search"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="mb-4 d-flex gap-4">
        <h4 className="filter-label">Filtrer par:</h4>
        <div className="d-flex gap-3">
          <button
            onClick={() => {
              setSearchParams({})
            }}
            className={`filter-btn ${activeProfil === null && 'active-filter'}`}
          >
            Tous
          </button>
          {profils.map((item) => (
            <button
              key={item.profil}
              className={`filter-btn ${
                activeProfil === item.profil && 'active-filter'
              }`}
              onClick={() => {
                handleFilterByProfil(item)
              }}
            >
              {item.label}
            </button>
          ))}
        </div>
      </div>
      <div>
        <ProfessionnelsTable searchWord={search} profil={activeProfil} />
      </div>
    </div>
  )
}

export default ReseauPro
