import React, { useEffect } from 'react'
import { User } from '../../../../../utils/api/user/user.type'
import { BtnNext, BtnSkip } from '../../../../common/Button'
import { CustomInput } from '../../../../common/CustomInput'
import { CustomSelectInput } from '../../../../common/CustomSelectInput'
import ErrorMessage from '../../../../common/ErrorMessage'

export const Status = [
  {
    label: 'Actif',
    value: 'actif',
  },
  {
    label: 'Passif',
    value: 'passif',
  },
]

export type TraderStepPropsType = {
  navigation: any
  setValue: any
  register: any
  formState: any
  user: User
}

const AddInfoTrader = ({ setValue, register, formState, user, dataForm }) => {
  useEffect(() => {
    window.scrollTo(10, 10)
    if (dataForm) {
      setValue('condition', dataForm?.condition)
    }
  }, [dataForm])
  return (
    <>
      <h1 className="stepper-title">
        Ajouter les informations du compte du vendeur
      </h1>
      <div>
        <div className="row">
          <CustomInput
            label="Prénom et nom"
            name="fullname"
            type="text"
            readOnly={true}
            value={user?.firstname + ' ' + user?.lastname}
            error={formState?.errors?.author?.message}
            required
          />
          <CustomInput
            label="Nom d’affichage / Nom de la boutique"
            name="shop-name"
            {...register('name')}
            type="text"
            error={formState?.errors?.name?.message}
            required
          />
        </div>
        <div className="row">
          <CustomInput
            label="Adresse mail"
            name="email"
            type="email"
            {...register('email')}
            error={formState?.errors?.email?.message}
            required
          />
          <CustomInput
            label="N° téléphone"
            name="phone"
            type="tel"
            {...register('phone')}
            error={formState?.errors?.phone?.message}
            required
          />
        </div>
        <div className="row">
          <CustomInput
            label="Whatsapp / N° téléphone alternatif"
            name="whatsapp"
            type="tel"
            {...register('whatsapp')}
            error={formState?.errors?.whatsapp?.message}
          />
          <div className="form-group mb-4 col-md-6">
            <label className="custom-form-label" aria-labelledby="accept-terms">
              Conditions générales
              <span className="text-danger">*</span>
            </label>
            <div className="d-flex gap-2">
              <input
                type="checkbox"
                data-testid="acceptTerms"
                className="form-check-input form-custom-check-input"
                {...register('condition')}
              />
              <div>
                <span className="custom-form-label my-0 ml-3    ">
                  J’accepte
                </span>
                <span className="custom-form-label custom-form-check-label my-0">
                  {' '}
                  les conditions générales d’utilisation
                </span>
              </div>
            </div>
            {formState?.errors?.condition?.message && (
              <ErrorMessage message={formState?.errors?.condition?.message} />
            )}
          </div>
        </div>

        <div className="flex-r gap-3 mt-5">
          <BtnSkip label="Passer l’étape" type="button" />
          <BtnNext label="Suivant" type="submit" />
        </div>
      </div>
    </>
  )
}

export default AddInfoTrader
