import React from "react";
import { BtnSubmit } from "../../common/Button";
import ErrorMessage from "../../common/ErrorMessage";
import FrontHeader from "../../shared/FrontHeader/FrontHeader";
import { UseResetPasswordForm } from "./useAuthForm/UseResetPasswordForm";

const ResetPassword = () => {
	const { register, onSubmit, errors, isLoading } = UseResetPasswordForm();

	return (
		<div className="auth-component">
			<FrontHeader />
			<div className="fixed-after-header">
				<div className="auth-container">
					<div className="auth-row row">
						<div className="col-md-4 auth-col auth-left-side-col no-view-l-mobile ">
							<div className="auth-left-side-container"></div>
						</div>
						<div className="col-md-8 auth-col auth-right-side-col">
							<div className="auth-right-side-container">
								<div className="row">
									<div className="auth-form-container col-12 col-xl-8 offset-xl-2">
										<div className="mb-4">
											<h1 className="auth-form-title">
												Modification de mot de passe
											</h1>
											<h4 className="auth-form-message">
												Créer un nouveau mot de passe
											</h4>
										</div>
										<form onSubmit={onSubmit}>
											<div className="row auth-form-row">
												<div className="col-md-12 auth-input-col">
													<div className="form-group auth-form-group">
														<label htmlFor="code">Code</label>
														<input
															type="number"
															className="form-control auth-form-control"
															id="code"
															placeholder="Entrez le code"
															{...register("code")}
															data-testid="codeId"
														/>
														{errors.code?.message && (
															<ErrorMessage
																message={errors.code.message}
															/>
														)}
													</div>
												</div>
												<div className="col-md-12 auth-input-col">
													<div className="form-group auth-form-group">
														<label htmlFor="password">
															Nouveau Mot de passe
														</label>
														<input
															type={`password`}
															className="form-control auth-form-control"
															id="password"
															{...register("new_password")}
															data-testid="newPasswordId"
															placeholder="Entrez un nouveau mot de passe"
														/>
														{errors.new_password?.message && (
															<ErrorMessage
																message={
																	errors.new_password.message
																}
															/>
														)}
													</div>
												</div>
												<div className="col-md-12 auth-input-col">
													<div className="form-group auth-form-group">
														<label
															htmlFor="confirm-new-password"
															aria-labelledby="confirm-password"
														>
															Confimer le mot de passe
														</label>
														<input
															type="password"
															className="form-control auth-form-control"
															id="confirm-new-password"
															{...register("new_password_confirm")}
															data-testid="confirmNewPasswordId"
															placeholder="Confirmer le mot de passe"
														/>
														{errors.new_password_confirm?.message && (
															<ErrorMessage
																message={
																	errors.new_password_confirm
																		.message
																}
															/>
														)}
													</div>
												</div>
												<div className="col-md-6 offset-md-6 auth-submit-btn-container">
													<BtnSubmit
														isLoading={isLoading}
														label="Modifier"
													/>
												</div>
											</div>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPassword;
