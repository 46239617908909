import React from "react";
import "./HeaderLandingpage.css";
import Logo from "../../../assets/appImages/logo.png";
import { useAppSelector } from "../../../../redux/hooks";
import { NavLink } from "react-router-dom";
import { IoMdLogIn } from "react-icons/io";

const HeaderLandingpage: React.FC = () => {
  const { token } = useAppSelector((state) => state.user);

  return (
    <div className="header bg-white">
      <nav className="navbar navbar-expand-lg navbar-light nav-header-custom-landingpage fixed-top">
        <div className="container-fluid">
          <div className="logo-and-version-container">
            <div className="logo-container">
              <a className="navbar-brand" href="/">
                <img src={Logo} alt="Munngano" className="app-logo" />
              </a>
            </div>
          </div>
          {!token && (
            <>
              <div className="content-menu-first-header-landingpage no-view-mobile">
                <a
                  href="/app/se-connecter"
                  className="btn btn-banner-landingpage"
                >
                  <span className="pe-2">
                    <IoMdLogIn />
                  </span>{" "}
                  <span>Se connecter</span>
                </a>
              </div>
              <div className="content-btn-login-mobile-header-landingpage no-view-desktop">
                <a
                  href="/app/se-connecter"
                  className="btn btn-banner-landingpage"
                >
                  <IoMdLogIn />
                </a>
              </div>
            </>
          )}
        </div>
      </nav>
    </div>
  );
};

export default HeaderLandingpage;
