import React, { useState } from "react";
import SlidesTableTableSkeleton from "./SlidesTableSkeleton";
import BootstrapTable from "react-bootstrap-table-next";
import { RiDeleteBin7Fill, RiEdit2Fill } from "react-icons/ri";
import { getImage } from "../../../../../utils/utils";
import { useGetSlidesQuery } from "../../../../../utils/api/slide/slide.api";
import { ISlide } from "../../../../../utils/api/slide/slide.type";
import { useDeleteSlide } from "../../../admin/SettingAdmin/requestSettings/useAddSlide";
import EditSlideModal from "../../../modals/adminModal/EditSlideModal";

function SlidesTable() {
	const { isLoading, data } = useGetSlidesQuery();
	const [item, setItem] = useState();
	const photoFormatter = (cell: string, row: ISlide) => {
		return (
			<div>
				<img
					src={getImage(cell)}
					alt="Produit"
					style={{
						width: "100%",
						height: "200px",
					}}
				/>
			</div>
		);
	};

	const actionFormatter: any = (cell, row) => {
		return (
			<div className="table-actions-btn-container-commandes gap-2 d-flex">
				<div>
					<button
						className="btn btn-action-user-admin"
						title="Modifier"
						data-bs-toggle="modal"
						data-bs-target={`#EditSlideModal`}
						onClick={() => setItem(row)}
					>
						<RiEdit2Fill />
					</button>
				</div>
				<div>
					<DeleteSlide item={row} />
				</div>
			</div>
		);
	};
	const columns = [
		{
			dataField: "nom",
			text: " Noms",
			style: { textAlign: "left" },
		},
		{
			dataField: "fonction",
			text: "Fonction du prestataire",
			style: { textAlign: "left" },
		},
		{
			dataField: "image_couverture",
			text: " Photo",
			style: { textAlign: "left" },
			formatter: (cell: string, row: ISlide) => photoFormatter(cell, row),
			headerStyle: () => {
				return { width: "30%" };
			},
		},
		{
			dataField: "slug",
			text: "actions",
			headerStyle: () => {
				return { textAlign: "center" };
			},
			formatter: (cell: string, row: ISlide) => actionFormatter(cell, row),
		},
	];

	return (
		<>
			{isLoading && <SlidesTableTableSkeleton />}
			{!isLoading && (
				<div>
					<BootstrapTable
						keyField="slug"
						data={data?.results || []}
						columns={columns}
						striped
						noDataIndication="Aucune donnée disponible"
						bordered={false}
						condensed={false}
						responsive
						wrapperClasses="table-responsive admin-table"
						rowStyle={{
							fontFamily: "Poppins",
							fontStyle: "normal",
							fontWeight: "600",
							fontSize: "14px",
							lineHeight: "21px",
							color: "rgba(0, 0, 0, 0.7)",
						}}
						headerClasses="header-class"
					/>
				</div>
			)}
			<EditSlideModal item={item} />
		</>
	);
}

function DeleteSlide({ item }: { item: ISlide }) {
	const onDelete = useDeleteSlide(item);
	return (
		<button className="btn btn-action-user-admin" title="Supprimer" onClick={onDelete}>
			<RiDeleteBin7Fill />
		</button>
	);
}

export default SlidesTable;
