import React, { useEffect } from "react";
import { useGetSocialMediaQuery } from "../../../../utils/api/about/about.api";
import { BtnSubmit } from "../../../common/Button";
import { CustomInputAdmin } from "../../../common/CustomInputAdmin";
import useSocialMediaForm from "./requestSettings/useSocialMediaForm";

const SocialMedia = () => {
	const { data } = useGetSocialMediaQuery();
	const { register, onSubmit, errors, isLoading, setValue } = useSocialMediaForm();
	// console.log(data);
	useEffect(() => {
		if (data) {
			setValue("slug", data?.slug);
			setValue("facebook", data?.facebook);
			setValue("linkedin", data?.linkedin);
			setValue("instagram", data?.instagram);
		}
	}, [data]);
	return (
    <div className="tab-content-admin px-3 py-3">
      <form onSubmit={onSubmit}>
        <div className="mt-3 row">
          <CustomInputAdmin
            label="Facebook"
            id="facebook"
            type="url"
            placeholder="Link"
            {...register("facebook")}
            error={errors?.facebook?.message}
          />
          <CustomInputAdmin
            label="Instagram"
            id="instagram"
            type="url"
            placeholder="Link"
            {...register("instagram")}
            error={errors?.instagram?.message}
          />
          <CustomInputAdmin
            label="Linkedin"
            id="linkedin"
            type="url"
            placeholder="Link"
            {...register("linkedin")}
            error={errors?.linkedin?.message}
          />
          <CustomInputAdmin
            label="Twitter"
            id="twitter"
            type="url"
            placeholder="Twitter"
            {...register("twitter")}
            error={errors?.twitter?.message}
          />
        </div>
        <div className="px-2 flex-r col-md-4 offset-md-8 gap-3 py-3">
          <BtnSubmit label="Edit" isLoading={isLoading} />
        </div>
      </form>
    </div>
  );
};

export default SocialMedia;
