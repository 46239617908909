import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { AnnonceEditFormData, IAnnonce } from "../../../../../utils/api/annonce/annonce.type";
import { useEffect, useState } from "react";
import { cleannerError } from "../../../../../utils/utils";
import { useEditAnnonceMutation } from "../../../../../utils/api/annonce/annonce.api";
import { useAppSelector } from "../../../../../redux/hooks";
import Swal from "sweetalert2";
import { Color } from "../../../../../utils/theme";
import { useNavigate } from "react-router-dom";

const UseEditAnnonceForm = (annonce?: IAnnonce) => {
	const [editAnnonce, { data, isLoading, isSuccess, isError, error }] =
		useEditAnnonceMutation();
	const { user } = useAppSelector((state) => state.user);
	const navigate = useNavigate();
	const [selected, setSelected] = useState<string[]>([]);

	const validationSchema = yup.object().shape({
		title: yup.string().required("Le titre est requis"),
		description: yup.string().required("La Description est requise"),
		type_announcement: yup.string().required("Le type d'annonce est requis"),
		ville: yup.string().required("La ville est requise"),
		region: yup.string().required("La région est requise"),
		country: yup.string().required("Le pays est requise"),
		sector: yup.string().required("Les secteurs d'activités sont requises"),
		images: yup.array(),
	});

	const {
		register,
		handleSubmit,
		formState: { errors },
		setValue,
		clearErrors,
	} = useForm<AnnonceEditFormData>({
		resolver: yupResolver(validationSchema),
	});

	useEffect(() => {
		cleannerError(errors, clearErrors, 5000);
	}, [errors]);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: "Annonce modifiée avec succès!",
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 2000,
			}).then(() => navigate("/app/mon-espace-client/mes-annonces"));
		}
		if (isError) {
			const err = error as any;
			const { message = "Une erreur inconnue s'est produite" } = err.data || {};
			Swal.fire({
				icon: "error",
				title: message,
				showConfirmButton: false,
				timer: 5000,
			});
		}
	}, [isLoading, isSuccess]);

	const handleChangeTags = (value: string[]) => {
		console.log({ value });
		setSelected(value);
		setValue("tags", value);
	};

	useEffect(() => {
		if (annonce) {
			console.log({ annonce });
			const tags = [...annonce?.tags];
			setSelected(tags);
			setValue("tags", tags);
		}
	}, [annonce]);

	const onSubmit = (data: AnnonceEditFormData) => {
		const { images, slug, tags, ...rest } = data;
		// console.log(data);
		const formData = new FormData();
		if (user?.id) {
			formData.append("user", JSON.stringify(user.id));
		}
		if (images !== undefined && images?.length) {
			for (const image of images) {
				formData.append("images", image);
			}
		}
		if (tags) {
			formData.append("tags", JSON.stringify(tags));
		}
		for (let key of Object.keys(rest)) {
			const val = data[key];
			formData.append(key, val);
		}

		editAnnonce({ slug, formData });
	};
	return {
		register,
		errors,
		onSubmit: handleSubmit(onSubmit),
		setValue,
		isLoading,
		isSuccess,
		selected,
		handleChangeTags,
	};
};

export default UseEditAnnonceForm;
