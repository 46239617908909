// import Skeleton from "react-loading-skeleton";

import VolkenoSkeleton from "volkeno-react-skeleton";

function SectorTableSkelaton() {
  return (
		<div className="col-md-12">
			<table className="table table-striped">
				<thead>
					<tr>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							Nom
						</th>
						<th
							scope="col"
							style={{
								textAlign: "center",
								color: "#FFA73C",
								fontFamily: "Montserrat",
								fontSize: "14px",
								fontWeight: "700",
								verticalAlign: "middle",
							}}
						>
							actions
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
					<tr>
						<th scope="row" style={{ verticalAlign: "middle" }}>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</th>
						<td>
							<VolkenoSkeleton
								variant="rect"
								width={`100%`}
								height={20}
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
  );
}

export default SectorTableSkelaton;
