import { useEffect } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { BtnSubmit } from "../../../common/Button";
import ErrorMessage from "../../../common/ErrorMessage";
import useApplyForm from "./UseAnnonceForm/useApplyForm";

function ApplyForm({ project }) {
	const { register, onSubmit, errors, isLoading, setValue } = useApplyForm();
	const { user } = useAppSelector((state) => state.user);
	useEffect(() => {
		if (user?.id) {
			setValue("candidat", user?.id);
		}
	}, [user]);
	useEffect(() => {
		if (project) {
			setValue("project", project);
		}
	}, [project]);
	const onChangeCV = (e) => {
		setValue("cv", e.target.files[0]);
	};
	const onChangeMotivationLetter = (e) => {
		setValue("modification_letter", e.target.files[0]);
	};
	return (
		<div className="form-container p-2 p-xl-5">
			<form onSubmit={onSubmit}>
				<div className="row mb-4">
					<div className="col-md-12 mb-4 form-group">
						<label
							htmlFor="titre_candidat"
							className="espace-client-form-label"
						>
							Titre candidat
						</label>
						<input
							type="text"
							className="form-control espace-client-form-control"
							placeholder="Titre candidat"
							id="titre_candidat"
							{...register("titre_candidat")}
						/>
						{errors.titre_candidat?.message && (
							<ErrorMessage message={errors.titre_candidat.message} />
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label htmlFor="nom" className="espace-client-form-label">
							Nom candidat
						</label>
						<input
							type="text"
							className="form-control espace-client-form-control"
							placeholder="Nom candidat"
							id="nom"
							{...register("nom")}
						/>
						{errors.nom?.message && (
							<ErrorMessage message={errors.nom.message} />
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label htmlFor="prenom" className="espace-client-form-label">
							Prénom candidat
						</label>
						<input
							type="text"
							className="form-control espace-client-form-control"
							placeholder="Prénom candidat"
							id="prenom"
							{...register("prenom")}
						/>
						{errors.prenom?.message && (
							<ErrorMessage message={errors.prenom.message} />
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label htmlFor="adresse" className="espace-client-form-label">
							Adresse candidat
						</label>
						<input
							type="text"
							className="form-control espace-client-form-control"
							placeholder="Adresse candidat"
							id="adresse"
							{...register("adresse")}
						/>
						{errors.adresse?.message && (
							<ErrorMessage message={errors.adresse.message} />
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label htmlFor="phone" className="espace-client-form-label">
							Téléphone candidat
						</label>
						<input
							type="tel"
							className="form-control espace-client-form-control"
							placeholder="Téléphone candidat"
							id="phone"
							{...register("phone")}
						/>
						{errors.phone?.message && (
							<ErrorMessage message={errors.phone.message} />
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label
							htmlFor="motivation_letter"
							className="espace-client-form-label"
						>
							Lettre de motivation
						</label>
						<input
							type="file"
							className="form-control espace-client-form-control"
							id="motivation_letter"
							name="motivation_letter"
							onChange={onChangeMotivationLetter}
							multiple={false}
							accept=".pdf"
						/>
						<span className="text-warning">
							accepte uniquement que des fichiers .pdf
						</span>
						{errors.modification_letter?.message && (
							<ErrorMessage
								message={errors.modification_letter.message}
							/>
						)}
					</div>
					<div className="col-md-6 mb-4 form-group">
						<label htmlFor="cv" className="espace-client-form-label">
							Curriculum Vitae{" "}
						</label>
						<input
							type="file"
							className="form-control espace-client-form-control"
							id="cv"
							onChange={onChangeCV}
							multiple={false}
							accept=".pdf"
						/>
						<span className="text-warning">
							accepte uniquement que des fichiers .pdf
						</span>
						{errors.cv?.message && (
							<ErrorMessage message={errors.cv.message} />
						)}
					</div>
					<div className="col-md-4 form-group mb-4">
						<label
							htmlFor="pretention_salariale"
							className="espace-client-form-label"
						>
							Prétenton salariale
						</label>
						<input
							type="text"
							className="form-control espace-client-form-control"
							placeholder="Prétenton salariale"
							{...register("pretention_salariale")}
						/>
						{errors.pretention_salariale?.message && (
							<ErrorMessage
								message={errors.pretention_salariale.message}
							/>
						)}
					</div>
					<div className="col-md-2 form-group mb-4">
						<label
							htmlFor="level"
							className="espace-client-form-label"
							aria-labelledby="level"
						>
							Devise
						</label>
						<select
							className="select-form-custom form-select espace-client-form-control text-uppercase"
							id="level"
							data-testid="levelId"
							{...register("device")}
						>
							<option value="euro">€</option>
							<option value="FCFA">FCFA</option>
						</select>
						{errors.device?.message && (
							<ErrorMessage message={errors.device.message} />
						)}
					</div>
					<div className="col-md-6 form-group mb-4">
						<label
							htmlFor="experience_years"
							className="espace-client-form-label"
						>
							Années d’expériences
						</label>
						<input
							type="number"
							className="form-control espace-client-form-control"
							id="experience_years"
							placeholder="Années d’expériences"
							{...register("experience_years")}
						/>
						{errors.experience_years?.message && (
							<ErrorMessage message={errors.experience_years.message} />
						)}
					</div>
					<div className="col-md-6 form-group mb-4">
						<label
							htmlFor="level"
							className="espace-client-form-label"
							aria-labelledby="level"
						>
							Niveau d’étude et Diplôme
						</label>
						<select
							className="select-form-custom form-select espace-client-form-control text-uppercase"
							id="level"
							data-testid="levelId"
							{...register("level_study")}
						>
							<option value="cep">CEP</option>
							<option value="bepc">BEPC</option>
							<option value="bac">BAC</option>
							<option value="cap">CAP</option>
							<option value="licence">Licence</option>
							<option value="master">Master</option>
							<option value="doctorat">Doctorat</option>
							{/* <option value="cep">cèpes</option>
							<option value="bepc">casquette</option>
							<option value="bac">maître</option> */}
						</select>
						{errors.level_study?.message && (
							<ErrorMessage message={errors.level_study.message} />
						)}
					</div>
				</div>
				<div className="row">
					<div className="offset-md-6 col-md-6 mt-4">
						<BtnSubmit label="Postuler" isLoading={isLoading} />
					</div>
				</div>
			</form>
		</div>
	);
}

export default ApplyForm;
