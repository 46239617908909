import moment from "moment";
import { OrderType } from "../../../../utils/api/order/order.type";
import { getImage, useLocationState } from "../../../../utils/utils";
import { statusFormatter } from "../../Tables/Trader/Commandes/TraderCommandesTable";

const DetailAchat = () => {
	const item = useLocationState<OrderType>(null);
	console.log(item);
	return (
		<div className="mes-achats-tabs-component">
			<div className="bloc-title-page-tab pb-4">
				<div>
					<h6 className="titre-page-tab-content">Detail d'achat</h6>
				</div>
			</div>
			<div className="content-page-produit">
				<div className="row">
					<div className="col-md-7 table-container  table-responsive no-border">
						<table className="table table-hover ">
							<thead>
								<tr>
									<th scope="col">Produit</th>
									<th scope="col">Quantité</th>
									<th scope="col">Prix unitaire</th>
									<th scope="col">Prix total</th>
								</tr>
							</thead>
							<tbody>
								{item?.cartitems.map((elem, i) => (
									<tr key={i}>
										<th scope="row">
											<img
												src={getImage(
													elem?.item
														?.background_image
												)}
												alt="img-commande"
												className="img-40"
											/>
											<span className="article-com-name fw-400 ms-2">
												{elem?.item?.name}
											</span>
										</th>
										<td>{elem?.quantity}</td>
										<td>{elem?.price}FCFA</td>
										<td>
											{elem?.price * elem?.quantity}FCFA
										</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
					<div className="table-container no-border col-md-5">
						<table className="table pt-3 ">
							<thead>
								<tr>
									<th scope="col">Résumé de la commande</th>
								</tr>
							</thead>
							<tbody>
								<tr>
									<td className="flex-sb">
										<span>Date de création</span>
										<span className="fw-600">
											{moment(item?.order_date).format(
												"DD MMMM YYYY"
											)}{" "}
										</span>
									</td>
								</tr>
								<tr>
									<td className="flex-sb">
										<span>Heure</span>
										<span className="fw-600">
											{moment(item?.order_date).format(
												"HH:mm"
											)}
										</span>
									</td>
								</tr>
								<tr>
									<td className="flex-sb">
										<span>Prix total</span>
										<span className="fw-600">
											{item?.cart?.total} FCFA
										</span>
									</td>
								</tr>
								<tr>
									<td className="flex-sb">
										<span>Prix livraison</span>
										<span className="fw-600">
											{item?.delivery?.amount || "0"}{" "}
											FCFA
										</span>
									</td>
								</tr>
								<tr>
									<td className="flex-sb">
										<span>Payée</span>
										<span className="fw-600">
											{item?.paid ? "Oui" : "Non"}
										</span>
									</td>
								</tr>
								<tr>
									<td className="flex-sb">
										<span>Statut</span>
										<span className="fw-600">
											{statusFormatter(
												item?.status,
												item
											)}
										</span>
									</td>
								</tr>
							</tbody>
						</table>
						<div className="total-container flex-sb">
							<span className="fw-600">Total</span>
							<span className="fw-700">{item?.order_total} FCFA </span>
						</div>
						<div className="adresse-livraison mt-4">
							<p className="fw-400">Adresse de livraison</p>
							<address className="mt-2 mb-0 fw-600">
								{item?.shipping_address}
							</address>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default DetailAchat;
