import React from "react";
import { NavLink } from "react-router-dom";
import "./LoginAdmin.css";
import Logo from '../../../assets/appImages/logo.png'
import useLoginForm from "./useAdminForm/useLoginForm";
import ErrorMessage from "../../../common/ErrorMessage";
import { BtnSubmit } from "../../../common/Button";


interface FormProps {
	register: any;
	errors: any;
	onSubmit: any;
	isLoading: any;
}

export const LoginAdminForm: React.FC<FormProps> = ({ onSubmit, errors, register, isLoading }) => {
	// const { register, onSubmit, errors } = useLoginForm();
	return (
		<form id="login-form" onSubmit={onSubmit}>
			<div className="form-group my-4">
				<label className="espace-client-form-label" htmlFor="email">
					Email
				</label>
				<input
					name="email"
					id="email"
					type="email"
					className="form-control input-with-icon pl-4"
					placeholder="example@gmail.com"
					{...register("email")}
					data-testid="emailId"
				/>
				{errors.email?.message && <ErrorMessage message={errors.email.message} />}
			</div>
			<div className="form-group mt-4 position-relative">
				<label className="espace-client-form-label" htmlFor="password">
					Mot de passe
				</label>
				<input
					name="password"
					id="password"
					type="password"
					className="form-control input-with-icon pl-4"
					placeholder="************"
					{...register("password")}
					data-testid="passwordId"
				/>
				{errors.password?.message && <ErrorMessage message={errors.password.message} />}
			</div>
			<div className="dis-flex justify-content-end m-b-40">
				<NavLink to="/app/admin/mot-de-passe-oublie" className="mdpOublie">
					Mot de passe oublié ?
				</NavLink>
			</div>

			<div className="row submit-btn-row">
				<div className="col-md-12 submit-btn-col">
					{/* <NavLink to='/admin/tableau-de-bord'
              type="submit"
              className="btn-connexion"
              data-testid="btnId"
            >
              Connexion
            </NavLink> */}
					<BtnSubmit label="Connexion" isLoading={isLoading} />
				</div>
			</div>
		</form>
	);
};

const LoginAdmin = () => {
  const { register, onSubmit, errors, isLoading } = useLoginForm();
  return (
    <div className="login-component">
      <div className="">
        <div className="row login-page-row">
          <div className="col-md-4 offset-md-4 right-side-col">
            <div className="login-form-container">
              <div className="login-form-introduction d-flex justify-content-center">
                  <div className="pb-3">
                      <img src={Logo} alt="logo" className="img-logo" />
                  </div>
                <h1 className="login-title-label">
                  Connectez-vous
                </h1>
              </div>
              <LoginAdminForm
                register={register}
                onSubmit={onSubmit}
                errors={errors}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginAdmin;
