import React, { useState } from "react";
import { BtnBack, BtnCollapse, BtnNext, BtnSkip } from "../../../../common/Button";
import { CustomInput, FormError } from "../../../../common/CustomInput";
import { CustomSelectInput } from "../../../../common/CustomSelectInput";
import ErrorMessage from "../../../../common/ErrorMessage";
import { Status, TraderStepPropsType } from "./AddInfoTrader";
import GoogleInput from "../../../../common/GoogleInput";
import { CountrySelector, StateSelector, CitySelector } from "volkeno-react-country-state-city";
import { Profil } from "./AddInfoCompany";

const SummaryStep = ({
	navigation,
	register,
	formState,
	setValue,
	setAdress,
	adress,
	profil,
	setProfil,
	options,
	cityLabel,
	dataForm,
	country,
	setCountry,
	state,
	setState,
	city,
	setCity,
	user,
	isLoading,
	countryLabel,
	stateLabel,
}) => {
	return (
		<div>
			<h1 className="stepper-title">Résumé</h1>
			<SellerAccount
				setValue={setValue}
				register={register}
				formState={formState}
				user={user}
				dataForm={dataForm}
			/>
			<CompanyInfo
				setValue={setValue}
				register={register}
				formState={formState}
				setAdress={setAdress}
				adress={adress}
				profil={profil}
				setProfil={setProfil}
				options={options}
				cityLabel={cityLabel}
				dataForm={dataForm}
				country={country}
				setCountry={setCountry}
				state={state}
				setState={setState}
				city={city}
				setCity={setCity}
				countryLabel={countryLabel}
				stateLabel={stateLabel}
			/>
			<AccountBank register={register} formState={formState} />
			<div className="flex-sb mt-5">
				<div>
					<BtnBack label="Retour" type="button" navigation={navigation} />
				</div>
				<div className="flex-r gap-3">
					<BtnSkip label="Passer l’étape" type="button" />
					<BtnNext label="Inscription" type="submit" isLoading={isLoading} />
				</div>
			</div>
		</div>
	);
};

export function SellerAccount({ setValue, register, dataForm, user, formState }) {
	const [hideRightIcon, setHideRightIcon] = useState<boolean>(true);
	const toggleHideRigntIcon = () => {
		setHideRightIcon((prevState) => !prevState);
	};
	return (
		<div className="mb-3">
			<div className="mb-3">
				<BtnCollapse
					label="Compte Vendeur"
					onClick={toggleHideRigntIcon}
					id="compteVendeur"
					hideRightIcon={hideRightIcon}
				/>
			</div>
			<div className="collapse show" id="compteVendeur">
				<div className="row">
					<CustomInput
						label="Prénom et nom"
						name="fullname"
						type="text"
						readOnly={true}
						value={user?.firstname + " " + user?.lastname}
						error={formState?.errors?.author?.message}
					/>
					<CustomInput
						label="Nom d’affichage / Nom de la boutique"
						name="shop-name"
						{...register("name")}
						type="text"
						error={formState?.errors?.name?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="Adresse mail"
						name="email"
						type="email"
						{...register("email")}
						error={formState?.errors?.email?.message}
					/>
					<CustomInput
						label="N° téléphone"
						name="phone"
						type="tel"
						{...register("phone")}
						error={formState?.errors?.phone?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="Whatsapp / N° téléphone alternatif"
						name="whatsapp"
						type="tel"
						{...register("whatsapp")}
						error={formState?.errors?.whatsapp?.message}
					/>
					<div className="form-group mb-4 col-md-6">
						<label className="custom-form-label" aria-labelledby="accept-terms">
							Conditions générales
						</label>
						<div className="d-flex gap-2">
							<input
								type="checkbox"
								data-testid="acceptTerms"
								className="form-check-input form-custom-check-input"
								{...register("condition")}
							/>
							<div>
								<span className="custom-form-label my-0 ml-3    ">J’accepte</span>
								<span className="custom-form-label custom-form-check-label my-0">
									{" "}
									les conditions générales d’utilisation
								</span>
							</div>
						</div>
						{formState?.errors?.condition?.message && (
							<ErrorMessage message={formState?.errors?.condition?.message} />
						)}
					</div>
				</div>
				{/* <div className="row">
					<CustomInput label="Mot de passe" name="password" type="password" />
					<CustomInput
						label="Confirmer mot de passe"
						name="confirm-password"
						type="password"
					/>
				</div>
				<div className="row">
					<CustomSelectInput label="Statut" name="statut" options={Status} />
				</div> */}
			</div>
		</div>
	);
}

export function CompanyInfo({
	register,
	formState,
	setValue,
	setAdress,
	adress,
	profil,
	setProfil,
	options,
	cityLabel,
	dataForm,
	country,
	setCountry,
	state,
	setState,
	city,
	setCity,
	countryLabel,
	stateLabel,
}) {
	const [hideRightIcon, setHideRightIcon] = useState<boolean>(false);
	const toggleHideRigntIcon = () => {
		setHideRightIcon((prevState) => !prevState);
	};
	const handleCountrySelect = (option: any) => {
		setCountry(option);
		console.log("country", option);
		setValue("country", option.name);
	};

	const handleStateSelect = (option: any) => {
		setValue("region", option.name);
		setState(option);
	};

	const handleCitySelect = (option: any) => {
		setValue("city", option.name);
		setCity(option);
	};
	return (
		<div className="mb-3">
			<div className="mb-3">
				<BtnCollapse
					label="Informations d’affaires"
					onClick={toggleHideRigntIcon}
					id="companyInfo"
					hideRightIcon={hideRightIcon}
				/>
			</div>
			<div className="collapse" id="companyInfo">
				<div className="row">
					<CustomSelectInput
						label="Choisir une option"
						name="option"
						value={profil}
						onChange={(e) => {
							console.log("value", e.target.value);
							setProfil(e.target.value);
							if (e.target.value === "personne_morale") {
								setValue("isMoral", true);
							} else {
								setValue("isMoral", true);
							}
						}}
						options={Profil}
						error={formState?.errors?.profil?.message}
					/>
					<CustomInput
						label="Nom légale / Raison sociale"
						name="legale-name"
						type="text"
						{...register("legal_form")}
						error={formState?.errors?.legal_form?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="Le numéro d’Identificaion Nationale des Entreprises et des Associations
						(NINEA)"
						name="ninea"
						type="text"
						{...register("ninea")}
						error={formState?.errors?.ninea?.message}
					/>
					<CustomInput
						label="Quel est le nom du / des compte (s) ?"
						name="account-name"
						type="text"
						{...register("account_name")}
						error={formState?.errors?.account_name?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="Personne responsable"
						name="resp-people"
						type="text"
						{...register("person_in_charge")}
						error={formState?.errors?.person_in_charge?.message}
					/>
					<CustomInput
						label="Forme juridique"
						name="forme-juridique"
						type="text"
						{...register("legal_form")}
						error={formState?.errors?.legal_form?.message}
					/>
				</div>
				<div className="row">
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="location" className="custom-form-label">
							Adresse
						</label>
						<GoogleInput
							value={adress || ""}
							onChange={(value) => {
								setAdress(value.rue);
								setValue("location", value?.rue);
								setValue("longitude", value?.longitude);
								setValue("latitude", value?.latitude);
							}}
							className="form-control espace-client-form-control"
						/>
						{<FormError error={formState?.errors?.location?.message} />}
					</div>
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="pays" className="custom-form-label">
							Pays
						</label>
						<CountrySelector
							onChange={handleCountrySelect}
							name="country"
							placeholder={countryLabel || "Choisir un pays"}
							value={country}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.country?.message} />}
					</div>
				</div>
				<div className="row">
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="state" className="custom-form-label">
							Région
						</label>
						<StateSelector
							onChange={handleStateSelect}
							name="state"
							country={country}
							placeholder={stateLabel || "Choisir une région"}
							countryPlaceholder={stateLabel || "Choisir un pays"}
							value={state}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.region?.message} />}
					</div>
					<div className="col-md-6 form-group mb-4">
						<label htmlFor="state" className="custom-form-label">
							Ville
						</label>
						<CitySelector
							onChange={handleCitySelect}
							name="city"
							state={state}
							placeholder={cityLabel || "Choisir une ville"}
							statePlaceholder={cityLabel || "Choisir une région"}
							value={city}
							containerClass="tx country-select-container my-0 p-0"
						/>
						{<FormError error={formState?.errors?.city?.message} />}
					</div>
				</div>
				<div className="row">
					<CustomInput
						label="Code postale"
						name="postal_card"
						type="text"
						{...register("postal_card")}
						error={formState?.errors?.postal_card?.message}
					/>
					{/* <CustomInput
						label="Catégories principale de vos produits"
						name="categorie-principale"
						type="text"
					/> */}
					<CustomSelectInput
						label="Catégories principale de vos produits"
						name="category"
						options={options}
						{...register("category")}
						error={formState?.errors?.category?.message}
					/>
				</div>
				<div className="row">
					{/* <CustomSelectInput
						label="N° d’Enrégistrement des Entreprises"
						name="num-company-register"
						options={Status}
					/> */}
				</div>
			</div>
		</div>
	);
}

export function AccountBank({ register, formState }) {
	const [hideRightIcon, setHideRightIcon] = useState<boolean>(false);
	const toggleHideRigntIcon = () => {
		setHideRightIcon((prevState) => !prevState);
	};
	return (
		<div className="mb-3">
			<div className="mb-3">
				<BtnCollapse
					label="Compte bancaire"
					onClick={toggleHideRigntIcon}
					id="accountBank"
					hideRightIcon={hideRightIcon}
				/>
			</div>
			<div className="collapse" id="accountBank">
				<div className="row">
					<CustomInput
						label="Nom de votre banque"
						name="bank-name"
						type="text"
						{...register("bank_name")}
						error={formState?.errors?.bank_name?.message}
					/>
					<CustomInput
						label="Numéro de compte"
						name="account_number"
						type="number"
						{...register("account_number")}
						error={formState?.errors?.account_number?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="IBAN"
						name="iban"
						type="text"
						{...register("iban")}
						error={formState?.errors?.iban?.message}
					/>
					<CustomInput
						label="Nom du titulaire de compte"
						name="resp-account"
						type="text"
						{...register("account_owner")}
						error={formState?.errors?.account_owner?.message}
					/>
				</div>
				<div className="row">
					<CustomInput
						label="SWIFT"
						name="swift"
						type="text"
						{...register("swift")}
						error={formState?.errors?.swift?.message}
					/>
				</div>
			</div>
		</div>
	);
}
export default SummaryStep;
