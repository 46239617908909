import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { ApiBaseUrl } from "../../http";
import { prepareHeaders } from "../user/user.api";
import { IRatePartner, TRatePartnerData, TUpdateRatePartnerData } from "./ratePartner.type";

export const ratePartnerApi = createApi({
	reducerPath: "ratePartnerApi",
	tagTypes: ["ratePartner"],
	baseQuery: fetchBaseQuery({
		baseUrl: `${ApiBaseUrl}api/`,
		prepareHeaders,
	}),
	endpoints: (builder) => ({
		addRatePartner: builder.mutation<TRatePartnerData, any>({
			query: (data) => ({
				url: "rate_partner/",
				method: "POST",
				body: data,
			}),
			invalidatesTags: ["ratePartner"],
		}),
		updateRatePartner: builder.mutation<TUpdateRatePartnerData, IRatePartner | any>({
			query: ({ slug, ...data }) => ({
				url: `rate_partner/${slug}/`,
				method: "PUT",
				body: data,
			}),
			invalidatesTags: ["ratePartner"],
		}),
	}),
});

export const { useAddRatePartnerMutation, useUpdateRatePartnerMutation } = ratePartnerApi;
