import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import 'react-datepicker/dist/react-datepicker.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { Provider } from 'react-redux'
import store from './redux/store'
import { fr } from 'yup-locales'
import { setLocale } from 'yup'
import 'moment/locale/fr'
import 'react-quill/dist/quill.snow.css'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import { BrowserRouter } from 'react-router-dom'
setLocale(fr)

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
