import { useState } from "react";
import { useAppSelector } from "../../../../redux/hooks";
import { isPrestataire } from "../../../../routes/router";
import { useLocationState } from "../../../../utils/utils";
import Budgets from "./ParametresTabs/Budgets";
import ChangePassword from "./ParametresTabs/ChangePassword";
import EditProfilPro from "./ParametresTabs/EditProfilPro";
import InformationsPersonnelles from "./ParametresTabs/InformationsPersonnelles";

const ParametreTabs = () => {
	const index = useLocationState<number>(undefined);
	const [active, setActive] = useState<number>(index || 0);
	const { user } = useAppSelector((state) => state.user);
	const is_prestataire = isPrestataire(user);
	return (
		<div className="parametres-tabs-component">
			<div className="row">
				<ul className="params-tabs-container">
					<li
						role="button"
						className={`param-tab ${active === 0 && "active"}`}
						onClick={() => setActive(0)}
					>
						<span>Profil</span>
					</li>
					{is_prestataire && (
						<>
							<li
								role="button"
								className={`param-tab ${active === 1 && "active"}`}
								onClick={() => setActive(1)}
							>
								<span>Profil pro</span>
							</li>
							<li
								role="button"
								className={`param-tab ${active === 2 && "active"}`}
								onClick={() => setActive(2)}
							>
								<span>Budgets</span>
							</li>
						</>
					)}
				</ul>
				<div className="tabs-container">
					{active === 0 && <InformationsPersonnelles />}
					{active === 0 && <ChangePassword />}
					{active === 1 && <EditProfilPro />}
					{active === 2 && <Budgets />}
				</div>
			</div>
		</div>
	);
};
export default ParametreTabs;
