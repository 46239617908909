import React from 'react'
import './MyCustumerArea.css';
interface PropsType {
	label: string;
}
const TitlePage: React.FC<PropsType> = ({ label }) => {
    return (
        <div className='title-page-component'>
            <div className='text-center'>
                <h2 className='title-page-custumer-area'>{ label }</h2>
            </div>
        </div>
    )
}
export default TitlePage