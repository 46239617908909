// import Skeleton from "react-loading-skeleton";

import VolkenoSkeleton from "volkeno-react-skeleton";

function CategorieTableSkelaton() {
  return (
    <div className="col-md-12">
      <table className="table table-striped">
        <thead>
          <tr>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Nom
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Sous-catégories
            </th>
            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              Position
            </th>

            <th
              scope="col"
              style={{
                textAlign: "center",
                color: "rgba(0, 0, 0, 0.7)",
                fontFamily: "Montserrat",
                fontSize: "14px",
                fontWeight: "700",
              }}
            >
              actions
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <th scope="row" style={{ verticalAlign: "middle" }}>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </th>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>{" "}
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ verticalAlign: "middle" }}>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </th>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>{" "}
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ verticalAlign: "middle" }}>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </th>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>{" "}
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
          </tr>
          <tr>
            <th scope="row" style={{ verticalAlign: "middle" }}>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </th>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>{" "}
            <td>
              <VolkenoSkeleton variant="rect" width={`100%`} height={20} />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default CategorieTableSkelaton;
