import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Color } from "../../../../utils/theme";

export function useDelete<T>(props: {
	item: T | any;
	deleteItem: (slug) => void;
	isSuccess: boolean;
	isError: boolean;
	error: any;
	question: string;
	successMessage: string;
	redirectUrl?: string;
}) {
	const { item, deleteItem, isSuccess, isError, error, question, successMessage, redirectUrl } =
		props;
	const navigate = useNavigate();
	const onDelete = useCallback(() => {
		Swal.fire({
			title: `Êtes vous sûr de vouloir supprimer ${question}`,
			icon: "question",
			showCancelButton: true,
			confirmButtonText: "Oui",
			cancelButtonText: "Non",
			showLoaderOnConfirm: true,
			focusCancel: true,
			customClass: {
				confirmButton: "swal-custom-btn mx-2",
				cancelButton: "swal-custom-btn mx-2",
			},
			preConfirm: () => {
				return deleteItem(item.slug);
			},
		}).then((result) => {
			if (result.isConfirmed && redirectUrl) {
				navigate(redirectUrl);
			}
		});
	}, []);
	useEffect(() => {
		if (isSuccess) {
			Swal.fire({
				icon: "success",
				title: `${successMessage} avec succès`,
				iconColor: Color.themeColor,
				showConfirmButton: false,
				timer: 1500,
			});
		}
	}, [isSuccess]);

	useEffect(() => {
		if (isError) {
			const err = error as any;
			Swal.fire({
				icon: "error",
				title: `Une erreur de status ${err?.status} est survenue!`,
				showConfirmButton: false,
				timer: 2000,
			});
		}
	}, [isError]);

	return onDelete;
}
