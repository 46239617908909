import { yupResolver } from '@hookform/resolvers/yup'
import { useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import Swal from 'sweetalert2'
import * as yup from 'yup'
import { useAddConditionMutation } from '../../../../../utils/api/conditions/condition.api'
import { ConditionFormData } from '../../../../../utils/api/conditions/condition.type'
import { Color } from '../../../../../utils/theme'
import { onHide } from '../../../../../utils/utils'

function UseAddConditionForm() {
  const validationSchema = useMemo(
    () =>
      yup.object().shape({
        text: yup.string().required('Le contenu est requis.'),
        type: yup.string().required(),
      }),
    [],
  )

  const {
    register,
    handleSubmit,
    formState,
    reset,
    clearErrors,
    setValue,
    setError,
  } = useForm<ConditionFormData | any>({
    resolver: yupResolver(validationSchema),
  })
  const [
    addData,
    { isError, isSuccess, isLoading, data, error },
  ] = useAddConditionMutation()

  useEffect(() => {
    if (isSuccess) {
      onHide('AddCguModal')
      Swal.fire({
        icon: 'success',
        title: 'Contenu ajouté avec succès',
        iconColor: Color.themeColor,
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      console.log('error', error)
      const err = error as any

      Swal.fire({
        icon: 'error',
        title: `${
          err?.data?.message
            ? err?.data?.message
            : `Une erreur de status ${err?.status} est survenue!`
        }`,
        showConfirmButton: false,
        timer: 2000,
      })
    }
  }, [isError])

  const onSubmit = async (formValues: ConditionFormData) => {
    console.log(formValues)

    await addData(formValues)
  }
  return {
    register,
    formState,
    onSubmit: handleSubmit(onSubmit),
    clearErrors,
    setValue,
    reset,
    isLoading,
    isSuccess,
  }
}

export default UseAddConditionForm
