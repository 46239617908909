import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import DropZoneImage from "../../../shared/DropzoneImage";
import { CKEditor } from "ckeditor4-react";
import { AiOutlineCloseCircle } from "react-icons/ai";
import { IoMdAddCircleOutline } from "react-icons/io";
import { BtnSubmit } from "../../../common/Button";

const AjouterProduitEspaceClient = () => {
	const [inputFields, setInputFields] = useState([{ categorie: "" }]);
	// handle input change
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputFields];
		list[index][name] = value;
		setInputFields(list);
	};

	// handle click event of the Remove button
	const handleRemoveClick = (index) => {
		const list = [...inputFields];
		list.splice(index, 1);
		setInputFields(list);
	};

	// handle click event of the Add button
	const handleAddClick = () => {
		setInputFields([...inputFields, { categorie: "" }]);
	};
	const handleChangeImage = () => {};
	return (
		<>
			<div className="mb-4 ps-3">
				<nav aria-label="breadcrumb">
					<ol className="breadcrumb">
						<li className="breadcrumb-item">
							<NavLink
								to="/mes-achats"
								className="breadcrumb-item-costum"
							>
								Mes produits{" "}
							</NavLink>
						</li>
						{/* <li
							className="breadcrumb-item active breadcrumb-item-costum"
							aria-current="page"
						>
							Ajouter un produit
						</li> */}
					</ol>
				</nav>
			</div>
			<div className="page-add-project-container">
				<div className="form-container px-3">
					<form>
						<div className="row mb-4">
							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="title-mission"
									className="espace-client-form-label"
								>
									Nom du produit
								</label>
								<input
									type="text"
									className="form-control espace-client-form-control"
									placeholder="Nom du produit"
									id="title-projet"
								/>
							</div>

							<div className="col-md-12 mb-4 form-group">
								<label
									htmlFor="desc-mision"
									className="espace-client-form-label"
								>
									Description du produit
								</label>
								<CKEditor initData={"Ajouter du contenu"} />
							</div>
							<div className="col-md-12 mb-4">
								<DropZoneImage
									label="Image du produit"
									id="annonces-images"
									onChange={handleChangeImage}
								/>
							</div>
							{inputFields.map((x, i) => {
								return (
									<div className="col-md-12 form-group mb-4">
										<label
											htmlFor="categorie"
											className="espace-client-form-label"
										>
											Catégorie du produit
										</label>
										<input
											name="categorie"
											className="form-control espace-client-form-control"
											value={x.categorie}
											onChange={(e) =>
												handleInputChange(e, i)
											}
										/>
										<div className="btn-box mt-3">
											{inputFields.length !== 1 && (
												<button
													className="btn-add-element-product btn"
													onClick={() =>
														handleRemoveClick(
															i
														)
													}
												>
													<AiOutlineCloseCircle />{" "}
													Supprimer
												</button>
											)}
											{inputFields.length - 1 === i && (
												<button
													onClick={
														handleAddClick
													}
													className="btn btn-add-element-product"
												>
													<IoMdAddCircleOutline />{" "}
													Ajouter un autre
													élèment
												</button>
											)}
										</div>
									</div>
								);
							})}
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="prix"
									className="espace-client-form-label"
								>
									Prix du produit
								</label>
								<input
									type="number"
									className="form-control espace-client-form-control"
									placeholder="Prix du produit"
									id="prix"
								/>
							</div>
							<div className="col-md-6 form-group mb-4">
								<label
									htmlFor="nombre_stock"
									className="espace-client-form-label"
								>
									Nombre de produit en stock
								</label>
								<input
									type="number"
									className="form-control espace-client-form-control"
									placeholder="Nombre de produit en stock"
									id="nombre_stock"
								/>
							</div>
							<div className="offset-md-6 col-md-6">
								<BtnSubmit label="Ajouter" isLoading={false} />
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AjouterProduitEspaceClient;
