import React from "react";
import { getImage } from "../../../utils/utils";
import { FaFacebookF, FaTwitter } from "react-icons/fa";
import { FiLinkedin } from "react-icons/fi";
import VolkenoSkeleton from "volkeno-react-skeleton";
import { ITeam } from "../../../utils/api/about/about.type";

const Team = ({ team }: { team: ITeam[] }) => {
	return (
		<section className="team-section pt-5 mb-5">
			<h2 className="about-section-title mb-5">Notre equipe</h2>
			<div className="row">
				{team?.map((member) => (
					<div className="col-md-4 col-xl-3  px-xxl-4 mb-3" key={member.slug}>
						<div className="member-card">
							<div className="member-img-container">
								<img
									src={getImage(member?.image)}
									alt={member.nom}
									className="w-100"
									style={{
										maxHeight: "376px",
										minHeight: "376px",
									}}
								/>
							</div>
							<div className="member-infos-socials d-flex p-2">
								<div className="member-infos flex-fill">
									<h5 className="member-name">{member.nom}</h5>
									<h6 className="member-function">
										{member.fonction}
									</h6>
								</div>
								<div className="member-socials position-relative">
									<div className="social-link-container d-flex flex-column p-2 gap-2 position-absolute">
										<a
											href={member.facebook}
											target="_blank"
											rel="noopener noreferrer"
											className="social-link"
										>
											<FaFacebookF />
										</a>
										<a
											href={member.twitter}
											target="_blank"
											rel="noopener noreferrer"
											className="social-link"
										>
											<FaTwitter />
										</a>
										<a
											href={member.linkedin}
											target="_blank"
											rel="noopener noreferrer"
											className="social-link"
										>
											<FiLinkedin />
										</a>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</section>
	);
};

export function TeamItemSkeleton() {
	return (
		<div className="col-md-4 col-xl-3  px-xxl-4 mb-3">
			<div className="member-card">
				<VolkenoSkeleton variant="rect" width="100%" height="350px" />
				<div className="member-infos-socials d-flex p-2">
					<div className="member-infos flex-fill">
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
						<VolkenoSkeleton variant="rect" width="100%" height={10} />
					</div>
					<div className="member-socials position-relative">
						<div className="social-link-container d-flex flex-column p-2 position-absolute">
							<VolkenoSkeleton
								variant="rect"
								width="100%"
								height={20}
							/>
							<VolkenoSkeleton
								variant="rect"
								width="100%"
								height={20}
							/>
							<VolkenoSkeleton variant="rect" width={14} height={20} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
}

export default Team;
