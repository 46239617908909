export interface IAbonnnement {
	id: number;
	slug: string;
	title: string;
	price: number;
	description: string;
	duration: number;
	created_at: string;
}

export interface IOffer {
	id: number;
	slug: string;
	duree: number;
	prix: number;
	type_boost: TypeBoost;
	description: number;
	created_at: string;
}
export enum TypeBoost {
	simple = "simple",
	reseaux = "reseaux",
	video_reseau = "video_reseau",
}

export type AbonnementFormData = Partial<IAbonnnement>;
export type OfferFormData = Partial<IOffer>;

