import React from "react";

interface StepperPropsType {
	index: number;
}

const StepperHorizontal: React.FC<StepperPropsType> = ({ index }) => {
	return (
		<div className="d-flex justify-content-end mb-4">
			<div className="stepper-horizontal-wrapper d-flex gap-3">
				{[...Array(4)].map((elem, key) => (
					<div
						className={`stepper-item ${
							index === key || index > key
								? "active"
								: ""
						}`}
						key={key}
					></div>
				))}
			</div>
		</div>
	);
};

export default StepperHorizontal;
