export const IconDelete = () => {
	return (
		<svg
			width="17"
			height="17"
			viewBox="0 0 17 17"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect x="4.45508" y="5.92969" width="8.71111" height="6.84444" stroke="#65635F" />
			<path d="M7.5 7.79757L10.1667 7.77535" stroke="#65635F" strokeLinecap="square" />
			<rect x="3.83301" y="3.44141" width="9.95555" height="2.48889" stroke="#65635F" />
		</svg>
	);
};

export const IconHeart = () => {
	return (
		<svg
			width="20"
			height="18"
			viewBox="0 0 20 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M10 18L8.55 16.7052C3.4 12.1243 0 9.103 0 5.3951C0 2.37384 2.42 0 5.5 0C7.24 0 8.91 0.794551 10 2.05014C11.09 0.794551 12.76 0 14.5 0C17.58 0 20 2.37384 20 5.3951C20 9.103 16.6 12.1243 11.45 16.715L10 18Z"
				fill="#FFA73C"
			/>
		</svg>
	);
};

export const IconWallet = () => {
	return (
		<svg
			width="28"
			height="27"
			viewBox="0 0 28 27"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M26.2988 17.3424H20.9011C18.9192 17.3412 17.3127 15.6858 17.3115 13.6419C17.3115 11.598 18.9192 9.94266 20.9011 9.94141H26.2988"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M21.5117 13.5563H21.0961"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M7.77716 1.67188H19.3017C23.1659 1.67188 26.2986 4.90245 26.2986 8.88741V18.7558C26.2986 22.7408 23.1659 25.9714 19.3017 25.9714H7.77716C3.91295 25.9714 0.780273 22.7408 0.780273 18.7558V8.88741C0.780273 4.90245 3.91295 1.67188 7.77716 1.67188Z"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M6.82812 7.91172H14.0267"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IconSend = () => {
	return (
		<svg
			width="28"
			height="28"
			viewBox="0 0 28 28"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.2484 8.7134L11.4607 16.5532L2.34315 10.9414C1.14843 10.2059 1.39059 8.39146 2.73648 8.00082L24.2628 1.7371C25.4869 1.38283 26.6188 2.5305 26.2522 3.76035L19.8652 25.2644C19.4652 26.6101 17.6711 26.8432 16.946 25.6416L11.4607 16.5532"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IconChatDots = () => {
	return (
		<svg
			width="30"
			height="30"
			viewBox="0 0 30 30"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.02866 16.961C8.14211 16.961 7.42188 16.2407 7.42188 15.3542C7.42188 14.4702 8.14211 13.75 9.02866 13.75C9.91521 13.75 10.6354 14.4702 10.6354 15.3542C10.6354 16.2407 9.91521 16.961 9.02866 16.961ZM15.2751 16.961C14.3885 16.961 13.6683 16.2407 13.6683 15.3542C13.6683 14.4702 14.3885 13.75 15.2751 13.75C16.1616 13.75 16.8819 14.4702 16.8819 15.3542C16.8819 16.2407 16.1616 16.961 15.2751 16.961ZM19.9149 15.3542C19.9149 16.2407 20.6351 16.961 21.5216 16.961C22.4082 16.961 23.1284 16.2407 23.1284 15.3542C23.1284 14.4702 22.4082 13.75 21.5216 13.75C20.6351 13.75 19.9149 14.4702 19.9149 15.3542Z"
				fill="#200E32"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M14.9748 1.29688C6.98602 1.29688 1.19727 7.82279 1.19727 15.0675C1.19727 17.381 1.87102 19.7619 3.05352 21.9377C3.27352 22.2971 3.30102 22.7502 3.14977 23.1784L2.22852 26.2631C2.02227 27.0067 2.65477 27.5561 3.35602 27.3358L6.13352 26.5109C6.88977 26.2631 7.48102 26.5784 8.18227 27.0067C10.1898 28.1896 12.6923 28.7969 14.9473 28.7969C21.7673 28.7969 28.6973 23.5227 28.6973 15.0262C28.6973 7.69885 22.7848 1.29688 14.9748 1.29688Z"
				stroke="#200E32"
				strokeWidth="1.5"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export const IconArrowFillRight = () => {
	return (
		<svg
			width="8"
			height="10"
			viewBox="0 0 8 10"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M0.34375 0.34375L7.65625 5L0.34375 9.65625V0.34375Z" fill="#666666" />
		</svg>
	);
};
export const IconArrowFillDown = () => {
	return (
		<svg
			width="10"
			height="8"
			viewBox="0 0 10 8"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M9.65625 0.34375L5 7.65625L0.34375 0.34375L9.65625 0.34375Z"
				fill="#666666"
			/>
		</svg>
	);
};

export const IconCheck = () => {
	return (
		<svg
			width="18"
			height="14"
			viewBox="0 0 18 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M6 11.1719L16.5938 0.578125L18 1.98438L6 13.9844L0.421875 8.40625L1.82812 7L6 11.1719Z"
				fill="#21A374"
			/>
		</svg>
	);
};
export const IconClose = () => {
	return (
		<svg
			width="14"
			height="14"
			viewBox="0 0 14 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M13.9844 1.42188L8.40625 7L13.9844 12.5781L12.5781 13.9844L7 8.40625L1.42188 13.9844L0.015625 12.5781L5.59375 7L0.015625 1.42188L1.42188 0.015625L7 5.59375L12.5781 0.015625L13.9844 1.42188Z"
				fill="#CD1010"
			/>
		</svg>
	);
};

export const IconDelete2 = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M9 9H10.5V18H9V9Z" fill="#B7B1B1" />
			<path d="M13.5 9H15V18H13.5V9Z" fill="#B7B1B1" />
			<path
				d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
				fill="#B7B1B1"
			/>
			<path d="M9 1.5H15V3H9V1.5Z" fill="#B7B1B1" />
		</svg>
	);
};

export const IconDeleteDanger = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M9 9H10.5V18H9V9Z" fill="#FF0000" />
			<path d="M13.5 9H15V18H13.5V9Z" fill="#FF0000" />
			<path
				d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
				fill="#FF0000"
			/>
			<path d="M9 1.5H15V3H9V1.5Z" fill="#FF0000" />
		</svg>
	);
};
export const IconEdit = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M20.7188 7.03125L18.8906 8.85938L15.1406 5.10938L16.9688 3.28125C17.1562 3.09375 17.3906 3 17.6719 3C17.9531 3 18.1875 3.09375 18.375 3.28125L20.7188 5.625C20.9062 5.8125 21 6.04688 21 6.32812C21 6.60938 20.9062 6.84375 20.7188 7.03125ZM3 17.25L14.0625 6.1875L17.8125 9.9375L6.75 21H3V17.25Z"
				fill="#C8C8C8"
			/>
		</svg>
	);
};

export const IconDelete3 = () => {
	return (
		<svg
			width="24"
			height="24"
			viewBox="0 0 24 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M9 9H10.5V18H9V9Z" fill="#F42D2D" />
			<path d="M13.5 9H15V18H13.5V9Z" fill="#F42D2D" />
			<path
				d="M3 4.5V6H4.5V21C4.5 21.3978 4.65804 21.7794 4.93934 22.0607C5.22064 22.342 5.60218 22.5 6 22.5H18C18.3978 22.5 18.7794 22.342 19.0607 22.0607C19.342 21.7794 19.5 21.3978 19.5 21V6H21V4.5H3ZM6 21V6H18V21H6Z"
				fill="#F42D2D"
			/>
			<path d="M9 1.5H15V3H9V1.5Z" fill="#F42D2D" />
		</svg>
	);
};

export const IconImage = () => {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17 0C18.6569 0 20 1.34315 20 3V17C20 18.6569 18.6569 20 17 20H3C1.34315 20 0 18.6569 0 17V3C0 1.34315 1.34315 0 3 0H17ZM18 10.302L13.8321 16.5547C13.5243 17.0163 12.9063 17.1338 12.454 16.838L12.3598 16.7682L7.1875 12.458L3.228 18H17C17.5523 18 18 17.5523 18 17V10.302ZM17 2H3C2.44772 2 2 2.44772 2 3V16.278L6.18627 10.4188C6.50017 9.9793 7.09949 9.87371 7.54124 10.159L7.64018 10.2318L12.7853 14.5193L18 6.696V3C18 2.44772 17.5523 2 17 2ZM6 4C7.10457 4 8 4.89543 8 6C8 7.10457 7.10457 8 6 8C4.89543 8 4 7.10457 4 6C4 4.89543 4.89543 4 6 4Z"
				fill="black"
			/>
		</svg>
	);
};
export const IconChat = () => {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M11.3738 2.32033C9.98767 2.31942 8.61984 2.63675 7.37571 3.24786C6.13157 3.85898 5.04434 4.74756 4.19779 5.84513C3.35124 6.94271 2.76797 8.21999 2.49291 9.57854C2.21786 10.9371 2.25838 12.3407 2.61133 13.6811C2.96429 15.0215 3.62026 16.263 4.52871 17.31C5.43717 18.3569 6.57384 19.1813 7.85115 19.7196C9.12846 20.2579 10.5123 20.4958 11.8961 20.415C13.2798 20.3341 14.6266 19.9367 15.8325 19.2532L19.2372 20.3878C19.3297 20.4171 19.4263 20.4314 19.5233 20.4304C19.6667 20.4303 19.808 20.3961 19.9357 20.3307C20.0633 20.2653 20.1735 20.1705 20.2574 20.0542C20.3412 19.9378 20.3962 19.8032 20.4178 19.6614C20.4394 19.5197 20.4271 19.3748 20.3817 19.2388L19.2517 15.8341C20.0344 14.4572 20.4418 12.899 20.4331 11.3153C20.4245 9.73154 20.0002 8.17784 19.2026 6.8096C18.4049 5.44136 17.262 4.30656 15.8881 3.51873C14.5142 2.73089 12.9575 2.31764 11.3738 2.32033ZM17.3981 16.0124L18.0917 18.0951L16.0091 17.4015C15.882 17.3582 15.7468 17.3438 15.6135 17.3595C15.4802 17.3751 15.352 17.4204 15.2385 17.492C13.7282 18.4478 11.9186 18.8131 10.1558 18.518C8.39309 18.2229 6.80107 17.2882 5.68446 15.8926C4.56785 14.4971 4.00508 12.7388 4.10384 10.9542C4.20261 9.16969 4.95598 7.48424 6.21978 6.22045C7.48357 4.95666 9.16902 4.20328 10.9536 4.10452C12.7381 4.00575 14.4964 4.56852 15.8919 5.68513C17.2875 6.80174 18.2223 8.39376 18.5174 10.1565C18.8125 11.9192 18.4472 13.7289 17.4914 15.2391C17.4193 15.353 17.3735 15.4815 17.3573 15.6153C17.3412 15.749 17.3551 15.8847 17.3981 16.0124Z"
				fill="#8E8EA1"
			/>
			<path
				d="M14.9958 8.20312H7.75182C7.51166 8.20312 7.28134 8.29853 7.11153 8.46834C6.94171 8.63816 6.84631 8.86847 6.84631 9.10863C6.84631 9.34878 6.94171 9.5791 7.11153 9.74892C7.28134 9.91873 7.51166 10.0141 7.75182 10.0141H14.9958C15.236 10.0141 15.4663 9.91873 15.6361 9.74892C15.8059 9.5791 15.9014 9.34878 15.9014 9.10863C15.9014 8.86847 15.8059 8.63816 15.6361 8.46834C15.4663 8.29853 15.236 8.20312 14.9958 8.20312ZM11.3738 12.7306H7.75182C7.51166 12.7306 7.28134 12.826 7.11153 12.9959C6.94171 13.1657 6.84631 13.396 6.84631 13.6361C6.84631 13.8763 6.94171 14.1066 7.11153 14.2764C7.28134 14.4462 7.51166 14.5417 7.75182 14.5417H11.3738C11.614 14.5417 11.8443 14.4462 12.0141 14.2764C12.1839 14.1066 12.2793 13.8763 12.2793 13.6361C12.2793 13.396 12.1839 13.1657 12.0141 12.9959C11.8443 12.826 11.614 12.7306 11.3738 12.7306Z"
				fill="#8E8EA1"
			/>
		</svg>
	);
};

export const IconNotif = () => {
	return (
		<svg
			width="23"
			height="23"
			viewBox="0 0 23 23"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M12.7227 17.8278H10.6854C10.21 17.8278 9.8025 18.2353 9.8025 18.7107C9.8025 19.1861 10.21 19.5935 10.6854 19.5935H12.7227C13.1981 19.5935 13.6056 19.1861 13.6056 18.7107C13.6056 18.2353 13.1981 17.8278 12.7227 17.8278ZM17.7483 15.7225H17.205V10.4253C17.205 7.64089 15.1676 5.39977 12.519 4.99229V4.10943C12.519 3.63404 12.1794 3.22656 11.7041 3.22656C11.2287 3.22656 10.8212 3.63404 10.8212 4.10943V4.99229C8.17259 5.39977 6.13521 7.70881 6.13521 10.4253V15.7225H5.72773C5.25234 15.7225 4.91278 16.13 4.91278 16.6054C4.91278 17.0808 5.32026 17.4882 5.79565 17.4882H7.08599H16.458H17.8162C18.2916 17.4882 18.6991 17.0808 18.6991 16.6054C18.6991 16.13 18.2237 15.7225 17.7483 15.7225ZM7.83303 15.7225V10.4253C7.83303 8.32002 9.53085 6.6222 11.6361 6.6222C13.7414 6.6222 15.4393 8.32002 15.4393 10.4253V15.7225H7.83303Z"
				fill="#8E8EA1"
			/>
		</svg>
	);
};

export function IconCalendar() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M17.9167 3.33405H15.6944V5.00072C15.6944 5.16122 15.6628 5.32015 15.6014 5.46844C15.54 5.61673 15.45 5.75146 15.3365 5.86496C15.223 5.97845 15.0882 6.06848 14.9399 6.1299C14.7917 6.19132 14.6327 6.22294 14.4722 6.22294C14.3117 6.22294 14.1528 6.19132 14.0045 6.1299C13.8562 6.06848 13.7215 5.97845 13.608 5.86496C13.4945 5.75146 13.4045 5.61673 13.343 5.46844C13.2816 5.32015 13.25 5.16122 13.25 5.00072V3.33405H6.77777V5.00072C6.77777 5.32487 6.649 5.63575 6.41979 5.86496C6.19058 6.09417 5.8797 6.22294 5.55555 6.22294C5.2314 6.22294 4.92052 6.09417 4.69131 5.86496C4.4621 5.63575 4.33333 5.32487 4.33333 5.00072V3.33405H2.1111C1.9789 3.33254 1.84773 3.35757 1.72536 3.40764C1.60299 3.45771 1.4919 3.53181 1.39867 3.62556C1.30543 3.71931 1.23195 3.83081 1.18256 3.95345C1.13316 4.0761 1.10887 4.20741 1.1111 4.3396V16.7729C1.1089 16.9028 1.1323 17.0318 1.17996 17.1527C1.22763 17.2735 1.29863 17.3837 1.38892 17.4771C1.4792 17.5705 1.58699 17.6452 1.70614 17.6969C1.82529 17.7486 1.95346 17.7763 2.08333 17.7785H17.9167C18.0465 17.7763 18.1747 17.7486 18.2938 17.6969C18.413 17.6452 18.5208 17.5705 18.6111 17.4771C18.7014 17.3837 18.7724 17.2735 18.82 17.1527C18.8677 17.0318 18.8911 16.9028 18.8889 16.7729V4.3396C18.8911 4.20974 18.8677 4.08071 18.82 3.95988C18.7724 3.83906 18.7014 3.72881 18.6111 3.63543C18.5208 3.54205 18.413 3.46738 18.2938 3.41566C18.1747 3.36395 18.0465 3.33622 17.9167 3.33405ZM5.55555 14.4452H4.44444V13.334H5.55555V14.4452ZM5.55555 11.6674H4.44444V10.5563H5.55555V11.6674ZM5.55555 8.88961H4.44444V7.77849H5.55555V8.88961ZM8.88888 14.4452H7.77777V13.334H8.88888V14.4452ZM8.88888 11.6674H7.77777V10.5563H8.88888V11.6674ZM8.88888 8.88961H7.77777V7.77849H8.88888V8.88961ZM12.2222 14.4452H11.1111V13.334H12.2222V14.4452ZM12.2222 11.6674H11.1111V10.5563H12.2222V11.6674ZM12.2222 8.88961H11.1111V7.77849H12.2222V8.88961ZM15.5556 14.4452H14.4444V13.334H15.5556V14.4452ZM15.5556 11.6674H14.4444V10.5563H15.5556V11.6674ZM15.5556 8.88961H14.4444V7.77849H15.5556V8.88961Z"
				fill="black"
				fillOpacity="0.6"
			/>
			<path
				d="M5.55556 5.55577C5.7029 5.55577 5.84421 5.49724 5.94839 5.39305C6.05258 5.28887 6.11111 5.14756 6.11111 5.00022V1.66688C6.11111 1.51954 6.05258 1.37823 5.94839 1.27405C5.84421 1.16986 5.7029 1.11133 5.55556 1.11133C5.40821 1.11133 5.26691 1.16986 5.16272 1.27405C5.05853 1.37823 5 1.51954 5 1.66688V5.00022C5 5.14756 5.05853 5.28887 5.16272 5.39305C5.26691 5.49724 5.40821 5.55577 5.55556 5.55577Z"
				fill="black"
				fillOpacity="0.6"
			/>
			<path
				d="M14.4444 5.55577C14.5918 5.55577 14.7331 5.49724 14.8373 5.39305C14.9415 5.28887 15 5.14756 15 5.00022V1.66688C15 1.51954 14.9415 1.37823 14.8373 1.27405C14.7331 1.16986 14.5918 1.11133 14.4444 1.11133C14.2971 1.11133 14.1558 1.16986 14.0516 1.27405C13.9474 1.37823 13.8889 1.51954 13.8889 1.66688V5.00022C13.8889 5.14756 13.9474 5.28887 14.0516 5.39305C14.1558 5.49724 14.2971 5.55577 14.4444 5.55577Z"
				fill="black"
				fillOpacity="0.6"
			/>
		</svg>
	);
}
export function IconStar() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M19.2836 7.27657L13.3328 6.41173L10.6727 1.01876C10.6 0.871102 10.4805 0.751571 10.3328 0.678915C9.9625 0.496102 9.5125 0.648446 9.32734 1.01876L6.66718 6.41173L0.716404 7.27657C0.552342 7.30001 0.402342 7.37735 0.287498 7.49454C0.148658 7.63724 0.0721514 7.82923 0.0747883 8.02831C0.0774251 8.22739 0.15899 8.41728 0.301561 8.55626L4.60703 12.7539L3.58984 18.6813C3.56599 18.8191 3.58125 18.961 3.63389 19.0906C3.68652 19.2203 3.77444 19.3326 3.88766 19.4148C4.00088 19.497 4.13487 19.5459 4.27445 19.5558C4.41403 19.5658 4.5536 19.5364 4.67734 19.4711L10 16.6727L15.3227 19.4711C15.468 19.5484 15.6367 19.5742 15.7984 19.5461C16.2062 19.4758 16.4805 19.0891 16.4102 18.6813L15.393 12.7539L19.6984 8.55626C19.8156 8.44142 19.893 8.29142 19.9164 8.12735C19.9797 7.7172 19.6937 7.33751 19.2836 7.27657Z"
				fill="#FFA73C"
			/>
		</svg>
	);
}

export function Badge() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.372 6.2168C15.3904 6.0776 15.4 5.9384 15.4 5.8C15.4 3.8968 13.6856 2.3696 11.7832 2.628C11.2288 1.6416 10.1728 1 9 1C7.8272 1 6.7712 1.6416 6.2168 2.628C4.3104 2.3696 2.6 3.8968 2.6 5.8C2.6 5.9384 2.6096 6.0776 2.628 6.2168C1.6416 6.772 1 7.828 1 9C1 10.172 1.6416 11.228 2.628 11.7832C2.60954 11.9214 2.60019 12.0606 2.6 12.2C2.6 14.1032 4.3104 15.6264 6.2168 15.372C6.7712 16.3584 7.8272 17 9 17C10.1728 17 11.2288 16.3584 11.7832 15.372C13.6856 15.6264 15.4 14.1032 15.4 12.2C15.4 12.0616 15.3904 11.9224 15.372 11.7832C16.3584 11.228 17 10.172 17 9C17 7.828 16.3584 6.772 15.372 6.2168Z"
				fill="#FFA73C"
			/>
			<path
				d="M5.37 8.51C5.37 7.83 5.52667 7.22 5.84 6.68C6.16 6.14 6.59 5.72 7.13 5.42C7.67667 5.11333 8.27333 4.96 8.92 4.96C9.66 4.96 10.3167 5.14333 10.89 5.51C11.47 5.87 11.89 6.38333 12.15 7.05H10.78C10.6 6.68333 10.35 6.41 10.03 6.23C9.71 6.05 9.34 5.96 8.92 5.96C8.46 5.96 8.05 6.06333 7.69 6.27C7.33 6.47667 7.04667 6.77333 6.84 7.16C6.64 7.54667 6.54 7.99667 6.54 8.51C6.54 9.02333 6.64 9.47333 6.84 9.86C7.04667 10.2467 7.33 10.5467 7.69 10.76C8.05 10.9667 8.46 11.07 8.92 11.07C9.34 11.07 9.71 10.98 10.03 10.8C10.35 10.62 10.6 10.3467 10.78 9.98H12.15C11.89 10.6467 11.47 11.16 10.89 11.52C10.3167 11.88 9.66 12.06 8.92 12.06C8.26667 12.06 7.67 11.91 7.13 11.61C6.59 11.3033 6.16 10.88 5.84 10.34C5.52667 9.8 5.37 9.19 5.37 8.51Z"
				fill="white"
			/>
		</svg>
	);
}

export function BadgePrestataire() {
	return (
		<svg
			width="20"
			height="20"
			viewBox="0 0 20 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M15.372 6.2168C15.3904 6.0776 15.4 5.9384 15.4 5.8C15.4 3.8968 13.6856 2.3696 11.7832 2.628C11.2288 1.6416 10.1728 1 9 1C7.8272 1 6.7712 1.6416 6.2168 2.628C4.3104 2.3696 2.6 3.8968 2.6 5.8C2.6 5.9384 2.6096 6.0776 2.628 6.2168C1.6416 6.772 1 7.828 1 9C1 10.172 1.6416 11.228 2.628 11.7832C2.60954 11.9214 2.60019 12.0606 2.6 12.2C2.6 14.1032 4.3104 15.6264 6.2168 15.372C6.7712 16.3584 7.8272 17 9 17C10.1728 17 11.2288 16.3584 11.7832 15.372C13.6856 15.6264 15.4 14.1032 15.4 12.2C15.4 12.0616 15.3904 11.9224 15.372 11.7832C16.3584 11.228 17 10.172 17 9C17 7.828 16.3584 6.772 15.372 6.2168Z"
				fill="#FFA73C"
			/>
			<path
				d="M10.61 7.12C10.61 7.47333 10.5267 7.80667 10.36 8.12C10.1933 8.43333 9.92667 8.69 9.56 8.89C9.19333 9.08333 8.72333 9.18 8.15 9.18H6.89V12H5.75V5.05H8.15C8.68333 5.05 9.13333 5.14333 9.5 5.33C9.87333 5.51 10.15 5.75667 10.33 6.07C10.5167 6.38333 10.61 6.73333 10.61 7.12ZM8.15 8.25C8.58333 8.25 8.90667 8.15333 9.12 7.96C9.33333 7.76 9.44 7.48 9.44 7.12C9.44 6.36 9.01 5.98 8.15 5.98H6.89V8.25H8.15Z"
				fill="white"
			/>
		</svg>
	);
}

export function Facebook() {
	return (
		<svg
			width="18"
			height="34"
			viewBox="0 0 18 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M4.38128 34V18.0462H0V12.3021H4.38128V7.39585C4.38128 3.54049 6.96932 0 12.9327 0C15.3472 0 17.1326 0.22287 17.1326 0.22287L16.9919 5.58691C16.9919 5.58691 15.1711 5.56985 13.1841 5.56985C11.0336 5.56985 10.6891 6.52406 10.6891 8.10781V12.3021H17.1629L16.8812 18.0462H10.6891V34H4.38128Z"
				fill="#FFA73C"
			/>
		</svg>
	);
}

export function Twitter() {
	return (
		<svg
			width="36"
			height="34"
			viewBox="0 0 36 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M32.3814 8.44357C31.319 8.88185 30.1629 9.19728 28.972 9.32013C30.2084 8.61767 31.1343 7.5079 31.5759 6.19904C30.4157 6.85908 29.1448 7.32191 27.8194 7.56701C27.2654 7.00065 26.5955 6.54946 25.8512 6.24156C25.1069 5.93366 24.3044 5.77564 23.4935 5.77736C20.2126 5.77736 17.574 8.32072 17.574 11.4418C17.574 11.8801 17.6295 12.3184 17.7198 12.7401C12.8071 12.4943 8.42567 10.2498 5.5128 6.81329C4.98204 7.68028 4.70391 8.66744 4.70733 9.67208C4.70733 11.6377 5.75236 13.3709 7.34593 14.3902C6.40681 14.3549 5.48969 14.108 4.66914 13.6697V13.7395C4.66914 16.492 6.70364 18.7731 9.41515 19.2977C8.90603 19.4241 8.3823 19.4888 7.85629 19.4902C7.47092 19.4902 7.10637 19.4537 6.73836 19.4039C7.48828 21.6485 9.67206 23.2787 12.2725 23.3318C10.238 24.8559 7.68964 25.7524 4.92259 25.7524C4.42611 25.7524 3.96783 25.7358 3.49219 25.6826C6.1169 27.293 9.23114 28.2227 12.5849 28.2227C23.4726 28.2227 29.4303 19.5965 29.4303 12.1092C29.4303 11.8635 29.4303 11.6178 29.413 11.3721C30.5656 10.5652 31.5759 9.56583 32.3814 8.44357Z"
				fill="#FFA73C"
			/>
		</svg>
	);
}
export function Instagram() {
	return (
		<svg
			width="37"
			height="34"
			viewBox="0 0 37 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				d="M18.4897 12.5729C15.9414 12.5729 13.8618 14.5617 13.8618 16.9988C13.8618 19.4359 15.9414 21.4248 18.4897 21.4248C21.0381 21.4248 23.1177 19.4359 23.1177 16.9988C23.1177 14.5617 21.0381 12.5729 18.4897 12.5729ZM32.3702 16.9988C32.3702 15.166 32.3875 13.3498 32.2799 11.5203C32.1723 9.39532 31.6654 7.50939 30.0406 5.95548C28.4123 4.39825 26.4437 3.91681 24.2218 3.81388C22.3053 3.71095 20.4062 3.72755 18.4932 3.72755C16.5768 3.72755 14.6777 3.71095 12.7647 3.81388C10.5427 3.91681 8.5707 4.40157 6.94587 5.95548C5.31758 7.51271 4.81416 9.39532 4.70653 11.5203C4.59891 13.3531 4.61627 15.1693 4.61627 16.9988C4.61627 18.8283 4.59891 20.6479 4.70653 22.4774C4.81416 24.6024 5.32105 26.4883 6.94587 28.0422C8.57417 29.5994 10.5427 30.0809 12.7647 30.1838C14.6811 30.2867 16.5802 30.2701 18.4932 30.2701C20.4097 30.2701 22.3088 30.2867 24.2218 30.1838C26.4437 30.0809 28.4157 29.5961 30.0406 28.0422C31.6689 26.485 32.1723 24.6024 32.2799 22.4774C32.391 20.6479 32.3702 18.8317 32.3702 16.9988ZM18.4897 23.8088C14.5492 23.8088 11.369 20.7674 11.369 16.9988C11.369 13.2303 14.5492 10.1889 18.4897 10.1889C22.4303 10.1889 25.6105 13.2303 25.6105 16.9988C25.6105 20.7674 22.4303 23.8088 18.4897 23.8088ZM25.9021 11.5004C24.9821 11.5004 24.2391 10.7899 24.2391 9.90997C24.2391 9.03009 24.9821 8.31954 25.9021 8.31954C26.8222 8.31954 27.5651 9.03009 27.5651 9.90997C27.5654 10.1189 27.5226 10.3258 27.4391 10.5189C27.3556 10.712 27.2332 10.8874 27.0787 11.0352C26.9242 11.1829 26.7408 11.3 26.5389 11.3799C26.337 11.4597 26.1206 11.5007 25.9021 11.5004Z"
				fill="#FFA73C"
			/>
		</svg>
	);
}
export function Linkedin() {
	return (
		<svg
			width="35"
			height="34"
			viewBox="0 0 35 34"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M1.68164 4.02181C1.68164 3.33123 1.95597 2.66893 2.44429 2.18062C2.9326 1.6923 3.59489 1.41797 4.28547 1.41797H30.2416C30.5839 1.41741 30.9229 1.48436 31.2392 1.61498C31.5555 1.74561 31.843 1.93734 32.0851 2.1792C32.3272 2.42106 32.5192 2.70831 32.6502 3.02449C32.7812 3.34067 32.8485 3.67957 32.8483 4.02181V29.978C32.8487 30.3203 32.7816 30.6593 32.6508 30.9756C32.52 31.2919 32.3281 31.5794 32.0862 31.8215C31.8442 32.0636 31.5569 32.2556 31.2406 32.3866C30.9243 32.5175 30.5854 32.5848 30.2431 32.5846H4.28547C3.94341 32.5846 3.60471 32.5172 3.2887 32.3863C2.9727 32.2554 2.68559 32.0634 2.44379 31.8215C2.20198 31.5796 2.01021 31.2923 1.87944 30.9763C1.74867 30.6602 1.68145 30.3214 1.68164 29.9794V4.02181ZM14.018 13.301H18.2382V15.4203C18.8474 14.202 20.4057 13.1055 22.7475 13.1055C27.2369 13.1055 28.3008 15.5322 28.3008 19.9848V28.2326H23.7576V20.9991C23.7576 18.4633 23.1484 17.0325 21.6014 17.0325C19.4551 17.0325 18.5626 18.5752 18.5626 20.9991V28.2326H14.018V13.301ZM6.22631 28.0386H10.771V13.1055H6.22631V28.0371V28.0386ZM11.4212 8.23497C11.4298 8.62409 11.3606 9.011 11.2176 9.373C11.0746 9.735 10.8607 10.0648 10.5886 10.343C10.3164 10.6212 9.99138 10.8423 9.63262 10.9932C9.27386 11.1442 8.88856 11.2219 8.49935 11.2219C8.11014 11.2219 7.72484 11.1442 7.36608 10.9932C7.00732 10.8423 6.68232 10.6212 6.41015 10.343C6.13798 10.0648 5.92412 9.735 5.78114 9.373C5.63815 9.011 5.56891 8.62409 5.57747 8.23497C5.59429 7.47118 5.90952 6.74434 6.45565 6.21011C7.00177 5.67588 7.73538 5.37673 8.49935 5.37673C9.26332 5.37673 9.99693 5.67588 10.5431 6.21011C11.0892 6.74434 11.4044 7.47118 11.4212 8.23497Z"
				fill="#FFA73C"
			/>
		</svg>
	);
}

export function IconBoost() {
	return (
		<svg
			width="18"
			height="18"
			viewBox="0 0 18 18"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M15.5137 4.47654C15.515 3.94841 15.3069 3.44133 14.9352 3.06653C14.5634 2.69172 14.0583 2.47978 13.5306 2.47721C13.2603 2.46385 12.9901 2.5056 12.7364 2.59993C12.4827 2.69425 12.2508 2.83919 12.0548 3.02593C11.8587 3.21267 11.7027 3.43733 11.596 3.68627C11.4894 3.93522 11.4344 4.20325 11.4344 4.47411C11.4344 4.74496 11.4894 5.013 11.596 5.26194C11.7027 5.51088 11.8587 5.73554 12.0548 5.92228C12.2508 6.10903 12.4827 6.25396 12.7364 6.34828C12.9901 6.44261 13.2603 6.48436 13.5306 6.471C14.0546 6.47177 14.5576 6.26548 14.9304 5.89699C15.118 5.7118 15.266 5.49039 15.3654 5.24621C15.4648 5.00203 15.5136 4.74017 15.5088 4.47654H15.5137ZM5.15099 11.6469L4.17888 10.674L1.16534 11.6372H1.06813C1.02075 11.6401 0.973295 11.633 0.928887 11.6162C0.884479 11.5994 0.844133 11.5734 0.810518 11.5399L0.105738 10.8442C0.0488061 10.79 0.0123741 10.7178 0.00262798 10.6398C-0.00711811 10.5617 0.0104227 10.4827 0.0522718 10.4162L0.873705 9.00057C1.38249 8.12913 2.0923 7.39223 2.94381 6.85146C3.79532 6.31068 4.76379 5.98174 5.76828 5.89212L6.97856 5.7851C7.68172 4.9484 8.32979 4.23169 8.92278 3.63497C10.0479 2.4422 11.391 1.47621 12.8793 0.789209C14.3963 0.221697 16.0093 -0.0443305 17.628 0.0060166C17.726 0.00688806 17.8199 0.0451716 17.8905 0.113037C17.9242 0.143977 17.951 0.181567 17.9694 0.223431C17.9879 0.265296 17.9974 0.310526 17.9974 0.356264C18.0306 1.98119 17.7415 3.59656 17.1468 5.10893C16.4781 6.60286 15.5237 7.95136 14.3374 9.0784C13.7396 9.68161 13.0202 10.3286 12.1891 11.0242L12.0821 12.2306C11.9949 13.2354 11.6688 14.2048 11.1311 15.0578C10.5933 15.9108 9.85947 16.6227 8.99083 17.1341L7.58613 17.9562C7.53072 17.9854 7.46893 18.0005 7.40629 18C7.31328 17.9981 7.22386 17.9637 7.15354 17.9027L6.44876 17.1925C6.40325 17.1489 6.37092 17.0934 6.35544 17.0324C6.33997 16.9713 6.34199 16.9071 6.36127 16.8471L7.29936 13.797L6.35641 12.8533M12.2085 11.0242L7.29936 13.797L12.2085 11.0242ZM6.97856 5.7851L4.20318 10.6983L6.97856 5.7851Z" />
			<path d="M3.64417 14.3503C5.58839 13.9903 9.03938 10.7505 9.44281 8.54688C7.22639 8.97009 3.98926 12.4045 3.64417 14.3503Z" />
		</svg>
	);
}

export function IconSpeaker() {
	return (
		<svg
			width="22"
			height="24"
			viewBox="0 0 22 24"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M2.59995 7.2C2.59995 5.88 3.67995 4.8 4.99995 4.8H14.6L19.4 0H21.8V19.2H19.4L14.6 14.4H4.99995C4.36343 14.4 3.75298 14.1471 3.30289 13.6971C2.85281 13.247 2.59995 12.6365 2.59995 12H0.199951V7.2H2.59995ZM12.2 18V24H8.59995L6.59595 18H4.99995V15.6H14.6V18H12.2Z" />
		</svg>
	);
}

export function IconProject() {
	return (
		<svg
			width="18"
			height="20"
			viewBox="0 0 18 20"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<path d="M16 2.0002H11.82C11.6152 1.41564 11.2339 0.909159 10.7287 0.550821C10.2235 0.192483 9.61939 0 9 0C8.38061 0 7.77653 0.192483 7.27133 0.550821C6.76612 0.909159 6.38476 1.41564 6.18 2.0002H2C1.47005 2.00179 0.962265 2.21301 0.587535 2.58774C0.212805 2.96247 0.00158273 3.47026 0 4.0002V18.0002C0.00158273 18.5301 0.212805 19.0379 0.587535 19.4127C0.962265 19.7874 1.47005 19.9986 2 20.0002H16C16.5299 19.9986 17.0377 19.7874 17.4125 19.4127C17.7872 19.0379 17.9984 18.5301 18 18.0002V4.0002C17.9984 3.47026 17.7872 2.96247 17.4125 2.58774C17.0377 2.21301 16.5299 2.00179 16 2.0002ZM9 2.0002C9.19778 2.0002 9.39112 2.05885 9.55557 2.16873C9.72002 2.27861 9.84819 2.43479 9.92388 2.61752C9.99957 2.80025 10.0194 3.00131 9.98079 3.19529C9.9422 3.38927 9.84696 3.56746 9.70711 3.70731C9.56725 3.84716 9.38907 3.9424 9.19509 3.98099C9.00111 4.01957 8.80004 3.99977 8.61732 3.92408C8.43459 3.84839 8.27841 3.72022 8.16853 3.55577C8.05865 3.39132 8 3.19798 8 3.0002C8.00079 2.73523 8.1064 2.48133 8.29377 2.29397C8.48113 2.1066 8.73503 2.00099 9 2.0002ZM7 7.0002L9.79 9.7942L12.31 7.2742L11 6.0002H15V10.0002L13.724 8.6892L9.792 12.6242L7 9.8302L4.414 12.4142L3 11.0002L7 7.0002ZM16 17.0002H2V15.0002H16V17.0002Z" />
		</svg>
	);
}

export function IconCircleCheck() {
	return (
		<svg
			width="17"
			height="16"
			viewBox="0 0 17 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g clipPath="url(#clip0_3069_17790)">
				<path
					d="M8.41992 0C4.00166 0 0.419922 3.58173 0.419922 8C0.419922 12.4183 4.00166 16 8.41992 16C12.8382 16 16.4199 12.4183 16.4199 8C16.4199 3.58173 12.8382 0 8.41992 0ZM8.41992 1.73828C11.8782 1.73828 14.6807 4.54261 14.6807 8C14.6807 11.4574 11.8782 14.2607 8.41992 14.2607C4.96166 14.2607 2.15918 11.4574 2.15918 8C2.15919 4.54261 4.96166 1.73828 8.41992 1.73828ZM11.4853 4.20117L6.84863 8.83887L5.34472 7.33496L3.86914 8.80957L5.37304 10.3135L6.85839 11.7988L8.33302 10.3232L12.9707 5.68652L11.4853 4.20117Z"
					fill="#FFA73C"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3069_17790">
					<rect
						width="16"
						height="16"
						fill="white"
						transform="translate(0.419922)"
					/>
				</clipPath>
			</defs>
		</svg>
	);
}
