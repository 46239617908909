import React from "react";
import ReactQuill from "react-quill";
import { IAbonnnement } from "../../../../utils/api/abonnement/abonnement.type";
import ErrorMessage from "../../../common/ErrorMessage";
import CloseModalBtn from "../../../shared/CloseModalBtn";
import useAbonnementForm from "./forms/useAbonnementForm";
const AddAbonnementModal = ({ abonnement }: { abonnement?: IAbonnnement }) => {
	const { register, onSubmit, errors, isLoading, description, handleChange, reset } =
		useAbonnementForm(abonnement);
	return (
		<div
			className="modal fade"
			id={"addAbonnementModal"}
			aria-labelledby={`addAbonnementModalLabel`}
			aria-hidden="true"
		>
			<div className="modal-dialog modal-dialog-centered modal-lg">
				<div className="modal-content">
					<div className="modal-header">
						<h5
							className="modal-title modal-title-custom"
							id={`addAbonnementModalTitle`}
						>
							{abonnement ? "Modifier" : "Ajouter"} une offre
						</h5>
						<CloseModalBtn onClick={reset} />
					</div>
					<div className="modal-body">
						<form onSubmit={onSubmit}>
							<div className="row">
								<div className="mb-3">
									<div className="form-group">
										<label
											htmlFor="nom"
											aria-labelledby="nom"
											className="admin-form-label"
										>
											Nom de l'offre
										</label>
										<span className="text-danger">*</span>
										<input
											type="text"
											className="form-control"
											id="nom"
											{...register("title")}
										/>
										{errors.title?.message && (
											<ErrorMessage
												message={
													errors.title.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12  mb-3">
									<div className="form-group">
										<label
											htmlFor="duration"
											aria-labelledby="duration"
											className="admin-form-label"
										>
											Durée mensuelle
										</label>
										<span className="text-danger">*</span>
										<input
											type="number"
											className="form-control "
											id="duration"
											{...register("duration")}
										/>
										{errors.duration?.message && (
											<ErrorMessage
												message={
													errors.duration
														.message
												}
											/>
										)}
									</div>
								</div>
								<div className="col-12  mb-3">
									<div className="form-group">
										<label
											htmlFor="adresse"
											aria-labelledby="adresse"
											className="admin-form-label"
										>
											Prix mensuel
											<span className="text-danger">
												*
											</span>
										</label>
										<input
											type="number"
											className="form-control "
											id="adresse"
											{...register("price")}
											data-testid="adresseId"
										/>
										{errors.price?.message && (
											<ErrorMessage
												message={
													errors.price.message
												}
											/>
										)}
									</div>
								</div>

								<div className="col-12  mb-3 form-group">
									<label
										htmlFor="desc-mision"
										className="admin-form-label"
									>
										Description
										<span className="text-danger">*</span>
									</label>
									<ReactQuill
										className="editor-cgu"
										value={description}
										onChange={handleChange}
									/>
									{errors.description && (
										<ErrorMessage
											message={
												errors.description?.message
											}
										/>
									)}
								</div>
								<div className="col-12 col-md-4 offset-md-8 pt-4">
									{isLoading && (
										<button
											type="button"
											className="btn btn-primary auth-submit-btn"
										>
											Chargement...
										</button>
									)}
									{!isLoading && (
										<button
											type="submit"
											className="btn btn-primary auth-submit-btn"
										>
											{abonnement
												? "Modifier"
												: "Ajouter"}
										</button>
									)}
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddAbonnementModal;
