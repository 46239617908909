import React, { useState, useEffect } from 'react'
import { useGetCategoriesByVisitorQuery } from '../../../../../utils/api/category/category.api'
import { useStepType } from '../../../../../utils/type'
import UseAddBoutique from './requestCrudBoutique/UseAddBoutique'
import CompanyInfos from './steps/CompanyInfos'
import InfosBancaire from './steps/InfosBancaire'
import InfosBoutique from './steps/InfosBoutique'
import { useStep } from 'react-hooks-helper'
import { useLocation, useNavigate } from 'react-router-dom'
import { Commerce } from '../../../../../utils/api/commerce/commerce.type'
import UseEditBoutique from './requestCrudBoutique/UseEditBoutique'

const steps = [
  {
    id: 'detail-boutique',
    Component: InfosBoutique,
  },
  {
    id: 'more-detail',
    Component: CompanyInfos,
  },
  {
    id: 'infos-bank',
    Component: InfosBancaire,
  },
]
function EditBoutiqueTrader() {
  const {
		register,
		onSubmit,
		formState,
		setValue,
		level,
		isLoading,
		setLevel,
		dataForm,
		setError,
  } = UseEditBoutique();
  const location = useLocation()
  const navigate = useNavigate()
  const { data: categories = [], isSuccess } = useGetCategoriesByVisitorQuery()
  const [adress, setAdress] = useState<string>()
  const [profil, setProfil] = useState<string>()
  const [options, setOptions] = useState<any>([])
  const [cityLabel, setCityLabel] = useState<string>('Choisir une ville')
  const [stateLabel, setStateLabel] = useState<string>('Choisir une région')
  const [logoUrl, setLogoUrl] = useState<string>()
  const [fileUrl, setFileUrl] = useState<string>()
  const [logo, setLogo] = useState<File>()
  const [file, setFile] = useState<File>()
  const [option, setOption] = useState<any>()
  const [countryLabel, setCountryLabel] = useState<string>('Choisir un pays')
  const { step, navigation, index }: useStepType = useStep({
    initialStep: 0,
    steps,
  })

  const [item, setItem] = useState<any>(location?.state)

  const [country, setCountry] = useState<any>('')
  const [state, setState] = useState<any>('')
  const [city, setCity] = useState<any>('')
  const { Component } = step
  const props = {
		navigation,
		register,
		setValue,
		formState,
		setAdress,
		adress,
		setProfil,
		profil,
		options,
		setOptions,
		cityLabel,
		setCityLabel,
		setLevel,
		dataForm,
		country,
		setCountry,
		state,
		setState,
		city,
		setCity,
		isLoading,
		countryLabel,
		stateLabel,
		logo,
		setLogo,
		file,
		setFile,
		option,
		setOption,
		item,
		setError,
  };

  useEffect(() => {
    window.scrollTo(10, 10)
    let newIndex = index + 1
    console.log('level index', level, step)
    setValue('step', newIndex)
  }, [index])
  useEffect(() => {
    if (level) {
      console.log('level', level)
      if (level === 1) {
        navigation.go('more-detail')
      }

      if (level === 2) {
        navigation.go('infos-bank')
      }
    }
  }, [level, dataForm])

  useEffect(() => {
    if (isSuccess) {
      setOptions(
        categories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          }
        }),
      )
    }
  }, [categories, isSuccess])

  useEffect(() => {
    if (location?.state) {
      console.log('location', location?.state)
      let data: any = location?.state
      delete data.slides
      delete data.subscribers
      delete data.products
      delete data.notes
      setLogoUrl(data?.logo)
      setFileUrl(data.background_image)
      setOption(data?.category?.id)
      setAdress(data?.location)
      setProfil(data?.profil)
      setCityLabel(data?.city)
      setStateLabel(data?.region)
      setCountryLabel(data?.country)
      setValue('index', index)
      if (dataForm?.id) {
        for (let key of Object.keys(dataForm)) {
          const val = dataForm[key]

          if (key === 'author' || key === 'category') {
            setValue(key, parseInt(val))
          } else {
            setValue(key, val)
          }
        }
      } else {
        for (let key of Object.keys(data)) {
          const val = data[key]
          if (key !== 'logo' && key !== 'background_image') {
            if (key === 'author' || key === 'category') {
              setValue(key, val?.id)
            } else {
              setValue(key, val)
            }
          }
        }
      }
    } else {
      setTimeout(() => {
        navigate(-1)
      }, 100)
    }
  }, [location, dataForm])
  return (
    <div className="article-card p-4">
      <div className="flex-sb">
        <div className="d-flex flex-column flex-md-row gap-md-3">
          <h3 className="article-title">Modifier une boutique</h3>
        </div>
      </div>
      <form onSubmit={onSubmit}>{item?.id && <Component {...props} />}</form>
    </div>
  )
}

export default EditBoutiqueTrader
