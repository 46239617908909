import React, { useState } from "react";
import "../MessageAdmin/MessageAdmin.css";
import "../SellerListAdmin/SellerListAdmin.css";
import { AlertInfo } from "../../../common/Alert";
import Pagination from "../../../common/Pagination";
import { useGetServiceMessagesQuery } from "../../../../utils/api/service/service.api";
import moment from "moment";
import { createMarkup } from "../../../../utils/utils";
import ChangeStatusMessageServiceModal, {
  ServiceStatus,
} from "../../modals/adminModal/ChangeStatusMessageServiceModal";
import { MessageItemSkelton } from "../MessageAdmin/MessageAdmin";
import { IService } from "../../../../utils/api/service/service.type";
import { useSearchParams } from "react-router-dom";
import DetailsUserModal from "../../modals/adminModal/DetailsUserModal";

const ServiceAdmin = () => {
  const [page, setPage] = useState(1);
  const [item, setItem] = useState<IService>();
  const [searchParams, setSearchParams] = useSearchParams();
  const statut = searchParams.get("statut");

  const { data, isLoading } = useGetServiceMessagesQuery({
    statut,
    page,
    limit: 10,
  });

  const handleFilterByStatus = (item): void => {
    setSearchParams({ statut: item?.value });
  };
  return (
    <div className="bloc-entete-admin-container">
      <div className="bloc-entete-admin py-4">
        <div className="title-admin-container">
          <h1 className="admin-page-title">Services</h1>
        </div>
      </div>
      <div className="my-3 d-flex gap-3">
        <button
          onClick={() => {
            setSearchParams({});
          }}
          className={`btn-filter ${statut === null && "active"}`}
        >
          Tous
        </button>
        {ServiceStatus?.map((item) => (
          <button
            className={`btn-filter ${statut === item?.value && "active"}`}
            key={item?.value}
            onClick={() => {
              handleFilterByStatus(item);
            }}
          >
            {item?.label}
          </button>
        ))}
      </div>
      <div className="mx-2">
        {!isLoading &&
          data?.results &&
          data?.results?.map((item) => (
            <div key={item?.slug} className="mb-3">
              <div className="px-2 py-3 admin-detail-mission-card mb-2 flex-m gap-1">
                <button
                  className="no-style-btn accordion-button"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#responseContact${item?.slug}`}
                  aria-expanded="false"
                  aria-controls="responseContact"
                ></button>
                <div className="row w-100 align-items-center">
                  <div className="col-md-5 mb-2">
                    <button
                      className="admin-detail-mission-card-description btn p-0"
                      data-bs-toggle="modal"
                      // data-bs-target="#DetailsMessageModal"
                      // onClick={() => setItem(item)}
                      style={{
                        textOverflow: "ellipsis",
                      }}
                    >
                      {item?.object}
                    </button>
                    <p className="admin-detail-mission-card-frequence fz-14 mb-0">
                      {moment(item?.created_at).format("DD/MM/YYYY à HH:mm:ss")}
                    </p>
                  </div>
                  <div className="col-md-2 mb-2">
                    <button
                      className={`btn message-btn ${
                        item?.statut_service === "ouvert"
                          ? "badge-danger"
                          : item?.statut_service === "encours"
                          ? "bg-secondary"
                          : "bg-success"
                      }`}
                      onClick={() => setItem(item)}
                      title="Changer le statut"
                      data-bs-toggle="modal"
                      data-bs-target="#ChangeStatusMessageServiceModal"
                      aria-expanded="false"
                    >
                      {item?.statut_service === "ouvert"
                        ? "Demande ouverte"
                        : item?.statut_service === "encours"
                        ? "En cours de traitement"
                        : "Clôturée"}
                    </button>
                  </div>
                  <div
                    className="col-md-3 mb-2"
                    role="button"
                    data-bs-toggle="modal"
                    data-bs-target="#DetailsUserModal"
                    onClick={() => setItem(item)}
                  >
                    <p className="admin-detail-mission-card-description mb-0">
                      {item?.prenom + " " + item?.nom}
                    </p>
                  </div>
                </div>
              </div>
              <div className="collapse" id={`responseContact${item?.slug}`}>
                <div
                  className="card card-body mb-1 message-text"
                  dangerouslySetInnerHTML={createMarkup(item?.message)}
                />
              </div>
            </div>
          ))}
        {isLoading &&
          [...Array(4)].map((elem, _i) => <MessageItemSkelton key={_i} />)}
        {data?.results?.length === 0 && (
          <AlertInfo message="Aucun message de contact trouvé" />
        )}
        {data?.results && data?.results?.length > 10 && (
          <Pagination page={page} onPageChange={setPage} total={data?.count} />
        )}
      </div>
      <DetailsUserModal user={item} />
      <ChangeStatusMessageServiceModal item={item} />
    </div>
  );
};

export default ServiceAdmin;
